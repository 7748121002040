import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useAppDispatch, useAppSelector } from 'hooks';
import { hideAlertAction } from 'store/actions/Actions';

import styles from './Alert.module.scss';

function Alert() {
  const [show, setShow] = useState(false);
  const dispatch = useAppDispatch();
  const { message, color, type } = useAppSelector((state) => state.AlertReducer);

  useEffect(() => {
    if (message && (!type || type === 'simple')) {
      window.setTimeout(() => {
        setShow(true);
      }, 10);
    } else {
      setShow(false);
    }
  }, [message]);

  const handleClose = (): void => {
    setShow(false);
    window.setTimeout(() => {
      dispatch(hideAlertAction());
    }, 1000);
  };

  const style = classNames(styles.alert, styles[color], show && styles.show);

  return message ? (
    <div onClick={handleClose} className={style} aria-hidden="true">
      {message}
    </div>
  ) : undefined;
}

export default Alert;
