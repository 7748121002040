import React from 'react';
import classNames from 'classnames';

import styles from './Tab.module.scss';

export interface ITabProps {
  className?: string;
  active?: boolean;
  icon?: string;
  heading?: string;
  description?: string;
  count?: number | string;
  onClick?: () => void;
}

const defaultProps = {
  className: '',
  active: false,
  icon: 'fal fa-copy',
  heading: 'Description',
  description: '',
  count: undefined,
  onClick: () => false,
};

export function Tab({ className, icon, heading, active, count, description, onClick }: ITabProps) {
  const style = classNames(styles.tab, className, { [styles.active]: active });

  const iconStyle = classNames(icon, styles['tab-icon']);

  return (
    <div className={style} role="none" onClick={onClick}>
      <i className={iconStyle} />
      <div className={styles['tab-info']}>
        <span className={styles['tab-heading']}>
          {heading}
          {count && <span>{` (${count})`}</span>}
        </span>
        <span className={styles['tab-description']}>{description}</span>
      </div>
    </div>
  );
}

Tab.defaultProps = defaultProps;

export default Tab;
