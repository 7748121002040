// import ColVisibilitySelector from "components/DataTable/ColVisibilitySelector";
import ItemActionsMenu from 'components/DataTable/ItemActionsMenu';
import React from 'react';
import Checkbox from 'components/Checkbox/Checkbox';
import moment from 'moment';

const actionMenuDropDown = React.createRef();

export default {
  TABLE: {
    className: 'data-table-documents',
    initialSortKey: 'name',
    initialSortOrder: 'asc',
  },

  COLUMN: [
    {
      label: 'Row Selector',
      key: 'row-selector',
      header: ({ customTableProps }) => {
        const isSelectedAll =
          customTableProps.selectedRows &&
          customTableProps.selectedRows.length &&
          customTableProps.rowsData &&
          customTableProps.rowsData.length &&
          customTableProps.selectedRows.length === customTableProps.rowsData.length
            ? true
            : false;
        return <Checkbox checked={isSelectedAll} onChange={() => customTableProps.doSelectAll()} name="row-selector" />;
      },
      className: 'col-row-selector',
      format: ({ rowData, customTableProps }) => {
        const isSelected = customTableProps.selectedRows && customTableProps.selectedRows.length && customTableProps.selectedRows.includes(rowData) ? true : false;
        return <Checkbox checked={isSelected} onChange={() => customTableProps.onRowSelect({ rowData })} name="row-selector" />;
      },
      canBeHidden: false,
    },
    {
      label: 'Name',
      header: 'NAME',
      key: 'name',
      className: 'name',
      sortFunction: function (a, b) {
        return b.type.localeCompare(a.type) || b.folder_name - a.folder_name || b.type.localeCompare(a.type) || b.description - a.description;
      },
      sortable: true,
      format: ({ rowData, customTableProps }) => {
        let icon = '';

        if (rowData['type'] === 'folder') {
          icon = <i className="fas fa-folder" />;
        }

        if (rowData['type'] === 'file') {
          if (rowData['file_type'] === 'text') {
            if (rowData['folder_name'].endsWith('.pdf')) {
              icon = <i className="fas fa-file-pdf"></i>;
            } else {
              icon = <i className="fas fa-file-word"></i>;
            }
          }

          if (rowData['file_type'] === 'spreadsheet') {
            icon = <i className="fas fa-file-excel"></i>;
          }

          if (rowData['file_type'] === 'presentation') {
            icon = <i className="fas fa-file-powerpoint"></i>;
          }

          if (rowData['file_type'] === 'video') {
            icon = <i className="fas fa-file-video"></i>;
          }
        }

        return (
          <span>            
            <span style={{display: 'flex'}}>
              <span>{icon}</span><span><strong>{rowData['folder_name']}</strong><br /><small>{rowData['description']}</small></span>
            </span>
          </span>
        );
      },
    },
    // {
    //   label: 'Description',
    //   header: 'DESCRIPTION',
    //   key: 'description',
    //   className: 'description',
    //   sortFunction: function (a, b) {
    //     return b.type.localeCompare(a.type) || b.description - a.description;
    //   },
    //   sortable: true,
    //   format: ({ rowData }) => <span>{rowData['description']}</span>,
    // },
    {
      label: 'Program',
      header: 'PROGRAM',
      key: 'regulatory_act',
      sortable: true,
      className: 'type',
      format: ({ rowData }) => {
        return <span>{rowData['type'] === 'folder' ? '' : rowData['regulatory_act']}</span>;
      },
    },
    {
      label: 'Last Modified',
      header: 'LAST MODIFIED',
      key: 'last_modified',
      sortable: true,
      format: ({ rowData, customTableProps }) => {
        return <span>{moment(rowData['last_modified']).format('MM/DD/YYYY')}</span>;
      },
    },
    {
      label: 'Expiration',
      header: 'EXPIRATION',
      key: 'expiration_date',
      sortable: true,
      format: ({ rowData }) => {
        return <span>{rowData['expiration_date'] ? moment(rowData['expiration_date']).format('MM/DD/YYYY') : '-'}</span>;
      },
    },
    {
      label: 'Permission Level',
      header: 'PERMISSION LEVEL',
      key: 'permission_level',
      sortable: true,
      format: ({ rowData }) => {
        return <span>{rowData['permission_level']}</span>;
      },
    },
    {
      label: 'Actions',
      key: 'actions',
      format: ({ rowData, customTableProps }) => {
        return (
          <ItemActionsMenu
            ref={actionMenuDropDown}
            actions={(() => {
              return rowData.type === 'folder'
                ? [
                    // customTableProps.view && {
                    //     label: "View",
                    //     action: () => {
                    //         if (actionMenuDropDown && actionMenuDropDown.current){
                    //             actionMenuDropDown.current.handleMenuClose();
                    //         }
                    //         customTableProps.view(rowData);
                    //     }
                    // },
                    customTableProps.download && {
                      label: 'Download Folder',
                      action: () => {
                        if (actionMenuDropDown && actionMenuDropDown.current) {
                          actionMenuDropDown.current.handleMenuClose();
                        }
                        customTableProps.download(rowData);
                      },
                    },
                    customTableProps.upload && {
                      label: 'Upload Documents',
                      action: () => {
                        if (actionMenuDropDown && actionMenuDropDown.current) {
                          actionMenuDropDown.current.handleMenuClose();
                        }
                        customTableProps.upload({ rowData });
                      },
                    },
                    customTableProps.edit && {
                      label: 'Edit Permissions',
                      action: () => {
                        customTableProps.edit(rowData);
                      },
                    },
                    customTableProps.delete && {
                      label: 'Delete',
                      action: () => {
                        if (actionMenuDropDown && actionMenuDropDown.current) {
                          actionMenuDropDown.current.handleMenuClose();
                        }
                        customTableProps.delete(rowData);
                      },
                    },
                  ]
                : [
                    customTableProps.edit &&
                      rowData.file_type !== 'video' &&
                      !rowData.folder_name.endsWith('.pdf') && {
                        label: 'Edit Document',
                        action: () => {
                          customTableProps.edit(rowData);
                        },
                      },
                    customTableProps.download && {
                      label: 'Download Document',
                      action: () => {
                        if (actionMenuDropDown && actionMenuDropDown.current) {
                          actionMenuDropDown.current.handleMenuClose();
                        }
                        customTableProps.download(rowData);
                      },
                    },
                    customTableProps.editFilePermissions && {
                      label: 'Edit Permissions',
                      action: () => {
                        customTableProps.editFilePermissions(rowData);
                      },
                    },
                    customTableProps.delete && {
                      label: 'Delete',
                      action: () => {
                        if (actionMenuDropDown && actionMenuDropDown.current) {
                          actionMenuDropDown.current.handleMenuClose();
                        }
                        customTableProps.delete(rowData);
                      },
                    },
                  ];
            })()}
          />
        );
      },
      // className: "col-actions",
      // header: ({ customTableProps }) => {
      //     return (
      //         <ColVisibilitySelector
      //             colDefs={customTableProps.colDefs}
      //             visibleColumnKeys={customTableProps.visibleColumnKeys}
      //             onChangeVisibility={colKey =>
      //                 customTableProps.onChangeVisibility(colKey)
      //             }
      //         />
      //     );
      // }
    },
  ],
};
