import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import { useAppSelector, useAppDispatch } from 'hooks';
import { Button } from 'components/Button/Button';
import { masterKeyAction } from 'store/actions/Actions';

import TypeIcon from './TypeIcon';
import DeleteMethodModal from '../DeleteMethod/DeleteMethodModal';
import MasterKeyModal from '../MasterKeyModal/MasterKeyModal';

import styles from './Method.module.scss';

function VerifiedMethod(props) {
  const { type, value, userId } = props;

  const MfaReducer = useAppSelector((state) => state.MfaReducer);
  const dispatch = useAppDispatch();

  const [showModal, setShowModal] = useState(false);
  const [showMasterKeyModal, setShowMasterKeyModal] = useState(undefined);

  useEffect(() => {
    if (MfaReducer?.mfa?.masterKey) {
      setShowMasterKeyModal(true);
    }
  }, [MfaReducer?.mfa?.masterKey]);

  useEffect(() => {
    if (MfaReducer?.mfa?.masterKey && showMasterKeyModal === false) {
      dispatch(masterKeyAction());
    }
  }, [showMasterKeyModal, MfaReducer?.mfa?.masterKey]);

  const onRemoveVerifiedValue = () => setShowModal(true);

  const renderDeleteMethodModal = () =>
    ReactDOM.createPortal(<DeleteMethodModal userId={userId} type={type} value={value} setShowModal={setShowModal} />, document.getElementById('modal-root'));

  const renderMasterKeyMethodModal = () => ReactDOM.createPortal(<MasterKeyModal setShowModal={setShowMasterKeyModal} />, document.getElementById('modal-root'));

  return (
    <div className={styles.form_confirm}>
      <div className={styles.form_confirm_value}>
        <TypeIcon type={type} />
        <div className={styles.title}>{value}</div>
      </div>
      <div className={styles.verified}>
        <i className="fa-solid fa-circle-check" color="#0a407e" />
        <span className={styles.status}>Active</span>
      </div>
      <div className={styles.button_group}>
        <Button size="md" text="Remove" color="danger" onClick={onRemoveVerifiedValue} />
      </div>
      {showModal && renderDeleteMethodModal()}
      {showMasterKeyModal && renderMasterKeyMethodModal()}
    </div>
  );
}

export default VerifiedMethod;
