import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';

import Modal from 'components/Modal/Modal';
import { Button } from 'components/Button/Button';

import { hideSessionModalAction, authLogoutAction } from 'store/actions/Actions';

import styles from './SessionExpirationModal.module.scss';

export function SessionExpirationModal() {
  const [counter, setCounter] = React.useState<number>(0);
  const dispatch = useAppDispatch();
  const SessionModalReducer = useAppSelector((state) => state.SessionModalReducer);
  const AuthReducer = useAppSelector((state) => state.AuthReducer);
  const { message } = SessionModalReducer;
  const { isLoggedIn } = AuthReducer;

  useEffect(() => {
    if (counter > 0 && message) {
      window.setTimeout(() => setCounter(counter - 1), 1000);
    }
    if (message && counter === 0) {
      dispatch(hideSessionModalAction());
      dispatch(authLogoutAction());
    }
  }, [counter]);

  useEffect(() => {
    if (message && isLoggedIn) {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      }
      setCounter(60);
    }
  }, [message, isLoggedIn]);

  return (
    message &&
    isLoggedIn && (
      <Modal className={styles.modal} disableCloseButton>
        <div>
          <p className={styles['modal-heading']}>Session will expire</p>
          <div className={styles['modal-content']}>{`Your session will expire in ${counter} seconds. To continue using the application click continue session button.`}</div>

          <div className={styles['modal-footer']}>
            <Button text="CONTINUE SESSION" color="primary" onClick={() => dispatch(hideSessionModalAction())} />
          </div>
        </div>
      </Modal>
    )
  );
}

export default SessionExpirationModal;
