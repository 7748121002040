import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import styles from './Edit.module.scss';

import PageHeader from 'v2/components/PageHeader/PageHeader';
import FormElement from 'components/FormElement/FormElement';
import Select from 'components/Select/Select';
import CostInput from 'components/CostInput/CostInput';
import Row from 'components/Row/Row';
import DateInput from 'components/DateInput/DateInput';
import AddTask from 'views/Achieve/Remediation/AddTask/AddTask';
import HR from 'components/HR/HR';
import Button from 'components/Button/Button';
import { toggleDropdowns } from 'helpers/utils';
import {
  submitEditRemediationPlanFormAction,
  loadSitesAction,
  loadEditRemediationPlanFormAction,
  loadGapsAction,
  loadUsersByOrgAction,
  loadRolesAction,
} from 'store/actions/Actions';
import Spinner from 'components/Spinner/Spinner';
import ActionStatusConstants from 'helpers/ActionStatusConstants';

const Edit = (props) => {
  const [search] = useSearchParams();
  const remediationID = search.get('id');
  const navigate = useNavigate();

  const [state, setState] = useState({
    title: '',
    site: '',
    status: '',
    estimated_cost: '',
    start_date: '',
    end_date: '',
    notes: '',
    regulatory_act: '',
    owner: '',
    locked: 'f',
  });

  const [tasks, setTasks] = useState([]);
  const [stateErrors, setStateErrors] = useState({});
  const [isLoading, setLoading] = useState(true);

  const {
    loadEditRemediationPlanFormAction,
    // UsersReducer,
    submitEditRemediationPlanFormAction,
    GapsReducer,
    loadUsersByOrgAction,
    loadGapsAction,
    SitesReducer,
    loadSitesAction,
    EditRemediationPlanFormReducer,
    UserOrgSiteReducer,
    UsersByOrgReducer,
    RolesReducer,
    loadRolesAction,
    OrgModulesReducer,
  } = props;

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    if (!SitesReducer.sites) {
      loadSitesAction();
    }
  }, [SitesReducer.sites, loadSitesAction]);

  useEffect(() => {
    if (!RolesReducer.roles) {
      loadRolesAction();
    }
  }, [RolesReducer.roles, loadRolesAction]);

  useEffect(() => {
    try {
      if (RolesReducer.roles) {
        loadUsersByOrgAction({
          // site: state.site,
          // department: undefined,
          role: RolesReducer.roles.map((role) => (!role.name.includes('User') ? Number(role.id) : '')).filter((item) => typeof item === 'number'),
        });
      }
    } catch (error) {
      console.log('error', error);
    }
  }, [state.site, RolesReducer.roles]);

  useEffect(() => {
    if (!GapsReducer.gaps) {
      loadGapsAction();
    }
  }, [GapsReducer.gaps, loadGapsAction]);

  useEffect(() => {
    if (!EditRemediationPlanFormReducer.remediation_plans && SitesReducer.sites) {
      loadEditRemediationPlanFormAction({ id: remediationID });
    }

    if (EditRemediationPlanFormReducer.remediation_plans) {
      setState((s) => ({
        ...s,
        ...EditRemediationPlanFormReducer.remediation_plans[0],
      }));
      if (EditRemediationPlanFormReducer.remediation_plans[0].tasks) {
        setTasks((s) => EditRemediationPlanFormReducer.remediation_plans[0].tasks);
      }
    }
  }, [EditRemediationPlanFormReducer.remediation_plans, SitesReducer.sites, loadEditRemediationPlanFormAction, remediationID]);

  useEffect(() => {
    if (
      EditRemediationPlanFormReducer.status === ActionStatusConstants.SUCCESS &&
      SitesReducer.status === ActionStatusConstants.SUCCESS &&
      GapsReducer.status === ActionStatusConstants.SUCCESS &&
      RolesReducer.status === ActionStatusConstants.SUCCESS
    ) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [EditRemediationPlanFormReducer.remediation_plans, SitesReducer.sites, GapsReducer.gaps, RolesReducer.roles]);

  const handleChange = (inputName) => (e) => {
    e.persist();
    setState((s) => ({
      ...s,
      [inputName]: e.target.value,
    }));
    setStateErrors((s) => ({ ...s, [inputName]: '' }));
  };

  const handleRegSelect = (inputName) => (e) => {
    e.persist();
    setState((s) => ({
      ...s,
      [inputName]: e.target.attributes && e.target.attributes.value ? e.target.attributes.value.value : '',
    }));
    setStateErrors((s) => ({ ...s, [inputName]: '', type: '' }));
  };

  const handleSelect = (inputName) => (e) => {
    e.persist();
    setState((s) => ({
      ...s,
      [inputName]: e.target.attributes && e.target.attributes.value ? e.target.attributes.value.value : '',
    }));
    setStateErrors((s) => ({ ...s, [inputName]: '' }));
  };

  const submitRemediationPlan = (e) => {
    const estimatedCost = parseInt(state.estimated_cost, 10);
    if (
      // state.site === "" ||
      state.title === '' ||
      state.regulatory_act === '' ||
      state.start_date === '' ||
      state.end_date === '' ||
      estimatedCost < 0
    ) {
      setStateErrors({
        ...stateErrors,
        // site: state.site === "" ? "Site is required" : "",
        title: state.title === '' ? 'Title is required' : '',
        regulatory_act: state.regulatory_act === '' ? 'Regulatory act is required' : '',
        start_date: state.start_date === '' ? 'Start date is required' : '',
        end_date: state.end_date === '' ? 'End date is required' : '',
        estimated_cost: estimatedCost < 0 && "Estimated cost can't be negative",
      });
    } else {
      e.preventDefault();
      submitEditRemediationPlanFormAction({ ...state, tasks });
    }
  };

  const gapsList = {};
  if (GapsReducer && GapsReducer.gaps) {
    GapsReducer.gaps.forEach((gap) => {
      gapsList[gap.id] = gap.name;
    });
  }

  let usersList = {};
  if (UsersByOrgReducer && UsersByOrgReducer.users) {
    UsersByOrgReducer.users.forEach((user) => {
      usersList[user.id || user.user_id] = user.name || user.email;
    });
  }

  const sitesList = {};
  SitesReducer &&
    SitesReducer.sites &&
    SitesReducer.sites.forEach((site) => {
      sitesList[site.id] = site.name;
    });

  const statusCodes = {
    0: 'Pending',
    1: 'Incomplete',
    2: 'Complete',
    3: 'Closed incomplete',
    4: 'Deactivated incomplete',
  };

  // [OPTIONAL]
  // A way to close dropdowns when clicking on parent if they are open
  const statusDropdownRef = React.createRef();
  const associatedGapDropdownRef = React.createRef();
  const assigneeDropdownRef = React.createRef();
  const ownerDropdownRef = React.createRef();
  const siteRef = React.createRef();
  const regTypeRef = React.createRef();

  const is_admin = (UserOrgSiteReducer.selected && UserOrgSiteReducer.selected.is_admin) || false;

  return (
    <div>
      <PageHeader title="Edit Remediation" />
      {isLoading ? (
        <Spinner />
      ) : (
        <div className={styles['form-wrapper']}>
          <form className={styles.form} onClick={toggleDropdowns([statusDropdownRef, associatedGapDropdownRef, assigneeDropdownRef, ownerDropdownRef, siteRef, regTypeRef])}>
            <FormElement labelText="TITLE" htmlFor="title" smallText="*" errorMessage={stateErrors.title}>
              <textarea
                style={{ padding: '10px', resize: 'vertical' }}
                rows={3}
                name="title"
                disabled={state.locked === 't' || state.status === '4' ? true : false}
                type="text"
                placeholder="Enter Plan Title"
                value={state.title}
                onChange={handleChange('title')}
              />
            </FormElement>
            <FormElement labelText="STATUS" htmlFor="status" errorMessage={stateErrors.status}>
              <Select
                disabled={is_admin || state.locked === 't' || state.status === '4' ? true : false}
                labels={statusCodes}
                dropdownToggleRef={statusDropdownRef}
                value={state.status || 0}
                onClick={handleChange('status')}
              >
                <li value="0">Pending</li>
                <li value="1">Incomplete</li>
                <li value="2">Complete</li>
              </Select>
            </FormElement>
            <FormElement labelText="SITE" htmlFor="site" errorMessage={stateErrors.site || ''}>
              <Select disabled labels={sitesList} value={state.site || 'All Sites'} placeholder="Select Site" onClick={handleSelect('site')} dropdownToggleRef={siteRef}>
                {SitesReducer.sites &&
                  SitesReducer.sites.map((site) => (
                    <li key={`siteid-${site.id}`} data-name={site.name} value={site.id}>
                      {site.name}
                    </li>
                  ))}
              </Select>
            </FormElement>

            <FormElement labelText="PROGRAM" htmlFor="regulatory_act" errorMessage={stateErrors['regulatory_act'] || ''}>
              <Select disabled placeholder="Select program" value={state.regulatory_act} onClick={handleRegSelect('regulatory_act')} dropdownToggleRef={regTypeRef}>
                {OrgModulesReducer &&
                  OrgModulesReducer.data &&
                  OrgModulesReducer.data.map((d) => (
                    <li key={`regid-${d.name.toLowerCase()}`} value={d.name}>
                      {d.name}
                    </li>
                  ))}
              </Select>
            </FormElement>

            <Row>
              <FormElement labelText="START DATE" htmlFor="start_date" smallText="*" errorMessage={stateErrors.start_date}>
                <DateInput disabled={state.locked === 't' || state.status === '4' ? true : false} value={state.start_date} onChange={handleChange('start_date')} />
              </FormElement>
              <FormElement labelText="END DATE" htmlFor="end_date" smallText="*" errorMessage={stateErrors.end_date}>
                <DateInput disabled={state.locked === 't' || state.status === '4' ? true : false} value={state.end_date} onChange={handleChange('end_date')} />
              </FormElement>
            </Row>
            <FormElement labelText="OWNER" htmlFor="owner" info={false} errorMessage={stateErrors.owner}>
              <Select
                placeholder="Select owner"
                labels={usersList}
                disabled={state.locked === 't' || state.status === '4' ? true : false}
                dropdownToggleRef={ownerDropdownRef}
                value={state.owner}
                onClick={handleChange('owner')}
              >
                {/* {UsersReducer && UsersReducer.users && UsersReducer.users.filter(item => item.registered === "t").map(user => <li key={user.email} data-name={user.name || user.email} value={user.id}>{user.name || user.email}</li>)} */}
                {UsersByOrgReducer &&
                  UsersByOrgReducer.users &&
                  UsersByOrgReducer.users
                    .filter((item) => item.active === 't')
                    .map((user) => (
                      <li key={user.email} data-name={user.name || user.email} value={user.id || user.user_id}>
                        {user.name || user.email}
                      </li>
                    ))}
              </Select>
            </FormElement>

            <FormElement labelText="ESTIMATED COST" htmlFor="estimated_cost" errorMessage={stateErrors.estimated_cost}>
              <CostInput disabled={state.locked === 't' || state.status === '4' ? true : false} value={state.estimated_cost} onChange={handleChange('estimated_cost')} />
            </FormElement>
            <AddTask
              regulatory_act={state.regulatory_act}
              allowAdd={state.locked === 't' || state.status === '4' ? false : true}
              setParentErrors={setStateErrors}
              className={styles['add-task']}
              site={state.site !== '' ? state.site : undefined}
              tasks={tasks.map((t) => ({ ...t, locked: state.locked }))}
              setTasks={setTasks}
              dropdownToggleRef={assigneeDropdownRef}
            />
            <FormElement labelText="NOTES" htmlFor="notes">
              <textarea disabled={state.locked === 't' || state.status === '4' ? true : false} className={styles.notes} value={state.notes} onChange={handleChange('notes')} />
            </FormElement>
            <HR className={styles['hr']} />
            <div className={styles['form-options']}>
              <Button text="CANCEL" color="secondary" onClick={() => navigate('/assessments/remediations')} />
              <Button disabled={state.locked === 't' || state.status === '4' ? true : false} text="SAVE" icon="far fa-save" onClick={submitRemediationPlan} />
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  AddRemediationPlanFormReducer: state.AddRemediationPlanFormReducer,
  EditRemediationPlanFormReducer: state.EditRemediationPlanFormReducer,
  GapsReducer: state.GapsReducer,
  UsersReducer: state.UsersReducer,
  AuthReducer: state.AuthReducer,
  SitesReducer: state.SitesReducer,
  UserOrgSiteReducer: state.UserOrgSiteReducer,
  UsersByOrgReducer: state.UsersByOrgReducer,
  RolesReducer: state.RolesReducer,
  OrgModulesReducer: state.OrgModulesReducer,
});

const mapDispatchToProps = {
  submitEditRemediationPlanFormAction,
  loadGapsAction,
  loadUsersByOrgAction,
  loadSitesAction,
  loadRolesAction,
  loadEditRemediationPlanFormAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
