import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useConfirmModal } from 'hooks';
import AppLayoutWrapFull from 'components/AppLayoutWrapFull';
import Container from 'components/Container/Container';
import PageHeader from 'components/PageHeader/PageHeader';
import PageHeaderActions from 'components/PageHeader/components/PageHeaderActions/PageHeaderActions';
import FullDataTable from 'components/DataTable/FullDataTable';
import TableDefIncidents from 'table-defs/TableDefIncidents';
import Spinner from 'components/Spinner/Spinner';

import {
  loadIncidentsAction,
  deleteIncidentAction,
  loadSitesAction,
  approveIncidentAction,
  completeIncidentAction,
  notifyIncidentAction,
  setPaginationAction,
  investigationIncidentAction,
  loadIncidentTypesAction,
  loadAffiliatesAction,
} from 'store/actions/Actions';

import useFilterRows from 'helpers/customHooks/useFilterRows';

import { setSelectedRows, toggleDropdowns, exportAsCSV } from 'helpers/utils';
import { INCIDENTS_PAGE } from 'helpers/constants/DataTableConstants';
import ActionStatusConstants from 'helpers/ActionStatusConstants';

const labels = () => ({
  name: {
    label: 'TITLE',
  },
  description: {
    label: 'DESCRIPTION',
  },
  regulatory_act: {
    label: 'REGULATORY',
  },
  occurred: {
    label: 'OCCURRED',
  },
  discovered: {
    label: 'DISCOVERED',
  },
  reported: {
    label: 'REPORTED',
  },
  siteName: {
    label: 'SITE',
  },
  resolved: {
    label: 'RESOLVED',
  },
  reported_by: {
    label: 'REPORTED BY',
  },
  affiliate: {
    label: 'VENDOR',
  },
  typeText: {
    label: 'TYPE',
  },
  affected: {
    label: 'AFFECTED',
  },
  investigating: {
    label: 'STATUS',
  },
});

function Incidents(props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const DatatableReducer = useAppSelector((state) => state.DatatableReducer);
  const IncidentTypesReducer = useAppSelector((state) => state.IncidentTypesReducer);
  const IncidentsReducer = useAppSelector((state) => state.IncidentsReducer);
  const SitesReducer = useAppSelector((state) => state.SitesReducer);
  const UserOrgSiteReducer = useAppSelector((state) => state.UserOrgSiteReducer);
  const AffiliatesReducer = useAppSelector((state) => state.AffiliatesReducer);

  const [state, setState] = useState({
    selectedRows: undefined,
    selected_sites: [],
  });
  const [isLoading, setLoading] = useState(true);
  const [sortKey, setSortKey] = useState(DatatableReducer.datatable[INCIDENTS_PAGE]?.sortKey);
  const [sortOrder, setSortOrder] = useState(DatatableReducer.datatable[INCIDENTS_PAGE]?.sortOrder);

  const {
    loadSitesAction,
    completeIncidentAction,
    loadIncidentsAction,
    approveIncidentAction,
    setPaginationAction,
    loadIncidentTypesAction,
    investigationIncidentAction,
    deleteIncidentAction,
    notifyIncidentAction,
    loadAffiliatesAction,
  } = props;

  const [showConfirmModal, setShowConfirmModal, renderConfirmModal] = useConfirmModal();

  useEffect(() => {
    loadAffiliatesAction();
  }, [loadAffiliatesAction]);

  useEffect(() => {
    if (!SitesReducer.sites) {
      loadSitesAction();
    }
  }, [SitesReducer.sites, loadSitesAction]);

  useEffect(() => {
    if (!IncidentTypesReducer.incidentTypes) {
      loadIncidentTypesAction();
    }
  }, [IncidentTypesReducer.incidentTypes, loadIncidentTypesAction]);

  useEffect(() => {
    if (!IncidentsReducer.incidents && SitesReducer.sites && IncidentTypesReducer.incidentTypes) {
      loadIncidentsAction();
    }
  }, [IncidentsReducer.incidents, loadIncidentsAction, IncidentTypesReducer.incidentTypes, SitesReducer.sites]);

  useEffect(() => {
    if (
      IncidentsReducer.status === ActionStatusConstants.SUCCESS &&
      SitesReducer.status === ActionStatusConstants.SUCCESS &&
      DatatableReducer.status === ActionStatusConstants.SUCCESS
    ) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [IncidentsReducer.incidents, SitesReducer.sites, DatatableReducer.datatable]);

  // ##############################################

  const [activeReg, setActiveReg] = useState('');

  const noFilters = {
    text: '',
    type: '',
    'last-action': '',
    status: '',
    role: '',
    site: '',
    start: '',
    end: '',
    regulatory_act: activeReg,
    selected_sites: [],
    dateField: 'occurred',
  };

  useEffect(() => {
    if (DatatableReducer?.datatable?.dashboard?.activeReg) {
      setActiveReg(DatatableReducer.datatable.dashboard.activeReg);
    }
  }, [DatatableReducer.datatable]);

  const [filters, setFilters] = useState(DatatableReducer?.datatable?.incidents?.filters || noFilters);

  useEffect(() => {
    if (activeReg) {
      setFilters({
        ...DatatableReducer?.datatable?.incidents?.filters,
        regulatory_act: activeReg,
      });
    }
  }, [activeReg]);

  const [selectedIncidentIds, setSelectedIncidentIds] = useState(DatatableReducer?.datatable?.incidents?.selectedIds || []);

  useEffect(() => {
    dispatch({
      type: 'SET_DATATABLE',
      payload: {
        datatable: {
          incidents: {
            selectedIds: selectedIncidentIds,
            filters,
            selectedRows: state.selectedRows,
            selected_sites: state.selected_sites,
            rowsPerPage: DatatableReducer?.datatable?.incidents?.rowsPerPage || 10,
            currentPage: DatatableReducer?.datatable?.incidents?.currentPage || 0,
            sortKey,
            sortOrder,
          },
        },
      },
    });
  }, [selectedIncidentIds, filters, state, sortKey, sortOrder]);

  const setFilter = (filterName) => (e) => {
    setFilters({
      ...filters,
      [filterName]: e.target.type === 'checkbox' ? e.target.checked : e.target.value || e?.target?.attributes?.value?.value || '',
    });
    setPaginationAction({ dataType: INCIDENTS_PAGE, currentPage: 0 });
  };

  const setDateFilter = (filterName) => (value) => {
    setFilters({
      ...filters,
      [filterName]: value,
    });
    setPaginationAction({ dataType: INCIDENTS_PAGE, currentPage: 0 });
  };

  const filteredRows = useFilterRows(filters, IncidentsReducer.incidents);

  // ##############################################

  const onChangeRowSelect = ({ selectedRows }) => {
    if (selectedRows !== state.selectedRows) {
      setState((s) => ({
        ...s,
        selectedRows,
      }));
    }
  };

  const is_officer = UserOrgSiteReducer?.selected?.is_officer || false;
  const is_super = UserOrgSiteReducer?.selected?.is_super || false;
  const is_coach = UserOrgSiteReducer?.selected?.is_coach || false;

  const chooseActionDropdownRef = React.createRef();
  const statusDropdownRef = React.createRef();
  const regDropdownRef = React.createRef();

  const openAddNewIncident = () => navigate('/maintain/incidents/add-new-incident');

  const handleDeleteSelected = () => {
    const idCount = selectedIncidentIds.length;
    if (idCount > 0) {
      setShowConfirmModal({
        title: 'Delete selected incidents',
        question: idCount === 1 ? 'Are you sure you want to delete the selected incident?' : `Are you sure you want to delete ${idCount} selected incidents?`,
        confirmAction: () => {
          deleteIncidentAction(selectedIncidentIds);
        },
      });
    } else {
      setShowConfirmModal({
        type: 'simple',
        title: 'Nothing selected',
        question: 'Please select incidents to be deleted.',
      });
    }
  };

  const handleNotifySelected = () => {
    const idCount = selectedIncidentIds.length;
    if (idCount > 0) {
      setShowConfirmModal({
        title: 'Notify Users',
        question:
          idCount === 1 ? 'Are you sure you want to notify the user about the selected incident?' : `Are you sure you want to notify users about ${idCount} selected incidents?`,
        confirmAction: () => {
          notifyIncidentAction(selectedIncidentIds);
        },
      });
    } else {
      setShowConfirmModal({
        type: 'simple',
        title: 'Nothing selected',
        question: 'Please select incidents.',
      });
    }
  };

  const handleExportSelected = () => {
    const data = IncidentsReducer.incidents.filter((item) => selectedIncidentIds.map((i) => i.id).includes(item.id));
    const updatedData = data.map((item) => {
      const affiliate = AffiliatesReducer && AffiliatesReducer.affiliates.find((affiliate) => affiliate.id === item.affiliate);
      let investigatingStr = '';
      switch (item.investigating) {
        case '0': {
          investigatingStr = 'Not Verified';
          break;
        }
        case '1': {
          investigatingStr = 'Under Investigation';
          break;
        }
        case '2': {
          investigatingStr = 'Verified';
          break;
        }
        case '3': {
          investigatingStr = 'Completed';
          break;
        }
        default: {
          investigatingStr = 'Unknown';
          break;
        }
      }
      return {
        ...item,
        affected: item.affected ? 'Yes' : 'No',
        investigating: investigatingStr,
        affiliate: affiliate && affiliate.name,
      };
    });
    exportAsCSV(updatedData, labels, 'incidents.csv');
  };

  return (
    <AppLayoutWrapFull onClick={toggleDropdowns([chooseActionDropdownRef, statusDropdownRef, regDropdownRef])}>
      <PageHeader showStats>
        <PageHeaderActions
          primaryButtonText={!is_coach && 'ADD INCIDENT'}
          primaryButtonHandler={!is_coach && openAddNewIncident}
          searchHandler={(e) => {
            setFilters({ ...filters, text: e.target.value || '' });
            setPaginationAction({ dataType: INCIDENTS_PAGE, currentPage: 0 });
          }}
          searchValue={filters.text}
          chooseActionDropdownRef={chooseActionDropdownRef}
          chooseAction={
            !is_coach
              ? is_officer || is_super
                ? [
                    {
                      actionName: 'Under Investigation Selected',
                      actionHandler: () => {
                        if (selectedIncidentIds.length > 0) {
                          investigationIncidentAction(selectedIncidentIds);
                        }
                      },
                    },
                    {
                      actionName: 'Verify Selected',
                      actionHandler: () => {
                        if (selectedIncidentIds.length > 0) {
                          approveIncidentAction(selectedIncidentIds);
                        }
                      },
                    },
                    {
                      actionName: 'Complete Selected',
                      actionHandler: () => {
                        if (selectedIncidentIds.length > 0) {
                          completeIncidentAction(selectedIncidentIds);
                        }
                      },
                    },
                    {
                      actionName: 'Notify Selected',
                      actionHandler: () => handleNotifySelected(),
                    },
                    {
                      actionName: 'Export Selected',
                      actionHandler: () => handleExportSelected(),
                    },
                    {
                      actionName: 'Delete Selected',
                      actionHandler: () => handleDeleteSelected(),
                    },
                  ]
                : [
                    {
                      actionName: 'Under Investigation Selected',
                      actionHandler: () => {
                        if (selectedIncidentIds.length > 0) {
                          investigationIncidentAction(selectedIncidentIds);
                        }
                      },
                    },
                    {
                      actionName: 'Verify Selected',
                      actionHandler: () => {
                        if (selectedIncidentIds.length > 0) {
                          approveIncidentAction(selectedIncidentIds);
                        }
                      },
                    },
                    {
                      actionName: 'Notify Selected',
                      actionHandler: () => handleNotifySelected(),
                    },
                  ]
              : undefined
          }
          site={{
            onClick: (e) => {
              if (e.target.type === 'checkbox') {
                let selected_sites = [...filters.selected_sites];
                if (e.target.checked) {
                  selected_sites.push(e.target.value);
                } else {
                  selected_sites = selected_sites.filter((site) => site !== e.target.value);
                }
                setFilters({
                  ...filters,
                  site: selected_sites.length > 0 ? `Sites (${selected_sites.length})` : 'Site',
                  // site: "Site",
                  selected_sites,
                });
                setPaginationAction({
                  dataType: INCIDENTS_PAGE,
                  currentPage: 0,
                });
              }
            },
            sites: SitesReducer.sites
              ? SitesReducer.sites.map((site) => ({
                  id: site.id,
                  siteName: site.name,
                  value: !!filters.selected_sites.includes(site.id),
                }))
              : [],
          }}
          // siteDropdownRef={siteDropdownRef}
          status={{
            onChange: setFilter('status'),
            options: ['All', 'Not Verified', 'Under Investigation', 'Verified', 'Completed'],
          }}
          statusDropdownRef={statusDropdownRef}
          reg={{
            onChange: setFilter('regulatory_act'),
            options: ['All regulatory', 'HIPAA', 'OSHA'],
          }}
          regDropdownRef={regDropdownRef}
          dateRange={{
            onChangeStart: setDateFilter('start'),
            onChangeEnd: setDateFilter('end'),
          }}
          filters={filters}
          resetFilters={() => {
            setFilters(noFilters);
            setPaginationAction({ dataType: INCIDENTS_PAGE, currentPage: 0 });
          }}
        />
      </PageHeader>
      {isLoading ? (
        <Spinner />
      ) : (
        <Container wide fluid>
          {IncidentsReducer.incidents && (
            <FullDataTable
              rowsData={filteredRows}
              definitions={TableDefIncidents}
              dataType={INCIDENTS_PAGE}
              setSortKey={setSortKey}
              setSortOrder={setSortOrder}
              onChangeRowSelect={({ selectedRows }) => onChangeRowSelect({ selectedRows })}
              customTableProps={
                is_officer || is_super
                  ? {
                      selectedRows: state.selectedRows || selectedIncidentIds,
                      viewIncident: (id) => {
                        navigate(`/maintain/incidents/edit?id=${id}`);
                      },
                      invetiagation: (id) => {
                        investigationIncidentAction([{ id }]);
                      },
                      approve: (id) => {
                        approveIncidentAction([{ id }]);
                      },
                      complete: (id) => {
                        completeIncidentAction([{ id }]);
                      },
                      notify: (id) => {
                        setShowConfirmModal({
                          title: 'Notify User',
                          question: 'Are you sure you want to notify user about the incident?',
                          confirmAction: () => {
                            notifyIncidentAction([{ id }]);
                          },
                        });
                      },
                      delete: (id) => {
                        setShowConfirmModal({
                          title: 'Delete the incident',
                          question: 'Are you sure you want to remove the incident?',
                          confirmAction: () => {
                            deleteIncidentAction([{ id }]);
                          },
                        });
                      },
                    }
                  : {
                      selectedRows: state.selectedRows,
                      viewIncident: (id) => {
                        navigate(`/maintain/incidents/edit?id=${id}`);
                      },
                      invetiagation: !is_coach
                        ? (id) => {
                            investigationIncidentAction([{ id }]);
                          }
                        : undefined,
                      approve: !is_coach
                        ? (id) => {
                            approveIncidentAction([{ id }]);
                          }
                        : undefined,
                      notify: !is_coach
                        ? (id) => {
                            setShowConfirmModal({
                              title: 'Notify User',
                              question: 'Are you sure you want to notify user about the incident?',
                              confirmAction: () => {
                                notifyIncidentAction([{ id }]);
                              },
                            });
                          }
                        : undefined,
                    }
              }
              onRowClick={function onRowClick(e) {
                if (e.currentTarget.classList.contains('col-actions') || e.currentTarget.classList.contains('col-row-selector')) {
                  return;
                }
                navigate(`/maintain/incidents/edit?id=${this.props.rowData.id}`);
              }}
              setSelectedRows={setSelectedRows(['id'], setSelectedIncidentIds)}
            />
          )}
          {showConfirmModal && renderConfirmModal(showConfirmModal, setShowConfirmModal)}
        </Container>
      )}
    </AppLayoutWrapFull>
  );
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  loadSitesAction,
  loadIncidentTypesAction,
  loadIncidentsAction,
  approveIncidentAction,
  setPaginationAction,
  investigationIncidentAction,
  completeIncidentAction,
  notifyIncidentAction,
  deleteIncidentAction,
  loadAffiliatesAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Incidents);
