import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useParams } from 'react-router-dom';

import styles from './Editor.module.scss';

import MainLayout from 'v2/layouts/MainLayout/MainLayout';
import { ONLY_OFFICE_JS } from 'helpers/ApiEndpointConstants';
import Button from 'components/Button/Button';
import SaveAsModal from '../SaveAsModal/SaveAsModal';

import { useAppDispatch, useAppSelector } from 'hooks';
import { loadDocumentAction, editDocumentAction, initializeDocumentAction } from 'store/actions/Actions';

const Editor = () => {
  const dispatch = useAppDispatch();
  const document = useAppSelector((state) => state.DocumentReducer.document);
  const userData = useAppSelector((state) => state.AuthReducer.userData);
  const UserOrgSiteReducer = useAppSelector((state) => state.UserOrgSiteReducer);

  const [state] = useState([]);
  const [showSaveAsModal, setSaveAsModal] = useState(false);

  const is_admin = UserOrgSiteReducer && UserOrgSiteReducer.selected && UserOrgSiteReducer.selected.is_admin ? true : false;

  // @ts-ignore
  const [isEditorLoaded, setIsEditorLoaded] = useState(state.parentFolder);
  var docEditor;

  const { id, mode } = useParams();

  useEffect(() => {
    //inject only office widget javascript
    const script = window.document.createElement('script');
    script.src = ONLY_OFFICE_JS;
    script.async = false;

    window.document.body.appendChild(script);

    script.onload = function () {
      setIsEditorLoaded(true);
    };

    return () => {
      window.document.body.removeChild(script);
      // if (mode === 'edit' && document){
      //     console.log("CLEANING");
      //     cleanupDocumentAction({id: document.id});
      // }
    };
  }, []);

  useEffect(() => {
    if (mode === 'edit') {
      dispatch(editDocumentAction({ id: id }));
    } else {
      dispatch(loadDocumentAction({ id: id, unapproved: true }));
    }
  }, [id, mode]);

  var onAppReady = function () {
    console.log('Document editor ready');
  };

  var isDocumentChanged = false;
  var onDocumentStateChange = function (event) {
    var title = window.document.title.replace(/\*$/g, '');
    window.document.title = title + (event.data ? '*' : '');
    console.log('SAVE');
    if (event.data) {
      isDocumentChanged = true;
      // console.log("The document changed");
      // console.log("Document id", document.id);
      //initializeDocumentAction
    } else {
      // console.log("Changes are collected on document editing service");
      if (isDocumentChanged && mode === 'edit') {
        dispatch(initializeDocumentAction({ id: document.id }));
      }
    }
  };

  var onRequestEditRights = function () {
    window.location.href = window.location.href.replace(RegExp('action=view\\&?', 'i'), '');
  };

  var onError = function (event) {
    if (event) console.error(event.data);
  };

  var onOutdatedVersion = function (event) {
    // @ts-ignore
    window.location.reload(true);
  };

  var onRequestRestore = function (event) {
    // var url = event.data.url;
    var version = event.data.version;
    docEditor.refreshHistory({
      currentVersion: version,
      history: document.history.history,
    });
  };

  var getFolderIcon = function () {
    return (
      <div className={styles['saveas']}>
        {is_admin ? (
          <div className={styles['path']}>
            <a href="/documents">
              <i className={`fal fa-folder-open ${styles['up']}`}></i>
            </a>{' '}
            {document.path}
          </div>
        ) : (
          <div className={styles['path']}></div>
        )}
        {mode === 'edit' && <Button onClick={() => setSaveAsModal(true)} className={styles.button} text="Save as new document" iconEnd={`${styles['button-arrow']}`} />}{' '}
      </div>
    );
  };

  useEffect(() => {
    if (document) {
      let edit = mode === 'edit' || mode === 'review';

      let config = document.config;

      config['height'] = '1000px';
      config['document']['permissions']['comment'] = edit;
      config['document']['permissions']['edit'] = edit;
      config['document']['permissions']['fillForms'] = edit;
      config['document']['permissions']['modifyFilter'] = edit;
      config['document']['permissions']['modifyContentControl'] = edit;
      config['document']['permissions']['review'] = edit;

      config['editorConfig']['user']['id'] = userData.id;
      config['editorConfig']['user']['name'] = userData.name;

      if (document.history && document.historyData) {
        config.events = {
          onAppReady: onAppReady,
          onDocumentStateChange: onDocumentStateChange,
          onRequestEditRights: onRequestEditRights,
          onError: onError,
          onOutdatedVersion: onOutdatedVersion,
          onRequestRestore: onRequestRestore,
        };
        config.events['onRequestHistory'] = function () {
          docEditor.refreshHistory(document.history);
        };
        config.events['onRequestHistoryData'] = function (event) {
          var ver = event.data;
          docEditor.setHistoryData(document.historyData[ver]);
        };
        config.events['onRequestHistoryClose'] = function () {
          window.document.location.reload();
        };
      }

      if (isEditorLoaded) {
        // eslint-disable-next-line
        docEditor = new window.DocsAPI.DocEditor('editor', config);
      }
    }
  }, [document, mode, isEditorLoaded]);

  return (
    <MainLayout>
      {document && getFolderIcon()}
      <div id="editor" />
      {showSaveAsModal && ReactDOM.createPortal(<SaveAsModal document={document} setShowModal={setSaveAsModal} />, document.querySelector('#modal-root'))}
    </MainLayout>
  );
};

export default Editor;
