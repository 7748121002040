import { call, put, delay, takeEvery, select } from 'redux-saga/effects';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { actionApiCall } from 'helpers/api/ApiCalls';
import * as sagaConstants from 'helpers/constants/SagasConstants';

const getSelected = (state) => state.UserOrgSiteReducer.selected;

function* updateOrgSaga({ payload }) {
  try {
    const selected = yield select(getSelected);

    const apiResponse = yield call(actionApiCall, {
      actionType: ActionTypeConstants.UPDATE_ORG,
      data: {
        id: selected.org,
        name: payload.company_name,
        address_1: payload.address_1,
        address_2: payload.address_2,
        city: payload.city,
        region: payload.region,
        country_code: payload.country_code,
        postal_code: payload.postal_code,
        active: true,
        reseller: 1,
        acts: payload.acts ? payload.acts : null,
        site_limit: Number(payload.site_limit || 1),
        coach_id: payload.coach_id,
        sso_enabled: payload.sso_enabled || false,
      },
    });

    if (apiResponse.res.data.response === 200) {
      yield put({
        type: ActionTypeConstants.UPDATE_ORG_SUCCESS,
        payload,
      });
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: apiResponse.res.data.message || `Organization details ${sagaConstants.SUCCESSFULLY_UPDATED}`,
          color: 'success',
        },
      });
      yield put({
        type: ActionTypeConstants.LOAD_ORG_SITE_DEPT,
      });
      yield delay(10000);
      yield put({ type: ActionTypeConstants.HIDE_ALERT });
    } else {
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: apiResponse.res.data.message || `${sagaConstants.ISSUE_OCCURED} updating the organization`,
          color: 'danger',
        },
      });
      yield delay(10000);
      yield put({ type: ActionTypeConstants.HIDE_ALERT });
    }
  } catch (err) {
    yield put({
      type: ActionTypeConstants.SHOW_ALERT,
      payload: {
        message: `${sagaConstants.SERVER_ERROR}`,
        color: 'danger',
      },
    });
    yield delay(10000);
    yield put({ type: ActionTypeConstants.HIDE_ALERT });
  }
}

export default function* () {
  yield takeEvery(ActionTypeConstants.UPDATE_ORG, updateOrgSaga);
}
