import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import Modal from 'components/Modal/Modal';
import FormElement from 'components/FormElement/FormElement';
import Select from 'components/Select/Select';
import Checkbox from 'components/Checkbox/Checkbox';
import { DateInput } from 'components/DateInput/DateInput';

import { Button } from 'components/Button/Button';
import { loadActsAction, loadSitesAction, createAuditAction } from 'store/actions/Actions';
import { useAppSelector } from 'hooks';

import styles from './AddAuditModal.module.scss';

function AddAuditModal(props) {
  const { loadSitesAction, loadActsAction, setShowModal, actDropdownRef, siteDropdownRef } = props;

  const ActsReducer = useAppSelector((state) => state.ActsReducer);
  const SitesReducer = useAppSelector((state) => state.SitesReducer);
  const OrgModulesReducer = useAppSelector((state) => state.OrgModulesReducer);
  const UserOrgSiteReducer = useAppSelector((state) => state.UserOrgSiteReducer);

  const is_site_admin = (UserOrgSiteReducer.selected && UserOrgSiteReducer.selected.is_site_admin) || false;
  const isSiteSelected = !!(UserOrgSiteReducer.selected.site && UserOrgSiteReducer.selected.site !== '0' && UserOrgSiteReducer.selected.site !== '');

  const defaultReg = OrgModulesReducer?.data?.map((r) => r.name) || [];
  const [state, setState] = useState({
    regulatory_act: defaultReg[0] || '',
    act: '',
    site: is_site_admin || isSiteSelected ? UserOrgSiteReducer.selected.site : 0,
    name: '',
    org_wide: false,
    frequency: '0',
    lock_tasks: false,
    launch_date: moment().format('YYYY-MM-DD'),
  });

  const [stateErrors, setStateErrors] = useState({
    act: '',
    site: '',
    regulatory_act: '',
    audit_name: '',
    launch_date: '',
  });

  useEffect(() => {
    if (!SitesReducer.sites) {
      loadSitesAction();
    }
  }, [SitesReducer.sites, loadSitesAction]);

  useEffect(() => {
    if (!ActsReducer.acts) {
      loadActsAction();
    }
  }, [ActsReducer.acts, loadActsAction]);

  const isDeviceAudit = ActsReducer.acts.find((a) => a.id === `${state.act}`);
  const auditType = isDeviceAudit?.type || '';

  const addModal = (e) => {
    if (
      state.name === '' ||
      state.act === '' ||
      (state.site === '' && auditType !== 'device' && auditType !== 'cloud' && auditType !== 'inventory') ||
      state.regulatory_act === '' ||
      (!state.launch_date && auditType !== 'device')
    ) {
      setStateErrors((s) => ({
        ...s,
        site: state.site === '' ? 'Site is required' : '',
        regulatory_act: state.regulatory_act === '' ? 'Regulatory Act is required' : '',
        act: state.act === '' ? 'Audit is required' : '',
        launch_date: !state.launch_date ? 'Launch date is required' : '',
      }));
    } else {
      props.createAuditAction({
        name: state.name,
        act: state.act,
        site: state.site,
        org_wide: auditType === 'device' ? true : state.org_wide,
        configuration: {
          frequency: state.frequency,
          lock_tasks: state.frequency === '0' ? false : state.lock_tasks,
        },
        launch_date: state.launch_date,
        assessments: true,
      });
      props.setShowModal(false);
    }
  };

  const handleChange = (inputName) => (e) => {
    e.persist();
    setState((s) => ({
      ...s,
      [inputName]: e.target.value,
    }));
    setStateErrors((s) => ({ ...s, [inputName]: '' }));
  };

  const handleActSelect = (e) => {
    e.persist();
    setState((s) => ({
      ...s,
      act: e.target.value,
      name: e.target.textContent,
    }));
    setStateErrors((s) => ({
      ...s,
      act: '',
      site: '',
      regulatory_act: '',
    }));
  };

  const handleSiteSelect = (e) => {
    e.persist();
    setState((s) => ({
      ...s,
      site: e.target.value,
    }));
    setStateErrors((s) => ({ ...s, site: '' }));
  };

  const handleRegSelect = (inputName) => (e) => {
    e.persist();
    setState((s) => ({
      ...s,
      [inputName]: e.target.attributes && e.target.attributes.value ? e.target.attributes.value.value : '',
      act: '',
      site: is_site_admin ? UserOrgSiteReducer.selected.site : 0,
      name: '',
    }));
    setStateErrors((s) => ({ ...s, [inputName]: '', type: '' }));
  };

  const sitesList = {};
  sitesList[0] = 'All';
  SitesReducer.sites.forEach((site) => {
    sitesList[site.id] = site.name;
  });

  const actsList = {};
  ActsReducer.acts.forEach((act) => {
    actsList[act.id] = act.name;
  });

  const regTypeRef = React.createRef();

  const frequencyListLabels = {
    0: 'One Time',
    1: 'Monthly',
    3: 'Quarterly',
    6: 'Biannually',
    12: 'Annually',
  };

  const handleCheckbox = (inputName) => (e) => {
    e.persist();
    setState((s) => ({
      ...s,
      [inputName]: e.target.checked,
    }));
  };

  return (
    <Modal className={styles.modal} setShowModal={setShowModal}>
      <Modal.Heading>Add Assessment</Modal.Heading>
      <Modal.Content>
        {ActsReducer.acts && (
          <>
            <FormElement labelText="PROGRAM" htmlFor="regulatory_act" errorMessage={stateErrors.regulatory_act || ''}>
              <Select placeholder="Select program" value={state.regulatory_act} onClick={handleRegSelect('regulatory_act')} dropdownToggleRef={regTypeRef}>
                {OrgModulesReducer?.data?.map((d) => (
                  <li key={`regid-${d.name.toLowerCase()}`} value={d.name}>
                    {d.name}
                  </li>
                ))}
              </Select>
            </FormElement>

            <br />

            <FormElement className={styles['regulatory-act']} labelText="ASSESSMENT" smallText="*" htmlFor="regulatory-act" errorMessage={stateErrors.act}>
              <Select value={state.act} placeholder="Select assessment" onClick={handleActSelect} labels={actsList} dropdownToggleRef={actDropdownRef}>
                {ActsReducer?.acts
                  ?.filter((a) => a.regulatory_act === state.regulatory_act)
                  .filter((b) => (b.type !== "inventory" && b.type !== "cloud" && b.type !== "device"))
                  .map((act) => (
                    <li key={`actid-${act.id}`} value={act.id}>
                      {act.name}
                    </li>
                  ))}
              </Select>
            </FormElement>

            <br />

            {state.act && auditType !== 'device' && (
              <>
                <FormElement className={styles.site} labelText="SITE" htmlFor="site" errorMessage={stateErrors.site}>
                  <Select value={state.site} placeholder="Site" onClick={handleSiteSelect} labels={sitesList} dropdownToggleRef={siteDropdownRef}>
                    {!is_site_admin && !isSiteSelected && (
                      <li key="siteid-all" data-name="All" value={0}>
                        All
                      </li>
                    )}
                    {SitesReducer?.sites
                      ?.filter((site) => site.active === 't')
                      .map((site) => (
                        <li key={`siteid-${site.id}`} value={site.id}>
                          {site.name}
                        </li>
                      ))}
                  </Select>
                </FormElement>
                <br />
              </>
            )}
          </>
        )}

        {state.site === 0 && auditType !== 'device' && auditType !== '' && (
          <>
            <FormElement
              className={styles.siteAll}
              // labelText="SITE"
              htmlFor="site"
              errorMessage={stateErrors.site}
            >
              <Checkbox
                onChange={() => setState((s) => ({ ...s, org_wide: !state.org_wide }))}
                checked={state.org_wide}
                className={styles.complete}
                labelText="Organization-wide"
              />
            </FormElement>
            <br />
          </>
        )}

        {auditType !== 'device' && auditType !== 'cloud' && auditType !== 'inventory' && auditType !== '' && (
          <>
            <div className={styles.holder}>
              <FormElement labelText="FREQUENCY" htmlFor="frequency">
                <Select value={state.frequency} onClick={handleChange('frequency')} labels={frequencyListLabels}>
                  {Object.entries(frequencyListLabels).map((frequency) => (
                    <li key={frequency[0]} value={frequency[0]}>
                      {frequency[1]}
                    </li>
                  ))}
                </Select>
              </FormElement>

              <FormElement labelText="LAUNCH DATE" htmlFor="launch_date" className={styles['launch-date']} errorMessage={stateErrors.launch_date}>
                <DateInput onChange={handleChange('launch_date')} value={state.launch_date} />
              </FormElement>
            </div>

            <br />

            <Checkbox
              labelText="Lock tasks"
              disabled={state.frequency === '0'}
              checked={state.frequency === '0' ? false : state.lock_tasks}
              onChange={handleCheckbox('lock_tasks')}
              className={styles.complete}
            />
          </>
        )}
      </Modal.Content>

      <Modal.Footer>
        <Button text="CANCEL" color="secondary" onClick={() => props.setShowModal(false)} />
        <Button text="CREATE" className={styles.create} onClick={(e) => addModal(e)} />
      </Modal.Footer>
    </Modal>
  );
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  loadActsAction,
  loadSitesAction,
  createAuditAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddAuditModal);
