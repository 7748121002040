import React from 'react';
import moment from 'moment';
// import { Link } from 'react-router-dom';
// import HR from 'components/HR/HR';

// import Accordion from 'components/Accordion/Accordion';
// import EditEvidence from 'views/Achieve/Tasks/components/Evidence/EditEvidence';
// import EditNote from 'views/Achieve/Tasks/Edit/EditNote/EditNote';

import styles from './DashboardViews.module.scss';

function TaskView(props) {
  const { task } = props;
  return (
    <div className={styles.question}>
      <div className={styles.task_holder}>
        <div>
          <div>Task name:</div>
          <div className={styles.title}>{task.task}</div>
        </div>

        {task.notes && (
          <div>
            <div>Notes:</div>
            <div className={styles.notes}>{task.notes}</div>
          </div>
        )}

        <div>
          <div>Requested date:</div>
          <div>{task.assigned ? moment(task.assigned).format('MM/DD/YYYY') : ''}</div>
        </div>

        <div>
          <div>Type:</div>
          <div>Miscellaneous Task</div>
        </div>

        {task.siteName && (
          <div>
            <div>Site:</div>
            <div>{task.siteName}</div>
          </div>
        )}

        {/* {(task.subtask === 'remediation' && task.subtask_data?.remediation && task?.subtask_data?.audit_response) && (
          <>
            <HR />
        
            <h2 className={styles.title}>Related Audit Information</h2>

            <div>
              <div>Audit:</div>
              <div className={styles.notes}>{task.subtask_data?.parent_question?.audit_name}</div>
            </div>

            <div>
              <div>Audit Question:</div>
              <div className={styles.notes}>
                {task?.subtask_data?.parent_question?.id}: {task?.subtask_data?.parent_question?.question} <Link className={styles['icon-link']} to={`/achieve/audits/audit?id=${task?.subtask_data?.audit}&questionId=${task?.subtask_data?.parent_question?.id}`}><i className="fa-solid fa-up-right-from-square"></i></Link>
              </div>
            </div>

            <div>
              <div>Remediation:</div>
              <div className={styles.notes}>
                {task?.subtask_data?.parent_remediation?.control} {task?.subtask_data?.parent_remediation?.title} <Link className={styles['icon-link']} to={`/achieve/remediation/edit?id=${task?.subtask_data?.remediation}`}><i className="fa-solid fa-up-right-from-square"></i></Link>
              </div>
            </div>

            <div style={{ padding: '0', width: '100%' }}>
              <div style={{width: '100%'}}>
                <Accordion
                  title={
                    <span>
                      ATTACH EVIDENCE
                      <span
                        style={{
                          color: 'grey',
                          fontSize: '1.3rem',
                          marginLeft: '10px',
                        }}
                      >
                        (Optional)
                      </span>
                    </span>
                  }
                  extraTitle={`FILES: ${task.evidence?.length || 0}`}
                  content={
                    <EditEvidence
                      allowAdd={true}
                      taskId={task.id}
                      files={task && task.evidence ? task.evidence : []}
                      isFinalized={false}
                      showTitle={false}
                    />
                  }
                />
              </div>
            </div>

            <div style={{ padding: '0', width: '100%' }}>
              <div style={{width: '100%'}}>
                <Accordion
                  title={
                    <span>
                      ADD NOTES
                      <span
                        style={{
                          color: 'grey',
                          fontSize: '1.3rem',
                          marginLeft: '10px',
                        }}
                      >
                        (Optional)
                      </span>
                    </span>
                  }
                  extraTitle={`NOTES: ${task.task_notes?.length || 0}`}
                  content={
                    <EditNote allowAdd={true} taskId={task.id} notes={task.task_notes} showTitle={false} />
                  }
                />
              </div>
            </div>
          </>
        )} */}
      </div>
    </div>
  );
}

export default TaskView;
