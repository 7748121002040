import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'components/Modal/Modal';
import { Button } from 'components/Button/Button';
import styles from './ConfirmationModal.module.scss';

/**
 * example usage:

  1. import props and render function
  import { useConfirmModal } from 'hooks';

  2. define useState
  const [showConfirmModal, setShowConfirmModal, renderConfirmModal] = useConfirmModal();

  3. add modal to the view
  {showConfirmModal && renderConfirmModal(showConfirmModal, setShowConfirmModal)}

  4. call setShow
  setShowConfirmModal({
    title: 'Approve Affiliate',
    question: 'Are you sure you want to approve the affiliate?',
    confirmAction: () => {
      props.approveAffiliateAction({ items: [id] });
    },
  });

*/

export interface IDataProps {
  type?: string;
  title?: string;
  color?: string;
  question?: string | React.ReactNode;
  padding?: string;
  maxHeight?: string;
  warning?: string;
  confirmAction?: () => void;
}

interface IPropsConfirmModal {
  data: IDataProps;
  setShowModal?: (e) => void;
}

const defaultProps = {
  setShowModal: () => false,
};

function ConfirmationModal(props: IPropsConfirmModal) {
  const { data, setShowModal } = props;
  const handleConfirm = () => {
    data.confirmAction();
    setShowModal(false);
  };

  const isSimple = data.type === 'simple' || !data.confirmAction;

  const modalButtons = <Button text="OK" color={data.color || 'danger'} className={styles.create} onClick={() => setShowModal(false)} />;

  const confirmButtons = (
    <>
      <Button text="CANCEL" color="secondary" onClick={() => setShowModal(false)} />
      <Button text="CONFIRM" color={data.color || 'danger'} className={styles.create} onClick={handleConfirm} />
    </>
  );

  return (
    <Modal className={styles.modal} setShowModal={setShowModal}>
      <div>
        <p className={styles['modal-heading']}>{data.title}</p>

        <div
          className={styles['modal-content']}
          style={{
            padding: data.padding || '50px',
            maxHeight: data.maxHeight || '400px',
          }}
        >
          {data.warning && <p className={styles['modal-warning']}>{data.warning}</p>}
          {data.question}
        </div>

        <div className={styles['modal-footer']}>{isSimple ? modalButtons : confirmButtons}</div>
      </div>
    </Modal>
  );
}

ConfirmationModal.defaultProps = defaultProps;

export default ConfirmationModal;

export const renderConfirmModal = (data, setShowModal) =>
  ReactDOM.createPortal(<ConfirmationModal data={data} setShowModal={setShowModal} />, document.querySelector('#modal-root'));
