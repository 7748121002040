import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import AppLayoutWrapFull from 'components/AppLayoutWrapFull';
import Container from 'components/Container/Container';
import { Tabs } from 'components/Tabs/Tabs';

import FormElement from 'components/FormElement/FormElement';
import Select from 'components/Select/Select';

import { loadDashboardTasksAction, loadUserStatsAction, loadSitesAction, loadStatsAction } from 'store/actions/Actions';

import Spinner from 'components/Spinner/Spinner';
import ActionStatusConstants from 'helpers/ActionStatusConstants';

import { useAppDispatch, useAppSelector } from 'hooks';

import TasksTab from './components/TasksTab/TasksTab';
import PieChartBox from './components/PieChartBox/PieChartBox';
import LineChartBox from './components/LineChartBox/LineChartBox';
import LineChartBoxUser from './components/LineChartBox/LineChartBoxUser';

import styles from './Dashboard.module.scss';

function Dashboard() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { entity, id } = useParams();
  const [isLoading, setLoading] = useState(true);

  const SitesReducer = useAppSelector((state) => state.SitesReducer);
  const UsersReducer = useAppSelector((state) => state.UsersReducer);
  const StatsReducer = useAppSelector((state) => state.StatsReducer);
  const DashboardTasksReducer = useAppSelector((state) => state.DashboardTasksReducer);
  const UserStatsReducer = useAppSelector((state) => state.UserStatsReducer);
  const UserOrgSiteReducer = useAppSelector((state) => state.UserOrgSiteReducer);
  const OrgModulesReducer = useAppSelector((state) => state.OrgModulesReducer);

  const DatatableReducer = useAppSelector((state) => state.DatatableReducer);
  const defaultReg = OrgModulesReducer?.data?.map((r) => r.name) || [];
  const [activeReg, setActiveReg] = useState(DatatableReducer?.datatable?.dashboard?.activeReg || defaultReg[0] || '');
  const activeTab = DashboardTasksReducer?.activeTab || 'ALL TASKS';

  const setActiveTab = (heading) => () => {
    dispatch({
      type: 'SET_DASHBOARD_TAB',
      payload: {
        activeTab: heading,
      },
    });
  };

  const is_admin = UserOrgSiteReducer?.selected?.is_admin || false;
  const is_super = UserOrgSiteReducer?.selected?.is_super || false;
  const is_coach = UserOrgSiteReducer?.selected?.is_coach || false;
  const is_officer = UserOrgSiteReducer?.selected?.is_officer || false;

  useEffect(() => {
    setActiveTab(DashboardTasksReducer.activeTab || 'ALL TASKS');
  }, [DashboardTasksReducer.activeTab, DashboardTasksReducer.tasks]);

  useEffect(() => {
    if (DashboardTasksReducer.tasks && UserOrgSiteReducer.selected && (is_admin || is_super || is_coach || is_officer)) {
      dispatch(loadStatsAction({ regulatory_act: activeReg }));
    }
    if (DashboardTasksReducer.tasks && UserOrgSiteReducer.selected && !is_admin && !is_super && !is_coach && !is_officer) {
      dispatch(loadUserStatsAction({ regulatory_act: activeReg }));
    }
  }, [DashboardTasksReducer.tasks, UserOrgSiteReducer.selected, activeReg]);

  useEffect(() => {
    if (!SitesReducer.sites) {
      dispatch(loadSitesAction());
    }
  }, [SitesReducer.sites]);

  useEffect(() => {
    if (SitesReducer.sites) {
      dispatch(loadDashboardTasksAction());
    }
  }, [UsersReducer.users, SitesReducer.sites]);

  useEffect(() => {
    if (SitesReducer.status === ActionStatusConstants.SUCCESS && DashboardTasksReducer.status === ActionStatusConstants.SUCCESS) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [SitesReducer.sites, DashboardTasksReducer.tasks]);

  const tasks = DashboardTasksReducer?.tasks?.filter((task) => task.deactivated !== 't') || [];
  
  useEffect(() => {
    if (entity && entity === 'task' && tasks.length > 0) {
      const openTask = tasks.find((task) => task.id === id);
      if (openTask && openTask.deactivated === 't') {
        dispatch({
          type: 'SHOW_ALERT',
          payload: {
            message: 'The site related to this task is deactivated!',
            color: 'danger',
          },
        });
      }
      if (openTask) {
        navigate(`/dashboard/${openTask.subtype}/${id}/${openTask.first}`);
      }
    }
  }, [entity, tasks]);

  useEffect(() => {
    setActiveReg(DatatableReducer?.datatable?.dashboard?.activeReg);
  }, [DatatableReducer?.datatable?.dashboard?.activeReg]);

  const regDropdownRef = React.createRef();

  const filteredTasks = activeReg && activeReg !== '' ? tasks.filter((task) => task.regulatory_act === activeReg) : tasks;

  return (
    <AppLayoutWrapFull setActiveTab={setActiveTab}>
      {isLoading ? (
        <Spinner />
      ) : (
        (StatsReducer.stats || UserStatsReducer.stats) && (
          <>
            <div className={styles.overview}>
              <Container wide dashboard>
                <div style={{ marginBottom: 30, width: 200, marginLeft: 'auto' }}>
                  <FormElement
                    // labelText="REGULATORY ACT"
                    htmlFor="regulatory_act"
                  >
                    <Select
                      size="md"
                      value={activeReg}
                      placeholder="All regulatory acts"
                      onClick={(e) => {
                        dispatch({
                          type: 'SET_DATATABLE',
                          payload: {
                            datatable: {
                              dashboard: {
                                activeReg: e.target.dataset.name,
                              },
                            },
                          },
                        });
                      }}
                      dropdownToggleRef={regDropdownRef}
                    >
                      <li key="regid-all" data-name={''}>
                        All
                      </li>
                      {OrgModulesReducer &&
                        OrgModulesReducer.data &&
                        OrgModulesReducer.data.map((d) => (
                          <li key={`regid-${d.name.toLowerCase()}`} data-name={d.name}>
                            {d.name}
                          </li>
                        ))}
                    </Select>
                  </FormElement>
                </div>

                <div className={styles.boxes}>
                  {is_admin || is_super || is_coach || is_officer ? (
                    <>
                      <PieChartBox
                        key="tasks"
                        title="Total progress"
                        desc="Number of tasks that are assigned and completed versus not completed"
                        complete={StatsReducer?.stats?.tasks?.complete}
                        total={StatsReducer?.stats?.tasks?.total}
                        color="#21AAE1"
                        bg="#C7E9F7"
                        link="/achieve/tasks"
                      />
                      <LineChartBox stats={StatsReducer?.stats} />
                    </>
                  ) : (
                    <>
                      <PieChartBox
                        key="tasks"
                        title="Total progress"
                        desc="Number of tasks that are assigned and completed versus not completed"
                        complete={UserStatsReducer?.stats?.all?.complete}
                        total={UserStatsReducer?.stats?.all?.total}
                        color="#21AAE1"
                        bg="#C7E9F7"
                      />
                      <LineChartBoxUser stats={UserStatsReducer?.stats} />
                    </>
                  )}
                </div>
              </Container>
            </div>
            <Tabs
              tabs={[
                {
                  heading: 'ALL TASKS',
                  icon: 'fal fa-list',
                  count: filteredTasks?.length || '0',
                },
                // {
                //   heading: 'REMEDIATIONS',
                //   icon: 'fal fa-hand-holding-medical',
                //   count: filteredTasks?.filter((task) => task.type === 'task' && task.subtask === 'remediation' && task.subtask_data?.remediation && task?.subtask_data?.audit_response).length || '0',
                // },
                {
                  heading: 'TRAININGS',
                  icon: 'fal fa-badge-check',
                  count: filteredTasks?.filter((task) => task.type === 'training').length || '0',
                },
                {
                  heading: 'QUESTIONS',
                  icon: 'fal fa-question-circle',
                  count: filteredTasks?.filter((task) => task.type === 'question').length || '0',
                },
                {
                  heading: 'DOCUMENTS',
                  icon: 'fal fa-file-alt',
                  count: filteredTasks?.filter((task) => task.type === 'document').length || '0',
                },
                {
                  heading: 'MISC',
                  icon: 'fal fa-user-cog',
                  count: filteredTasks?.filter((task) => task.type === 'task').length || '0',
                },
              ]}
              setActiveTab={setActiveTab}
              activeTab={activeTab}
              className={styles.tabs}
            >
              {/* 
                Tab Content 
                dataType must end in "Dash", e.g. trainingDash. Especially important if new table definition is created for the dashboard
              */}
              <Container wide dashboard>
                {
                  {
                    'ALL TASKS': <TasksTab type="all" dataType="allTasksDash" tasks={filteredTasks} />,
                    // REMEDIATIONS: <TasksTab type="remediation" dataType="remediationDash" tasks={filteredTasks.filter((task) => task.type === 'task' && task.subtask === 'remediation' && task.subtask_data?.remediation && task?.subtask_data?.audit_response)} />,
                    TRAININGS: <TasksTab type="training" dataType="trainingsDash" tasks={filteredTasks.filter((task) => task.type === 'training')} />,
                    QUESTIONS: <TasksTab type="question" dataType="questionsDash" tasks={filteredTasks.filter((task) => task.type === 'question')} />,
                    DOCUMENTS: <TasksTab type="document" dataType="documentsDash" tasks={filteredTasks.filter((task) => task.type === 'document')} />,
                    MISC: <TasksTab type="my" dataType="miscDash" tasks={filteredTasks.filter((task) => task.type === 'task')} />,
                  }[activeTab]
                }
              </Container>
            </Tabs>
          </>
        )
      )}
    </AppLayoutWrapFull>
  );
}

export default Dashboard;
