import React from 'react';
import ItemActionsMenu from 'components/DataTable/ItemActionsMenu';
import Checkbox from 'components/Checkbox/Checkbox';
import moment from 'moment';

export default {
  TABLE: {
    className: 'data-table-tasks',
    initialSortKey: 'name',
    initialSortOrder: 'desc',
  },

  COLUMN: [
    {
      label: 'Row Selector',
      key: 'row-selector',
      header: ({ customTableProps }) => {
        const isSelectedAll =
          customTableProps.selectedRows &&
          customTableProps.selectedRows.length &&
          customTableProps.rowsData &&
          customTableProps.rowsData.length &&
          customTableProps.selectedRows.length === customTableProps.rowsData.length
            ? true
            : false;
        return <Checkbox checked={isSelectedAll} onChange={() => customTableProps.doSelectAll()} name="row-selector" />;
      },
      className: 'col-row-selector',
      format: ({ rowData, customTableProps }) => {
        const isSelected = customTableProps.selectedRows && customTableProps.selectedRows.length && customTableProps.selectedRows.includes(rowData) ? true : false;
        return <Checkbox checked={isSelected} onChange={() => customTableProps.onRowSelect({ rowData })} name="row-selector" />;
      },
      canBeHidden: true,
    },
    {
      label: 'Name',
      header: 'NAME',
      key: 'name',
      sortable: true,
      className: 'name',
      format: ({ rowData }) => {
        return <span>{rowData['name']}</span>;
      },
    },
    {
      label: 'E-mail',
      header: 'E-MAIL',
      key: 'email',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{rowData['email']}</span>;
      },
    },
    {
      label: 'Notes',
      header: 'NOTES',
      key: 'notes',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{rowData['notes']}</span>;
      },
    },
    {
      label: 'Created',
      header: 'CREATED',
      key: 'created_at',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{rowData['created_at'] ? moment(rowData['created_at']).format('MM/DD/YYYY') : ''}</span>;
      },
    },
    {
      label: 'Actions',
      key: 'actions',
      format: ({ rowData, customTableProps }) => {
        const actionMenuDropDown = React.createRef();
        if (customTableProps.handleDelete && customTableProps.onlyOwn && rowData.user === customTableProps.userId) {
          return (
            <ItemActionsMenu
              ref={actionMenuDropDown}
              actions={(() => {
                return [
                  {
                    label: 'Delete note',
                    action: () => {
                      if (actionMenuDropDown && actionMenuDropDown.current) {
                        actionMenuDropDown.current.handleMenuClose();
                      }
                      customTableProps.handleDelete(rowData);
                    },
                  },
                ];
              })()}
            />
          );
        }
        if (customTableProps.handleDelete && !customTableProps.onlyOwn) {
          return (
            <ItemActionsMenu
              ref={actionMenuDropDown}
              actions={(() => {
                return [
                  {
                    label: 'Delete note',
                    action: () => {
                      if (actionMenuDropDown && actionMenuDropDown.current) {
                        actionMenuDropDown.current.handleMenuClose();
                      }
                      customTableProps.handleDelete(rowData);
                    },
                  },
                ];
              })()}
            />
          );
        }
      },
    },
  ],
};
