import React, { useState, useEffect, Dispatch } from 'react';

import { generateCodeAction, verifyCodeAction } from 'store/actions/Actions';
import { useAppDispatch, useAppSelector } from 'hooks';
import ActionStatusConstants from 'helpers/ActionStatusConstants';
import { Button } from 'components/Button/Button';
import FormElement from 'components/FormElement/FormElement';

import TypeIcon from './TypeIcon';
import Counter from '../Counter/Counter';

import styles from './Method.module.scss';

interface IProps {
  type: 'email' | 'phone';
  value: string;
  userId: string;
  onInitType: () => void;
  counter: number;
  setCounter: Dispatch<any>;
}

function NotVerifiedMethod(props: IProps) {
  const { type, value, userId, onInitType, counter, setCounter } = props;

  const dispatch = useAppDispatch();
  const MfaReducer = useAppSelector((state) => state.MfaReducer);

  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState('');

  useEffect(() => {
    if (MfaReducer.status === ActionStatusConstants.FAILURE && MfaReducer?.message) {
      setCodeError(MfaReducer?.message);
    }
  }, [MfaReducer.message]);

  const onChangeCode = (e) => {
    setCode(e.target.value);
    setCodeError('');
  };

  const generateVerificationCode = (e) => {
    dispatch(
      generateCodeAction({
        type,
        user_id: userId,
      })
    );
    e.preventDefault();
    setCode('');
    setCodeError('');
  };

  const confirmVerificationCode = () => {
    if (code?.length === 6) {
      dispatch(
        verifyCodeAction({
          type,
          code,
          user_id: userId,
          for_deactivation: false,
        })
      );
    } else {
      setCodeError('Code is required to have 6 digits');
    }
  };

  return (
    <div>
      <div className={styles.form}>
        <TypeIcon type={type} />
        <div>
          <FormElement className={styles.form_element} htmlFor="verificationCode" errorMessage={codeError}>
            <input autoComplete="none" type="text" name="verificationCode" placeholder="Enter verification code" value={code} onChange={onChangeCode} />
          </FormElement>
        </div>
        <Button size="md" text="Confirm verification code" disabled={!(counter && counter > 0)} onClick={confirmVerificationCode} />
        <Button size="md" color="secondary" className={styles.resend} text="Resend" disabled={counter > 0} onClick={generateVerificationCode} />
        <Button size="md" text={`Edit ${type}`} color="secondary" className={styles.cancel} onClick={onInitType} />
      </div>
      <div className={styles.address}>
        <div>{`Code sent to the ${value}`}</div>
        <Counter counter={counter} setCounter={setCounter} />
      </div>
    </div>
  );
}

export default NotVerifiedMethod;
