import React from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './Stat.module.scss';

/**
 * @param {String} className - additional className to add
 * @param {String} iconClass - the icon class
 * @param {String} statName - the name of the stat
 * @param {String} link - url for the stat
 * @param {String|Number} statData - the data to represent in the stat
 */
const Stat = (props) => {
  const navigate = useNavigate();

  const handleClick = (link) => {
    navigate(link);
  };

  return (
    <div className={`${styles.stat} ${props.className || ''}`} onClick={() => handleClick(props.link)}>
      <i className={`${props.iconClass || 'fal fa-copy'} ${styles['stat-icon']}`} />
      <div className={styles['stat-info']}>
        <span className={styles['stat-name']}>{props.statName}</span>
        <span className={styles['stat-data']}>{props.statData}</span>
      </div>
    </div>
  );
};

export default Stat;
