import React, { ChangeEventHandler } from 'react';
import classNames from 'classnames';

import styles from './Radio.module.scss';

interface IRadio {
  checked?: boolean;
  className?: string;
  labelClassName?: string;
  labelText?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  value?: string;
  disabled?: boolean;
}

const defaultProps = {
  disabled: false,
  checked: false,
  className: undefined,
  labelClassName: undefined,
  labelText: undefined,
  onChange: () => false,
  value: undefined,
};

function Radio({ disabled, checked, className, labelClassName, labelText, onChange, value }: IRadio) {
  const styleRadio = classNames(styles.radio, labelClassName);
  const styleInput = classNames(styles.hidden, className);

  return (
    <label className={styleRadio}>
      <input disabled={disabled || false} checked={checked} className={styleInput} type="radio" value={value} onChange={onChange} />
      <span className={styles['radio-span']} />
      {labelText}
    </label>
  );
}

Radio.defaultProps = defaultProps;

export default Radio;
