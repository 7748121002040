import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styles from './BulkUploadModal.module.scss';
import Modal from 'components/Modal/Modal';
import Button from 'components/Button/Button';
import { bulkUploadSiteAction } from 'store/actions/Actions';
import UploadZone from 'components/UploadZone/UploadZone';

const BulkUploadModal = (props) => {
  const [template, setTemplate] = useState({
    name: '',
    data: '',
  });
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (files) {
      setTemplate(files[0]);
    }
  }, [files]);

  return (
    <Modal setShowModal={props.setShowModal} className={styles.modal}>
      <div className={styles['modal-header']}>
        <h2 className={styles.heading}>HIPAA Sites - Bulk Upload</h2>
        <p className={styles.description}>Fill out the HIPAA Site Excel template and upload below.</p>
      </div>
      <div className={styles['modal-body']}>
        <p>If you need the template, you can download it here:</p>
        {/* <Button
                    className={styles.button}
                    text="DOWNLOAD EXCEL TEMPLATE"
                    icon="fas fa-arrow-circle-down"
                    color="primary-outline"
                /> */}
        <a className={styles['download-button']} href={`${process.env.PUBLIC_URL}/SitesWorksheet.xlsx`}>
          DOWNLOAD TEMPLATE
        </a>
        <UploadZone files={files} setFiles={setFiles} formats={['xls', 'xlsx']} maxFileSize={10 * 1024 * 1024} maxFileNameLength={235} />
      </div>
      <div className={styles['modal-footer']}>
        <Button className={styles.button} text="CANCEL" onClick={() => props.setShowModal(false)} color="secondary" />
        <Button
          className={styles.button}
          text="UPLOAD"
          onClick={() => {
            props.bulkUploadSiteAction({
              file: template.data,
            });
            props.setShowModal(false);
          }}
        />
      </div>
    </Modal>
  );
};

// const mapStateToProps = state => state.AuditReducer;
const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  bulkUploadSiteAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(BulkUploadModal);
