import { call, put, takeLatest, delay, select } from 'redux-saga/effects';
// import { push } from 'redux-first-history'
import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { actionApiCall } from 'helpers/api/ApiCalls';
import * as sagaConstants from 'helpers/constants/SagasConstants';

const getSelected = (state) => state.UserOrgSiteReducer.selected;

function* createUsersBulkSaga({ payload }) {
  try {
    const selected = yield select(getSelected);
    const apiResponse = yield call(actionApiCall, {
      actionType: ActionTypeConstants.CREATE_USERS_BULK,
      data: {
        emails: payload.emails,
        organization: selected.org,
        role: payload.access,
      },
    });
    if (apiResponse.res.data.response !== 400) {
      yield put({
        type: ActionTypeConstants.CREATE_USERS_BULK_SUCCESS,
        payload: {
          message: apiResponse.res.data.message || `Accounts ${sagaConstants.SUCCESSFULLY_CREATED}`,
        },
      });
      // yield put(push('/maintain/users'));
      // yield put({
      //   type: ActionTypeConstants.SHOW_ALERT,
      //   payload: {
      //     message: apiResponse.res.data.message || "Account successfully created",
      //     color: "success"
      //   }
      // });
      // yield delay(10000)
      // yield put({ type: ActionTypeConstants.HIDE_ALERT });
    } else {
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: apiResponse.res.data.message || `${sagaConstants.ISSUE_OCCURED} creating accounts`,
          color: 'danger',
        },
      });
      yield delay(10000);
      yield put({
        type: ActionTypeConstants.HIDE_ALERT,
      });
    }
  } catch (err) {
    yield put({
      type: ActionTypeConstants.SHOW_ALERT,
      payload: {
        message: `${sagaConstants.SERVER_ERROR}`,
        color: 'danger',
      },
    });
    yield delay(10000);
    yield put({ type: ActionTypeConstants.HIDE_ALERT });
  }
}

export default function* () {
  yield takeLatest(ActionTypeConstants.CREATE_USERS_BULK, createUsersBulkSaga);
}
