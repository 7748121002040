import { call, put, takeLatest, delay } from 'redux-saga/effects';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { actionApiCall } from 'helpers/api/ApiCalls';
import * as sagaConstants from 'helpers/constants/SagasConstants';

function* ReadAffiliateAgreementsSaga({ payload }) {
  try {
    const apiResponse = yield call(actionApiCall, {
      actionType: ActionTypeConstants.READ_AFFILIATE_AGREEMENTS,
      data: {},
    });

    if (apiResponse.res && apiResponse.res.data && apiResponse.res.data.response === 200) {
      const { data } = apiResponse.res.data;
      yield put({
        type: ActionTypeConstants.READ_AFFILIATE_AGREEMENTS_SUCCESS,
        payload: {
          files: data,
        },
      });
    } else {
      yield put({
        type: ActionTypeConstants.READ_AFFILIATE_AGREEMENTS_SUCCESS,
        payload: {
          files: [],
        },
      });
    }
  } catch (err) {
    yield put({
      type: ActionTypeConstants.SHOW_ALERT,
      payload: {
        message: `${sagaConstants.SERVER_ERROR}`,
        color: 'danger',
      },
    });
    yield delay(10000);
    yield put({ type: ActionTypeConstants.HIDE_ALERT });
  }
}

export default function* () {
  yield takeLatest(ActionTypeConstants.READ_AFFILIATE_AGREEMENTS, ReadAffiliateAgreementsSaga);
}
