import ActionStatusConstants from 'helpers/ActionStatusConstants';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
// import { LOCATION_CHANGE } from "redux-first-history";

const initialState = {
  status: ActionStatusConstants.INITIAL,
  message: null,
  formErrors: null,
  datatable: {},
  orgWizardData: null,
};

const DatatableReducer = (state = initialState, action) => {
  const actionType = action && action.type ? action.type : null;
  const payload = action && action.payload ? action.payload : null;

  switch (actionType) {
    case ActionTypeConstants.SET_DATATABLE:
      return {
        ...state,
        status: ActionStatusConstants.ISBUSY,
      };
    case ActionTypeConstants.SET_DATATABLE_SUCCESS:
      return {
        ...state,
        datatable: { ...state.datatable, ...payload.datatable },
        status: ActionStatusConstants.SUCCESS,
        message: payload && payload.message ? payload.message : null,
        formErrors: payload && payload.formErrors ? payload.formErrors : null,
      };
    case ActionTypeConstants.SET_DATATABLE_FAILURE:
      return {
        ...state,
        status: ActionStatusConstants.FAILURE,
        message: payload && payload.message ? payload.message : null,
        formErrors: payload && payload.formErrors ? payload.formErrors : null,
      };
    case ActionTypeConstants.SET_ROWS_PER_PAGE:
      return {
        ...state,
        datatable: {
          ...state.datatable,
          [payload.dataType]: {
            ...state.datatable[payload.dataType],
            rowsPerPage: payload.rowsPerPage,
            currentPage: payload.currentPage,
          },
        },
      };
    case ActionTypeConstants.SET_PAGINATION:
      return {
        ...state,
        datatable: {
          ...state.datatable,
          [payload.dataType]: {
            ...state.datatable[payload.dataType],
            currentPage: payload.currentPage,
          },
        },
      };
    case ActionTypeConstants.SET_ORG_WIZARD:
      return {
        ...state,
        orgWizardData: payload,
      };
    default:
  }
  return state;
};

export default DatatableReducer;
