import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from 'components/Button/Button';
import logo from 'assets/img/cg-logo-large.png';
import WelcomePanel from '../components/WelcomePanel/WelcomePanel';

import styles from './ForgotPassword.module.scss';

function ForgotPasswordSuccess() {
  const navigate = useNavigate();
  return (
    <WelcomePanel>
      <div className={styles['login-panel-heading']}>
        <img className={styles.logo} src={logo} alt="" />
      </div>
      <div className={styles['login-panel-content']}>
        <p className={styles.welcome}>We&apos;ve sent you an email!</p>
      </div>
      <div className={styles['login-panel-footer']}>
        <Button text="BACK TO LOGIN" onClick={() => navigate('/login')} />
      </div>
    </WelcomePanel>
  );
}

export default ForgotPasswordSuccess;
