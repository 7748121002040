import React, { useState, useEffect } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import AppLayoutWrapFull from 'components/AppLayoutWrapFull';
import Container from 'components/Container/Container';
import PageHeader from 'components/PageHeader/PageHeader';
import PageHeaderActions from 'components/PageHeader/components/PageHeaderActions/PageHeaderActions';
import FullDataTable from 'components/DataTable/FullDataTable';
import TableDefRemediation from 'table-defs/TableDefRemediation';
import { useConfirmModal } from 'hooks';

import {
  loadRemediationPlansAction,
  loadSitesAction,
  completeRemediationPlanAction,
  incompleteRemediationPlanAction,
  notifyRemediationPlanAction,
  setPaginationAction,
  deleteRemediationPlanAction,
} from 'store/actions/Actions';

import useFilterRows from 'helpers/customHooks/useFilterRows';
import { setSelectedRows, toggleDropdowns, exportAsCSV } from 'helpers/utils';
import { REMEDIATIONS_PAGE } from 'helpers/constants/DataTableConstants';
import Spinner from 'components/Spinner/Spinner';
import ActionStatusConstants from 'helpers/ActionStatusConstants';

const Remediation = (props) => {
  const dispatch = useDispatch();
  const DatatableReducer = useSelector((state) => state.DatatableReducer);
  const navigate = useNavigate();

  const [state, setState] = useState({
    selectedRows: null,
    selected_sites: [],
  });
  const [sortKey, setSortKey] = useState(DatatableReducer.datatable[REMEDIATIONS_PAGE]?.sortKey);
  const [sortOrder, setSortOrder] = useState(DatatableReducer.datatable[REMEDIATIONS_PAGE]?.sortOrder);

  const {
    loadRemediationPlansAction,
    loadSitesAction,
    completeRemediationPlanAction,
    incompleteRemediationPlanAction,
    notifyRemediationPlanAction,
    deleteRemediationPlanAction,
    setPaginationAction,
    SitesReducer,
    RemediationReducer,
    UserOrgSiteReducer,
  } = props;

  const [showConfirmModal, setShowConfirmModal, renderConfirmModal] = useConfirmModal();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (!SitesReducer.sites) {
      loadSitesAction();
    }
  }, [SitesReducer.sites, loadSitesAction]);

  useEffect(() => {
    if (!RemediationReducer.remediation_plans && SitesReducer.sites) {
      loadRemediationPlansAction();
    }
  }, [RemediationReducer.remediation_plans, loadRemediationPlansAction, SitesReducer.sites]);

  // ##############################################

  const [activeReg, setActiveReg] = useState('');

  useEffect(() => {
    if (DatatableReducer.datatable && DatatableReducer.datatable.dashboard && DatatableReducer.datatable.dashboard.activeReg) {
      setActiveReg(DatatableReducer.datatable.dashboard.activeReg);
    }
  }, [DatatableReducer.datatable]);

  useEffect(() => {
    if (
      RemediationReducer.status === ActionStatusConstants.SUCCESS &&
      SitesReducer.status === ActionStatusConstants.SUCCESS &&
      DatatableReducer.status === ActionStatusConstants.SUCCESS
    ) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [RemediationReducer.remediation_plans, SitesReducer.sites, DatatableReducer.datatable]);

  useEffect(() => {
    if (activeReg) {
      setFilters({
        ...DatatableReducer.datatable.remediations.filters,
        regulatory_act: activeReg,
      });
    }
  }, [activeReg]);

  const noFilters = {
    text: '',
    type: '',
    regulatory_act: activeReg,
    'last-action': '',
    status: '',
    role: '',
    site: '',
    dateField: 'start_date',
    selected_sites: [],
  };

  const [filters, setFilters] = useState(
    DatatableReducer.datatable && DatatableReducer.datatable.remediations && DatatableReducer.datatable.remediations.filters
      ? DatatableReducer.datatable.remediations.filters
      : noFilters
  );

  const onChangeRowSelect = ({ selectedRows }) => {
    if (selectedRows !== state.selectedRows) {
      setState((s) => ({ ...s, selectedRows }));
    }
  };

  const [selectedRemediationIds, setSelectedRemediationIds] = useState(
    DatatableReducer.datatable && DatatableReducer.datatable.remediations && DatatableReducer.datatable.remediations.selectedIds
      ? DatatableReducer.datatable.remediations.selectedIds
      : []
  );

  useEffect(() => {
    dispatch({
      type: 'SET_DATATABLE',
      payload: {
        datatable: {
          remediations: {
            selectedIds: selectedRemediationIds,
            filters,
            selectedRows: state.selectedRows,
            selected_sites: state.selected_sites,
            rowsPerPage:
              DatatableReducer.datatable && DatatableReducer.datatable.remediations && DatatableReducer.datatable.remediations.rowsPerPage
                ? DatatableReducer.datatable.remediations.rowsPerPage
                : 10,
            currentPage:
              DatatableReducer.datatable && DatatableReducer.datatable.remediations && DatatableReducer.datatable.remediations.currentPage
                ? DatatableReducer.datatable.remediations.currentPage
                : 0,
            sortKey,
            sortOrder,
          },
        },
      },
    });
  }, [selectedRemediationIds, filters, state, sortKey, sortOrder]);

  const setFilter = (filterName) => (e) => {
    setFilters({
      ...filters,
      [filterName]:
        e.target.type === 'checkbox' ? e.target.checked : e.target.value || (e.target && e.target.attributes && e.target.attributes.value && e.target.attributes.value.value) || '',
    });
    setPaginationAction({ dataType: REMEDIATIONS_PAGE, currentPage: 0 });
  };

  const setDateFilter = (filterName) => (value) => {
    setFilters({
      ...filters,
      [filterName]: value,
    });
    setPaginationAction({ dataType: REMEDIATIONS_PAGE, currentPage: 0 });
  };

  const filteredRows = useFilterRows(filters, RemediationReducer.remediation_plans);

  // ##############################################

  // const onChangeRowSelect = ({ selectedRows }) => {
  //     if (selectedRows !== state.selectedRows) {
  //         setState({
  //             ...state,
  //             selectedRows
  //         });
  //     }
  // };

  const openAddNewIncident = () => navigate('/achieve/remediation/add');

  const getSitesList = () => {
    try {
      const sitesList = SitesReducer.sites
        ? SitesReducer.sites.map((site) => {
            return {
              id: site.id,
              siteName: site.name,
              value: filters.selected_sites.includes(site.id) ? true : false,
            };
          })
        : [];
      sitesList.unshift({
        id: '',
        siteName: 'All Sites',
        value: filters.selected_sites.includes(''),
      });
      return sitesList;
    } catch (error) {
      console.log('error with site list', error);
    }
    return [];
  };

  const statusDropdownRef = React.createRef();
  // const siteDropdownRef = React.createRef();
  const regDropdownRef = React.createRef();
  const chooseActionDropdownRef = React.createRef();
  const selectedIds = selectedRemediationIds.map((item) => item.id);

  // const is_admin = (UserOrgSiteReducer.selected && UserOrgSiteReducer.selected.is_admin) || false;
  // const is_officer = (UserOrgSiteReducer.selected && UserOrgSiteReducer.selected.is_officer) || false;
  const is_super = (UserOrgSiteReducer.selected && UserOrgSiteReducer.selected.is_super) || false;
  const is_coach = (UserOrgSiteReducer.selected && UserOrgSiteReducer.selected.is_coach) || false;

  const labels = () => ({
    id: {
      label: 'ID',
    },
    title: {
      label: 'TITLE',
    },
    regulatory_act: {
      label: 'REGULATORY',
    },
    start_date: {
      label: 'START DATE',
    },
    end_date: {
      label: 'END DATE',
    },
    open_tasks: {
      label: 'OPEN TASKS',
    },
    site: {
      label: 'SITE',
    },
    status: {
      label: 'STATUS',
    },
  });

  return (
    <AppLayoutWrapFull
      onClick={toggleDropdowns([
        chooseActionDropdownRef,
        statusDropdownRef,
        // siteDropdownRef,
        regDropdownRef,
      ])}
    >
      <PageHeader showStats={true}>
        <PageHeaderActions
          primaryButtonText="ADD PLAN"
          primaryButtonHandler={openAddNewIncident}
          searchHandler={(e) => {
            setFilters({ ...filters, text: e.target.value || '' });
            setPaginationAction({
              dataType: REMEDIATIONS_PAGE,
              currentPage: 0,
            });
          }}
          statusDropdownRef={statusDropdownRef}
          dateRange={{
            onChangeStart: setDateFilter('start'),
            onChangeEnd: setDateFilter('end'),
          }}
          searchValue={filters.text}
          chooseAction={[
            {
              actionName: 'Notify Selected',
              actionHandler: () => {
                const idCount = selectedIds.length;

                if (idCount > 0) {
                  setShowConfirmModal({
                    title: 'Notify Assignees',
                    question:
                      idCount === 1
                        ? `Are you sure you want to notify assignees about the selected remediation?`
                        : `Are you sure you want to notify assignees about ${idCount} selected remediations?`,
                    confirmAction: () => {
                      notifyRemediationPlanAction({ items: selectedIds });
                    },
                  });
                } else {
                  setShowConfirmModal({
                    type: 'simple',
                    title: 'Nothing selected',
                    question: `Please select remediations.`,
                  });
                }
              },
            },
            {
              actionName: 'Mark Selected Complete',
              actionHandler: () => {
                completeRemediationPlanAction({ items: selectedIds });
              },
            },
            {
              actionName: 'Mark Selected Incomplete',
              actionHandler: () => {
                incompleteRemediationPlanAction({ items: selectedIds });
              },
            },
            {
              actionName: 'Export Selected',
              actionHandler: () => {
                const data = RemediationReducer.remediation_plans.filter((item) => selectedRemediationIds.map((i) => i.id).includes(item.id));
                const updatedData = data.map((item) => {
                  const siteObj = item.site === '' ? { name: 'All Sites' } : SitesReducer.sites.find((site) => site.id === item.site);
                  return {
                    ...item,
                    site: siteObj.name,
                  };
                });
                exportAsCSV(updatedData, labels, 'remediations.csv');
              },
            },
            (is_super || is_coach) && {
              actionName: 'Delete Selected',
              actionHandler: () => {
                const idCount = selectedIds.length;

                if (idCount > 0) {
                  setShowConfirmModal({
                    title: 'Delete selected remediations',
                    question: idCount === 1 ? `Are you sure you want to delete the selected remediation?` : `Are you sure you want to delete ${idCount} selected remediations?`,
                    confirmAction: () => {
                      deleteRemediationPlanAction({ items: selectedIds });
                    },
                  });
                } else {
                  setShowConfirmModal({
                    type: 'simple',
                    title: 'Nothing selected',
                    question: `Please select remediations to be deleted.`,
                  });
                }
              },
            },
          ]}
          chooseActionDropdownRef={chooseActionDropdownRef}
          site={{
            onClick: (e) => {
              if (e.target.type === 'checkbox') {
                let selected_sites = filters.selected_sites.slice();
                if (e.target.checked) {
                  selected_sites.push(e.target.value);
                } else {
                  selected_sites = selected_sites.filter((site) => site !== e.target.value);
                }
                setFilters({
                  ...filters,
                  site: selected_sites.length > 0 ? `Sites (${selected_sites.length})` : 'Site',
                  // site: "Site",
                  selected_sites: selected_sites,
                });
                setPaginationAction({
                  dataType: REMEDIATIONS_PAGE,
                  currentPage: 0,
                });
              }
            },
            sites: getSitesList(),
          }}
          // siteDropdownRef={siteDropdownRef}
          status={{
            onChange: setFilter('status'),
            options: ['All', 'Pending', 'Incomplete', 'Complete', 'Closed incomplete', 'Deactivated incomplete'],
          }}
          reg={{
            onChange: setFilter('regulatory_act'),
            options: ['All regulatory', 'HIPAA', 'OSHA'],
          }}
          regDropdownRef={regDropdownRef}
          filters={filters}
          resetFilters={() => {
            setFilters(noFilters);
            setPaginationAction({
              dataType: REMEDIATIONS_PAGE,
              currentPage: 0,
            });
          }}
        />
      </PageHeader>
      {isLoading ? (
        <Spinner />
      ) : (
        <Container wide fluid>
          {RemediationReducer.remediation_plans && (
            <FullDataTable
              onRowClick={function (e) {
                if (e.currentTarget.classList.contains('col-actions') || e.currentTarget.classList.contains('col-row-selector')) {
                  return;
                }
                navigate(`/achieve/remediation/edit?id=${this.props.rowData.id}`);
              }}
              rowsData={filteredRows}
              definitions={TableDefRemediation}
              dataType={REMEDIATIONS_PAGE}
              setSortKey={setSortKey}
              setSortOrder={setSortOrder}
              onChangeRowSelect={({ selectedRows }) => {
                onChangeRowSelect({ selectedRows });
              }}
              setSelectedRows={setSelectedRows(['id'], setSelectedRemediationIds)}
              customTableProps={{
                edit: ({ id }) => navigate(`/achieve/remediation/edit?id=${id}`),
                complete: ({ id }) => {
                  completeRemediationPlanAction({ items: [id] });
                },
                incomplete: ({ id }) => {
                  incompleteRemediationPlanAction({ items: [id] });
                },
                notify: ({ id }) => {
                  setShowConfirmModal({
                    title: 'Notify Assignee',
                    question: 'Are you sure you want to notify assignees about the remediation',
                    confirmAction: () => {
                      notifyRemediationPlanAction({ items: [id] });
                    },
                  });
                },
                delete:
                  is_super || is_coach
                    ? ({ id, title }) => {
                        setShowConfirmModal({
                          title: 'Delete the remediation',
                          question: `Are you sure you want to remove the remediation "${title}"`,
                          confirmAction: () => {
                            deleteRemediationPlanAction({ items: [id] });
                          },
                        });
                      }
                    : null,
                selectedRows: state.selectedRows ? state.selectedRows : selectedRemediationIds,
              }}
            />
          )}
          {showConfirmModal && renderConfirmModal(showConfirmModal, setShowConfirmModal)}
        </Container>
      )}
    </AppLayoutWrapFull>
  );
};

const mapStateToProps = (state) => ({
  RemediationReducer: state.RemediationReducer,
  SitesReducer: state.SitesReducer,
  UserOrgSiteReducer: state.UserOrgSiteReducer,
});

const mapDispatchToProps = {
  loadSitesAction,
  loadRemediationPlansAction,
  completeRemediationPlanAction,
  setPaginationAction,
  incompleteRemediationPlanAction,
  notifyRemediationPlanAction,
  deleteRemediationPlanAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Remediation);
