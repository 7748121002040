import axios, { AxiosRequestConfig, ResponseType } from 'axios';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { URLS } from 'helpers/ApiEndpointConstants';
import { LogEventToAwsCloudwatch } from 'index';
import Cookies from 'js-cookie';

export const actionApiCall = async (request: { actionType: string; data: any; subtask?: any }) => {
  try {
    const org = Cookies.get('org') || false;
    const site = Cookies.get('site') || false;

    if (!request?.actionType) {
      return false;
    }

    if (!ActionTypeConstants[request.actionType]) {
      return false;
    }

    if (!URLS[request.actionType]) {
      return false;
    }

    const api = URLS[request.actionType];
    if (!(api.URL && api.METHOD && api.API_OBJECT && api.API_ACTION)) {
      return false;
    }

    const reqConfig: AxiosRequestConfig = {
      method: api.METHOD,
      url: api.URL,
      maxContentLength: 200 * 1024 * 1024, // 200 Mb
      maxBodyLength: 200 * 1024 * 1024, // 200 Mb
      responseType: <ResponseType>api.RESPONSE_TYPE || 'json',
      withCredentials: true,
      data: {
        object: api.API_OBJECT,
        action: api.API_ACTION,
        data: request.data,
        subtask: request.subtask,
        organization: request?.data?.organization || org || 1,
        site: request?.data?.site || site,
      },
    };

    const response = await axios(reqConfig);

    try {
      //DO NOT LOG PASSWORDS
      const requestData = { api, ...reqConfig.data, password: undefined };
      const responseData = { ...response };
      LogEventToAwsCloudwatch('HttpRequestFromFrontEnd', { request: requestData, response: responseData });
    } catch (e) {
      //ignore errors
    }

    return {
      req: request,
      res: response,
      isAfterCall: true,
      errType: undefined,
      errObj: undefined,
    };
  } catch (error) {
    return {
      errType: 'Unknown error',
      error,
    };
  }
};

export default actionApiCall;
