import ActionStatusConstants from 'helpers/ActionStatusConstants';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
// import { LOCATION_CHANGE } from "redux-first-history"

export interface IAlertState {
  status: string;
  message?: string;
  type?: string;
  color?: 'primary' | 'danger' | 'success';
  data?: {
    imported_rows: string;
    errors: Array<{ affected_row: string; messages: Array<string> }>;
  };
  title?: string;
}

const initialState: IAlertState = {
  status: ActionStatusConstants.INITIAL,
  message: null,
};

export default (state = initialState, action): IAlertState => {
  const actionType = action && action.type ? action.type : null;
  const payload = action && action.payload ? action.payload : null;

  switch (actionType) {
    case ActionTypeConstants.SHOW_ALERT:
      return {
        ...state,
        status: ActionStatusConstants.ISBUSY,
        message: payload && payload.message ? payload.message : null,
        type: payload && payload.type ? payload.type : null,
        color: payload && payload.color ? payload.color : 'primary',
        data: payload && payload.data ? payload.data : null,
        title: payload && payload.title ? payload.title : null,
      };
    case ActionTypeConstants.HIDE_ALERT:
      return {
        ...state,
        status: ActionStatusConstants.ISBUSY,
        message: null,
        type: null,
        color: 'primary',
      };
    default:
  }
  return state;
};
