import DataTableHeader from './DataTableHeader';
// import DataTableRow from "./DataTableRow";
import DataTableRows from './DataTableRows';
import PaginatedData from './PaginatedData';
import PaginationSelector from './PaginationSelector';
import PropTypes from 'prop-types';
import React from 'react';
import RowsPerPageSelector from './RowsPerPageSelector';
import SortedData from './SortedData';
// import classNames from "classnames"
import { connect } from 'react-redux';
import { setRowsPerPageAction, setPaginationAction } from '../../store/actions/Actions';
import { SUPER_ADMIN_ORGANIZATIONS } from 'helpers/constants/DataTableConstants';

import styles from './DataTableRows.module.scss';

/**
 * @param {String} className - additional className to add
 * @param {Object[]} rowsData - rowsData
 * @param {Object[]} definitions - tableDef
 * @param {Function} onRowClick - a function to execute onClick of a row (this will refer to DataRow)
 * @param {Object} customTableProps - custom Table props to add
 * @param {Function} onChangeRowSelect - function to execute on row select
 */
class FullDataTable extends React.Component {
  state = {
    rowsData: null,
    sortKey: this.props.DatatableReducer?.datatable[this.props.dataType]?.sortKey || this.props.definitions.TABLE.initialSortKey || null,
    sortOrder: this.props.DatatableReducer?.datatable[this.props.dataType]?.sortOrder || this.props.definitions.TABLE.initialSortOrder || null,
    rowsPerPage:
      this.props.DatatableReducer &&
      this.props.DatatableReducer.datatable &&
      this.props.DatatableReducer.datatable[this.props.dataType] &&
      this.props.DatatableReducer.datatable[this.props.dataType].rowsPerPage
        ? this.props.DatatableReducer.datatable[this.props.dataType].rowsPerPage
        : 10,
    currentPage:
      this.props.DatatableReducer &&
      this.props.DatatableReducer.datatable &&
      this.props.DatatableReducer.datatable[this.props.dataType] &&
      this.props.DatatableReducer.datatable[this.props.dataType].currentPage
        ? this.props.DatatableReducer.datatable[this.props.dataType].currentPage
        : 0,
    selectedRows: [],
    visibleColumnKeys: this.getInitialVisibleColumnKeys(),
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.rowsData !== prevState.rowsData) {
      return {
        rowsData: nextProps.rowsData,
        currentPage:
          nextProps.DatatableReducer &&
          nextProps.DatatableReducer.datatable &&
          nextProps.DatatableReducer.datatable[nextProps.dataType] &&
          nextProps.DatatableReducer.datatable[nextProps.dataType].currentPage
            ? nextProps.DatatableReducer.datatable[nextProps.dataType].currentPage
            : 0,
        rowsPerPage:
          nextProps.DatatableReducer &&
          nextProps.DatatableReducer.datatable &&
          nextProps.DatatableReducer.datatable[nextProps.dataType] &&
          nextProps.DatatableReducer.datatable[nextProps.dataType].rowsPerPage
            ? nextProps.DatatableReducer.datatable[nextProps.dataType].rowsPerPage
            : 10,
        selectedRows: [],
      };
    }

    return null;
  }

  doonce = true;
  componentDidUpdate(prevProps, prevState) {
    // if (this.props.dataType?.slice(-4) === 'Dash') {
    //   if (prevProps.dataType !== this.props.dataType) {
    //     this.setState({ visibleColumnKeys: this.getInitialVisibleColumnKeys() });
    //   }
    // }
    if (prevState.selectedRows !== this.state.selectedRows) {
      this.onChangeRowSelectCallback();
      if (typeof this.props.setSelectedRows == 'function') {
        this.props.setSelectedRows(this.state.selectedRows);
      }
    }
    if (this.state.selectedRows.length === 0 && this.doonce) {
      if (typeof this.props.setSelectedRows == 'function') {
        if (prevProps.dataType) {
          const previouslyChecked =
            this.props.DatatableReducer.datatable &&
            this.props.DatatableReducer.datatable[prevProps.dataType] &&
            this.props.DatatableReducer.datatable[prevProps.dataType].selectedIds
              ? this.props.DatatableReducer.datatable[prevProps.dataType].selectedIds
              : [];
          this.props.setSelectedRows(previouslyChecked);

          this.setState({
            selectedRows: previouslyChecked,
          });
        }
      }

      this.doonce = false;
    }
  }

  getInitialVisibleColumnKeys() {
    // console.log("xxx", this.props.definitions.TABLE.initialSortKey)
    // hiddenColumns is an array of keys to hide
    // each key should correspond to the key in each column def
    const hiddenColumns = this.props.hiddenColumns || [];

    const visibleColumns = this.props.definitions.COLUMN.filter((item) => ((item.canBeHidden && item.isHidden) || hiddenColumns.includes(item.key) ? false : true));
    const visibleColumnKeys = visibleColumns && visibleColumns.length ? visibleColumns.map((item) => item.key) : null;

    const localStorageVisibleColumnKeysAll = JSON.parse(localStorage.getItem('CompiancyVisibleColumnKeys') || '{}');
    const localStorageVisibleColumnKeysThisTable = localStorageVisibleColumnKeysAll[this.props.dataType] ? localStorageVisibleColumnKeysAll[this.props.dataType] : null;

    return localStorageVisibleColumnKeysThisTable || visibleColumnKeys;  
  }

  onRowSelect({ rowData }) {
    let selectedRows = this.state.selectedRows;
    if (
      this.state.selectedRows.some((row) => {
        return row.id === rowData.id;
      })
    ) {
      selectedRows = selectedRows.filter((item) => item.id !== rowData.id);
    } else {
      selectedRows = selectedRows.concat(rowData);
    }

    if (this.state.selectedRows !== selectedRows) {
      this.setState({
        selectedRows,
      });
    }
  }

  doSelectAll() {
    const rowsData = this.state.rowsData;
    const selectedRows = this.state.selectedRows;
    let newSelectedRows = [];

    if (rowsData && rowsData.length && selectedRows.length !== rowsData.length) {
      newSelectedRows = rowsData.filter((item) => item.deactivated !== 't').slice();
    }

    this.setState({ selectedRows: newSelectedRows });
  }

  onChangeRowSelectCallback() {
    if (typeof this.props.onChangeRowSelect === 'function') {
      this.props.onChangeRowSelect({
        selectedRows: this.state.selectedRows,
      });
    }
  }

  toggleColVisibility(colKey) {
    let newVisibleColumnKeys = null;

    if (!colKey) {
      // Reset
      newVisibleColumnKeys = this.getInitialVisibleColumnKeys();
    } else {
      newVisibleColumnKeys = this.state.visibleColumnKeys ? this.state.visibleColumnKeys.slice() : [];

      if (newVisibleColumnKeys.includes(colKey)) {
        newVisibleColumnKeys = newVisibleColumnKeys.filter((visibleKey) => {
          if (visibleKey !== colKey) {
            return true;
          }
          return false;
        });
      } else {
        newVisibleColumnKeys.push(colKey);
      }

      // Store visible column keys into the local storage
      const localStorageVisibleColumnKeys = JSON.parse(localStorage.getItem('CompiancyVisibleColumnKeys') || '{}');
      const localStorageVisibleColumnKeysUpdated = {
        ...localStorageVisibleColumnKeys,
        [this.props.dataType]: newVisibleColumnKeys,
      };
      localStorage.setItem('CompiancyVisibleColumnKeys', JSON.stringify(localStorageVisibleColumnKeysUpdated));
    }

    this.setState({ visibleColumnKeys: newVisibleColumnKeys });
  }

  render() {
    let tableWrapClassName = 'data-table-wrap';
    if (this.props.definitions.TABLE.className) {
      tableWrapClassName += ` ${this.props.definitions.TABLE.className}`;
    }
    if (this.props.className) {
      tableWrapClassName += ` ${this.props.className}`;
    }
    // if (this.state.rowsData.length === 0) {
    //     return false;
    // }

    let columnDefinitions = this.props.definitions.COLUMN;
    if (this.props.hideActionCheckboxes) {
      columnDefinitions = columnDefinitions.filter((def) => def.label !== 'Row Selector');
    }
    if (this.props.hideActionControls) {
      columnDefinitions.find((colDef) => colDef.label === 'Actions').hideActionControls = true;
    } else {
      const actionsColumnDefinition = columnDefinitions.find((colDef) => colDef.label === 'Actions');
      if (actionsColumnDefinition) actionsColumnDefinition.hideActionControls = false;
    }

    return (
      <div className={`${tableWrapClassName} ${this.props.className || ''}`}>
        <SortedData
          serverPagination={this.props.serverPagination}
          sortKey={this.state.sortKey}
          sortOrder={this.state.sortOrder}
          data={this.state.rowsData}
          colDefs={this.props.definitions.COLUMN}
        >
          {({ sortedData }) => {
            return (
              <PaginatedData rowsPerPage={this.state.rowsPerPage} currentPage={this.state.currentPage} data={sortedData} serverPagination={this.props.serverPagination}>
                {({ paginatedData }) => {
                  return (
                    <React.Fragment>
                      <div className="data-table-scrollbox" id="data-table-scrollbox">
                        <div className="data-table">
                          <DataTableHeader
                            colDefs={columnDefinitions}
                            customTableProps={{
                              ...this.props.customTableProps,
                              onChangeSort: ({ sortKey, sortOrder }) => {
                                if (this.props.serverPagination) {
                                  this.props.serverSort.setSort({
                                    sort_by: sortKey,
                                    sort_direction: sortOrder,
                                  });
                                }
                                this.props.setSortKey && this.props.setSortKey(sortKey);
                                this.props.setSortOrder && this.props.setSortOrder(sortOrder);
                                this.setState({
                                  sortKey,
                                  sortOrder,
                                });
                              },
                              doSelectAll: () => this.doSelectAll(),
                              rowsData: this.state.rowsData,
                              selectedRows: this.state.selectedRows,
                              sortKey: this.state.sortKey,
                              sortOrder: this.state.sortOrder,
                              colDefs: this.props.definitions.COLUMN,
                              visibleColumnKeys: this.state.visibleColumnKeys,
                              onChangeVisibility: (colKey) => this.toggleColVisibility(colKey),
                            }}
                            className="custom-header-class"
                            visibleColumnKeys={this.state.visibleColumnKeys}
                          />
                          <DataTableRows
                            onRowClick={this.props.onRowClick}
                            allowDisable={this.props.allowDisable || false}
                            rowsData={paginatedData}
                            colDefs={columnDefinitions}
                            customTableProps={{
                              ...this.props.customTableProps,
                              onRowSelect: ({ rowData }) => this.onRowSelect({ rowData }),
                              selectedRows: this.state.selectedRows,
                            }}
                            className="table-class-name"
                            visibleColumnKeys={this.state.visibleColumnKeys}
                          />
                        </div>
                        {!this.state.rowsData && <div className={styles['no-data-available']}></div>}
                        {this.state.rowsData && this.state.rowsData.length === 0 && <div className={styles['no-data-available']}>No data available</div>}
                      </div>
                      <div className="table-footer">
                        {!this.props.hideRowsPage && (
                          <RowsPerPageSelector
                            rowsPerPageOpts={[5, 10, 20, 50, 100]}
                            rowsPerPage={this.state.rowsPerPage}
                            onChangeRowsPerPage={(rowsPerPage) => {
                              if (this.props.dataType === SUPER_ADMIN_ORGANIZATIONS) {
                                this.props.setRowsPerPage(rowsPerPage);
                                this.setState({
                                  rowsPerPage,
                                  currentPage: 0,
                                });
                              } else {
                                const payload = {
                                  dataType: this.props.dataType,
                                  rowsPerPage,
                                  currentPage: 0,
                                };
                                this.props.setRowsPerPageAction(payload);
                                this.setState({
                                  rowsPerPage,
                                  currentPage: 0,
                                });
                              }
                            }}
                          />
                        )}
                        {!this.props.hideRowsPage && (
                          <PaginationSelector
                            dataSize={this.props.dataSize ? this.props.dataSize : this.state.rowsData ? this.state.rowsData.length : 0}
                            currentPage={this.state.currentPage}
                            rowsPerPage={this.state.rowsPerPage}
                            onChangeCurrentPage={(currentPage) => {
                              if (this.props.dataType === SUPER_ADMIN_ORGANIZATIONS) {
                                this.props.setPage(currentPage);
                                this.setState({
                                  currentPage,
                                });
                              } else {
                                const payload = {
                                  dataType: this.props.dataType,
                                  currentPage,
                                };
                                this.props.setPaginationAction(payload);
                                this.setState({
                                  currentPage,
                                });
                              }
                            }}
                          />
                        )}
                        {this.props.hideRowsPage && this.props.serverPagination && (
                          <PaginationSelector
                            dataSize={this.props.dataSize || 0}
                            currentPage={this.state.currentPage}
                            rowsPerPage={this.state.rowsPerPage}
                            onChangeCurrentPage={(currentPage) => {
                              const payload = {
                                dataType: this.props.dataType,
                                currentPage,
                              };
                              this.props.setPaginationAction(payload);
                              this.setState({
                                currentPage,
                              });
                            }}
                          />
                        )}
                      </div>
                    </React.Fragment>
                  );
                }}
              </PaginatedData>
            );
          }}
        </SortedData>
      </div>
    );
  }

  static propTypes = {
    hiddenColumns: PropTypes.array,
    setSelectedRows: PropTypes.func,
    className: PropTypes.string,
    rowsData: PropTypes.array,
    definitions: PropTypes.shape({
      TABLE: PropTypes.object.isRequired,
      COLUMN: PropTypes.array.isRequired,
    }).isRequired,
    onRowClick: PropTypes.func,
    allowDisable: PropTypes.bool,
    customTableProps: PropTypes.object,
    onChangeRowSelect: PropTypes.func,
    DatatableReducer: PropTypes.object,
    dataType: PropTypes.string,
    dataSize: PropTypes.number,
    serverPagination: PropTypes.bool,
    serverSort: PropTypes.object,
    hideRowsPage: PropTypes.bool,
    setRowsPerPageAction: PropTypes.func,
    setPaginationAction: PropTypes.func,
    hideActionControls: PropTypes.bool,
    setRowsPerPage: PropTypes.func,
    setPage: PropTypes.func,
    hideActionCheckboxes: PropTypes.bool,
    setSortKey: PropTypes.func,
    setSortOrder: PropTypes.func,
  };
}

const mapStateToProps = (state) => ({
  DatatableReducer: state.DatatableReducer,
});

const mapDispatchToProps = {
  setRowsPerPageAction,
  setPaginationAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(FullDataTable);
