import React from 'react';
import styles from './Label.module.scss';

const Label = (props) => {
  const { backgroundColor, color, icon, children, margin, borderRadius = '5px', padding = '3px 10px', fontSize = '14px', fontWeight = 'normal', height = 'fit-content' } = props;

  return (
    <div
      className={styles.label}
      style={{
        backgroundColor,
        color,
        margin,
        borderRadius,
        padding,
        fontSize,
        fontWeight,
        height,
      }}
    >
      {icon && (
        <span className={styles.icon}>
          <i className={icon} />
        </span>
      )}
      {children}
    </div>
  );
};

export default Label;
