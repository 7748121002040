import React, { useState, useEffect, useRef } from 'react';
import { makeDeepCopy } from 'helpers/utils';
import classNames from 'classnames';
import styles from './Dropdown.module.scss';
import Checkbox from '../Checkbox/Checkbox';

interface IOption {
  id: string;
  label: string;
  checked: boolean;
}

interface IDropdown {
  button?: boolean;
  configuration?: boolean;
  dropdownHeader?: boolean;
  dropdowHeaderTitle?: string;
  multipleSelect?: boolean;
  options?: IOption[];
  onChange?: (options: IOption[]) => void;
}

const defaultProps = {
  button: true,
  configuration: true,
  dropdownHeader: true,
  dropdowHeaderTitle: 'Add Title',
  multipleSelect: true,
  options: [{ id: '1', label: 'Add Label', checked: false }],
  onChange: (options: []) => false,
};

function Dropdown({ button, configuration, dropdownHeader, dropdowHeaderTitle, multipleSelect, options, onChange }: IDropdown) {
  const wrapperRef = useRef(null);

  const [dropdownOptions, setDropdownOptions] = useState(options);
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  useEffect(() => {
    if (dropdownOptions && dropdownOptions.length > 0) {
      onChange(dropdownOptions);
    }
  }, [dropdownOptions]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsMenuVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  const areAllCheckboxesChecked = (dropdownOptions) => {
    if (dropdownOptions.some((dropdownOption) => !dropdownOption.toggleAll && !dropdownOption.checked)) return false;
    return true;
  };

  const handleCheckbox = (e, optionId) => {
    const dropdownOptionsCopy = makeDeepCopy(dropdownOptions);
    const currentOption = dropdownOptionsCopy.find((opt) => opt.id === optionId);

    if (currentOption.toggleAll) {
      // toggle all checkbox
      setDropdownOptions(
        dropdownOptionsCopy.map((dropdownOption) => {
          dropdownOption.checked = e.target.checked;
          return dropdownOption;
        })
      );
      return;
    }

    // other than toggle all checkbox
    currentOption.checked = e.target.checked;
    const toggleAllCheckbox = dropdownOptionsCopy.find((opt) => opt.toggleAll);
    toggleAllCheckbox.checked = areAllCheckboxesChecked(dropdownOptionsCopy);
    setDropdownOptions(dropdownOptionsCopy);
  };

  const toggleMenu = () => {
    setIsMenuVisible(!isMenuVisible);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      toggleMenu();
    }
  };

  const style1 = classNames(styles['config-button'], styles.active);
  const style2 = classNames(styles['config-button-cog'], 'fal fa-cog');
  const style3 = classNames(styles['config-button-sort-down'], 'fas fa-sort-down');

  return (
    <div className={styles.dropdown} ref={wrapperRef} data-testid="dropdown">
      {button && configuration && (
        <div className={isMenuVisible ? style1 : styles['config-button']} onClick={toggleMenu} tabIndex={0} role="button" onKeyPress={handleKeyPress}>
          <i className={style2} />
          <i className={style3} />
        </div>
      )}

      {isMenuVisible && (
        <div className={styles.menu}>
          {dropdownHeader && <div className={styles['menu-header']}>{dropdowHeaderTitle}</div>}

          {multipleSelect && (
            <div className={styles['menu-body']}>
              {dropdownOptions &&
                dropdownOptions.length > 0 &&
                dropdownOptions.map((option) => (
                  <Checkbox
                    id={option.id}
                    key={option.id}
                    name="dropdown"
                    onChange={(e) => handleCheckbox(e, option.id)}
                    checked={option.checked}
                    labelText={option.label}
                    className={styles.checkbox}
                  />
                ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

Dropdown.defaultProps = defaultProps;

export default Dropdown;
