import React, { useEffect, useState, useRef } from 'react';

import Cookies from 'js-cookie';

import Select from 'components/Select/Select';
import FormElement from 'components/FormElement/FormElement';
import { DateInput } from 'components/DateInput/DateInput';
import SearchInput from 'components/SearchInput/SearchInput';

import AppLayoutWrapFull from 'components/AppLayoutWrapFull';
import FullDataTable from 'components/DataTable/FullDataTable';
import TableDefOrganizations from 'table-defs/TableDefOrganizations';
import PageHeader from 'components/PageHeader/PageHeader';
// import PageHeaderActions from "components/PageHeader/components/PageHeaderActions/PageHeaderActions";
// import useFilterRows from "helpers/customHooks/useFilterRows";
import { toggleDropdowns } from 'helpers/utils';

import { loadOrganizationsAction, setPaginationAction } from 'store/actions/Actions';

import { SUPER_ADMIN_ORGANIZATIONS } from 'helpers/constants/DataTableConstants';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks';
import ActionStatusConstants from 'helpers/ActionStatusConstants';

import Spinner from 'components/Spinner/Spinner';
import styles from './Dashboard.module.scss';

function DashboardSuper() {
  const dispatch = useAppDispatch();
  const DatatableReducer = useAppSelector((state) => state.DatatableReducer);
  const UserOrgSiteReducer = useAppSelector((state) => state.UserOrgSiteReducer);
  const SuperUserReducer = useAppSelector((state) => state.SuperUserReducer);

  const navigate = useNavigate();

  const [orgs, setOrgs] = useState(SuperUserReducer.organizations);
  const [page, setPage] = useState(
    DatatableReducer.datatable && DatatableReducer.datatable[SUPER_ADMIN_ORGANIZATIONS] && DatatableReducer.datatable[SUPER_ADMIN_ORGANIZATIONS].page
      ? DatatableReducer.datatable[SUPER_ADMIN_ORGANIZATIONS].page
      : 0
  );
  const [rowsPerPage, setRowsPerPage] = useState(
    DatatableReducer.datatable && DatatableReducer.datatable[SUPER_ADMIN_ORGANIZATIONS] && DatatableReducer.datatable[SUPER_ADMIN_ORGANIZATIONS].rowsPerPage
      ? DatatableReducer.datatable[SUPER_ADMIN_ORGANIZATIONS].rowsPerPage
      : 10
  );
  const [total, setTotal] = useState(0);
  const [sortBy, setSort] = useState({
    sort_by: 'name',
    sort_direction: 'ASC',
  });
  const [filterBy, setFilter] = useState({
    name: undefined,
    coach_id: undefined,
    seal_granted_at: undefined,
    seal_expires_at: undefined,
    active: undefined,
  });
  const [coach, setCoach] = useState<boolean | any>(false);
  const [isLoading, setLoading] = useState(true);
  const [sortKey, setSortKey] = useState(DatatableReducer.datatable[SUPER_ADMIN_ORGANIZATIONS]?.sortKey);
  const [sortOrder, setSortOrder] = useState(DatatableReducer.datatable[SUPER_ADMIN_ORGANIZATIONS]?.sortOrder);

  useEffect(() => {
    const timeout = window.setTimeout(
      () => {
        dispatch(
          loadOrganizationsAction({
            rowsPerPage,
            page,
            ...sortBy,
            filterBy,
          })
        );
        dispatch(
          setPaginationAction({
            dataType: SUPER_ADMIN_ORGANIZATIONS,
            currentPage: page,
          })
        );
      },
      filterBy.name ? 300 : 0
    );
    return () => {
      window.clearTimeout(timeout);
    };
  }, [page, sortBy, filterBy]);

  useEffect(() => {
    if (SuperUserReducer.organizations) {
      setOrgs(SuperUserReducer.organizations);
    }
    if (SuperUserReducer.count) {
      setTotal(SuperUserReducer.count);
    }
  }, [SuperUserReducer.organizations, SuperUserReducer.page, SuperUserReducer.count]);

  const didMount = useRef(false);
  useEffect(() => {
    if (didMount.current) {
      dispatch(
        setPaginationAction({
          dataType: SUPER_ADMIN_ORGANIZATIONS,
          currentPage: 0,
        })
      );
      setPage(0);
    } else {
      didMount.current = true;
    }
  }, [filterBy]);

  useEffect(() => {
    dispatch({
      type: 'SET_DATATABLE',
      payload: {
        datatable: {
          [SUPER_ADMIN_ORGANIZATIONS]: {
            rowsPerPage,
            page,
            sortKey,
            sortOrder,
          },
        },
      },
    });
  }, [rowsPerPage, page, sortKey, sortOrder]);

  useEffect(() => {
    if (SuperUserReducer.status === ActionStatusConstants.SUCCESS) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [SuperUserReducer.organizations]);

  const handleSearch = (e) => {
    setFilter({
      ...filterBy,
      name: e.target.value.toLowerCase(),
    });
  };

  const handleCoachFilter = (e) => {
    setFilter({
      ...filterBy,
      coach_id: e.target.value || undefined,
    });
    setCoach(SuperUserReducer.coaches && SuperUserReducer.coaches.find((coach) => coach.id === e.target.value.toString()));
  };

  const clearFilters = () => {
    setFilter({
      name: undefined,
      coach_id: undefined,
      seal_granted_at: undefined,
      seal_expires_at: undefined,
      active: undefined,
    });
    setCoach(false);
  };

  const setRowsPerPageFunc = (rowsPerPage) => {
    setRowsPerPage(rowsPerPage);
    dispatch(
      loadOrganizationsAction({
        rowsPerPage,
        page: 0,
        ...sortBy,
        filterBy,
      })
    );
  };

  const coachDropdownRef = React.createRef();
  const statusDropdownRef = React.createRef();

  return (
    <AppLayoutWrapFull onClick={toggleDropdowns([coachDropdownRef, statusDropdownRef])}>
      <div>
        <PageHeader loadStats={false} title="Organizations Overview">
          <div className={styles.pageHeader}>
            <div>
              <SearchInput onChange={handleSearch} value={filterBy.name || ''} size="md" className={styles.search} />
            </div>
            <div className={styles.right}>
              <div>
                <FormElement className={styles['type-filter']} htmlFor="rem">
                  <Select
                    value={coach ? `${coach.firstname} ${coach.lastname}` : 'All Coaches'}
                    placeholder="Coach name"
                    size="md"
                    onClick={handleCoachFilter}
                    dropdownToggleRef={coachDropdownRef}
                  >
                    <li value={undefined} key="all">
                      All Coaches
                    </li>
                    {SuperUserReducer.coaches &&
                      SuperUserReducer.coaches.map((el) => (
                        <li value={el.id} key={el.id}>
                          {`${el.firstname} ${el.lastname}`}
                        </li>
                      ))}
                  </Select>
                </FormElement>
              </div>
              <div>
                <FormElement htmlFor="date-range1">
                  <DateInput
                    size="md"
                    value={filterBy.seal_granted_at || ''}
                    onChange={(e) => {
                      setFilter({
                        ...filterBy,
                        seal_granted_at: e.target.value,
                      });
                    }}
                  />
                </FormElement>
              </div>
              <div>
                <FormElement htmlFor="date-range2">
                  <DateInput
                    size="md"
                    value={filterBy.seal_expires_at || ''}
                    onChange={(e) => {
                      setFilter({
                        ...filterBy,
                        seal_expires_at: e.target.value,
                      });
                    }}
                  />
                </FormElement>
              </div>
              <div>
                <Select
                  labels={{ 0: 'All', 1: 'Active', 2: 'Inactive' }}
                  value={filterBy.active}
                  className={styles.button}
                  placeholder="Status"
                  size="md"
                  dropdownToggleRef={statusDropdownRef}
                  onClick={(e) => {
                    setFilter({
                      ...filterBy,
                      active: e.target.value,
                    });
                  }}
                >
                  <li value={0} key="1-status">
                    All
                  </li>
                  <li value={1} key="2-status">
                    Active
                  </li>
                  <li value={2} key="3-status">
                    Inactive
                  </li>
                </Select>
              </div>
              <span className={styles.clear} onClick={clearFilters} aria-hidden="true">
                <i aria-hidden="true" className={`${styles['reset-filters']} far fa-times`} />
              </span>
            </div>
          </div>
        </PageHeader>

        {isLoading ? (
          <Spinner />
        ) : (
          <div style={{ margin: '15px 0' }}>
            <FullDataTable
              rowsData={orgs}
              definitions={TableDefOrganizations}
              dataType={SUPER_ADMIN_ORGANIZATIONS}
              setSortKey={setSortKey}
              setSortOrder={setSortOrder}
              setRowsPerPage={setRowsPerPageFunc}
              setPage={setPage}
              serverPagination
              serverSort={{ sortBy, setSort }}
              dataSize={total}
              onRowClick={function onRowClick(e) {
                if (e.currentTarget.classList.contains('col-actions') || e.currentTarget.classList.contains('col-row-selector')) {
                  return;
                }
                // console.log(this.props.rowData.id);
                // set cookie to selected org
                Cookies.set('org', this.props.rowData.id);
                // force reload new page
                dispatch({
                  type: 'SET_USER_ORG_SITE_SUCCESS',
                  payload: {
                    selected: {
                      ...UserOrgSiteReducer.selected,
                      org: this.props.rowData.id,
                      site: undefined,
                    },
                  },
                });
                navigate(`/organization/${this.props.rowData.id}`);
                // window.location.href = `/organization/${this.props.rowData.id}`;
              }}
            />
          </div>
        )}
      </div>
    </AppLayoutWrapFull>
  );
}

export default DashboardSuper;
