import React, { useState } from 'react';
import { connect } from 'react-redux';

import styles from './ApproveDocumentModal.module.scss';

import Modal from 'components/Modal/Modal';
import Button from 'components/Button/Button';
import Radio from 'components/Radio/Radio';

import { approveDocumentAction } from 'store/actions/Actions';

/**
 *
 * @param {Function} setShowModal - toggles the modal
 */
const ApproveDocumentModal = (props) => {
  const [state, setState] = useState({
    requireAttesatation: false,
  });

  const handleChange = (inputName) => (e) => {
    e.persist();

    setState((s) => ({
      ...s,
      [inputName]: e.target.type === 'checkbox' ? e.target.checked : e.target.value === 'true' ? true : e.target.value === 'false' ? false : e.target.value,
    }));
  };

  return (
    <Modal setShowModal={props.setShowModal} className={styles.modal}>
      <div className={styles['modal-header']}>
        <h2 className={styles.heading}>Approve Document</h2>
      </div>

      <div className={styles['modal-body']}>
        <div className={styles['heading-line']}>Does this document require users to attest to it ?</div>
        <div>
          <Radio
            checked={state.requireAttesatation}
            labelText={
              <span>
                <strong>Yes request attestation</strong>
              </span>
            }
            onChange={handleChange('requireAttesatation')}
            value={true}
          />
          <Radio
            checked={!state.requireAttesatation}
            labelText={
              <span>
                <strong>No, do not request attestation</strong>
              </span>
            }
            onChange={handleChange('requireAttesatation')}
            value={false}
          />
          <div className={styles['existing-folder']}></div>
        </div>
      </div>

      <div className={styles['modal-footer']}>
        <Button className={styles.button} text="NEVERMIND" onClick={() => props.setShowModal(false)} color="secondary" />
        <Button
          className={styles.button}
          text="YES, APPROVE"
          onClick={() => {
            if (props.documentForApproval) {
              props.approveDocumentAction({
                id: props.documentForApproval.id,
                requires_attest: state.requireAttesatation,
              });
              props.setShowModal(false);
            }
          }}
        />
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  folders: state.FoldersReducer.folders,
});

const mapDispatchToProps = {
  approveDocumentAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ApproveDocumentModal);
