import React, { useEffect, useState } from 'react';

import FormElement from 'components/FormElement/FormElement';
import Button from 'components/Button/Button';
import Checkbox from 'components/Checkbox/Checkbox';
// import Select from "components/Select/Select";

import styles from './OrgSiteDept.module.scss';

import { country_list } from 'helpers/utils';
import { connect } from 'react-redux';
import { loadSuperadminsAction } from 'store/actions/Actions';

const OrgSiteDeptEditForm = (props) => {
  const { loadSuperadminsAction, SuperadminsReducer } = props;
  const [errors, setErrors] = useState({ name: undefined });

  const formData = props.formData;
  const setFormData = props.setFormData;
  const updateOrgAction = props.updateOrgAction;
  const updateSiteAction = props.updateSiteAction;
  const isSuper = props.isSuper;
  const isCoach = props.isCoach;
  const isOrg = props.formData.type === 'org' ? true : false;
  const currentOrg = props.currentOrg;

  const superadmins = SuperadminsReducer ? SuperadminsReducer.superadmins : [];

  useEffect(() => {
    setErrors((s) => ({ ...s, name: undefined }));
    if ((isSuper || isCoach) && isOrg && currentOrg) {
      loadSuperadminsAction(currentOrg.Id);
      setFormData((s) => ({
        ...s,
        coach_id: currentOrg.coach_id ? currentOrg.coach_id : '0',
      }));
    }
  }, [isSuper, isCoach, isOrg, currentOrg]);

  const handleChange = (e) => {
    if (e.target.dataset && e.target.dataset.name && e.target.dataset.value) {
      // for country
      setFormData({
        ...formData,
        [e.target.dataset.name]: e.target.dataset.value,
      });
    } else {
      setErrors((s) => ({ ...s, name: undefined }));
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleSiteLimitChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: Math.max(e.target.value, 0),
    });
  };

  const handleSSOChange = (e) => {
    setFormData({
      ...formData,
      sso_enabled: e.target.checked,
    });
  };

  const handleSave = () => {
    if (formData.company_name === '') {
      setErrors({ name: 'Name is required!' });
      return false;
    }
    window.scrollTo(0, 0);
    if (formData.type === 'site') {
      updateSiteAction({
        ...formData,
        active: true,
        name: formData.company_name,
      });
    }

    if (formData.type === 'org') {
      updateOrgAction({
        ...formData,
        active: true,
        name: formData.company_name,
      });
    }
  };

  // const countryLabels = country_list.reduce((acc, curr) => {
  //     acc[curr.id] = curr.name;
  //     return acc;
  // }, {});

  return (
    <>
      <FormElement errorMessage={errors.name} labelText="NAME" htmlFor="company_name" smallText="*">
        <input placeholder="Company Name" name="company_name" type="text" maxLength={70} value={formData.company_name} onChange={(e) => handleChange(e)} />
      </FormElement>

      <FormElement labelText="ADDRESS" htmlFor="address_1">
        <input placeholder="Company Address Line 1" type="text" name="address_1" id="address_1" value={formData.address_1} onChange={(e) => handleChange(e)} />
      </FormElement>

      <FormElement htmlFor="address_2">
        <input placeholder="Company Address Line 2" type="text" name="address_2" id="address_2" value={formData.address_2} onChange={(e) => handleChange(e)} />
      </FormElement>

      <FormElement labelText="CITY" htmlFor="city">
        <input placeholder="City" id="city" name="city" type="text" value={formData.city} onChange={(e) => handleChange(e)} />
      </FormElement>

      <FormElement labelText="REGION/STATE" htmlFor="region">
        <input placeholder="Region" name="region" id="region" type="text" value={formData.region} onChange={(e) => handleChange(e)} />
      </FormElement>

      <FormElement labelText="COUNTRY" htmlFor="country">
        <div className={styles.input_holder}>
          <select className={styles.input} name="country_code" value={props.country} onChange={handleChange}>
            {country_list.map((item) => (
              <option key={item.id} data-name="country_code" data-value={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
      </FormElement>

      {/* <FormElement labelText="COUNTRY" htmlFor="country">
                <Select
                    value={props.country || "Please Select a country"}
                    placeholder="Country"
                    name="country"
                    onClick={e => handleChange(e)}
                    dropdownToggleRef={props.countryDropdownToggleRef || null}
                    labels={countryLabels}
                >
                    {country_list_small.map(item => (
                        <li
                            key={item.id}
                            data-value={item.id}
                            data-name="country_code"
                        >
                            {item.name}
                        </li>
                    ))}
                </Select>
            </FormElement> */}

      <FormElement labelText="POSTAL CODE" htmlFor="postal_code">
        <input placeholder="Postal Code" type="text" id="postal_code" name="postal_code" value={formData.postal_code} onChange={(e) => handleChange(e)} />
      </FormElement>

      {(isSuper || isCoach) && isOrg ? (
        <FormElement labelText="COACH" htmlFor="coach">
          <div className={styles.input_holder}>
            <select className={styles.input} name="coach_id" value={formData.coach_id} onChange={handleChange}>
              <option key={'0'} data-name="coach_id" data-value={'0'} value={'0'}>
                {'Select Coach'}
              </option>
              {superadmins &&
                superadmins.map((superadmin) => (
                  <option key={superadmin.id} data-name="coach" data-value={superadmin.id} value={superadmin.id}>
                    {`${superadmin.firstname} ${superadmin.lastname}`}
                  </option>
                ))}
            </select>
          </div>
        </FormElement>
      ) : null}

      {isSuper && isOrg ? (
        <>
          <FormElement labelText="SITE LIMIT" htmlFor="site_limit">
            <input placeholder="Site Limit" name="site_limit" id="site_limit" min="0" type="number" value={formData.site_limit} onChange={(e) => handleSiteLimitChange(e)} />
          </FormElement>
          <FormElement htmlFor="sso_enabled">
            <Checkbox
              key="sso_enabled"
              name="sso_enabled"
              id="sso_enabled"
              className={styles.checkbox}
              labelText="Single sign-on enabled (Azure Active Directory)"
              checked={formData.sso_enabled || false}
              onChange={handleSSOChange}
            />
          </FormElement>
        </>
      ) : (
        ''
      )}

      <br />
      <br />

      <div className={styles['org-site-bottom-buttons']}>
        <Button
          text="CLEAR"
          color="secondary"
          onClick={() =>
            setFormData({
              company_name: '',
              id: '',
              address_1: '',
              type: '',
              address_2: '',
              city: '',
              region: '',
              country_code: '',
              postal_code: '',
              site_limit: '',
              sso_enabled: false,
            })
          }
        />
        <Button icon="fal fa-save" text="SAVE CHANGES" onClick={handleSave} />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  SuperadminsReducer: state.SuperadminsReducer,
});

const mapDispatchToProps = {
  loadSuperadminsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrgSiteDeptEditForm);
