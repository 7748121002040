import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Cookies from 'js-cookie';

import logo from 'assets/img/cg-logo-large.png';

import FormElement from 'components/FormElement/FormElement';
import Select from 'components/Select/Select';
import SelectWithSearch from 'components/SelectWithSearch/SelectWithSearch';
import { Button } from 'components/Button/Button';

import { toggleDropdowns } from 'helpers/utils';
import { getPermissions } from 'helpers/utils/getPermissions/getPermissions';

import { loadOrgModulesAction, loadUserOrgSiteAction } from 'store/actions/Actions';
import { useAppDispatch, useAppSelector } from 'hooks';

import WelcomePanel from '../components/WelcomePanel/WelcomePanel';

import styles from './SelectSite.module.scss';

function SelectSite() {
  const dispatch = useAppDispatch();
  const UserOrgSiteReducer = useAppSelector((state) => state.UserOrgSiteReducer);
  const AuthReducer = useAppSelector((state) => state.AuthReducer);

  const [orgs, setOrgs] = useState({});
  const [sites, setSites] = useState({});
  const [selected_org, setSelectedOrg] = useState('');
  const [selected_site, setSelectedSite] = useState(undefined);
  const [stateErrors, setStateErrors] = useState({
    site: undefined,
    org: undefined,
  });
  const { hash } = useParams();

  const [roles, setRoles] = useState({
    is_officer: false,
    is_super: false,
    is_coach: false,
    is_org_admin: false,
  });

  useEffect(() => {
    dispatch(loadUserOrgSiteAction());
  }, [loadUserOrgSiteAction]);

  useEffect(() => {
    if (UserOrgSiteReducer.organizations) {
      setOrgs(UserOrgSiteReducer.organizations);
    }
  }, [UserOrgSiteReducer.organizations]);

  useEffect(() => {
    if (AuthReducer.userData) {
      const data = JSON.parse(JSON.stringify(AuthReducer.userData));
      const myRoles = getPermissions(data.role, selected_org, selected_site);
      if (myRoles) {
        setRoles(myRoles);
      }
    }
  }, [AuthReducer.userData, selected_org, selected_site]);

  const selectSiteDropdownToggleRef = React.createRef();

  const showAll = selected_org && (roles.is_officer || roles.is_super || roles.is_org_admin || roles.is_coach);

  const handleOrgSelect = (data) => {
    try {
      if (data.id) {
        setSelectedOrg(data.id);
        setSelectedSite(showAll ? '0' : '');
        setSites(orgs[data.id].sites);
        setStateErrors((s) => ({ ...s, org: '', site: '' }));
        dispatch({
          type: 'SET_USER_ORG_SITE_SUCCESS',
          payload: {
            selected: {
              ...UserOrgSiteReducer.selected,
              ui_version: UserOrgSiteReducer.organizations[data.id].ui_version,
            },
          },
        });
      } else {
        setSelectedOrg('');
        setSites([]);
        setSelectedSite('');
      }
    } catch (error) {
      console.log('error with org select', error);
    }
  };

  useEffect(() => {
    setSelectedSite(() => (showAll ? '0' : ''));
  }, [showAll]);

  const handleSiteSelect = (e) => {
    try {
      setSelectedSite(e.target.value);
      setStateErrors((s) => ({ ...s, site: '' }));
    } catch {
      console.log('error with site select');
    }
  };

  const handleLogin = () => {
    // set cookies
    if (selected_org === '') {
      setStateErrors((s) => ({ ...s, org: 'Please, select Organization!' }));
    }
    if (selected_site === '') {
      setStateErrors((s) => ({ ...s, site: 'Please, select Site!' }));
    }

    if (selected_site !== '' && selected_org !== '') {
      Cookies.set('org', selected_org);
      if (selected_site !== '0') {
        Cookies.set('site', selected_site);
      } else {
        Cookies.remove('site');
      }
      if (hash) {
        const link = window.atob(hash);
        window.location.href = `/${link}`;
      } else {
        dispatch(loadUserOrgSiteAction());
        dispatch({
          type: 'SET_USER_ORG_SITE_SUCCESS',
          payload: {
            selected: {
              ...roles,
              org: selected_org,
              site: selected_site,
            },
          },
        });
        dispatch(loadOrgModulesAction());
        // const { is_admin, is_coach, is_super, is_officer, is_org_admin, is_site_admin, ui_version } = UserOrgSiteReducer.selected;
        // if (ui_version === '1') {
        //   if (is_admin || is_coach || is_super || is_officer || is_org_admin || is_site_admin) {
        //     window.location.href = '/my-dashboard';
        //   } else {
        //     window.location.href = '/my-tasks';
        //   }
        // } else {
        //   window.location.href = '/';
        // }
        window.location.href = '/';
      }
    }
  };

  const orgsList = {};
  for (const key of Object.keys(orgs)) {
    orgsList[key] = orgs[key].name;
  }

  const sitesList = {};
  sitesList[0] = showAll ? 'All' : '';
  for (const key of Object.keys(sites)) {
    sitesList[key] = sites[key];
  }

  const sortedOrgs = Object.keys(orgs)
    .map((key) => ({
      ...orgs[key],
      id: key,
      name: `${orgs[key].name}${orgs[key].customer_id ? ` - ${orgs[key].customer_id}` : ''}`,
    }))
    .sort((a, b) => (a.name > b.name ? 1 : -1));
  const sortedSites = Object.keys(sites).sort((a, b) => (a > b ? 1 : -1));

  return (
    <WelcomePanel onClick={toggleDropdowns([selectSiteDropdownToggleRef])}>
      <div className={styles['login-panel-heading']}>
        <img className={styles.logo} src={logo} alt="" />
      </div>

      <div className={styles['site-select-panel']}>
        <p className={styles.welcome}>Welcome to The Guard!</p>
        <FormElement labelText="Organization" htmlFor="org-select" className={styles['form-org-select']} errorMessage={stateErrors.org}>
          <SelectWithSearch value={selected_org} onChange={handleOrgSelect} options={sortedOrgs} placeholder="Select Organization" />
        </FormElement>

        <FormElement labelText="Site" htmlFor="site-select" className={styles['form-site-select']} errorMessage={stateErrors.site}>
          <Select placeholder="Select Site" labels={sitesList} value={selected_site} onClick={handleSiteSelect} dropdownToggleRef={selectSiteDropdownToggleRef}>
            {showAll ? (
              <li key="org_id-all" value="0">
                All
              </li>
            ) : (
              ''
            )}
            {sortedSites.map((site) => (
              <li key={`sites_id-${site}`} value={site}>
                {sitesList[site]}
              </li>
            ))}
          </Select>
        </FormElement>
      </div>
      <div className={styles['login-panel-footer']}>
        <Button text="CLICK TO LOGIN" onClick={handleLogin} />
      </div>
    </WelcomePanel>
  );
}

export default SelectSite;
