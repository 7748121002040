import { useEffect, useState } from 'react';
import moment from 'moment';

// Filter definitions
const textColumns = new Set([
  'alert',
  'application',
  'asset_tag',
  'assignee_name',
  'description',
  'email',
  'first',
  'folder_name',
  'inventory',
  'last-action',
  'last',
  'license_number',
  'license_type',
  'location',
  'model',
  'name',
  'notes',
  'owner',
  'ownerName',
  'physical_location',
  'serial_number',
  'siteName',
  'task',
  'title',
  'training_name',
  'type',
  'url',
  'user_access',
  'user_assigned',
  'user_name',
]);

const typeColumns = [
  {
    type: 'agreement-type',
    options: ['All', 'Confidentiality', 'Business Associate'],
  },
  { 'affiliate-type': [] },
  {
    type: 'type',
    options: ['All', 'Remediation', 'Attestation', 'Question', 'Miscellaneous'],
  },
];

function testRow(row, filter, filters) {
  try {
    // Test textual fields
    if (filter === 'text' && filters.text) {
      return Object.keys(row).some((key) => {
        if (textColumns.has(key)) {
          return row[key].toLowerCase().includes(filters.text.toLowerCase());
        }
        return false;
      });
    }

    // Test rem
    if (filter === 'rem' && filters.rem) {
      return Object.keys(row).some((key) => {
        if (key === 'rem') {
          return filters.rem === 'All remediation' ? 1 : row[key].includes(filters.rem);
        }
        return false;
      });
    }

    // Test regulatory_act
    if (filter === 'regulatory_act' && filters.regulatory_act) {
      return Object.keys(row).some((key) => {
        if (key === 'regulatory_act') {
          return (filters.regulatory_act === 'All regulatory' || filters.regulatory_act === 'All programs') ? 1 : row[key] === filters.regulatory_act;
        }
        return false;
      });
    }

    // Test user
    if (filter === 'user_name' && filters.user_name) {
      return Object.keys(row).some((key) => {
        if (key === 'user_name') {
          return filters.user_name === 'All Users' ? 1 : row[key] === filters.user_name;
        }
        return false;
      });
    }

    // Test type
    if (filter === 'type' && filters.type) {
      return Object.keys(row).some((key) => {
        if (typeColumns.some(({ type }) => type === key)) {
          return filters.type === 'All task types' ? 1 : row[key] === filters.type;
        }
        return false;
      });
    }

    // Test coach
    if (filter === 'coach' && filters.coach) {
      return Object.keys(row).some((key) => {
        if (key === 'coach') {
          return filters.coach === 'All coaches' ? 1 : row[key] === filters.coach;
        }
        return false;
      });
    }

    // Test last-action
    if (filter === 'last-action' && filters['last-action']) {
      return Object.keys(row).some((key) => {
        if (key === 'last-action') {
          return filters['last-action'] === 'All' ? 1 : row[key] === filters['last-action'];
        }
        return false;
      });
    }

    // Test affiliate status
    if (filter === 'affiliate_status' && filters.affiliate_status) {
      return Object.keys(row).some((key) => {
        if (key === 'affiliate_status') {
          return filters.affiliate_status === 'All' ? 1 : row[key] === filters.affiliate_status;
        }
        return false;
      });
    }

    // Test status
    if (filter === 'status' && filters.status) {
      return Object.keys(row).some((key) => {
        if (key === 'status') {
          return filters.status === 'All' ? 1 : row[key] === filters.status;
        }
        return false;
      });
    }

    // Test start
    if (filter === 'start' && filters.start && filters.dateField) {
      if (moment(row[filters.dateField], 'YYYY-MM-DD').isSameOrAfter(filters.start)) {
        return 1;
      }
      return false;
    }

    // Test end
    if (filter === 'end' && filters.end && filters.dateField) {
      if (moment(row[filters.dateField], 'YYYY-MM-DD').isSameOrBefore(filters.end)) {
        return 1;
      }
      return false;
    }

    // Test site
    if (filter === 'site' && filters.site) {
      if (filters.selected_sites.length === 0) {
        return true;
      }
      return !!filters.selected_sites.includes(row.site);
    }

    // Test role
    if (filter === 'role' && filters.role) {
      if (filters.role === 'All') {
        return true;
      }
      return row.role_str === filters.role ? 1 : 0;
    }

    if (filter === 'role_str' && filters.role_str) {
      return row.role_str === filters.role_str ? 1 : 0;
    }

    if (filter === 'folder_name' && filters.folder_name) {
      return row.folder_name === filters.folder_name ? 1 : 0;
    }
  } catch {
    return 0;
  }
  return false;
}

function useFilterRows(filters, rowsData) {
  const [filteredRows, setFilteredRows] = useState(rowsData);

  useEffect(() => {
    if (Object.values(filters).some(Boolean)) {
      const result = rowsData?.filter((row) => {
        if (
          (!filters.text || testRow(row, 'text', filters)) &&
          (!filters.type || testRow(row, 'type', filters)) &&
          (!filters.coach || testRow(row, 'coach', filters)) &&
          (!filters.user_name || testRow(row, 'user_name', filters)) &&
          (!filters.regulatory_act || testRow(row, 'regulatory_act', filters)) &&
          (!filters.rem || testRow(row, 'rem', filters)) &&
          (!filters['last-action'] || testRow(row, 'last-action', filters)) &&
          (!filters.affiliate_status || testRow(row, 'affiliate_status', filters)) &&
          (!filters.status || testRow(row, 'status', filters)) &&
          (!filters.start || testRow(row, 'start', filters)) &&
          (!filters.site || testRow(row, 'site', filters)) &&
          (!filters.end || testRow(row, 'end', filters)) &&
          (!filters.role || testRow(row, 'role', filters)) &&
          (!filters.role_str || testRow(row, 'role_str', filters)) &&
          (!filters.folder_name || testRow(row, 'folder_name', filters))
        ) {
          return row;
        }
        return false;
      });
      setFilteredRows(result);
    } else {
      setFilteredRows(rowsData);
    }
  }, [filters, rowsData]);

  return filteredRows;
}

export default useFilterRows;
