import { push } from 'redux-first-history';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { actionApiCall } from 'helpers/api/ApiCalls';
import { redirectToQuestionnaire } from 'helpers/utils';

const getSelected = (state) => state.UserOrgSiteReducer.selected;

function* saveVendorAuditAffiliateSaga({ payload }) {
  const selected = yield select(getSelected);
  try {
    yield call(actionApiCall, {
      actionType: ActionTypeConstants.SAVE_VENDOR_AUDIT_AFFILIATE,
      data: {
        ...payload,
      },
    });
    yield put(push(redirectToQuestionnaire(payload.token, selected.ui_version)));
  } catch (err) {
    yield put(push(redirectToQuestionnaire(payload.token, selected.ui_version)));
  }
}

export default function* () {
  yield takeLatest(ActionTypeConstants.SAVE_VENDOR_AUDIT_AFFILIATE, saveVendorAuditAffiliateSaga);
}
