import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';

import styles from './Select.module.scss';

interface IOption {
  id: string | number;
  name: string;
  displayName?: string;
}

interface IProps {
  /* md/lg/fixed; default is lg */
  size?: 'md' | 'lg' | 'fixed';
  /* placeholder */
  placeholder?: string | undefined;
  /* disables select; default is false */
  disabled?: boolean;
  /* value "selected" and shown in dropdown-toggle */
  value: string;
  /* function to run when clicking on option;
  use to alter state in parent component and pass down value */
  onChange: (data: object) => void;
  /* children to render instead of options */
  options: IOption[];
}

const defaultProps = {
  size: 'lg',
  placeholder: 'Placeholder',
  disabled: false,
};

function SelectWithSearch({ size, placeholder, disabled, value, onChange, options }: IProps) {
  const wrapperRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchList, setSearchList] = useState(options);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showDropdown && wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        const tmp = options.find((opt) => opt.id === value)?.name || '';
        if (searchValue !== tmp) {
          setSearchValue('');
          onChange({});
        }
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef, showDropdown, searchValue]);

  useEffect(() => {
    setSearchValue(options.find((opt) => opt.id === value)?.name || '');
  }, [value]);

  const toggleDropdown = () => {
    if (!disabled && !showDropdown) {
      setSearchList(options);
      setShowDropdown(!showDropdown);
    }
  };

  const handleClick = (event) => {
    if (!disabled) {
      const tmp: IOption = options.find((opt) => opt.id === event.target.dataset.id);
      setSearchValue(tmp?.name || '');
      onChange(tmp);
      setShowDropdown(!showDropdown);
    }
  };

  const handleSearch = (event) => {
    if (showDropdown) {
      setSearchValue(event.target.value);
      const search = options.filter((item) => item.name.toLowerCase().includes(event.target.value.toLowerCase()));
      setSearchList(search);
    }
  };

  const className = classNames(styles['dropdown-toggle'], showDropdown ? 'show' : 'hide', styles[`select-${size}`], { [styles.disabled]: disabled });
  const menuClass = classNames(styles['dropdown-menu'], { [styles.hidden]: !showDropdown }, styles[`select-${size}`]);

  return (
    <div className={styles.dropdown} ref={wrapperRef}>
      <div className={className} role="none" onClick={toggleDropdown}>
        <input placeholder={placeholder} autoComplete="none" className={styles.input} value={searchValue} onChange={handleSearch} />
        <i className="fas fa-sort-down" />
      </div>
      <menu className={menuClass} role="none" onClick={handleClick}>
        {searchList.map((opt) => (
          <li key={opt.id} data-id={opt.id}>
            {opt.displayName || opt.name}
          </li>
        ))}
      </menu>
    </div>
  );
}

SelectWithSearch.defaultProps = defaultProps;

export default SelectWithSearch;
