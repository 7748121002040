import React, { useState } from 'react';
import Button from 'components/Button/Button';
import { randomId } from 'helpers/utils';

import styles from './SitesDeptsTree.module.scss';

const SortableItem = (item) => {
  const paddingLeft = item.type === 'dept' ? '60px' : item.type === 'org' ? '0px' : '30px';
  const className = item.type === 'dept' ? 'fal fa-hospital-user' : item.type === 'org' ? 'fal fa-briefcase-medical' : 'fal fa-hospital';

  return (
    <li className={`${styles['org-site']} ${styles[item.type]}`} style={{ paddingLeft: paddingLeft, cursor: 'pointer' }} onDoubleClick={() => item.handleEditItem(item)}>
      <span>
        <i className={item.isEditing ? `${styles['icon-editing-adjustment']} ${className}` : className} style={{ cursor: 'default' }}>
          &nbsp;
        </i>
      </span>
      <span className={styles['item-content']}>
        <strong>{item.type}:</strong>
        {item.isEditing ? (
          <>
            <form onSubmit={item.handleSaveDept}>
              <input key={item.id + item.type} size={40} maxLength={70} value={item.value} onChange={(e) => item.handleInput(e, item)} name={`${item.id}-editing`} />
              <i onClick={() => item.handleSaveInput(item)} className="fal fa-save"></i>
              <i onClick={() => item.handleCancelEdit(item)} className="fal fa-times"></i>
            </form>
            {item.isError ? <span className={styles['error-message']}>{item.errorMessage}</span> : null}
          </>
        ) : (
          <span className={styles['value']}>{item.value}</span>
        )}
      </span>
      <span>
        {item.type == 'org' && (
          <>
            <i
              onClick={() => item.handleAddUsersForm(item)}
              className={item.users && item.users.length > 0 ? `${styles['form-filled']} fal fa-users` : 'fal fa-users'}
              title="Add users"
            ></i>
            <i onClick={item.handleAddSite} className="fal fa-plus-circle" title="Add site"></i>
          </>
        )}
        {item.type === 'site' && !item.isEditing && (
          <>
            <i
              onClick={() => item.handleAddUsersForm(item)}
              className={item.users && item.users.length > 0 ? `${styles['form-filled']} fal fa-users` : 'fal fa-users'}
              title="Add users"
            ></i>
            <i onClick={() => item.handleSiteDetailsForm(item)} className={item.isFilled ? `${styles['form-filled']} fal fa-pen` : 'fal fa-pen'} title="Add site details"></i>
            <i onClick={() => item.handleDeleteSite(item)} className="fal fa-trash-alt" title="Delete site"></i>
            <i onClick={() => item.handleAddDept(item)} className="fal fa-plus-circle" title="Add department"></i>
          </>
        )}
        {item.type === 'dept' && !item.isEditing && (
          <>
            <i onClick={() => item.handleDeleteDept(item)} className="fal fa-trash-alt" title="Delete department"></i>
          </>
        )}
      </span>
    </li>
  );
};

const SortableList = (props) => {
  const items = props.items;
  const setFormData = props.setFormData;
  const formData = props.formData;
  const handleSiteDetailsForm = props.handleSiteDetailsForm;
  const handleAddUsersForm = props.handleAddUsersForm;

  const [isError, setIsError] = useState(false);

  const handleAddSite = () => {
    if (formData.sites.length < parseInt(formData.site_limit, 10)) {
      const formDataCopy = JSON.parse(JSON.stringify(formData));
      formDataCopy.sites.push({
        id: randomId(),
        parent: 1,
        type: 'site',
        name: '',
        value: '',
        isEditing: true,
        address_1: '',
        address_2: '',
        city: '',
        region: '',
        postal_code: '',
        country_code: 235,
      });
      setFormData(formDataCopy);
    } else {
      setIsError(true);
    }
  };

  const handleInput = (e, item) => {
    //item.value = e.target.value;
    if (item.type === 'site') {
      const formDataCopy = JSON.parse(JSON.stringify(formData));
      const siteIndex = formDataCopy.sites.findIndex((site) => site.id === item.id);
      formDataCopy.sites[siteIndex].name = e.target.value;
      formDataCopy.sites[siteIndex].value = e.target.value;
      setFormData(formDataCopy);
    }
    if (item.type === 'dept') {
      const formDataCopy = JSON.parse(JSON.stringify(formData));
      const siteIndex = formDataCopy.sites.findIndex((site) => site.id === item.parent);
      const deptIndex = formDataCopy.sites[siteIndex].departments.findIndex((dept) => dept.id === item.id);
      formDataCopy.sites[siteIndex].departments[deptIndex].name = e.target.value;
      formDataCopy.sites[siteIndex].departments[deptIndex].value = e.target.value;
      setFormData(formDataCopy);
    }
  };

  const handleSaveInput = (item) => {
    const formDataCopy = JSON.parse(JSON.stringify(formData));

    // Check for errors
    if (item.type === 'site') {
      const siteIndex = formDataCopy.sites.findIndex((site) => site.id === item.id);
      let nameCounter = 0;
      formDataCopy.sites.forEach((site) => {
        if (site.name.toLowerCase().trim() === item.name.toLowerCase().trim()) {
          // must go up to 2 to be an error
          nameCounter++;
        }
      });

      if (item.name === '') {
        formDataCopy.sites[siteIndex].isError = true;
        formDataCopy.sites[siteIndex].errorMessage = 'Site name is required';
      } else if (nameCounter > 1) {
        formDataCopy.sites[siteIndex].isError = true;
        formDataCopy.sites[siteIndex].errorMessage = 'The site name already exists';
      } else {
        formDataCopy.sites[siteIndex].isError = false;
        formDataCopy.sites[siteIndex].errorMessage = '';
        formDataCopy.sites[siteIndex].isEditing = false;
      }

      setFormData(formDataCopy);
    }

    if (item.type === 'dept') {
      const siteIndex = formDataCopy.sites.findIndex((site) => site.id === item.parent);
      const deptIndex = formDataCopy.sites[siteIndex].departments.findIndex((dept) => dept.id === item.id);
      let nameCounter = 0;
      formDataCopy.sites[siteIndex].departments.forEach((site) => {
        if (site.name.toLowerCase().trim() === item.name.toLowerCase().trim()) {
          // must go up to 2 to be an error
          nameCounter++;
        }
      });

      if (item.name === '') {
        formDataCopy.sites[siteIndex].departments[deptIndex].isError = true;
        formDataCopy.sites[siteIndex].departments[deptIndex].errorMessage = 'Department name is required';
      } else if (nameCounter > 1) {
        formDataCopy.sites[siteIndex].departments[deptIndex].isError = true;
        formDataCopy.sites[siteIndex].departments[deptIndex].errorMessage = 'The department name already exists';
      } else {
        formDataCopy.sites[siteIndex].departments[deptIndex].isError = false;
        formDataCopy.sites[siteIndex].departments[deptIndex].errorMessage = '';
        formDataCopy.sites[siteIndex].departments[deptIndex].isEditing = false;
      }

      setFormData(formDataCopy);
    }
  };

  const handleCancelEdit = (item) => {
    const formDataCopy = JSON.parse(JSON.stringify(formData));

    if (item.type === 'site') {
      const siteIndex = formDataCopy.sites.findIndex((site) => site.id === item.id);

      if (formDataCopy.sites[siteIndex].oldValue && formDataCopy.sites[siteIndex].oldName) {
        formDataCopy.sites[siteIndex].isEditing = false;
        formDataCopy.sites[siteIndex].isError = false;
        formDataCopy.sites[siteIndex].errorMessage = '';
        formDataCopy.sites[siteIndex].value = formDataCopy.sites[siteIndex].oldValue;
        formDataCopy.sites[siteIndex].name = formDataCopy.sites[siteIndex].oldName;
        delete formDataCopy.sites[siteIndex].oldValue;
        delete formDataCopy.sites[siteIndex].oldName;
        setFormData(formDataCopy);
      } else {
        formDataCopy.sites.splice(siteIndex, 1);
        setFormData(formDataCopy);
      }
    }
    if (item.type === 'dept') {
      const siteIndex = formDataCopy.sites.findIndex((site) => site.id === item.parent);
      const deptIndex = formDataCopy.sites[siteIndex].departments.findIndex((dept) => dept.id === item.id);

      if (formDataCopy.sites[siteIndex].departments[deptIndex].oldValue && formDataCopy.sites[siteIndex].departments[deptIndex].oldName) {
        formDataCopy.sites[siteIndex].departments[deptIndex].isEditing = false;
        formDataCopy.sites[siteIndex].departments[deptIndex].isError = false;
        formDataCopy.sites[siteIndex].departments[deptIndex].errorMessage = '';
        formDataCopy.sites[siteIndex].departments[deptIndex].value = formDataCopy.sites[siteIndex].departments[deptIndex].oldValue;
        formDataCopy.sites[siteIndex].departments[deptIndex].name = formDataCopy.sites[siteIndex].departments[deptIndex].oldName;
        delete formDataCopy.sites[siteIndex].departments[deptIndex].oldValue;
        delete formDataCopy.sites[siteIndex].departments[deptIndex].oldName;
        setFormData(formDataCopy);
      } else {
        formDataCopy.sites[siteIndex].departments.splice(deptIndex, 1);
        setFormData(formDataCopy);
      }
    }
  };

  const handleAddDept = (item) => {
    const formDataCopy = JSON.parse(JSON.stringify(formData));
    const siteIndex = formDataCopy.sites.findIndex((site) => site.id === item.id);
    if (!formDataCopy.sites[siteIndex].departments) {
      formDataCopy.sites[siteIndex].departments = [];
    }
    formDataCopy.sites[siteIndex].departments.push({
      id: randomId(),
      parent: item.id,
      type: 'dept',
      name: '',
      value: '',
      isEditing: true,
    });
    setFormData(formDataCopy);
  };

  const handleDeleteDept = (item) => {
    const formDataCopy = JSON.parse(JSON.stringify(formData));
    const siteIndex = formDataCopy.sites.findIndex((site) => site.id === item.parent);
    const deptIndex = formDataCopy.sites[siteIndex].departments.findIndex((dept) => dept.id === item.id);
    formDataCopy.sites[siteIndex].departments.splice(deptIndex, 1);
    // Remove department ids (used to add users to departments)
    if (formDataCopy.sites[siteIndex].users) {
      formDataCopy.sites[siteIndex].users.forEach((user) => {
        const userDepartmentIndex = user.departments.findIndex((departmentId) => item.id == departmentId);
        if (user.departments && userDepartmentIndex !== -1) {
          user.departments.splice(userDepartmentIndex, 1);
        }
      });
    }
    setFormData(formDataCopy);
  };

  const handleEditItem = (item) => {
    // doubleclick edit
    if (item.type === 'site') {
      const formDataCopy = JSON.parse(JSON.stringify(formData));
      const siteIndex = formDataCopy.sites.findIndex((site) => site.id === item.id);
      formDataCopy.sites[siteIndex].oldValue = formDataCopy.sites[siteIndex].value;
      formDataCopy.sites[siteIndex].oldName = formDataCopy.sites[siteIndex].name;
      formDataCopy.sites[siteIndex].isEditing = true;
      setFormData(formDataCopy);
    }
    if (item.type === 'dept') {
      const formDataCopy = JSON.parse(JSON.stringify(formData));
      const siteIndex = formDataCopy.sites.findIndex((site) => site.id === item.parent);
      const deptIndex = formDataCopy.sites[siteIndex].departments.findIndex((dept) => dept.id === item.id);
      formDataCopy.sites[siteIndex].departments[deptIndex].oldValue = formDataCopy.sites[siteIndex].departments[deptIndex].value;
      formDataCopy.sites[siteIndex].departments[deptIndex].oldName = formDataCopy.sites[siteIndex].departments[deptIndex].name;
      formDataCopy.sites[siteIndex].departments[deptIndex].isEditing = true;
      setFormData(formDataCopy);
    }
  };

  const handleDeleteSite = (item) => {
    const formDataCopy = JSON.parse(JSON.stringify(formData));
    const siteIndex = formDataCopy.sites.findIndex((site) => site.id === item.id);
    formDataCopy.sites.splice(siteIndex, 1);
    setFormData(formDataCopy);
  };

  return (
    <>
      <ul>
        {items &&
          items[0] &&
          items.map((item, index) => (
            <SortableItem
              key={`item-${index}-${item.id}`}
              index={index}
              {...item}
              setFormData={setFormData}
              formData={formData}
              handleAddSite={handleAddSite}
              handleInput={handleInput}
              handleSaveInput={handleSaveInput}
              handleCancelEdit={handleCancelEdit}
              handleAddDept={handleAddDept}
              handleEditItem={handleEditItem}
              handleDeleteDept={handleDeleteDept}
              handleDeleteSite={handleDeleteSite}
              handleSiteDetailsForm={handleSiteDetailsForm}
              handleAddUsersForm={handleAddUsersForm}
            />
          ))}
      </ul>
      {isError && <span className={styles['error-message']}>{`Maximum number of sites is ${formData.site_limit}`}</span>}
    </>
  );
};

const SitesDeptsTree = (props) => {
  const formData = props.formDataOrg;
  const setFormData = props.setFormDataOrg;
  const cancel = props.cancelFun;
  const stepsFun = props.stepsFun;
  const steps = props.steps;
  const currentStep = props.currentStep;
  const setCurrentStep = props.setCurrentStep;
  const handleSiteDetailsForm = props.handleSiteDetailsForm;
  const handleAddUsersForm = props.handleAddUsersForm;

  const handleNext = () => {
    const stepsArr = JSON.parse(JSON.stringify(steps));
    stepsArr[currentStep - 1].active = true;
    setCurrentStep(currentStep + 1);
    stepsFun(stepsArr);
  };

  const handleBack = () => {
    const stepsArr = JSON.parse(JSON.stringify(steps));
    stepsArr[currentStep - 2].active = false;
    setCurrentStep(currentStep - 1);
    stepsFun(stepsArr);
  };

  return (
    <>
      <SortableList items={props.sitesData} setFormData={setFormData} formData={formData} handleSiteDetailsForm={handleSiteDetailsForm} handleAddUsersForm={handleAddUsersForm} />

      <br />
      <br />

      <div className={styles['org-site-bottom-buttons']}>
        <Button text="CANCEL" color="secondary" onClick={() => cancel()} />
        <Button icon="fas fa-arrow-left" text="BACK" onClick={handleBack} />
        <Button icon="fas fa-arrow-right" text="NEXT" onClick={handleNext} />
      </div>
    </>
  );
};

export default SitesDeptsTree;
