import React, { useState } from 'react';
import Modal from 'components/Modal/Modal';
import FormElement from 'components/FormElement/FormElement';
import Checkbox from 'components/Checkbox/Checkbox';
import { Button } from 'components/Button/Button';
import { useAppDispatch } from 'hooks';
import { createOrgAction } from 'store/actions/Actions';
import Modules from '../components/Modules/ModulesAlt';

import styles from './AddOrgModal.module.scss';

function AddOrgModal(props) {
  const dispatch = useAppDispatch();

  const [formData, setFormData] = useState({
    name: '',
    customer_id: '',
    address_1: '',
    address_2: '',
    city: '',
    region: '',
    postal_code: '',
    country_code: 235,
    active: true,
    site_limit: 1,
    acts: [],
    sso_enabled: false,
  });

  const [modules, setModules] = useState([]);
  const [selected, setSelected] = useState([]);
  const [documents, setDocuments] = useState([]);

  const [stateErrors, setStateErrors] = useState({
    name: '',
    customer_id: '',
    site_limit: '',
  });

  const handleChange = (e) => {
    e.persist();
    const val = e.target && e.target.value ? e.target.value : '';
    setFormData((s) => ({ ...s, name: val }));
    setStateErrors((s) => ({ ...s, name: '' }));
  };

  const handleIdChange = (e) => {
    e.persist();
    const val = e.target && e.target.value ? e.target.value : '';
    setFormData((s) => ({ ...s, customer_id: val }));
    setStateErrors((s) => ({ ...s, customer_id: '' }));
  };

  const handleLimitChange = (e) => {
    setFormData((s) => ({ ...s, site_limit: Math.max(e.target.value, 0) }));
  };

  const handleSSOChange = (e) => {
    setFormData((s) => ({ ...s, sso_enabled: e.target.checked }));
  };

  const addModal = () => {
    if (formData.name !== '' && formData.customer_id !== '') {
      dispatch(
        createOrgAction({
          ...formData,
          acts: selected,
          modules: [],
          files: documents,
        })
      );
      props.setShowModal(false);
      return false;
    }

    if (formData.name === '') {
      setStateErrors((s) => ({
        ...s,
        name: 'Organization name is required',
      }));
    }

    if (formData.customer_id === '') {
      setStateErrors((s) => ({
        ...s,
        customer_id: 'Infusionsoft Id is required',
      }));
    }

    return false;
  };

  return (
    <Modal className={styles.modal} setShowModal={props.setShowModal}>
      <Modal.Heading>Create new organization</Modal.Heading>
      <Modal.Content>
        <FormElement labelText="ORGANIZATION" htmlFor="name" errorMessage={stateErrors.name} smallText="*">
          <input placeholder="Organization Name" name="name" type="text" value={formData.name} onChange={handleChange} />
        </FormElement>

        <br />

        <FormElement labelText="ID" htmlFor="id" errorMessage={stateErrors.customer_id} smallText="*">
          <input placeholder="Infusionsoft Id" name="customer_id" type="text" value={formData.customer_id} onChange={handleIdChange} />
        </FormElement>

        <br />
        <FormElement labelText="SITE LIMIT" htmlFor="site_limit" errorMessage={stateErrors.site_limit}>
          <input placeholder="Site Limit" name="site_limit" type="number" min="0" value={formData.site_limit} onChange={handleLimitChange} />
        </FormElement>
        <br />

        <FormElement htmlFor="sso">
          <Checkbox
            key="sso"
            className={styles.checkbox}
            labelText="Single sign-on enabled (Azure Active Directory)"
            checked={formData.sso_enabled || false}
            onChange={handleSSOChange}
          />
        </FormElement>
        <br />

        <h2 className={styles.text_left}>Modules</h2>

        <Modules
          editAccess
          selected={selected}
          setSelected={setSelected}
          documents={documents}
          setDocuments={setDocuments}
          modules={modules}
          setModules={setModules}
          editMode={false}
        />
      </Modal.Content>
      <Modal.Footer>
        <Button text="CANCEL" color="secondary" onClick={() => props.setShowModal(false)} />
        <Button text="CREATE" className={styles.create} onClick={addModal} />
      </Modal.Footer>
    </Modal>
  );
}

export default AddOrgModal;
