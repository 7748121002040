import { call, put, takeLatest, delay, select } from 'redux-saga/effects';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { actionApiCall } from 'helpers/api/ApiCalls';
import * as sagaConstants from 'helpers/constants/SagasConstants';

const getSelected = (state) => state.UserOrgSiteReducer.selected;

function* updateDeviceInventoryV2Saga({ payload }) {
  try {
    const selected = yield select(getSelected);
    const apiResponse = yield call(actionApiCall, {
      actionType: ActionTypeConstants.UPDATE_DEVICE_INVENTORY_V2,
      data: {
        ...payload,
        organization: selected.org,
      },
    });
    if (apiResponse.res.data.response === 200) {
      yield put({ type: ActionTypeConstants.UPDATE_DEVICE_INVENTORY_V2_SUCCESS });
      yield put({
        type: ActionTypeConstants.LOAD_DEVICE_INVENTORY_V2,
        payload: {},
      });
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: apiResponse.res.data.message || `The device inventory ${sagaConstants.SUCCESSFULLY_UPDATED}`,
          color: 'success',
        },
      });
      yield delay(10000);
      yield put({ type: ActionTypeConstants.HIDE_ALERT });
    } else {
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: apiResponse.res.data.message || `${sagaConstants.ISSUE_OCCURED} updating device inventory`,
          color: 'danger',
        },
      });
      yield delay(10000);
      yield put({ type: ActionTypeConstants.HIDE_ALERT });
    }
  } catch (err) {
    yield put({
      type: ActionTypeConstants.SHOW_ALERT,
      payload: {
        message: `${sagaConstants.SERVER_ERROR}`,
        color: 'danger',
      },
    });
    yield delay(10000);
    yield put({ type: ActionTypeConstants.HIDE_ALERT });
  }
}

export default function* () {
  yield takeLatest(ActionTypeConstants.UPDATE_DEVICE_INVENTORY_V2, updateDeviceInventoryV2Saga);
}
