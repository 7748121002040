import React from 'react';
import classNames from 'classnames';

import styles from './Checkbox.module.scss';

interface IProps {
  disabled?: boolean;
  checked?: boolean;
  className?: string;
  labelText?: React.ReactNode | string;
  onChange?: (any) => void;
  isSwitch?: boolean;
  name?: string;
  value?: string;
  id?: string;
  partial?: boolean;
}

const defaultProps = {
  disabled: false,
  checked: false,
  className: undefined,
  labelText: '',
  onChange: () => false,
  isSwitch: false,
  name: '',
  value: '',
  id: undefined,
  partial: false,
};

const Checkbox = React.forwardRef<HTMLInputElement, IProps>(({ disabled, checked, className, labelText, onChange, isSwitch, name, value, id, partial }, ref) => {
  const styleLabelSwitch = classNames(styles['switch-wrap'], !checked && styles['switch-wrap-inactive']);
  const renderSwitch = (
    <label className={styleLabelSwitch}>
      <input checked={checked} disabled={disabled} onChange={onChange} type="checkbox" />
      <div className={styles.switch} />
    </label>
  );
  const styleCheckbox = classNames(styles['input-checkbox'], partial && styles['input-checkbox-partial']);
  const styleLabel = classNames(className, styles['checkbox-container']);
  const renderCheckbox = (
    <label className={styleLabel}>
      <input
        ref={ref}
        id={id}
        disabled={disabled}
        checked={checked}
        className={styleCheckbox}
        type="checkbox"
        onChange={onChange}
        name={name}
        key={value}
        value={value}
        data-label={labelText}
        data-partial={partial}
      />
      <i />
      {labelText && <span>{labelText}</span>}
    </label>
  );

  return isSwitch ? renderSwitch : renderCheckbox;
});

Checkbox.defaultProps = defaultProps;
Checkbox.displayName = 'Checkbox';

export default Checkbox;
