import React from 'react';
import MainLayout from 'v2/layouts/MainLayout/MainLayout';

import Tabs from 'v2/components/Tabs/Tabs';

function Assessments() {
    return (
        <>
            <MainLayout>
                <Tabs tabs={[
                    {
                        title: 'Assessments',
                        path: '',
                    },
                    {
                        title: 'Remediations',
                        path: 'remediations',
                    },
                    {
                        title: 'Open Tasks',
                        path: 'tasks',
                    }
                ]} />
            </MainLayout>
        </>
    );
}

export default Assessments;
