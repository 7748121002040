/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import styles from './Editor.module.scss';

import AppLayoutWrapFull from 'components/AppLayoutWrapFull';
// import PageHeader from "components/PageHeader/PageHeader";
import { ONLY_OFFICE_JS } from 'helpers/ApiEndpointConstants';
import Button from 'components/Button/Button';
import SaveAsModal from '../SaveAsModal/SaveAsModal';

import {
  loadDocumentAction,
  editDocumentAction,
  initializeDocumentAction,
  // cleanupDocumentAction
} from 'store/actions/Actions';

const Editor = (props) => {
  const [state, setState] = useState([]);
  const { editDocumentAction, loadDocumentAction, document } = props;
  const [showSaveAsModal, setSaveAsModal] = useState(false);

  const { UserOrgSiteReducer } = props;
  const is_admin = UserOrgSiteReducer && UserOrgSiteReducer.selected && UserOrgSiteReducer.selected.is_admin ? true : false;

  const [isEditorLoaded, setIsEditorLoaded] = useState(state.parentFolder);
  var docEditor;

  const { id, mode } = useParams();

  useEffect(() => {
    //inject only office widget javascript
    const script = window.document.createElement('script');
    script.src = ONLY_OFFICE_JS;
    script.async = false;

    window.document.body.appendChild(script);

    script.onload = function () {
      setIsEditorLoaded(true);
    };

    return () => {
      window.document.body.removeChild(script);
      // if (mode === 'edit' && document){
      //     console.log("CLEANING");
      //     cleanupDocumentAction({id: document.id});
      // }
    };
  }, []);

  useEffect(() => {
    if (mode === 'edit') {
      console.log('EDIT');
      editDocumentAction({ id: id });
    } else {
      loadDocumentAction({ id: id, unapproved: true });
    }
  }, [id, loadDocumentAction, editDocumentAction, mode]);

  var onAppReady = function () {
    console.log('Document editor ready');
  };

  var isDocumentChanged = false;
  var onDocumentStateChange = function (event) {
    var title = window.document.title.replace(/\*$/g, '');
    window.document.title = title + (event.data ? '*' : '');
    console.log('SAVE');
    if (event.data) {
      isDocumentChanged = true;
      // console.log("The document changed");
      // console.log("Document id", props.document.id);
      //initializeDocumentAction
    } else {
      // console.log("Changes are collected on document editing service");
      if (isDocumentChanged && mode === 'edit') {
        props.initializeDocumentAction({ id: props.document.id });
      }
    }
  };

  var onRequestEditRights = function () {
    window.location.href = window.location.href.replace(RegExp('action=view\\&?', 'i'), '');
  };

  var onError = function (event) {
    if (event) console.error(event.data);
  };

  var onOutdatedVersion = function (event) {
    window.location.reload(true);
  };

  var onRequestRestore = function (event) {
    var url = event.data.url;
    var version = event.data.version;
    docEditor.refreshHistory({
      currentVersion: version,
      history: document.history.history,
    });
  };

  var getFolderIcon = function () {
    return (
      <div className={styles['saveas']}>
        {is_admin ? (
          <div className={styles['path']}>
            <a href="/achieve/documents">
              <i className={`fal fa-folder-open ${styles['up']}`}></i>
            </a>{' '}
            {document.path}
          </div>
        ) : (
          <div className={styles['path']}></div>
        )}
        {mode === 'edit' && <Button onClick={() => setSaveAsModal(true)} className={styles.button} text="Save as new document" iconEnd={`${styles['button-arrow']}`} />}{' '}
      </div>
    );
  };

  useEffect(() => {
    if (document) {
      let edit = mode === 'edit' || mode === 'review';

      let config = document.config;

      config['height'] = '1000px';
      config['document']['permissions']['comment'] = edit;
      config['document']['permissions']['edit'] = edit;
      config['document']['permissions']['fillForms'] = edit;
      config['document']['permissions']['modifyFilter'] = edit;
      config['document']['permissions']['modifyContentControl'] = edit;
      config['document']['permissions']['review'] = edit;

      config['editorConfig']['user']['id'] = props.userData.id;
      config['editorConfig']['user']['name'] = props.userData.name;

      if (document.history && document.historyData) {
        config.events = {
          onAppReady: onAppReady,
          onDocumentStateChange: onDocumentStateChange,
          onRequestEditRights: onRequestEditRights,
          onError: onError,
          onOutdatedVersion: onOutdatedVersion,
          onRequestRestore: onRequestRestore,
        };
        config.events['onRequestHistory'] = function () {
          docEditor.refreshHistory(document.history);
        };
        config.events['onRequestHistoryData'] = function (event) {
          var ver = event.data;
          docEditor.setHistoryData(document.historyData[ver]);
        };
        config.events['onRequestHistoryClose'] = function () {
          window.document.location.reload();
        };
      }

      if (isEditorLoaded) {
        // eslint-disable-next-line
        docEditor = new window.DocsAPI.DocEditor('editor', config);
      }
    }
  }, [document, mode, isEditorLoaded]);

  return (
    <AppLayoutWrapFull>
      {document && getFolderIcon()}
      <div id="editor" />
      {showSaveAsModal && ReactDOM.createPortal(<SaveAsModal document={document} setShowModal={setSaveAsModal} />, document.querySelector('#modal-root'))}
    </AppLayoutWrapFull>
  );
};

const mapStateToProps = (state) => ({
  document: state.DocumentReducer.document,
  userData: state.AuthReducer.userData,
  UserOrgSiteReducer: state.UserOrgSiteReducer,
});

const mapDispatchToProps = {
  loadDocumentAction,
  editDocumentAction,
  initializeDocumentAction,
  // cleanupDocumentAction
};

// export default Documents;
export default connect(mapStateToProps, mapDispatchToProps)(Editor);
