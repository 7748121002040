import classNames from 'classnames';
import React, { useState, useRef, ReactNode } from 'react';

import styles from './Select.module.scss';

interface IProps {
  icon?: string;
  labels?: Object;
  options?: ReactNode;
  children?: ReactNode;
  onClick?;
  value?: string;
  dropdownToggleRef?;
  placeholder?: string;
  disabled?: boolean;
  size?: 'md' | 'lg' | 'fixed';
  isActive?: boolean;
  truncate?: boolean;
  active?: string;
  className?: string;
}

const defaultProps = {
  icon: undefined,
  labels: undefined,
  options: undefined,
  children: undefined,
  onClick: () => false,
  value: '',
  dropdownToggleRef: () => false,
  placeholder: '',
  disabled: false,
  size: 'lg',
  isActive: false,
  truncate: false,
  active: '',
  className: undefined,
};

function Select(props: IProps) {
  const { icon, labels, options, children, onClick, value, dropdownToggleRef, placeholder, disabled, isActive, truncate, active, size, className } = props;

  const [showDropdown, setShowDropdown] = useState(false);
  const menuRef = useRef<HTMLElement>();

  const toggleDropdown = (e) => {
    if (!disabled) setShowDropdown(!showDropdown);
    if (active) {
      window.setTimeout(() => {
        const myElement = document.getElementById(active);
        if (myElement && menuRef.current) {
          const topPos = myElement.offsetTop;
          const scroll = menuRef.current;
          scroll.scrollTop = topPos;
        }
      }, 1);
    }
  };

  const iconRender = icon && <i className={icon} />;
  const valueRender = (labels && labels[value]) || value || placeholder || 'Placeholder';
  const classRender = (value === '' || value === null) && styles.placeholder;

  const className1 = classNames(
    className,
    styles['dropdown-toggle'],
    showDropdown ? 'show' : 'hide',
    styles[`select-${size}`],
    disabled && styles.disabled,
    isActive && styles.active
  );
  const menuClass = classNames(styles['dropdown-menu'], !showDropdown && styles.hidden, styles[`select-${size}`]);

  const handleClick = (e) => {
    if (!disabled) {
      onClick(e);
    }
    toggleDropdown(e);
  };

  const style = classNames(classRender, truncate && styles.truncate);

  return (
    <div className={styles.dropdown}>
      <div className={className1} onClick={toggleDropdown} ref={dropdownToggleRef} aria-hidden="true">
        {iconRender}
        <span className={style}>{valueRender}</span>
        <i className="fas fa-sort-down" />
      </div>
      <menu className={menuClass} ref={menuRef}>
        <div onMouseUp={handleClick} aria-hidden="true">
          {options || children}
        </div>
      </menu>
    </div>
  );
}

Select.defaultProps = defaultProps;

export default Select;
