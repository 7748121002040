// import ColVisibilitySelector from "components/DataTable/ColVisibilitySelector";
// import ItemActionsMenu from "components/DataTable/ItemActionsMenu";
import React from 'react';
// import Checkbox from "components/Checkbox/Checkbox";
import moment from 'moment';
import Button from 'components/Button/Button';
// import { getStatusButtonColor } from "helpers/utils";

export default {
  TABLE: {
    className: 'data-table-tasks',
    initialSortKey: 'assigned',
    initialSortOrder: 'desc',
  },

  COLUMN: [
    {
      label: 'Task name',
      header: 'TASK NAME',
      key: 'task',
      sortable: true,
      className: 'title',
      format: ({ rowData }) => {
        return (
          <span>
            {rowData['task']} {rowData.subtask_data && rowData.subtask_data['has_quiz'] === 't' && '(Quiz)'}
          </span>
        );
      },
    },
    {
      label: 'Program',
      header: 'PROGRAM',
      key: 'regulatory_act',
      sortable: true,
      className: '',
      format: ({ rowData }) => {
        return <span>{rowData['regulatory_act']}</span>;
      },
    },
    // {
    //   label: 'Type',
    //   header: 'TYPE',
    //   key: 'type',
    //   sortable: true,
    //   className: '',
    //   format: ({ rowData }) => {
    //     return <span>{rowData['typeLabel']}</span>;
    //   },
    // },
    {
      label: 'Date requested',
      header: 'DATE REQUESTED',
      key: 'assigned',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{rowData['assigned'] ? moment(rowData['assigned']).format('MM/DD/YYYY') : ''}</span>;
      },
    },
    {
      label: 'Completed',
      header: 'DATE COMPLETED',
      key: 'completed_on',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{rowData['completed_on'] ? moment(rowData['completed_on']).format('MM/DD/YYYY') : ''}</span>;
      },
    },
    {
      label: 'Status',
      header: 'STATUS',
      key: 'status',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData, customTableProps }) => {
        return (
          <span>
            <Button
              disabled={rowData.locked === 't' ? true : false}
              cursorDefault={customTableProps.cursorDefault}
              color={rowData.statusType}
              size="md"
              icon={rowData.locked === 't' ? 'fas fa-lock' : undefined}
              text={rowData.statusLabel}
              onClick={() => null}
            />
          </span>
        );
      },
    },
    {
      label: 'Certificate',
      header: 'CERTIFICATE',
      key: 'certificate',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return (
          <span>
            {rowData.subtask_data && rowData.subtask_data.certificate && rowData.subtask_data.understood === 't' ? (
              <Button
                color="secondary"
                onClick={() => {
                  window.location.href = `/certificates/${rowData.subtask_data.certificate}`;
                }}
                text="Certificate"
                iconEnd="fal fa-file-certificate"
                size="md"
              />
            ) : (
              ''
            )}
          </span>
        );
      },
    },
    {
      label: 'Site',
      header: 'SITE',
      key: 'siteName',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span style={{ minWidth: 120 }}>{rowData.siteName || 'All Sites'}</span>;
      },
    },
    {
      label: 'Action',
      header: 'ACTION',
      key: 'action',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return (
          <span>
            <Button size="md" disabled={rowData.locked === 't' ? true : false} text={rowData.buttonLabel} onClick={() => null} />
          </span>
        );
      },
    },
  ],
};
