// import React from 'react';

// import classNames from 'classnames';

const SortedData = (props) => {
  const { sortKey, data, colDefs, children, sortOrder = 'asc', serverPagination } = props;

  const getSortedData = () => {
    if (!(sortKey && data && data.length > 0 && colDefs && colDefs.length > 0)) {
      return data;
    }

    if (serverPagination) {
      return data;
    }

    const sortKeyDef = colDefs.find((colDef) => colDef.key === sortKey);

    if (!sortKeyDef) {
      return data;
    }

    const sortedData = [...data]; // Shallow copy array items

    const sortFunction =
      typeof sortKeyDef.sortFunction === 'function'
        ? sortKeyDef.sortFunction
        : (rowDataA, rowDataB) => {
            const valueA = rowDataA[sortKey] || '';
            const valueB = rowDataB[sortKey] || '';
            if (typeof valueA === 'number' || !Number.isNaN(valueA) || sortKey === 'id') {
              return valueA.toString().localeCompare(valueB, undefined, { numeric: true });
            }
            return valueA.toString().localeCompare(valueB);
          };

    sortedData.sort(sortFunction);

    if (sortOrder === 'desc') {
      sortedData.reverse();
    }

    return sortedData;
  };

  return children({ sortedData: getSortedData() });
};

export default SortedData;
