import ActionStatusConstants from 'helpers/ActionStatusConstants';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
// import { LOCATION_CHANGE } from 'redux-first-history';

const initialState = {
  status: ActionStatusConstants.INITIAL,
  message: undefined,
  formErrors: undefined,
};

export default (state = initialState, action) => {
  const actionType = action && action.type ? action.type : undefined;
  const payload = action && action.payload ? action.payload : undefined;

  switch (actionType) {
    case ActionTypeConstants.LOAD_STATS: {
      return {
        ...state,
        status: ActionStatusConstants.ISBUSY,
      };
    }
    case ActionTypeConstants.LOAD_STATS_SUCCESS: {
      return {
        ...state,
        stats: payload.stats,
        status: ActionStatusConstants.SUCCESS,
        message: payload && payload.message ? payload.message : undefined,
        formErrors: payload && payload.formErrors ? payload.formErrors : undefined,
      };
    }
    case ActionTypeConstants.LOAD_STATS_FAILURE: {
      return {
        ...state,
        status: ActionStatusConstants.FAILURE,
        message: payload && payload.message ? payload.message : undefined,
        formErrors: payload && payload.formErrors ? payload.formErrors : undefined,
      };
    }
    // case LOCATION_CHANGE:
    //   return {
    //     ...initialState,
    //   };
    default:
  }
  return state;
};
