import { call, put, takeLatest, delay, select } from 'redux-saga/effects';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { actionApiCall } from 'helpers/api/ApiCalls';
import * as sagaConstants from 'helpers/constants/SagasConstants';
import Cookies from 'js-cookie';
import { push } from 'redux-first-history';
import { getPermissions } from 'helpers/utils/getPermissions/getPermissions';
import { redirectToOrganizations } from 'helpers/utils';

const getSelected = (state) => state.AuthReducer;
const getRoute = (state) => state.router;

function* verifyCodeSaga({ payload, type }) {
  try {
    const apiResponse = yield call(actionApiCall, {
      actionType: ActionTypeConstants.MFA_VERIFY_CODE,
      data: {
        ...payload,
      },
    });
    if (apiResponse && apiResponse.res.data.response === 200) {
      const selected = yield select(getSelected);
      const route = yield select(getRoute);
      yield put({
        type: ActionTypeConstants.MFA_VERIFY_CODE_SUCCESS,
        payload: {
          mfa: {
            isCodeVerified: true,
            masterKey: apiResponse?.res?.data?.data?.master_key,
          },
          message: apiResponse?.res?.data?.message,
        },
      });

      const apiResponse1 = yield call(actionApiCall, {
        actionType: ActionTypeConstants.LOAD_USER_ORG_SITE,
        data: {
          organization: false,
        },
      });

      const data = selected?.userData;
      const isSettingsRoute = route?.location?.pathname === '/settings' || route?.location?.pathname === '/profile/security';
      if (data && !isSettingsRoute) {
        Cookies.set('isLogged', true);

        const isSuper = (data.role && data.role.map((r) => r.is_super === 't' || r.is_coach === 't').includes(true)) || false;

        const orgs = [];
        const sites = [];
        if (data.role) {
          for (const r of data.role) {
            orgs.push(r.organization);
            sites.push(r.site);
          }
        }
        const isOneSite = [...new Set(orgs)].length === 1 && [...new Set(sites)].length === 1;

        if (isSuper) {
          const ui_version = apiResponse1?.res?.data?.data.organizations['1'].ui_version || "0";
          yield put({ type: ActionTypeConstants.LOAD_USER_ORG_SITE });
          yield put({
            type: ActionTypeConstants.SET_USER_ORG_SITE_SUCCESS,
            payload: {
              selected: {
                org: '1',
                is_admin: true,
                is_super: data.role[0].is_super === 't',
                is_coach: data.role[0].is_coach === 't',
                ui_version
              },
            },
          });
          yield put({
            type: ActionTypeConstants.LOAD_ORG_MODULES,
          });
          Cookies.set('org', '1');
          yield put(push(redirectToOrganizations(ui_version)));
        } else if (data.registered === 'f') {
          // redirect to register
          yield put(push('/login/register'));
        } else if (isOneSite && data.role[0].name !== 'Org Administrator' && !data.role[0].name.includes('Officer')) {
          Cookies.set('org', data.role[0].organization);
          if (data.role[0].site !== '' && data.role[0].site !== '*') {
            Cookies.set('site', data.role[0].site);
          }
          yield put({ type: ActionTypeConstants.LOAD_USER_ORG_SITE });
          const parsedData = yield JSON.parse(JSON.stringify(data));
          const siteClean = parsedData.role[0].site !== '' && parsedData.role[0].site !== '*' ? parsedData.role[0].site : undefined;
          const orgClean = parsedData.role[0].organization;
          const perms = yield getPermissions(parsedData.role, orgClean, siteClean);
          yield put({
            type: ActionTypeConstants.SET_USER_ORG_SITE_SUCCESS,
            payload: {
              selected: {
                org: orgClean,
                site: siteClean,
                ...perms,
              },
            },
          });
          yield put({
            type: ActionTypeConstants.LOAD_ORG_MODULES,
          });
          yield put(push('/'));
        } else {
          // redirect to site select
          yield payload.redirect ? put(push(`/login/site/${payload.redirect}`)) : put(push('/login/site'));
          // load orgs for drop-down for selected user
          yield put({ type: ActionTypeConstants.LOAD_USER_ORG_SITE });
        }
      }
      if (data && isSettingsRoute) {
        yield put({ type: ActionTypeConstants.LOAD_USER_SETTINGS_FORM });
      }
    } else {
      yield put({
        type: ActionTypeConstants.MFA_VERIFY_CODE_FAILURE,
        payload: {
          mfa: { isCodeVerified: false },
          message: apiResponse.res.data.message,
        },
      });
    }
  } catch {
    yield put({
      type: ActionTypeConstants.SHOW_ALERT,
      payload: {
        message: `${sagaConstants.SERVER_ERROR}`,
        color: 'danger',
      },
    });
    yield delay(10_000);
    yield put({ type: ActionTypeConstants.HIDE_ALERT });
  }
}

export default function* VerifyCodeSaga() {
  yield takeLatest(ActionTypeConstants.MFA_VERIFY_CODE, verifyCodeSaga);
}
