import React from 'react';
import MainLayout from 'v2/layouts/MainLayout/MainLayout';

import OneOrg from 'v2/views/Dashboard/OneOrg/OneOrg';

function Dashboard() {
    return (
        <>
            <MainLayout>
                <OneOrg />
            </MainLayout>
        </>
    );
}

export default Dashboard;
