import { call, put, takeLatest, delay, select } from 'redux-saga/effects';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { actionApiCall } from 'helpers/api/ApiCalls';
import * as sagaConstants from 'helpers/constants/SagasConstants';

const getSelected = (state) => state.UserOrgSiteReducer.selected;

function* cleanupDocumentSaga({ payload }) {
  try {
    const selected = yield select(getSelected);
    yield call(actionApiCall, {
      actionType: ActionTypeConstants.CLEANUP_DOCUMENT,
      data: {
        organization: selected.org,
        id: payload.id,
      },
    });

    // if (apiResponse.res.data.response === 200) {
    // yield put({
    //     type: ActionTypeConstants.SHOW_ALERT,
    //     payload: {
    //         message: apiResponse.res.data.message || "Successfully discarded document",
    //         color: "success"
    //     }
    // });
    // yield delay(10000)
    // yield put({ type: ActionTypeConstants.HIDE_ALERT });
    // } else {
    // Failure to initialize document
    // yield put({
    //     type: ActionTypeConstants.SHOW_ALERT,
    //     payload: {
    //         message: apiResponse.res.data.message || "We ran into an issue while saveing document",
    //         color: "danger"
    //     }
    // });
    // yield delay(10000)
    // yield put({ type: ActionTypeConstants.HIDE_ALERT });
    // }
  } catch (err) {
    yield put({
      type: ActionTypeConstants.SHOW_ALERT,
      payload: {
        message: `${sagaConstants.SERVER_ERROR}`,
        color: 'danger',
      },
    });
    yield delay(10000);
    yield put({ type: ActionTypeConstants.HIDE_ALERT });
  }
}

export default function* () {
  yield takeLatest(ActionTypeConstants.CLEANUP_DOCUMENT, cleanupDocumentSaga);
}
