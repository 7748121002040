import { call, put, takeLatest, delay, select } from 'redux-saga/effects';
import { push } from 'redux-first-history';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { actionApiCall } from 'helpers/api/ApiCalls';
import * as sagaConstants from 'helpers/constants/SagasConstants';
import { redirectToPeople } from 'helpers/utils';

const getSelected = (state) => state.UserOrgSiteReducer.selected;

function* RevokeUserAccessSaga({ payload }) {
  try {
    const apiResponse = yield call(actionApiCall, {
      actionType: ActionTypeConstants.REVOKE_USER_ACCESS,
      data: {
        id: payload.id,
      },
    });
    if (apiResponse.res.data.response === 200) {
      const selected = yield select(getSelected);
      yield put({
        type: ActionTypeConstants.LOAD_USERS,
        payload: {
          inactive: true,
        },
      });
      yield put(push(redirectToPeople(selected.ui_version)));
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: apiResponse.res.data.message || 'Account access revoked',
          color: 'success',
        },
      });
      yield delay(10000);
      yield put({ type: ActionTypeConstants.HIDE_ALERT });
    } else {
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: apiResponse.res.data.message || `${sagaConstants.ISSUE_OCCURED} revoking account access`,
          color: 'danger',
        },
      });
      yield delay(10000);
      yield put({
        type: ActionTypeConstants.HIDE_ALERT,
      });
    }
  } catch (err) {
    yield put({
      type: ActionTypeConstants.SHOW_ALERT,
      payload: {
        message: `${sagaConstants.SERVER_ERROR}`,
        color: 'danger',
      },
    });
    yield delay(10000);
    yield put({ type: ActionTypeConstants.HIDE_ALERT });
  }
}

export default function* () {
  yield takeLatest(ActionTypeConstants.REVOKE_USER_ACCESS, RevokeUserAccessSaga);
}
