import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styles from './DeviceInventoryBulkUploadModal.module.scss';
import Modal from 'components/Modal/Modal';
import Button from 'components/Button/Button';
import UploadZone from 'components/UploadZone/UploadZone';

import { toggleDropdowns } from 'helpers/utils';

import { bulkUploadDeviceInventoryV2Action, downloadTemplateDeviceInventoryV2Action, loadSitesAction } from 'store/actions/Actions';
import BulkUploadSiteSelector from 'v2/views/Assets/components/BulkUploadSiteSelector/BulkUploadSiteSelector';

const DeviceInventoryBulkUploadModal = (props) => {
  const [template, setTemplate] = useState({
    name: '',
    data: undefined,
  });
  const [files, setFiles] = useState([]);
  const [site_id, setSite] = useState('-1');

  useEffect(() => {
    if (files) {
      setTemplate(files[0]);
    }
  }, [files]);

  const siteRef = React.createRef();

  return (
    <Modal setShowModal={props.setShowModal} className={styles.modal}>
      <div className={styles['modal-header']}>
        <h2 className={styles.heading}>Device Inventory - Bulk Upload</h2>
        <p className={styles.description}>Fill out the Device Inventory Excel template and upload below.</p>
      </div>
      <div className={styles['modal-body']} onClick={toggleDropdowns([siteRef])}>
        <BulkUploadSiteSelector
          site_id={site_id}
          onChangeSite={setSite}
        />

        <p>If you need the template, you can download it here:</p>
        <a
          className={styles['download-button']}
          onClick={() => {
            props.downloadTemplateDeviceInventoryV2Action({
              site_id: site_id !== '-1' ? site_id : null,
            });
          }}
        >
          DOWNLOAD TEMPLATE
        </a>

        <p>
          The upload template is for new asset uploads only.
          <br />
          Re-uploading existing assets will result in a duplication of assets in the system.
        </p>

        <UploadZone files={files} setFiles={setFiles} formats={['xls', 'xlsx']} maxFileSize={10 * 1024 * 1024} maxFileNameLength={235} />
      </div>
      <div className={styles['modal-footer']}>
        <Button className={styles.button} text="CANCEL" onClick={() => props.setShowModal(false)} color="secondary" />
        <Button
          className={styles.button}
          text="UPLOAD"
          // disabled={!site}
          onClick={() => {
            if (template.data) {
              props.bulkUploadDeviceInventoryV2Action({
                file: template.data,
                site_id: site_id !== '-1' ? site_id : null,
              });
              props.setShowModal(false);
            }
          }}
        />
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  SitesReducer: state.SitesReducer,
});

const mapDispatchToProps = {
  bulkUploadDeviceInventoryV2Action,
  downloadTemplateDeviceInventoryV2Action,
  loadSitesAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceInventoryBulkUploadModal);
