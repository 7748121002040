import React from 'react';

import { Route, Routes, Navigate } from 'react-router-dom';

import Add from './Users/Add/Add';
import AddBulk from './Users/Add/AddBulk';
// import Alerts from './Alerts/Alerts';
import Incidents from './Incidents/Incidents';
import EditIncident from './Incidents/Edit/Edit';
import Edit from './Users/Edit/Edit';
import OrgSiteDept from './OrgSiteDept/OrgSiteDept';
import Users from './Users/Users';
import UsersUnassigned from './Users/UsersUnassigned';
import AddNewIncident from './Incidents/Add/Add';

function Maintain() {
  return (
    <Routes>
      {/* <Route path="/alerts" element={<Alerts />} /> */}
      <Route path="/incidents" element={<Incidents />} />
      <Route path="/incidents/edit" element={<EditIncident />} />
      <Route path="/incidents/add-new-incident" element={<AddNewIncident />} />
      <Route path="/organization" element={<OrgSiteDept />} />
      <Route path="/organization/unassigned" element={<UsersUnassigned />} />
      <Route path="/users" element={<Users />} />
      <Route path="/users/add" element={<Add />} />
      <Route path="/users/add_bulk" element={<AddBulk />} />
      <Route path="/users/edit" element={<Edit />} />
      <Route path="*" element={<Navigate to="/404" replace />} />
    </Routes>
  );
}

export default Maintain;
