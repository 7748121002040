import ActionStatusConstants from 'helpers/ActionStatusConstants';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { LOCATION_CHANGE } from 'redux-first-history';

export interface Stats {
  all: All;
  training: Documents;
  audits: All;
  documents: Documents;
  my: Documents;
}

export interface All {
  total: string;
  complete: string;
}

export interface Documents {
  total: number;
  complete: number;
}

export interface IUserStatsState {
  status: string;
  message?: string;
  stats?: Stats;
}

const initialState: IUserStatsState = {
  status: ActionStatusConstants.INITIAL,
  message: undefined,
};

export default (state = initialState, { type, payload }): IUserStatsState => {
  switch (type) {
    case ActionTypeConstants.LOAD_USER_STATS: {
      return {
        ...state,
        status: ActionStatusConstants.ISBUSY,
      };
    }
    case ActionTypeConstants.LOAD_USER_STATS_SUCCESS: {
      return {
        ...state,
        stats: payload?.stats,
        status: ActionStatusConstants.SUCCESS,
        message: payload?.message,
      };
    }
    case ActionTypeConstants.LOAD_USER_STATS_FAILURE: {
      return {
        ...state,
        status: ActionStatusConstants.FAILURE,
        message: payload?.message,
      };
    }
    case LOCATION_CHANGE: {
      return {
        ...initialState,
      };
    }
    default:
  }
  return state;
};
