import { call, put, takeLatest, delay } from 'redux-saga/effects';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { actionApiCall } from 'helpers/api/ApiCalls';
import * as sagaConstants from 'helpers/constants/SagasConstants';

function* createTasknoteSaga({ payload }) {
  try {
    const apiResponse = yield call(actionApiCall, {
      actionType: ActionTypeConstants.CREATE_TASKNOTE,
      data: {
        task: payload.id,
        notes: payload.notes,
      },
    });
    if (apiResponse && apiResponse.res.data.response === 200) {
      // SUCCESS
      yield put({ type: ActionTypeConstants.CREATE_TASKNOTE_SUCCESS });
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: apiResponse.res.data.message || `Task note ${sagaConstants.SUCCESSFULLY_ADDED}`,
          color: 'success',
        },
      });
      yield put({
        type: ActionTypeConstants.LOAD_ONE_TASK,
        payload: { id: payload.id, refresh: true },
      });
      yield delay(10000);
      yield put({ type: ActionTypeConstants.HIDE_ALERT });
    } else {
      yield put({ type: ActionTypeConstants.CREATE_TASKNOTE_FAILURE });
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: apiResponse.res.data.message || `${sagaConstants.ISSUE_OCCURED} adding a task note`,
          color: 'danger',
        },
      });
      yield delay(10000);
      yield put({ type: ActionTypeConstants.HIDE_ALERT });
    }
  } catch (err) {
    console.log(err);
    yield put({ type: ActionTypeConstants.CREATE_TASKNOTE_FAILURE });
    yield put({
      type: ActionTypeConstants.SHOW_ALERT,
      payload: {
        message: `${sagaConstants.SERVER_ERROR}`,
        color: 'danger',
      },
    });
    yield delay(10000);
    yield put({ type: ActionTypeConstants.HIDE_ALERT });
  }
}

export default function* () {
  yield takeLatest(ActionTypeConstants.CREATE_TASKNOTE, createTasknoteSaga);
}
