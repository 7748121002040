// import ColVisibilitySelector from "components/DataTable/ColVisibilitySelector";
// import ItemActionsMenu from "components/DataTable/ItemActionsMenu";
import Button from 'components/Button/Button';
import React from 'react';
// import Checkbox from "components/Checkbox/Checkbox";
import moment from 'moment';

// const actionMenuDropDown = React.createRef();

export default {
  TABLE: {
    className: 'data-table-documents',
    initialSortKey: 'name',
    initialSortOrder: 'asc',
  },

  COLUMN: [
    // {
    //     label: "Row Selector",
    //     key: "row-selector",
    //     header: ({ customTableProps }) => {
    //         const isSelectedAll =
    //             customTableProps.selectedRows &&
    //             customTableProps.selectedRows.length &&
    //             customTableProps.rowsData &&
    //             customTableProps.rowsData.length &&
    //             customTableProps.selectedRows.length ===
    //                 customTableProps.rowsData.length
    //                 ? true
    //                 : false;
    //         return (
    //             <Checkbox
    //                 checked={isSelectedAll}
    //                 onChange={() => customTableProps.doSelectAll()}
    //                 name="row-selector"
    //             />
    //         );
    //     },
    //     className: "col-row-selector",
    //     format: ({ rowData, customTableProps }) => {
    //         const isSelected =
    //             customTableProps.selectedRows &&
    //             customTableProps.selectedRows.length &&
    //             customTableProps.selectedRows.includes(rowData)
    //                 ? true
    //                 : false;
    //         return (
    //             <Checkbox
    //                 checked={isSelected}
    //                 onChange={() => customTableProps.onRowSelect({ rowData })}
    //                 name="row-selector"
    //             />
    //         );
    //     },
    //     canBeHidden: false
    // },
    {
      label: 'Name',
      header: 'NAME',
      key: 'name',
      className: 'name',
      sortable: true,
      format: ({ rowData, customTableProps }) => {
        let icon = '';

        if (rowData['type'] === 'folder') {
          icon = <i className="fas fa-folder" />;
        }

        if (rowData['type'] === 'file') {
          if (rowData['file_type'] === 'text') {
            if (rowData['folder_name'].endsWith('.pdf')) {
              icon = <i className="fas fa-file-pdf"></i>;
            } else {
              icon = <i className="fas fa-file-word"></i>;
            }
          }

          if (rowData['file_type'] === 'spreadsheet') {
            icon = <i className="fas fa-file-excel"></i>;
          }

          if (rowData['file_type'] === 'presentation') {
            icon = <i className="fas fa-file-powerpoint"></i>;
          }
        }

        return (
          <span>
            {icon}
            {rowData['folder_name']}
          </span>
        );
      },
    },
    {
      label: 'Last Modified',
      header: 'LAST MODIFIED',
      key: 'last_modified',
      sortable: true,
      format: ({ rowData, customTableProps }) => {
        if (rowData.status === 'processing') {
          return <span>PROCESSING...</span>;
        }
        return <span>{moment(rowData['last_modified']).format('MM/DD/YYYY')}</span>;
      },
    },
    {
      label: 'Version',
      header: 'VERSION',
      key: 'version',
      sortable: true,
      format: ({ rowData }) => {
        if (rowData.status === 'processing') {
          return <span></span>;
        }
        return <span>{rowData['version']}</span>;
      },
    },
    {
      label: 'Permission Level',
      header: 'PERMISSION LEVEL',
      key: 'permission_level',
      sortable: true,
      format: ({ rowData }) => {
        if (rowData.status === 'processing') {
          return <span></span>;
        }
        return <span>{rowData['admins_only'] ? 'Admins' : rowData['permission_department'] ? 'Department' : rowData['permission_site'] ? 'Site' : 'Org'}</span>;
      },
    },
    {
      label: 'Actions',
      key: 'actions',
      format: ({ rowData, customTableProps }) => {
        if (rowData.status === 'processing') {
          return <span></span>;
        }
        return (
          <div>
            {customTableProps.showActions() && (
              <div className="actions">
                <Button icon="far fa-thumbs-up" text="Approve" onClick={() => customTableProps.confirm(rowData)} />
                <Button styleName="btn-md" icon="far fa-thumbs-down" text="Reject" color="danger" onClick={() => customTableProps.reject(rowData)} />
              </div>
            )}
          </div>
        );
      },
    },
  ],
};
