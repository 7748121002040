import React, { useState } from 'react';
import styles from './Accordion.module.scss';

interface IAccordion {
  title?: string | React.ReactNode;
  content?: string | React.ReactNode;
  extraTitle?: string | React.ReactNode;
  children?: string | React.ReactNode;
  isAccordionActive?: boolean;
}

const defaultProps = {
  title: '',
  content: '',
  extraTitle: undefined,
  children: '',
  isAccordionActive: false,
};

function Accordion({ title, content, extraTitle, children, isAccordionActive }: IAccordion) {
  const [isActive, setIsActive] = useState(isAccordionActive);

  return (
    <div className={styles['accordion-item']}>
      <div className={styles['accordion-title-wrapper']} onClick={() => setIsActive(!isActive)} aria-hidden="true">
        <span className={styles['accordion-title']}>{title}</span>
        {extraTitle && <span className={styles['accordion-extra-title']}>{extraTitle}</span>}
        <span className={styles['accordion-indicator']}>{isActive ? <i className="fas fa-caret-down" /> : <i className="fas fa-caret-up" />}</span>
      </div>
      {isActive && <div className={styles['accordion-content']}>{content || children}</div>}
    </div>
  );
}

Accordion.defaultProps = defaultProps;

export default Accordion;
