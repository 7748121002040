import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import styles from './Edit.module.scss';
import AppLayoutWrapFull from 'components/AppLayoutWrapFull';
import PageHeader from 'components/PageHeader/PageHeader';
import Container from 'components/Container/Container';
import Row from 'components/Row/Row';
import FormElement from 'components/FormElement/FormElement';
import SelectWithSearch from 'components/SelectWithSearch/SelectWithSearch';
import Radio from 'components/Radio/Radio';
import AccessPermissions from '../components/AccessPermissions/AccessPermissions';
import HR from 'components/HR/HR';
import Button from 'components/Button/Button';
import {
  submitAddUserFormAction,
  submitEditUserFormAction,
  loadUsersAction,
  loadSitesAction,
  loadRolesAction,
  loadRolesUserAction,
  loadOrgSiteDeptAction,
  deactivateUserAction,
} from 'store/actions/Actions';
import { states, statesObj, country_list, showPassword } from 'helpers/utils';
import { generateOrgList, getInitState, getItemsList } from '../accessUtils';
import PassowordValidation from 'components/PasswordValidation/PasswordValidation';
import UserDeactivationModal from '../components/UserDeactivationModal/UserDeactivationModal';
import ReactDOM from 'react-dom';
import Spinner from 'components/Spinner/Spinner';
import ActionStatusConstants from 'helpers/ActionStatusConstants';
import { isPasswordValid, isEmailValid } from 'helpers/utils';

const Edit = (props) => {
  const navigate = useNavigate();

  const [showPasswordValidation, setShowPasswordValidation] = useState(false);
  const [togglePasswordIcon, setTogglePasswordIcon] = useState(false);
  const [togglePasswordIconConfirm, setTogglePasswordIconConfirm] = useState(false);
  const [isLoading, setLoading] = useState(true);

  const [officerState, setOfficerState] = useState({
    active: 't',
    privacy: false,
    security: false,
    connected_to_sso: false,
    sso: 'No',
    status: 'active',
    firstname: '',
    lastname: '',
    email: '',
    address_1: '',
    address_2: '',
    city: '',
    country_name: '',
    country_code: '',
    registered: '',
    state: '',
    state_name: '',
    postcode: '',
    password: '',
    'confirm-password': '',
    phone: '',
  });
  const [registeredUser, setRegisteredUser] = useState({});

  const [showUserDeactivationModal, setShowUserDeactivationModal] = useState(false);
  const renderUserDeactivationModal = () => {
    return ReactDOM.createPortal(<UserDeactivationModal data={showUserDeactivationModal} setShowModal={setShowUserDeactivationModal} />, document.getElementById('modal-root'));
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    const state_name = statesObj[officerState.state] || officerState.state;
    if (state_name) {
      setOfficerState((s) => ({
        ...s,
        state_name,
      }));
    }
  }, [officerState.state]);

  const [stateErrors, setStateErrors] = useState({
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    'confirm-password': '',
    access: '',
    address_1: '',
    address_2: '',
    country_name: '',
    city: '',
    state: '',
    state_name: '',
    postcode: '',
  });

  const [search] = useSearchParams();
  const userId = search.get('id');

  const {
    SitesReducer,
    loadUsersAction,
    loadSitesAction,
    UsersReducer,
    RolesReducer,
    loadOrgSiteDeptAction,
    loadRolesAction,
    loadRolesUserAction,
    OrgSiteDeptReducer,
    RolesUserReducer,
    UserOrgSiteReducer,
    deactivateUserAction,
  } = props;

  useEffect(() => {
    loadSitesAction();
    loadRolesAction();
    loadOrgSiteDeptAction();
    loadUsersAction({ id: userId });
    loadRolesUserAction({ id: userId });
  }, [loadSitesAction, loadUsersAction, loadRolesUserAction, loadRolesAction, loadOrgSiteDeptAction, userId]);

  useEffect(() => {
    if (UsersReducer.users && SitesReducer.sites && RolesUserReducer.roles && RolesReducer.roles && OrgSiteDeptReducer.organizations && UsersReducer.users[0]) {
      setOfficerState((s) => ({ ...s, ...UsersReducer.users[0] }));
      setRegisteredUser(UsersReducer.users[0].registered);
    }
  }, [UsersReducer.users, SitesReducer.sites, loadUsersAction, RolesUserReducer.roles, RolesReducer.roles, OrgSiteDeptReducer.organizations, userId]);

  useEffect(() => {
    if (
      UsersReducer.status === ActionStatusConstants.SUCCESS &&
      SitesReducer.status === ActionStatusConstants.SUCCESS &&
      RolesUserReducer.status === ActionStatusConstants.SUCCESS &&
      RolesReducer.status === ActionStatusConstants.SUCCESS &&
      OrgSiteDeptReducer.status === ActionStatusConstants.SUCCESS
    ) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [UsersReducer.users, SitesReducer.sites, RolesUserReducer.roles, RolesReducer.roles, OrgSiteDeptReducer.organizations]);

  const handleChangeSSO = (value) => (e) => {
    setOfficerState((s) => ({ ...s, connected_to_sso: value, sso: value ? "Azure" : "No" }))
  }

  const handleChange = (inputName) => (e) => {
    e.persist();
    if (inputName === 'password') {
      if (e.target.value && showPasswordValidation === false) {
        setShowPasswordValidation(true);
      }

      if (!e.target.value && showPasswordValidation === true) {
        setShowPasswordValidation(false);
      }
    }

    setOfficerState((s) => ({
      ...s,
      [inputName]: e.target.value,
    }));

    if (inputName === 'active') {
      setOfficerState((s) => ({
        ...s,
        registered: 't',
      }));
    }

    if (inputName === 'registered') {
      setOfficerState((s) => ({
        ...s,
        active: 't',
      }));
    }

    setStateErrors((s) => ({ ...s, [inputName]: '' }));
  };

  const handleChangeSelectWithSearch = (type) => (data) => {
    let tmp = {};
    if (type === 'country_name') {
      tmp = {
        country_code: data?.id || '',
        country_name: data?.name || '',
      };
    }
    if (type === 'state_name') {
      tmp = {
        state: data?.id || '',
        state_name: data?.name || '',
      };
    }
    setOfficerState((s) => ({
      ...s,
      ...tmp,
    }));
    setStateErrors((s) => ({ ...s, [type]: '' }));
  };

  const handleSubmitEditForm = (e) => {
    e.preventDefault();
    const access = [];
    let errorAccess = false;
    let errorDepartment = false;
    const rolesElemnets = document.querySelectorAll('[name=role]');
    if (rolesElemnets.length < 1) {
      errorAccess = true;
    }
    rolesElemnets.forEach((item) => {
      if (item.value === '0') {
        errorAccess = true;
      }
      access.push({
        role_id: item.value,
        organization: item.dataset.org,
        site: item.dataset.site || null,
        department: item.dataset.dep || (item.dataset.partial === 'true' && 'partial') || null,
      });
    });

    if (
      errorAccess ||
      // errorDepartment ||
      (officerState.registered === 't' && officerState.active === 't' && officerState.firstname === '') ||
      (officerState.registered === 't' && officerState.active === 't' && officerState.lastname === '') ||
      officerState.email === '' ||
      !isEmailValid(officerState.email) ||
      (officerState.password && !(registeredUser === 'f' && officerState.registered === 't' && officerState.active === 't') && !isPasswordValid(officerState.password)) ||
      (registeredUser === 'f' && officerState.registered === 't' && officerState.active === 't' && !isPasswordValid(officerState.password)) ||
      (officerState.password !== '' && officerState.password !== officerState['confirm-password'])
    ) {
      setStateErrors((s) => ({
        ...s,
        // ...validatePass(officerState),
        access: errorAccess ? (errorDepartment ? 'At least one department per site is required!' : 'Access role(s) are required!') : '',
        firstname: officerState.registered === 't' && officerState.active === 't' && officerState.firstname === '' ? 'First name is required' : '',
        lastname: officerState.registered === 't' && officerState.active === 't' && officerState.lastname === '' ? 'Last name is required' : '',
        email: officerState.email === '' || !isEmailValid(officerState.email) ? 'Email is required' : '',
        password:
          registeredUser === 'f' && officerState.registered === 't' && officerState.active === 't' && !officerState.password
            ? 'Password is required'
            : (officerState.password && !(registeredUser === 'f' && officerState.registered === 't' && officerState.active === 't') && !isPasswordValid(officerState.password)) ||
              (registeredUser === 'f' && officerState.registered === 't' && officerState.active === 't' && !isPasswordValid(officerState.password))
            ? 'The password is not valid'
            : '',
        ['confirm-password']:
          officerState.password !== '' && officerState.password !== officerState['confirm-password']
            ? `
          Passwords don't match
        `
            : '',
      }));
    } else {
      const selectedOrg = OrgSiteDeptReducer.organizations ? Object.keys(OrgSiteDeptReducer.organizations)[0] : null;
      const access_roles = access.length === 0 ? [{ organization: selectedOrg }] : access;
      if (registeredUser === 't' && officerState.registered === 't' && officerState.active === 'f') {
        // User was registered and active but about to become inactive
        setShowUserDeactivationModal({
          title: 'User Deactivation',
          question: 'Are you sure you want to deactivate the user?',
          currentUserId: officerState.id,
          confirmAction: (reasignableUserId) => {
            deactivateUserAction({
              id: officerState.id,
              to_user: reasignableUserId.toString(),
            });
          },
        });
      } else {
        props.submitEditUserFormAction({
          ...officerState,
          role: access_roles,
        });
      }
    }
  };

  // on add we need to check if selected org is sso org
  const isSSOEnabled =
  (OrgSiteDeptReducer.organizations &&
    UserOrgSiteReducer.selected &&
    OrgSiteDeptReducer.organizations[UserOrgSiteReducer.selected.org] &&
    OrgSiteDeptReducer.organizations[UserOrgSiteReducer.selected.org].sso_enabled) ||
  false;

  const [localBoxes, setLocalBoxes] = useState({});

  const orgs = generateOrgList(OrgSiteDeptReducer.organizations);
  const items = getItemsList(orgs);
  const initState = {
    ...getInitState(RolesUserReducer.roles, items, RolesReducer.roles),
    ...localBoxes,
  };

  const togglePasswordVisibility = (e, id, isPwdVisible, toggleSetter) => {
    e.preventDefault();
    toggleSetter(isPwdVisible);
    showPassword(id, isPwdVisible);
  };

  return (
    <AppLayoutWrapFull>
      <PageHeader />
      {isLoading ? (
        <Spinner />
      ) : (
        <Container>
          <form className={styles['add-user-form']}>
            <div className={styles.holder}>
              <div className={styles.left}>
                <FormElement className={styles.form_element} labelText="FIRST NAME" smallText="*" htmlFor="firstname" errorMessage={stateErrors.firstname}>
                  <input autoComplete="none" name="firstname" type="text" placeholder="Enter your first name" value={officerState.firstname} onChange={handleChange('firstname')} />
                </FormElement>

                <FormElement className={styles.form_element} labelText="LAST NAME" smallText="*" htmlFor="lastname" errorMessage={stateErrors.lastname}>
                  <input autoComplete="none" name="lastname" type="text" placeholder="Enter your last name" value={officerState.lastname} onChange={handleChange('lastname')} />
                </FormElement>

                <FormElement className={styles.form_element} labelText="EMAIL" smallText="*" htmlFor="email" errorMessage={stateErrors.email}>
                  <input
                    disabled={officerState.connected_to_sso}
                    autoComplete="new-email"
                    name="email"
                    type="email"
                    placeholder="Enter your email address"
                    value={officerState.email}
                    onChange={handleChange('email')}
                  />
                </FormElement>

                {!officerState.connected_to_sso && (
                  <Row className={`${styles['form-group-row']} ${styles.form_element}`}>
                    <FormElement labelText="PASSWORD" htmlFor="password" errorMessage={stateErrors.password}>
                      <input
                        autoComplete="new-password"
                        name="password"
                        type="password"
                        placeholder="Enter your password"
                        value={officerState.password}
                        onChange={handleChange('password')}
                        className={styles.password}
                        id="pass-user-edit"
                      />
                      <span className={styles['show-password']}>
                        <button className={styles['password-button']} onClick={(e) => togglePasswordVisibility(e, 'pass-user-edit', !togglePasswordIcon, setTogglePasswordIcon)}>
                          <i className={togglePasswordIcon ? 'hide' : 'fas fa-eye'} title="show password"></i>
                          <i className={!togglePasswordIcon ? 'hide' : 'fas fa-eye-slash'} title="hide password"></i>
                        </button>
                      </span>
                    </FormElement>

                    <FormElement labelText="CONFIRM PASSWORD" htmlFor="confirm-password" errorMessage={stateErrors['confirm-password']}>
                      <input
                        autoComplete="pass-confirm"
                        name="confirm-password"
                        type="password"
                        placeholder="Confirm your password"
                        value={officerState['confirm-password']}
                        onChange={handleChange('confirm-password')}
                        className={styles.password}
                        id="pass-user-edit-confirm"
                      />
                      <span className={styles['show-password']}>
                        <button
                          className={styles['password-button']}
                          onClick={(e) => togglePasswordVisibility(e, 'pass-user-edit-confirm', !togglePasswordIconConfirm, setTogglePasswordIconConfirm)}
                        >
                          <i className={togglePasswordIconConfirm ? 'hide' : 'fas fa-eye'} title="show password"></i>
                          <i className={!togglePasswordIconConfirm ? 'hide' : 'fas fa-eye-slash'} title="hide password"></i>
                        </button>
                      </span>
                    </FormElement>
                  </Row>
                )}

                {showPasswordValidation && !isPasswordValid(officerState.password) ? <PassowordValidation password={officerState.password} /> : ''}

                <FormElement className={styles.form_element} errorMessage={stateErrors['phone']}>
                  <label htmlFor="phone">CONTACT PHONE</label>
                  <Row className={styles['form-group-row']}>
                    <input
                      autoComplete="none"
                      className={styles['contact-phone-input']}
                      name="phone"
                      type="tel"
                      placeholder="Enter your phone number"
                      value={officerState['phone']}
                      onChange={handleChange('phone')}
                    />
                  </Row>
                </FormElement>
                <FormElement className={styles.form_element} labelText="ADDRESS" htmlFor="address_1" errorMessage={stateErrors.address_1}>
                  <input autoComplete="none" name="address_1" type="text" placeholder="Street and number" value={officerState.address_1} onChange={handleChange('address_1')} />
                </FormElement>
                <FormElement
                  className={styles.form_element}
                  // labelText="ADDRESS 2"
                  htmlFor="address_2"
                  errorMessage={stateErrors.address_2}
                >
                  <input
                    autoComplete="none"
                    name="address_2"
                    type="text"
                    placeholder="Apartment, building, floor (optional)"
                    value={officerState.address_2}
                    onChange={handleChange('address_2')}
                  />
                </FormElement>

                <FormElement className={styles.form_element} labelText="COUNTRY" htmlFor="country_name" errorMessage={stateErrors.country_name}>
                  <SelectWithSearch value={officerState.country_code} onChange={handleChangeSelectWithSearch('country_name')} options={country_list} placeholder="Country" />
                </FormElement>

                <Row className={`${styles['form-group-row']} ${styles['city-state-postcode']} ${styles.form_element}`}>
                  <FormElement labelText="CITY" htmlFor="city" errorMessage={stateErrors.city}>
                    <input autoComplete="none" name="city" type="text" placeholder="City" value={officerState.city} onChange={handleChange('city')} />
                  </FormElement>

                  <FormElement labelText="STATE / REGION" htmlFor="state" errorMessage={stateErrors.state}>
                    <SelectWithSearch
                      placeholder="State"
                      value={officerState.state}
                      onChange={handleChangeSelectWithSearch('state_name')}
                      options={(states || []).map((item) => ({
                        ...item,
                        id: item.abbrev,
                      }))}
                    />
                  </FormElement>

                  <FormElement labelText="POSTAL CODE" htmlFor="postcode" errorMessage={stateErrors.postcode}>
                    <input autoComplete="none" name="postcode" type="text" placeholder="Postal Code" value={officerState.postcode} onChange={handleChange('postcode')} />
                  </FormElement>
                </Row>
              </div>

              <div className={styles.right}>
                <FormElement labelText="STATUS" htmlFor="status" className={styles.form_element}>
                  <Row className={styles['status-row']}>
                    <Radio checked={officerState.active === 't' && officerState.registered === 't'} labelText="Active" onChange={handleChange('active')} value="t" />
                    <Radio checked={officerState.active === 'f'} labelText="Inactive" onChange={handleChange('active')} value="f" />
                    {registeredUser !== 't' && (
                      <Radio
                        checked={officerState.active === 't' && officerState.registered === 'f'}
                        labelText="Awaiting Registration"
                        onChange={handleChange('registered')}
                        value="f"
                      />
                    )}
                  </Row>
                </FormElement>

                {isSSOEnabled ? (
                  <FormElement labelText="SSO" htmlFor="sso" className={styles.form_element}>
                    <Row className={styles['status-row']}>
                      <Radio checked={officerState.sso === 'Azure'} labelText="Enabled" onChange={handleChangeSSO(true)} value="Azure" />
                      <Radio checked={officerState.sso === 'No' || officerState.sso === ''} labelText="Disabled" onChange={handleChangeSSO(false)} value="No" />
                    </Row>
                  </FormElement>
                ) : null}

                {OrgSiteDeptReducer.organizations && RolesReducer.roles && (
                  <AccessPermissions
                    className={styles['access-permissions']}
                    organizations={OrgSiteDeptReducer.organizations}
                    loadRolesUserAction={loadRolesUserAction}
                    userId={userId}
                    initState={initState}
                    orgs={orgs}
                    userOrgs={UserOrgSiteReducer.organizations}
                    selected={UserOrgSiteReducer.selected}
                    setLocalBoxes={setLocalBoxes}
                    loadOrgSiteDeptAction={loadOrgSiteDeptAction}
                    roles={RolesReducer.roles}
                    error={stateErrors.access}
                    setStateErrors={setStateErrors}
                    // permissions={RolesUserReducer.roles}
                    placeholder="The Business Company"
                    UsersReducer={UsersReducer}
                  />
                )}
              </div>
            </div>

            <HR className={styles.hr} />

            <div className={styles['submit-box']}>
              <Button text="Cancel" color="secondary" onClick={() => navigate('/maintain/users')} />
              <Button icon="far fa-save" text="Save" onClick={handleSubmitEditForm} />
            </div>
          </form>
          {showUserDeactivationModal && renderUserDeactivationModal()}
        </Container>
      )}
    </AppLayoutWrapFull>
  );
};

const mapStateToProps = ({ SitesReducer, UsersReducer, AddUserFormReducer, AddOfficerFormReducer, UserOrgSiteReducer, RolesReducer, OrgSiteDeptReducer, RolesUserReducer }) => {
  return {
    SitesReducer,
    UsersReducer,
    AddUserFormReducer,
    AddOfficerFormReducer,
    UserOrgSiteReducer,
    RolesReducer,
    OrgSiteDeptReducer,
    RolesUserReducer,
  };
};

const mapDispatchToProps = {
  submitAddUserFormAction,
  submitEditUserFormAction,
  loadUsersAction,
  loadSitesAction,
  loadRolesAction,
  loadRolesUserAction,
  loadOrgSiteDeptAction,
  deactivateUserAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
