import { call, put, takeLatest, delay, select } from 'redux-saga/effects';
import { push } from 'redux-first-history';

import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { actionApiCall } from 'helpers/api/ApiCalls';
import * as sagaConstants from 'helpers/constants/SagasConstants';
import { redirectToAudit } from 'helpers/utils';

const getSelected = (state) => state.UserOrgSiteReducer.selected;

function* bulkUploadCloudAssetsSaga({ payload }) {
  try {
    const apiResponse = yield call(actionApiCall, {
      actionType: ActionTypeConstants.BULK_UPLOAD_CLOUD_ASSETS,
      data: {
        file: payload.file,
        audit_id: payload.auditId,
      },
    });
    if (apiResponse.res.data.response === 200) {
      const selected = yield select(getSelected);
      yield put({
        type: ActionTypeConstants.BULK_UPLOAD_CLOUD_ASSETS_SUCCESS,
        payload,
      });
      yield put(push(redirectToAudit('audit-cloud', payload.auditId, selected.ui_version)));

      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: apiResponse.res.data.message || `Cloud asset(s) ${sagaConstants.SUCCESSFULLY_IMPORTED}`,
          data: apiResponse.res.data.data,
          title: apiResponse.res.data.data.title,
          color: 'success',
          type: apiResponse.res.data.data.type,
        },
      });
      yield delay(10000);
      yield put({ type: ActionTypeConstants.HIDE_ALERT });
    } else {
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: apiResponse.res.data.message || `${sagaConstants.ISSUE_OCCURED} importing cloud assets`,
          color: 'danger',
        },
      });
      yield delay(10000);
      yield put({ type: ActionTypeConstants.HIDE_ALERT });
    }
  } catch (error) {
    yield put({
      type: ActionTypeConstants.SHOW_ALERT,
      payload: {
        message: `${sagaConstants.SERVER_ERROR}`,
        color: 'danger',
      },
    });
    yield delay(10000);
    yield put({ type: ActionTypeConstants.HIDE_ALERT });
  }
}

export default function* () {
  yield takeLatest(ActionTypeConstants.BULK_UPLOAD_CLOUD_ASSETS, bulkUploadCloudAssetsSaga);
}
