import React from 'react';
import ItemActionsMenu from 'components/DataTable/ItemActionsMenu';

import moment from 'moment';

export default {
  TABLE: {
    className: 'data-table-tasks',
    initialSortKey: 'created_at',
    initialSortOrder: 'desc',
  },

  COLUMN: [
    {
      label: 'File Name',
      header: 'FILE NAME',
      key: 'filename',
      sortable: true,
      className: 'filename',
      format: ({ rowData }) => {
        return <span>{rowData['filename']}</span>;
      },
    },
    {
      label: 'DATE',
      header: 'DATE',
      key: 'created_at',
      sortable: true,
      format: ({ rowData }) => {
        return <span>{rowData['created_at'] ? moment(rowData['created_at']).format('MM/DD/YYYY hh:mm A') : ''}</span>;
      },
    },
    {
      label: 'Added By',
      header: 'ADDED BY',
      key: 'created_by_name',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{rowData['created_by_name']}</span>;
      },
    },
    {
      label: 'Actions',
      key: 'actions',
      format: ({ rowData, customTableProps }) => {
        const actionMenuDropDown = React.createRef();
        // const isCurrentUserUploader = (rowData.created_by === customTableProps.currentUserId);
        return (
          <ItemActionsMenu
            ref={actionMenuDropDown}
            actions={(() => {
              return [
                customTableProps.downloadEvidence
                  ? {
                      label: 'Download',
                      action: () => {
                        if (actionMenuDropDown && actionMenuDropDown.current) {
                          actionMenuDropDown.current.handleMenuClose();
                        }
                        customTableProps.downloadEvidence(rowData);
                      },
                    }
                  : false,
                customTableProps.deleteEvidence && {
                  label: 'Delete',
                  action: () => {
                    if (actionMenuDropDown && actionMenuDropDown.current) {
                      actionMenuDropDown.current.handleMenuClose();
                    }
                    customTableProps.deleteEvidence(rowData);
                  },
                },
              ];
            })()}
          />
        );
      },
    },
  ],
};
