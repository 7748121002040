import ColVisibilitySelector from 'components/DataTable/ColVisibilitySelector';
import ItemActionsMenu from 'components/DataTable/ItemActionsMenu';
import React, { RefObject } from 'react';
import Checkbox from 'components/Checkbox/Checkbox';
import moment from 'moment';
import { Button } from 'components/Button/Button';
import { getStatusButtonColor } from 'helpers/utils';
import { Audit } from 'types/Audit';

interface IAuditTable {
  TABLE;
  COLUMN: Array<{
    label: string;
    key: string;
    sortable?: boolean;
    header?;
    className?: string;
    format: ({ rowData, customTableProps }: { rowData: Audit; customTableProps }) => void;
    canBeHidden?: boolean;
  }>;
}

const tableDefenition: IAuditTable = {
  TABLE: {
    className: 'data-table-audits',
    initialSortKey: 'assigned',
    initialSortOrder: 'desc',
  },

  COLUMN: [
    {
      label: 'Row Selector',
      key: 'row-selector',
      header: ({ customTableProps }) => {
        const isSelectedAll = customTableProps?.selectedRows?.length === customTableProps?.rowsData?.length && customTableProps?.rowsData?.length > 0;
        // const isPartial = (customTableProps?.selectedRows?.length < customTableProps?.rowsData?.length) && customTableProps?.selectedRows?.length > 0;
        return (
          <Checkbox
            checked={isSelectedAll}
            onChange={() => customTableProps.doSelectAll()}
            name="row-selector"
            // partial={isPartial}
            // data-partial={isPartial}
          />
        );
      },
      className: 'col-row-selector',
      format: ({ rowData, customTableProps }) => {
        const isSelected = customTableProps?.selectedRows?.some((checkbox) => checkbox.id === rowData.id);
        return (
          <Checkbox
            disabled={rowData.deactivated === 't'}
            checked={rowData.deactivated === 't' ? false : isSelected}
            onChange={() => customTableProps.onRowSelect({ rowData })}
            name="row-selector"
          />
        );
      },
      canBeHidden: false,
    },
    {
      label: 'Title',
      header: 'TITLE',
      key: 'name',
      sortable: true,
      className: 'title',
      format: ({ rowData, customTableProps }) => <span>{rowData.name}</span>,
    },
    {
      label: 'Program',
      header: 'PROGRAM',
      key: 'regulatory_act',
      sortable: true,
      className: 'type',
      format: ({ rowData }) => <span><strong>{rowData.regulatory_act}</strong><br /><small>{rowData.actName}</small></span>,
    },
    {
      label: 'Assigned',
      header: 'ASSIGNED',
      key: 'assigned',
      sortable: true,
      className: 'assigned',
      canBeHidden: true,
      format: ({ rowData, customTableProps }) => <span>{rowData.assigned ? moment(rowData.assigned).format('MM/DD/YYYY') : ''}</span>,
    },
    {
      label: 'Launch',
      header: 'LAUNCH DATE',
      key: 'launch_date',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => <span>{rowData.launch_date ? moment(rowData.launch_date).format('MM/DD/YYYY') : '-'}</span>,
    },
    {
      label: 'Taken',
      header: 'TAKEN',
      key: 'taken',
      sortable: true,
      className: 'taken',
      canBeHidden: true,
      format: ({ rowData, customTableProps }) => <span>{rowData.taken ? moment(rowData.taken).format('MM/DD/YYYY') : '-'}</span>,
    },
    // {
    //   label: 'Assessment',
    //   header: 'ASSESSMENT',
    //   key: 'actName',
    //   sortable: true,
    //   className: 'act',
    //   canBeHidden: true,
    //   format: ({ rowData, customTableProps }) => <span>{rowData.actName}</span>,
    // },
    {
      label: 'Frequency',
      header: 'FREQUENCY',
      key: 'frequency_label',
      sortable: true,
      className: 'frequency',
      canBeHidden: true,
      format: ({ rowData, customTableProps }) => <span>{rowData.frequency_label}</span>,
    },
    {
      label: 'Site',
      header: 'SITE',
      key: 'siteName',
      sortable: true,
      className: 'site',
      format: ({ rowData, customTableProps }) => <span>{rowData.siteName || 'All Sites'}</span>,
    },
    {
      label: 'Status',
      header: 'STATUS',
      key: 'status',
      sortable: true,
      className: 'status',
      canBeHidden: true,
      format: ({ rowData, customTableProps }) => {
        const status = rowData.configuration && rowData.configuration.status_label;
        const color = getStatusButtonColor(status, ['Finalized'], ['Error on launch', 'Error on reset', 'Closed incomplete']);
        return (
          <span>
            <Button cursorDefault={customTableProps.cursorDefault} color={color} size="md" text={status} />
          </span>
        );
      },
    },
    {
      label: 'Actions',
      key: 'actions',
      format: ({ rowData, customTableProps }) => {
        const actionMenuDropDown = React.createRef() as RefObject<ItemActionsMenu>;
        return (
          <ItemActionsMenu
            ref={actionMenuDropDown}
            actions={(() => [
              rowData.type !== 'device' && {
                label: 'View',
                action: () => {
                  if (actionMenuDropDown && actionMenuDropDown.current) {
                    actionMenuDropDown.current.handleMenuClose();
                  }
                  customTableProps.view(rowData);
                },
              },
              rowData.type !== 'device' &&
                rowData.deactivated !== 't' && {
                  label: 'Edit',
                  action: () => {
                    if (actionMenuDropDown && actionMenuDropDown.current) {
                      actionMenuDropDown.current.handleMenuClose();
                    }
                    customTableProps.edit(rowData);
                  },
                },
              customTableProps.download &&
                rowData.type !== 'cloud' &&
                rowData.type !== 'inventory' &&
                rowData.deactivated !== 't' && {
                  label: 'Download',
                  action: () => {
                    if (actionMenuDropDown && actionMenuDropDown.current) {
                      actionMenuDropDown.current.handleMenuClose();
                    }
                    if (rowData.type === 'device') {
                      customTableProps.downloadDevices(rowData);
                    } else {
                      customTableProps.download(rowData);
                    }
                  },
                },
              rowData.type !== 'device' && {
                label: 'Notify',
                action: () => {
                  if (actionMenuDropDown && actionMenuDropDown.current) {
                    actionMenuDropDown.current.handleMenuClose();
                  }
                  customTableProps.notify(rowData);
                },
              },
              customTableProps.upload &&
                rowData.type !== 'device' &&
                rowData.type !== 'cloud' &&
                rowData.type !== 'inventory' &&
                rowData.deactivated !== 't' &&
                rowData.configuration &&
                !rowData.configuration.reset && {
                  label: 'Upload',
                  action: () => {
                    if (actionMenuDropDown && actionMenuDropDown.current) {
                      actionMenuDropDown.current.handleMenuClose();
                    }
                    customTableProps.upload(rowData);
                  },
                },
              customTableProps.evidence &&
                (rowData.type === 'cloud' || rowData.type === 'inventory') &&
                rowData.deactivated !== 't' && {
                  label: 'Evidence Locker',
                  action: () => {
                    if (actionMenuDropDown && actionMenuDropDown.current) {
                      actionMenuDropDown.current.handleMenuClose();
                    }
                    customTableProps.evidence(rowData);
                  },
                },
              (customTableProps.is_super || customTableProps.is_coach) &&
                rowData.deactivated !== 't' && {
                  label: 'Delete',
                  action: () => {
                    if (actionMenuDropDown && actionMenuDropDown.current) {
                      actionMenuDropDown.current.handleMenuClose();
                    }
                    customTableProps.delete(rowData);
                  },
                },
              rowData.finalized === 't' &&
                rowData.deactivated !== 't' &&
                (customTableProps.is_super || customTableProps.is_coach) &&
                rowData.configuration &&
                !rowData.configuration.reset && {
                  label: 'Reopen',
                  action: () => {
                    if (actionMenuDropDown && actionMenuDropDown.current) {
                      actionMenuDropDown.current.handleMenuClose();
                    }
                    customTableProps.reopen(rowData);
                  },
                },
            ])()}
          />
        );
      },
      className: 'col-actions',
      header: ({ customTableProps }) => (
        <ColVisibilitySelector
          colDefs={customTableProps.colDefs}
          visibleColumnKeys={customTableProps.visibleColumnKeys}
          onChangeVisibility={(colKey) => customTableProps.onChangeVisibility(colKey)}
        />
      ),
    },
  ],
};

export default tableDefenition;
