import { call, put, takeLatest, delay } from 'redux-saga/effects';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { actionApiCall } from 'helpers/api/ApiCalls';
import * as sagaConstants from 'helpers/constants/SagasConstants';

function* checkQuizAnswersSaga({ payload }) {
  try {
    const apiResponse = yield call(actionApiCall, {
      actionType: ActionTypeConstants.CHECK_QUIZ_ANSWERS,
      data: {
        ...payload,
      },
    });
    yield delay(300);
    if (apiResponse.res.data.response === 200) {
      yield put({
        type: ActionTypeConstants.CHECK_QUIZ_ANSWERS_SUCCESS,
        payload: {
          section_passed: apiResponse.res.data.data.section_passed,
          section_watched: apiResponse.res.data.data.section_watched,
          section_hints: apiResponse.res.data.data.questions,
        },
      });
    } else {
      yield put({
        type: ActionTypeConstants.CHECK_QUIZ_ANSWERS_FAILURE,
        payload: {
          message: apiResponse.res.data.message || `${sagaConstants.ISSUE_OCCURED} searching users`,
        },
      });
    }
  } catch (err) {
    yield put({
      type: ActionTypeConstants.CHECK_QUIZ_ANSWERS_FAILURE,
      payload: {
        message: `${sagaConstants.SERVER_ERROR}`,
      },
    });
  }
}

function* checkQuizAnswersSagaFail({ payload }) {
  yield put({
    type: ActionTypeConstants.SHOW_ALERT,
    payload: {
      message: payload.message || 'An issue occurred while searching users',
      color: 'danger',
    },
  });
  yield delay(10000);
  yield put({ type: ActionTypeConstants.HIDE_ALERT });
}

export default function* () {
  yield takeLatest(ActionTypeConstants.CHECK_QUIZ_ANSWERS, checkQuizAnswersSaga);
  yield takeLatest(ActionTypeConstants.CHECK_QUIZ_ANSWERS_FAILURE, checkQuizAnswersSagaFail);
}
