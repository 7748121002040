import React, { useState, useEffect } from 'react';
import Modal from 'components/Modal/Modal';
import { Button } from 'components/Button/Button';
import Select from 'components/Select/Select';
import { useAppDispatch, useAppSelector } from 'hooks';
import { loadReasignableUsersAction } from 'store/actions/Actions';
import styles from './UserDeactivationModal.module.scss';

function UserDeactivationModal(props) {
  const dispatch = useAppDispatch();
  const ReasignableUsersReducer = useAppSelector((state) => state.ReasignableUsersReducer);

  const dropdowneRef = React.createRef();

  const [reasignableUsers, setReasignableUsers] = useState([]);
  const [reasignableUserId, setReasignableUserId] = useState(0);

  const handleConfirm = () => {
    try {
      props.data.confirmAction(reasignableUserId);
      props.setShowModal(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  useEffect(() => {
    if (!ReasignableUsersReducer.users) {
      dispatch(loadReasignableUsersAction({ userId: props.data.currentUserId }));
    } else {
      setReasignableUsers(ReasignableUsersReducer.users);
    }
  }, [ReasignableUsersReducer.users, props.data.currentUserId]);

  const modalButtons = (
    <>
      <Button text="CANCEL" color="secondary" onClick={() => props.setShowModal(false)} />
      <Button text={props.data.btnLabel || 'DEACTIVATE'} color="danger" className={styles.create} onClick={handleConfirm} />
    </>
  );

  return (
    <Modal className={styles.modal} setShowModal={props.setShowModal}>
      <Modal.Heading>{props.data.title}</Modal.Heading>

      <div className={styles['modal-content']}>{props.data.question}</div>

      <div className={styles['modal-reassign']}>
        <div className={styles['modal-reassign-desc']}>If you want to reassign all of this user&apos;s tasks to a different user please use the menu below:</div>
        <Select
          value={
            (reasignableUsers.find((ru) => ru.id.toString() === reasignableUserId.toString()) &&
              reasignableUsers.find((ru) => ru.id.toString() === reasignableUserId.toString()).name) ||
            "Don't reassign"
          }
          size="md"
          onClick={(e) => setReasignableUserId(e.target.value)}
          dropdownToggleRef={dropdowneRef}
        >
          {reasignableUsers.map((el) => (
            <li value={el.id} key={el.id}>
              {el.name}
            </li>
          ))}
        </Select>
      </div>

      <Modal.Footer>{modalButtons}</Modal.Footer>
    </Modal>
  );
}

export default UserDeactivationModal;
