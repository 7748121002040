import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import styles from './Edit.module.scss';
import { useNavigate, useSearchParams } from 'react-router-dom';

import AppLayoutWrapFull from 'components/AppLayoutWrapFull';
import PageHeader from 'components/PageHeader/PageHeader';
import Container from 'components/Container/Container';
import FormElement from 'components/FormElement/FormElement';
import DateInput from 'components/DateInput/DateInput';
import Select from 'components/Select/Select';
import Row from 'components/Row/Row';
import Checkbox from 'components/Checkbox/Checkbox';
import Tabs from 'components/Tabs/Tabs';
import AddTask from 'views/Maintain/Incidents/AddTask/AddTask';
import EditEvidence from 'views/Maintain/Incidents/Evidence/EditEvidence';
import HR from 'components/HR/HR';
import Button from 'components/Button/Button';
import FullDataTable from 'components/DataTable/FullDataTable';
import TableDefNotes from 'table-defs/TableDefNotes';

import {
  loadSitesAction,
  loadIncidentManagerAction,
  updateIncidentManagerAction,
  loadIncidentTypesAction,
  loadAffiliatesAction,
  loadIncidentNotesAction,
  addIncidentNoteAction,
  deleteIncidentNoteAction,
} from 'store/actions/Actions';

import { toggleDropdowns } from 'helpers/utils';
import Spinner from 'components/Spinner/Spinner';
import ActionStatusConstants from 'helpers/ActionStatusConstants';

const IncidentManager = (props) => {
  const defaultIncident = {
    id: '',
    site: '',
    affiliate: '',
    name: '',
    reported_by: 'Anonymous',
    type: '',
    reason: '',
    regulatory_act: '',
    investigating: '',
    occurred: '',
    discovered: '',
    affected: false,
    description: '',
  };

  const [stateErrors, setStateErrors] = useState({
    site: '',
    name: '',
    type: '',
    occurred: '',
    discovered: '',
  });
  const [isLoading, setLoading] = useState(true);

  const {
    OrgModulesReducer,
    IncidentNotesReducer,
    IncidentTypesReducer,
    IncidentManagerReducer,
    AffiliatesReducer,
    SitesReducer,
    loadIncidentManagerAction,
    loadSitesAction,
    loadAffiliatesAction,
    deleteIncidentNoteAction,
    addIncidentNoteAction,
    loadIncidentTypesAction,
    loadIncidentNotesAction,
    updateIncidentManagerAction,
    UserOrgSiteReducer,
    AuthReducer,
  } = props;

  const navigate = useNavigate();

  const [incident, setIncident] = useState(defaultIncident);
  const [tasks, setTasks] = useState([]);
  const [activeTab, setActiveTab] = useState('DESCRIPTION');
  const [search] = useSearchParams();
  const incidentId = search.get('id');
  const userId = AuthReducer.userData.id;

  const [note, setNote] = useState('');

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    setTasks(incident.tasks);
  }, [incident.tasks, setTasks]);

  useEffect(() => {
    if (!IncidentManagerReducer.incident) {
      loadIncidentManagerAction(incidentId);
    } else {
      setIncident((s) => ({
        ...s,
        ...IncidentManagerReducer.incident,
      }));
    }
  }, [IncidentManagerReducer.incident, loadIncidentManagerAction, incidentId]);

  useEffect(() => {
    if (!SitesReducer.sites) {
      loadSitesAction();
    }
  }, [SitesReducer.sites, loadSitesAction]);

  useEffect(() => {
    if (!IncidentNotesReducer.notes) {
      loadIncidentNotesAction({ incidentId });
    }
  }, [IncidentNotesReducer.notes, loadIncidentNotesAction, incidentId]);

  useEffect(() => {
    if (!AffiliatesReducer.affiliates) {
      loadAffiliatesAction();
    }
  }, [AffiliatesReducer.affiliates, loadAffiliatesAction]);

  useEffect(() => {
    if (!IncidentTypesReducer.incidentTypes && incident.regulatory_act && incident.regulatory_act !== '') {
      loadIncidentTypesAction();
    }
  }, [IncidentTypesReducer.incidentTypes, loadIncidentTypesAction]);

  useEffect(() => {
    if (incident.regulatory_act && incident.regulatory_act !== '') {
      loadIncidentTypesAction({ regulatory_act: incident.regulatory_act });
    }
  }, [loadIncidentTypesAction, incident.regulatory_act]);

  useEffect(() => {
    if (
      IncidentTypesReducer.status === ActionStatusConstants.SUCCESS &&
      AffiliatesReducer.status === ActionStatusConstants.SUCCESS &&
      IncidentNotesReducer.status === ActionStatusConstants.SUCCESS &&
      SitesReducer.status === ActionStatusConstants.SUCCESS &&
      IncidentManagerReducer.status === ActionStatusConstants.SUCCESS
    ) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [IncidentTypesReducer.incidentTypes, AffiliatesReducer.affiliates, IncidentNotesReducer.notes, SitesReducer.sites, IncidentManagerReducer.incident]);

  const setTabAsActive = (heading) => () => {
    setActiveTab(heading);
  };

  const handleCheckbox = (inputName) => (e) => {
    e.persist();
    setIncident((s) => ({
      ...s,
      [inputName]: e.target.checked ? true : false,
    }));
    setStateErrors((s) => ({ ...s, [inputName]: '' }));
  };

  const handleSelect = (inputName) => (e) => {
    e.persist();
    setIncident((s) => ({
      ...s,
      [inputName]: e.target.attributes && e.target.attributes.value ? e.target.attributes.value.value : '',
    }));
    setStateErrors((s) => ({ ...s, [inputName]: '' }));
  };

  const handleRegSelect = (inputName) => (e) => {
    e.persist();
    setIncident((s) => ({
      ...s,
      [inputName]: e.target.attributes && e.target.attributes.value ? e.target.attributes.value.value : '',
      type: '',
    }));
    setStateErrors((s) => ({ ...s, [inputName]: '', type: '' }));
  };

  const handleChange = (inputName) => (e) => {
    e.persist();
    setIncident((s) => ({
      ...s,
      [inputName]: e.target.value,
    }));
    setStateErrors((s) => ({ ...s, [inputName]: '' }));
  };

  const addIncidentNote = () => {
    if (incidentId && note && note !== '') {
      addIncidentNoteAction({ incidentId, note });
      setNote('');
    }
  };

  const updateIncident = () => {
    var today = moment();

    if (
      incident.site === '' ||
      incident.name === '' ||
      incident.type === '' ||
      incident.occurred === '' ||
      incident.discovered === '' ||
      today.diff(incident.occurred) < 0 ||
      today.diff(incident.discovered) < 0
    ) {
      setStateErrors({
        ...stateErrors,
        site: incident.site === '' ? 'Site is required' : '',
        name: incident.name === '' ? 'Incident title is required' : '',
        type: incident.type === '' ? 'Incident type is required' : '',
        occurred: incident.occurred === '' ? 'Incident date is required' : today.diff(incident.occurred) < 0 ? 'Incident date must be in the past' : '',
        discovered: incident.discovered === '' ? 'Discovery date is required' : today.diff(incident.discovered) < 0 ? 'Discovery date must be in the past' : '',
      });
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      updateIncidentManagerAction({ ...incident, tasks });
      window.setTimeout(() => navigate('/maintain/incidents'), 1000);
    }
  };

  const sitesList = {};
  SitesReducer &&
    SitesReducer.sites &&
    SitesReducer.sites.forEach((site) => {
      sitesList[site.id] = site.name;
    });

  const affiliatesList = {};
  AffiliatesReducer &&
    AffiliatesReducer.affiliates &&
    AffiliatesReducer.affiliates.forEach((affiliate) => {
      affiliatesList[affiliate.id] = affiliate.name;
    });

  const typesList = {};
  IncidentTypesReducer &&
    IncidentTypesReducer.incidentTypes &&
    IncidentTypesReducer.incidentTypes.forEach((incident) => {
      typesList[incident.id] = incident.name;
    });

  const handleDelete = (data) => {
    deleteIncidentNoteAction({ entity_id: incidentId, id: data.id });
  };

  // Close dropdown when clicking outside them
  const associatedSiteRef = React.createRef();
  const affiliateReportedRef = React.createRef();
  const incidentTypeRef = React.createRef();
  const reportedByRef = React.createRef();
  const investigationStatusRef = React.createRef();
  const regTypeRef = React.createRef();

  const is_officer = (UserOrgSiteReducer.selected && UserOrgSiteReducer.selected.is_officer) || false;
  const is_super = (UserOrgSiteReducer.selected && UserOrgSiteReducer.selected.is_super) || false;
  const is_coach = (UserOrgSiteReducer.selected && UserOrgSiteReducer.selected.is_coach) || false;

  const onlyOwn = is_officer || is_super ? false : true;

  const renderNotes = () => {
    return (
      <div className={styles.notes_tab}>
        <p className={styles['add-note-heading']}>Add Note</p>
        <div className={styles.notes_add}>
          <FormElement labelText="INCIDENT NOTE" htmlFor="note">
            <textarea
              rows="2"
              // className={styles.notes}
              value={note || ''}
              onChange={(e) => setNote(e.target.value)}
            />
          </FormElement>
        </div>
        <Button disabled={is_coach} size="md" className={styles['add-note-button']} text="ADD NOTE" onClick={addIncidentNote} />
        <div className={styles.notes_list}>
          <FullDataTable
            className={styles['add-note-table']}
            rowsData={IncidentNotesReducer.notes}
            definitions={TableDefNotes}
            customTableProps={{
              onlyOwn,
              userId,
              handleDelete,
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <AppLayoutWrapFull onClick={toggleDropdowns([associatedSiteRef, affiliateReportedRef, reportedByRef, incidentTypeRef, investigationStatusRef])}>
      <PageHeader />
      {isLoading ? (
        <Spinner />
      ) : (
        <div>
          <form className={styles['incident-manager-form']}>
            <Container wide className={styles['incident-manager-container']}>
              <FormElement labelText="ASSOCIATED SITE" htmlFor="site" errorMessage={stateErrors.site || ''}>
                <Select
                  labels={sitesList}
                  disabled={incident.investigating === '3' || (tasks && tasks.length > 0) ? true : false}
                  value={incident.site}
                  placeholder="Select Site"
                  onClick={handleSelect('site')}
                  dropdownToggleRef={associatedSiteRef}
                >
                  {SitesReducer &&
                    SitesReducer.sites &&
                    SitesReducer.sites.map((site) => (
                      <li key={`siteid-${site.id}`} data-name={site.name} value={site.id}>
                        {site.name}
                      </li>
                    ))}
                </Select>
              </FormElement>

              <FormElement labelText="VENDOR REPORTED" htmlFor="affiliate" smallText="(if any)">
                <Select
                  labels={affiliatesList}
                  disabled={incident.investigating === '3' ? true : false}
                  placeholder="** No Vendor reported"
                  value={incident['affiliate']}
                  onClick={handleSelect('affiliate')}
                  dropdownToggleRef={affiliateReportedRef}
                >
                  {AffiliatesReducer &&
                    AffiliatesReducer.affiliates &&
                    AffiliatesReducer.affiliates.map((type) => (
                      <li key={`it_id-${type.id}`} data-name={type.name} value={type.id}>
                        {type.name}
                      </li>
                    ))}
                </Select>
              </FormElement>
              <FormElement labelText="INCIDENT TITLE" smallText="*" htmlFor="name" errorMessage={stateErrors['name'] || ''}>
                <input disabled={incident.investigating === '3' ? true : false} value={incident['name']} onChange={handleChange('name')} />
              </FormElement>
              <FormElement labelText="REPORTED BY" htmlFor="reported_by" errorMessage={stateErrors['reported_by'] || ''}>
                <Select
                  value={incident['reported_by']}
                  onClick={handleSelect('reported_by')}
                  labels={{
                    [`${props.userData.name}`]: `${props.userData.name}`,
                    Anonymous: 'Anonymous',
                  }}
                  dropdownToggleRef={reportedByRef}
                >
                  <li value={`${props.userData.name}`}>{props.userData.name}</li>
                  <li value={'Anonymous'}>Anonymous</li>
                </Select>
              </FormElement>

              <FormElement labelText="REGULATORY ACT" htmlFor="regulatory_act" errorMessage={stateErrors['regulatory_act'] || ''}>
                <Select placeholder="Select regulatory act" value={incident.regulatory_act} onClick={handleRegSelect('regulatory_act')} dropdownToggleRef={regTypeRef}>
                  {OrgModulesReducer &&
                    OrgModulesReducer.data &&
                    OrgModulesReducer.data.map((d) => (
                      <li key={`regid-${d.name.toLowerCase()}`} value={d.name}>
                        {d.name}
                      </li>
                    ))}
                  <li value="Other">Other</li>
                </Select>
              </FormElement>

              <FormElement labelText="INCIDENT TYPE" htmlFor="type" errorMessage={stateErrors['type'] || ''}>
                <Select
                  labels={typesList}
                  placeholder="Unauthorized individual having access to company protected information"
                  value={incident['type']}
                  onClick={handleSelect('type')}
                  disabled={incident.investigating === '3' ? true : false}
                  dropdownToggleRef={incidentTypeRef}
                >
                  {IncidentTypesReducer &&
                    IncidentTypesReducer.incidentTypes &&
                    IncidentTypesReducer.incidentTypes
                      .filter((type) => type.regulatory_act === incident.regulatory_act)
                      .map((type) => (
                        <li key={`it_id-${type.id}`} data-name={type.name} value={type.id}>
                          {type.name}
                        </li>
                      ))}
                </Select>

                {incident.type !== '' && typesList[incident.type] === 'Other' && (
                  <input type="text" placeholder="Describe the other type of incident this is" onChange={handleChange('reason')} value={incident['reason']} />
                )}
              </FormElement>

              <FormElement labelText="INVESTIGATION STATUS" htmlFor="investigating">
                {is_officer || is_super ? (
                  <Select
                    placeholder="Incident Under Investigation"
                    value={incident.investigating}
                    labels={{
                      0: 'Not Verified',
                      1: 'Under Investigation',
                      2: 'Verified',
                      3: 'Completed',
                    }}
                    disabled={IncidentManagerReducer.incident && IncidentManagerReducer.incident.investigating === '3' ? true : false}
                    onClick={handleSelect('investigating')}
                    dropdownToggleRef={investigationStatusRef}
                  >
                    <li value="0">Not Verified</li>
                    <li value="1">Under Investigation</li>
                    <li value="2">Verified</li>
                    <li value="3">Completed</li>
                  </Select>
                ) : (
                  <Select
                    placeholder="Incident Under Investigation"
                    value={incident.investigating}
                    labels={{
                      0: 'Not Verified',
                      1: 'Under Investigation',
                      2: 'Verified',
                    }}
                    disabled={IncidentManagerReducer.incident && IncidentManagerReducer.incident.investigating === '3' ? true : false}
                    onClick={handleSelect('investigating')}
                    dropdownToggleRef={investigationStatusRef}
                  >
                    <li value="0">Not Verified</li>
                    <li value="1">Under Investigation</li>
                    <li value="2">Verified</li>
                  </Select>
                )}
              </FormElement>
              <Row className={styles['date-inputs-row']}>
                <FormElement labelText="INCIDENT DATE" htmlFor="occurred" smallText="*" errorMessage={stateErrors['occurred'] || ''}>
                  <DateInput
                    className={styles['date-input']}
                    disabled={incident.investigating === '3' ? true : false}
                    value={incident['occurred']}
                    onChange={handleChange('occurred')}
                    maxDate={moment().format('YYYY-MM-DD')}
                  />
                </FormElement>
                <FormElement labelText="DISCOVERY DATE" htmlFor="discovery-date" smallText="*" errorMessage={stateErrors['discovered'] || ''}>
                  <DateInput
                    disabled={incident.investigating === '3' ? true : false}
                    className={styles['date-input']}
                    value={incident['discovered']}
                    onChange={handleChange('discovered')}
                    maxDate={moment().format('YYYY-MM-DD')}
                  />
                </FormElement>
              </Row>
              <Checkbox
                disabled={incident.investigating === '3' ? true : false}
                labelText="This incident affected more than 500 members"
                checked={incident['affected'] ? 'checked' : ''}
                onChange={handleCheckbox('affected')}
                className={styles['affected']}
              />
            </Container>
            <Tabs
              tabs={[
                {
                  heading: 'DESCRIPTION',
                  icon: 'fad fa-engine-warning',
                },
                {
                  heading: 'INVESTIGATION',
                  icon: 'fad fa-tasks',
                },
                {
                  heading: 'NOTES',
                  icon: 'fad fa-pen',
                },
                {
                  heading: 'EVIDENCE',
                  icon: 'fad fa-cctv',
                },
              ]}
              activeTab={activeTab}
              setActiveTab={setTabAsActive}
            >
              <Container wide>
                {activeTab === 'NOTES' ? renderNotes() : ''}
                {activeTab === 'DESCRIPTION' ? (
                  <>
                    <br />
                    <textarea
                      disabled={incident.investigating === '3' ? true : false}
                      className={styles['incident-description']}
                      rows="5"
                      maxLength="8192"
                      value={incident.description}
                      onChange={handleChange('description')}
                    />
                  </>
                ) : null}
                {activeTab === 'INVESTIGATION' ? (
                  <AddTask
                    regulatory_act={incident.regulatory_act}
                    setParentErrors={setStateErrors}
                    site={incident.site}
                    tasks={tasks}
                    setTasks={setTasks}
                    allowAdd={incident.investigating === '3' || is_coach ? false : true}
                  />
                ) : null}

                {activeTab === 'EVIDENCE' ? (
                  <EditEvidence
                    incidentId={incident.id}
                    isFinalized={IncidentManagerReducer && IncidentManagerReducer.incident && IncidentManagerReducer.incident.resolved !== ''}
                  />
                ) : null}

                <HR className={styles.hr} />
                <div className={styles['form-actions']}>
                  {/* <ClearAllFields onClick={clearAllFields} /> */}
                  {IncidentManagerReducer.incident && IncidentManagerReducer.incident.investigating && IncidentManagerReducer.incident.investigating === '3' ? (
                    <Button className={styles['resolved-incident-button']} text="RESOLVED AND CANNOT BE UPDATED" color="danger" />
                  ) : (
                    <React.Fragment>
                      <Button className={styles['cancel-button']} text="CANCEL" color="secondary" onClick={() => navigate(-1)} />
                      <Button disabled={is_coach} className={styles['update-incident-button']} text="UPDATE INCIDENT" onClick={updateIncident} />
                    </React.Fragment>
                  )}
                </div>
              </Container>
            </Tabs>
          </form>
        </div>
      )}
    </AppLayoutWrapFull>
  );
};

const mapStateToProps = (state) => ({
  IncidentTypesReducer: state.IncidentTypesReducer,
  IncidentManagerReducer: state.IncidentManagerReducer,
  AffiliatesReducer: state.AffiliatesReducer,
  SitesReducer: state.SitesReducer,
  IncidentNotesReducer: state.IncidentNotesReducer,
  UserOrgSiteReducer: state.UserOrgSiteReducer,
  AuthReducer: state.AuthReducer,
  userData: state.AuthReducer.userData,
  OrgModulesReducer: state.OrgModulesReducer,
});

const mapDispatchToProps = {
  loadIncidentManagerAction,
  loadSitesAction,
  loadIncidentNotesAction,
  loadAffiliatesAction,
  loadIncidentTypesAction,
  addIncidentNoteAction,
  updateIncidentManagerAction,
  deleteIncidentNoteAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(IncidentManager);
