import { push } from 'redux-first-history';
import { call, put, takeLatest, delay, select } from 'redux-saga/effects';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { actionApiCall } from 'helpers/api/ApiCalls';
import * as sagaConstants from 'helpers/constants/SagasConstants';
import { redirectToVendors } from 'helpers/utils';

const getSelected = (state) => state.UserOrgSiteReducer.selected;

function* submitAddAffiliateFormSaga({ payload }) {
  try {
    const selected = yield select(getSelected);
    const apiResponse = yield call(actionApiCall, {
      actionType: ActionTypeConstants.SUBMIT_ADD_AFFILIATE_FORM,
      data: {
        organization: selected.org,
        site: selected.site,
        address_1: payload.address_1,
        address_2: payload.address_2,
        ba_agreement_id: payload.ba_agreement_id,
        city: payload.city,
        conf_agreement_id: payload.conf_agreement_id,
        contact_email: payload.contact_email,
        contact_first: payload.contact_first,
        contact_last: payload.contact_last,
        country_code: payload.country_code,
        documents:
          payload.documents &&
          payload.documents.map((d) => ({
            ...d,
            approved: d.approved === 'true' || d.approved === true ? true : false,
          })),
        name: payload.name,
        notes: payload.notes,
        phi: payload.phi,
        phone: payload.phone,
        postal_code: payload.postal_code,
        // region: payload.region,
        region: payload.state,
        // state_name: payload.state_name,
      },
    });

    if (apiResponse.res.data.response === 200) {
      yield put({
        type: ActionTypeConstants.SUBMIT_ADD_AFFILIATE_FORM_SUCCESS,
        payload,
      });
      yield put({ type: ActionTypeConstants.LOAD_AFFILIATES });
      yield put(push(redirectToVendors(selected.ui_version)));
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: apiResponse.res.data.message || `Vendor ${sagaConstants.SUCCESSFULLY_CREATED}`,
          color: 'success',
        },
      });
      yield delay(10000);
      yield put({ type: ActionTypeConstants.HIDE_ALERT });
    } else {
      yield put({
        type: ActionTypeConstants.SUBMIT_ADD_AFFILIATE_FORM_FAILURE,
        payload,
      });
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: apiResponse.res.data.message || `${sagaConstants.ISSUE_OCCURED} creating an vendor`,
          color: 'danger',
        },
      });
      yield delay(10000);
      yield put({ type: ActionTypeConstants.HIDE_ALERT });
    }
  } catch (err) {
    yield put({
      type: ActionTypeConstants.SUBMIT_ADD_AFFILIATE_FORM_FAILURE,
      payload,
    });
    yield put({
      type: ActionTypeConstants.SHOW_ALERT,
      payload: {
        message: `${sagaConstants.SERVER_ERROR}`,
        color: 'danger',
      },
    });
    yield delay(10000);
    yield put({ type: ActionTypeConstants.HIDE_ALERT });
  }
}

export default function* () {
  yield takeLatest(ActionTypeConstants.SUBMIT_ADD_AFFILIATE_FORM, submitAddAffiliateFormSaga);
}
