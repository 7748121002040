import React, { RefObject } from 'react';

import ColVisibilitySelector from 'components/DataTable/ColVisibilitySelector';
import ItemActionsMenu from 'components/DataTable/ItemActionsMenu';
import Checkbox from 'components/Checkbox/Checkbox';
import { Button } from 'components/Button/Button';

import { getStatusButtonColor, isEmailValid } from 'helpers/utils';
import { Affiliate } from 'types/Affiliate';

interface IAuditTable {
  TABLE;
  COLUMN: Array<{
    label: string;
    key: string;
    sortable?: boolean;
    header?;
    className?: string;
    format: ({ rowData, customTableProps }: { rowData: Affiliate; customTableProps }) => void;
    canBeHidden?: boolean;
  }>;
}

const tableDefenition: IAuditTable = {
  TABLE: {
    className: 'data-table-affiliates',
    initialSortKey: 'name',
    initialSortOrder: 'asc',
  },

  COLUMN: [
    {
      label: 'Row Selector',
      key: 'row-selector',
      header: ({ customTableProps }) => {
        const isSelectedAll = !!(
          customTableProps.selectedRows &&
          customTableProps.selectedRows.length > 0 &&
          customTableProps.rowsData &&
          customTableProps.rowsData.length > 0 &&
          customTableProps.selectedRows.length === customTableProps.rowsData.length
        );
        return <Checkbox checked={isSelectedAll} onChange={() => customTableProps.doSelectAll()} name="row-selector" />;
      },
      className: 'col-row-selector',
      format: ({ rowData, customTableProps }) => {
        const isSelected = !!(customTableProps.selectedRows && customTableProps.selectedRows.some((checkbox) => checkbox.id === rowData.id));
        return <Checkbox checked={isSelected} onChange={() => customTableProps.onRowSelect({ rowData })} name="row-selector" />;
      },
      canBeHidden: false,
    },
    {
      label: 'Name',
      header: 'NAME',
      key: 'name',
      sortable: true,
      className: 'name',
      format: ({ rowData, customTableProps }) => <span>{rowData.name}</span>,
    },
    {
      label: 'Email',
      header: 'EMAIL',
      key: 'contact_email',
      sortable: true,
      format: ({ rowData }) => (isEmailValid(rowData.contact_email) ? <span>{rowData.contact_email}</span> : <span style={{ color: 'red' }}>Invalid e-mail address!</span>),
    },
    {
      label: 'Agreement Type',
      header: 'AGREEMENT TYPE',
      key: 'agreement-type',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData, customTableProps }) => <span>{rowData['agreement-type']}</span>,
    },
    {
      label: 'Audit Status',
      header: 'AUDIT STATUS',
      key: 'audit_status',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => <span>{rowData.audit_status}</span>,
    },
    {
      label: 'Vendor Status',
      header: 'VENDOR STATUS',
      key: 'affiliate_status',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => <span>{rowData.affiliate_status}</span>,
    },
    {
      label: 'Approval Status',
      header: 'APPROVAL STATUS',
      key: 'status',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData, customTableProps }) => {
        const { status } = rowData;
        const color = getStatusButtonColor(status, ['Approved'], ['Declined']);
        return (
          <span>
            <Button cursorDefault={customTableProps.cursorDefault} color={color} size="md" text={status} />
          </span>
        );
      },
    },
    {
      label: 'Actions',
      key: 'actions',
      format: ({ rowData, customTableProps }) => {
        const actionMenuDropDown = React.createRef() as RefObject<ItemActionsMenu>;
        return (
          <ItemActionsMenu
            ref={actionMenuDropDown}
            actions={(() => [
              customTableProps.edit && {
                label: 'Edit',
                action: () => {
                  if (actionMenuDropDown && actionMenuDropDown.current) {
                    actionMenuDropDown.current.handleMenuClose();
                  }
                  customTableProps.edit(rowData);
                },
              },
              customTableProps.approve && {
                label: 'Approve',
                action: () => {
                  if (actionMenuDropDown && actionMenuDropDown.current) {
                    actionMenuDropDown.current.handleMenuClose();
                  }
                  customTableProps.approve(rowData);
                },
              },
              customTableProps.decline && {
                label: 'Decline',
                action: () => {
                  if (actionMenuDropDown && actionMenuDropDown.current) {
                    actionMenuDropDown.current.handleMenuClose();
                  }
                  customTableProps.decline(rowData);
                },
              },
              customTableProps.email && {
                label: 'Email Files',
                action: () => {
                  if (actionMenuDropDown && actionMenuDropDown.current) {
                    actionMenuDropDown.current.handleMenuClose();
                  }
                  customTableProps.email(rowData);
                },
              },
              rowData.completed_audits?.length > 0 && customTableProps.read_vendor_audit
                ? {
                    label: 'View Vendor Audit',
                    action: () => {
                      if (actionMenuDropDown && actionMenuDropDown.current) {
                        actionMenuDropDown.current.handleMenuClose();
                      }
                      customTableProps.read_vendor_audit(rowData);
                    },
                  }
                : false,
              rowData.audit_status === 'Audit complete' && customTableProps.read_vendor_audit
                ? {
                    label: 'View Vendor Audit',
                    action: () => {
                      if (actionMenuDropDown && actionMenuDropDown.current) {
                        actionMenuDropDown.current.handleMenuClose();
                      }
                      customTableProps.read_vendor_audit(rowData);
                    },
                  }
                : false,
              customTableProps.send_vendor_audit && {
                label: 'Send Vendor Audit',
                action: () => {
                  if (actionMenuDropDown && actionMenuDropDown.current) {
                    actionMenuDropDown.current.handleMenuClose();
                  }
                  customTableProps.send_vendor_audit(rowData);
                },
              },
              customTableProps.send_ba_agreement && {
                label: 'Send BA Agreement',
                action: () => {
                  if (actionMenuDropDown && actionMenuDropDown.current) {
                    actionMenuDropDown.current.handleMenuClose();
                  }
                  customTableProps.send_ba_agreement(rowData);
                },
              },
              customTableProps.send_confidentiality_agreement && {
                label: 'Send Confidentiality Agreement',
                action: () => {
                  if (actionMenuDropDown && actionMenuDropDown.current) {
                    actionMenuDropDown.current.handleMenuClose();
                  }
                  customTableProps.send_confidentiality_agreement(rowData);
                },
              },
              customTableProps.delete && {
                label: 'Delete',
                action: () => {
                  if (actionMenuDropDown && actionMenuDropDown.current) {
                    actionMenuDropDown.current.handleMenuClose();
                  }
                  customTableProps.delete(rowData);
                },
              },
            ])()}
          />
        );
      },
      className: 'col-actions',
      header: ({ customTableProps }) => (
        <ColVisibilitySelector
          colDefs={customTableProps.colDefs}
          visibleColumnKeys={customTableProps.visibleColumnKeys}
          onChangeVisibility={(colKey) => customTableProps.onChangeVisibility(colKey)}
        />
      ),
    },
  ],
};

export default tableDefenition;
