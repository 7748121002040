import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import Login from './Login/Login';
import ForgotPassword from './ForgotPassword/ForgotPassword';
import ForgotPasswordSuccess from './ForgotPassword/ForgotPasswordSuccess';
import ResetPassword from './ResetPassword/ResetPassword';
import UserRegister from './UserRegister/UserRegister';
import SelectSite from './SelectSite/SelectSite';

function Welcome() {
  return (
    <Routes>
      <Route path="/:hash" element={<Login />} />
      <Route path="/" element={<Login />} />

      <Route path="/site/:hash" element={<SelectSite />} />
      <Route path="/site" element={<SelectSite />} />

      <Route path="/register/:url" element={<UserRegister />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/forgot-password-success" element={<ForgotPasswordSuccess />} />
      <Route path="/reset-password/:hash" element={<ResetPassword />} />
      <Route path="*" element={<Navigate to="/login" replace />} />
    </Routes>
  );
}

export default Welcome;
