import React, { useState, useEffect } from 'react';

import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import { loadStatsAction } from 'store/actions/Actions';
import { useAppDispatch, useAppSelector } from 'hooks';

import Stat from './components/Stat/Stat';

import styles from './PageHeader.module.scss';

function PageHeader(props) {
  const [showStats, setShowStats] = useState(true);
  const { loadStats = true, title, children, heading, breadcrumbs, className, disableHints, hints } = props;

  const dispatch = useAppDispatch();
  const StatsReducer = useAppSelector((state) => state.StatsReducer);

  const toggleStats = () => {
    setShowStats(!showStats);
  };

  useEffect(() => {
    if (!StatsReducer.stats && loadStats) {
      dispatch(loadStatsAction());
    }
  }, [StatsReducer.stats, loadStatsAction, loadStats]);

  const renderHints = () => (
    <div className={styles.hints}>
      <i className="far fa-question-circle" />
      <span onClick={disableHints} aria-hidden="true">
        Hide Helpful Hints
      </span>
    </div>
  );

  return (
    <div className={`${styles['page-header']} ${className || ''}`}>
      <div className={styles['page-header-container']}>
        {!title ? <Breadcrumbs className={styles.breadcrumbs} breadcrumbs={breadcrumbs} /> : <h1 style={{ fontWeight: 500 }}>{title}</h1>}

        {props.showStats && (
          <div className={styles['show-stats']} onClick={toggleStats} aria-hidden="true">
            <i className={`fas fa-sort-down ${styles['show-stats-icon']} ${showStats ? styles.active : ''}`} />
            <i className={`fad fa-chart-bar ${styles['chart-icon']} ${showStats ? styles.active : ''}`} />
          </div>
        )}
      </div>

      {props.showStats && (
        <div className={`${styles.stats} ${showStats ? '' : styles['hide-stats']}`}>
          <Stat key="stats-key-1" statData={StatsReducer?.stats?.audits?.total} statName="Audits" link="/achieve/audits" iconClass="fad fa-cabinet-filing" />
          <Stat key="stats-key-2" statData={StatsReducer?.stats?.remediation?.total} statName="Remediation" link="/achieve/remediation" iconClass="fad fa-file-search" />
          <Stat key="stats-key-3" statData={StatsReducer?.stats?.tasks?.total} statName="Organization Tasks" link="/achieve/tasks?filter=all" iconClass="fad fa-tasks" />
          <Stat
            key="stats-key-4"
            statData={StatsReducer?.stats?.attestation?.total}
            statName="Attestation"
            link="/achieve/tasks?filter=attestation"
            iconClass="fad fa-users-class"
          />
          <Stat key="stats-key-5" statData={StatsReducer?.stats?.incidents?.total} statName="Incidents" link="/maintain/incidents" iconClass="fad fa-exclamation-triangle" />
        </div>
      )}

      {(children || heading) && (
        <div className={styles['page-header-children']}>
          {heading ? (
            <span className={styles.heading}>
              {heading}
              {hints && renderHints()}
            </span>
          ) : (
            children || ''
          )}
        </div>
      )}
    </div>
  );
}

export default PageHeader;
