import { call, put, takeLatest, select, delay } from 'redux-saga/effects';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { actionApiCall } from 'helpers/api/ApiCalls';
import * as sagaConstants from 'helpers/constants/SagasConstants';

const getUsers = (state) => state.UsersReducer.users;
const getSites = (state) => state.SitesReducer.sites;
const getSelected = (state) => state.UserOrgSiteReducer.selected;

function transformRemediations(plans, sites) {
  const result = plans.map((element, index) => {
    element.index = index;
    if (sites) {
      sites.forEach((item) => {
        if (item.id === element.site) {
          element.siteName = item.name;
        }
      });
    }
    return element;
  });
  return result;
}

function* loadEditRemediationPlanFormSaga({ payload }) {
  try {
    const selected = yield select(getSelected);
    const apiResponse = yield call(actionApiCall, {
      actionType: ActionTypeConstants.LOAD_EDIT_REMEDIATION_PLAN_FORM,
      data: {
        organization: selected.org,
        id: payload.id,
      },
    });
    if (apiResponse.res.data.response === 200) {
      const { data } = apiResponse.res.data;
      const users = yield select(getUsers);
      const sites = yield select(getSites);
      if (data[0].tasks) {
        data[0].tasks.map((task) => {
          task.status = task.completed === true ? 'Complete' : 'Incomplete';
          if (task.subtask === 'remediation') {
            task.type = 'Remediation';
          } else if (task.subtask === 'attestation') {
            task.type = 'Attestation';
          } else if (task.subtask === 'incident') {
            task.type = 'Incident';
          } else if (task.subtask === 'auditResponse') {
            task.type = 'Question';
          } else {
            task.type = 'Miscellaneous';
          }
          if (users) {
            users.forEach((user) => {
              if (user.id === task.user) {
                task.assignee_name = user.name;
              }
            });
          }
          return task;
        });
      }
      yield put({
        type: ActionTypeConstants.LOAD_EDIT_REMEDIATION_PLAN_FORM_SUCCESS,
        payload: {
          remediation_plans: transformRemediations(data, sites),
        },
      });
    } else {
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: apiResponse.res.data.message || `${sagaConstants.ISSUE_OCCURED} loading remediation plans`,
          color: 'danger',
        },
      });
      yield delay(10000);
      yield put({ type: ActionTypeConstants.HIDE_ALERT });
    }
  } catch (err) {
    yield put({
      type: ActionTypeConstants.SHOW_ALERT,
      payload: {
        message: `${sagaConstants.SERVER_ERROR}`,
        color: 'danger',
      },
    });
    yield delay(10000);
    yield put({ type: ActionTypeConstants.HIDE_ALERT });
  }
}

export default function* () {
  yield takeLatest(ActionTypeConstants.LOAD_EDIT_REMEDIATION_PLAN_FORM, loadEditRemediationPlanFormSaga);
}
