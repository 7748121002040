import ActionStatusConstants from 'helpers/ActionStatusConstants';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { LOCATION_CHANGE } from 'redux-first-history';

const initialState = {
  status: ActionStatusConstants.INITIAL,
  message: null,
  formErrors: null,
};

const EvidenceReducer = (state = initialState, action) => {
  const actionType = action && action.type ? action.type : null;
  const payload = action && action.payload ? action.payload : null;

  switch (actionType) {
    case ActionTypeConstants.LOAD_EVIDENCE_BY_INCIDENT:
      return {
        ...state,
        status: ActionStatusConstants.ISBUSY,
      };
    case ActionTypeConstants.LOAD_EVIDENCE_BY_INCIDENT_SUCCESS:
      return {
        ...state,
        evidence: payload.evidence || [],
        status: ActionStatusConstants.SUCCESS,
        message: payload && payload.message ? payload.message : null,
        formErrors: payload && payload.formErrors ? payload.formErrors : null,
      };
    case ActionTypeConstants.LOAD_EVIDENCE_BY_INCIDENT_FAILURE:
      return {
        ...state,
        status: ActionStatusConstants.FAILURE,
        message: payload && payload.message ? payload.message : null,
        formErrors: payload && payload.formErrors ? payload.formErrors : null,
      };
    case ActionTypeConstants.LOAD_EVIDENCE_BY_TASK:
      return {
        ...state,
        status: ActionStatusConstants.ISBUSY,
      };
    case ActionTypeConstants.LOAD_EVIDENCE_BY_TASK_SUCCESS:
      return {
        ...state,
        tasks: payload.tasks || [],
        status: ActionStatusConstants.SUCCESS,
        message: payload && payload.message ? payload.message : null,
        formErrors: payload && payload.formErrors ? payload.formErrors : null,
      };
    case ActionTypeConstants.LOAD_EVIDENCE_BY_TASK_FAILURE:
      return {
        ...state,
        status: ActionStatusConstants.FAILURE,
        message: payload && payload.message ? payload.message : null,
        formErrors: payload && payload.formErrors ? payload.formErrors : null,
      };
    case ActionTypeConstants.LOAD_EVIDENCE_BY_CLOUD_ASSET:
      return {
        ...state,
        status: ActionStatusConstants.ISBUSY,
      };
    case ActionTypeConstants.LOAD_EVIDENCE_BY_CLOUD_ASSET_SUCCESS:
      return {
        ...state,
        evidence: payload.evidence,
        status: ActionStatusConstants.SUCCESS,
        message: payload && payload.message ? payload.message : null,
        formErrors: payload && payload.formErrors ? payload.formErrors : null,
      };
    case ActionTypeConstants.LOAD_EVIDENCE_BY_CLOUD_ASSET_FAILURE:
      return {
        ...state,
        status: ActionStatusConstants.FAILURE,
        message: payload && payload.message ? payload.message : null,
        formErrors: payload && payload.formErrors ? payload.formErrors : null,
      };
    case ActionTypeConstants.LOAD_EVIDENCE_BY_INVENTORY:
      return {
        ...state,
        status: ActionStatusConstants.ISBUSY,
      };
    case ActionTypeConstants.LOAD_EVIDENCE_BY_INVENTORY_SUCCESS:
      return {
        ...state,
        evidence: payload.evidence,
        status: ActionStatusConstants.SUCCESS,
        message: payload && payload.message ? payload.message : null,
        formErrors: payload && payload.formErrors ? payload.formErrors : null,
      };
    case ActionTypeConstants.LOAD_EVIDENCE_BY_INVENTORY_FAILURE:
      return {
        ...state,
        status: ActionStatusConstants.FAILURE,
        message: payload && payload.message ? payload.message : null,
        formErrors: payload && payload.formErrors ? payload.formErrors : null,
      };
    case ActionTypeConstants.LOAD_EVIDENCE_BY_AUDIT:
      return {
        ...state,
        status: ActionStatusConstants.ISBUSY,
      };
    case ActionTypeConstants.LOAD_EVIDENCE_BY_AUDIT_SUCCESS:
      return {
        ...state,
        evidence: payload.evidence,
        status: ActionStatusConstants.SUCCESS,
        message: payload && payload.message ? payload.message : null,
        formErrors: payload && payload.formErrors ? payload.formErrors : null,
      };
    case ActionTypeConstants.LOAD_EVIDENCE_BY_AUDIT_FAILURE:
      return {
        ...state,
        status: ActionStatusConstants.FAILURE,
        message: payload && payload.message ? payload.message : null,
        formErrors: payload && payload.formErrors ? payload.formErrors : null,
      };
    case LOCATION_CHANGE:
      return {
        ...initialState,
      };
    default:
  }
  return state;
};

export default EvidenceReducer;
