import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import AppLayoutWrapFull from 'components/AppLayoutWrapFull';
import Container from 'components/Container/Container';
import PageHeader from 'components/PageHeader/PageHeader';
import PageHeaderActions from 'components/PageHeader/components/PageHeaderActions/PageHeaderActions';
import FullDataTable from 'components/DataTable/FullDataTable';
import Dimmer from 'components/Dimmer/Dimmer';
import TableDefCertificates from 'table-defs/TableDefCertificates';

import { setPaginationAction, loadCertificatesAction } from 'store/actions/Actions';
import { generatePDFDocument, generatePDFDocuments, previewPDFDocument, myCert } from 'components/PdfCertificate/PdfCertificate';
import useFilterRows from 'helpers/customHooks/useFilterRows';

import { toggleDropdowns, setSelectedRows } from 'helpers/utils';
import { CERTIFICATES_PAGE } from 'helpers/constants/DataTableConstants';
import { useAppDispatch, useAppSelector, useConfirmModal } from 'hooks';
import Spinner from 'components/Spinner/Spinner';
import ActionStatusConstants from 'helpers/ActionStatusConstants';
import CertificatePreviewModal from './components/CertificatePreviewModal/CertificatePreviewModal';

function Certificates(props) {
  const { loadCertificatesAction, setPaginationAction } = props;
  const DatatableReducer = useAppSelector((state) => state.DatatableReducer);
  const CertificatesReducer = useAppSelector((state) => state.CertificatesReducer);
  const dispatch = useAppDispatch();
  const userDropdownRef = React.createRef();
  const chooseActionDropdownRef = React.createRef();
  const [users, setUsers] = useState([]);
  const [state, setState] = useState({ selectedRows: undefined });
  const [isZipFileBeingCreated, setIsZipFileBeingCreated] = useState(false);
  const [showConfirmModal, setShowConfirmModal, renderConfirmModal] = useConfirmModal();
  const [isLoading, setLoading] = useState(true);
  const [showCertificatePreviewModal, setShowCertificatePreviewModal] = useState(false);
  const [rowData, setRowData] = useState(undefined);

  const noFilters = {
    text: '',
    user_name: '',
    start: '',
    end: '',
    dateField: 'created_at',
  };

  const [selectedCertIds, setselectedCertIds] = useState(DatatableReducer?.datatable?.certificates?.selectedIds || []);
  const [filters, setFilters] = useState(DatatableReducer?.datatable?.certificates?.filters || noFilters);
  const [sortKey, setSortKey] = useState(DatatableReducer.datatable[CERTIFICATES_PAGE]?.sortKey);
  const [sortOrder, setSortOrder] = useState(DatatableReducer.datatable[CERTIFICATES_PAGE]?.sortOrder);

  useEffect(() => {
    if (!CertificatesReducer.certificates) {
      loadCertificatesAction();
    }
  }, [CertificatesReducer.certificates]);

  useEffect(() => {
    const usersList = CertificatesReducer?.certificates?.map((cert) => cert.user_name);
    if (!usersList) return;
    if (Array.isArray(usersList)) usersList.unshift('All Users');
    const usersListNoDuplicates = [...new Set(usersList)];
    if (usersListNoDuplicates) {
      setUsers(usersListNoDuplicates);
    }
  }, [CertificatesReducer.certificates]);

  useEffect(() => {
    if (CertificatesReducer.status === ActionStatusConstants.SUCCESS && DatatableReducer.status === ActionStatusConstants.SUCCESS) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [CertificatesReducer.certificates, DatatableReducer.datatable]);

  useEffect(() => {
    dispatch({
      type: 'SET_DATATABLE',
      payload: {
        datatable: {
          certificates: {
            filters,
            selectedIds: selectedCertIds,
            selectedRows: state.selectedRows,
            rowsPerPage: DatatableReducer?.datatable?.certificates?.rowsPerPage || 10,
            currentPage: DatatableReducer?.datatable?.certificates?.currentPage || 0,
            sortKey,
            sortOrder,
          },
        },
      },
    });
  }, [filters, state, selectedCertIds, sortKey, sortOrder]);

  const setFilter = (filterName) => (e) => {
    setFilters({
      ...filters,
      [filterName]: e.target.type === 'checkbox' ? e.target.checked : e.target.value || e?.target?.attributes?.value?.value || '',
    });
    setPaginationAction({ dataType: CERTIFICATES_PAGE, currentPage: 0 });
  };

  const setDateFilter = (filterName) => (value) => {
    setFilters((oldState) => ({ ...oldState, [filterName]: value }));
    setPaginationAction({ dataType: CERTIFICATES_PAGE, currentPage: 0 });
  };

  const onChangeRowSelect = ({ selectedRows }) => {
    if (selectedRows !== state.selectedRows) {
      setState((s) => ({ ...s, selectedRows }));
    }
  };

  const filteredRows = useFilterRows(filters, CertificatesReducer.certificates);
  const selectedIds = selectedCertIds.map((item) => item.id);

  return (
    <AppLayoutWrapFull onClick={toggleDropdowns([userDropdownRef, chooseActionDropdownRef])}>
      <Dimmer active={isZipFileBeingCreated} />
      <PageHeader showStats>
        <PageHeaderActions
          searchHandler={(e) => {
            setFilters({ ...filters, text: e.target.value || '' });
            setPaginationAction({
              dataType: CERTIFICATES_PAGE,
              currentPage: 0,
            });
          }}
          searchValue={filters.text}
          user={{
            onChange: setFilter('user_name'),
            options: users,
          }}
          userDropdownRef={userDropdownRef}
          dateRange={{
            onChangeStart: setDateFilter('start'),
            onChangeEnd: setDateFilter('end'),
          }}
          filters={filters}
          resetFilters={() => {
            setFilters(noFilters);
            setPaginationAction({
              dataType: CERTIFICATES_PAGE,
              currentPage: 0,
            });
          }}
          chooseActionDropdownRef={chooseActionDropdownRef}
          chooseAction={[
            {
              actionName: 'Download Selected',
              actionHandler: async () => {
                const idCount = selectedIds.length;
                if (idCount > 0) {
                  setIsZipFileBeingCreated(true);
                  const selectedCertificates = CertificatesReducer.certificates.filter((cert) => selectedIds.includes(cert.id));
                  const templatesAndData = selectedCertificates.map((cert) => ({
                    data: cert,
                    template: myCert(cert),
                  }));
                  await generatePDFDocuments(templatesAndData);
                  setIsZipFileBeingCreated(false);
                } else {
                  setShowConfirmModal({
                    type: 'simple',
                    title: 'Nothing selected',
                    question: 'Please select certificates to download.',
                  });
                }
              },
            },
          ]}
        />
      </PageHeader>
      {isLoading ? (
        <Spinner />
      ) : (
        <Container wide fluid>
          {CertificatesReducer && CertificatesReducer.certificates && (
            <FullDataTable
              rowsData={filteredRows}
              definitions={TableDefCertificates}
              dataType={CERTIFICATES_PAGE}
              setSortKey={setSortKey}
              setSortOrder={setSortOrder}
              onRowClick={function onRowClick(e) {
                if (e.currentTarget.classList.contains('col-actions') || e.currentTarget.classList.contains('col-row-selector')) return;
                setRowData(this.props.rowData);
                setShowCertificatePreviewModal(true);
              }}
              onChangeRowSelect={({ selectedRows }) => {
                onChangeRowSelect({ selectedRows });
              }}
              customTableProps={{
                preview: (rowData) => {
                  setRowData(rowData);
                  setShowCertificatePreviewModal(true);
                },
                download: (rowData) => {
                  generatePDFDocument(myCert(rowData));
                },
                selectedRows: state.selectedRows || selectedCertIds,
              }}
              setSelectedRows={setSelectedRows(['id'], setselectedCertIds)}
            />
          )}
          {showConfirmModal && renderConfirmModal(showConfirmModal, setShowConfirmModal)}
        </Container>
      )}

      {showCertificatePreviewModal && <CertificatePreviewModal modalContent={previewPDFDocument(myCert(rowData))} setShowModal={setShowCertificatePreviewModal} />}
    </AppLayoutWrapFull>
  );
}

const mapStateToProps = (state) => ({
  CertificatesReducer: state.CertificatesReducer,
});

const mapDispatchToProps = {
  loadCertificatesAction,
  setPaginationAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Certificates);
