import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import styles from './AddTask.module.scss';

import HR from 'components/HR/HR';
import FormElement from 'components/FormElement/FormElement';
import DateInput from 'components/DateInput/DateInput';
import Select from 'components/Select/Select';
import Button from 'components/Button/Button';
import Modal from 'components/Modal/Modal';
import TableDefTasksRemediation from 'table-defs/TableDefTasksRemediation';
import FullDataTable from 'components/DataTable/FullDataTable';
import { toggleDropdowns } from 'helpers/utils';

import { loadSitesAction, loadUsersAction, createTaskAction } from 'store/actions/Actions';

/**
 *
 * @param {Array} tasks - an array of tasks (objects)
 * @param {Function} setTasks - a function to set Tasks
 * @param {Object} dropdownToggleRef - a way to close an open select when clicking on parent
 * @param {boolean} allowEdit - true allows editing of tasks
 * @param {boolean} allowAdd - whether to allow the adding of new tasks
 */
const AddTask = (props) => {
  const navigate = useNavigate();

  const { UsersReducer, loadUsersAction, errorMessage, tasks, site, setParentErrors } = props;

  const [state, setState] = useState({
    number: tasks.length,
    task: '',
    due: '',
    assignee: '',
    assignee_name: '',
  });

  useEffect(() => {
    if (site !== '') {
      loadUsersAction({ site });
      setState((s) => ({ ...s, assignee: '', assignee_name: '' }));
    }
  }, [site, loadUsersAction]);

  const [stateErrors, setStateErrors] = useState({
    task: '',
    due: '',
    assignee: '',
  });

  // const [editing, setEditing] = useState(false);

  const noTaskToDelete = {
    showModal: false,
    id: '',
  };

  const [taskToDelete, setTaskToDelete] = useState(noTaskToDelete);

  const anyErrors = Object.keys(stateErrors).some((key) => stateErrors[key]);

  const handleAssigneeChange = (e) => {
    e.persist();
    setState((s) => ({
      ...s,
      assignee: e.target.value,
      assignee_name: e.target.dataset.name,
    }));
    setStateErrors((s) => ({ ...s, assignee: '' }));
  };

  const handleChange = (inputName) => (e) => {
    e.persist();
    setState((s) => ({
      ...s,
      [inputName]: e.target.value,
    }));
    setStateErrors((s) => ({ ...s, [inputName]: '' }));
    setParentErrors((s) => ({ ...s, tasks: '' }));
  };

  const addTask = () => {
    if (!state.task || !state.due || !state.assignee) {
      setStateErrors({
        ...stateErrors,
        task: !state.task ? 'Task description is required' : '',
        due: !state.due ? 'Target date is required' : '',
        assignee: !state.assignee ? 'Assignee is required' : '',
      });
      return;
    }
    // add simple object task to local state tasks array
    props.setTasks((s) => [
      ...s,
      {
        task: state.task,
        due: state.due,
        assignee: state.assignee,
        assignee_name: state.assignee_name,
        status: 'Incomplete',
        type: 'Remediation',
        regulatory_act: props.regulatory_act,
        assigned: moment().format('YYYY-MM-DD'),
      },
    ]);
    setState((s) => ({
      ...s,
      task: '',
      due: '',
      assignee: '',
      assignee_name: '',
    }));
  };

  // const saveTask = e => {
  //     const newTasks = props.tasks
  //     newTasks[state.number] = state
  //     props.setTasks(newTasks)
  //     setState({
  //         number: props.tasks.length + 1,
  //         description: "",
  //         due: "",
  //         assignee: ""
  //     })
  //     setEditing(false)
  // }

  const changeTaskStatus = (index) => {
    const tasks = [...props.tasks];
    tasks[index].status = !tasks[index].status;
    props.setTasks(tasks);
  };

  const removeTask = () => {
    props.setTasks(
      props.tasks
        .filter((el) => {
          return el.number !== taskToDelete.id;
        })
        .map((el, index) => ({ ...el, number: index }))
    );
    setState({
      ...state,
      number: state.number - 1,
    });
    setTaskToDelete({
      showModal: false,
      id: '',
    });
  };
  const dropdownToggleRef = React.createRef();

  let usersList = {};
  if (UsersReducer && UsersReducer.users) {
    UsersReducer.users.forEach((user) => {
      usersList[user.id] = user.name || user.email;
    });
  }

  return (
    <div className={`${styles['add-task']} ${props.className || ''}`} onClick={toggleDropdowns([dropdownToggleRef])}>
      <p className={styles['add-task-heading']}>{props.allowAdd ? 'Add New Task' : 'Tasks'}</p>
      <HR />
      {errorMessage && (
        <>
          <p className={styles['error-msg']}>{errorMessage}</p>
          <br />
        </>
      )}
      <div className={styles['add-task-data']}>
        {props.allowAdd && (
          <>
            <FormElement labelText="TASKS" smallText="*" htmlFor="description" errorMessage={stateErrors.task}>
              <input type="text" value={state['task']} placeholder="Enter Task name / description" onChange={handleChange('task')} />
            </FormElement>

            <FormElement labelText="TARGET DATE" smallText="*" htmlFor="due" errorMessage={stateErrors['due']}>
              <DateInput onChange={handleChange('due')} value={state['due']} />
            </FormElement>

            <FormElement labelText="ASSIGNEE" htmlFor="assignee" smallText="*" errorMessage={stateErrors.assignee}>
              <Select labels={usersList} value={state.assignee_name} placeholder="Select Assignee" onClick={handleAssigneeChange} dropdownToggleRef={dropdownToggleRef}>
                {/* <li key='user-null' data-name='' value=''>Default text</li> */}
                {UsersReducer &&
                  UsersReducer.users &&
                  UsersReducer.users
                    .filter((item) => item.registered === 't')
                    .filter((item) => item.active === 't')
                    .map((user) => (
                      <li key={user.email} data-name={user.name || user.email} value={user.id}>
                        {user.name || user.email}
                      </li>
                    ))}
              </Select>
            </FormElement>
            <Button className={`${styles['add-task-button']} ${anyErrors ? styles['add-task-button-center'] : ''}`} text="ADD TASK" icon="far fa-plus" onClick={addTask} />
          </>
        )}

        {/* {editing ?
                    <Button
                        className={`${styles["add-task-button"]} ${anyErrors ? styles["add-task-button-center"] : ""}`}
                        text="UPDATE TASK"
                        icon="far fa-plus"
                        onClick={saveTask}
                    />
                :
                    <Button
                        className={`${styles["add-task-button"]} ${anyErrors ? styles["add-task-button-center"] : ""}`}
                        text="ADD TASK"
                        icon="far fa-plus"
                        onClick={addTask}
                    />
                } */}

        <FullDataTable
          className={styles['add-task-table']}
          rowsData={props.tasks}
          definitions={TableDefTasksRemediation}
          customTableProps={{
            removeTask,
            // editTask,
            changeTaskStatus,
            setTaskToDelete,
          }}
          hiddenColumns={['row-selector', 'requested', 'completed', 'site', 'actions', props.allowEdit ? 'delete-action' : 'edit-delete-action']}
          onRowClick={function (e) {
            if (e.currentTarget.classList.contains('col-actions') || e.currentTarget.classList.contains('col-row-selector')) return;
            if (this.props.rowData.id) {
              navigate(`/achieve/tasks/edit/${this.props.rowData.id}/`);
            }
          }}
        />
      </div>
      {taskToDelete.showModal ? (
        <Modal className={styles['confirmation-modal']} setShowModal={() => setTaskToDelete(noTaskToDelete)}>
          <p className={styles['confirmation-modal-heading']}>Are you sure you want to delete this task?</p>
          <Button text="CANCEL" color="secondary" onClick={() => setTaskToDelete(noTaskToDelete)} />
          <Button text="DELETE" color="danger" onClick={removeTask} />
        </Modal>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  UsersReducer: state.UsersReducer,
});

const mapDispatchToProps = {
  loadSitesAction,
  loadUsersAction,
  createTaskAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTask);
