// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import styles from './AddTrainingModal.module.scss';
import Modal from 'components/Modal/Modal';
import FormElement from 'components/FormElement/FormElement';
import Select from 'components/Select/Select';
import SelectMulti from 'components/SelectMulti/SelectMulti';
import Button from 'components/Button/Button';
import DateInput from 'components/DateInput/DateInput';
import Checkbox from 'components/Checkbox/Checkbox';
import SelectWithSearch from 'components/SelectWithSearch/SelectWithSearch';
import AddRemoveItems from "components/AddRemoveItems/AddRemoveItems";
import RadioGroup from 'components/RadioGroup/RadioGroup';

import {
  loadActsAction,
  loadSitesAction,
  loadDeptAction,
  loadUsersByOrgAction,
  createTrainingAction,
  loadTrainingCoursesAction,
  loadFilesAction,
  loadRolesAction,
} from 'store/actions/Actions';

const AddTrainingModal = (props) => {
  const {
    OrgModulesReducer,
    UsersByOrgReducer,
    RolesReducer,
    UserOrgSiteReducer,
    loadRolesAction,
    loadUsersByOrgAction,
    loadFilesAction,
    loadSitesAction,
    files,
    SitesReducer,
    DepartmentsReducer,
    loadDeptAction,
  } = props;

  const is_site_admin = (UserOrgSiteReducer.selected && UserOrgSiteReducer.selected.is_site_admin) || false;
  const isSiteSelected = UserOrgSiteReducer.selected.site && UserOrgSiteReducer.selected.site !== '0' && UserOrgSiteReducer.selected.site !== '' ? true : false;
  const defaultReg = (OrgModulesReducer.data && OrgModulesReducer.data.map((r) => r.name)) || [];

  const [state, setState] = useState({
    site: '',
    due: '',
    reminder: undefined,
    selected_course: {},
    selected_site: {},
    selected_dep: {},
    users: [],
    regulatory_act: defaultReg[0] || '',
    training_courses: [],
    sites: [],
    selected_roles: {},
    selected_roles_names: undefined,
    frequency: undefined,
    lock_tasks: false,
    has_quiz: false,
    launch_date: '',
  });

  const [allUsers, setAllUsers] = useState([]);

  const [stateErrors, setStateErrors] = useState({
    site: '',
    due: '',
    selected_course: {},
    selected_site: {},
    regulatory_act: '',
    course: '',
    sites: [],
    training_courses: [],
    role: '',
    launch_date: '',
    department: '',
    users: '',
    frequency: '',
    reminder: '',
  });

  const [isUserSelectionVisible, setIsUserSelectionVisible] = useState('0');

  useEffect(() => {
    if (UsersByOrgReducer?.users) {
        setAllUsers(UsersByOrgReducer.users.filter((user) => user.active === "t" && user.name !== '').map((user) => ({id: user.user_id, label: user.name})));
    }
  }, [UsersByOrgReducer?.users]);

  useEffect(() => {
      if (allUsers) {
          setState((s) => ({
              ...s,
              users: allUsers.filter(user => user.added).map(item => item.id),
          }));
          setStateErrors((s) => ({ ...s, users: '' }));
      }
  }, [allUsers]);

  useEffect(() => {
    if (state.selected_site.name && state.selected_dep.name) {
      loadFilesAction({
        site: state.selected_site.id ? String(state.selected_site.id) : undefined,
        department: state.selected_dep.id ? String(state.selected_dep.id) : undefined,
      });
    }
  }, [state.selected_site, state.selected_dep]);

  useEffect(() => {
    if (!SitesReducer.sites) {
      loadSitesAction();
    }
  }, [SitesReducer.sites, loadSitesAction]);

  useEffect(() => {
    if (!RolesReducer.roles) {
      loadRolesAction();
    }
  }, [RolesReducer.roles, loadRolesAction]);

  useEffect(() => {
    if (state.selected_site.name && state.selected_dep.name && Object.keys(state.selected_roles) && state.selected_course.id) {
      loadUsersByOrgAction({
        site: state.selected_site.id ? String(state.selected_site.id) : undefined,
        department: state.selected_dep.id ? String(state.selected_dep.id) : undefined,
        role: Object.keys(state.selected_roles).length ? Object.keys(state.selected_roles).map(Number) : undefined,
        file_id: state.selected_course.id ? String(state.selected_course.id) : undefined,
      });
    }
  }, [state.selected_site.id, state.selected_dep.id, state.selected_roles, state.selected_course]);

  // useEffect(() => {
  //     if (!DepartmentsReducer.departments && SitesReducer.sites) {
  //         loadDeptAction({ site: });
  //     }
  // }, [DepartmentsReducer.departments, loadDeptAction, SitesReducer.sites]);

  let roleList = {};
  if (RolesReducer && RolesReducer.roles) {
    RolesReducer.roles.map((role) => {
      if (role.name && !role.name.includes('Super') && !role.name.includes('Coach')) {
        roleList[role.id] = { id: role.id, name: role.name };
      }
      return false;
    });
  }

  const handleCourseChange = (data) => {
    setState((s) => ({
      ...s,
      selected_course: {
        id: data?.id || '',
        name: data?.folder_name || '',
        document_id: data?.document_id || '',
        has_quiz: data?.has_quiz || '',
      },
    }));
    setStateErrors({
      ...stateErrors,
      course: '',
    });
  };

  const handleSiteChange = (e) => {
    setState((s) => ({
      ...s,
      users: [],
      selected_site: {
        id: e.target.value,
        name: e.target.dataset.name,
      },
      selected_dep: {},
      selected_course: {},
    }));

    loadDeptAction({ site: e.target.value });
    setStateErrors({
      ...stateErrors,
      site: '',
    });
  };

  const handleDepChange = (e) => {
    setState((s) => ({
      ...s,
      users: [],
      selected_dep: {
        id: e.target.value,
        name: e.target.dataset.name,
      },
      selected_course: {},
    }));

    setStateErrors({
      ...stateErrors,
      department: '',
    });
  };

  const handleRoleChange = (e) => {
    if (!e.target.value) {
      return false;
    }

    const role_id = e.target.value;
    const role_name = e.target.name;
    const role_active = e.target.checked;
    const newRoles = Object.assign({}, state.selected_roles);

    // update new roles
    if (role_id && role_name) {
      if (role_active) {
        // add
        newRoles[role_id] = true;
      } else {
        // remove
        delete newRoles[role_id];
      }
    }

    let newRoleNames = '';

    // generate role string name
    Object.keys(newRoles).forEach((key) => {
      if (roleList[key] && newRoles[key]) {
        newRoleNames = `${newRoleNames}, ${roleList[key].name}`;
      }
    });
    // trim leading space and commma
    newRoleNames = newRoleNames.substring(2);

    setState((s) => ({
      ...s,
      users: [],
      selected_roles: newRoles,
      selected_roles_names: newRoleNames,
    }));

    setStateErrors({
      ...stateErrors,
      role: '',
    });
  };

  const handleRegSelect = (e) => {
    setState((s) => ({
      ...s,
      regulatory_act: e.target.attributes && e.target.attributes.value ? e.target.attributes.value.value : '',
      selected_course: {},
    }));
    setStateErrors((s) => ({ ...s, regulatory_act: '', type: '' }));
  };

  const handleChange = (inputName) => (e) => {
    setState((s) => ({
      ...s,
      [inputName]: e.target.value,
    }));
    setStateErrors((s) => ({ ...s, [inputName]: '' }));
  };

  const handleChangeFreq = (inputName) => (e) => {
    setState((s) => ({
      ...s,
      [inputName]: e.target.value,
      due: e.target.value === 0 ? state.due : moment(state.launch_date, 'YYYY-MM-DD').add(e.target.value, 'month').format('YYYY-MM-DD'),
    }));
    setStateErrors((s) => ({ ...s, [inputName]: '', due: '' }));
  };

  const handleChangeLaunch = (inputName) => (e) => {
    setState((s) => ({
      ...s,
      [inputName]: e.target.value,
      due: state.frequency === 0 ? state.due : moment(e.target.value, 'YYYY-MM-DD').add(state.frequency, 'month').format('YYYY-MM-DD'),
    }));
    setStateErrors((s) => ({ ...s, [inputName]: '', due: '' }));
  };

  const handleCheckbox = (inputName) => (e) => {
    setState((s) => ({
      ...s,
      [inputName]: e.target.checked,
    }));
  };

  const handleUsersChange = (e) => {
    e.persist();
    setIsUserSelectionVisible(e.target.value);
    if (e.target.value === '0') {
      setState({
        ...state,
        users: [],
      });
    }
    setStateErrors((s) => ({ ...s, users: ''}));
  };

  const addTraining = () => {
    const totalRolesSelected = Object.values(state.selected_roles).length;

    if (
      !state.regulatory_act ||
      !state.selected_site.name ||
      !state.selected_dep.name ||
      !state.selected_course.name ||
      totalRolesSelected < 1 ||
      isUserSelectionVisible === '1' && !state.users.length ||
      state.frequency === undefined ||
      !state.launch_date ||
      !state.due
    ) {
      setStateErrors({
        ...stateErrors,
        regulatory_act: !state.regulatory_act ? 'Regulatory act is required' : '',
        site: !state.selected_site.name ? 'Site is required' : '',
        department: !state.selected_dep.name ? 'Department is required' : '',
        course: !state.selected_course.name ? 'Course is required' : '',
        role: totalRolesSelected < 1 ? 'Role is required' : '',
        users: isUserSelectionVisible === '1' && !state.users.length ? 'Users is required' : '',
        frequency: state.frequency === undefined ? 'Frequency is required' : '',
        launch_date: !state.launch_date ? 'Launch date is required' : '',
        due: !state.due ? 'Due date is required' : '',
      });
      return;
    }
    props.createTrainingAction({
      ...state,
      lock_tasks: state.frequency == 0 ? false : state.lock_tasks,
      has_quiz: state.has_quiz,
    });
    props.setShowModal(false);
  };

  const regTypeRef = useRef();
  const siteDropdownRef = useRef();
  const frequencyDropdownRef = useRef();
  const depDropdownRef = useRef();
  const userDropdownRef = useRef();
  const reminderDropdownRef = useRef();

  const toggleRoleDropdown = (e) => {
    if (siteDropdownRef?.current?.classList?.contains('show')) {
      siteDropdownRef.current.click();
    }
    if (depDropdownRef?.current?.classList?.contains('show')) {
      depDropdownRef.current.click();
    }
    if (frequencyDropdownRef?.current?.classList?.contains('show')) {
      frequencyDropdownRef.current.click();
    }
    if (regTypeRef?.current?.classList?.contains('show')) {
      regTypeRef.current.click();
    }
    if (userDropdownRef?.current?.classList?.contains('show')) {
      userDropdownRef.current.click();
    }
    if (reminderDropdownRef?.current?.classList?.contains('show')) {
      reminderDropdownRef.current.click();
    }
  };

  let usersList = {};
  usersList[0] = 'All';
  if (UsersByOrgReducer && UsersByOrgReducer.users) {
    UsersByOrgReducer.users.map((user) => {
      usersList[user.user_id] = user.name || user.email;
      return false;
    });
  }
  const frequencyListLabels = {
    0: 'One Time',
    1: 'Monthly',
    3: 'Quarterly',
    6: 'Biannually',
    12: 'Annually',
  };

  return (
    <Modal className={styles.modal} setShowModal={props.setShowModal}>
      <div onClick={toggleRoleDropdown}>
        <p className={styles['modal-heading']}>Add Training</p>

        <div className={styles['modal-content']}>
          <FormElement labelText="REGULATORY ACT" htmlFor="regulatory_act" errorMessage={stateErrors['regulatory_act'] || ''}>
            <Select placeholder="Select regulatory act" value={state.regulatory_act} onClick={(e) => handleRegSelect(e)} dropdownToggleRef={regTypeRef}>
              {OrgModulesReducer &&
                OrgModulesReducer.data &&
                OrgModulesReducer.data.map((d) => (
                  <li key={`regid-${d.name.toLowerCase()}`} value={d.name}>
                    {d.name}
                  </li>
                ))}
            </Select>
          </FormElement>

          <br />

          <div className={styles.holder}>
            <FormElement labelText="SITE" htmlFor="site" smallText="*" errorMessage={stateErrors.site}>
              <Select 
                value={SitesReducer.sites.length === 1 ? SitesReducer.sites[0].name : state.selected_site.name ? state.selected_site.name : 'All'} 
                placeholder="Select Site" 
                onClick={handleSiteChange} 
                dropdownToggleRef={siteDropdownRef}
              >
                {!is_site_admin && !isSiteSelected && (
                  <li key={`siteid-all`} data-name="All" value={0}>
                    All
                  </li>
                )}
                {SitesReducer &&
                  SitesReducer.sites &&
                  SitesReducer.sites
                    .filter((site) => site.active === 't')
                    .map((site) => (
                      <li key={`siteid-${site.id}`} data-name={site.name} value={site.id}>
                        {site.name}
                      </li>
                    ))}
              </Select>
            </FormElement>

            <FormElement className={styles.department} labelText="DEPARTMENT" htmlFor="department" smallText="*" errorMessage={stateErrors.department}>
              <Select 
                value={state.selected_dep.name ? state.selected_dep.name : 'All'} 
                placeholder="Select Department" 
                onClick={handleDepChange} 
                dropdownToggleRef={depDropdownRef}
              >
                <li key={`depid-all`} data-name={'All'} value={'0'}>
                  All
                </li>
                {DepartmentsReducer &&
                  DepartmentsReducer.departments &&
                  DepartmentsReducer.departments
                    .filter((dep) => dep.active === 't')
                    .map((department) => (
                      <li key={`siteid-${department.id}`} data-name={department.name} value={department.id}>
                        {department.name}
                      </li>
                    ))}
              </Select>
            </FormElement>
          </div>

          <br />

          <FormElement labelText="COURSE NAME" htmlFor="course" smallText="*" errorMessage={stateErrors.course}>
            <SelectWithSearch
              value={state.selected_course.id}
              onChange={handleCourseChange}
              placeholder="Select Course"
              disabled={Object.values(state.selected_dep).length === 0 || Object.values(state.selected_site).length === 0}
              options={(files || [])
                .filter((f) => f.regulatory_act === state.regulatory_act)
                .map((file) => ({
                  ...file,
                  name: file.folder_name,
                  displayName: file.path.replace(/[/]$/, ''),
                }))}
            />
          </FormElement>

          {state.selected_course.has_quiz === 't' && (
            <div className={styles.holder}>
              <Checkbox labelText="Quiz" checked={state.has_quiz} onChange={handleCheckbox('has_quiz')} className={styles['complete']} />
            </div>
          )}

          <br />

          <div className={`${styles.holder}`}>
            <FormElement labelText="ROLE" htmlFor="role" smallText="*" errorMessage={stateErrors.role}>
              <SelectMulti value={state.selected_roles_names} placeholder="Select Roles" onClick={handleRoleChange}>
                {Object.keys(roleList).map((key) => (
                  <Checkbox checked={state.selected_roles[key]} name={roleList[key].name} labelText={roleList[key].name} key={roleList[key].id} value={roleList[key].id} />
                ))}
              </SelectMulti>
            </FormElement>

            <FormElement className={styles.custom_radio_group} labelText="" htmlFor="users">
              <div style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: 10 }}>
                <RadioGroup
                  items={[
                    {
                      label: 'All Users',
                      value: '0',
                    },
                    {
                      label: 'Individual',
                      value: '1',
                    },
                  ]}
                  value={isUserSelectionVisible}
                  onChange={handleUsersChange}
                  name="users"
                />
              </div>
            </FormElement>
          </div>

          <br />

          {isUserSelectionVisible === '1' && <div className={`${styles.items}`}>
              <AddRemoveItems
                  allItemsTitle="ALL USERS"
                  addedItemsTitle="ADDED USERS"
                  searchPlaceholder="Search users..."
                  allItems={allUsers}
                  setAllItems={setAllUsers}
                  disabled={Object.values(state.selected_roles).length < 1 || 
                    Object.values(state.selected_site).length < 1 || 
                    Object.values(state.selected_dep).length < 1 || 
                    Object.values(state.selected_course).length < 1}
                  errorMessage={stateErrors.users}
                  required={true}
              />
          </div>}

          <br />

          <div className={styles.holder}>
            <FormElement labelText="FREQUENCY" htmlFor="frequency" smallText="*" errorMessage={stateErrors.frequency}>
              <Select
                placeholder="Select Frequency"
                dropdownToggleRef={frequencyDropdownRef}
                value={state.frequency}
                name="frequency"
                onClick={handleChangeFreq('frequency')}
                labels={frequencyListLabels}
              >
                {Object.entries(frequencyListLabels).map((frequency) => (
                  <li key={frequency[0]} value={frequency[0]}>
                    {frequency[1]}
                  </li>
                ))}
              </Select>
            </FormElement>

            <FormElement labelText="LAUNCH DATE" htmlFor="launch_date" smallText="*" className={styles.reminder} errorMessage={stateErrors.launch_date}>
              <DateInput onChange={handleChangeLaunch('launch_date')} value={state.launch_date} />
            </FormElement>
          </div>

          <br />

          <div className={styles.holder}>
            <FormElement labelText="DUE DATE" htmlFor="due" smallText="*" errorMessage={stateErrors.due}>
              <DateInput disabled={state.frequency !== '0' && state.frequency !== 0} onChange={handleChange('due')} value={state.due} />
            </FormElement>
          </div>

          <div className={styles.holder}>
            <Checkbox
              labelText="Lock tasks"
              disabled={state.frequency == 0 ? true : false}
              checked={state.frequency == 0 ? false : state.lock_tasks}
              onChange={handleCheckbox('lock_tasks')}
              className={styles['complete']}
            />
          </div>
        </div>

        <div className={styles['modal-footer']}>
          <Button text="CANCEL" color="secondary" onClick={() => props.setShowModal(false)} />
          <Button text="ADD TRAINING" className={styles.create} onClick={addTraining} />
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = ({
  ActsReducer,
  SitesReducer,
  UsersByOrgReducer,
  DepartmentsReducer,
  TrainingReducer,
  FilesReducer,
  RolesReducer,
  UserOrgSiteReducer,
  OrgModulesReducer,
}) => ({
  ActsReducer,
  SitesReducer,
  UsersByOrgReducer,
  TrainingReducer,
  DepartmentsReducer,
  files: FilesReducer.files,
  RolesReducer,
  UserOrgSiteReducer,
  OrgModulesReducer,
});

const mapDispatchToProps = {
  loadActsAction,
  loadSitesAction,
  loadUsersByOrgAction,
  loadDeptAction,
  createTrainingAction,
  loadTrainingCoursesAction,
  loadFilesAction,
  loadRolesAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTrainingModal);
