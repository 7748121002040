import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { connect, useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Spinner from 'components/Spinner/Spinner';

import Container from 'components/Container/Container';
import PageHeader from 'v2/components/PageHeader/PageHeader';
import PageHeaderActions from 'v2/components/PageHeader/components/PageHeaderActions/PageHeaderActions';
import FullDataTable from 'components/DataTable/FullDataTable';
import TableDefTasks from 'v2/table-defs/TableDefAssessmentTasks';
import AddTaskModal from './components/AddTaskModal/AddTaskModal';
import ViewTaskModal from './components/ViewTaskModal/ViewTaskModal';
import useFilterRows from 'helpers/customHooks/useFilterRows';
import { useAppSelector, useConfirmModal } from 'hooks';

import {
  loadTasksAction,
  setPaginationAction,
  deleteTaskAction,
  updateTaskAction,
  loadSitesAction,
  loadTasksFilteredAction,
  notifyTaskAction,
  loadUsersAction,
  completeTaskAction,
} from 'store/actions/Actions';
import { setSelectedRows, toggleDropdowns, exportAsCSV } from 'helpers/utils';
import { ASSESSMENT_TASKS_PAGE } from 'helpers/constants/DataTableConstants';
import ActionStatusConstants from 'helpers/ActionStatusConstants';

import styles from './Tasks.module.scss';

// const convertTypes = (tasks) => {
//   const remediation_types = ['All remediation'];
//   let response = [];
//   try {
//     tasks &&
//       tasks.map((t) => {
//         if (t.remediation_type && Array.isArray(t.remediation_type) && t.remediation_type.length > 0) {
//           t.remediation_type.map((type) => {
//             remediation_types.push(type);
//           });
//         }
//       });
//     response = [...new Set(remediation_types)];
//   } catch (error) {
//     console.log(error);
//   }
//   return response;
// };

const Tasks = (props) => {
  const [search] = useSearchParams();
  const taskId = search.get('id');

  const dispatch = useDispatch();
  const DatatableReducer = useAppSelector((state) => state.DatatableReducer);
  const { loadSitesAction, setPaginationAction, loadTasksFilteredAction, loadUsersAction, SitesReducer, TasksReducer, UsersReducer, UserOrgSiteReducer } = props;
  const [state, setState] = useState({
    selectedRows: null,
    selected_sites: [],
  });
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [isLoading, setLoading] = useState(true);

  const [sortKey, setSortKey] = useState(DatatableReducer.datatable[ASSESSMENT_TASKS_PAGE]?.sortKey);
  const [sortOrder, setSortOrder] = useState(DatatableReducer.datatable[ASSESSMENT_TASKS_PAGE]?.sortOrder);

  const [showConfirmModal, setShowConfirmModal, renderConfirmModal] = useConfirmModal();

  useEffect(() => {
    if (!SitesReducer.sites) {
      loadSitesAction();
    }
  }, [SitesReducer.sites, loadSitesAction]);

  useEffect(() => {
    if (!UsersReducer.users) {
      loadUsersAction({ inactive: true });
    }
  }, [UsersReducer.users, loadUsersAction]);

  useEffect(() => {
    if (!TasksReducer.tasks && SitesReducer.sites && UsersReducer.users) {
      loadTasksFilteredAction({ filter: { page: 'assessments' } });
    }
  }, [TasksReducer.tasks, SitesReducer.sites, UsersReducer.users, loadTasksFilteredAction]);

  const [activeReg, setActiveReg] = useState('');
  useEffect(() => {
      if (DatatableReducer.datatable && DatatableReducer.datatable.dashboard && DatatableReducer.datatable.dashboard.activeReg) {
          setActiveReg(DatatableReducer.datatable.dashboard.activeReg);
      }
  }, [DatatableReducer.datatable]);

  useEffect(() => {
    if (activeReg && DatatableReducer.datatable[ASSESSMENT_TASKS_PAGE].filters) {
      setFilters({
        ...DatatableReducer.datatable[ASSESSMENT_TASKS_PAGE].filters,
        regulatory_act: activeReg,
      });
    }
  }, [activeReg]);

  useEffect(() => {
    if (
      TasksReducer.status === ActionStatusConstants.SUCCESS &&
      SitesReducer.status === ActionStatusConstants.SUCCESS &&
      UsersReducer.status === ActionStatusConstants.SUCCESS
    ) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [TasksReducer.tasks, SitesReducer.sites, UsersReducer.users]);

  const noFilters = {
    text: '',
    type: '',
    regulatory_act: activeReg,
    'last-action': '',
    status: '',
    role: '',
    site: '',
    selected_sites: [],
    rem: '',
    start: '',
    end: '',
    dateField: 'assigned',
  };

  const [filters, setFilters] = useState(
    DatatableReducer?.datatable[ASSESSMENT_TASKS_PAGE]?.filters ? DatatableReducer?.datatable[ASSESSMENT_TASKS_PAGE]?.filters : noFilters
  );

  const setFilter = (filterName) => (e) => {
    setFilters({
      ...filters,
      [filterName]:
        e.target.type === 'checkbox' ? e.target.checked : e.target.value || (e.target && e.target.attributes && e.target.attributes.value && e.target.attributes.value.value) || '',
    });
    setPaginationAction({ dataType: ASSESSMENT_TASKS_PAGE, currentPage: 0 });
  };

  const searchFilter = search.get('filter');
  const sfCapitalized = searchFilter ? searchFilter.charAt(0).toUpperCase() + searchFilter.slice(1) : '';

  useEffect(() => {
    if (searchFilter === '') {
      setFilters((s) => ({
        ...s,
        type: '',
      }));
    } else if (searchFilter === 'all') {
      setFilters((s) => ({
        ...s,
        type: 'All task types',
      }));
    } else {
      setFilters((s) => ({
        ...s,
        type:
          sfCapitalized ||
          (DatatableReducer?.datatable[ASSESSMENT_TASKS_PAGE]?.filters?.type) ||
          '',
      }));
    }
    // window.history.replaceState(null, null, '/assessments/tasks');
  }, [searchFilter]);

  const setDateFilter = (filterName) => (value) => {
    setFilters({
      ...filters,
      [filterName]: value,
    });
    setPaginationAction({ dataType: ASSESSMENT_TASKS_PAGE, currentPage: 0 });
  };

  const onChangeRowSelect = ({ selectedRows }) => {
    if (selectedRows !== state.selectedRows) {
      setState((s) => ({ ...s, selectedRows }));
    }
  };

  const filteredRows = useFilterRows(filters, TasksReducer.tasks);

  const [selectedTaskIds, setSelectedTaskIds] = useState(
    DatatableReducer?.datatable[ASSESSMENT_TASKS_PAGE]?.selectedIds ? DatatableReducer?.datatable[ASSESSMENT_TASKS_PAGE]?.selectedIds : []
  );

  useEffect(() => {
    dispatch({
      type: 'SET_DATATABLE',
      payload: {
        datatable: {
          [ASSESSMENT_TASKS_PAGE]: {
            selectedIds: selectedTaskIds,
            filters,
            selectedRows: state.selectedRows,
            selected_sites: state.selected_sites,
            rowsPerPage:
              DatatableReducer?.datatable[ASSESSMENT_TASKS_PAGE]?.rowsPerPage ? DatatableReducer?.datatable[ASSESSMENT_TASKS_PAGE]?.rowsPerPage : 10,
            currentPage:
              DatatableReducer?.datatable[ASSESSMENT_TASKS_PAGE]?.currentPage ? DatatableReducer?.datatable[ASSESSMENT_TASKS_PAGE]?.currentPage : 0,
            sortKey,
            sortOrder,
          },
        },
      },
    });
  }, [selectedTaskIds, filters, state.selectedRows, state.selected_sites, sortKey, sortOrder, filteredRows]);

  const actDropdownRef = React.createRef();
  // const siteDropdownRef = React.createRef();
  const chooseActionDropdownRef = React.createRef();
  const typeDropdownRef = React.createRef();
  const statusDropdownRef = React.createRef();
  const regDropdownRef = React.createRef();
  const remDropdownRef = React.createRef();

  const handleComplete = (selectedTaskIds) => {
    if (!selectedTaskIds) return;
    const payload = selectedTaskIds.map((item) => {
      return { ...item, completed: true };
    });
    payload[0].filter = { page: 'assessments' } ;
    props.completeTaskAction(payload);
  };

  const handleIncomplete = (selectedTaskIds) => {
    if (!selectedTaskIds) return;
    const payload = selectedTaskIds.map((item) => {
      return { ...item, completed: false };
    });
    payload[0].filter = { page: 'assessments' } ;
    props.completeTaskAction(payload);
  };

  const handleDelete = (selectedTaskIds) => {
    if (!selectedTaskIds) return;
    selectedTaskIds[0].filter = { page: 'assessments' } ;
    props.deleteTaskAction(selectedTaskIds);
  };

  const handleNotify = (selectedTaskIds) => {
    if (!selectedTaskIds) return;
    selectedTaskIds[0].filter = { page: 'assessments' } ;
    props.notifyTaskAction(selectedTaskIds);
  };

  useEffect(() => {
    if (taskId && TasksReducer.tasks) {
      let tasks = TasksReducer.tasks.filter((task) => task.id == taskId);
      if (tasks && tasks.length === 1) {
        setShowViewModal(tasks[0]);
      }
    }
  }, [taskId, TasksReducer.tasks]);

  // const is_admin = (UserOrgSiteReducer.selected && UserOrgSiteReducer.selected.is_admin) || false;
  const is_officer = (UserOrgSiteReducer.selected && UserOrgSiteReducer.selected.is_officer) || false;
  const is_super = (UserOrgSiteReducer.selected && UserOrgSiteReducer.selected.is_super) || false;
  const is_coach = (UserOrgSiteReducer.selected && UserOrgSiteReducer.selected.is_coach) || false;

  const getSitesList = () => {
    try {
      const sitesList = SitesReducer.sites
        ? SitesReducer.sites.map((site) => {
            return {
              id: site.id,
              siteName: site.name,
              value: filters.selected_sites.includes(site.id) ? true : false,
            };
          })
        : [];
      sitesList.unshift({
        id: '',
        siteName: 'All Sites',
        value: filters.selected_sites.includes(''),
      });
      return sitesList;
    } catch (error) {
      console.log('error with site list', error);
    }
    return [];
  };

  const labels = () => ({
    task: {
      label: 'TITLE',
    },
    notes: {
      label: 'NOTES',
    },
    regulatory_act: {
      label: 'REGULATORY',
    },
    type: {
      label: 'TYPE',
    },
    rem: {
      label: 'REMEDIATION',
    },
    risk: {
      label: 'RISK',
    },
    assigned: {
      label: 'REQUESTED',
    },
    due: {
      label: 'DUE',
    },
    completed_on: {
      label: 'COMPLETED',
    },
    assignee_name: {
      label: 'ASSIGNEE',
    },
    siteName: {
      label: 'SITE',
    },
    status: {
      label: 'STATUS',
    },
  });

  return (
    <div
      onClick={toggleDropdowns([
        chooseActionDropdownRef,
        typeDropdownRef,
        // siteDropdownRef,
        actDropdownRef,
        statusDropdownRef,
        regDropdownRef,
        remDropdownRef,
      ])}
    >
      <PageHeader>
        <PageHeaderActions
          className={styles.tasks_page_header}
          // type={{
          //   onChange: setFilter('type'),
          //   options: ['All task types', 'Remediation', 'Attestation', 'Question', 'Incident', 'Miscellaneous'],
          // }}
          // typeDropdownRef={typeDropdownRef}
          primaryButtonText="ADD TASK"
          primaryButtonHandler={() => navigate(`/assessments/tasks/add`)}
          searchHandler={(e) => {
            setFilters({ ...filters, text: e.target.value || '' });
            setPaginationAction({ dataType: ASSESSMENT_TASKS_PAGE, currentPage: 0 });
          }}
          searchValue={filters.text}
          chooseActionDropdownRef={chooseActionDropdownRef}
          chooseAction={
            is_officer || is_super || is_coach
              ? [
                  {
                    actionName: 'Notify Selected',
                    actionHandler: () => {
                      const idCount = selectedTaskIds.length;

                      if (idCount > 0) {
                        setShowConfirmModal({
                          title: 'Notify Assignees',
                          question:
                            idCount === 1
                              ? `Are you sure you want to notify the assignee about the task?`
                              : `Are you sure you want to notify assignees about ${idCount} selected tasks?`,
                          confirmAction: () => {
                            handleNotify(selectedTaskIds);
                          },
                        });
                      } else {
                        setShowConfirmModal({
                          type: 'simple',
                          title: 'Nothing selected',
                          question: `Please select tasks.`,
                        });
                      }
                    },
                  },
                  {
                    actionName: 'Mark Selected Complete',
                    actionHandler: () => handleComplete(selectedTaskIds),
                  },
                  {
                    actionName: 'Mark Selected Incomplete',
                    actionHandler: () => handleIncomplete(selectedTaskIds),
                  },
                  {
                    actionName: 'Export Selected',
                    actionHandler: () => {
                      const data = TasksReducer.tasks.filter((item) => selectedTaskIds.map((i) => i.id).includes(item.id));
                      exportAsCSV(data, labels, 'tasks.csv');
                    },
                  },
                  {
                    actionName: 'Delete Selected',
                    actionHandler: () => {
                      const idCount = selectedTaskIds.length;

                      if (idCount > 0) {
                        setShowConfirmModal({
                          title: 'Delete selected tasks',
                          question: idCount === 1 ? `Are you sure you want to delete the selected task?` : `Are you sure you want to delete ${idCount} selected tasks?`,
                          confirmAction: () => {
                            handleDelete(selectedTaskIds);
                          },
                        });
                      } else {
                        setShowConfirmModal({
                          type: 'simple',
                          title: 'Nothing selected',
                          question: `Please select tasks to be deleted.`,
                        });
                      }
                    },
                  },
                ]
              : [
                  {
                    actionName: 'Mark Selected Complete',
                    actionHandler: () => handleComplete(selectedTaskIds),
                  },
                  {
                    actionName: 'Mark Selected Incomplete',
                    actionHandler: () => handleIncomplete(selectedTaskIds),
                  },
                  {
                    actionName: 'Notify Selected',
                    actionHandler: () => {
                      const idCount = selectedTaskIds.length;

                      if (idCount > 0) {
                        setShowConfirmModal({
                          title: 'Notify Assignees',
                          question: idCount === 1 ? `Are you sure you want to notify the assignee?` : `Are you sure you want to notify ${idCount} assignees?`,
                          confirmAction: () => {
                            handleNotify(selectedTaskIds);
                          },
                        });
                      } else {
                        setShowConfirmModal({
                          type: 'simple',
                          title: 'Nothing selected',
                          question: `Please select tasks.`,
                        });
                      }
                    },
                  },
                  {
                    actionName: 'Export Selected',
                    actionHandler: () => {
                      const data = TasksReducer.tasks.filter((item) => selectedTaskIds.map((i) => i.id).includes(item.id));
                      exportAsCSV(data, labels, 'tasks.csv');
                    },
                  },
                ]
          }
          site={{
            onClick: (e) => {
              if (e.target.type === 'checkbox') {
                let selected_sites = filters.selected_sites.slice();
                if (e.target.checked) {
                  selected_sites.push(e.target.value);
                } else {
                  selected_sites = selected_sites.filter((site) => site !== e.target.value);
                }
                setFilters({
                  ...filters,
                  site: selected_sites.length > 0 ? `Sites (${selected_sites.length})` : 'Site',
                  // site: "Site",
                  selected_sites: selected_sites,
                });
                setPaginationAction({ dataType: ASSESSMENT_TASKS_PAGE, currentPage: 0 });
              }
            },
            sites: getSitesList(),
          }}
          // siteDropdownRef={siteDropdownRef}
          status={{
            onChange: setFilter('status'),
            options: ['All', 'Complete', 'Incomplete', 'Deactivated incomplete'],
          }}
          statusDropdownRef={statusDropdownRef}
          dateRange={{
            onChangeStart: setDateFilter('start'),
            onChangeEnd: setDateFilter('end'),
          }}
          // reg={{
          //   onChange: setFilter('regulatory_act'),
          //   options: ['All regulatory', 'HIPAA', 'OSHA'],
          // }}
          // regDropdownRef={regDropdownRef}
          // rem={{
          //   onChange: setFilter('rem'),
          //   options: convertTypes(TasksReducer.tasks),
          // }}
          // remDropdownRef={remDropdownRef}
          filters={filters}
          resetFilters={() => {
            setFilters(noFilters);
            setPaginationAction({ dataType: ASSESSMENT_TASKS_PAGE, currentPage: 0 });
          }}
        />
      </PageHeader>
      {isLoading ? (
        <Spinner />
      ) : (
        <Container wide fluid>
          {TasksReducer.tasks && (
            <FullDataTable
              onRowClick={function (e) {
                if (e.currentTarget.classList.contains('col-actions') || e.currentTarget.classList.contains('col-row-selector')) {
                  return;
                }
                // const task = TasksReducer.tasks.filter(task => task.id === this.props.rowData.id)[0];
                // window.scrollTo({ top: 0, behavior: "smooth" });
                // setShowViewModal(task);
                navigate(`/assessments/tasks/edit/${this.props.rowData.id}`);
              }}
              rowsData={filteredRows}
              definitions={TableDefTasks}
              dataType={ASSESSMENT_TASKS_PAGE}
              setSortKey={setSortKey}
              setSortOrder={setSortOrder}
              onChangeRowSelect={({ selectedRows }) => {
                onChangeRowSelect({ selectedRows });
              }}
              customTableProps={
                is_officer || is_super || is_coach
                  ? {
                      // onNameClick: ({ rowData }) => console.log("NAME CLICK", rowData),
                      selectedRows: state.selectedRows ? state.selectedRows : selectedTaskIds,
                      viewTask: (id) => {
                        // console.log(id);
                        // navigate(`/maintain/tasks/edit?id=${id}`);
                      },
                      handleNotify: (item) => {
                        setShowConfirmModal({
                          title: 'Notify the assignee',
                          question: 'Are you sure you want to notify the assignee about the task?',
                          confirmAction: () => {
                            handleNotify([{ id: item.id }]);
                          },
                        });
                      },
                      handleComplete: (item) => handleComplete([{ id: item.id }]),
                      handleIncomplete: is_super || is_coach ? (item) => handleIncomplete([{ id: item.id }]) : undefined,
                      handleDelete: ({ id, task }) => {
                        setShowConfirmModal({
                          title: 'Delete the task',
                          question: `Are you sure you want to remove the task "${task}"`,
                          confirmAction: () => {
                            handleDelete([{ id }]);
                          },
                        });
                      },
                      selectCheckboxes: JSON.parse(localStorage.getItem('taskIds')),
                    }
                  : {
                      // onNameClick: ({ rowData }) => console.log("NAME CLICK", rowData),
                      selectedRows: state.selectedRows ? state.selectedRows : selectedTaskIds,
                      viewTask: (id) => {
                        // console.log(id);
                        // navigate(`/maintain/tasks/edit?id=${id}`);
                      },
                      handleNotify: (item) => handleNotify([{ id: item.id }]),
                      handleComplete: (item) => handleComplete([{ id: item.id }]),
                      selectCheckboxes: JSON.parse(localStorage.getItem('taskIds')),
                      // handleIncomplete: item => handleIncomplete([{ id: item.id }]),
                      // handleDelete: item => handleDelete([{ id: item.id }])
                    }
              }
              setSelectedRows={setSelectedRows(['id'], setSelectedTaskIds)}
            />
          )}
          {showConfirmModal && renderConfirmModal(showConfirmModal, setShowConfirmModal)}
        </Container>
      )}

      {showModal ? ReactDOM.createPortal(<AddTaskModal setShowModal={setShowModal} id={taskId} />, document.querySelector('#modal-root')) : null}

      {showViewModal ? ReactDOM.createPortal(<ViewTaskModal data={showViewModal} setShowModal={setShowViewModal} />, document.querySelector('#modal-root')) : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  TasksReducer: state.TasksReducer,
  UsersReducer: state.UsersReducer,
  SitesReducer: state.SitesReducer,
  UserOrgSiteReducer: state.UserOrgSiteReducer,
});

const mapDispatchToProps = {
  loadUsersAction,
  updateTaskAction,
  completeTaskAction,
  setPaginationAction,
  loadTasksFilteredAction,
  loadTasksAction,
  deleteTaskAction,
  notifyTaskAction,
  loadSitesAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Tasks);
