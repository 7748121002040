import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'hooks';
import { saveVendorAuditAffiliateAction, readVendorAuditTokenAffiliateAction } from 'store/actions/Actions';
import { Button } from 'components/Button/Button';

import compliancyLogo from 'assets/img/cg-logo-large.png';
import styles from './Questionnaire.module.scss';

const data = {
  1: {
    id: '1',
    questions: {
      1: {
        id: '1',
        question: 'Do you identify how and where PHI is accessed, created, received, maintained, or transmitted within your organization?',
        answer: 'No',
      },
      2: {
        id: '2',
        question: 'Do you protect the accessibility and integrity of PHI in all forms?',
        answer: 'No',
      },
      3: {
        id: '3',
        question: 'Do you have systems and processes in place to detect and track any unauthorized access, use, alteration, blocked access to, or disclosure of PHI?',
        answer: 'No',
      },
      4: {
        id: '4',
        question: 'Do you have adequate resources and capabilities to respond to any unauthorized access, use, alteration, blocked access or disclosure of PHI?',
        answer: 'No',
      },
      5: {
        id: '5',
        question: 'Do you have adequate resources dedicated to recovering any PHI that has been inappropriately altered or for which access has been blocked?',
        answer: 'No',
      },
      6: {
        id: '6',
        question:
          'Do you enter into business associate agreements when you create, receive, maintain, or transmit PHI or when allowing another entity to access, create, receive, maintain, or transmit PHI on your behalf?',
        answer: 'No',
      },
      7: {
        id: '7',
        question:
          'Do you ensure that your business associate agreements require the business associate to comply with the HIPAA Security Rule in its performance of work for you that involves ePHI?',
        answer: 'No',
      },
      8: {
        id: '8',
        question:
          'Do your business associate agreements establish the permitted and required uses and disclosures of protected health information and not allow further disclosure of that information in a manner that would violate the Privacy Rule (with the exception that it may be used for a business associates own proper management and administration or to provide data aggregation services relating to the healthcare operations of a covered entity if such services are part of their (direct or delegated) responsibilities to the covered entity)?',
        answer: 'No',
      },
      9: {
        id: '9',
        question: 'Do you ensure that you have properly designated your contractors, either as business associates, or as members of the workforce?',
        answer: 'No',
      },
      10: {
        id: '10',
        question:
          'Do you have policies and procedures in place to ensure the confidentiality, integrity, and availability of all electronic protected health information that you create, receive, maintain, or transmit?',
        answer: 'No',
      },
      11: {
        id: '11',
        question:
          'Do you have policies and procedures mandating the completion of an annual security risk analysis, and do you promptly remediate any gaps uncovered by that analysis?',
        answer: 'No',
      },
      12: {
        id: '12',
        question: 'Have you implemented security measures that serve to prevent unauthorized or unnecessary disclosure of PHI?',
        answer: 'No',
      },
      13: {
        id: '13',
        question: 'Do you require training of your workforce on your security policies and procedures, and the Security Rule?',
        answer: 'No',
      },
      14: {
        id: '14',
        question: 'Do you periodically review and modify security measures as needed to continue to provide protection to ePHI?',
        answer: 'No',
      },
      15: {
        id: '15',
        question: 'Do you ensure that documentation of security measures is updated and maintained?',
        answer: 'No',
      },
      16: {
        id: '16',
        question: 'Has your organization made the workforce aware of what constitutes unauthorized use or disclosure of ePHI?',
        answer: 'No',
      },
      17: {
        id: '17',
        question:
          'Has your organization made the workforce aware of your sanctions policy and potential disciplinary measures for inappropriate use, disclosure, and transmission of ePHI?',
        answer: 'No',
      },
      18: {
        id: '18',
        question: 'Has your organization made your workforce members aware of the identity, roles, and responsibilities of their supervisors?',
        answer: 'No',
      },
      19: {
        id: '19',
        question:
          'Does your organization have a procedure that outlines how and when your managers, staff, and all other workforce members are to be notified of suspected inappropriate activity?',
        answer: 'No',
      },
      20: {
        id: '20',
        question:
          'Does your organization have a procedure that determines whether providing access to an individual is appropriate and prevents access to individuals whose backgrounds indicate that giving them access might compromise the privacy or integrity of PHI? (For example, does your organization perform criminal background checks, credentialing or ongoing exclusion list checks for workforce members?)',
        answer: 'No',
      },
      21: {
        id: '21',
        question:
          "Do you have policies and procedures for terminating access to electronic protected health information when the employment of, or other arrangement with, a workforce member ends, or when someone's access is no longer appropriate?",
        answer: 'No',
      },
      22: {
        id: '22',
        question:
          'Have you developed and implemented a policy that prohibits users from installing software on workstations and devices prior to a proper vetting of that software and its security?',
        answer: 'No',
      },
      23: {
        id: '23',
        question:
          "Do you have a policy that restricts users from connecting their personal portable devices to any workstations, devices, or the organization's internal network without following established procedures regarding using their own devices?",
        answer: 'No',
      },
      24: {
        id: '24',
        question: 'Do you restrict the administrative group access for each server, firewall, and router to key personnel?',
        answer: 'No',
      },
      25: {
        id: '25',
        question:
          'Do you ensure that you treat entities that maintain or store ePHI on your behalf as business associates, even if they do not actually view the protected health information?',
        answer: 'No',
      },
      26: {
        id: '26',
        question:
          'Do you ensure that business associate agreements require business associates to comply with the applicable requirements of the Security Rule, and to report to you any security incident of which the business associate becomes aware, including breaches of unsecured protected health information, as required by the Breach Notification Rule?',
        answer: 'No',
      },
      27: {
        id: '27',
        question:
          'Whenever you change your policies and procedures, do you ensure that all changes are documented and implemented in accordance with the provisions of the Security Rule?',
        answer: 'No',
      },
      28: {
        id: '28',
        question: 'Do you maintain your Security Rule policies and procedures in writing, whether in paper or electronic format? and maintain them for at least six years?',
        answer: 'No',
      },
      29: {
        id: '29',
        question:
          'When the Security Rule requires an action, activity or assessment to be documented, do you maintain a written (paper or electronic format) record of the action activity, or assessment and retain them for at least six years?',
        answer: 'No',
      },
      30: {
        id: '30',
        question:
          'Applicable to group health plans and those servicing group health plans only; others may select "N/A". If you are a group health plan or are providing services to or on behalf of a group health plan , do you ensure that plan documents provide that the plan sponsor will reasonably and appropriately safeguard electronic protected health information created, received, maintained, or transmitted to or by the plan sponsor on behalf of the group health plan?',
        answer: 'No',
      },
      31: {
        id: '31',
        question: 'Do you monitor business associates for patterns or practices that violate the terms of the business associate agreement?',
        answer: 'No',
      },
      32: {
        id: '32',
        question: 'Do you assure that any violations by business associates are addressed with reasonable steps to cure or end the violation?',
        answer: 'No',
      },
      33: {
        id: '33',
        question:
          'If any business associate fails to address a violation or is unable to meet the terms of their business associate agreement do you terminate agreements with the business associate or report them to HHS if termination is not feasible?',
        answer: 'No',
      },
      34: {
        id: '34',
        question: 'Do you ensure that you are able to provide HHS access to PHI if it is necessary to any compliance investigation by the HHS Secretary?',
        answer: 'No',
      },
      35: {
        id: '35',
        question: 'Will you provide us access to any PHI held by you in a designated record set if we need to provide required HIPAA access to an individual?',
        answer: 'No',
      },
      36: {
        id: '36',
        question:
          'Do you use and disclose PHI only as permitted or required by the business associate agreements and require your business associates to only use and disclose PHI as required or permitted under their business associate agreements?',
        answer: 'No',
      },
    },
  },
};

const redirectToCompliancyGroup = () => {
  window.open('https://compliancy-group.com/hipaa-business-associate-software/?utm_source=The+Guard&utm_medium=Thank+You&utm_campaign=BA');
};

function Questionnaire() {
  const dispatch = useAppDispatch();

  const AffiliatesAuditReducer = useAppSelector((state) => state.AffiliatesAuditReducer);

  const { hash } = useParams();
  const [audit, setAudit] = useState({});
  const [isCompleted, setCompleted] = useState(true);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (!AffiliatesAuditReducer.audit) {
      dispatch(readVendorAuditTokenAffiliateAction({ token: hash }));
    }

    if (AffiliatesAuditReducer.audit) {
      setCompleted(AffiliatesAuditReducer.audit.audit_complete === 't');
      setAudit(AffiliatesAuditReducer.audit.audit_json || data);
      setLoading(false);
    }
  }, [AffiliatesAuditReducer.audit, hash]);

  const handleCheckbox = (e) => {
    try {
      const a = { ...audit };
      a[e.target.dataset.sid].questions[e.target.dataset.qid].answer = e.target.value;
      dispatch(
        saveVendorAuditAffiliateAction({
          token: hash,
          audit_json: audit,
          is_completed: false,
        })
      );
      setAudit(a);
    } catch {
      console.log('error handle checkbox!');
    }
  };

  const handleSubmit = () => {
    dispatch(
      saveVendorAuditAffiliateAction({
        token: hash,
        audit_json: audit,
        is_completed: true,
      })
    );
  };

  return (
    <div className={styles.container}>
      <img src={compliancyLogo} className={styles.logo} alt="" />
      {!isCompleted && !isLoading && (
        <>
          <h1 className={styles.title}>Business Associate Due Diligence Questionnaire</h1>
          <div className={styles.holder}>
            {Object.values(audit).map((section: any) => (
              <div key={section.id}>
                {section.title && (
                  <div className={styles.section_head}>
                    <div>{section.title}</div>
                    <div>{section.desc}</div>
                  </div>
                )}
                <div>
                  <div className={styles.questions}>
                    {Object.values(section.questions).map((q: any, id) => (
                      <div key={`${q.id}-all`}>
                        <span>{q.id}</span>
                        <span>{q.question}</span>
                        <span>
                          <input
                            type="radio"
                            id={`${q.id}-yes`}
                            onChange={handleCheckbox}
                            data-sid={section.id}
                            data-qid={id + 1}
                            name={`${q.id}-q`}
                            value="Yes"
                            defaultChecked={q.answer === 'Yes'}
                          />
                          <label htmlFor={`${q.id}-yes`}>Yes</label>
                          <input
                            type="radio"
                            id={`${q.id}-no`}
                            onChange={handleCheckbox}
                            data-sid={section.id}
                            data-qid={id + 1}
                            name={`${q.id}-q`}
                            value="No"
                            defaultChecked={q.answer === 'No'}
                          />
                          <label htmlFor={`${q.id}-no`}>No</label>
                          <input
                            type="radio"
                            id={`${q.id}-na`}
                            onChange={handleCheckbox}
                            data-sid={section.id}
                            data-qid={id + 1}
                            name={`${q.id}-q`}
                            value="NA"
                            defaultChecked={q.answer === 'NA'}
                          />
                          <label htmlFor={`${q.id}-na`}>Not Applicable</label>
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <Button className={styles.submit} onClick={handleSubmit} text="Submit Questionnaire" />
        </>
      )}
      {isCompleted && !isLoading && (
        <div className={styles.completed}>
          <div className={styles.completed_title}>Thank you for completing the questionnaire!</div>
          <div className={styles.completed_sub_title}>Want to Automate and Simplify HIPAA compliance?</div>
          <div className={styles.completed_text}>
            HIPAA has many requirements, and even missing one step makes your business noncompliant.
            <br />
            Your client uses our software to protect their business, see how we can help yours too.
          </div>
          <Button className={styles.completed_button} onClick={redirectToCompliancyGroup} text="Get HIPAA Compliant" />
        </div>
      )}
      <div className={styles.copyright}>
        <p>Copyright &#169; 2023 Compliancy Group, LLC. All Rights Reserved</p>
      </div>
    </div>
  );
}

export default Questionnaire;
