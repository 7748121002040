import React from 'react';
import classNames from 'classnames';

import styles from './Container.module.scss';

interface IProps {
  wide?: boolean;
  fluid?: boolean;
  dashboard?: boolean;
  className?: string;
  children?: React.ReactNode;
}

const defaultProps = {
  wide: false,
  fluid: false,
  dashboard: false,
  className: undefined,
  children: undefined,
};

function Container(props: IProps) {
  const style = classNames(styles.container, props.wide && styles.wide, props.fluid && styles.fluid, props.dashboard && styles.dashboard, props.className);
  return <div className={style}>{props.children}</div>;
}

Container.defaultProps = defaultProps;

export default Container;
