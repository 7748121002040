import React from 'react';

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';

import { msalConfig } from './authConfig';

const msalInstance = new PublicClientApplication(msalConfig);

function MsalAppProvider(props) {
  return <MsalProvider instance={msalInstance}>{props.children}</MsalProvider>;
}

export default MsalAppProvider;
