import React from 'react';
import classNames from 'classnames';
import styles from './FormElement.module.scss';

interface IProps {
  /** additional className to add */
  className?: string;
  /** text displayed between opening and closing label tag */
  labelText?: string;
  /** associated with (same as) an input's name */
  htmlFor?: string;
  /** an error message to display */
  errorMessage?: string;
  children?: React.ReactNode;
  /** small text to add after label */
  smallText?: string;
}

const defaultProps = {
  className: undefined,
  labelText: '',
  htmlFor: undefined,
  errorMessage: undefined,
  children: undefined,
  smallText: undefined,
};

function FormElement({ className, labelText, htmlFor, errorMessage, children, smallText }: IProps) {
  const style = classNames(styles['form-group'], className);
  return (
    <div className={style}>
      {labelText && (
        <label htmlFor={htmlFor}>
          {labelText}
          {smallText && <span className={styles['small-text']}>{smallText}</span>}
        </label>
      )}
      {children}
      {errorMessage && <p className={styles['form-error-message']}>{errorMessage}</p>}
    </div>
  );
}

FormElement.defaultProps = defaultProps;

export default FormElement;
