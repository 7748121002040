import React, { useEffect, useState } from 'react';

import styles from './Question.module.scss';

import FormElement from 'components/FormElement/FormElement';
import Row from 'components/Row/Row';
import Radio from 'components/Radio/Radio';
import Select from 'components/Select/Select';
import { toggleDropdowns } from 'helpers/utils';

/**
 *
 * @param {Object} question
 * @param {String} question.id - id
 * @param {String} question.question - question to show
 * @param {String} question.answer - answer to question
 * @param {Number} question.risk - risk from 1 to 5 inclusive
 * @param {String} question.notes - question notes
 * @param {Function} setData - a function to set data in parent
 */
const Question = (props) => {
  // [OPTIONAL]
  // A way to close dropdowns when clicking on parent if they are open
  const riskDropdownRef = React.createRef();
  const assigneeDropdownRef = React.createRef();
  const disabled = props.finalized;

  const [stateErrors, setStateErrors] = useState({
    employee: '',
  });

  useEffect(() => {
    setStateErrors((s) => ({
      ...s,
      ...props.errors,
    }));
  }, [props.errors]);

  if (!props.question || !props.question.question || !props.users) {
    return false;
  }

  let usersList = {};
  if (props && props.users) {
    props.users
      .filter((item) => item.registered === 't')
      .forEach((user) => {
        usersList[user.id] = user.name;
      });
  }

  return (
    <form className={styles['question-form']} onClick={toggleDropdowns([riskDropdownRef, assigneeDropdownRef])}>
      <div>
        <p className={styles.question}>{props.question.question}</p>
      </div>

      <FormElement htmlFor="answer">
        <Row className={styles['status-row']}>
          {/* 2 = Yes */}
          <Radio disabled={disabled} checked={props.question.answer === '2'} labelText="Yes" onChange={props.setData('answer')} value="2" />
          {/* 1 = No */}
          <Radio disabled={disabled} checked={props.question.answer === '1'} labelText="No" onChange={props.setData('answer')} value="1" />
          {/* 3 = Not Applicatble */}
          <Radio
            disabled={disabled}
            checked={props.question.answer === '3' || props.question.answer === ''}
            labelText="Not Applicable"
            onChange={props.setData('answer')}
            value="3"
          />
          {/* 0 = Assign */}
          <Radio disabled={disabled} checked={props.question.answer === '4'} labelText="Assign" onChange={props.setData('answer')} value="4" />
        </Row>
      </FormElement>

      <div className={styles.answer_holder}>
        <FormElement className={styles.risk} htmlFor="risk" labelText="DEGREE OF RISK">
          <Select
            labels={{
              1: '1',
              2: '2',
              3: '3',
              4: '4',
              5: '5',
              6: '6',
              7: '7',
              8: '8',
              9: '9',
              10: '10',
            }}
            disabled={disabled}
            value={props.question.risk}
            onClick={(e) => props.setData('risk')(e)}
            dropdownToggleRef={riskDropdownRef}
          >
            <li value="1">1</li>
            <li value="2">2</li>
            <li value="3">3</li>
            <li value="4">4</li>
            <li value="5">5</li>
            <li value="6">6</li>
            <li value="7">7</li>
            <li value="8">8</li>
            <li value="9">9</li>
            <li value="10">10</li>
          </Select>
        </FormElement>
        <FormElement htmlFor="assignee" labelText="ASSIGN QUESTION TO EMPLOYEE" errorMessage={stateErrors.employee}>
          <Select
            labels={usersList}
            disabled={props.question.answer !== '4' ? true : disabled}
            placeholder="Select employee"
            value={props.question.answer !== '4' ? '' : props.question.user_id}
            onClick={(e) => {
              props.setData('user_id')(e);
              setStateErrors({
                employee: '',
              });
            }}
            dropdownToggleRef={assigneeDropdownRef}
          >
            {/* <li key={'empty-assignee'} value="">Please, select employee</li> */}
            {props.users &&
              props.users
                .filter((item) => item.registered === 't')
                .map((user) => (
                  <li key={user.id} value={user.id}>
                    {user.name}
                  </li>
                ))}
          </Select>
        </FormElement>
      </div>
      <div>
        <FormElement className={styles.notes} htmlFor="notes" labelText="NOTES">
          <textarea disabled={disabled} className={styles.notes} rows="5" onChange={props.setData('notes')} value={props.question.notes} />
        </FormElement>
      </div>
    </form>
  );
};

export default Question;
