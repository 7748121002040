import ActionStatusConstants from 'helpers/ActionStatusConstants';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { LOCATION_CHANGE } from 'redux-first-history';
import { Audit } from 'types/Audit';

export interface IAuditState {
  status: string;
  message?: string;
  audits?: Audit[];
}

const initialState: IAuditState = {
  status: ActionStatusConstants.INITIAL,
};

export default (state = initialState, { type, payload }): IAuditState => {
  switch (type) {
    case ActionTypeConstants.LOAD_AUDITS: {
      return {
        ...state,
        status: ActionStatusConstants.ISBUSY,
      };
    }
    case ActionTypeConstants.LOAD_AUDITS_SUCCESS: {
      return {
        ...state,
        audits: payload?.audits,
        status: ActionStatusConstants.SUCCESS,
        message: payload?.message,
      };
    }
    case ActionTypeConstants.LOAD_AUDITS_FAILURE: {
      return {
        ...state,
        status: ActionStatusConstants.FAILURE,
        message: payload?.message,
      };
    }
    case LOCATION_CHANGE: {
      return {
        ...initialState,
      };
    }
    default:
  }
  return state;
};
