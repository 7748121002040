import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

/**
 * List item for navigation.
 */
function NavListItem(props) {
  const navItemClassNames = classNames('nav-item', { active: props.isActive === true, disabled: props.isDisabled === true }, props.className);

  return (
    <li className={navItemClassNames}>
      {props.path ? (
        props.path.includes('http') ? (
          <a className="nav-link" target="_blank" rel="noreferrer" href={props.path}>
            {props.title}
          </a>
        ) : (
          <Link to={props.path} className="nav-link" onClick={props.onClick}>
            {props.icon}
            <span className="label">{props.title}</span>
          </Link>
        )
      ) : (
        <span aria-hidden="true" className="nav-link" onClick={props.onClick}>
          {props.icon}
          <span className="label">{props.title}</span>
        </span>
      )}
    </li>
  );
}

export default NavListItem;
