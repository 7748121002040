import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { Button } from 'components/Button/Button';
import { loginRequest } from '../../../../Maintain/OrgSiteDept/MicrosoftAzureActiveDirectory/authConfig';
import { ssoLoginUser } from 'store/actions/Actions';
import { useAppDispatch } from 'hooks';

import styles from './MicrosoftLogInButtonSSO.module.scss';
import { EventType } from '@azure/msal-browser/dist/event/EventType';

interface IProps {
  loginEmail: string;
  setIsSsoUserMismatch: React.Dispatch<React.SetStateAction<boolean>>;
  isSsoUserMismatch: boolean;
  setSsoMessage?: React.Dispatch<React.SetStateAction<string>>;
}

export function MicrosoftLogInButton({ loginEmail, setIsSsoUserMismatch, isSsoUserMismatch }: IProps) {
  const { instance, accounts } = useMsal();
  const dispatch = useAppDispatch();
  const [accessToken, setAccessToken] = useState(null);

  useEffect(() => {
    if (accessToken) {
      dispatch(
        ssoLoginUser({
          provider_key: 'Azure',
          access_token: accessToken,
        })
      );
    }
  }, [accessToken]);

  useEffect(() => {
    if (loginEmail && accounts.length !== 0) {
      setIsSsoUserMismatch(accounts.findIndex((account) => account.username === loginEmail) === -1);
    }
  }, [loginEmail]);

  instance.addEventCallback((message) => {
    // This will be run every time an event is emitted after registering this callback
    if (message.eventType === EventType.LOGOUT_END) {
      if (accounts.length === 0) {
        setIsSsoUserMismatch(false);
      }
    }
  });

  const handleLogin = async () => {
    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    if (isSsoUserMismatch) {
      // E-mails mismatch
      instance.logoutPopup();
    } else {
      // E-mails match
      // Silently acquires an access token which is then attached to a request for Microsoft Graph data
      instance
        .acquireTokenSilent(request)
        .then((response) => {
          setAccessToken(response.accessToken);
        })
        .catch((e) => {
          instance.acquireTokenPopup(request).then((response) => {
            setAccessToken(response.accessToken);
          });
        });
    }
  };

  return (
    <Button className={styles['log-in-button']} text={isSsoUserMismatch ? 'Log out with Microsoft' : 'Log in with Microsoft'} onClick={handleLogin} icon="fa-brands fa-microsoft" />
  );
}

export default MicrosoftLogInButton;
