import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import ReactPlayer from 'react-player';

import Button from 'components/Button/Button';
import MainLayout from 'v2/layouts/MainLayout/MainLayout';

import { useAppDispatch, useAppSelector } from 'hooks';
import { loadFoldersAction, loadSitesAction } from 'store/actions/Actions';

import styles from './Player.module.scss';

const Player = () => {
  const dispatch = useAppDispatch();
  const folders = useAppSelector((state) => state.FoldersReducer.folders);
  const UserOrgSiteReducer = useAppSelector((state) => state.UserOrgSiteReducer);

  const is_admin = UserOrgSiteReducer && UserOrgSiteReducer.selected && UserOrgSiteReducer.selected.is_admin ? true : false;

  const { id } = useParams();
  const navigate = useNavigate();

  const [isActive, setActive] = useState(true);

  useEffect(() => {
    const vc = function (event) {
      if (window.document.hidden) {
        setActive(false);
      } else {
        setActive(true);
      }
    };
    window.document.addEventListener('visibilitychange', vc);
    return () => {
      window.document.removeEventListener('visibilitychange', vc);
    };
  });

  useEffect(() => {
    dispatch(loadFoldersAction({ id }));
  }, [id]);

  var getFolderIcon = function () {
    return is_admin ? (
      <div className={styles['path']}>
        <a href="/documents">
          <i className={`fal fa-folder-open ${styles['up']}`}></i>
        </a>{' '}
        {folders.path}
      </div>
    ) : (
      <div className={styles['path']}></div>
    );
  };

  return (
    <MainLayout>
      {folders && getFolderIcon()}

      <div className={styles['holder']}>
        {folders && (
          <div id="video" className={styles.video}>
            <div className={styles['close']}>
              <Button className={styles.button} text={'CLOSE'} icon="fal fa-times" onClick={() => navigate(-1)} size="md" />
            </div>

            {folders.url && (
              <ReactPlayer
                className={styles.video}
                height="auto"
                onProgress={(e) => isActive && dispatch(loadSitesAction())}
                progressInterval={1000 * 60 * 5}
                controls
                width="100%"
                style={{ maxWidth: 960 }}
                playing
                url={folders.url}
              />
            )}
          </div>
        )}
      </div>
    </MainLayout>
  );
};

export default Player;
