import React, { useState, useEffect } from 'react';

import Container from 'components/Container/Container';
import Row from 'components/Row/Row';
import FormElement from 'components/FormElement/FormElement';

import SelectWithSearch from 'components/SelectWithSearch/SelectWithSearch';

import HR from 'components/HR/HR';
import { Button } from 'components/Button/Button';
import { states, statesObj, country_list, showPassword, isEmailValid, isPasswordValid } from 'helpers/utils';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'hooks';

import { submitUserSettingsFormAction } from 'store/actions/Actions';
import PassowordValidation from 'components/PasswordValidation/PasswordValidation';
import Spinner from 'components/Spinner/Spinner';
import ActionStatusConstants from 'helpers/ActionStatusConstants';

import styles from './Profile.module.scss';

function Settings() {
  const dispatch = useAppDispatch();
  const SettingsReducer = useAppSelector((state) => state.SettingsReducer);
  const isSsoUser = SettingsReducer.user?.connected_to_sso || false;
  const [isLoading, setLoading] = useState(true);

  // Keep track of officer role state
  const [officerState, setOfficerState] = useState({
    active: 't',
    privacy: false,
    security: false,
    status: 'active',
    firstname: '',
    lastname: '',
    email: '',
    address_1: '',
    address_2: '',
    city: '',
    state: '',
    state_name: '',
    country_code: '',
    country_name: '',
    postcode: '',
    password: '',
    'confirm-password': '',
    phone: '',
    // showHints: true
  });

  useEffect(() => {
    const state_name = statesObj[officerState.state] || officerState.state;
    if (state_name) {
      setOfficerState((s) => ({
        ...s,
        state_name,
      }));
    }
  }, [officerState.state]);

  interface IStateErrors {
    firstname: string;
    lastname: string;
    email: string;
    address_1: string;
    address_2: string;
    city: string;
    state: string;
    state_name: string;
    postcode: string;
    password: string;
    country_name: string;
    'confirm-password': string;
    phone: string;
  }

  const [stateErrors, setStateErrors] = useState<IStateErrors | undefined>({
    firstname: undefined,
    lastname: undefined,
    email: undefined,
    address_1: undefined,
    address_2: undefined,
    city: undefined,
    state: undefined,
    state_name: undefined,
    postcode: undefined,
    password: undefined,
    country_name: undefined,
    'confirm-password': undefined,
    phone: undefined,
  });
  const [showPasswordValidation, setShowPasswordValidation] = useState(false);

  const [togglePasswordIcon, setTogglePasswordIcon] = useState(false);
  const [togglePasswordIconConfirm, setTogglePasswordIconConfirm] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (SettingsReducer.user) {
      setOfficerState({ ...SettingsReducer.user });
    }
  }, [SettingsReducer.user]);

  useEffect(() => {
    if (SettingsReducer.status === ActionStatusConstants.SUCCESS) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [SettingsReducer.user]);

  const handleChange = (inputName) => (e) => {
    e.persist();

    if (inputName === 'password') {
      if (e.target.value && showPasswordValidation === false) {
        setShowPasswordValidation(true);
      }

      if (!e.target.value && showPasswordValidation === true) {
        setShowPasswordValidation(false);
      }
    }

    setOfficerState((s) => ({
      ...s,
      [inputName]: e.target.value,
    }));
    setStateErrors((s) => ({ ...s, [inputName]: '' }));
  };

  const handleChangeSelectWithSearch = (type) => (data) => {
    let tmp = {};
    if (type === 'country_name') {
      tmp = {
        country_code: data?.id || '',
        country_name: data?.name || '',
      };
    }
    if (type === 'state_name') {
      tmp = {
        state: data?.id || '',
        state_name: data?.name || '',
      };
    }
    setOfficerState((s) => ({
      ...s,
      ...tmp,
    }));
    setStateErrors((s) => ({ ...s, [type]: '' }));
  };

  const handleSubmitEditForm = (e) => {
    e.preventDefault();
    if (
      (officerState.password && !isPasswordValid(officerState.password)) ||
      (officerState.password && officerState.password !== officerState['confirm-password']) ||
      officerState.firstname === '' ||
      officerState.lastname === '' ||
      officerState.email === '' ||
      !isEmailValid(officerState.email)
      // officerState.phone === "" ||
      // officerState.address_1 === "" ||
      // officerState.city === "" ||
      // officerState.state_name === ""
      // officerState.postcode === ""
    ) {
      setStateErrors({
        ...stateErrors,
        firstname: officerState.firstname === '' ? 'First name is required' : '',
        lastname: officerState.lastname === '' ? 'Last name is required' : '',
        password: officerState.password && !isPasswordValid(officerState.password) ? 'The password is not valid' : '',
        'confirm-password': officerState.password && officerState.password !== officerState['confirm-password'] ? "Passwords don't match" : '',
        email: !isEmailValid(officerState.email) ? 'Email is required' : '',
        // phone: officerState.phone === "" ? "Phone is required" : "",
        // address_1: officerState.address_1 === "" ? "Address is required" : "",
        // city: officerState.city === "" ? "City is required" : "",
        // state_name: officerState.state_name === "" ? "State is required" : "",
        // postcode: officerState.postcode === "" ? "Postal code is required" : "",
      });
    } else {
      dispatch(submitUserSettingsFormAction(officerState));
    }
  };

  const togglePasswordVisibility = (e, id, isPwdVisible, toggleSetter) => {
    e.preventDefault();
    toggleSetter(isPwdVisible);
    showPassword(id, isPwdVisible);
  };

  return (
    <Container>
      {isLoading ? (
        <div className={styles.loader}>
          <Spinner />
        </div>
      ) : undefined}
      <form className={styles['add-user-form']}>
        <div className={styles.holder}>
          <div className={styles.left}>
            <FormElement className={styles.form_element} labelText="FIRST NAME" htmlFor="firstname" smallText="*" errorMessage={stateErrors.firstname}>
              <input data-testid="firstname" name="firstname" type="text" placeholder="Enter your first name" value={officerState.firstname} onChange={handleChange('firstname')} />
            </FormElement>

            <FormElement className={styles.form_element} labelText="LAST NAME" htmlFor="lastname" smallText="*" errorMessage={stateErrors.lastname}>
              <input name="lastname" type="text" placeholder="Enter your last name" value={officerState.lastname} onChange={handleChange('lastname')} />
            </FormElement>

            <FormElement className={styles.form_element} labelText="EMAIL" htmlFor="email" smallText="*" errorMessage={stateErrors.email}>
              <input
                autoComplete="new-password"
                name="email"
                type="email"
                placeholder="Enter your email address"
                disabled={isSsoUser}
                value={officerState.email}
                onChange={handleChange('email')}
              />
            </FormElement>

            <FormElement className={styles.form_element} labelText="ADDRESS" htmlFor="address_1" errorMessage={stateErrors.address_1}>
              <input name="address_1" type="text" placeholder="Street and number" value={officerState.address_1} onChange={handleChange('address_1')} />
            </FormElement>

            <FormElement
              className={styles.form_element}
              // labelText="ADDRESS 2"
              htmlFor="address_2"
              errorMessage={stateErrors.address_2}
            >
              <input name="address_2" type="text" placeholder="Apartment, building, floor (optional)" value={officerState.address_2} onChange={handleChange('address_2')} />
            </FormElement>
          </div>

          <div className={styles.right}>
            {!isSsoUser && (
              <>
                <Row className={`${styles['form-group-row']} ${styles.form_element}`}>
                  <FormElement labelText="PASSWORD" htmlFor="password" errorMessage={stateErrors.password}>
                    <input
                      autoComplete="none"
                      name="password"
                      type="password"
                      placeholder="Enter your password"
                      defaultValue={officerState.password}
                      onChange={handleChange('password')}
                      className={styles.password}
                      id="pass-settings"
                    />
                    <span className={styles['show-password']}>
                      <button
                        type="button"
                        className={styles['password-button']}
                        onClick={(e) => togglePasswordVisibility(e, 'pass-settings', !togglePasswordIcon, setTogglePasswordIcon)}
                      >
                        <i className={togglePasswordIcon ? 'hide' : 'fas fa-eye'} title="show password" />
                        <i className={!togglePasswordIcon ? 'hide' : 'fas fa-eye-slash'} title="hide password" />
                      </button>
                    </span>
                  </FormElement>

                  <FormElement labelText="CONFIRM PASSWORD" htmlFor="confirm-password" errorMessage={stateErrors['confirm-password']}>
                    <input
                      name="confirm-password"
                      type="password"
                      placeholder="Confirm your password"
                      defaultValue={officerState['confirm-password']}
                      onChange={handleChange('confirm-password')}
                      className={styles.password}
                      id="pass-settings-confirm"
                    />
                    <span className={styles['show-password']}>
                      <button
                        type="button"
                        className={styles['password-button']}
                        onClick={(e) => togglePasswordVisibility(e, 'pass-settings-confirm', !togglePasswordIconConfirm, setTogglePasswordIconConfirm)}
                      >
                        <i className={togglePasswordIconConfirm ? 'hide' : 'fas fa-eye'} title="show password" />
                        <i className={!togglePasswordIconConfirm ? 'hide' : 'fas fa-eye-slash'} title="hide password" />
                      </button>
                    </span>
                  </FormElement>
                </Row>

                {showPasswordValidation && !isPasswordValid(officerState.password) ? <PassowordValidation password={officerState.password} /> : ''}
              </>
            )}

            <FormElement className={`${styles.form_element} ${!isSsoUser && styles.form_element_contact_phone}`} errorMessage={stateErrors.phone}>
              <label htmlFor="phone">CONTACT PHONE</label>
              <Row className={styles['form-group-row']}>
                <input className={styles['contact-phone-input']} name="phone" type="tel" value={officerState.phone} onChange={handleChange('phone')} />
              </Row>
            </FormElement>

            <FormElement className={styles.form_element} labelText="COUNTRY" htmlFor="country_name" errorMessage={stateErrors.country_name}>
              <SelectWithSearch placeholder="Country" value={officerState.country_code} onChange={handleChangeSelectWithSearch('country_name')} options={country_list} />
            </FormElement>

            <Row className={`${styles['form-group-row']} ${styles['city-state-postcode']} ${styles.form_element}`}>
              <FormElement labelText="CITY" htmlFor="city" errorMessage={stateErrors.city}>
                <input name="city" type="text" placeholder="City" value={officerState.city} onChange={handleChange('city')} />
              </FormElement>

              <FormElement labelText="STATE / REGION" htmlFor="state" errorMessage={stateErrors.state_name}>
                <SelectWithSearch
                  placeholder="State"
                  value={officerState.state}
                  onChange={handleChangeSelectWithSearch('state_name')}
                  options={states.map((item) => ({ ...item, id: item.abbrev }))}
                />
              </FormElement>

              <FormElement labelText="POSTAL CODE" htmlFor="postcode" errorMessage={stateErrors.postcode}>
                <input name="postcode" type="text" placeholder="Postal Code" value={officerState.postcode} onChange={handleChange('postcode')} />
              </FormElement>
            </Row>
          </div>
        </div>

        <HR className={styles.hr} />

        <div className={styles['submit-box']}>
          <Button text="Cancel" color="secondary" onClick={() => navigate('/')} />
          &nbsp;
          <Button icon="far fa-save" text="Save" onClick={handleSubmitEditForm} />
        </div>
      </form>
    </Container>
  );
}

export default Settings;
