import React from 'react';
import styles from './Stepper.module.scss';

/**
 * Dispaly steps with active step
 * @param {Number} active - intger for current active step
 */
const Stepper = (props) => {
  const { steps } = props;
  let active = -1;
  let stages = [];
  steps.forEach((element) => {
    stages.push(element.label);
    if (element.active) {
      active = `${parseInt(element.step, 10) - 1}`;
    }
  });

  const list = [];
  for (let index = 0; index < steps.length; index++) {
    const element = {};
    element.id = index;
    element.title = stages[index];
    element.status = 'completed';
    element.value = index + 1;

    if (parseInt(active, 10) + 1 === parseInt(index, 10)) {
      element.status = 'active';
    }
    if (parseInt(index, 10) > parseInt(active, 10) + 1) {
      element.status = 'disabled';
    }
    list.push(element);
  }
  return (
    <div className={styles.stepper}>
      {list.map((item) => (
        <div key={`stepper-${item.id}`} className={`${styles['stepper-item']} ${styles[item.status]}`}>
          <div className={styles['stepper-circle']}>{item.status === 'completed' ? <i className="fas fa-check" /> : item.value}</div>
          <div className={styles['stepper-line']} />
          <div>{item.title}</div>
        </div>
      ))}
    </div>
  );
};

export default Stepper;
