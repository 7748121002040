/**
 * All application routes
 */
export const RouteDefinitions = {
  // Generic
  LOGIN: {
    key: 'LOGIN',
    path: '/login',
    classname: 'login',
    title: 'Login',
  },
  REGISTER: {
    key: 'REGISTER',
    path: '/login/register',
    classname: 'register',
    title: 'Account Registration',
    icon: undefined,
  },
  FORGOT_PASSWORD: {
    key: 'FORGOT_PASSWORD',
    path: '/login/forgot-password',
    classname: 'forgot-password',
    title: 'Password Recovery',
    icon: undefined,
  },
  RESET_PASSWORD: {
    key: 'RESET_PASSWORD',
    path: '/login/reset-password',
    classname: 'reset-password',
    title: 'Reset Password',
  },

  // Dashboard
  DASHBOARD: {
    key: 'DASHBOARD',
    path: '/',
    classname: 'dashboard',
    title: 'Dashboard',
    icon: 'fad fa-tachometer-fast',
  },

  // Achieve
  ACHIEVE: {
    key: 'ACHIEVE',
    path: '/achieve',
    classname: 'achieve',
    title: 'Achieve',
    icon: 'fad fa-bullseye-pointer',
  },
  ACHIEVE_AUDITS: {
    key: 'ACHIEVE_AUDITS',
    path: '/achieve/audits',
    classname: 'achieve-audits',
    title: 'Audits',
  },
  ACHIEVE_AUDITS_AUDIT: {
    key: 'ACHIEVE_AUDITS_AUDIT',
    path: '/achieve/audits/audit',
    classname: 'achieve-audits-audit',
    title: 'Audit',
    hidden: true,
  },
  ACHIEVE_AUDITS_AUDIT_DEVICE: {
    key: 'ACHIEVE_AUDITS_AUDIT_DEVICE',
    path: '/achieve/audits/audit-device',
    classname: 'achieve-audits-audit-device',
    title: 'Device Audit',
    hidden: true,
  },
  ACHIEVE_AUDITS_AUDIT_CLOUD: {
    key: 'ACHIEVE_AUDITS_AUDIT_CLOUD',
    path: '/achieve/audits/audit-cloud',
    classname: 'achieve-audits-audit-cloud',
    title: 'Cloud Audit',
    hidden: true,
  },
  ACHIEVE_AUDITS_AUDIT_INVENTORY: {
    key: 'ACHIEVE_AUDITS_AUDIT_INVENTORY',
    path: '/achieve/audits/audit-inventory',
    classname: 'achieve-audits-audit-inventory',
    title: 'Device Inventory Audit',
    hidden: true,
  },
  ACHIEVE_REMEDIATION: {
    key: 'ACHIEVE_REMEDIATION',
    path: '/achieve/remediation',
    classname: 'achieve-remediation',
    title: 'Remediation',
  },
  ACHIEVE_REMEDIATION_ADD: {
    key: 'ACHIEVE_REMEDIATION_ADD',
    path: '/achieve/remediation/add',
    classname: 'achieve-remediation',
    title: 'Add',
    hidden: true, // used to hide this option in the menus
  },
  ACHIEVE_REMEDIATION_EDIT: {
    key: 'ACHIEVE_REMEDIATION_EDIT',
    path: '/achieve/remediation/edit',
    classname: 'achieve-remediation',
    title: 'Edit',
    hidden: true,
  },
  ACHIEVE_AFFILIATES: {
    key: 'ACHIEVE_AFFILIATES',
    path: '/achieve/affiliates',
    classname: 'achieve-affiliates',
    title: 'Vendors',
  },
  ACHIEVE_AFFILIATES_EDIT: {
    key: 'ACHIEVE_AFFILIATES_EDIT',
    path: '/achieve/affiliates/edit',
    classname: 'achieve-affiliates-edit',
    title: 'Edit Vendor',
    hidden: true,
  },
  ACHIEVE_AFFILIATES_ADD: {
    key: 'ACHIEVE_AFFILIATES_ADD',
    path: '/achieve/affiliates/add',
    classname: 'achieve-affiliates-add',
    title: 'Add Vendor',
    hidden: true,
  },
  ACHIEVE_DOCUMENTS: {
    key: 'ACHIEVE_DOCUMENTS',
    path: '/achieve/documents',
    classname: 'achieve-documents',
    title: 'Documents',
  },
  ACHIEVE_DOCUMENTS_UNAPPROVED: {
    key: 'ACHIEVE_DOCUMENTS_UNAPPROVED',
    path: '/achieve/documents/unapproved',
    classname: 'achieve-documents',
    title: 'Unapproved',
  },
  ACHIEVE_TRAINING: {
    key: 'ACHIEVE_TRAINING',
    path: '/achieve/training',
    classname: 'achieve-training',
    title: 'Training',
  },
  ACHIEVE_TRAINING_EDIT: {
    key: 'ACHIEVE_TRAINING_EDIT',
    path: '/achieve/training/edit',
    classname: 'achieve-training-edit',
    title: 'Training Edit',
    hidden: true,
  },
  ACHIEVE_TASKS: {
    key: 'ACHIEVE_TASKS',
    path: '/achieve/tasks',
    classname: 'achieve-tasks',
    title: 'Tasks',
  },
  ACHIEVE_TASKS_ADD: {
    key: 'ACHIEVE_TASKS_ADD',
    path: '/achieve/tasks/add',
    classname: 'achieve-tasks-add',
    title: 'Add New Task',
    hidden: true,
  },

  // Illustrate
  ILLUSTRATE: {
    key: 'ILLUSTRATE',
    path: '/illustrate',
    classname: 'illustrate',
    title: 'Illustrate',
    icon: 'fad fa-bezier-curve',
  },
  ILLUSTRATE_REPORTS: {
    key: 'ILLUSTRATE_REPORTS',
    path: '/illustrate/reports',
    classname: 'illustrate-reports',
    title: 'Reports',
    icon: 'fas fa-file-pdf',
  },
  ILLUSTRATE_CERTIFICATES: {
    key: 'ILLUSTRATE_CERTIFICATES',
    path: '/illustrate/certificates',
    classname: 'illustrate-certificates',
    title: 'Certificates',
  },

  // Maintain
  MAINTAIN: {
    key: 'MAINTAIN',
    path: '/maintain',
    classname: 'maintain',
    title: 'Maintain',
    icon: 'fad fa-cogs',
  },
  MAINTAIN_INCIDENTS: {
    key: 'MAINTAIN_INCIDENTS',
    path: '/maintain/incidents',
    classname: 'maintain-incidents',
    title: 'Incidents',
  },
  MAINTAIN_INCIDENTS_EDIT: {
    key: 'MAINTAIN_INCIDENTS_INCIDENT_MANAGER',
    path: '/maintain/incidents/edit',
    classname: 'maintain-incident-edit',
    title: 'Incident Manager',
    hidden: true,
  },
  MAINTAIN_INCIDENTS_ADD_NEW_INCIDENT: {
    key: 'MAINTAIN_INCIDENTS_ADD_NEW_INCIDENT',
    path: '/maintain/incidents/add-new-incident',
    classname: 'maintain-add-new-incident',
    title: 'Add New Incident',
    hidden: true,
  },
  // MAINTAIN_ALERTS: {
  //     key: "MAINTAIN_ALERTS",
  //     path: "/maintain/alerts",
  //     classname: "maintain-alerts",
  //     title: "Alerts",
  //     icon: null
  // },
  MAINTAIN_ORG_SITE_DEPT: {
    key: 'MAINTAIN_ORG_SITE_DEPT',
    path: '/maintain/organization',
    classname: 'maintain-organization',
    title: 'Organization',
  },
  MAINTAIN_USERS: {
    key: 'MAINTAIN_USERS',
    path: '/maintain/users',
    classname: 'maintain-users',
    title: 'Users',
  },
  MAINTAIN_UNASSIGNED: {
    key: 'MAINTAIN_UNASSIGNED',
    path: '/maintain/organization/unassigned',
    classname: 'maintain-users',
    title: 'Unassigned Users',
  },
  MAINTAIN_USERS_ADD: {
    key: 'MAINTAIN_USERS_ADD',
    path: '/maintain/users/add',
    classname: 'maintain-users',
    title: 'Add',
    hidden: true,
  },
  MAINTAIN_USERS_ADD_BULK: {
    key: 'MAINTAIN_USERS_ADD_BULK',
    path: '/maintain/users/add_bulk',
    classname: 'maintain-users',
    title: 'Add Bulk',
    hidden: true,
  },
  MAINTAIN_USERS_EDIT: {
    key: 'MAINTAIN_USERS_EDIT',
    path: '/maintain/users/edit',
    classname: 'maintain-users',
    title: 'Edit',
    hidden: true,
  },
  SETTINGS: {
    key: 'SETTINGS',
    path: '/settings',
    classname: 'settings',
    title: 'Settings',
    hidden: true,
  },
};

/**
 * Assembles a list of child routes depending on key
 */
const getMainNavItemChildren = (mainNavItem) => {
  const mainNavItemChildren = [];

  for (const key in RouteDefinitions) {
    if (Object.prototype.hasOwnProperty.call(RouteDefinitions, key) && key.startsWith(`${mainNavItem.key}_`)) {
      mainNavItemChildren.push({
        routeDefinition: RouteDefinitions[key],
      });
    }
  }

  return mainNavItemChildren;
};

/**
 * Main navigation tree used for menu generation
 */
export const MainNavigationTree = [
  {
    routeDefinition: RouteDefinitions.DASHBOARD,
    children: getMainNavItemChildren(RouteDefinitions.DASHBOARD),
  },
  {
    routeDefinition: RouteDefinitions.ACHIEVE,
    children: getMainNavItemChildren(RouteDefinitions.ACHIEVE),
  },
  {
    routeDefinition: RouteDefinitions.ILLUSTRATE,
    children: getMainNavItemChildren(RouteDefinitions.ILLUSTRATE),
  },
  {
    routeDefinition: RouteDefinitions.MAINTAIN,
    children: getMainNavItemChildren(RouteDefinitions.MAINTAIN),
  },
];

/**
 * Get route definition for the given path
 */
export const getDefByPathname = (pathname) => {
  if (!(pathname && pathname.length > 0)) {
    return false;
  }
  let def;
  for (const key in RouteDefinitions) {
    if (Object.prototype.hasOwnProperty.call(RouteDefinitions, key) && RouteDefinitions[key].path === pathname) {
      def = RouteDefinitions[key];
      break;
    }
  }
  return def;
};

/**
 * Get route definition for given path's parent
 */
export const getParent = (def) => {
  if (!def && !def.path) {
    return false;
  }
  if (!def.path.includes('/')) {
    return false;
  }
  const parentPath = def.path.slice(0, Math.max(0, def.path.lastIndexOf('/')));
  let parentDef;
  for (const key in RouteDefinitions) {
    if (Object.prototype.hasOwnProperty.call(RouteDefinitions, key) && RouteDefinitions[key].path === parentPath) {
      parentDef = RouteDefinitions[key];
      break;
    }
  }
  return parentDef;
};

/**
 * Build a breadcrumb chain list from the current definition up to the root
 */
export const getBreadcrumbChain = (def) => {
  let defChain = [];
  let currentDef = def;
  while (currentDef) {
    defChain.push(currentDef);
    currentDef = getParent(currentDef);
  }
  defChain = defChain && defChain.length > 0 ? defChain.reverse() : undefined;
  return defChain;
};

/**
 * Select subNavItem
 * @param {String} path - this.props.location.pathname
 * @param {Number} level - how many levels to return
 */
export const getActivePath = (path, level = 1) => {
  // If at dashboard, return it
  if (path === '/') {
    return MainNavigationTree[0];
  }
  const activePath = path !== '/' ? path.split('/') : '/';

  // Get main nav item
  if (level === 1) {
    switch (activePath[1]) {
      case 'achieve': {
        return MainNavigationTree[1];
      }
      case 'illustrate': {
        return MainNavigationTree[2];
      }
      case 'maintain': {
        return MainNavigationTree[3];
      }
      default: {
        return MainNavigationTree[1];
      }
    }
  }
  // Get second level nav item
  if (level === 2) {
    return getDefByPathname(`/${activePath[1]}/${activePath[2]}`);
  }
  return false;
};
