import ColVisibilitySelector from 'components/DataTable/ColVisibilitySelector';
import ItemActionsMenu from 'components/DataTable/ItemActionsMenu';
import React from 'react';
import Checkbox from 'components/Checkbox/Checkbox';
// import moment from 'moment';
import Button from 'components/Button/Button';
import moment from 'moment';

export default {
  TABLE: {
    className: 'data-table-audits',
    initialSortKey: 'name',
    initialSortOrder: 'asc',
  },

  COLUMN: [
    {
      label: 'Row Selector',
      key: 'row-selector',
      header: ({ customTableProps }) => {
        const isSelectedAll =
          customTableProps.selectedRows &&
          customTableProps.selectedRows.length &&
          customTableProps.rowsData &&
          customTableProps.rowsData.length &&
          customTableProps.selectedRows.length === customTableProps.rowsData.length
            ? true
            : false;
        return <Checkbox checked={isSelectedAll} onChange={() => customTableProps.doSelectAll()} name="row-selector" />;
      },
      className: 'col-row-selector',
      format: ({ rowData, customTableProps }) => {
        const isSelected =
          customTableProps.selectedRows &&
          customTableProps.selectedRows.length &&
          customTableProps.selectedRows.some((checkbox) => {
            return checkbox.id === rowData.id;
          })
            ? true
            : false;
        return <Checkbox checked={isSelected} onChange={() => customTableProps.onRowSelect({ rowData })} name="row-selector" />;
      },
      canBeHidden: false,
    },
    {
      label: 'Name',
      header: 'ORGANIZATION NAME',
      key: 'name',
      sortable: true,
      className: 'name',
      format: ({ rowData, customTableProps }) => {
        return <span>{rowData['name']}</span>;
      },
    },
    {
      label: 'Total Users',
      header: '# OF USERS',
      key: 'number_of_users',
      sortable: true,
      canBeHidden: true,
      className: 'type',
      format: ({ rowData }) => {
        return <span>{rowData['number_of_users']}</span>;
      },
    },
    {
      label: 'Total Sites',
      header: '# OF SITES',
      key: 'number_of_sites',
      sortable: true,
      canBeHidden: true,
      className: 'type',
      format: ({ rowData }) => {
        return <span>{rowData['number_of_sites']}</span>;
      },
    },
    {
      label: 'Coach Name',
      header: 'COACH NAME',
      key: 'coach_name',
      sortable: true,
      canBeHidden: true,
      className: 'type',
      format: ({ rowData }) => {
        return <span>{rowData['coach_name']}</span>;
      },
    },
    {
      label: 'Seal Issue Date',
      header: 'SEAL ISSUE DATE',
      key: 'seal_granted_at',
      sortable: true,
      canBeHidden: true,
      className: 'type',
      format: ({ rowData }) => {
        return <span>{rowData.seal_granted_at ? moment(rowData.seal_granted_at).format('MM/DD/YYYY') : '-'}</span>;
      },
    },
    {
      label: 'Seal Expire Date',
      header: 'SEAL EXPIRE DATE',
      key: 'seal_expires_at',
      sortable: true,
      canBeHidden: true,
      className: 'type',
      format: ({ rowData }) => {
        return <span>{rowData.seal_expires_at ? moment(rowData.seal_expires_at).format('MM/DD/YYYY') : '-'}</span>;
      },
    },
    {
      label: 'Tasks',
      header: 'TASKS %',
      key: 'completed_tasks_perc',
      sortable: true,
      canBeHidden: true,
      className: 'type',
      format: ({ rowData }) => {
        return <span>{`${rowData['ctp']}% (${rowData['completed_tasks']}/${rowData['total_tasks']})`}</span>;
      },
    },
    {
      label: 'Audits',
      header: 'AUDITS %',
      key: 'completed_audits_perc',
      sortable: true,
      canBeHidden: true,
      className: 'type',
      format: ({ rowData }) => {
        return <span>{`${rowData['cap']}% (${rowData['finalized_audits']}/${rowData['total_audits']})`}</span>;
      },
    },
    {
      label: 'Status',
      header: 'STATUS',
      key: 'active',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => (
        <span>
          <Button color={rowData['active'] === 't' ? 'success' : 'secondary'} size="md" text={rowData['active'] === 't' ? 'ACTIVE' : 'INACTIVE'} onClick={() => null} />
        </span>
      ),
    },
    {
      label: 'Actions',
      key: 'actions',
      format: ({ rowData, customTableProps }) => {
        const actionMenuDropDown = React.createRef();
        return (
          <ItemActionsMenu
            ref={actionMenuDropDown}
            actions={(() => {
              return [];
            })()}
          />
        );
      },
      className: 'col-actions',
      header: ({ customTableProps }) => {
        return (
          <ColVisibilitySelector
            colDefs={customTableProps.colDefs}
            visibleColumnKeys={customTableProps.visibleColumnKeys}
            onChangeVisibility={(colKey) => customTableProps.onChangeVisibility(colKey)}
          />
        );
      },
    },
  ],
};
