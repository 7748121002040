import React from 'react';
import MainLayout from 'v2/layouts/MainLayout/MainLayout';

import Tabs from 'v2/components/Tabs/Tabs';

function Trainings() {
  return (
    <MainLayout>
      <Tabs
        tabs={[
          {
            title: 'Trainings',
            path: '',
          },
          {
            title: 'Assignments',
            path: 'assignments',
          },
        ]}
      />
    </MainLayout>
  );
}

export default Trainings;
