import { call, put, takeLatest, delay, select } from 'redux-saga/effects';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { actionApiCall } from 'helpers/api/ApiCalls';
import * as sagaConstants from 'helpers/constants/SagasConstants';

const getSelected = (state) => state.UserOrgSiteReducer.selected;

function* deleteAuditSaga({ payload }) {
  try {
    const selected = yield select(getSelected);
    const apiResponse = yield call(actionApiCall, {
      actionType: ActionTypeConstants.DELETE_AUDIT,
      data: {
        organization: selected.org,
        items: payload.items,
      },
    });
    if (apiResponse.res.data.response === 200) {
      yield put({
        type: ActionTypeConstants.DELETE_AUDIT_SUCCESS,
      });
      yield put({
        type: ActionTypeConstants.LOAD_AUDITS,
        payload: payload.assessments ? { assessments: true } : undefined
      });
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: apiResponse.res.data.message || `The audit ${sagaConstants.SUCCESSFULLY_DELETED}`,
          color: 'success',
        },
      });
      yield delay(10_000);
      yield put({ type: ActionTypeConstants.HIDE_ALERT });
    } else {
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: apiResponse.res.data.message || `${sagaConstants.ISSUE_OCCURED} deleting the audit`,
          color: 'danger',
        },
      });
      yield delay(10_000);
      yield put({ type: ActionTypeConstants.HIDE_ALERT });
    }
  } catch {
    yield put({
      type: ActionTypeConstants.SHOW_ALERT,
      payload: {
        message: `${sagaConstants.SERVER_ERROR}`,
        color: 'danger',
      },
    });
    yield delay(10_000);
    yield put({ type: ActionTypeConstants.HIDE_ALERT });
  }
}

export default function* DeleteAuditSaga() {
  yield takeLatest(ActionTypeConstants.DELETE_AUDIT, deleteAuditSaga);
}
