import { call, put, takeLatest, delay, select } from 'redux-saga/effects';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { actionApiCall } from 'helpers/api/ApiCalls';

const getSelected = (state) => state.UserOrgSiteReducer.selected;

function* deleteReportSaga({ payload }) {
  try {
    if (payload.length === 0) {
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: `You need to select a report first!`,
          color: 'danger',
        },
      });
      yield delay(10000);
      yield put({ type: ActionTypeConstants.HIDE_ALERT });
      return;
    }

    const selected = yield select(getSelected);
    const apiResponse = yield call(actionApiCall, {
      actionType: ActionTypeConstants.DELETE_REPORT,
      data: {
        organization: selected.org,
        site: selected.site,
        items: payload,
      },
    });
    if (apiResponse) {
      yield put({ type: ActionTypeConstants.DELETE_REPORT_SUCCESS });
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: `Report${payload.length > 0 && 's'} deleted!`,
          color: 'success',
        },
      });
      yield put({ type: ActionTypeConstants.LOAD_REPORTS });
      yield delay(10000);
      yield put({ type: ActionTypeConstants.HIDE_ALERT });
    } else {
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: `An issue occured - Report${payload.length > 0 && 's'} not deleted!`,
          color: 'danger',
        },
      });
      yield delay(10000);
      yield put({ type: ActionTypeConstants.HIDE_ALERT });
    }
  } catch (err) {
    yield put({
      type: ActionTypeConstants.SHOW_ALERT,
      payload: {
        message: `An issue occured - Report${payload.length > 0 && 's'} not deleted!`,
        color: 'danger',
      },
    });
    yield delay(10000);
    yield put({ type: ActionTypeConstants.HIDE_ALERT });
  }
}

export default function* () {
  yield takeLatest(ActionTypeConstants.DELETE_REPORT, deleteReportSaga);
}
