import React from 'react';
import moment from 'moment';

import styles from './DashboardViews.module.scss';

function TaskFinalize(props) {
  const { task } = props;

  return (
    <div className={styles.question}>
      <div className={styles.title_holder}>
        <i className={`fal fa-check-circle ${styles.icon}`} />
        <h3>Task Finalized</h3>
        <p>You have completed the task.</p>
      </div>

      <div className={styles.task_holder}>
        <div>
          <div>Task name:</div>
          <div className={styles.title}>{task.task}</div>
        </div>

        {task.notes && (
          <div>
            <div>Notes:</div>
            <div className={styles.notes}>{task.notes}</div>
          </div>
        )}

        <div>
          <div>Requested date:</div>
          <div>{task.assigned ? moment(task.assigned).format('MM/DD/YYYY') : ''}</div>
        </div>

        <div>
          <div>Type:</div>
          <div>Miscellaneous Task</div>
        </div>

        {task.siteName && (
          <div>
            <div>Site:</div>
            <div>{task.siteName}</div>
          </div>
        )}
      </div>
    </div>
  );
}

export default TaskFinalize;
