import React, { useState } from 'react';
import Modal from 'components/Modal/Modal';
import FormElement from 'components/FormElement/FormElement';
import { Button } from 'components/Button/Button';
import { useAppDispatch } from 'hooks';
import { createSiteAction } from 'store/actions/Actions';
import styles from './AddSiteModal.module.scss';

function AddSiteModal(props) {
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState({
    name: '',
    address_1: '',
    address_2: '',
    city: '',
    region: '',
    postal_code: '',
    country_code: 235,
    active: true,
  });

  const [stateErrors, setStateErrors] = useState({
    name: '',
  });

  const handleChange = (e) => {
    e.persist();
    setFormData((s) => ({
      ...s,
      name: e.target && e.target.value ? e.target.value : '',
    }));
  };

  const addModal = () => {
    if (formData.name !== '') {
      dispatch(createSiteAction(formData));
      props.setShowModal(false);
      return false;
    }
    setStateErrors((s) => ({
      ...s,
      name: 'Site name is required',
    }));
    return false;
  };

  return (
    <Modal setShowModal={props.setShowModal}>
      <Modal.Heading>Create new site</Modal.Heading>

      <Modal.Content>
        <FormElement labelText="SITE" htmlFor="name" errorMessage={stateErrors.name} smallText="*">
          <input placeholder="Site Name" name="name" type="text" maxLength={70} value={formData.name} onChange={handleChange} />
        </FormElement>
      </Modal.Content>

      <Modal.Footer>
        <Button text="CANCEL" color="secondary" onClick={() => props.setShowModal(false)} />
        <Button text="CREATE" className={styles.create} onClick={addModal} />
      </Modal.Footer>
    </Modal>
  );
}

export default AddSiteModal;
