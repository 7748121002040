import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import styles from './EditAuditModal.module.scss';

import Modal from 'components/Modal/Modal';
import FormElement from 'components/FormElement/FormElement';
import Select from 'components/Select/Select';
import Checkbox from 'components/Checkbox/Checkbox';
import DateInput from 'components/DateInput/DateInput';

import Button from 'components/Button/Button';
import { loadActsAction, loadSitesAction, editAuditAction, loadAuditAction } from 'store/actions/Actions';

const EditAuditModal = (props) => {
  const [state, setState] = useState({
    active: 't',
    regulatory_act: 'HIPAA',
    act: '',
    site: '',
    name: '',
    org_wide: false,
    reset: false,
    launched: false,
    frequency: '0',
    lock_tasks: false,
    launch_date: '',
    finalized: false,
  });

  const [stateErrors, setStateErrors] = useState({
    act: '',
    site: '',
    regulatory_act: '',
    audit_name: '',
    launch_date: '',
  });

  const { id, SitesReducer, ActsReducer, OrgModulesReducer, AuditReducer, UserOrgSiteReducer, loadSitesAction, loadActsAction, loadAuditAction } = props;

  useEffect(() => {
    if (!SitesReducer.sites) {
      loadSitesAction();
    }
  }, [SitesReducer.sites, loadSitesAction]);

  useEffect(() => {
    if (!ActsReducer.acts) {
      loadActsAction();
    }
  }, [ActsReducer.acts, loadActsAction]);

  useEffect(() => {
    loadAuditAction(id);
  }, [id]);

  useEffect(() => {
    if (AuditReducer.audit) {
      setState({
        active: AuditReducer.audit.active,
        finalized: AuditReducer.audit.finalized,
        regulatory_act: AuditReducer.audit.regulatory_act,
        act: '',
        site: AuditReducer.audit.site ? AuditReducer.audit.site : '',
        name: AuditReducer.audit.name,
        org_wide: false,
        launched: AuditReducer.audit.configuration.launched,
        reset: AuditReducer.audit.configuration.reset,
        frequency: AuditReducer.audit.configuration.frequency,
        lock_tasks: AuditReducer.audit.configuration.lock_tasks,
        launch_date: AuditReducer.audit.launch_date,
      });
    }
  }, [AuditReducer.audit]);

  // const isOrgWide = (actId) => {
  //     try {
  //         if (ActsReducer.acts && actId && ActsReducer.acts.filter(item => item.id === `${actId}`)[0].org_wide === "t") {
  //             return true;
  //         }
  //     } catch (error) {
  //         console.log('error isOrgWide');
  //     }
  //     return false;
  // }

  const addModal = (e) => {
    if (!state.launch_date) {
      setStateErrors((s) => ({
        ...s,
        // site: state.site === "" ? "Site is required" : "",
        // regulatory_act: state.regulatory_act === "" ? "Regulatory Act is required" : "",
        // act: state.act === "" ? "Audit is required" : "",
        launch_date: !state.launch_date ? 'Launch date is required' : '',
      }));
    } else {
      props.editAuditAction({
        id: id,
        launch_date: state.launch_date,
        configuration: {
          frequency: state.frequency,
          lock_tasks: state.frequency == 0 ? false : state.lock_tasks,
        },
        assessments: true,
      });
      props.setShowModal(false);
    }
  };

  const handleChange = (inputName) => (e) => {
    e.persist();
    setState((s) => ({
      ...s,
      [inputName]: e.target.value,
    }));
    setStateErrors((s) => ({ ...s, [inputName]: '' }));
  };

  const handleActSelect = (e) => {
    e.persist();
    setState((s) => ({
      ...s,
      act: e.target.value,
      name: e.target.textContent,
    }));
    setStateErrors((s) => ({ ...s, act: '', site: '', regulatory_act: '' }));
  };

  const handleSiteSelect = (e) => {
    e.persist();
    setState((s) => ({
      ...s,
      site: e.target.value,
    }));
    setStateErrors((s) => ({ ...s, site: '' }));
  };

  const handleRegSelect = (inputName) => (e) => {
    e.persist();
    setState((s) => ({
      ...s,
      [inputName]: e.target.attributes && e.target.attributes.value ? e.target.attributes.value.value : '',
      act: '',
      site: '',
      name: '',
    }));
    setStateErrors((s) => ({ ...s, [inputName]: '', type: '' }));
  };

  const sitesList = {};
  sitesList[0] = 'All';
  SitesReducer.sites &&
    SitesReducer.sites.forEach((site) => {
      sitesList[site.id] = site.name;
    });

  const actsList = {};
  ActsReducer.acts &&
    ActsReducer.acts.forEach((act) => {
      actsList[act.id] = act.name;
    });

  const regTypeRef = React.createRef();

  const frequencyListLabels = {
    0: 'One Time',
    1: 'Monthly',
    3: 'Quarterly',
    6: 'Biannually',
    12: 'Annually',
  };

  const handleCheckbox = (inputName) => (e) => {
    e.persist();
    setState((s) => ({
      ...s,
      [inputName]: e.target.checked,
    }));
  };

  return (
    <Modal className={styles.modal} setShowModal={props.setShowModal}>
      <p className={styles['modal-heading']}>Edit Audit</p>
      <div className={styles['modal-content']}>
        {ActsReducer.acts && (
          <>
            <FormElement labelText="PROGRAM" htmlFor="regulatory_act" errorMessage={stateErrors['regulatory_act'] || ''}>
              <Select disabled={true} placeholder="Select regulatory act" value={state.regulatory_act} onClick={handleRegSelect('regulatory_act')} dropdownToggleRef={regTypeRef}>
                {OrgModulesReducer &&
                  OrgModulesReducer.data &&
                  OrgModulesReducer.data.map((d) => (
                    <li key={`regid-${d.name.toLowerCase()}`} value={d.name}>
                      {d.name}
                    </li>
                  ))}
              </Select>
            </FormElement>

            <br />

            <FormElement className={styles['regulatory-act']} labelText="AUDIT" htmlFor="regulatory-act" errorMessage={stateErrors.act}>
              <Select disabled={true} value={state.name} placeholder="Select audit" onClick={handleActSelect} labels={actsList} dropdownToggleRef={props.actDropdownRef || null}>
                <li key={`actid-0`} value={'0'}>
                  {state.name}
                </li>
                {/* {ActsReducer.acts && ActsReducer.acts.filter(a => a.regulatory_act === state.regulatory_act).map(act => <li key={`actid-${act.id}`} value={act.id}>{act.name}</li>)} */}
              </Select>
            </FormElement>

            <br />

            <>
              <FormElement className={styles['site']} labelText="SITE" htmlFor="site" errorMessage={stateErrors.site}>
                <Select
                  disabled={true}
                  value={state.site !== '' ? state.site : 'All sites'}
                  placeholder="Site"
                  onClick={handleSiteSelect}
                  labels={sitesList}
                  dropdownToggleRef={props.siteDropdownRef || null}
                >
                  {UserOrgSiteReducer.selected && !UserOrgSiteReducer.selected.site && (
                    <li key={`siteid-all`} value={0}>
                      All
                    </li>
                  )}
                  {SitesReducer.sites &&
                    SitesReducer.sites.map((site) => (
                      <li key={`siteid-${site.id}`} value={site.id}>
                        {site.name}
                      </li>
                    ))}
                </Select>
              </FormElement>
              <br />
            </>
          </>
        )}

        {state.site === 0 && (
          <>
            <FormElement
              className={styles['siteAll']}
              // labelText="SITE"
              htmlFor="site"
              errorMessage={stateErrors.site}
            >
              <Checkbox
                onChange={() => setState((s) => ({ ...s, org_wide: !state.org_wide }))}
                checked={state.org_wide}
                className={styles['complete']}
                labelText="Organization-wide"
              />
            </FormElement>
            <br />
          </>
        )}

        <div className={styles.holder}>
          <FormElement labelText="FREQUENCY" htmlFor="frequency">
            <Select disabled={state.reset || state.launched} value={state.frequency} name="frequency" onClick={handleChange('frequency')} labels={frequencyListLabels}>
              {Object.entries(frequencyListLabels).map((frequency) => (
                <li key={frequency[0]} value={frequency[0]}>
                  {frequency[1]}
                </li>
              ))}
            </Select>
          </FormElement>

          <FormElement labelText="LAUNCH DATE" htmlFor="launch_date" className={styles['launch-date']} errorMessage={stateErrors.launch_date}>
            <DateInput disabled={state.reset || state.launched} onChange={handleChange('launch_date')} value={state.launch_date || ''} />
          </FormElement>
        </div>

        <br />

        <Checkbox
          labelText="Lock tasks"
          disabled={state.reset || state.frequency == 0 ? true : false}
          checked={state.frequency == 0 ? false : state.lock_tasks}
          onChange={handleCheckbox('lock_tasks')}
          className={styles['complete']}
        />
      </div>

      <div className={styles['modal-footer']}>
        <Button text="CANCEL" color="secondary" onClick={() => props.setShowModal(false)} />
        <Button text="UPDATE" disabled={state.reset || state.active === 'f' || state.finalized} className={styles.create} onClick={(e) => addModal(e)} />
      </div>
    </Modal>
  );
};

const mapStateToProps = ({ ActsReducer, SitesReducer, AuditReducer, OrgModulesReducer, CreateAuditReducer, UserOrgSiteReducer }) => ({
  ActsReducer,
  SitesReducer,
  AuditReducer,
  OrgModulesReducer,
  CreateAuditReducer,
  UserOrgSiteReducer,
});

const mapDispatchToProps = {
  loadActsAction,
  loadSitesAction,
  loadAuditAction,
  editAuditAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditAuditModal);
