import { call, put, takeLatest, delay, select } from 'redux-saga/effects';
import { push } from 'redux-first-history';

import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { actionApiCall } from 'helpers/api/ApiCalls';
import * as sagaConstants from 'helpers/constants/SagasConstants';
import { redirectToIncidents } from 'helpers/utils';

const getSelected = (state) => state.UserOrgSiteReducer.selected;

function* addNewIncidentSaga({ payload }) {
  try {
    const selected = yield select(getSelected);
    const apiResponse = yield call(actionApiCall, {
      actionType: ActionTypeConstants.ADD_NEW_INCIDENT,
      data: {
        organization: selected.org,
        site: payload.site,
        name: payload.name,
        affiliate: payload.affiliate,
        description: payload.description,
        occurred: payload.occurred,
        reported_by: payload.reported_by,
        discovered: payload.discovered,
        type: payload.type,
        affected: payload.affected,
        active: true,
        reason: payload.reason,
        regulatory_act: payload.regulatory_act,
        investigating: true,
        tasks: payload.tasks,
        files: payload.files,
        notes: payload.notes,
      },
    });
    if (apiResponse.res.data.response === 200) {
      yield put({ type: ActionTypeConstants.ADD_NEW_INCIDENT_SUCCESS });
      if (selected.is_admin || selected.is_officer || selected.is_org_admin || selected.is_site_admin || selected.is_super || selected.is_coach) {
        yield put(push(redirectToIncidents(selected.ui_version)));
      } else {
        yield put(push('/'));
      }
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: apiResponse.res.data.message || 'Incident successfully reported',
          color: 'success',
        },
      });
      yield delay(10000);
      yield put({ type: ActionTypeConstants.HIDE_ALERT });
    } else {
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: apiResponse.res.data.message || `${sagaConstants.ISSUE_OCCURED} reporting incident`,
          color: 'danger',
        },
      });
      yield delay(10000);
      yield put({ type: ActionTypeConstants.HIDE_ALERT });
    }
  } catch (err) {
    yield put({
      type: ActionTypeConstants.SHOW_ALERT,
      payload: {
        message: `${sagaConstants.SERVER_ERROR}`,
        color: 'danger',
      },
    });
    yield delay(10000);
    yield put({ type: ActionTypeConstants.HIDE_ALERT });
  }
}

export default function* () {
  yield takeLatest(ActionTypeConstants.ADD_NEW_INCIDENT, addNewIncidentSaga);
}
