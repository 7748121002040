export function generateOrgList(organizations) {
  const orgList = [];
  if (organizations) {
    let keyId = 0;
    Object.values(organizations).forEach((org, idx) => {
      keyId = keyId + 1;
      orgList.push({ key: keyId, id: org.id, name: org.name, sites: [] });
      if (org.sites && Object.values(org.sites)) {
        Object.values(org.sites).forEach((site, idz) => {
          keyId = keyId + 1;
          orgList[idx]['sites'].push({
            key: keyId,
            id: site.id,
            name: site.name,
            deps: [],
          });
          if (site.departments) {
            Object.entries(site.departments).forEach((dep) => {
              keyId = keyId + 1;
              orgList[idx]['sites'][idz]['deps'].push({
                key: keyId,
                id: dep[0],
                name: dep[1],
              });
            });
          }
        });
      }
    });
  }
  return orgList;
}

export function getInitState(permissions = {}, items = {}, roles = []) {
  const initState = {};
  const myPerm = JSON.parse(JSON.stringify(permissions));
  try {
    Object.values(myPerm).forEach((permission) => {
      delete permission.is_admin;
      delete permission.is_super;
      const n = Object.assign({}, permission);
      if (permission.organization === '*') {
        delete permission.organization;
      }
      if (permission.site === '*') {
        delete permission.site;
      }
      if (permission.department === '*') {
        delete permission.department;
      }
      if (permission.department === 'partial') {
        delete permission.department;
      }
      Object.keys(items).forEach((key) => {
        if (items[key].organization === permission.organization && items[key].site === permission.site && items[key].department === permission.department && roles) {
          const role_obj = roles.filter((role) => role.id === `${n.role_id}`)[0].name || null;
          if (role_obj) {
            initState[key] = {
              role_id: parseInt(n.role_id, 10),
              role_name: role_obj,
              disabled: true,
            };
          }
        }
      });
    });
    return initState;
  } catch (error) {
    console.log(error);
    return {};
  }
}

export function getItemsList(options) {
  try {
    const items = {};
    let i = 0;
    options.forEach((org) => {
      i = i + 1;
      items[i] = { organization: org.id };
      org.sites.forEach((site) => {
        i = i + 1;
        items[i] = { organization: org.id, site: site.id };
        site.deps.forEach((dep) => {
          i = i + 1;
          items[i] = {
            organization: org.id,
            site: site.id,
            department: dep.id,
          };
        });
      });
    });
    return items;
  } catch (error) {
    console.log('Error getItemsList');
    return {};
  }
}
