import React from 'react';

import styles from './OrgSiteDept.module.scss';

import { arrayMoveImmutable } from 'array-move';
import { connect } from 'react-redux';

import { updateDeptAction, addNewDeptAction, deleteDeptAction, deactivateSiteAction, activateSiteAction } from 'store/actions/Actions';

const SortableItem = (item) => {
  const paddingLeft = item.type === 'dept' ? '60px' : item.type === 'org' ? '0px' : '30px';
  const className = item.type === 'dept' ? 'fal fa-hospital-user' : item.type === 'org' ? 'fal fa-briefcase-medical' : 'fal fa-hospital';
  return (
    <li className={`${styles['org-site']} ${styles[item.type]}`} style={{ paddingLeft: paddingLeft }}>
      <span>
        <i className={className} style={{ cursor: 'default' }}>
          &nbsp;
        </i>
      </span>
      <span className={styles['item-content']} onDoubleClick={item.type !== 'org' ? item.handleEditDept : null}>
        <strong>{item.type}:</strong>
        {item.isEditing ? (
          <form onSubmit={item.handleSaveDept}>
            <input key={item.id} size={40} maxLength={70} value={item.value} onChange={(e) => item.handleInputDept(e, item)} name={`${item.id}-editing`} />
            <i onClick={item.handleSaveDept} className="fal fa-save"></i>
            {!item.isNew && <i onClick={item.handleCancelEdit} className="fal fa-times"></i>}
          </form>
        ) : (
          <span className={styles['value']}>{item.value}</span>
        )}
      </span>
      <span>
        {item.type !== 'dept' && !(item.isSiteAdmin && item.type == 'org') && (
          <>
            <i onClick={item.handleChangeForm} className="fal fa-pen"></i>
          </>
        )}
        {item.type === 'site' && (
          <>
            <i onClick={item.handleAddDept} className="fal fa-plus-circle"></i>
          </>
        )}

        {item.type === 'site' && item.isSuper && item.active === 't' && (
          <>
            <i title="Dectivate Site" onClick={item.handleDeactivateSite} className="far fa-toggle-large-on"></i>
          </>
        )}

        {item.type === 'site' && item.isSuper && item.active === 'f' && (
          <>
            <i title="Activate Site" onClick={item.handleActivateSite} className="far fa-toggle-large-off"></i>
          </>
        )}

        {item.type === 'dept' && (
          <>
            <i onClick={item.handleDeleteDept} className="fal fa-trash-alt"></i>
          </>
        )}
      </span>
    </li>
  );
};

const SortableList = (props) => {
  const items = props.items;
  const handleChangeForm = (item) => {
    props.setFormData({
      id: item.id,
      type: item.type,
      company_name: item.name,
      address_1: item.address_1,
      address_2: item.address_2,
      city: item.city,
      country_code: item.country_code,
      postal_code: item.postal_code,
      region: item.region,
      site_limit: item.site_limit,
      sso_enabled: item.sso_enabled,
    });
  };

  const handleEditDept = (item) => {
    if (item.type !== 'dept') {
      return;
    }
    const myItems = items.slice();
    myItems.map((itm) => {
      if (itm.id === item.id) {
        itm.isNew = false;
        itm.isEditing = true;
        itm.disabled = true;
        itm.old_value = itm.value;
      }
      return itm;
    });
    props.setState({ ...props.state, sitesData: { items: myItems } });
  };

  const handleInputDept = (e, item) => {
    const myItems = items.slice();
    myItems.map((itm) => {
      if (itm.id === item.id) {
        itm.value = e.target.value;
      }
      return itm;
    });
    props.setState({ ...props.state, sitesData: { items: myItems } });
  };

  const handleSaveDept = (item) => {
    if (item.value === '') {
      return false;
    }
    const myItems = items.slice();
    myItems.map((itm) => {
      if (itm.id === item.id) {
        itm.isEditing = false;
        itm.old_value = '';
        itm.disabled = false;
      }
      return itm;
    });
    props.setState({ ...props.state, sitesData: { items: myItems } });

    // if is new, create, else update
    if (item.isNew) {
      const department = [
        {
          site: item.site,
          name: item.value,
        },
      ];
      props.addNewDeptAction(department);
    } else {
      const department = {
        id: item.id,
        site: item.site,
        name: item.value,
        active: true,
      };
      props.updateDeptAction(department);
    }
  };

  const handleCancelEdit = (item) => {
    if (item.value === '') {
      return false;
    }
    if (item.type !== 'dept') {
      return;
    }
    const myItems = items.slice();
    myItems.map((itm) => {
      if (itm.id === item.id) {
        itm.value = itm.old_value;
        itm.isNew = false;
        itm.isEditing = false;
        itm.disabled = false;
      }
      return itm;
    });
    props.setState({ ...props.state, sitesData: { items: myItems } });
  };

  const handleDeleteDept = (item) => {
    props.setShowConfirmModal({
      title: 'Delete Department',
      question: `Are you sure you want to delete "${item.value}" department?`,
      confirmAction: () => {
        props.setState({
          ...props.state,
          sitesData: { items: items.filter((itm) => itm.id !== item.id) },
        });
        if (item.id !== 0) {
          props.deleteDeptAction({
            id: item.id,
          });
        }
      },
    });
  };

  const handleDeactivateSite = (item) => {
    props.setShowSiteDeactivationModal({
      title: 'Site Deactivation',
      question: 'Are you sure you want to deactivate the site?',
      siteId: item.site,
      confirmAction: (siteId) => {
        props.deactivateSiteAction({ id: item.id, reassign: siteId });
      },
    });
  };

  const handleActivateSite = (item) => {
    props.activateSiteAction({ id: item.id });
  };

  const handleAddDept = (item) => {
    const myItems = items.slice();
    if (myItems.filter((item) => item.isNew).length === 0) {
      const myItem = {
        value: '',
        id: 0,
        isNew: true,
        disabled: false,
        site: item.site,
        type: 'dept',
        collection: 1,
        isEditing: true,
      };
      // first item is org, second item is part of same collection -> disable department as child of org
      const index = items.findIndex((i) => item.id === i.id);
      if (index === 0) {
        myItems.splice(index + 2, 0, myItem);
      } else {
        myItems.splice(index + 1, 0, myItem);
      }
      props.setState({ ...props.state, sitesData: { items: myItems } });
    }
  };

  return (
    <ul>
      {items &&
        items[0] &&
        items.map((item, index) => (
          <SortableItem
            key={`item-${index}-${item.id}`}
            index={index}
            {...item}
            isSuper={props.isSuper}
            handleDeactivateSite={() => handleDeactivateSite(item)}
            handleActivateSite={() => handleActivateSite(item)}
            handleDeleteDept={() => handleDeleteDept(item)}
            handleAddDept={() => handleAddDept(item)}
            handleSaveDept={() => handleSaveDept(item)}
            handleInputDept={handleInputDept}
            handleEditDept={() => handleEditDept(item)}
            handleChangeForm={() => handleChangeForm(item)}
            handleCancelEdit={() => handleCancelEdit(item)}
            isSiteAdmin={props.isSiteAdmin}
          />
        ))}
    </ul>
  );
};

const OrgSiteDeptSites = (props) => {
  const UserOrgSiteReducer = props.UserOrgSiteReducer;
  const onSortEnd = ({ oldIndex, newIndex }) => {
    const siteId = props.sitesData.items[newIndex > oldIndex ? newIndex : newIndex - 1].site;
    const items = arrayMoveImmutable(props.sitesData.items, oldIndex, newIndex);
    props.setState({ ...props.state, sitesData: { items } });
    const department = {
      id: props.sitesData.items[oldIndex].id,
      site: siteId,
      name: props.sitesData.items[oldIndex].value,
      active: true,
    };
    props.updateDeptAction(department);
  };
  const is_super = (UserOrgSiteReducer.selected && UserOrgSiteReducer.selected.is_super) || false;
  const is_officer = (UserOrgSiteReducer.selected && UserOrgSiteReducer.selected.is_officer) || false;
  const is_coach = (UserOrgSiteReducer.selected && UserOrgSiteReducer.selected.is_coach) || false;
  return (
    <SortableList
      isSuper={is_super || is_officer || is_coach}
      items={props.sitesData.items}
      setFormData={props.setFormData}
      setState={props.setState}
      state={props.state}
      distance={10}
      lockAxis="y"
      deactivateSiteAction={props.deactivateSiteAction}
      activateSiteAction={props.activateSiteAction}
      updateDeptAction={props.updateDeptAction}
      deleteDeptAction={props.deleteDeptAction}
      addNewDeptAction={props.addNewDeptAction}
      onSortEnd={onSortEnd}
      isSiteAdmin={props.isSiteAdmin}
      setShowSiteDeactivationModal={props.setShowSiteDeactivationModal}
      setShowConfirmModal={props.setShowConfirmModal}
    />
  );
};

const mapStateToProps = (state) => ({
  OrgSiteDeptReducer: state.OrgSiteDeptReducer,
  UserOrgSiteReducer: state.UserOrgSiteReducer,
});

const mapDispatchToProps = {
  updateDeptAction,
  addNewDeptAction,
  deleteDeptAction,
  deactivateSiteAction,
  activateSiteAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrgSiteDeptSites);
