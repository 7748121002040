import { call, put, takeLeading, delay, select } from 'redux-saga/effects';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { actionApiCall } from 'helpers/api/ApiCalls';
import * as sagaConstants from 'helpers/constants/SagasConstants';

const getSelected = (state) => state.UserOrgSiteReducer.selected;

function* loadAttestationsSaga({ payload }) {
  try {
    const selected = yield select(getSelected);
    const apiResponse = yield call(actionApiCall, {
      actionType: ActionTypeConstants.LOAD_ATTESTATIONS,
      data: {
        organization: selected.org,
      },
    });
    if (apiResponse.res.data.response === 200) {
      yield put({
        type: ActionTypeConstants.LOAD_ATTESTATIONS_SUCCESS,
        payload: {
          files: apiResponse.res.data.data,
        },
      });
    } else {
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: apiResponse.message || `${sagaConstants.ISSUE_OCCURED} loading attestations`,
          color: 'danger',
        },
      });
      yield delay(10000);
      yield put({ type: ActionTypeConstants.HIDE_ALERT });
    }
  } catch (err) {
    yield put({
      type: ActionTypeConstants.SHOW_ALERT,
      payload: {
        message: `${sagaConstants.SERVER_ERROR}`,
        color: 'danger',
      },
    });
    yield delay(10000);
    yield put({ type: ActionTypeConstants.HIDE_ALERT });
  }
}

export default function* () {
  yield takeLeading(ActionTypeConstants.LOAD_ATTESTATIONS, loadAttestationsSaga);
}
