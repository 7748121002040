// import DataTableRow from './DataTableRow';
import PropTypes from 'prop-types';
import React from 'react';
// import classNames from 'classnames';

class PaginatedData extends React.Component {
  getPaginatedData() {
    if (this.props.data && !this.props.serverPagination && this.props.data.length && this.props.rowsPerPage > 0 && this.props.currentPage >= 0) {
      const startIndex = this.props.currentPage * this.props.rowsPerPage;
      const endIndex = startIndex + this.props.rowsPerPage;

      const paginatedData = this.props.data.slice(
        startIndex > this.props.data.length ? this.props.data.length : startIndex,
        endIndex > this.props.data.length ? this.props.data.length : endIndex
      );

      return paginatedData;
    } else {
      return this.props.data;
    }
  }

  render() {
    const paginatedData = this.getPaginatedData();
    return this.props.children({ paginatedData });
  }

  static propTypes = {
    children: PropTypes.func,
    rowsPerPage: PropTypes.number,
    currentPage: PropTypes.number,
    data: PropTypes.array,
    serverPagination: PropTypes.bool,
  };
}

export default PaginatedData;
