import ItemActionsMenu from 'components/DataTable/ItemActionsMenu';
import React from 'react';
import Checkbox from 'components/Checkbox/Checkbox';
import moment from 'moment';
import ColVisibilitySelector from 'components/DataTable/ColVisibilitySelector';
import Button from 'components/Button/Button';
import { getStatusButtonColor } from 'helpers/utils';

export default {
  TABLE: {
    className: 'data-table-reports',
    initialSortKey: 'created_at',
    initialSortOrder: 'desc',
  },

  COLUMN: [
    {
      label: 'Row Selector',
      key: 'row-selector',
      header: ({ customTableProps }) => {
        const isSelectedAll =
          customTableProps.selectedRows &&
          customTableProps.selectedRows.length &&
          customTableProps.rowsData &&
          customTableProps.rowsData.length &&
          customTableProps.selectedRows.length === customTableProps.rowsData.length
            ? true
            : false;
        return <Checkbox checked={isSelectedAll} onChange={() => customTableProps.doSelectAll()} name="row-selector" />;
      },
      className: 'col-row-selector',
      format: ({ rowData, customTableProps }) => {
        const isSelected =
          customTableProps.selectedRows &&
          customTableProps.selectedRows.length &&
          customTableProps.selectedRows.some((checkbox) => {
            return checkbox.id === rowData.id;
          })
            ? true
            : false;
        return <Checkbox checked={isSelected} onChange={() => customTableProps.onRowSelect({ rowData })} name="row-selector" />;
      },
      canBeHidden: false,
    },
    // {
    //     label: "Id",
    //     header: "ID",
    //     key: "id",
    //     sortable: true,
    //     className: "",
    //     format: ({ rowData, customTableProps }) => {
    //         return <span>{rowData["id"]}</span>;
    //     }
    // },
    {
      label: 'Title',
      header: 'TITLE',
      key: 'name',
      sortable: true,
      className: 'title',
      format: ({ rowData, customTableProps }) => {
        return <span>{rowData['name']}</span>;
      },
    },
    {
      label: 'Regulatory act',
      header: 'REGULATORY ACT',
      key: 'regulatory_act',
      sortable: true,
      className: 'regulation',
      format: ({ rowData, customTableProps }) => {
        return <span>{rowData['regulatory_act']}</span>;
      },
    },
    {
      label: 'Site',
      header: 'SITE',
      key: 'siteName',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{!rowData.siteName ? 'Org-wide report' : rowData.siteName}</span>;
      },
    },
    {
      label: 'Date from',
      header: 'DATE FROM',
      key: 'date_from',
      sortable: true,
      className: 'generated',
      canBeHidden: true,
      format: ({ rowData, customTableProps }) => {
        return <span>{rowData['date_from'] ? moment(rowData['date_from']).format('MM/DD/YYYY') : ''}</span>;
      },
    },
    {
      label: 'Date to',
      header: 'DATE TO',
      key: 'date_to',
      sortable: true,
      className: 'generated',
      canBeHidden: true,
      format: ({ rowData, customTableProps }) => {
        return <span>{rowData['date_to'] ? moment(rowData['date_to']).format('MM/DD/YYYY') : ''}</span>;
      },
    },
    {
      label: 'Created',
      header: 'CREATED',
      key: 'created_at',
      sortable: true,
      className: 'generated',
      canBeHidden: true,
      format: ({ rowData, customTableProps }) => {
        return <span>{rowData['created_at'] ? moment(rowData['created_at']).format('MM/DD/YYYY') : ''}</span>;
      },
    },
    {
      label: 'Status',
      header: 'STATUS',
      key: 'status',
      sortable: true,
      className: '',
      canBeHidden: true,
      format: ({ rowData, customTableProps }) => {
        const status = rowData.status;
        const color = getStatusButtonColor(status, ['Ready'], ['New']);
        return (
          <span>
            <Button cursorDefault={customTableProps.cursorDefault} color={color} size="md" text={status} onClick={() => null} />
          </span>
        );
      },
    },
    {
      label: 'Actions',
      key: 'actions',
      format: ({ rowData, customTableProps }) => {
        const actionMenuDropDown = React.createRef();
        return (
          <ItemActionsMenu
            ref={actionMenuDropDown}
            actions={(() => {
              return rowData.generated !== ''
                ? [
                    rowData['status'] === 'Ready' && {
                      label: 'Download PDF',
                      action: () => {
                        if (actionMenuDropDown && actionMenuDropDown.current) {
                          actionMenuDropDown.current.handleMenuClose();
                        }
                        customTableProps.pdf(rowData);
                      },
                    },
                    rowData['status'] === 'Ready' && {
                      label: 'Download CSV',
                      action: () => {
                        if (actionMenuDropDown && actionMenuDropDown.current) {
                          actionMenuDropDown.current.handleMenuClose();
                        }
                        customTableProps.csv(rowData);
                      },
                    },
                    {
                      label: 'Delete',
                      action: () => {
                        if (actionMenuDropDown && actionMenuDropDown.current) {
                          actionMenuDropDown.current.handleMenuClose();
                        }
                        customTableProps.delete(rowData);
                      },
                    },
                  ]
                : [
                    {
                      label: 'Delete',
                      action: () => {
                        if (actionMenuDropDown && actionMenuDropDown.current) {
                          actionMenuDropDown.current.handleMenuClose();
                        }
                        customTableProps.delete(rowData);
                      },
                    },
                  ];
            })()}
          />
        );
      },
      className: 'col-actions',
      header: ({ customTableProps }) => {
        return (
          <ColVisibilitySelector
            colDefs={customTableProps.colDefs}
            visibleColumnKeys={customTableProps.visibleColumnKeys}
            onChangeVisibility={(colKey) => customTableProps.onChangeVisibility(colKey)}
          />
        );
      },
    },
  ],
};
