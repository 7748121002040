import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styles from './BulkUploadModal.module.scss';
import Modal from 'components/Modal/Modal';
import Button from 'components/Button/Button';

import { bulkUploadCloudAppAction, downloadTemplateCloudAppAction } from 'store/actions/Actions';
import UploadZone from 'components/UploadZone/UploadZone';

const BulkUploadCloudModal = (props) => {
  const [template, setTemplate] = useState({
    name: '',
    data: '',
  });
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (files) {
      setTemplate(files[0]);
    }
  }, [files]);

  return (
    <Modal setShowModal={props.setShowModal} className={styles.modal}>
      <div className={styles['modal-header']}>
        <h2 className={styles.heading}>Cloud and Application - Bulk Upload</h2>
        <p className={styles.description}>Fill out the Cloud and Application Excel template and upload below.</p>
      </div>
      <div className={styles['modal-body']}>
        <p>If you need the template, you can download it here:</p>
        <a
          className={styles['download-button']}
          onClick={() => {
            props.downloadTemplateCloudAppAction({
              audit_id: props.auditId,
            });
          }}
        >
          DOWNLOAD TEMPLATE
        </a>

        <p>
          The upload template is for new asset uploads only.
          <br />
          Re-uploading existing assets will result in a duplication of assets in the system.
        </p>

        <UploadZone files={files} setFiles={setFiles} formats={['xls', 'xlsx']} maxFileSize={10 * 1024 * 1024} maxFileNameLength={235} />
      </div>
      <div className={styles['modal-footer']}>
        <Button className={styles.button} text="CANCEL" onClick={() => props.setShowModal(false)} color="secondary" />
        <Button
          className={styles.button}
          text="UPLOAD"
          // disabled={!site}
          onClick={() => {
            if (template.data) {
              props.bulkUploadCloudAppAction({
                file: template.data,
                auditId: props.auditId,
              });
              props.setShowModal(false);
            }
          }}
        />
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  bulkUploadCloudAppAction,
  downloadTemplateCloudAppAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(BulkUploadCloudModal);
