import { put, takeLatest } from 'redux-saga/effects';
import ActionTypeConstants from 'helpers/ActionTypeConstants';

function* mfaMasterKeySaga() {
  yield put({ type: ActionTypeConstants.MFA_MASTER_KEY_SUCCESS });
}

export default function* MfaMasterKeySaga() {
  yield takeLatest(ActionTypeConstants.MFA_MASTER_KEY, mfaMasterKeySaga);
}
