import { call, put, takeLatest, delay, select } from 'redux-saga/effects';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { actionApiCall } from 'helpers/api/ApiCalls';
import * as sagaConstants from 'helpers/constants/SagasConstants';

const getSelected = (state) => state.UserOrgSiteReducer.selected;

function* loadStepsSaga({ payload }) {
  try {
    const selected = yield select(getSelected);
    const apiResponse = yield call(actionApiCall, {
      actionType: ActionTypeConstants.LOAD_STEPS,
      data: {
        organization: selected.org,
        site: selected.site,
      },
    });

    const apiResponse2 = yield call(actionApiCall, {
      actionType: ActionTypeConstants.LOAD_ACTIVE_STEP,
      data: {
        organization: selected.org,
        site: selected.site,
      },
    });

    // console.log(apiResponse.res.data.data);
    // console.log(apiResponse2.res.data.data);

    // TODO: check if multiple will break
    let active = 0;
    try {
      active = apiResponse2.res.data.data[0].step;
    } catch (error) {
      console.log('empty step');
    }

    if (apiResponse.res.data.response === 200) {
      const { data } = apiResponse.res.data;
      yield put({
        type: ActionTypeConstants.LOAD_STEPS_SUCCESS,
        payload: {
          steps: data.map((item) => {
            if (item.step === active) {
              item.active = true;
            }
            return item;
          }),
        },
      });
    } else {
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: apiResponse.res.data.message || `${sagaConstants.ISSUE_OCCURED} loading steps`,
          color: 'danger',
        },
      });
      yield delay(10000);
      yield put({ type: ActionTypeConstants.HIDE_ALERT });
    }
  } catch (err) {
    yield put({
      type: ActionTypeConstants.SHOW_ALERT,
      payload: {
        message: `${sagaConstants.SERVER_ERROR}`,
        color: 'danger',
      },
    });
    yield delay(10000);
    yield put({ type: ActionTypeConstants.HIDE_ALERT });
  }
}

export default function* () {
  yield takeLatest(ActionTypeConstants.LOAD_STEPS, loadStepsSaga);
}
