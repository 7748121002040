import React, { useEffect, useRef } from 'react';
import moment from 'moment';

import Checkbox from 'components/Checkbox/Checkbox';

import { useAppDispatch, useAppSelector } from 'hooks';
import { loadOrgEntitiesAction, loadModulesAction, loadActsAllAction, loadTopLevelFoldersAction } from 'store/actions/Actions';

import styles from './Modules.module.scss';

function getAllOther(_presets, _selected, currentModule, modules) {
  const allOtherRegs = [];
  Object.keys(_presets).forEach((key) => {
    if (key === currentModule.name) return;
    if (!modules.includes(key)) return;
    if (_presets[key].every((r) => _selected.includes(r))) {
      allOtherRegs.push(..._presets[key]);
    }
  });
  return [...new Set(allOtherRegs)];
}

const ModulesAlt = (props) => {
  const { selected = [], setSelected, documents = [], setDocuments, modules, setModules, editMode, editAccess } = props;

  const dispatch = useAppDispatch();
  const ActsReducer = useAppSelector((state) => state.ActsReducer);
  const ModulesReducer = useAppSelector((state) => state.ModulesReducer);
  const TopLevelFoldersReducer = useAppSelector((state) => state.TopLevelFoldersReducer);
  const OrgEntitiesReducer = useAppSelector((state) => state.OrgEntitiesReducer);

  useEffect(() => {
    if (!ModulesReducer.data && editAccess) {
      dispatch(loadModulesAction());
    }
  }, [ModulesReducer.data]);

  useEffect(() => {
    if (!ActsReducer.all) {
      dispatch(loadActsAllAction());
    }
  }, [ActsReducer.all]);

  useEffect(() => {
    if (editMode) {
      dispatch(loadOrgEntitiesAction());
    }
  }, [editMode]);

  useEffect(() => {
    if (!TopLevelFoldersReducer.data && editAccess) {
      dispatch(loadTopLevelFoldersAction());
    }
  }, [TopLevelFoldersReducer.data]);

  useEffect(() => {
    if (OrgEntitiesReducer.data && OrgEntitiesReducer.data.acts && editMode) {
      setSelected(() => [...new Set(OrgEntitiesReducer.data.acts.map((i) => i.id))]);
    }
  }, [OrgEntitiesReducer.data]);

  useEffect(() => {
    if (OrgEntitiesReducer.data && OrgEntitiesReducer.data.folders && editMode) {
      const myFiles = (TopLevelFoldersReducer.data && TopLevelFoldersReducer.data.map((f) => f.name)) || [];
      const myCurrentFiles = (OrgEntitiesReducer.data && OrgEntitiesReducer.data.folders.map((i) => i.name).filter((item) => myFiles.includes(item))) || [];
      setDocuments(() => [...myCurrentFiles]);
    }
  }, [OrgEntitiesReducer.data, TopLevelFoldersReducer.data]);

  // generate presets objects
  const presets = {};
  const presetsFiles = {};
  ModulesReducer.data &&
    ModulesReducer.data.map((d) => {
      d.submodules.map((m) => {
        presets[m.name] = (m.presets[0] && m.presets[0].acts.map((a) => a.id)) || [];
        presetsFiles[m.name] = (m.presets[0] && m.presets[0].files && m.presets[0].files.map((a) => a.file_name)) || [];
      });
    });

  const myFiles = (TopLevelFoldersReducer.data && TopLevelFoldersReducer.data.map((f) => f.name)) || [];

  const handlePresetCheckbox = (e, m) => {
    if (e.target.checked || e.target.dataset.partial === 'true') {
      setModules((s) => [...new Set([...s, m.name])]);
      setSelected((s) => [...new Set([...s, ...presets[m.name]])]);
      setDocuments((s) => [...new Set([...s, ...presetsFiles[m.name].filter((f) => myFiles.includes(f))])]);
      return;
    }

    setModules((s) => [...new Set(s.filter((f) => f !== m.name))]);
    const allOtherRegs = getAllOther(presets, selected, m, modules);
    const unique = presets[m.name].filter(function (obj) {
      return allOtherRegs.indexOf(obj) == -1;
    });
    setSelected((s) => [...new Set([...s.filter((e) => !unique.includes(e))])]);

    const allOtherRegsFiles = getAllOther(presetsFiles, documents, m, modules);
    const uniqueFiles = presetsFiles[m.name].filter(function (obj) {
      return allOtherRegsFiles.indexOf(obj) == -1;
    });
    const d = [...new Set(documents)].filter((e) => !uniqueFiles.includes(e));
    setDocuments(d);
  };

  const handleCheckboxDocuments = (e) => {
    e.persist();
    if (e.target.checked) {
      setDocuments((s) => [...s, e.target.value]);
      return;
    }
    setDocuments((s) => [...s.filter((item) => item !== e.target.value)]);
    // remove all modules having the file
    const modules = [];
    Object.keys(presetsFiles).forEach((key) => {
      if (presetsFiles[key].includes(e.target.value)) modules.push(key);
    });
    setModules((s) => [...s.filter((item) => !modules.includes(item))]);
  };

  const handleCheckboxActs = (e) => {
    e.persist();
    if (e.target.checked) {
      setSelected((s) => [...s, e.target.value]);
      return;
    }
    setSelected((s) => [...s.filter((item) => item !== e.target.value)]);
  };

  const ref = useRef([]);

  return (
    <div className={styles.modules}>
      {editAccess && (
        <>
          <hr />
          <div className={styles.modules1}>
            {ModulesReducer.data &&
              ModulesReducer.data.map((domain) => (
                <div key={domain.name}>
                  <h3>{domain.name}</h3>

                  <div className={styles.modules}>
                    {domain.submodules.map((m, i) => {
                      const isChecked =
                        (presets[m.name] && presets[m.name].some((r) => selected.includes(r))) ||
                        (!modules.includes(m.name) && presetsFiles[m.name] && presetsFiles[m.name].some((r) => documents.includes(r)));
                      const isPartial =
                        (presets[m.name] && !presets[m.name].every((r) => selected.includes(r))) ||
                        (!modules.includes(m.name) && presetsFiles[m.name] && !presetsFiles[m.name].every((r) => documents.includes(r)));
                      return (
                        <div key={m.id}>
                          <Checkbox
                            ref={(el) => (ref.current[m.id] = el)}
                            onChange={(e) => handlePresetCheckbox(e, m)}
                            className={styles.checkbox}
                            key={`${m.id}-k1`}
                            labelText={m.name}
                            value={m.id}
                            checked={isChecked}
                            partial={isPartial}
                            data-partial={isPartial}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              ))}
          </div>

          <br />
          <hr />
        </>
      )}

      <h3>HIPAA</h3>
      <div className={styles.acts}>
        {ActsReducer.all &&
          ActsReducer.all
            .filter((a) => a.regulatory_act === 'HIPAA')
            .map((a) => (
              <div key={a.id}>
                <Checkbox
                  disabled={!editAccess}
                  onChange={(e) => handleCheckboxActs(e)}
                  className={styles.checkbox}
                  key={`${a.id}-k2`}
                  labelText={`${a.name} (${moment(a.created_at).format('MM/DD/YYYY')})`}
                  value={a.id}
                  checked={selected.includes(a.id)}
                />
              </div>
            ))}
      </div>

      <h3>OSHA</h3>
      <div className={styles.acts}>
        {ActsReducer.all &&
          ActsReducer.all
            .filter((a) => a.regulatory_act === 'OSHA')
            .map((a) => (
              <div key={a.id}>
                <Checkbox
                  disabled={!editAccess}
                  onChange={(e) => handleCheckboxActs(e)}
                  className={styles.checkbox}
                  key={`${a.id}-k3`}
                  labelText={`${a.name} (${moment(a.created_at).format('MM/DD/YYYY')})`}
                  value={a.id}
                  checked={selected.includes(a.id)}
                />
              </div>
            ))}
      </div>

      {editAccess && (
        <div>
          <h3>Documents</h3>
          <div className={styles.acts}>
            {TopLevelFoldersReducer &&
              TopLevelFoldersReducer.data &&
              TopLevelFoldersReducer.data.map((doc) => {
                return (
                  <Checkbox
                    onChange={handleCheckboxDocuments}
                    className={styles.checkbox}
                    key={`${doc.key}-k4`}
                    labelText={`${doc.name}`}
                    value={doc.name}
                    checked={documents.includes(doc.name) || false}
                  />
                );
              })}
          </div>
        </div>
      )}
    </div>
  );
};

export default ModulesAlt;
