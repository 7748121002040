import React, { useRef, useState } from 'react';

import styles from './UploadDocument.module.scss';

/**
 *
 * @param {Object} [document] - current document
 * @param {Function} setDocument - sets the document
 */
const UploadDocument = (props) => {
  let reader = new FileReader();
  const { state, setState, formats } = props;
  const fileRef = useRef();
  const [errors, setErrors] = useState([]);
  const formatsString = `.${formats.join(', .')}`;

  const uploadDocument = (e) => {
    try {
      const file = e.target.files[0];
      // validate
      if (file && file.size > 0) {
        setErrors([]);
        const fileType = file.name.split('.').pop();
        const isTooBig = file.size > 20 * 1024 * 1024 ? true : false;
        const isCorrectType = formats.includes(fileType.toLowerCase());
        const isFilenameTooLong = file.name.length >= 64;

        if (isFilenameTooLong) {
          setErrors((s) => ({ ...s, isFilenameTooLong: true }));
          setState({
            ...state,
            file: '',
            name: file.name,
          });
        }
        if (isTooBig) {
          setErrors((s) => ({ ...s, isTooBig: true }));
          setState({
            ...state,
            file: '',
            name: file.name,
          });
        }
        if (!isCorrectType) {
          setErrors((s) => ({ ...s, isWrongType: true }));
          setState({
            ...state,
            file: '',
            name: file.name,
          });
        }
        if (!isTooBig && isCorrectType && !isFilenameTooLong) {
          reader.readAsDataURL(file);
          reader.onload = function (event) {
            const result = reader.result.split(',')[1];
            setErrors([]);
            setState({
              ...state,
              file: result,
              name: file.name,
            });
            fileRef.current.value = '';
          };
        }
      }
    } catch (error) {
      console.log('error with upload!', error);
    }
  };

  return (
    <>
      <label className={styles['upload-label']}>
        <span className={styles['upload-placeholder']}>{props.state['name'] || 'Click Browse to locate document for upload'}</span>
        <span className={styles['upload-button']}>BROWSE</span>
        <input
          ref={fileRef}
          // accept={".jpg, .jpeg, .png, .gif, .doc, .docx, .xlsx, .xls, .pptx, .ppt, .pdf"}
          accept={formatsString}
          onChange={uploadDocument}
          className={styles['upload-input']}
          hidden
          type="file"
        />
      </label>

      {(errors.isTooBig || errors.isWrongType || errors.isFilenameTooLong) && (
        <div className={styles.error}>
          {errors.isTooBig ? 'Selected file is too big!' : ''}
          {errors.isWrongType ? 'Unsupported file format!' : ''}
          {errors.isFilenameTooLong ? 'The file name must be 64 characters or less!' : ''}
        </div>
      )}

      <div>
        <small>Supported file formats are: {formatsString}. Maximum allowed file size is 20Mb.</small>
      </div>
    </>
  );
};

export default UploadDocument;
