import React, { useState } from 'react';
import { connect } from 'react-redux';
import styles from './BulkUploadModal.module.scss';
import Modal from 'components/Modal/Modal';
import Button from 'components/Button/Button';
import { uploadAuditAction } from 'store/actions/Actions';

/**
 *
 * @param {Function} setShowModal - toggles the modal
 */
const BulkUploadModal = (props) => {
  const [template, setTemplate] = useState({
    name: '',
    data: '',
  });

  console.log(props);

  const handleDrop = (e) => {
    e.preventDefault();
    if (e.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (let i = 0; i < e.dataTransfer.items.length; i++) {
        // If dropped items aren't files, reject them
        if (e.dataTransfer.items[i].kind === 'file') {
          const file = e.dataTransfer.items[i].getAsFile();
          const reader = new FileReader();
          reader.onload = (e) => {
            setTemplate({
              name: file.name,
              data: e.target.result,
            });
          };
          reader.readAsArrayBuffer(file);
        }
      }
    }
    // else {
    //     // Use DataTransfer interface to access the file(s)
    //     for (let i = 0; i < e.dataTransfer.files.length; i++) {
    //         console.log(
    //             "... file[" + i + "].name = " + e.dataTransfer.files[i].name
    //         )
    //     }
    // }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleBrowse = (state, setState) => (e) => {
    let reader = new FileReader();
    const { files } = e.target;
    const file = files[0];
    reader.onload = (res) => {
      setState({
        name: file.name,
        data: res.target.result,
      });
    };
    reader.readAsArrayBuffer(file);
  };

  function arrayBufferToBase64(buffer) {
    let binary = '';
    let bytes = new Uint8Array(buffer);
    let len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  return (
    <Modal setShowModal={props.setShowModal} className={styles.modal}>
      <div className={styles['modal-header']}>
        <h2 className={styles.heading}>Audit Upload</h2>
        <p className={styles.description}>Audit answers Excel spreadsheet</p>
      </div>
      <div className={styles['modal-body']}>
        {/* <p>If you need the template, you can download it here:</p>
                <a
                    className={styles["download-button"]}
                    href={`${process.env.PUBLIC_URL}/AffiliateWorksheet.xlsx`}
                >
                    DOWNLOAD TEMPLATE
                </a> */}
        <div className={styles['drag-n-drop']} onDrop={handleDrop} onDragOver={handleDragOver}>
          <i className={`fal fa-arrow-to-bottom ${styles['drag-icon']}`} />
          <p>Drag &amp; drop audit here</p>
          <span>
            <hr className={styles.line} /> OR <hr className={styles.line} />
          </span>
          <div className={styles.browse}>
            <input type="file" accept=".xls, .xlsx" onChange={handleBrowse(template, setTemplate)} />
            <Button className={styles.button} text="BROWSE FOR AUDIT" />
            <p>{template.name}</p>
          </div>
        </div>
      </div>
      <div className={styles['modal-footer']}>
        <Button className={styles.button} text="CANCEL" onClick={() => props.setShowModal(false)} color="secondary" />
        <Button
          disabled={template.name === '' ? true : false}
          className={styles.button}
          text="UPLOAD"
          onClick={() => {
            const base64File = arrayBufferToBase64(template.data);
            if (props.showAuditUploadModal !== '') {
              props.uploadAuditAction({
                id: props.showAuditUploadModal,
                file: base64File,
              });
            }
            props.setShowModal(false);
          }}
        />
      </div>
    </Modal>
  );
};

// const mapStateToProps = state => state.AuditReducer;
const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  uploadAuditAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(BulkUploadModal);
