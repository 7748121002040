import React from 'react';

import { Link } from 'react-router-dom';
import { authLogoutAction } from 'store/actions/Actions';
import { useAppDispatch, useAppSelector } from 'hooks';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import Fade from '@mui/material/Fade';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import VerifiedIcon from '@mui/icons-material/Verified';
import SettingsIcon from '@mui/icons-material/Settings';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import SchoolIcon from '@mui/icons-material/School';

import styles from './UserPanelNew.module.scss';

function UserPanelNew({menuData=[]}) {
  const dispatch = useAppDispatch();
  const AuthReducer = useAppSelector((state) => state.AuthReducer);

  const logout = async () => {
    dispatch(authLogoutAction());
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function getIcon(icon: string) {
    const iconMap = {
      LogoutIcon,
      AccountCircleIcon,
      VerifiedIcon,
      SettingsIcon,
      CorporateFareIcon,
      SchoolIcon
    }

    return React.createElement(iconMap[icon]);
}

  return (
      <>
        <Button
          id="user-menu"
          aria-controls={open ? 'fade-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <div className={styles['user-menu']}>
            <span className={styles['user-name']}>Welcome, {AuthReducer?.userData?.name?.split(' ')[0]}</span> <span className={styles['icon']}><KeyboardArrowDownIcon /></span>
          </div>
        </Button>
        <Menu
          id="fade-menu"
          MenuListProps={{
            'aria-labelledby': 'fade-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
        >
          <div className={styles['user-menu-dropdown']}>
            {menuData.map((menuItem: {label: string; icon: string; link: string;}) => {
                return (
                  <MenuItem onClick={handleClose} key={menuItem.label}>
                    <Link to={menuItem.link} className={styles['user-menu-link']}>
                        {getIcon(menuItem.icon)}
                        <span className={styles['user-menu-label']}>{menuItem.label}</span>
                    </Link>
                  </MenuItem>
                );
            })}
            <MenuItem onClick={handleClose}>
              <a 
                href="https://compliancygroup.freshdesk.com/support/home" 
                target="_blank" 
                rel="noopener noreferrer"
                className={styles['user-menu-link']}
              >
                  {getIcon('SchoolIcon')}
                  <span className={styles['user-menu-label']}>Knowledge Base</span>
              </a>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <a
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    logout();
                  }}
                  className={styles['user-menu-link']}
              >
                  {getIcon('LogoutIcon')}
                  <span className={styles['user-menu-label']}>Logout</span>
              </a>
            </MenuItem>
        </div>
      </Menu>
    </>
  );
}

export default UserPanelNew;
