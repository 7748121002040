import React, { useEffect, useState } from 'react';
import FormElement from 'components/FormElement/FormElement';
import Button from 'components/Button/Button';
import Checkbox from 'components/Checkbox/Checkbox';
import moment from 'moment';
import { connect } from 'react-redux';
import { loadActsAllAction } from 'store/actions/Actions';
import { country_list } from 'helpers/utils';
import styles from '../../OrgSiteDept.module.scss';

const OrgForm = (props) => {
  const formData = props.formDataOrg;
  const setFormData = props.setFormDataOrg;
  const cancel = props.cancelFun;
  const stepsFun = props.stepsFun;
  const steps = props.steps;
  const currentStep = props.currentStep;
  const setCurrentStep = props.setCurrentStep;

  const { ActsReducer, loadActsAllAction } = props;

  const [stateErrors, setStateErrors] = useState({
    name: '',
    customer_id: '',
    site_limit: '',
    acts: [],
  });

  useEffect(() => {
    if (!ActsReducer.all) {
      loadActsAllAction();
    }
  }, [ActsReducer.all, loadActsAllAction]);

  const handleChange = (e) => {
    if (e.target.dataset && e.target.dataset.name && e.target.dataset.value) {
      // for country
      setFormData({
        ...formData,
        [e.target.dataset.name]: e.target.dataset.value,
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleSiteLimitChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: Math.max(e.target.value, 0),
    });
  };

  const handleNext = () => {
    if (
      formData.name === '' ||
      formData.customer_id === '' ||
      formData.customer_id.length > 8 ||
      formData.site_limit === '' ||
      parseInt(formData.site_limit, 10) < 0 ||
      (formData.acts && formData.acts.length === 0)
    ) {
      const name = formData.name === '' ? 'Organization name is required' : '';
      const customer_id = formData.customer_id !== '' ? (formData.customer_id.length > 8 ? 'Customer ID can be 8 characters or less' : '') : 'Customer ID is required';
      const site_limit = formData.site_limit !== '' ? (parseInt(formData.site_limit, 10) < 0 ? 'Site limit must be a positive number' : '') : 'Site limit is required';
      const acts = formData.acts && formData.acts.length === 0 ? 'At least one act is required' : '';

      setStateErrors((s) => ({
        ...s,
        name,
        customer_id,
        site_limit,
        acts,
      }));

      return false;
    }

    const stepsArr = JSON.parse(JSON.stringify(steps));
    stepsArr[currentStep - 1].active = true;
    setCurrentStep(currentStep + 1);
    stepsFun(stepsArr);
  };

  const handleActs = (e) => {
    let a = formData.acts.slice();
    if (e.target.checked === true) {
      a.push(Number(e.target.value));
    } else {
      a = a.filter((item) => item !== Number(e.target.value));
    }
    setFormData((s) => ({
      ...s,
      acts: a,
    }));
  };

  return (
    <>
      <FormElement labelText="NAME" htmlFor="name" errorMessage={stateErrors.name}>
        <input placeholder="Company Name" name="name" type="text" maxLength="70" value={formData.name} onChange={(e) => handleChange(e)} />
      </FormElement>

      <FormElement labelText="ID" htmlFor="customer_id" errorMessage={stateErrors.customer_id}>
        <input placeholder="Infusionsoft Id" name="customer_id" type="text" maxLength="70" value={formData.customer_id} onChange={(e) => handleChange(e)} />
      </FormElement>

      <FormElement labelText="ADDRESS" htmlFor="address_1">
        <input placeholder="Company Address Line 1" type="text" name="address_1" id="address_1" value={formData.address_1} onChange={(e) => handleChange(e)} />
      </FormElement>

      <FormElement htmlFor="address_2">
        <input placeholder="Company Address Line 2" type="text" name="address_2" id="address_2" value={formData.address_2} onChange={(e) => handleChange(e)} />
      </FormElement>

      <FormElement labelText="CITY" htmlFor="city">
        <input placeholder="City" id="city" name="city" type="text" value={formData.city} onChange={(e) => handleChange(e)} />
      </FormElement>

      <FormElement labelText="REGION/STATE" htmlFor="region">
        <input placeholder="Region" name="region" id="region" type="text" value={formData.region} onChange={(e) => handleChange(e)} />
      </FormElement>

      <FormElement labelText="COUNTRY" htmlFor="country">
        <div className={styles.input_holder}>
          <select className={styles.input} name="country_code" value={formData.country_code} onChange={handleChange}>
            {country_list.map((item) => (
              <option key={item.id} data-name="country_code" data-value={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
      </FormElement>

      <FormElement labelText="POSTAL CODE" htmlFor="postal_code">
        <input placeholder="Postal Code" type="text" id="postal_code" name="postal_code" value={formData.postal_code} onChange={(e) => handleChange(e)} />
      </FormElement>

      <FormElement labelText="SITE LIMIT" htmlFor="site_limit" errorMessage={stateErrors.site_limit}>
        <input placeholder="Site Limit" name="site_limit" id="site_limit" type="number" value={formData.site_limit} onChange={(e) => handleSiteLimitChange(e)} min={0} />
      </FormElement>

      <FormElement labelText="ACTS" htmlFor="acts" errorMessage={stateErrors.acts}>
        {ActsReducer.all &&
          ActsReducer.all.map((act) => {
            {
              formData.acts.includes(parseInt(act.id, 10));
            }
            return (
              <Checkbox
                checked={formData.acts.includes(parseInt(act.id, 10))}
                onChange={handleActs}
                name="acts"
                className={styles.checkbox}
                key={`${act.id}-asdasd`}
                labelText={`${act.name} (${moment(act.created_at).format('MM/DD/YYYY')})`}
                value={act.id}
              />
            );
          })}
      </FormElement>

      <br />
      <br />

      <div className={styles['org-site-bottom-buttons']}>
        <Button text="CANCEL" color="secondary" onClick={() => cancel()} />
        <Button type="submit" icon="fas fa-arrow-right" text="NEXT" onClick={handleNext} />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  ActsReducer: state.ActsReducer,
});

const mapDispatchToProps = {
  loadActsAllAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrgForm);
