import React, { useState } from 'react';

import styles from './MessageBox.module.scss';

/**
 * @param {String} text - text to display
 * @param {String} className - additional className to add
 */
const MessageBox = (props) => {
  const [show, toggleShow] = useState(true);

  const handleChange = (e) => {
    toggleShow(!show);
  };

  return (
    <div className={`${styles['message-box']} ${!show ? styles.hide : ''} ${props.className || ''}`}>
      <span>
        {props.boldedText ? <strong>{props.boldedText}</strong> : ''}
        {props.text}
      </span>
      <i onClick={handleChange} className="fas fa-times" />
    </div>
  );
};

export default MessageBox;
