import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import styles from './Add.module.scss';
import AppLayoutWrapFull from 'components/AppLayoutWrapFull';
import PageHeader from 'components/PageHeader/PageHeader';
import Container from 'components/Container/Container';
import Row from 'components/Row/Row';
import FormElement from 'components/FormElement/FormElement';

// import Select from "components/Select/Select";
import SelectWithSearch from 'components/SelectWithSearch/SelectWithSearch';

// import MessageBox from "components/MessageBox/MessageBox";
// import Checkbox from "components/Checkbox/Checkbox";
import Radio from 'components/Radio/Radio';
import AccessPermissions from '../components/AccessPermissions/AccessPermissions';
import HR from 'components/HR/HR';
import Button from 'components/Button/Button';
import { submitAddUserFormAction, submitAddOfficerFormAction, loadOrgSiteDeptAction, loadRolesAction, createUsersBulkAction } from 'store/actions/Actions';
import { states, statesObj, country_list } from 'helpers/utils';

import { generateOrgList, getInitState, getItemsList } from '../accessUtils';
import { isEmailValid } from 'helpers/utils';

const Add = (props) => {
  const { RolesReducer, loadOrgSiteDeptAction, loadRolesAction, OrgSiteDeptReducer, UserOrgSiteReducer } = props;
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    if (!RolesReducer.roles) {
      loadRolesAction();
    }
  }, [RolesReducer.roles, loadRolesAction]);

  useEffect(() => {
    if (!OrgSiteDeptReducer.organizations) {
      loadOrgSiteDeptAction();
    }
  }, [OrgSiteDeptReducer.organizations, loadOrgSiteDeptAction]);

  // on add we need to check if selected org is sso org
  const isSSOEnabled =
    (OrgSiteDeptReducer.organizations &&
      UserOrgSiteReducer.selected &&
      OrgSiteDeptReducer.organizations[UserOrgSiteReducer.selected.org] &&
      OrgSiteDeptReducer.organizations[UserOrgSiteReducer.selected.org].sso_enabled) ||
    false;

  // Keep track of officer role state
  const [officerState, setOfficerState] = useState({
    active: 't',
    privacy: false,
    security: false,
    status: 'active',
    firstname: '',
    sso: 'Azure',
    lastname: '',
    email: '',
    address_1: '',
    address_2: '',
    city: '',
    country_name: 'United States of America',
    country_code: '235',
    state: '',
    state_name: '',
    postcode: '',
    // password: "",
    // "confirm-password": "",
    phone: '',
    // showHints: true
  });

  useEffect(() => {
    const state_name = statesObj[officerState.state] || officerState.state;
    if (state_name) {
      setOfficerState((s) => ({
        ...s,
        state_name,
      }));
    }
  }, [officerState.state]);

  useEffect(() => {
    setOfficerState((s) => ({
      ...s,
      sso: isSSOEnabled ? 'Azure' : 'No',
    }));
  }, [isSSOEnabled]);

  const [stateErrors, setStateErrors] = useState({
    password: '',
    'confirm-password': '',
    firstname: '',
    lastname: '',
    email: '',
    access: '',
    address_1: '',
    address_2: '',
    country_name: '',
    city: '',
    state_name: '',
    postcode: '',
  });

  /**
   *
   * @param {string} inputName - the inputName used in state
   */
  const handleChange = (inputName) => (e) => {
    e.persist();
    setOfficerState((s) => ({
      ...s,
      [inputName]: e.target.value,
    }));
    setStateErrors((s) => ({ ...s, [inputName]: '' }));
  };

  // const handleSelect = (inputName) => (e) => {
  //   e.persist();
  //   setOfficerState((s) => ({
  //       ...s,
  //       [inputName]: (e.target.attributes && e.target.attributes.value) ? e.target.attributes.value.value : ''
  //   }));
  //   setStateErrors((s) => ({ ...s, [inputName]: '' }));
  // };

  const handleChangeSelectWithSearch = (type) => (data) => {
    let tmp = {};
    if (type === 'country_name') {
      tmp = {
        country_code: data?.id || '',
        country_name: data?.name || '',
      };
    }
    if (type === 'state_name') {
      tmp = {
        state: data?.id || '',
        state_name: data?.name || '',
      };
    }
    setOfficerState((s) => ({
      ...s,
      ...tmp,
    }));
    setStateErrors((s) => ({ ...s, [type]: '' }));
  };

  // const validatePass = (officerState) => {
  //   const result = !/^(?=.*[\d])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])[\w!@#$%^&*]{8,}$/.test(officerState.password) ? "Invalid password" : ""
  //   if (officerState.password !== "" && (officerState.password !== officerState['confirm-password'])) {
  //     return {
  //       password: "Passwords don't match",
  //       "confirm-password": "Passwords don't match",
  //     };
  //   }
  //   return {
  //     password: result,
  //     "confirm-password": result,
  //   };
  // }

  const handleSubmitAddForm = (e) => {
    e.preventDefault();
    const access = [];
    let errorAccess = false;
    let errorDepartment = false;
    const rolesElemnets = document.querySelectorAll('[name=role]');
    if (rolesElemnets.length < 1) {
      errorAccess = true;
    }
    rolesElemnets.forEach((item) => {
      if (item.value === '0') {
        errorAccess = true;
      }
      access.push({
        role_id: item.value,
        organization: item.dataset.org,
        site: item.dataset.site || null,
        department: item.dataset.dep || (item.dataset.partial === 'true' && 'partial') || null,
      });
    });

    if (
      errorAccess ||
      // errorDepartment ||
      officerState.firstname === '' ||
      officerState.lastname === '' ||
      officerState.email === '' ||
      !isEmailValid(officerState.email)
    ) {
      setStateErrors((s) => ({
        ...s,
        // ...validatePass(officerState),
        access: errorAccess ? (errorDepartment ? 'At least one department per site is required!' : 'Access role(s) are required!') : '',
        firstname: officerState.firstname === '' ? 'First name is required' : '',
        lastname: officerState.lastname === '' ? 'Last name is required' : '',
        email: !isEmailValid(officerState.email) ? 'Email is required' : '',
      }));
    } else {
      props.submitAddOfficerFormAction({
        ...officerState,
        role: access,
      });
    }
  };

  const [localBoxes, setLocalBoxes] = useState({});

  const orgs = generateOrgList(OrgSiteDeptReducer.organizations);
  const items = getItemsList(orgs);
  const initState = {
    ...getInitState({}, items, RolesReducer.roles),
    ...localBoxes,
  };

  return (
    <AppLayoutWrapFull>
      <PageHeader />
      <Container>
        <form className={styles['add-user-form']}>
          <div className={styles.holder}>
            <div className={styles.left}>
              <FormElement className={styles.form_element} labelText="FIRST NAME" smallText="*" htmlFor="firstname" errorMessage={stateErrors.firstname}>
                <input name="firstname" type="text" placeholder="Enter your first name" value={officerState.firstname} onChange={handleChange('firstname')} />
              </FormElement>

              <FormElement className={styles.form_element} labelText="LAST NAME" smallText="*" htmlFor="lastname" errorMessage={stateErrors.lastname}>
                <input name="lastname" type="text" placeholder="Enter your last name" value={officerState.lastname} onChange={handleChange('lastname')} />
              </FormElement>

              <FormElement className={styles.form_element} labelText="EMAIL" smallText="*" htmlFor="email" errorMessage={stateErrors.email}>
                <input autoComplete="email" name="email" type="email" placeholder="Enter your email address" value={officerState.email} onChange={handleChange('email')} />
              </FormElement>

              <FormElement className={styles.form_element} errorMessage={stateErrors['phone']}>
                <label htmlFor="phone">CONTACT PHONE</label>
                <Row className={styles['form-group-row']}>
                  <input
                    className={styles['contact-phone-input']}
                    placeholder="Enter your phone number"
                    name="phone"
                    type="tel"
                    id="phone"
                    value={officerState['phone']}
                    onChange={handleChange('phone')}
                  />
                </Row>
              </FormElement>

              <FormElement className={styles.form_element} labelText="ADDRESS" htmlFor="address_1" errorMessage={stateErrors.address_1}>
                <input
                  name="address_1"
                  type="text"
                  placeholder="Street and number"
                  // placeholder="Enter your address"
                  value={officerState.address_1}
                  onChange={handleChange('address_1')}
                />
              </FormElement>

              <FormElement
                className={styles.form_element}
                // labelText="ADDRESS 2"
                htmlFor="address_2"
                errorMessage={stateErrors.address_2}
              >
                <input name="address_2" type="text" placeholder="Apartment, building, floor (optional)" value={officerState.address_2} onChange={handleChange('address_2')} />
              </FormElement>

              <FormElement className={styles.form_element} labelText="COUNTRY" htmlFor="country_name" errorMessage={stateErrors.country_name}>
                <SelectWithSearch value={officerState.country_code} onChange={handleChangeSelectWithSearch('country_name')} options={country_list} placeholder="Country" />
              </FormElement>

              <Row className={`${styles['form-group-row']} ${styles['city-state-postcode']} ${styles.form_element}`}>
                <FormElement labelText="CITY" htmlFor="city" errorMessage={stateErrors.city}>
                  <input name="city" type="text" placeholder="City" value={officerState.city} onChange={handleChange('city')} />
                </FormElement>

                <FormElement labelText="STATE / REGION" htmlFor="state" errorMessage={stateErrors.state_name}>
                  <SelectWithSearch
                    value={officerState.state}
                    onChange={handleChangeSelectWithSearch('state_name')}
                    options={states.map((item) => ({
                      ...item,
                      id: item.abbrev,
                    }))}
                    placeholder="State"
                  />
                </FormElement>

                <FormElement labelText="POSTAL CODE" htmlFor="postcode" errorMessage={stateErrors.postcode}>
                  <input name="postcode" type="text" placeholder="Postal Code" value={officerState.postcode} onChange={handleChange('postcode')} />
                </FormElement>
              </Row>
            </div>

            <div className={styles.right}>
              <FormElement labelText="STATUS" htmlFor="status" className={styles.form_element}>
                <Row className={styles['status-row']}>
                  <Radio checked={officerState.active === 't'} labelText="Active" onChange={handleChange('active')} value="t" />
                  <Radio checked={officerState.active !== 't'} labelText="Inactive" onChange={handleChange('active')} value="f" />
                </Row>
              </FormElement>

              {isSSOEnabled ? (
                <FormElement labelText="SSO" htmlFor="sso" className={styles.form_element}>
                  <Row className={styles['status-row']}>
                    <Radio checked={officerState.sso === 'Azure'} labelText="Enabled" onChange={handleChange('sso')} value="Azure" />
                    <Radio checked={officerState.sso === 'No'} labelText="Disabled" onChange={handleChange('sso')} value="No" />
                  </Row>
                </FormElement>
              ) : null}

              <AccessPermissions
                className={styles['access-permissions']}
                organizations={OrgSiteDeptReducer.organizations}
                initState={initState}
                orgs={orgs}
                userOrgs={UserOrgSiteReducer.organizations}
                selected={UserOrgSiteReducer.selected}
                setLocalBoxes={setLocalBoxes}
                loadOrgSiteDeptAction={loadOrgSiteDeptAction}
                roles={RolesReducer.roles}
                error={stateErrors.access}
                setStateErrors={setStateErrors}
                placeholder="The Business Company"
              />
            </div>
          </div>

          <HR className={styles.hr} />

          <div className={styles['submit-box']}>
            <Button text="Cancel" color="secondary" onClick={() => navigate('/maintain/users')} />
            <Button icon="far fa-save" text="Save" onClick={handleSubmitAddForm} />
          </div>
        </form>
      </Container>
    </AppLayoutWrapFull>
  );
};

const mapStateToProps = ({ AddUserFormReducer, UserOrgSiteReducer, AddOfficerFormReducer, OrgSiteDeptReducer, RolesReducer }) => {
  return {
    AddUserFormReducer,
    UserOrgSiteReducer,
    AddOfficerFormReducer,
    OrgSiteDeptReducer,
    RolesReducer,
  };
};

const mapDispatchToProps = {
  submitAddUserFormAction,
  submitAddOfficerFormAction,
  loadOrgSiteDeptAction,
  loadRolesAction,
  createUsersBulkAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Add);
