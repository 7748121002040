import React, { useEffect } from 'react';
import moment from 'moment';
import styles from './Counter.module.scss';

function Counter(props) {
  const { counter, setCounter } = props;

  useEffect(() => {
    const timer = window.setInterval(() => {
      if (sessionStorage.getItem('mfa_code_expire')) {
        const current = moment().unix();
        const expired = Number(sessionStorage.getItem('mfa_code_expire'));
        setCounter(expired - current);
      }
    }, 1000);
    return () => {
      window.clearInterval(timer);
    };
  }, []);

  return <div className={styles.text}>{counter > 0 ? `Code will expire in ${counter} second(s)` : 'Code expired'}</div>;
}

export default Counter;
