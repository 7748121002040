import React from 'react';
import MainLayout from 'v2/layouts/MainLayout/MainLayout';
import PageLayout from 'v2/layouts/PageLayout/PageLayout';

import IncidentPage from './IncidentPage/IncidentPage';

function ReportIncident() {
    return (
        <>
            <MainLayout>
                <PageLayout>
                    <IncidentPage />
                </PageLayout>
            </MainLayout>
        </>
    );
}

export default ReportIncident;
