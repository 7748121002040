import { call, put, takeLatest, delay, select } from 'redux-saga/effects';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { actionApiCall } from 'helpers/api/ApiCalls';
import * as sagaConstants from 'helpers/constants/SagasConstants';

const getSelected = (state) => state.UserOrgSiteReducer.selected;
const getSites = (state) => state.SitesReducer.sites;

function transformDevices(devices, sites) {
  const result = devices.map((device) => {
    if (device['device_destroyed'] === 't') {
      device.status = 'Destroyed';
    } else if (device['deactivated']) {
      device.status = 'Deactivated';
    } else if (device['activated']) {
      device.status = 'Activated'
    }
    if (sites) {
      sites.forEach((item) => {
        if (item.id === device.site_id) {
          device.siteName = item.name;
        }
      });
    }
    return device;
  });
  return result;
}

function* loadDeviceInvetoryV2Saga({ payload }) {
  try {
    const selected = yield select(getSelected);
    const apiResponse = yield call(actionApiCall, {
      actionType: ActionTypeConstants.LOAD_DEVICE_INVENTORY_V2,
      data: {
        organization: selected.org,
      },
    });
    if (apiResponse.res.data.response === 200) {
      const sites = yield select(getSites);
      const devices = apiResponse.res.data.data || [];
      yield put({
        type: ActionTypeConstants.LOAD_DEVICE_INVENTORY_V2_SUCCESS,
        payload: {
          devices: transformDevices(devices, sites),
        },
      });
    } else {
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: apiResponse.res.data.message || `${sagaConstants.ISSUE_OCCURED} loading devices`,
          color: 'danger',
        },
      });
      yield put({ type: ActionTypeConstants.LOAD_DEVICE_INVENTORY_V2_FAILURE });
      yield delay(10000);
      yield put({ type: ActionTypeConstants.HIDE_ALERT });
    }
  } catch (err) {
    yield put({
      type: ActionTypeConstants.SHOW_ALERT,
      payload: {
        message: `${sagaConstants.SERVER_ERROR}`,
        color: 'danger',
      },
    });
    yield delay(10000);
    yield put({ type: ActionTypeConstants.HIDE_ALERT });
    yield put({ type: ActionTypeConstants.LOAD_DEVICE_INVENTORY_V2_FAILURE });
  }
}

export default function* () {
  yield takeLatest(ActionTypeConstants.LOAD_DEVICE_INVENTORY_V2, loadDeviceInvetoryV2Saga);
}
