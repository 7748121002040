import { call, put, takeLatest, delay } from 'redux-saga/effects';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { actionApiCall } from 'helpers/api/ApiCalls';
import { push } from 'redux-first-history';
import Cookies from 'js-cookie';
import * as sagaConstants from 'helpers/constants/SagasConstants';
import { getPermissions } from 'helpers/utils/getPermissions/getPermissions';
import { redirectToMyTasks, redirectToOrganizations } from 'helpers/utils';

/**
 * Login user from login form submission.
 */
function* submitUserLoginFormSaga({ payload, type }) {
  try {
    const apiResponse = yield call(actionApiCall, {
      actionType: ActionTypeConstants.SUBMIT_USER_LOGIN_FORM,
      data: {
        organization: false,
        email: payload.email,
        password: payload.password,
      },
    });

    // yield delay(400);

    if (apiResponse.res.data.response === 200) {
      const { data } = apiResponse.res.data;

      const apiResponse1 = yield call(actionApiCall, {
        actionType: ActionTypeConstants.LOAD_USER_ORG_SITE,
        data: {
          organization: false,
        },
      });

      yield put({
        type: ActionTypeConstants.SUBMIT_USER_LOGIN_FORM_SUCCESS,
        payload: data,
      });

      // Set application logged in profile
      yield put({
        type: ActionTypeConstants.AUTH_LOGGED_IN_SUCCESS,
        payload: data,
      });

      if (!data?.mfa?.verifiable_by?.some((method) => method.email_activated_at || method.phone_activated_at) || data?.mfa?.remember_device) {
        Cookies.set('isLogged', true);

        const isSuper = (data.role && data.role.map((r) => r.is_super === 't' || r.is_coach === 't').includes(true)) || false;

        const orgs = [];
        const sites = [];
        if (data.role) {
          for (const r of data.role) {
            orgs.push(r.organization);
            sites.push(r.site);
          }
        }
        const isOneSite = [...new Set(orgs)].length === 1 && [...new Set(sites)].length === 1;

        if (isSuper) {
          const ui_version = apiResponse1?.res?.data?.data.organizations['1'].ui_version || "0";
          yield put({ type: ActionTypeConstants.LOAD_USER_ORG_SITE });
          yield put({
            type: ActionTypeConstants.SET_USER_ORG_SITE_SUCCESS,
            payload: {
              selected: {
                org: '1',
                is_admin: true,
                is_super: data.role[0].is_super === 't',
                is_coach: data.role[0].is_coach === 't',
                ui_version
              },
            },
          });
          yield put({
            type: ActionTypeConstants.LOAD_ORG_MODULES,
          });
          Cookies.set('org', '1');
          yield put(push(redirectToOrganizations(ui_version)));
        } else if (data.registered === 'f') {
          // redirect to register
          yield put(push('/login/register'));
        } else if (isOneSite && data.role[0].name !== 'Org Administrator' && !data.role[0].name.includes('Officer')) {
          Cookies.set('org', data.role[0].organization);
          if (data.role[0].site !== '' && data.role[0].site !== '*') {
            Cookies.set('site', data.role[0].site);
          }
          yield put({ type: ActionTypeConstants.LOAD_USER_ORG_SITE });
          const parsedData = yield JSON.parse(JSON.stringify(data));
          const siteClean = parsedData.role[0].site !== '' && parsedData.role[0].site !== '*' ? parsedData.role[0].site : undefined;
          const orgClean = parsedData.role[0].organization;
          const perms = yield getPermissions(parsedData.role, orgClean, siteClean);
          const ui_version = apiResponse1?.res?.data?.data.organizations[orgClean].ui_version || "0";
          yield put({
            type: ActionTypeConstants.SET_USER_ORG_SITE_SUCCESS,
            payload: {
              selected: {
                org: orgClean,
                site: siteClean,
                ui_version,
                ...perms,
              },
            },
          });
          yield put({
            type: ActionTypeConstants.LOAD_ORG_MODULES,
          });
          // yield put(push('/'));
          yield put(push(redirectToMyTasks(ui_version)));
        } else {
          // redirect to site select
          yield payload.redirect ? put(push(`/login/site/${payload.redirect}`)) : put(push('/login/site'));
          // load orgs for drop-down for selected user
          yield put({ type: ActionTypeConstants.LOAD_USER_ORG_SITE });
        }
      }
    } else if (apiResponse.res.data.response === 400) {
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: apiResponse.res.data.message || 'Invalid username or password!',
          color: 'danger',
        },
      });
      yield delay(500);
      yield put({ type: ActionTypeConstants.SUBMIT_USER_LOGIN_FORM_RESET });
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: ActionTypeConstants.SHOW_ALERT,
      payload: {
        message: `${sagaConstants.SERVER_ERROR}`,
        color: 'danger',
      },
    });
  }
}

export default function* SubmitUserLoginFormSaga() {
  yield takeLatest(ActionTypeConstants.SUBMIT_USER_LOGIN_FORM, submitUserLoginFormSaga);
}
