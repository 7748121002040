import React from 'react';
import { useAppSelector } from 'hooks';
import Header from 'components/HeaderNew';
import HeaderUser from 'components/HeaderUserNew';
import Footer from 'components/Footer/Footer';
import styles from './AppLayoutWrapFull.module.scss';

interface IProps {
  setActiveTab?: (e) => void;
  children?: React.ReactNode;
  onClick?: (e) => void;
}

const defaultProps = {
  setActiveTab: () => false,
  children: undefined,
  onClick: () => false,
};

function AppLayoutWrapFull({ setActiveTab, onClick, children }: IProps) {
  const UserOrgSiteReducer = useAppSelector((state) => state.UserOrgSiteReducer);
  const is_admin = UserOrgSiteReducer?.selected?.is_admin || false;
  const is_super = UserOrgSiteReducer?.selected?.is_super || false;
  const is_coach = UserOrgSiteReducer?.selected?.is_coach || false;

  return (
    <div className={styles.holder} onClick={onClick} aria-hidden="true">
      <div>{is_admin || is_super || is_coach ? <Header /> : <HeaderUser setActiveTab={setActiveTab} />}</div>
      <div className={styles.content}>
        {children}
      </div>
      <div id="modal-root" />
      <Footer />
    </div>
  );
}

AppLayoutWrapFull.defaultProps = defaultProps;

export default AppLayoutWrapFull;
