import React, { useEffect } from 'react';
import classNames from 'classnames';

import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

import styles from './Drawer.module.scss';

interface IProps {
    children: JSX.Element[] | JSX.Element;
    items: JSX.Element[] | JSX.Element | string;
    isExpanded: boolean;
    setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}

function Drawer({
    children,
    items = undefined,
    isExpanded = undefined,
    setIsExpanded = undefined,
}: IProps) {

    const stylesDrawer = classNames(styles.drawer, !isExpanded && styles['drawer-items-collapsed']);
    const stylesDrawerItems = classNames(styles['drawer-items'], !isExpanded && styles['drawer-items-collapsed']);
    const stylesDrawerOther = classNames(styles['drawer-other'], !isExpanded && styles['drawer-items-collapsed']);

    useEffect(() => {
        if (isExpanded !== undefined) localStorage.setItem('isMenuExpanded', String(isExpanded));
    }, [isExpanded]);

    return (
        <div className={stylesDrawer}>
            <div className={stylesDrawerItems}>
                <div className={styles['items-holder']}>
                    <SimpleBar style={{ maxHeight: '100vh', overflow: 'hidden auto' }}>
                        {items}
                    </SimpleBar>
                </div>
            </div>
            <div className={stylesDrawerOther}>
                <div className={styles.overlay} onClick={() => setIsExpanded(false)}></div>
                {children}
            </div>
        </div>
    );
}

export default Drawer;
