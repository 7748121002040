import React from 'react';

import Line from './Line';

import styles from './LineChartBox.module.scss';

const LineChartBox = ({ stats = {} }) => {
  return (
    <div className={styles.linechart}>
      <div className={styles.holder}>
        <div className={styles.left}>
          <h2>
            Progress
            <br />
            breakdown
          </h2>
          <p>Click on each bar to see more information about the category</p>
        </div>
        <div className={styles.right}>
          <Line url="/assessments" stats={stats.audits || 0} backgroundColor={'#34c7594b'} color={'#34C759'} label={'Assessments'} />
          <Line url="/incidents" stats={stats.incidents || 0} backgroundColor={'#FF90003f'} color={'#FF9000'} label={'Incidents'} />
          <Line url="/assessments/remediations" stats={stats.remediation || 0} backgroundColor={'#ff2d533d'} color={'#FF2D55'} label={'Remediations'} />
          <Line url="/trainings" stats={stats.attestation || 0} backgroundColor={'#546ADC42'} color={'#546ADC'} label={'Trainings / Attestations'} />
        </div>
      </div>
    </div>
  );
};

export default LineChartBox;
