/* eslint-disable no-param-reassign */

import { call, put, takeLatest, select, delay } from 'redux-saga/effects';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
import * as sagaConstants from 'helpers/constants/SagasConstants';
import { actionApiCall } from 'helpers/api/ApiCalls';
import { Audit } from 'types/Audit';

const getItems = (state) => state.SitesReducer.sites;
const getActs = (state) => state.ActsReducer.acts;
const getSelected = (state) => state.UserOrgSiteReducer.selected;

function transformAudits(audits: Audit[], sites, acts) {
  const result = audits.map((audit) => {
    audit.status = audit.configuration.status_label;
    if (sites) {
      for (const item of sites) {
        if (item.id === audit.site) {
          audit.siteName = item.name;
        }
      }
    }
    audit.deactivated = audit.configuration && audit.configuration.status === '8' ? 't' : 'f';
    audit.frequency_label = `${audit.configuration.frequency_label} ${audit.configuration.reset ? '(Reset)' : ''}`;
    if (acts) {
      for (const item of acts) {
        if (item.id === audit.act) {
          audit.actName = item.name;
        }
      }
    }
    // if (audit.type === "device") {
    //     audit.status = 'Ongoing';
    // }
    return audit;
  });
  return result;
}

function* loadAuditsSaga({ payload, type }) {
  const assessments = payload?.assessments || false;
  try {
    const selected = yield select(getSelected);
    const apiResponse = yield call(actionApiCall, {
      actionType: ActionTypeConstants.LOAD_AUDITS,
      data: {
        organization: selected.org,
      },
    });
    if (apiResponse.res.data.response === 200) {
      const { data } = apiResponse.res.data;
      const sites = yield select(getItems);
      const acts = yield select(getActs);
      const audits = transformAudits(data, sites, acts);
      yield put({
        type: ActionTypeConstants.LOAD_AUDITS_SUCCESS,
        payload: {
          audits: assessments
          ?
          audits.filter((a) => (a.type !== 'cloud' && a.type !== 'inventory'))
          :
          audits,
        },
      });
    } else {
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: apiResponse.res.data.message || `${sagaConstants.ISSUE_OCCURED} loading audits`,
          color: 'danger',
        },
      });
      yield delay(10_000);
      yield put({ type: ActionTypeConstants.HIDE_ALERT });
    }
  } catch {
    yield put({
      type: ActionTypeConstants.SHOW_ALERT,
      payload: {
        message: `${sagaConstants.SERVER_ERROR}`,
        color: 'danger',
      },
    });
    yield delay(10_000);
    yield put({ type: ActionTypeConstants.HIDE_ALERT });
  }
}

export default function* LoadAuditsSaga() {
  yield takeLatest(ActionTypeConstants.LOAD_AUDITS, loadAuditsSaga);
}
