import { put, takeLatest, delay } from 'redux-saga/effects';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
import * as sagaConstants from 'helpers/constants/SagasConstants';

function* paginationOrganizationsSaga({ payload }) {
  try {
    if (payload.dataType === 'organizations') {
      yield put({
        type: ActionTypeConstants.LOAD_ORGANIZATIONS_SUCCESS,
        payload: {
          page: payload.currentPage + 1,
        },
      });
    }
  } catch (err) {
    yield put({
      type: ActionTypeConstants.SHOW_ALERT,
      payload: {
        message: `${sagaConstants.SERVER_ERROR}`,
        color: 'danger',
      },
    });
    yield delay(10000);
    yield put({ type: ActionTypeConstants.HIDE_ALERT });
  }
}

export default function* () {
  yield takeLatest(ActionTypeConstants.SET_PAGINATION, paginationOrganizationsSaga);
}
