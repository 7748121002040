import React from 'react';
import MainLayout from 'v2/layouts/MainLayout/MainLayout';

import Users from 'v2/views/People/Users/Users';

function People() {
  return (
    <>
      <MainLayout>
        <Users />
      </MainLayout>
    </>
  );
}

export default People;
