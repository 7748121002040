import React from 'react';
import styles from './Dimmer.module.scss';

interface IDimmer {
  active?: boolean;
}

const defaultProps = {
  active: false,
};

function Dimmer({ active }: IDimmer) {
  return (
    <div className={active ? styles.dimmer : styles['dimmer-hidden']} data-testid="dimmer">
      <i className="fa fa-spinner" aria-hidden="true" />
    </div>
  );
}

Dimmer.defaultProps = defaultProps;

export default Dimmer;
