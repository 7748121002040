import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import HamburgerNav from './Header/HamburgerNav';
import UserPanel from './Header/UserPanel';
import logoImg from 'assets/img/cg-logo.png';
import styles from './Header.module.scss';

const HeaderUser = (props) => {
  const navigate = useNavigate();
  const [activeMenu, setActiveMenu] = useState('DASHBOARD');
  const location = useLocation();

  // Renders main navigation items
  const navItems = [
    {
      icon: 'fad fa-tachometer-fast',
      key: 'DASHBOARD',
      path: '/',
      title: 'Dashboard',
      onClick: () => navigate('/'),
    },
    {
      icon: 'fad fa-copy',
      key: 'INCIDENT',
      path: '/report-incident',
      title: 'Report incident',
      onClick: () => navigate('/report-incident'),
    },
    {
      icon: 'fad fa-comment-alt-exclamation',
      key: 'KNOWLEDGE BASE',
      path: '/',
      title: 'Knowledge Base',
      onClick: () => window.open('https://compliancygroup.freshdesk.com/support/home'),
    },
  ];

  useEffect(() => {
    if (location.pathname === '/') {
      setActiveMenu('DASHBOARD');
    }
    if (location.pathname.includes('/report-incident')) {
      setActiveMenu('INCIDENT');
    }
  }, [location.pathname]);

  return (
    <div className={styles.header}>
      <div>
        <div className={styles.logo}>
          <img onClick={() => navigate('/')} src={logoImg} alt="The Guard" />
        </div>
        <div className={styles.menu}>
          {navItems.map((item) => {
            return (
              <div className={activeMenu === item.key ? styles.active : null} key={item.key} onClick={item.onClick}>
                <i className={`${styles.icon} ${item.icon}`} />
                {item.title}
              </div>
            );
          })}
        </div>
        <div className={styles.panel}>
          <div className="header">
            <div className="nav-panel">
              <div className="top-nav-panel">
                <div className="user-nav">
                  <UserPanel />
                  <span className={styles.panel_hamburger}>
                    <HamburgerNav />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderUser;
