import React from 'react';
import { useMsal } from '@azure/msal-react';
import { Button } from 'components/Button/Button';
import styles from '../AzureADModal.module.scss';

export function SignOutButton() {
  const { instance } = useMsal();

  const handleLogout = () => {
    instance.logoutPopup({
      postLogoutRedirectUri: '/',
      mainWindowRedirectUri: '/',
    });
  };

  return (
    <div className={styles.sign_out}>
      <Button color="secondary" className={styles.sign_out_btn} text="Log out AD" onClick={handleLogout} icon="fa-brands fa-microsoft" />
    </div>
  );
}

export default SignOutButton;
