import { call, put, takeLatest, delay } from 'redux-saga/effects';

import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { actionApiCall } from 'helpers/api/ApiCalls';
import * as sagaConstants from 'helpers/constants/SagasConstants';

function* uploadAuditSaga({ payload }) {
  try {
    const apiResponse = yield call(actionApiCall, {
      actionType: ActionTypeConstants.UPLOAD_AUDIT,
      data: {
        audit: payload.id,
        file: payload.file,
      },
    });
    if (apiResponse.res.data.response === 200) {
      // yield put({
      //     type: ActionTypeConstants.REOPEN_AUDIT_SUCCESS,
      //     payload
      // });
      yield put({ type: ActionTypeConstants.LOAD_AUDITS, payload: payload.assessments ? { assessments: true } : undefined });
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: apiResponse.res.data.message || `The audit ${sagaConstants.SUCCESSFULLY_UPLOADED}`,
          color: 'success',
        },
      });
      yield delay(10_000);
      yield put({ type: ActionTypeConstants.HIDE_ALERT });
    } else {
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: apiResponse.res.data.message || `${sagaConstants.ISSUE_OCCURED} uploading the audit`,
          color: 'danger',
        },
      });
      yield delay(10_000);
      yield put({ type: ActionTypeConstants.HIDE_ALERT });
    }
  } catch {
    yield put({
      type: ActionTypeConstants.SHOW_ALERT,
      payload: {
        message: `${sagaConstants.SERVER_ERROR}`,
        color: 'danger',
      },
    });
    yield delay(10_000);
    yield put({ type: ActionTypeConstants.HIDE_ALERT });
  }
}

export default function* UploadAuditSaga() {
  yield takeLatest(ActionTypeConstants.UPLOAD_AUDIT, uploadAuditSaga);
}
