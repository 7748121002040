import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import useFilterRows from 'helpers/customHooks/useFilterRows';
import AppLayoutWrapFull from 'components/AppLayoutWrapFull';
import PageHeader from 'components/PageHeader/PageHeader';
import PageHeaderActions from 'components/PageHeader/components/PageHeaderActions/PageHeaderActions';
import Container from 'components/Container/Container';
import FullDataTable from 'components/DataTable/FullDataTable';
import TableDefAffiliates from 'table-defs/TableDefAffiliates';

import { useAppDispatch, useAppSelector, useConfirmModal } from 'hooks';
import {
  loadAffiliatesAction,
  deleteAffiliateAction,
  notifyAffiliateAction,
  sendVendorAuditAffiliateAction,
  readVendorAuditAffiliateAction,
  sendConfAgreementAffiliateAction,
  approveAffiliateAction,
  setPaginationAction,
  declineAffiliateAction,
  sendBaAgreementAffiliateAction,
  submitEditAffiliateFormAction,
  emailAffiliateFilesAction,
} from 'store/actions/Actions';

import { setSelectedRows, toggleDropdowns, exportAsCSV, isEmailValid } from 'helpers/utils';
import { AFFILIATES_PAGE } from 'helpers/constants/DataTableConstants';

import Spinner from 'components/Spinner/Spinner';
import ActionStatusConstants from 'helpers/ActionStatusConstants';
import BulkUploadModal from './components/BulkUploadModal/BulkUploadModal';
import VendorAuditModal from './components/VendorAuditModal/VendorAuditModal';

const labels = () => ({
  name: {
    label: 'NAME',
  },
  'agreement-type': {
    label: 'AGREEMENT TYPE',
  },
  audit_status: {
    label: 'AUDIT STATUS',
  },
  status: {
    label: 'APPROVAL STATUS',
  },
});

function Affiliates(props) {
  const { loadAffiliatesAction, setPaginationAction } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const DatatableReducer = useAppSelector((state) => state.DatatableReducer);
  const AffiliatesReducer = useAppSelector((state) => state.AffiliatesReducer);
  const UserOrgSiteReducer = useAppSelector((state) => state.UserOrgSiteReducer);

  const [state, setState] = useState({
    selectedRows: undefined,
    selected_sites: [],
  });

  const [showConfirmModal, setShowConfirmModal, renderConfirmModal] = useConfirmModal();
  const [isLoading, setLoading] = useState(true);
  const [sortKey, setSortKey] = useState(DatatableReducer.datatable[AFFILIATES_PAGE]?.sortKey);
  const [sortOrder, setSortOrder] = useState(DatatableReducer.datatable[AFFILIATES_PAGE]?.sortOrder);

  useEffect(() => {
    if (!AffiliatesReducer.affiliates) {
      loadAffiliatesAction();
    }
  }, [AffiliatesReducer.affiliates, loadAffiliatesAction]);

  useEffect(() => {
    if (AffiliatesReducer.status === ActionStatusConstants.SUCCESS) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [AffiliatesReducer.affiliates]);

  const openAddNewAffiliate = () => {
    navigate('/achieve/affiliates/add');
  };

  // ##############################################
  const noFilters = {
    text: '',
    type: '',
    'last-action': '',
    affiliate_status: '',
    status: '',
    role: '',
    site: '',
  };

  const [filters, setFilters] = useState(DatatableReducer?.datatable?.affiliates?.filters || noFilters);

  const onChangeRowSelect = ({ selectedRows }) => {
    if (selectedRows !== state.selectedRows) {
      setState((s) => ({ ...s, selectedRows }));
    }
  };

  const [selectedAffiliateIds, setSelectedAffiliateIds] = useState(DatatableReducer?.datatable?.affiliates?.selectedIds || []);
  useEffect(() => {
    dispatch({
      type: 'SET_DATATABLE',
      payload: {
        datatable: {
          affiliates: {
            selectedIds: selectedAffiliateIds,
            filters,
            selectedRows: state.selectedRows,
            selected_sites: state.selected_sites,
            rowsPerPage: DatatableReducer?.datatable?.affiliates?.rowsPerPage || 10,
            currentPage: DatatableReducer?.datatable?.affiliates?.currentPage || 0,
            sortKey,
            sortOrder,
          },
        },
      },
    });
  }, [selectedAffiliateIds, filters, state, dispatch, sortKey, sortOrder]);

  const setFilter = (filterName) => (e) => {
    setFilters({
      ...filters,
      [filterName]:
        e.target.type === 'checkbox' ? e.target.checked : e.target.value || (e.target && e.target.attributes && e.target.attributes.value && e.target.attributes.value.value) || '',
    });
    setPaginationAction({ dataType: AFFILIATES_PAGE, currentPage: 0 });
  };
  const filteredRows = useFilterRows(filters, AffiliatesReducer.affiliates);
  // ##############################################

  const [showBulkUploadModal, setShowBulkUploadModal] = useState(false);
  const [showVendorAuditModal, setVendorAuditModal] = useState(false);

  // Used to close the dropdown when clicking outside of it if it's open
  const typeDropdownRef = React.createRef();
  const lastActionDropdownRef = React.createRef();
  const affiliateStatusDropdownRef = React.createRef();
  const statusDropdownRef = React.createRef();
  const chooseActionDropdownRef = React.createRef();
  const selectedIds = selectedAffiliateIds.map((item) => item.id);

  const is_coach = (UserOrgSiteReducer.selected && UserOrgSiteReducer.selected.is_coach) || false;
  const is_super = (UserOrgSiteReducer.selected && UserOrgSiteReducer.selected.is_super) || false;

  const invalidEmail = {
    title: 'Invalid e-mail!',
    question: "Please, make sure vendor's email address is valid!",
    confirmAction: undefined,
  };

  return (
    <AppLayoutWrapFull onClick={toggleDropdowns([typeDropdownRef, lastActionDropdownRef, affiliateStatusDropdownRef, statusDropdownRef, chooseActionDropdownRef])}>
      <PageHeader showStats>
        <PageHeaderActions
          primaryButtonText="ADD VENDOR"
          primaryButtonHandler={openAddNewAffiliate}
          secondaryButtonText="BULK UPLOAD"
          secondaryButtonHandler={() => setShowBulkUploadModal(!showBulkUploadModal)}
          searchHandler={(e) => {
            setFilters({ ...filters, text: e.target.value || '' });
            setPaginationAction({ dataType: AFFILIATES_PAGE, currentPage: 0 });
          }}
          searchValue={filters.text}
          affiliateStatusDropdownRef={affiliateStatusDropdownRef}
          statusDropdownRef={statusDropdownRef}
          chooseActionDropdownRef={chooseActionDropdownRef}
          chooseAction={[
            !is_coach && {
              actionName: 'Approve Selected',
              actionHandler: () => {
                const idCount = selectedIds.length;

                if (idCount > 0) {
                  setShowConfirmModal({
                    title: 'Approve Vendor',
                    question: idCount === 1 ? 'Are you sure you want to approve the selected vendor?' : `Are you sure you want to approve ${idCount} selected vendors?`,
                    confirmAction: () => {
                      props.approveAffiliateAction({ items: selectedIds });
                    },
                  });
                } else {
                  setShowConfirmModal({
                    type: 'simple',
                    title: 'Nothing selected',
                    question: 'Please select some vendors.',
                  });
                }
              },
            },
            !is_coach && {
              actionName: 'Decline Selected',
              actionHandler: () => {
                const idCount = selectedIds.length;

                if (idCount > 0) {
                  setShowConfirmModal({
                    title: 'Decline Vendor',
                    question: idCount === 1 ? 'Are you sure you want to decline the selected vendor?' : `Are you sure you want to decline ${idCount} selected vendors?`,
                    confirmAction: () => {
                      props.declineAffiliateAction({ items: selectedIds });
                    },
                  });
                } else {
                  setShowConfirmModal({
                    type: 'simple',
                    title: 'Nothing selected',
                    question: 'Please select some vendors.',
                  });
                }
              },
            },
            {
              actionName: 'Send Vendor Audit to Selected',
              actionHandler: () => {
                const idCount = selectedIds.length;

                if (idCount > 0) {
                  setShowConfirmModal({
                    title: 'Send Vendor Audit',
                    question:
                      idCount === 1
                        ? 'Are you sure you want to send vendor audit to the selected vendor?'
                        : `Are you sure you want to send vendor audit to ${idCount} selected vendors?`,
                    confirmAction: () => {
                      props.sendVendorAuditAffiliateAction({
                        items: selectedIds,
                      });
                    },
                  });
                } else {
                  setShowConfirmModal({
                    type: 'simple',
                    title: 'Nothing selected',
                    question: 'Please select some vendors.',
                  });
                }
              },
            },
            {
              actionName: 'Send BA Agreement to Selected',
              actionHandler: () => {
                const idCount = selectedIds.length;

                if (idCount > 0) {
                  setShowConfirmModal({
                    title: 'Send BA Agreement',
                    question:
                      idCount === 1
                        ? 'Are you sure you want to send BA Agreement to the selected vendor?'
                        : `Are you sure you want to send BA Agreement to ${idCount} selected vendors?`,
                    confirmAction: () => {
                      props.sendBaAgreementAffiliateAction({
                        items: selectedIds,
                      });
                    },
                  });
                } else {
                  setShowConfirmModal({
                    type: 'simple',
                    title: 'Nothing selected',
                    question: 'Please select some vendors.',
                  });
                }
              },
            },
            {
              actionName: 'Send Confidentiality Agreement to Selected',
              actionHandler: () => {
                const idCount = selectedIds.length;

                if (idCount > 0) {
                  setShowConfirmModal({
                    title: 'Send Confidentiality Agreement',
                    question:
                      idCount === 1
                        ? 'Are you sure you want to send Confidentiality Agreement to the selected vendor?'
                        : `Are you sure you want to send Confidentiality Agreement to ${idCount} selected vendors?`,
                    confirmAction: () => {
                      props.sendConfAgreementAffiliateAction({
                        items: selectedIds,
                      });
                    },
                  });
                } else {
                  setShowConfirmModal({
                    type: 'simple',
                    title: 'Nothing selected',
                    question: 'Please select some vendors.',
                  });
                }
              },
            },
            {
              actionName: 'Export Selected',
              actionHandler: () => {
                const data = AffiliatesReducer.affiliates.filter((item) => selectedAffiliateIds.map((i) => i.id).includes(item.id));
                exportAsCSV(data, labels, 'vendors.csv');
              },
            },
            {
              actionName: 'Delete Selected',
              actionHandler: () => {
                const idCount = selectedIds.length;

                if (idCount > 0) {
                  setShowConfirmModal({
                    title: 'Delete selected vendors',
                    question: idCount === 1 ? 'Are you sure you want to delete the selected vendor?' : `Are you sure you want to delete ${idCount} selected vendors?`,
                    confirmAction: () => {
                      props.deleteAffiliateAction({ items: selectedIds });
                    },
                  });
                } else {
                  setShowConfirmModal({
                    type: 'simple',
                    title: 'Nothing selected',
                    question: 'Please select vendors to be deleted.',
                  });
                }
              },
            },
          ]}
          affiliateStatus={
            (is_super || is_coach) && {
              onChange: setFilter('affiliate_status'),
              options: ['All', 'Active', 'Inactive'],
            }
          }
          status={{
            onChange: setFilter('status'),
            options: ['All', 'Approved', 'Declined', 'Under Review'],
          }}
          resetFilters={() => {
            setFilters(noFilters);
            setPaginationAction({ dataType: AFFILIATES_PAGE, currentPage: 0 });
          }}
          filters={filters}
        />
      </PageHeader>
      {isLoading ? (
        <Spinner />
      ) : (
        <Container wide fluid>
          {showVendorAuditModal && ReactDOM.createPortal(<VendorAuditModal setShowModal={setVendorAuditModal} />, document.querySelector('#modal-root'))}
          {showBulkUploadModal && ReactDOM.createPortal(<BulkUploadModal setShowModal={setShowBulkUploadModal} />, document.querySelector('#modal-root'))}
          {AffiliatesReducer.affiliates && (
            <FullDataTable
              rowsData={filteredRows}
              definitions={TableDefAffiliates}
              dataType={AFFILIATES_PAGE}
              setSortKey={setSortKey}
              setSortOrder={setSortOrder}
              onChangeRowSelect={({ selectedRows }) => {
                onChangeRowSelect({ selectedRows });
              }}
              onRowClick={function onRowClick(e) {
                if (e.currentTarget.classList.contains('col-actions') || e.currentTarget.classList.contains('col-row-selector')) return;
                navigate(`/achieve/affiliates/edit?id=${this.props.rowData.id}`);
              }}
              setSelectedRows={setSelectedRows(['id'], setSelectedAffiliateIds)}
              customTableProps={{
                edit: ({ id }) => navigate(`/achieve/affiliates/edit?id=${id}`),
                approve: !is_coach
                  ? ({ id }) => {
                      setShowConfirmModal({
                        title: 'Approve Vendor',
                        question: 'Are you sure you want to approve the vendor?',
                        confirmAction: () => {
                          props.approveAffiliateAction({ items: [id] });
                        },
                      });
                    }
                  : undefined,
                decline: !is_coach
                  ? ({ id }) => {
                      setShowConfirmModal({
                        title: 'Decline Vendor',
                        question: 'Are you sure you want to decline the vendor?',
                        confirmAction: () => {
                          props.declineAffiliateAction({ items: [id] });
                        },
                      });
                    }
                  : undefined,
                email: ({ id, contact_email }) => {
                  setShowConfirmModal(
                    isEmailValid(contact_email)
                      ? {
                          title: 'Email Vendor Files',
                          question: "Are you sure you want to send vendor files to the vendor's email?",
                          confirmAction: () => {
                            props.emailAffiliateFilesAction(id);
                          },
                        }
                      : invalidEmail
                  );
                },
                delete: ({ id, name }) => {
                  setShowConfirmModal({
                    title: 'Delete the vendor',
                    question: `Are you sure you want to remove the vendor "${name}"`,
                    confirmAction: () => {
                      props.deleteAffiliateAction({ items: [id] });
                    },
                  });
                },
                read_vendor_audit: ({ id }) => {
                  setVendorAuditModal(true);
                  props.readVendorAuditAffiliateAction({ affiliate: id });
                },
                send_vendor_audit: ({ id, contact_email }) => {
                  setShowConfirmModal(
                    isEmailValid(contact_email)
                      ? {
                          title: 'Send Vendor Audit',
                          question: "Are you sure you want to send vendor audit to the vendor's email?",
                          confirmAction: () => {
                            props.sendVendorAuditAffiliateAction({
                              items: [id],
                            });
                          },
                        }
                      : invalidEmail
                  );
                },
                send_ba_agreement: ({ id, contact_email }) => {
                  setShowConfirmModal(
                    isEmailValid(contact_email)
                      ? {
                          title: 'Send BA Agreement',
                          question: "Are you sure you want to send BA Agreement to the vendor's email?",
                          confirmAction: () => {
                            props.sendBaAgreementAffiliateAction({
                              items: [id],
                            });
                          },
                        }
                      : invalidEmail
                  );
                },
                send_confidentiality_agreement: ({ id, contact_email }) => {
                  setShowConfirmModal(
                    isEmailValid(contact_email)
                      ? {
                          title: 'Send Confidentiality Agreement',
                          question: "Are you sure you want to send Confidentiality Agreement to the vendor's email?",
                          confirmAction: () => {
                            props.sendConfAgreementAffiliateAction({
                              items: [id],
                            });
                          },
                        }
                      : invalidEmail
                  );
                },
                selectedRows: state.selectedRows || selectedAffiliateIds,
              }}
            />
          )}
          {showConfirmModal && renderConfirmModal(showConfirmModal, setShowConfirmModal)}
        </Container>
      )}
    </AppLayoutWrapFull>
  );
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  loadAffiliatesAction,
  deleteAffiliateAction,
  notifyAffiliateAction,
  sendVendorAuditAffiliateAction,
  readVendorAuditAffiliateAction,
  approveAffiliateAction,
  declineAffiliateAction,
  setPaginationAction,
  sendBaAgreementAffiliateAction,
  sendConfAgreementAffiliateAction,
  submitEditAffiliateFormAction,
  emailAffiliateFilesAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Affiliates);
