import { call, put, takeLatest, delay, select } from 'redux-saga/effects';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { actionApiCall } from 'helpers/api/ApiCalls';
import * as sagaConstants from 'helpers/constants/SagasConstants';

const getSelected = (state) => state.UserOrgSiteReducer.selected;

function* restoreDocumentSaga({ payload }) {
  try {
    const selected = yield select(getSelected);
    const apiResponse = yield call(actionApiCall, {
      actionType: ActionTypeConstants.RESTORE_DOCUMENT,
      data: {
        organization: selected.org,
        permission_organization: selected.org,
        id: payload.id,
        version: payload.version,
      },
    });

    if (apiResponse.res.data.response === 200) {
      yield put({
        type: ActionTypeConstants.RESTORE_DOCUMENT_SUCCESS,
        payload: {
          document: apiResponse.res.data.data,
        },
      });
    } else {
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: apiResponse.message || `${sagaConstants.ISSUE_OCCURED} restoring the document`,
          color: 'danger',
        },
      });
      yield delay(10000);
      yield put({ type: ActionTypeConstants.HIDE_ALERT });
    }
  } catch (err) {
    yield put({
      type: ActionTypeConstants.SHOW_ALERT,
      payload: {
        message: `${sagaConstants.SERVER_ERROR}`,
        color: 'danger',
      },
    });
    yield delay(10000);
    yield put({ type: ActionTypeConstants.HIDE_ALERT });
  }
}

export default function* () {
  yield takeLatest(ActionTypeConstants.RESTORE_DOCUMENT, restoreDocumentSaga);
}
