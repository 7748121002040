import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import styles from './Add.module.scss';

// import AppLayoutWrapFull from 'components/AppLayoutWrapFull';
import PageHeader from 'v2/components/PageHeader/PageHeader';
import FormElement from 'components/FormElement/FormElement';
import Select from 'components/Select/Select';
import CostInput from 'components/CostInput/CostInput';
import Row from 'components/Row/Row';
import DateInput from 'components/DateInput/DateInput';
import AddTask from 'views/Achieve/Remediation/AddTask/AddTask';
import HR from 'components/HR/HR';
import Button from 'components/Button/Button';
import { submitAddRemediationForm, loadRolesAction, loadGapsAction, loadUsersAction, loadSitesAction, loadUsersByOrgAction } from 'store/actions/Actions';
import { toggleDropdowns } from 'helpers/utils';

const Add = (props) => {
  const navigate = useNavigate();
  const {
    SitesReducer,
    GapsReducer,
    loadGapsAction,
    // loadUsersAction,
    loadRolesAction,
    loadUsersByOrgAction,
    AuthReducer,
    UsersByOrgReducer,
    RolesReducer,
    loadSitesAction,
    submitAddRemediationForm,
    OrgModulesReducer,
    UserOrgSiteReducer,
  } = props;

  const [sitesList, setSiteList] = useState({ 0: 'All Sites' });
  const [tasks, setTasks] = useState([]);
  const [stateErrors, setStateErrors] = useState({});
  const defaultReg = (OrgModulesReducer.data && OrgModulesReducer.data.map((r) => r.name)) || [];

  const [state, setState] = useState({
    title: '',
    status: 'Pending',
    associated_gap: '',
    estimated_cost: 0,
    start_date: '',
    end_date: '',
    notes: '',
    site: 0,
    regulatory_act: defaultReg[0] || '',
    owner: '',
  });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    if (!SitesReducer.sites) {
      loadSitesAction();
    } else {
      const tmpList = {
        0: 'All Sites',
      };
      SitesReducer &&
        SitesReducer.sites &&
        SitesReducer.sites.forEach((site) => {
          tmpList[site.id] = site.name;
        });
      setSiteList(tmpList);
    }
  }, [SitesReducer.sites]);

  useEffect(() => {
    if (!RolesReducer.roles) {
      loadRolesAction();
    }
  }, [RolesReducer.roles, loadRolesAction]);

  // useEffect(() => {
  //     if (!UsersReducer.users) {
  //         loadUsersAction();
  //     }
  // }, [UsersReducer.users, loadUsersAction]);

  useEffect(() => {
    try {
      if (RolesReducer.roles) {
        loadUsersByOrgAction({
          site: state.site && state.site !== '0' ? state.site : undefined,
          role: RolesReducer.roles.map((role) => (!role.name.includes('User') ? Number(role.id) : '')).filter((item) => typeof item === 'number'),
        });
      }
    } catch (error) {
      console.log('error', error);
    }
  }, [state.site, RolesReducer.roles]);

  useEffect(() => {
    if (!GapsReducer.gaps) {
      loadGapsAction();
    }
  }, [GapsReducer.gaps, loadGapsAction]);

  useEffect(() => {
    setState((s) => ({
      ...s,
      user_id: AuthReducer.userData.id,
    }));
  }, [AuthReducer.userData]);

  useEffect(() => {
    setStateErrors((s) => ({ ...s, tasks: '' }));
  }, [tasks]);

  const handleChange = (inputName) => (e) => {
    e.persist();
    setState((s) => ({
      ...s,
      [inputName]: e.target.value,
    }));
    setStateErrors((s) => ({ ...s, [inputName]: '' }));
  };

  const handleSiteSelect = (inputName) => (e) => {
    e.persist();
    setState((s) => ({
      ...s,
      [inputName]: e.target.attributes && e.target.attributes.value ? e.target.attributes.value.value : '',
      owner: '',
    }));
    setStateErrors((s) => ({ ...s, [inputName]: '' }));
  };

  const handleRegSelect = (inputName) => (e) => {
    e.persist();
    setState((s) => ({
      ...s,
      [inputName]: e.target.attributes && e.target.attributes.value ? e.target.attributes.value.value : '',
    }));
    setStateErrors((s) => ({ ...s, [inputName]: '', type: '' }));
  };

  const submitRemediationPlan = (e) => {
    const estimatedCost = parseInt(state.estimated_cost, 10);
    if (
      state.title === '' ||
      // state.associated_gap === "" ||
      state.regulatory_act === '' ||
      state.start_date === '' ||
      state.end_date === '' ||
      tasks.length === 0 ||
      estimatedCost < 0
    ) {
      setStateErrors((s) => ({
        ...s,
        title: state.title === '' ? 'Title is required' : '',
        // associated_gap: state.associated_gap === "" ? "Associated gap is required" : "",
        regulatory_act: state.regulatory_act === '' ? 'Regulatory act is required' : '',
        start_date: state.start_date === '' ? 'Start date is required' : '',
        end_date: state.end_date === '' ? 'End date is required' : '',
        tasks: tasks.length === 0 ? 'At least one task is required' : '',
        estimated_cost: estimatedCost < 0 && "Estimated cost can't be negative",
      }));
    } else {
      e.preventDefault();
      submitAddRemediationForm({ ...state, tasks });
    }
  };

  let gapsList = {};
  if (GapsReducer && GapsReducer.gaps) {
    GapsReducer.gaps.forEach((gap) => {
      gapsList[gap.id] = gap.name;
    });
  }

  let usersList = {};
  if (UsersByOrgReducer && UsersByOrgReducer.users) {
    UsersByOrgReducer.users.forEach((user) => {
      usersList[user.id || user.user_id] = user.name || user.email;
    });
  }

  // [OPTIONAL]
  // A way to close dropdowns when clicking on parent if they are open
  const statusDropdownRef = React.createRef();
  const associatedGapDropdownRef = React.createRef();
  const assigneeDropdownRef = React.createRef();
  const ownerDropdownRef = React.createRef();
  const siteRef = React.createRef();
  const regTypeRef = React.createRef();

  return (
    <div>
      <PageHeader title="Add Remediation" />
      <div className={styles['form-wrapper']}>
        <form className={styles.form} onClick={toggleDropdowns([statusDropdownRef, associatedGapDropdownRef, ownerDropdownRef, assigneeDropdownRef, siteRef, regTypeRef])}>
          <FormElement labelText="TITLE" htmlFor="title" smallText="*" errorMessage={stateErrors.title}>
            <textarea
              style={{ padding: '10px', resize: 'vertical' }}
              rows={3}
              name="title"
              type="text"
              placeholder="Enter Plan Title"
              value={state.title}
              onChange={handleChange('title')}
            />
          </FormElement>
          <FormElement labelText="STATUS" htmlFor="status" errorMessage={stateErrors.status}>
            <Select dropdownToggleRef={statusDropdownRef} value={state.status} disabled={true} onClick={null}>
              <li value="Pending">Pending</li>
            </Select>
          </FormElement>
          {/* <FormElement
                        labelText="ASSOCIATED GAP"
                        htmlFor="associated_gap"
                        info={true}
                        errorMessage={stateErrors.associated_gap}
                    >
                        <Select
                            placeholder="Select associated gap"
                            labels={gapsList}
                            dropdownToggleRef={associatedGapDropdownRef}
                            value={state.associated_gap}
                            onClick={handleChange("associated_gap")}
                        >
                            {GapsReducer.gaps && GapsReducer.gaps.map((gap) => {
                                return <li key={gap.id} value={gap.id}>{gap.name}</li>;
                            })}
                        </Select>
                    </FormElement> */}

          <FormElement labelText="SITE" htmlFor="site" errorMessage={stateErrors.site || ''}>
            <Select
              labels={sitesList}
              value={
                UserOrgSiteReducer && UserOrgSiteReducer.selected && UserOrgSiteReducer.selected.site && UserOrgSiteReducer.selected.site !== ''
                  ? UserOrgSiteReducer.selected.site
                  : state.site
              }
              placeholder="Select Site"
              onClick={handleSiteSelect('site')}
              dropdownToggleRef={siteRef}
            >
              <li key={`siteid-all`} data-name="All Sites" value={0}>
                All Sites
              </li>
              {SitesReducer.sites &&
                SitesReducer.sites
                  .filter((s) => s.active === 't')
                  .map((site) => (
                    <li key={`siteid-${site.id}`} data-name={site.name} value={site.id}>
                      {site.name}
                    </li>
                  ))}
            </Select>
          </FormElement>

          <FormElement labelText="PROGRAM" htmlFor="regulatory_act" errorMessage={stateErrors['regulatory_act'] || ''}>
            <Select placeholder="Select program" value={state.regulatory_act} onClick={handleRegSelect('regulatory_act')} dropdownToggleRef={regTypeRef}>
              {OrgModulesReducer &&
                OrgModulesReducer.data &&
                OrgModulesReducer.data.map((d) => (
                  <li key={`regid-${d.name.toLowerCase()}`} value={d.name}>
                    {d.name}
                  </li>
                ))}
            </Select>
          </FormElement>

          <Row>
            <FormElement labelText="START DATE" htmlFor="start_date" smallText="*" errorMessage={stateErrors.start_date}>
              <DateInput value={state.start_date} onChange={handleChange('start_date')} />
            </FormElement>
            <FormElement labelText="END DATE" htmlFor="end_date" smallText="*" errorMessage={stateErrors.end_date}>
              <DateInput value={state.end_date} onChange={handleChange('end_date')} />
            </FormElement>
          </Row>

          <FormElement labelText="OWNER" htmlFor="owner" info={false} errorMessage={stateErrors.owner}>
            <Select placeholder="Select owner" labels={usersList} dropdownToggleRef={ownerDropdownRef} value={state.owner} onClick={handleChange('owner')}>
              {UsersByOrgReducer &&
                UsersByOrgReducer.users &&
                UsersByOrgReducer.users
                  .filter((item) => item.active === 't')
                  .map((user) => (
                    <li key={user.email} data-name={user.name || user.email} value={user.id || user.user_id}>
                      {user.name || user.email}
                    </li>
                  ))}
            </Select>
          </FormElement>

          <FormElement labelText="ESTIMATED COST" htmlFor="estimated_cost" errorMessage={stateErrors.estimated_cost}>
            <CostInput value={state.estimated_cost} onChange={handleChange('estimated_cost')} />
          </FormElement>

          <AddTask
            regulatory_act={state.regulatory_act}
            allowAdd={true}
            setParentErrors={setStateErrors}
            errorMessage={stateErrors.tasks}
            className={styles['add-task']}
            site={state.site !== '' ? state.site : undefined}
            tasks={tasks}
            setTasks={setTasks}
            dropdownToggleRef={assigneeDropdownRef}
          />
          <FormElement labelText="NOTES" htmlFor="notes">
            <textarea className={styles.notes} value={state.notes} onChange={handleChange('notes')} />
          </FormElement>
          <HR className={styles['hr']} />
          <div className={styles['form-options']}>
            <Button text="BACK" color="secondary" onClick={() => navigate('/assessments/remediations')} />
            <Button text="SAVE" icon="far fa-save" onClick={submitRemediationPlan} />
          </div>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  AddRemediationPlanFormReducer: state.AddRemediationPlanFormReducer,
  RemediationReducer: state.RemediationReducer,
  EditRemediationPlanFormReducer: state.EditRemediationPlanFormReducer,
  GapsReducer: state.GapsReducer,
  AuthReducer: state.AuthReducer,
  UsersByOrgReducer: state.UsersByOrgReducer,
  SitesReducer: state.SitesReducer,
  RolesReducer: state.RolesReducer,
  OrgModulesReducer: state.OrgModulesReducer,
  UserOrgSiteReducer: state.UserOrgSiteReducer,
});

const mapDispatchToProps = {
  loadGapsAction,
  loadUsersAction,
  loadUsersByOrgAction,
  loadSitesAction,
  loadRolesAction,
  submitAddRemediationForm,
};

export default connect(mapStateToProps, mapDispatchToProps)(Add);
