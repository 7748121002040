import React, { useEffect } from 'react';
import MainLayout from 'v2/layouts/MainLayout/MainLayout';
import TasksTab from './TasksTab/TasksTab';
import { useAppDispatch, useAppSelector } from 'hooks';
import { loadDashboardTasksAction, loadSitesAction, loadUserCertificatesAction, loadUserCountersAction } from 'store/actions/Actions';
import Certificates from './Certificates/CertificatesUser';

function MyDocuments() {
  const SitesReducer = useAppSelector((state) => state.SitesReducer);
  // const UsersReducer = useAppSelector((state) => state.UsersReducer);
  const DashboardTasksReducer = useAppSelector((state) => state.DashboardTasksReducer);
  const CertificatesUserReducer = useAppSelector((state) => state.CertificatesUserReducer);
  const tasks = DashboardTasksReducer?.tasks?.filter((task) => task.deactivated !== 't');
  const documentsTasks = tasks?.filter((task) => task.type === 'document');

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!SitesReducer.sites) {
      dispatch(loadSitesAction());
    }
  }, [SitesReducer.sites]);

  useEffect(() => {
    if (SitesReducer.sites) {
      dispatch(loadDashboardTasksAction());
    }
  }, [SitesReducer.sites]);

  useEffect(() => {
    if (!CertificatesUserReducer.certificates) {
      dispatch(loadUserCertificatesAction());
    }
  }, [CertificatesUserReducer.certificates, dispatch]);

  useEffect(() => {
    dispatch(loadUserCountersAction());
  }, [loadUserCountersAction, documentsTasks]);  
  
  return (
    <>
      <MainLayout>
        <div style={{ width: "100%", padding: '10px 50px', marginBottom: '100px' }}>
          <br />
          <h1 style={{ fontWeight: 500, marginBottom: '40px' }}>My Documents</h1>
          {documentsTasks?.length > 0 && <TasksTab title="My Documents" type="document" dataType="documentsDash" tasks={documentsTasks} />}
          {documentsTasks?.length === 0 &&
            <h2 style={{ fontWeight: 500, marginBottom: '40px', fontSize: '22px' }}>
              You currently do not have any documents assigned that require your attention
            </h2>}

          {(CertificatesUserReducer?.certificates?.length > 0) && <>
            <br />
            <h1 style={{ fontWeight: 500, marginBottom: '40px' }}>Certificates</h1>
            <Certificates />
          </>}
        </div>
      </MainLayout>
    </>
  );
}

export default MyDocuments;
