import ActionStatusConstants from 'helpers/ActionStatusConstants';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
// import { LOCATION_CHANGE } from 'redux-first-history';

const initialState = {
  status: ActionStatusConstants.INITIAL,
  message: undefined,
  formErrors: undefined,
  folders: undefined,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypeConstants.LOAD_FOLDERS: {
      return {
        ...state,
        status: ActionStatusConstants.ISBUSY,
      };
    }
    case ActionTypeConstants.LOAD_FOLDERS_SUCCESS: {
      return {
        ...state,
        folders: payload.folders,
        status: ActionStatusConstants.SUCCESS,
        message: payload?.message,
      };
    }
    case ActionTypeConstants.LOAD_FOLDERS_FAILURE: {
      return {
        ...state,
        status: ActionStatusConstants.FAILURE,
        message: payload?.message,
      };
    }
    // case LOCATION_CHANGE:
    //     return {
    //         ...initialState
    //     };
    default:
  }
  return state;
};
