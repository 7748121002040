import ColVisibilitySelector from 'components/DataTable/ColVisibilitySelector';
import ItemActionsMenu from 'components/DataTable/ItemActionsMenu';
import React from 'react';
import moment from 'moment';
import Checkbox from 'components/Checkbox/Checkbox';
import Button from 'components/Button/Button';
import { getStatusButtonColor } from 'helpers/utils';

export default {
  TABLE: {
    className: 'data-table-all-users',
    initialSortKey: 'name',
    initialSortOrder: 'asc',
  },

  COLUMN: [
    {
      label: 'Row Selector',
      key: 'row-selector',
      header: ({ customTableProps }) => {
        const isSelectedAll =
          customTableProps.selectedRows &&
          customTableProps.selectedRows.length &&
          customTableProps.rowsData &&
          customTableProps.rowsData.length &&
          customTableProps.selectedRows.length === customTableProps.rowsData.length
            ? true
            : false;
        return <Checkbox checked={isSelectedAll} onChange={() => customTableProps.doSelectAll()} name="row-selector" />;
      },
      className: 'col-row-selector',
      format: ({ rowData, customTableProps }) => {
        const isSelected =
          customTableProps.selectedRows &&
          customTableProps.selectedRows.length &&
          customTableProps.selectedRows.some((checkbox) => {
            return checkbox.id === rowData.id;
          })
            ? true
            : false;
        return <Checkbox checked={isSelected} onChange={() => customTableProps.onRowSelect({ rowData })} name="row-selector" />;
      },
      canBeHidden: false,
    },
    // {
    //     label: "User ID",
    //     header: "ID",
    //     key: "id",
    //     sortable: true,
    //     canBeHidden: true,
    //     isHidden: true
    // },
    {
      label: 'Name',
      header: 'NAME',
      key: 'name',
      sortable: true,
      className: 'title',
      format: ({ rowData, customTableProps }) => {
        return <span>{rowData['name']}</span>; // name -> title
      },
    },
    {
      label: 'Email',
      header: 'EMAIL',
      key: 'email',
      sortable: true,
      canBeHidden: false,
    },
    // {
    //     label: "Site",
    //     header: "SITE",
    //     key: "site",
    //     sortable: true,
    //     className: "site",
    //     format: ({ rowData, customTableProps }) => {
    //         return <span>{rowData.siteName}</span>;
    //     }
    // },
    {
      label: 'Role',
      header: 'ROLE',
      key: 'role_str',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{rowData.role_str}</span>;
      },
    },
    {
      label: 'Last Modified',
      header: 'MODIFIED',
      key: 'last_modified',
      format: ({ rowData }) => {
        const date = rowData.last_modified ? moment(rowData.last_modified).format('MM/DD/YYYY') : 'N/A';
        return <span>{date}</span>;
      },
      canBeHidden: true,
      sortable: true,
    },
    {
      label: 'Date Created',
      header: 'CREATED',
      key: 'created_at',
      format: ({ rowData }) => {
        const date = rowData.created_at ? moment(rowData.created_at).format('MM/DD/YYYY') : 'N/A';
        return <span>{date}</span>;
      },
      canBeHidden: true,
      sortable: true,
    },
    {
      label: 'Status',
      header: 'STATUS',
      key: 'status',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData, customTableProps }) => {
        const status = rowData.status;
        const color = getStatusButtonColor(status, ['Active'], ['Inactive']);
        return (
          <span>
            <Button cursorDefault={customTableProps.cursorDefault} color={color} size="md" text={status} onClick={() => null} />
          </span>
        );
      },
    },
    {
      label: 'Actions',
      key: 'actions',
      format: ({ rowData, customTableProps }) => {
        const actionMenuDropDown = React.createRef();
        return (
          <ItemActionsMenu
            ref={actionMenuDropDown}
            actions={(() => {
              return rowData.registered === 't'
                ? rowData.active === 't'
                  ? [
                      customTableProps.deactivate && {
                        label: 'Deactivate',
                        action: () => {
                          if (actionMenuDropDown && actionMenuDropDown.current) {
                            actionMenuDropDown.current.handleMenuClose();
                          }
                          customTableProps.deactivate({ id: rowData.id });
                        },
                      },
                      customTableProps.revokeUserAccess && {
                        label: 'Revoke access from Org',
                        action: () => {
                          if (actionMenuDropDown && actionMenuDropDown.current) {
                            actionMenuDropDown.current.handleMenuClose();
                          }
                          customTableProps.revokeUserAccess(rowData);
                        },
                      },
                      customTableProps.edit && {
                        label: 'Edit',
                        action: () => {
                          if (actionMenuDropDown && actionMenuDropDown.current) {
                            actionMenuDropDown.current.handleMenuClose();
                          }
                          customTableProps.edit(rowData);
                        },
                      },
                      customTableProps.reassignAllTasks && {
                        label: 'Reassign All Tasks',
                        action: () => {
                          if (actionMenuDropDown && actionMenuDropDown.current) {
                            actionMenuDropDown.current.handleMenuClose();
                          }
                          customTableProps.reassignAllTasks(rowData);
                        },
                      },
                    ]
                  : [
                      customTableProps.activate && {
                        label: 'Activate',
                        action: () => {
                          if (actionMenuDropDown && actionMenuDropDown.current) {
                            actionMenuDropDown.current.handleMenuClose();
                          }
                          customTableProps.activate({ id: rowData.id });
                        },
                      },
                      customTableProps.edit && {
                        label: 'Edit',
                        action: () => {
                          if (actionMenuDropDown && actionMenuDropDown.current) {
                            actionMenuDropDown.current.handleMenuClose();
                          }
                          customTableProps.edit(rowData);
                        },
                      },
                    ]
                : [
                    customTableProps.resendRegistrationEmail && {
                      label: 'Resend Registration Email',
                      action: () => {
                        if (actionMenuDropDown && actionMenuDropDown.current) {
                          actionMenuDropDown.current.handleMenuClose();
                        }
                        customTableProps.resendRegistrationEmail(rowData);
                      },
                    },
                    customTableProps.revokeUserAccess && {
                      label: 'Revoke access from Org',
                      action: () => {
                        if (actionMenuDropDown && actionMenuDropDown.current) {
                          actionMenuDropDown.current.handleMenuClose();
                        }
                        customTableProps.revokeUserAccess(rowData);
                      },
                    },
                    customTableProps.edit && {
                      label: 'Edit',
                      action: () => {
                        if (actionMenuDropDown && actionMenuDropDown.current) {
                          actionMenuDropDown.current.handleMenuClose();
                        }
                        customTableProps.edit(rowData);
                      },
                    },
                  ];
            })()}
          />
        );
      },
      className: 'col-actions',
      header: ({ customTableProps }) => {
        return (
          <ColVisibilitySelector
            colDefs={customTableProps.colDefs}
            visibleColumnKeys={customTableProps.visibleColumnKeys}
            onChangeVisibility={(colKey) => customTableProps.onChangeVisibility(colKey)}
          />
        );
      },
    },
  ],
};
