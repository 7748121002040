import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector, useConfirmModal } from 'hooks';
import useFilterRows from 'helpers/customHooks/useFilterRows';
import PageHeader from 'v2/components/PageHeader/PageHeader';
import PageHeaderActions from 'v2/components/PageHeader/components/PageHeaderActions/PageHeaderActions';
import {
  deactivateUserAction,
  activateUserAction,
  loadUsersAction,
  loadSitesAction,
  setPaginationAction,
  resendRegistrationEmailAction,
  downloadUsersAction,
  revokeUserAccessAction,
  reassignAllTasksAction,
} from 'store/actions/Actions';

import Container from 'components/Container/Container';
import FullDataTable from 'components/DataTable/FullDataTable';
import TableDefAllUsers from 'v2/table-defs/TableDefAllUsers';
import { connect } from 'react-redux';
import { setSelectedRows, toggleDropdowns, exportAsCSV } from 'helpers/utils';
import { USERS_PAGE } from 'helpers/constants/DataTableConstants';
import Spinner from 'components/Spinner/Spinner';
import ActionStatusConstants from 'helpers/ActionStatusConstants';

import UserDeactivationModal from './components/UserDeactivationModal/UserDeactivationModal';

const labels = () => ({
  name: {
    label: 'NAME',
  },
  email: {
    label: 'EMAIL',
  },
  status: {
    label: 'STATUS',
  },
  role_str: {
    label: 'ROLE',
  },
  last_modified: {
    label: 'MODIFIED',
  },
  created_at: {
    label: 'CREATED',
  },
});

function Users(props) {
  const {
    loadSitesAction,
    setPaginationAction,
    downloadUsersAction,
    loadUsersAction,
    deactivateUserAction,
    revokeUserAccessAction,
    activateUserAction,
    resendRegistrationEmailAction,
    reassignAllTasksAction,
  } = props;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const DatatableReducer = useAppSelector((state) => state.DatatableReducer);
  const UsersReducer = useAppSelector((state) => state.UsersReducer);
  const SitesReducer = useAppSelector((state) => state.SitesReducer);
  const UserOrgSiteReducer = useAppSelector((state) => state.UserOrgSiteReducer);

  const [state, setState] = useState({
    selectedRows: undefined,
    selected_sites: [],
  });
  const [isLoading, setLoading] = useState(true);
  const [sortKey, setSortKey] = useState(DatatableReducer.datatable[USERS_PAGE]?.sortKey);
  const [sortOrder, setSortOrder] = useState(DatatableReducer.datatable[USERS_PAGE]?.sortOrder);

  const [showUserDeactivationModal, setShowUserDeactivationModal] = useState(undefined);
  const renderUserDeactivationModal = () =>
    ReactDOM.createPortal(<UserDeactivationModal data={showUserDeactivationModal} setShowModal={setShowUserDeactivationModal} />, document.getElementById('modal-root'));

  useEffect(() => {
    if (!SitesReducer.sites) {
      loadSitesAction();
    }
  }, [SitesReducer.sites, loadSitesAction]);

  useEffect(() => {
    if (!UsersReducer.users && SitesReducer.sites) {
      loadUsersAction({ inactive: true });
    }
  }, [UsersReducer.users, SitesReducer.sites, loadUsersAction]);

  useEffect(() => {
    if (UsersReducer.status === ActionStatusConstants.SUCCESS && SitesReducer.status === ActionStatusConstants.SUCCESS) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [UsersReducer.users, SitesReducer.sites]);

  const noFilters = {
    text: '',
    role: '',
    sites: '',
    status: '',
    selected_sites: [],
  };

  const [filters, setFilters] = useState(DatatableReducer?.datatable?.users?.filters || noFilters);

  const onChangeRowSelect = ({ selectedRows }) => {
    if (selectedRows !== state.selectedRows) {
      setState((s) => ({ ...s, selectedRows }));
    }
  };

  const [selectedUsersIds, setSelectedUsersIds] = useState(DatatableReducer?.datatable?.users?.selectedIds || []);

  useEffect(() => {
    dispatch({
      type: 'SET_DATATABLE',
      payload: {
        datatable: {
          users: {
            selectedIds: selectedUsersIds,
            filters,
            selectedRows: state.selectedRows,
            selected_sites: state.selected_sites,
            rowsPerPage: DatatableReducer?.datatable?.users?.rowsPerPage || 10,
            currentPage: DatatableReducer?.datatable?.users?.currentPage || 0,
            sortKey,
            sortOrder,
          },
        },
      },
    });
  }, [selectedUsersIds, filters, state, sortKey, sortOrder]);

  const setFilter = (filterName) => (e) => {
    setFilters({
      ...filters,
      [filterName]: e.target.type === 'checkbox' ? e.target.checked : e.target.value || e?.target?.attributes?.value?.value || '',
    });
    setPaginationAction({ dataType: USERS_PAGE, currentPage: 0 });
  };

  const filteredRows = useFilterRows(filters, UsersReducer.users);

  const openAddNewUser = () => navigate('/people/add');
  const roleDropdownRef = React.createRef();
  const statusDropdownRef = React.createRef();
  const chooseActionDropdownRef = React.createRef();
  const selectedIds = selectedUsersIds.map((item) => item.id);
  const [showConfirmModal, setShowConfirmModal, renderConfirmModal] = useConfirmModal();

  const hadnleDownloadCSV = () => {
    downloadUsersAction();
  };

  const handleActiveteSelected = () => {
    const idCount = selectedIds.length;
    if (idCount > 0) {
      setShowConfirmModal({
        title: 'Acivate selected users',
        question: idCount === 1 ? 'Are you sure you want to activate the selected user?' : `Are you sure you want to activate ${idCount} selected users?`,
        confirmAction: () => {
          activateUserAction({ items: selectedIds });
        },
      });
    } else {
      setShowConfirmModal({
        type: 'simple',
        title: 'Nothing selected',
        question: 'Please select one or more users.',
      });
    }
  };

  const handleDeactiveSelected = () => {
    const idCount = selectedIds.length;
    if (idCount > 0) {
      setShowConfirmModal({
        title: 'Deacivate selected users',
        warning: '(Note: no tasks will be reassigned)',
        question: idCount === 1 ? 'Are you sure you want to deactivate the selected user?' : `Are you sure you want to deactivate ${idCount} selected users?`,
        confirmAction: () => {
          deactivateUserAction({ items: selectedIds });
        },
      });
    } else {
      setShowConfirmModal({
        type: 'simple',
        title: 'Nothing selected',
        question: 'Please select one or more users.',
      });
    }
  };

  const handleResendRegitrationEmail = () => {
    if (selectedIds.length > 0) {
      resendRegistrationEmailAction({ items: selectedIds });
    } else {
      setShowConfirmModal({
        type: 'simple',
        title: 'Nothing selected',
        question: 'Please select one or more users.',
      });
    }
  };

  const handleExportSelected = () => {
    const data = UsersReducer.users.filter((item) => selectedUsersIds.map((i) => i.id).includes(item.id));
    const updatedData = data.map((item) => ({
      ...item,
      created_at: item.created_at.slice(0, 10),
      last_modified: item.last_modified.slice(0, 10),
    }));
    exportAsCSV(updatedData, labels, 'users.csv');
  };

  const handleDeactiveUser = (id) => {
    setShowUserDeactivationModal({
      title: 'User Deactivation',
      question: 'Are you sure you want to deactivate the user?',
      currentUserId: id,
      confirmAction: (reasignableUserId) => {
        deactivateUserAction({
          id,
          to_user: reasignableUserId === 0 ? undefined : reasignableUserId,
        });
      },
    });
  };

  const handleReassignTasks = (id) => {
    setShowUserDeactivationModal({
      title: 'Tasks Reassignment',
      question: 'Are you sure you want to reassing all the tasks from this user to a different user?',
      currentUserId: id,
      btnLabel: 'REASSIGN',
      confirmAction: (reasignableUserId) => {
        reassignAllTasksAction({
          current_owner: id,
          new_owner: reasignableUserId === 0 ? undefined : reasignableUserId,
        });
      },
    });
  };

  const handleRevokeUser = (id, name, email, org_name) => {
    setShowConfirmModal({
      title: 'Revoke user access',
      question: `You're about to revoke access to ${name} (${email}) from ${org_name}. Are you sure?`,
      confirmAction: () => {
        revokeUserAccessAction({ id });
      },
    });
  };

  const is_super = UserOrgSiteReducer?.selected?.is_super || false;
  const is_coach = UserOrgSiteReducer?.selected?.is_coach || false;
  const org_name =
    (UserOrgSiteReducer.organizations &&
      UserOrgSiteReducer?.selected?.org &&
      UserOrgSiteReducer.organizations[UserOrgSiteReducer.selected.org] &&
      UserOrgSiteReducer.organizations[UserOrgSiteReducer.selected.org].name) ||
    '';

  return (
    <div onClick={toggleDropdowns([roleDropdownRef, statusDropdownRef, chooseActionDropdownRef])}>
      <PageHeader title="People">
        <PageHeaderActions
          primaryButtonText="ADD USER"
          primaryButtonHandler={openAddNewUser}
          secondaryButtonText="BULK UPLOAD"
          terianryButtonText="DOWNLOAD CSV"
          terianryButtonHandler={() => hadnleDownloadCSV()}
          terianryButtonIcon="fal fa-download"
          secondaryButtonHandler={() => navigate('/people/add_bulk')}
          searchHandler={(e) => {
            setFilters({ ...filters, text: e.target.value || '' });
            setPaginationAction({ dataType: USERS_PAGE, currentPage: 0 });
          }}
          searchValue={filters.text}
          statusDropdownRef={statusDropdownRef}
          chooseActionDropdownRef={chooseActionDropdownRef}
          chooseAction={[
            {
              actionName: 'Activate Selected',
              actionHandler: () => handleActiveteSelected(),
            },
            {
              actionName: 'Deactivate Selected',
              actionHandler: () => handleDeactiveSelected(),
            },
            {
              actionName: 'Resend registration email to selected users',
              actionHandler: () => handleResendRegitrationEmail(),
            },
            {
              actionName: 'Export Selected',
              actionHandler: () => handleExportSelected(),
            },
          ]}
          status={{
            onChange: setFilter('status'),
            options: ['All', 'Awaiting Registration', 'Inactive', 'Active'],
          }}
          resetFilters={() => {
            setFilters(noFilters);
            setPaginationAction({ dataType: USERS_PAGE, currentPage: 0 });
          }}
          filters={filters}
        />
      </PageHeader>
      {isLoading ? (
        <Spinner />
      ) : (
        <Container wide fluid>
          {UsersReducer.users && (
            <FullDataTable
              onRowClick={function onRowClick(e) {
                if (e.currentTarget.classList.contains('col-actions') || e.currentTarget.classList.contains('col-row-selector')) return;
                navigate(`/people/edit?id=${this.props.rowData.id}`);
              }}
              rowsData={filteredRows}
              definitions={TableDefAllUsers}
              dataType={USERS_PAGE}
              setSortKey={setSortKey}
              setSortOrder={setSortOrder}
              onChangeRowSelect={({ selectedRows }) => {
                onChangeRowSelect({ selectedRows });
              }}
              setSelectedRows={setSelectedRows(['id'], setSelectedUsersIds)}
              customTableProps={
                is_super || is_coach
                  ? {
                      selectedRows: state.selectedRows || selectedUsersIds,
                      onNameClick: ({ rowData }) => navigate(`/people/edit?id=${rowData.id}`),
                      edit: (rowData) => navigate(`/people/edit?id=${rowData.id}`),
                      activate: ({ id }) => activateUserAction({ items: [id] }),
                      deactivate: ({ id }) => handleDeactiveUser(id),
                      resendRegistrationEmail: (rowData) => resendRegistrationEmailAction({ items: [rowData.id] }),
                      revokeUserAccess: !is_coach ? ({ id, name, email }) => handleRevokeUser(id, name, email, org_name) : undefined,
                      reassignAllTasks: ({ id }) => handleReassignTasks(id),
                    }
                  : {
                      selectedRows: state.selectedRows || selectedUsersIds,
                      onNameClick: ({ rowData }) => navigate(`/people/edit?id=${rowData.id}`),
                      edit: (rowData) => navigate(`/people/edit?id=${rowData.id}`),
                      activate: ({ id }) => activateUserAction({ items: [id] }),
                      deactivate: ({ id }) => handleDeactiveUser(id),
                      resendRegistrationEmail: (rowData) => resendRegistrationEmailAction({ items: [rowData.id] }),
                    }
              }
            />
          )}
          {showConfirmModal && renderConfirmModal(showConfirmModal, setShowConfirmModal)}
          {showUserDeactivationModal && renderUserDeactivationModal()}
        </Container>
      )}
    </div>
  );
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  deactivateUserAction,
  revokeUserAccessAction,
  downloadUsersAction,
  activateUserAction,
  loadUsersAction,
  setPaginationAction,
  loadSitesAction,
  resendRegistrationEmailAction,
  reassignAllTasksAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
