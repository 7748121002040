import React, { MouseEventHandler } from 'react';
import classNames from 'classnames';

import styles from './Button.module.scss';

interface IProps {
  className?: string;
  icon?: string;
  iconEnd?: string;
  text?: string;
  color?: string;
  size?: 'sm' | 'md' | 'lg';
  // name?: string;
  dropdownToggleRef?: () => void;
  disabled?: boolean;
  cursorDefault?: boolean;
  onClick?: MouseEventHandler;
  // ref?: () => void | undefined;
}

const defaultProps = {
  className: '',
  icon: undefined,
  iconEnd: false,
  text: undefined,
  color: undefined,
  size: 'lg',
  // name?: string;
  dropdownToggleRef: () => false,
  disabled: undefined,
  cursorDefault: false,
  onClick: () => false,
};

export function Button({ className, icon, iconEnd, text, color, size, dropdownToggleRef, disabled, cursorDefault, onClick }: IProps) {
  const style = classNames(
    styles.button,
    cursorDefault && styles['cursor-default'],
    (color && styles[color]) || styles.primary,
    styles[`btn-${size}`],
    className,
    disabled && styles['btn-disabled']
  );
  return (
    <div aria-hidden="true" role="button" tabIndex={0} className={style} onClick={!disabled ? onClick : undefined} ref={dropdownToggleRef || undefined}>
      {icon ? <i className={icon} /> : undefined}
      <span>{text}</span>
      {iconEnd ? <i className={iconEnd} /> : undefined}
    </div>
  );
}

Button.defaultProps = defaultProps;

export default Button;
