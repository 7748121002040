import React from 'react';

import Radio from 'components/Radio/Radio';

import styles from './DashboardViews.module.scss';

function VideoAttest(props) {
  const { isUnderstood, setUnderstood, isLoading } = props;
  return (
    <div className={styles.question}>
      <div>
        I hereby attest and acknowledge that I have read or viewed and understood the contents of this training, manual or document. Through my attestation, I hereby confirm that I
        am bound by organization&#39;s trainings, policies, and procedures, and will perform my job duties accordingly. I understand that if I violate any of organization&#39;s
        policies or procedures or don&#39;t follow the training or document, I am subject to disciplinary action, up to and including termination of my employment.
        <br />
        <br />I hereby acknowledge and agree that this attestation is the equivalent of a physical or e-signature. I completely read or viewed the content of this training, manual
        or document and understand it.
      </div>

      <br />

      <div className={styles.answers}>
        <Radio
          checked={isUnderstood === true}
          labelText="I completely READ or VIEWED and understood the content of this training, manual or document."
          onChange={() => setUnderstood(true)}
          disabled={isLoading}
        />
        <Radio
          checked={isUnderstood === false}
          labelText="I did NOT READ or VIEW the content of this training, manual or document (it still requires attestation and understanding)"
          onChange={() => setUnderstood(false)}
          disabled={isLoading}
        />
      </div>
    </div>
  );
}

export default VideoAttest;
