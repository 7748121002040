import { put, call, takeLatest, delay, select } from 'redux-saga/effects';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { actionApiCall } from 'helpers/api/ApiCalls';
import * as sagaConstants from 'helpers/constants/SagasConstants';

const getItems = (state) => state.SitesReducer.sites;
const getUsers = (state) => state.UsersReducer.users;
const getSelected = (state) => state.UserOrgSiteReducer.selected;

function transformTasks(tasks, sites, users) {
  const result = tasks.map((task) => {
    task.status = task.deactivated === 't' ? 'Deactivated incomplete' : task.completed === 't' || task.completed === true ? 'Complete' : 'Incomplete';
    if (task.subtask === 'remediation') {
      task.type = 'Remediation';
    } else if (task.subtask === 'attestation') {
      task.type = 'Attestation';
    } else if (task.subtask === 'incident') {
      task.type = 'Incident';
    } else if (task.subtask === 'auditResponse') {
      task.type = 'Question';
    } else {
      task.type = 'Miscellaneous';
    }
    task.rem = task.remediation_type && Array.isArray(task.remediation_type) && task.remediation_type.length > 0 ? task.remediation_type.join(', ') : '';
    task.siteName = 'All Sites';
    if (sites) {
      sites.forEach((item) => {
        if (item.id === task.site) {
          task.siteName = item.name;
        }
      });
    }
    if (users) {
      users.forEach((user) => {
        if (user.id === task.user_id) {
          task.assignee_name = `${user.name}${user.active === 'f' ? ' (Deactivated)' : ''}`;
          // task.deactivated = user.active === 'f' ? 't' : 'f';
          task.assignee = user.id;
        }
      });
    }
    return task;
  });
  return result;
}

function* loadTasksFilteredSaga({ payload }) {
  try {
    const selected = yield select(getSelected);
    const apiResponse = yield call(actionApiCall, {
      actionType: ActionTypeConstants.LOAD_TASKS_FILTERED,
      data: {
        organization: selected.org,
        filter: payload.filter,
      },
    });

    if (apiResponse && apiResponse.res.data.response === 200) {
      const sites = yield select(getItems);
      const users = yield select(getUsers);
      const tasks = apiResponse.res.data.data || [];
      yield put({
        type: ActionTypeConstants.LOAD_TASKS_FILTERED_SUCCESS,
        payload: {
          tasks: transformTasks(tasks, sites, users),
        },
      });
    } else {
      yield put({
        type: ActionTypeConstants.LOAD_TASKS_FILTERED_FAILURE,
        payload: { message: apiResponse.res.data.message },
      });
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: apiResponse.res.data.message || `${sagaConstants.ISSUE_OCCURED} loading tasks`,
          color: 'danger',
        },
      });
      yield delay(10000);
      yield put({ type: ActionTypeConstants.HIDE_ALERT });
    }
  } catch (err) {
    yield put({
      type: ActionTypeConstants.SHOW_ALERT,
      payload: {
        message: `${sagaConstants.SERVER_ERROR}`,
        color: 'danger',
      },
    });
    yield delay(10000);
    yield put({ type: ActionTypeConstants.HIDE_ALERT });
  }
}

export default function* () {
  yield takeLatest(ActionTypeConstants.LOAD_TASKS_FILTERED, loadTasksFilteredSaga);
}
