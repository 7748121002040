import { call, put, takeEvery } from 'redux-saga/effects';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { actionApiCall } from 'helpers/api/ApiCalls';
import { push } from 'redux-first-history';
import Cookies from 'js-cookie';

/**
 * Logout logic. Removes userData cookie
 */
function* authLogoutSaga() {
  Cookies.remove('isLogged');
  Cookies.remove('org');
  Cookies.remove('site');
  Cookies.remove('active');

  // get use profile
  const apiResponse = yield call(actionApiCall, {
    actionType: ActionTypeConstants.AUTH_LOGOUT,
    data: {},
  });

  // redirect to login
  yield put(push('/login'));

  if (apiResponse) {
    // Set application logged out
    yield put({
      type: ActionTypeConstants.AUTH_LOGGED_OUT_SUCCESS,
    });
  }
}

export default function* () {
  yield takeEvery(ActionTypeConstants.AUTH_LOGOUT, authLogoutSaga);
}
