import React, { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'hooks';
import { loadUserSettingsFormAction } from 'store/actions/Actions';
import AppLayoutWrapFull from 'components/AppLayoutWrapFull';
import PageHeader from 'components/PageHeader/PageHeader';
import Container from 'components/Container/Container';
import { Tabs } from 'components/Tabs/Tabs';

import Profile from './Profile/Profile';
import MFASettings from './MFA/MFASettings';

const profileTab = {
  heading: 'PROFILE',
  icon: 'fad fa-user',
};
const securityTab = {
  heading: 'SECURITY',
  icon: 'fad fa-user-shield',
};

function Settings() {
  const dispatch = useAppDispatch();
  const SettingsReducer = useAppSelector((state) => state.SettingsReducer);

  const [activeTab, setActiveTab] = useState('PROFILE');
  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    if (SettingsReducer.user) {
      if (SettingsReducer.user?.connected_to_sso) {
        setTabs([profileTab]);
      } else {
        setTabs([profileTab, securityTab]);
      }
    } else {
      dispatch(loadUserSettingsFormAction());
    }
  }, [SettingsReducer.user]);

  const setTabAsActive = (heading) => () => {
    setActiveTab(heading);
  };

  return (
    <AppLayoutWrapFull>
      <PageHeader loadStats={false} />
      <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setTabAsActive} />
      <Container wide>
        {activeTab === 'PROFILE' ? <Profile /> : undefined}

        {activeTab === 'SECURITY' ? <MFASettings /> : undefined}
      </Container>
    </AppLayoutWrapFull>
  );
}

export default Settings;
