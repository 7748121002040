import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import styles from './AddDeviceInventoryModal.module.scss';

import Button from 'components/Button/Button';
import Modal from 'components/Modal/Modal';

import FormElement from 'components/FormElement/FormElement';
import Select from 'components/Select/Select';
import RadioGroup from 'components/RadioGroup/RadioGroup';
import DateInput from 'components/DateInput/DateInput';

import { loadAuditAction, loadUsersAction, createDeviceInventoryAction, updateDeviceInventoryAction } from 'store/actions/Actions';
import { toggleDropdowns } from 'helpers/utils';
import moment from 'moment';

const AddDeviceInventoryModal = (props) => {
  const { UsersReducer, loadUsersAction, auditId, createDeviceInventoryAction, updateDeviceInventoryAction, setShowAddDeviceModal, loadAuditAction, AuditReducer } = props;

  const isEditModal = props.device && (props.device.id === '' || !props.device.id) ? false : true;
  const isDeactivated = props.device && props.device.deactivated && props.device.deactivated !== '' ? true : false;
  const isDestroyed = props.device && props.device.device_destroyed === 't' ? true : false;

  const deviceRiskRef = React.createRef();
  const deviceTypeRef = React.createRef();
  const deviceUserRef = React.createRef();
  const deviceEphiRef = React.createRef();

  const [device, setDevice] = useState({
    audit_id: auditId,
    site_id: null,
    type: '',
    name: '',
    activated: moment(),
    deactivated: '',
    asset_tag: '',
    model: '',
    serial_number: '',
    physical_location: '',
    user_id: '',
    owner: '',
    last_ip_address: '',
    stores_touches_ephi: '',
    end_point_protection: '',
    encryption: '',
    risk_rating: '5',
    notes: '',
  });

  useEffect(() => {
    if (props.device) {
      setDevice((s) => ({
        ...s,
        ...props.device,
        are_other_notes: props.device.other ? true : false,
        audit_id: auditId,
      }));
    }
  }, [props.device]);

  const [stateErrors, setStateErrors] = useState({
    name: '',
    type: '',
    activated: '',
    user_id: '',
    security_controls: '',
    stores_touches_ephi: '',
  });

  useEffect(() => {
    if (!UsersReducer.users && AuditReducer.audit) {
      loadUsersAction({ site: AuditReducer.audit.site });
    }
  }, [UsersReducer.users, AuditReducer.audit, loadUsersAction]);

  useEffect(() => {
    if (!AuditReducer.audit && auditId) {
      loadAuditAction(auditId);
    }
  }, [AuditReducer.audit, auditId, loadAuditAction]);

  const addDevice = () => {
    if (
      !(device.name && device.name.trim()) ||
      !(device.type && device.type.trim()) ||
      !(device.user_id && device.user_id.trim()) ||
      !device.activated ||
      moment(device.activated).isAfter(moment()) ||
      (device.activated !== '' && moment(device.activated).isAfter(moment(device.deactivated))) ||
      !device.stores_touches_ephi ||
      !(device.end_point_protection && device.encryption)
    ) {
      setStateErrors({
        ...stateErrors,
        name: !(device.name && device.name.trim()) ? 'Device name is required' : '',
        type: !(device.type && device.type.trim()) ? 'Device type is required' : '',
        user_id: !(device.user_id && device.user_id.trim()) ? 'Device owner is required' : '',
        activated: !device.activated ? 'Activation date is required' : moment(device.activated).isAfter(moment()) ? 'Activation date can be only in present or past' : '',
        deactivated: device.activated !== '' && moment(device.activated).isAfter(moment(device.deactivated)) ? 'Deactivation date can be only in present or past' : '',
        security_controls: !(device.end_point_protection && device.encryption) ? 'All security controls must be checked' : '',
        stores_touches_ephi: !device.stores_touches_ephi ? 'Stores/Touches ePHI is required' : '',
      });
      return;
    }
    createDeviceInventoryAction({
      ...device,
      activated: moment(device.activated).format('YYYY-MM-DD'),
      deactivated: device.deactivated !== '' ? moment(device.deactivated).format('YYYY-MM-DD') : undefined,
    });
    setShowAddDeviceModal(false);
  };

  const saveDevice = () => {
    if (
      !(device.name && device.name.trim()) ||
      !(device.type && device.type.trim()) ||
      !(device.user_id && device.user_id.trim()) ||
      !device.stores_touches_ephi ||
      !(device.end_point_protection && device.encryption)
    ) {
      setStateErrors({
        ...stateErrors,
        name: !(device.name && device.name.trim()) ? 'Device name is required' : '',
        type: !(device.type && device.type.trim()) ? 'Device type is required' : '',
        user_id: !(device.user_id && device.user_id.trim()) ? 'Device owner is required' : '',
        security_controls: !(device.end_point_protection && device.encryption) ? 'All security controls must be checked' : '',
        stores_touches_ephi: !device.stores_touches_ephi ? 'Stores/Touches ePHI is required' : '',
      });
      return;
    }
    updateDeviceInventoryAction({ ...device });
    setShowAddDeviceModal(false);
  };

  const handleChangeAlt = (inputName) => (e) => {
    e.persist();
    setDevice((s) => ({
      ...s,
      [inputName]: e.target.value,
    }));
    setStateErrors((s) => ({ ...s, [inputName]: '' }));
  };

  const handleChange = (e, name) => {
    setDevice((oldState) => ({
      ...device,
      owner: name === 'user_assigned' ? e.target.value.toString() : oldState.owner,
      [name]:
        e.target.type === 'checkbox'
          ? e.target.checked === true
            ? true
            : false
          : e.target.value.toString() || (e.target && e.target.attributes && e.target.attributes.value && e.target.attributes.value.value) || '',
    }));
    setStateErrors((s) => ({ ...s, [name]: '' }));
  };

  return (
    <Modal className={styles['modal']} setShowModal={props.setShowAddDeviceModal}>
      <div className={styles['modal-body']} onClick={toggleDropdowns([deviceRiskRef, deviceTypeRef, deviceUserRef])}>
        <div>
          <p className={styles['modal-heading']}>{isEditModal ? 'Edit Device' : 'Add New Device'}</p>
        </div>

        <div className={styles['modal-content']}>
          <div>
            <FormElement labelText="DEVICE NAME" smallText="*" htmlFor="name" errorMessage={stateErrors.name}>
              <input type="text" onChange={handleChangeAlt('name')} value={device.name} disabled={isEditModal || isDeactivated || isDestroyed} />
            </FormElement>

            <br />

            <FormElement labelText="TYPE" htmlFor="type" smallText="*" errorMessage={stateErrors.type}>
              <Select
                labels={[
                  'Desktop Computer',
                  'Laptop',
                  'Server',
                  'Network Device',
                  'Cell Phone',
                  'Tablet',
                  'VoIP Phone',
                  'Removable Media',
                  'Scanner',
                  'MDF Scanner/Printer',
                  'Virtual Machine',
                  'Other',
                ]}
                name="type"
                value={device.type || ''}
                placeholder="Select Type"
                onClick={(e) => handleChange(e, 'type')}
                dropdownToggleRef={deviceTypeRef}
                disabled={isEditModal || isDeactivated || isDestroyed}
              >
                <li value="0">Desktop Computer</li>
                <li value="1">Laptop</li>
                <li value="2">Server</li>
                <li value="3">Network Device</li>
                <li value="4">Cell Phone</li>
                <li value="5">Tablet</li>
                <li value="6">VoIP Phone</li>
                <li value="7">Removable Media</li>
                <li value="8">Scanner</li>
                <li value="9">MDF Scanner/Printer</li>
                <li value="10">Virtual Machine</li>
                <li value="11">Other</li>
              </Select>
            </FormElement>

            <br />

            <FormElement labelText="ACTIVATION DATE" htmlFor="activated" errorMessage={stateErrors.activated} smallText="*">
              <DateInput
                disabled={isEditModal || isDeactivated || isDestroyed}
                onChange={handleChangeAlt('activated')}
                value={moment(device.activated).format('YYYY-MM-DD')}
                maxDate={moment().format('YYYY-MM-DD')}
              />
            </FormElement>

            <br />

            <FormElement labelText="DEACTIVATION DATE" htmlFor="deactivated" errorMessage={stateErrors.deactivated}>
              <DateInput
                disabled={isEditModal || isDeactivated || isDestroyed}
                onChange={handleChangeAlt('deactivated')}
                minDate={moment(device.activated).format('YYYY-MM-DD')}
                maxDate={moment().format('YYYY-MM-DD')}
                value={device.deactivated !== '' ? moment(device.deactivated).format('YYYY-MM-DD') : device.deactivated}
              />
            </FormElement>

            <br />

            <FormElement labelText="ASSET TAG" htmlFor="asset_tag" errorMessage={stateErrors.asset_tag}>
              <input type="text" onChange={handleChangeAlt('asset_tag')} value={device.asset_tag} disabled={isDeactivated || isDestroyed} />
            </FormElement>

            <br />

            <FormElement labelText="MODEL" htmlFor="model">
              <input type="text" onChange={handleChangeAlt('model')} value={device.model} disabled={isEditModal || isDeactivated || isDestroyed} />
            </FormElement>

            <br />

            <FormElement labelText="SERIAL NUMBER" htmlFor="serial_number">
              <input type="text" onChange={handleChangeAlt('serial_number')} value={device.serial_number} disabled={isEditModal || isDeactivated || isDestroyed} />
            </FormElement>

            <br />

            <FormElement labelText="PHYSICAL LOCATION" htmlFor="physical_location">
              <input type="text" onChange={handleChangeAlt('physical_location')} value={device.physical_location} disabled={isDeactivated || isDestroyed} />
            </FormElement>
          </div>

          <div>
            <FormElement
              className={styles.custom_fe}
              labelText="SECURITY CONTROLS"
              htmlFor="security_controls"
              smallText="*"
              errorMessage={stateErrors.security_controls}
            ></FormElement>

            <div className={styles.flex}>
              <FormElement className={styles.custom_radio_group} labelText="End Point Protection" htmlFor="end_point_protection">
                <div style={{ display: 'flex' }}>
                  <RadioGroup
                    items={[
                      {
                        label: 'Yes',
                        value: '1',
                      },
                      {
                        label: 'No',
                        value: '0',
                      },
                    ]}
                    value={device.end_point_protection}
                    onChange={(e) => {
                      handleChange(e, 'end_point_protection');
                      setStateErrors((s) => ({ ...s, security_controls: '' }));
                    }}
                    name="end_point_protection"
                    disabled={isDeactivated || isDestroyed}
                  />
                </div>
              </FormElement>

              <FormElement className={styles.custom_fe_last} labelText="Encryption" htmlFor="encryption">
                <div style={{ display: 'flex' }}>
                  <RadioGroup
                    items={[
                      {
                        label: 'Yes',
                        value: '1',
                      },
                      {
                        label: 'No',
                        value: '0',
                      },
                    ]}
                    value={device.encryption}
                    onChange={(e) => {
                      handleChange(e, 'encryption');
                      setStateErrors((s) => ({ ...s, security_controls: '' }));
                    }}
                    name="encryption"
                    disabled={isDeactivated || isDestroyed}
                  />
                </div>
              </FormElement>
            </div>

            <br />

            <FormElement labelText="Stores/Touches ePHI" htmlFor="stores_touches_ephi" smallText="*" errorMessage={stateErrors.stores_touches_ephi}>
              <Select
                disabled={isDeactivated || isDestroyed}
                labels={{ 1: 'Yes', 0: 'No' }}
                name="stores_touches_ephi"
                value={device.stores_touches_ephi}
                placeholder="Please select"
                onClick={(e) => handleChange(e, 'stores_touches_ephi')}
                dropdownToggleRef={deviceEphiRef}
              >
                <li value={'1'}>Yes</li>
                <li value={'0'}>No</li>
              </Select>
            </FormElement>

            <br />

            <FormElement labelText="LAST KNOWN IP ADDRESS" htmlFor="last_ip_address">
              <input type="text" onChange={handleChangeAlt('last_ip_address')} value={device.last_ip_address} disabled={isDeactivated || isDestroyed} />
            </FormElement>

            <br />

            <FormElement labelText="Risk Rating (1-10, 10 highest)" htmlFor="risk_rating" errorMessage={stateErrors.risk_rating}>
              <Select
                labels={{
                  1: 1,
                  2: 2,
                  3: 3,
                  4: 4,
                  5: 5,
                  6: 6,
                  7: 7,
                  8: 8,
                  9: 9,
                  10: 10,
                }}
                name="risk_rating"
                value={device.risk_rating || '5'}
                placeholder="Select Risk Rating"
                onClick={(e) => handleChange(e, 'risk_rating')}
                dropdownToggleRef={deviceTypeRef}
                disabled={isDeactivated || isDestroyed}
              >
                <li value="1">1</li>
                <li value="2">2</li>
                <li value="3">3</li>
                <li value="4">4</li>
                <li value="5">5</li>
                <li value="6">6</li>
                <li value="7">7</li>
                <li value="8">8</li>
                <li value="9">9</li>
                <li value="10">10</li>
              </Select>
            </FormElement>

            <br />

            <FormElement labelText="USER ASSIGNED" htmlFor="user_id" smallText="*" errorMessage={stateErrors.user_id}>
              <Select
                name="user_id"
                value={
                  (UsersReducer.users &&
                    UsersReducer.users.find((user) => user.id.toString() === device.user_id.toString()) &&
                    UsersReducer.users.find((user) => user.id.toString() === device.user_id.toString()).name) ||
                  ''
                }
                placeholder="Please assign a user"
                onClick={(e) => handleChange(e, 'user_id')}
                dropdownToggleRef={deviceRiskRef}
                disabled={isDeactivated || isDestroyed}
              >
                {UsersReducer.users
                  .slice()
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .filter((item) => item.registered === 't' && item.name !== '')
                  .map((user) => (
                    <li key={user.id} value={user.id}>
                      {user.name}
                    </li>
                  ))}
              </Select>
            </FormElement>

            <br />

            <FormElement labelText="NOTES" htmlFor="notes">
              <textarea rows="4" className={styles.notes} value={device.notes || ''} onChange={(e) => handleChange(e, 'notes')} disabled={isDeactivated || isDestroyed} />
            </FormElement>
          </div>
        </div>
      </div>

      <div className={styles['modal-footer']}>
        <Button className={styles.button} text="CANCEL" onClick={() => props.setShowAddDeviceModal(false)} color="secondary" />
        {isEditModal && !(isDeactivated || isDestroyed) && <Button className={styles.button} text="UPDATE DEVICE" onClick={saveDevice} disabled={isDeactivated || isDestroyed} />}
        {!isEditModal && <Button className={styles.button} text="ADD DEVICE" onClick={addDevice} />}
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  UsersReducer: state.UsersReducer,
  UserOrgSiteReducer: state.UserOrgSiteReducer,
  SitesReducer: state.SitesReducer,
  AuditReducer: state.AuditReducer,
});

const mapDispatchToProps = {
  loadAuditAction,
  updateDeviceInventoryAction,
  loadUsersAction,
  createDeviceInventoryAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddDeviceInventoryModal);
