import { configureStore } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { createReduxHistoryContext } from 'redux-first-history';
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';

import RootReducer from 'store/reducers/RootReducer';
import RootSaga from 'store/sagas/RootSaga';

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({ history: createBrowserHistory() });

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: RootReducer(routerReducer),
  middleware: (getDefaultMiddleware) => {
    const middlewares = [...getDefaultMiddleware(), routerMiddleware, sagaMiddleware];
    if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
      middlewares.push(logger);
    }
    return middlewares;
  },
  devTools: process.env.REACT_APP_ENVIRONMENT !== 'production',
});

export const history = createReduxHistory(store);

sagaMiddleware.run(RootSaga);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
