import React from 'react';
import { Button } from 'components/Button/Button';
import Modal from 'components/Modal/Modal';
import { SignInButton } from './SignInButton';
import styles from '../AzureADModal.module.scss';

function UnAuthModal(props) {
  return (
    <>
      <div className={styles['modal-content']}>
        <SignInButton />
      </div>
      <Modal.Footer>
        <Button text="CANCEL" color="secondary" onClick={() => props.setShowModal(false)} />
      </Modal.Footer>
    </>
  );
}

export default UnAuthModal;
