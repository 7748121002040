import isEmail from 'validator/es/lib/isEmail';
import pLimit from 'p-limit';
import axios from 'axios';
import { DOCUMENT_UPLOAD_URL } from 'helpers/ApiEndpointConstants';

export const copyToClipboard = (textToCopy) => {
  // navigator clipboard api needs a secure context (https)
  if (navigator.clipboard && window.isSecureContext) {
    // navigator clipboard api method'
    return navigator.clipboard.writeText(textToCopy);
  }
  // text area method
  const textArea = document.createElement('textarea');
  textArea.value = textToCopy;
  // make the textarea out of viewport
  textArea.style.position = 'fixed';
  textArea.style.left = '-999999px';
  textArea.style.top = '-999999px';
  document.body.append(textArea);
  textArea.focus();
  textArea.select();
  return new Promise<void>((res, rej) => {
    // here the magic happens
    document.execCommand('copy') ? res() : rej();
    textArea.remove();
  });
};

export const randomId = () => window.self.crypto.getRandomValues(new Uint32Array(3)).join('');

export const transformOrganizations = (data) => {
  const myData: any = Object.values({ ...data })[0];
  const filtered = {};
  const items = [];

  for (const x in myData) {
    if (Object.prototype.hasOwnProperty.call(myData, x)) {
      filtered[x] = myData[x];
    }
  }

  const cleaned: any = { ...filtered };

  const org_object = {
    id: cleaned.id,
    key: randomId(),
    value: cleaned.name,
    name: cleaned.name,
    address_1: cleaned.address_1,
    address_2: cleaned.address_2,
    city: cleaned.city,
    region: cleaned.region,
    country_code: cleaned.country_code,
    postal_code: cleaned.postal_code,
    type: 'org',
    disabled: true,
    collection: 0,
    isEditing: false,
    isParent: true,
    site_limit: cleaned.site_limit,
    sso_enabled: cleaned.sso_enabled,
  };

  items.push({ ...org_object });

  if (cleaned.sites) {
    for (const item of <any[]>Object.values(cleaned.sites)) {
      items.push({
        ...item,
        site: item.id,
        value: item.name,
        type: 'site',
        disabled: true,
        collection: items.length === 1 ? 0 : 1,
        isEditing: false,
        isParent: !!item.departments,
      });
      if (item.departments) {
        for (const [key, value] of Object.entries(item.departments)) {
          items.push({
            id: key,
            site: item.id,
            value,
            disabled: false,
            type: 'dept',
            collection: 1,
            isEditing: false,
            isParent: false,
          });
        }
      }
    }
  }

  return {
    items,
  };
};

/**
 *
 * @param {String} emailString - email string to validate
 */
export const isEmailValid = (emailString) => isEmail(emailString);

export const specialChars = new Set('!#$%&*@^');
const lowerCaseLetters = new Set('abcdefghijklmnopqrstuvwxyz');
const upperCaseLetters = new Set('ABCDEFGHIJKLMNOPQRSTUVWXYZ');

export const isCharacterLowerCase = (character: string) => lowerCaseLetters.has(character);

export const isCharacterUpperCase = (character: string) => upperCaseLetters.has(character);

export const isCharacterSpecial = (character: string) => specialChars.has(character);

export const isPasswordValid = (password) => {
  if (
    password &&
    password?.length >= 8 &&
    [...password]?.some((char) => isCharacterLowerCase(char)) &&
    [...password]?.some((char) => isCharacterUpperCase(char)) &&
    [...password]?.some((char) => !Number.isNaN(Number.parseInt(char, 10))) &&
    [...password]?.some((char) => isCharacterSpecial(char))
  ) {
    return true;
  }
  return false;
};

export const toggleDropdowns = (dropdowns) => (e) => {
  try {
    for (const el of dropdowns) {
      if (el.current && el.current.classList && el.current.classList.contains('show')) el.current.click();
    }
  } catch {
    console.log('Failed to close dropdown');
  }
};

function pick(object, keys) {
  return keys.reduce((obj, key) => {
    if (object && Object.prototype.hasOwnProperty.call(object, key)) {
      obj[key] = object[key];
    }
    return obj;
  }, {});
}

/**
 *
 * @param {Object} cols - array of col strings to keep
 * @param {Function} setState - set state
 */
export const setSelectedRows = (cols, setState) => (selectedRows) => {
  setState(selectedRows.map((row) => pick(row, cols)));
};

export function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${Number.parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

export function fileTypeIcon(fileName) {
  if (fileName?.endsWith('.pdf')) {
    return 'fa-solid fa-file-pdf';
  }
  if (fileName?.endsWith('.doc') || fileName?.endsWith('.docx')) {
    return 'fa-solid fa-file-word';
  }
  if (fileName?.endsWith('.xls') || fileName?.endsWith('.xlsx')) {
    return 'fa-solid fa-file-excel';
  }
  if (fileName?.endsWith('.gif') || fileName?.endsWith('.jpeg') || fileName?.endsWith('.jpg')) {
    return 'fa-solid fa-file-image';
  }
  if (fileName?.endsWith('.ppt') || fileName?.endsWith('.pptx')) {
    return 'fa-solid fa-file-powerpoint';
  }
  if (fileName?.endsWith('.csv')) {
    return 'fa-solid fa-file-text';
  }
  if (fileName?.endsWith('.mp4')) {
    return 'fa-solid fa-file-video';
  }
  return 'fa-solid fa-file';
}

export const states = [
  { name: 'Alabama', abbrev: 'AL' },
  { name: 'Alaska', abbrev: 'AK' },
  { name: 'Arizona', abbrev: 'AZ' },
  { name: 'Arkansas', abbrev: 'AR' },
  { name: 'California', abbrev: 'CA' },
  { name: 'Colorado', abbrev: 'CO' },
  { name: 'Connecticut', abbrev: 'CT' },
  { name: 'Delaware', abbrev: 'DE' },
  { name: 'Florida', abbrev: 'FL' },
  { name: 'Georgia', abbrev: 'GA' },
  { name: 'Hawaii', abbrev: 'HI' },
  { name: 'Idaho', abbrev: 'ID' },
  { name: 'Illinois', abbrev: 'IL' },
  { name: 'Indiana', abbrev: 'IN' },
  { name: 'Iowa', abbrev: 'IA' },
  { name: 'Kansas', abbrev: 'KS' },
  { name: 'Kentucky', abbrev: 'KY' },
  { name: 'Louisiana', abbrev: 'LA' },
  { name: 'Maine', abbrev: 'ME' },
  { name: 'Maryland', abbrev: 'MD' },
  { name: 'Massachusetts', abbrev: 'MA' },
  { name: 'Michigan', abbrev: 'MI' },
  { name: 'Minnesota', abbrev: 'MN' },
  { name: 'Mississippi', abbrev: 'MS' },
  { name: 'Missouri', abbrev: 'MO' },
  { name: 'Montana', abbrev: 'MT' },
  { name: 'Nebraska', abbrev: 'NE' },
  { name: 'Nevada', abbrev: 'NV' },
  { name: 'New Hampshire', abbrev: 'NH' },
  { name: 'New Jersey', abbrev: 'NJ' },
  { name: 'New Mexico', abbrev: 'NM' },
  { name: 'New York', abbrev: 'NY' },
  { name: 'North Carolina', abbrev: 'NC' },
  { name: 'North Dakota', abbrev: 'ND' },
  { name: 'Ohio', abbrev: 'OH' },
  { name: 'Oklahoma', abbrev: 'OK' },
  { name: 'Oregon', abbrev: 'OR' },
  { name: 'Other', abbrev: 'OTHER' },
  { name: 'Pennsylvania', abbrev: 'PA' },
  { name: 'Rhode Island', abbrev: 'RI' },
  { name: 'South Carolina', abbrev: 'SC' },
  { name: 'South Dakota', abbrev: 'SD' },
  { name: 'Tennessee', abbrev: 'TN' },
  { name: 'Texas', abbrev: 'TX' },
  { name: 'Utah', abbrev: 'UT' },
  { name: 'Vermont', abbrev: 'VT' },
  { name: 'Virginia', abbrev: 'VA' },
  { name: 'Washington', abbrev: 'WA' },
  { name: 'West Virginia', abbrev: 'WV' },
  { name: 'Wisconsin', abbrev: 'WI' },
  { name: 'Wyoming', abbrev: 'WY' },
];

export const statesObj = {};
for (const item of states) {
  statesObj[item.abbrev] = item.name;
}

export const country_list = [
  {
    id: '1',
    name: 'Afghanistan',
  },
  {
    id: '2',
    name: 'Aland Islands',
  },
  {
    id: '3',
    name: 'Albania',
  },
  {
    id: '4',
    name: 'Algeria',
  },
  {
    id: '5',
    name: 'American Samoa',
  },
  {
    id: '6',
    name: 'Andorra',
  },
  {
    id: '7',
    name: 'Angola',
  },
  {
    id: '8',
    name: 'Anguilla',
  },
  {
    id: '9',
    name: 'Antarctica',
  },
  {
    id: '10',
    name: 'Antigua and Barbuda',
  },
  {
    id: '11',
    name: 'Argentina',
  },
  {
    id: '12',
    name: 'Armenia',
  },
  {
    id: '13',
    name: 'Aruba',
  },
  {
    id: '14',
    name: 'Australia',
  },
  {
    id: '15',
    name: 'Austria',
  },
  {
    id: '16',
    name: 'Azerbaijan',
  },
  {
    id: '17',
    name: 'Bahamas',
  },
  {
    id: '18',
    name: 'Bahrain',
  },
  {
    id: '19',
    name: 'Bangladesh',
  },
  {
    id: '20',
    name: 'Barbados',
  },
  {
    id: '21',
    name: 'Belarus',
  },
  {
    id: '22',
    name: 'Belgium',
  },
  {
    id: '23',
    name: 'Belize',
  },
  {
    id: '24',
    name: 'Benin',
  },
  {
    id: '25',
    name: 'Bermuda',
  },
  {
    id: '26',
    name: 'Bhutan',
  },
  {
    id: '27',
    name: 'Bolivia',
  },
  {
    id: '28',
    name: 'Bosnia and Herzegovina',
  },
  {
    id: '29',
    name: 'Botswana',
  },
  {
    id: '30',
    name: 'Bouvet Island',
  },
  {
    id: '31',
    name: 'Brazil',
  },
  {
    id: '32',
    name: 'British Virgin Islands',
  },
  {
    id: '33',
    name: 'British Indian Ocean Territory',
  },
  {
    id: '34',
    name: 'Brunei Darussalam',
  },
  {
    id: '35',
    name: 'Bulgaria',
  },
  {
    id: '36',
    name: 'Burkina Faso',
  },
  {
    id: '37',
    name: 'Burundi',
  },
  {
    id: '38',
    name: 'Cambodia',
  },
  {
    id: '39',
    name: 'Cameroon',
  },
  {
    id: '40',
    name: 'Canada',
  },
  {
    id: '41',
    name: 'Cape Verde',
  },
  {
    id: '42',
    name: 'Cayman Islands',
  },
  {
    id: '43',
    name: 'Central African Republic',
  },
  {
    id: '44',
    name: 'Chad',
  },
  {
    id: '45',
    name: 'Chile',
  },
  {
    id: '46',
    name: 'China',
  },
  {
    id: '47',
    name: 'Hong Kong, SAR China',
  },
  {
    id: '48',
    name: 'Macao, SAR China',
  },
  {
    id: '49',
    name: 'Christmas Island',
  },
  {
    id: '50',
    name: 'Cocos(Keeling) Islands',
  },
  {
    id: '51',
    name: 'Colombia',
  },
  {
    id: '52',
    name: 'Comoros',
  },
  {
    id: '53',
    name: 'Congo(Brazzaville) ',
  },
  {
    id: '54',
    name: 'Congo, (Kinshasa) ',
  },
  {
    id: '55',
    name: 'Cook Islands',
  },
  {
    id: '56',
    name: 'Costa Rica',
  },
  {
    id: '57',
    name: 'C\u00F4te d’Ivoire',
  },
  {
    id: '58',
    name: 'Croatia',
  },
  {
    id: '59',
    name: 'Cuba',
  },
  {
    id: '60',
    name: 'Cyprus',
  },
  {
    id: '61',
    name: 'Czech Republic',
  },
  {
    id: '62',
    name: 'Denmark',
  },
  {
    id: '63',
    name: 'Djibouti',
  },
  {
    id: '64',
    name: 'Dominica',
  },
  {
    id: '65',
    name: 'Dominican Republic',
  },
  {
    id: '66',
    name: 'Ecuador',
  },
  {
    id: '67',
    name: 'Egypt',
  },
  {
    id: '68',
    name: 'El Salvador',
  },
  {
    id: '69',
    name: 'Equatorial Guinea',
  },
  {
    id: '70',
    name: 'Eritrea',
  },
  {
    id: '71',
    name: 'Estonia',
  },
  {
    id: '72',
    name: 'Ethiopia',
  },
  {
    id: '73',
    name: 'Falkland Islands(Malvinas) ',
  },
  {
    id: '74',
    name: 'Faroe Islands',
  },
  {
    id: '75',
    name: 'Fiji',
  },
  {
    id: '76',
    name: 'Finland',
  },
  {
    id: '77',
    name: 'France',
  },
  {
    id: '78',
    name: 'French Guiana',
  },
  {
    id: '79',
    name: 'French Polynesia',
  },
  {
    id: '80',
    name: 'French Southern Territories',
  },
  {
    id: '81',
    name: 'Gabon',
  },
  {
    id: '82',
    name: 'Gambia',
  },
  {
    id: '83',
    name: 'Georgia',
  },
  {
    id: '84',
    name: 'Germany',
  },
  {
    id: '85',
    name: 'Ghana',
  },
  {
    id: '86',
    name: 'Gibraltar',
  },
  {
    id: '87',
    name: 'Greece',
  },
  {
    id: '88',
    name: 'Greenland',
  },
  {
    id: '89',
    name: 'Grenada',
  },
  {
    id: '90',
    name: 'Guadeloupe',
  },
  {
    id: '91',
    name: 'Guam',
  },
  {
    id: '92',
    name: 'Guatemala',
  },
  {
    id: '93',
    name: 'Guernsey',
  },
  {
    id: '94',
    name: 'Guinea',
  },
  {
    id: '95',
    name: 'Guinea - Bissau',
  },
  {
    id: '96',
    name: 'Guyana',
  },
  {
    id: '97',
    name: 'Haiti',
  },
  {
    id: '98',
    name: 'Heard and Mcdonald Islands',
  },
  {
    id: '99',
    name: 'Holy See(Vatican City State) ',
  },
  {
    id: '100',
    name: 'Honduras',
  },
  {
    id: '101',
    name: 'Hungary',
  },
  {
    id: '102',
    name: 'Iceland',
  },
  {
    id: '103',
    name: 'India',
  },
  {
    id: '104',
    name: 'Indonesia',
  },
  {
    id: '105',
    name: 'Iran, Islamic Republic of',
  },
  {
    id: '106',
    name: 'Iraq',
  },
  {
    id: '107',
    name: 'Ireland',
  },
  {
    id: '108',
    name: 'Isle of Man',
  },
  {
    id: '109',
    name: 'Israel',
  },
  {
    id: '110',
    name: 'Italy',
  },
  {
    id: '111',
    name: 'Jamaica',
  },
  {
    id: '112',
    name: 'Japan',
  },
  {
    id: '113',
    name: 'Jersey',
  },
  {
    id: '114',
    name: 'Jordan',
  },
  {
    id: '115',
    name: 'Kazakhstan',
  },
  {
    id: '116',
    name: 'Kenya',
  },
  {
    id: '117',
    name: 'Kiribati',
  },
  {
    id: '118',
    name: 'Korea(North) ',
  },
  {
    id: '119',
    name: 'Korea(South) ',
  },
  {
    id: '120',
    name: 'Kuwait',
  },
  {
    id: '121',
    name: 'Kyrgyzstan',
  },
  {
    id: '122',
    name: 'Lao PDR',
  },
  {
    id: '123',
    name: 'Latvia',
  },
  {
    id: '124',
    name: 'Lebanon',
  },
  {
    id: '125',
    name: 'Lesotho',
  },
  {
    id: '126',
    name: 'Liberia',
  },
  {
    id: '127',
    name: 'Libya',
  },
  {
    id: '128',
    name: 'Liechtenstein',
  },
  {
    id: '129',
    name: 'Lithuania',
  },
  {
    id: '130',
    name: 'Luxembourg',
  },
  {
    id: '131',
    name: 'Macedonia, Republic of',
  },
  {
    id: '132',
    name: 'Madagascar',
  },
  {
    id: '133',
    name: 'Malawi',
  },
  {
    id: '134',
    name: 'Malaysia',
  },
  {
    id: '135',
    name: 'Maldives',
  },
  {
    id: '136',
    name: 'Mali',
  },
  {
    id: '137',
    name: 'Malta',
  },
  {
    id: '138',
    name: 'Marshall Islands',
  },
  {
    id: '139',
    name: 'Martinique',
  },
  {
    id: '140',
    name: 'Mauritania',
  },
  {
    id: '141',
    name: 'Mauritius',
  },
  {
    id: '142',
    name: 'Mayotte',
  },
  {
    id: '143',
    name: 'Mexico',
  },
  {
    id: '144',
    name: 'Micronesia, Federated States of',
  },
  {
    id: '145',
    name: 'Moldova',
  },
  {
    id: '146',
    name: 'Monaco',
  },
  {
    id: '147',
    name: 'Mongolia',
  },
  {
    id: '148',
    name: 'Montenegro',
  },
  {
    id: '149',
    name: 'Montserrat',
  },
  {
    id: '150',
    name: 'Morocco',
  },
  {
    id: '151',
    name: 'Mozambique',
  },
  {
    id: '152',
    name: 'Myanmar',
  },
  {
    id: '153',
    name: 'Namibia',
  },
  {
    id: '154',
    name: 'Nauru',
  },
  {
    id: '155',
    name: 'Nepal',
  },
  {
    id: '156',
    name: 'Netherlands',
  },
  {
    id: '157',
    name: 'Netherlands Antilles',
  },
  {
    id: '158',
    name: 'New Caledonia',
  },
  {
    id: '159',
    name: 'New Zealand',
  },
  {
    id: '160',
    name: 'Nicaragua',
  },
  {
    id: '161',
    name: 'Niger',
  },
  {
    id: '162',
    name: 'Nigeria',
  },
  {
    id: '163',
    name: 'Niue',
  },
  {
    id: '164',
    name: 'Norfolk Island',
  },
  {
    id: '165',
    name: 'Northern Mariana Islands',
  },
  {
    id: '166',
    name: 'Norway',
  },
  {
    id: '167',
    name: 'Oman',
  },
  {
    id: '168',
    name: 'Pakistan',
  },
  {
    id: '169',
    name: 'Palau',
  },
  {
    id: '170',
    name: 'Palestinian Territory',
  },
  {
    id: '171',
    name: 'Panama',
  },
  {
    id: '172',
    name: 'Papua New Guinea',
  },
  {
    id: '173',
    name: 'Paraguay',
  },
  {
    id: '174',
    name: 'Peru',
  },
  {
    id: '175',
    name: 'Philippines',
  },
  {
    id: '176',
    name: 'Pitcairn',
  },
  {
    id: '177',
    name: 'Poland',
  },
  {
    id: '178',
    name: 'Portugal',
  },
  {
    id: '179',
    name: 'Puerto Rico',
  },
  {
    id: '180',
    name: 'Qatar',
  },
  {
    id: '181',
    name: 'R\u00E9union',
  },
  {
    id: '182',
    name: 'Romania',
  },
  {
    id: '183',
    name: 'Russian Federation',
  },
  {
    id: '184',
    name: 'Rwanda',
  },
  {
    id: '185',
    name: 'Saint - Barth\u00E9lemy',
  },
  {
    id: '186',
    name: 'Saint Helena',
  },
  {
    id: '187',
    name: 'Saint Kitts and Nevis',
  },
  {
    id: '188',
    name: 'Saint Lucia',
  },
  {
    id: '189',
    name: 'Saint - Martin(French part) ',
  },
  {
    id: '190',
    name: 'Saint Pierre and Miquelon',
  },
  {
    id: '191',
    name: 'Saint Vincent and Grenadines',
  },
  {
    id: '192',
    name: 'Samoa',
  },
  {
    id: '193',
    name: 'San Marino',
  },
  {
    id: '194',
    name: 'Sao Tome and Principe',
  },
  {
    id: '195',
    name: 'Saudi Arabia',
  },
  {
    id: '196',
    name: 'Senegal',
  },
  {
    id: '197',
    name: 'Serbia',
  },
  {
    id: '198',
    name: 'Seychelles',
  },
  {
    id: '199',
    name: 'Sierra Leone',
  },
  {
    id: '200',
    name: 'Singapore',
  },
  {
    id: '201',
    name: 'Slovakia',
  },
  {
    id: '202',
    name: 'Slovenia',
  },
  {
    id: '203',
    name: 'Solomon Islands',
  },
  {
    id: '204',
    name: 'Somalia',
  },
  {
    id: '205',
    name: 'South Africa',
  },
  {
    id: '206',
    name: 'South Georgia and the South Sandwich Islands',
  },
  {
    id: '207',
    name: 'South Sudan',
  },
  {
    id: '208',
    name: 'Spain',
  },
  {
    id: '209',
    name: 'Sri Lanka',
  },
  {
    id: '210',
    name: 'Sudan',
  },
  {
    id: '211',
    name: 'Suriname',
  },
  {
    id: '212',
    name: 'Svalbard and Jan Mayen Islands',
  },
  {
    id: '213',
    name: 'Swaziland',
  },
  {
    id: '214',
    name: 'Sweden',
  },
  {
    id: '215',
    name: 'Switzerland',
  },
  {
    id: '216',
    name: 'Syrian Arab Republic(Syria) ',
  },
  {
    id: '217',
    name: 'Taiwan, Republic of China',
  },
  {
    id: '218',
    name: 'Tajikistan',
  },
  {
    id: '219',
    name: 'Tanzania, United Republic of',
  },
  {
    id: '220',
    name: 'Thailand',
  },
  {
    id: '221',
    name: 'Timor - Leste',
  },
  {
    id: '222',
    name: 'Togo',
  },
  {
    id: '223',
    name: 'Tokelau',
  },
  {
    id: '224',
    name: 'Tonga',
  },
  {
    id: '225',
    name: 'Trinidad and Tobago',
  },
  {
    id: '226',
    name: 'Tunisia',
  },
  {
    id: '227',
    name: 'Turkey',
  },
  {
    id: '228',
    name: 'Turkmenistan',
  },
  {
    id: '229',
    name: 'Turks and Caicos Islands',
  },
  {
    id: '230',
    name: 'Tuvalu',
  },
  {
    id: '231',
    name: 'Uganda',
  },
  {
    id: '232',
    name: 'Ukraine',
  },
  {
    id: '233',
    name: 'United Arab Emirates',
  },
  {
    id: '234',
    name: 'United Kingdom',
  },
  {
    id: '235',
    name: 'United States of America',
  },
  {
    id: '236',
    name: 'US Minor Outlying Islands',
  },
  {
    id: '237',
    name: 'Uruguay',
  },
  {
    id: '238',
    name: 'Uzbekistan',
  },
  {
    id: '239',
    name: 'Vanuatu',
  },
  {
    id: '240',
    name: 'Venezuela(Bolivarian Republic) ',
  },
  {
    id: '241',
    name: 'Viet Nam',
  },
  {
    id: '242',
    name: 'Virgin Islands, US',
  },
  {
    id: '243',
    name: 'Wallis and Futuna Islands',
  },
  {
    id: '244',
    name: 'Western Sahara',
  },
  {
    id: '245',
    name: 'Yemen',
  },
  {
    id: '246',
    name: 'Zambia',
  },
  {
    id: '247',
    name: 'Zimbabwe',
  },
];

export const countriesObj = {};
for (const item of country_list) {
  countriesObj[item.id] = item.name;
}

export function downloadCsv(csv, filename) {
  try {
    const downloadLink = document.createElement('a');
    const blob = new Blob(['\uFEFF', csv]);
    const url = window.URL.createObjectURL(blob);
    downloadLink.href = url;
    downloadLink.download = filename;
    document.body.append(downloadLink);
    downloadLink.click();
    downloadLink.remove();
  } catch (error) {
    console.log(error);
  }
}

export function downloadFile({ data, filename }) {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.append(link);
  link.click();
}

export function exportAsCSV(data, getLabels, filename) {
  try {
    const headers = Object.values(getLabels())
      .filter((e: any) => e.label !== undefined)
      .map((e: any) => `"${e.label.replace(/"/g, '""')}"`);
    const filteredLabels = Object.keys(getLabels())
      .filter((e) => e !== 'actions')
      .filter((e) => e !== 'checkbox')
      .filter((e) => e !== 'remediation_type');

    const csvString = [headers, ...data.map((item) => filteredLabels.map((label) => `"${item[label] ? `${item[label]}`.replace(/"/g, '""') : '-'}"`))]
      .map((e) => e.join(','))
      .join('\n');

    // download file
    downloadCsv(csvString, filename);

    return false;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export const showPassword = (elementId, show) => {
  const inputField = <HTMLInputElement>document.getElementById(elementId);
  if (show && inputField.type === 'password') {
    inputField.type = 'text';
  } else if (!show && inputField.type === 'text') {
    inputField.type = 'password';
  }
};

export const makeDeepCopy = (complexDataStructure) => {
  if (typeof complexDataStructure !== 'object') return false;
  return JSON.parse(JSON.stringify(complexDataStructure));
};

export const getStatusButtonColor = (status, greenArr, redArr) => {
  if (greenArr.includes(status)) return 'success';
  if (redArr.includes(status)) return 'fail';
  return 'warning';
};

export const appendScript = (scriptToAppend) => {
  const script = document.createElement('script');
  script.src = scriptToAppend;
  script.async = true;
  document.body.append(script);
};

export function calcPerc(total, partial) {
  return Number((Number.parseInt(partial, 10) / Number.parseInt(total, 10) || 0) * 100).toFixed(0);
}

const limit = pLimit(2);

export async function uploadFiles(documents = [], formData: FormData, setDocument, onReady) {
  const allFiles = documents.map((doc, index) =>
    limit(async () => {
      // set status to inprogress
      setDocument((s) => {
        const docsCopyState = [...s];
        docsCopyState[index] = {
          ...doc,
          status: 'inprogress',
          msg: 'In progress',
        };
        return docsCopyState;
      });
      // attach file
      formData.set('files[]', doc.file);
      // upload file
      const result = await axios.post(DOCUMENT_UPLOAD_URL, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      // set status to complete
      setDocument((s) => {
        const docsCopyStateAfter = [...s];
        docsCopyStateAfter[index] = {
          ...doc,
          status: result?.data?.status,
          msg: result?.data?.status === 'error' ? result?.data?.files[0]?.error : 'Successfully uploaded!',
        };
        return docsCopyStateAfter;
      });
    })
  );
  await Promise.all(allFiles);
  onReady();
}

// helper validate file
export async function validateFile(file, reader, setDocument, document, showAlertAction, parentFolder, formats, maxFileSize, maxFileNameLength) {
  const maxAllowedSize = window.maxAllowedSize || maxFileSize;
  const fileType = file.name.split('.').pop();
  const isTooBig = file.size > maxAllowedSize;
  const isCorrectType = formats.includes(fileType.toLowerCase());
  const isFilenameTooLong = file.name.length >= maxFileNameLength;
  const isFileDuplicate = document.some((d) => d.name === file.name);
  // const currentTotalSize = document.reduce((sum, d) => sum + d.file.size, file.size);
  // const isTotalSizeTooBig = currentTotalSize > maxAllowedSize;
  let isAlreadyUploaded = false;
  if (parentFolder) {
    isAlreadyUploaded = parentFolder?.children?.some((el) => el.folder_name === file.name) || false;
  }
  if (!isTooBig && isCorrectType && !isFilenameTooLong && !isFileDuplicate && !isAlreadyUploaded) {
    reader.addEventListener('load', (res) => {
      setDocument((document) => [
        ...document,
        {
          name: file.name,
          file,
          status: 'new',
          msg: 'Not started',
          data: res.target.result.split(',')[1],
          content: res.target.result.split(',')[1],
        },
      ]);
    });
    reader.readAsDataURL(file);
  } else {
    let errors = `Error: ${file.name}`;
    if (isTooBig) {
      errors += ', file size exceeds the maximum allowed size of 100Mb!';
    }
    // if (isTotalSizeTooBig) {
    //   errors += ', total files size exceeds the maximum allowed size of 100Mb!';
    // }
    if (isAlreadyUploaded) {
      errors += ', file with the same name, already exists.';
    }
    if (!isCorrectType) {
      errors += ', file type is not supported!';
    }
    if (isFilenameTooLong) {
      errors += ', file name exceeds the maximum allowed limit of 235 characters!';
    }
    if (isFileDuplicate) {
      errors += ', file already added!';
    }
    showAlertAction({ message: errors, color: 'danger' });
  }
}

// helper redirect /vendors path(old or new design)
export function redirectToVendors(ui_version) {
  return ui_version === '1' ? '/vendors' : '/achieve/affiliates';
}

// helper redirect /incidents path(old or new design)
export function redirectToIncidents(ui_version) {
  return ui_version === '1' ? '/incidents' : '/maintain/incidents';
}

// helper redirect /organizations path(old or new design)
export function redirectToOrganizations(ui_version) {
  return ui_version === '1' ? '/my-dashboard' : '/organizations';
}

// helper redirect /organizations path(old or new design)
export function redirectToMyTasks(ui_version) {
  return ui_version === '1' ? '/my-tasks' : '/';
}

// helper redirect /maintain/organizations path(old or new design)
export function redirectToMaintainOrganizations(ui_version) {
  return ui_version === '1' ? '/admin' : '/maintain/organization';
}

// helper redirect /people path(old or new design)
export function redirectToPeople(ui_version) {
  return ui_version === '1' ? '/people' : '/maintain/users';
}

// helper redirect /remediations path(old or new design)
export function redirectToRemediations(ui_version) {
  return ui_version === '1' ? '/assessments/remediations' : '/achieve/remediation';
}

// helper redirect /questionnaire path(old or new design)
export function redirectToQuestionnaire(token, ui_version) {
  return ui_version === '1' ? `/questionnaire/${token}` : `/questionnaire/${token}`;
}

// helper redirect /audit type id path(old or new design)
export function redirectToAudit(type, id, ui_version) {
  return ui_version === '1' ? `/assessments/${type}?id=${id}` : `/achieve/audits/${type}?id=${id}`;
}

// helper redirect /profile path(old or new design)
export function redirectToProfile(ui_version) {
  return ui_version === '1' ? '/profile' : '/';
}
