import React from 'react';

import FormElement from 'components/FormElement/FormElement';

import styles from './UserEmailAddresses.module.scss';

const UserEmailAddresses = (props) => {
  const { error, setStateErrors, className, errorMessage, state, setState } = props;

  const handleChange = (e) => {
    setState(e.target.value);
    setStateErrors((s) => ({
      ...s,
      emails: '',
    }));
  };

  return (
    <FormElement className={`${styles['user-email-addresses']} ${className || ''}`} labelText="USER EMAIL ADDRESSES" smallText="*" htmlFor="name" errorMessage={errorMessage || ''}>
      <textarea
        name="users-emails"
        className={`${styles['email-addresses']} ${error ? styles['error'] : ''}`}
        placeholder="Example list: bob@gmail.com, marc@gmail.com, joe@gmail.com, alan@gmail.com, stan@gmail.com"
        onChange={handleChange}
        defaultValue={state}
      />
      {error && <p className={styles['error-msg']}>Please, enter valid email address(s)</p>}
    </FormElement>
  );
};

export default UserEmailAddresses;
