import { call, put, takeLatest, delay } from 'redux-saga/effects';
import { push } from 'redux-first-history';

import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { actionApiCall } from 'helpers/api/ApiCalls';
import * as sagaConstants from 'helpers/constants/SagasConstants';

function* bulkUploadDeviceInventoryV2Saga({ payload }) {
  try {
    const apiResponse = yield call(actionApiCall, {
      actionType: ActionTypeConstants.BULK_UPLOAD_DEVICE_INVENTORY_V2,
      data: {
        ...payload,
      },
    });
    if (apiResponse.res.data.response === 200) {
      yield put({
        type: ActionTypeConstants.BULK_UPLOAD_DEVICE_INVENTORY_V2_SUCCESS,
        payload,
      });
      yield put(push('/assets'));

      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: apiResponse.res.data.message || `Device(s) ${sagaConstants.SUCCESSFULLY_IMPORTED}`,
          data: apiResponse.res.data.data,
          title: apiResponse.res.data.data.title,
          color: 'success',
          type: apiResponse.res.data.data.type,
        },
      });

      yield delay(10000);
      yield put({ type: ActionTypeConstants.HIDE_ALERT });
    } else {
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: apiResponse.res.data.message || `${sagaConstants.ISSUE_OCCURED} importing devices`,
          color: 'danger',
        },
      });
      yield delay(10000);
      yield put({ type: ActionTypeConstants.HIDE_ALERT });
    }
  } catch (error) {
    yield put({
      type: ActionTypeConstants.SHOW_ALERT,
      payload: {
        message: `${sagaConstants.SERVER_ERROR}`,
        color: 'danger',
      },
    });
    yield delay(10000);
    yield put({ type: ActionTypeConstants.HIDE_ALERT });
  }
}

export default function* () {
  yield takeLatest(ActionTypeConstants.BULK_UPLOAD_DEVICE_INVENTORY_V2, bulkUploadDeviceInventoryV2Saga);
}
