import React, { useEffect, useState } from 'react';
import HR from 'components/HR/HR';
import FormElement from 'components/FormElement/FormElement';
import Button from 'components/Button/Button';
import TableDefEvidence from 'table-defs/TableDefEvidence';
import FullDataTable from 'components/DataTable/FullDataTable';
import UploadDocument from 'components/UploadDocument/UploadDocument';
import styles from './AddEvidence.module.scss';
import { randomId } from 'helpers/utils';

const AddEvidence = (props) => {
  const { files, setFiles, username, clear } = props;
  const [state, setState] = useState({
    file: '',
    name: '',
  });

  useEffect(() => {
    if (clear) {
      setState({
        file: '',
        name: '',
      });
    }
  }, [clear]);

  const addEvidenceLocal = () => {
    try {
      const file = {
        id: randomId(),
        name: state['name'],
        file: state['file'],
      };
      setFiles((s) => [...s, file]);
      setState({
        id: '',
        name: '',
        file: '',
      });
    } catch (error) {
      console.log(error);
    }
  };

  const deleteEvidence = (payload) => {
    try {
      const f = files.filter((f) => f.id !== payload.id);
      setFiles(f);
    } catch (error) {
      console.log(error);
    }
  };

  const mapDatatable = (files, username) => {
    try {
      return files.map((file) => {
        return {
          id: file.id,
          filename: file.name || file.filename,
          created_at: new Date().toISOString(),
          created_by_name: username,
        };
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={`${styles['add-evidence']} ${props.className || ''}`}>
      <p className={styles['add-evidence-heading']}>Attach Evidence</p>

      <HR />

      <div className={styles['add-evidence-data']}>
        <>
          <div className={styles['holder']}>
            <FormElement labelText="DOCUMENTS" htmlFor="documents">
              <UploadDocument state={state} setState={setState} formats={['jpg', 'jpeg', 'png', 'gif', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'pdf']} />
            </FormElement>

            <Button className={styles.button} text="UPLOAD EVIDENCE" icon="fal fa-upload" disabled={state['file'] === '' ? true : false} onClick={addEvidenceLocal} />
          </div>

          <br />
          <br />
        </>

        <FullDataTable
          className={styles['add-evidence-table']}
          dataType={'task-evidence-add'}
          rowsData={mapDatatable(files, username)}
          definitions={TableDefEvidence}
          customTableProps={{ deleteEvidence }}
        />
      </div>
    </div>
  );
};

export default AddEvidence;
