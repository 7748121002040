import { call, put, takeLatest, delay } from 'redux-saga/effects';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { actionApiCall } from 'helpers/api/ApiCalls';
import * as sagaConstants from 'helpers/constants/SagasConstants';

function* downloadTemplateCloudAssetsV2Saga({ payload }) {
  try {
    const apiResponse = yield call(actionApiCall, {
      actionType: ActionTypeConstants.DOWNLOAD_TEMPLATE_CLOUD_ASSETS_V2,
      data: { ...payload },
    });

    if (apiResponse && apiResponse.res && apiResponse.res.headers && apiResponse.res.data) {
      // SUCCESS
      const filename = apiResponse.res.headers['content-disposition'].split('filename=')[1];
      const url = window.URL.createObjectURL(new Blob([apiResponse.res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    } else {
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: apiResponse.res.data.message || `${sagaConstants.ISSUE_OCCURED} downloading the file`,
          color: 'danger',
        },
      });
      yield put({ type: ActionTypeConstants.LOAD_CLOUD_ASSETS_V2_FAILURE });
      yield delay(10000);
      yield put({ type: ActionTypeConstants.HIDE_ALERT });
    }
  } catch (err) {
    console.log(err);
    yield put({
      type: ActionTypeConstants.SHOW_ALERT,
      payload: {
        message: `${sagaConstants.SERVER_ERROR}`,
        color: 'danger',
      },
    });
    yield delay(10000);
    yield put({ type: ActionTypeConstants.HIDE_ALERT });
    yield put({ type: ActionTypeConstants.LOAD_CLOUD_ASSETS_V2_FAILURE });
  }
}

export default function* () {
  yield takeLatest(ActionTypeConstants.DOWNLOAD_TEMPLATE_CLOUD_ASSETS_V2, downloadTemplateCloudAssetsV2Saga);
}
