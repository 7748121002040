import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import AppLayoutWrapFull from 'components/AppLayoutWrapFull';
import FullDataTable from 'components/DataTable/FullDataTable';
import Container from 'components/Container/Container';
import PageHeader from 'components/PageHeader/PageHeader';
import PageHeaderActions from 'components/PageHeader/components/PageHeaderActions/PageHeaderActions';

import TableDefCloudAudit from 'table-defs/TableDefCloudAudit';

// import { useConfirmModal } from 'hooks';
// import AddDeviceModal from "../components/AddDeviceModal/AddDeviceModal";
import AddApplicationModal from '../components/AddApplicationModal/AddApplicationModal';
import BulkUploadCloudModal from './components/BulkUploadCloudModal/BulkUploadCloudModal';
import BulkUpdateCloudModal from './components/BulkUpdateCloudModal/BulkUpdateCloudModal';

import EvidenceUploadModal from './../components/EvidenceUploadModal/EvidenceUploadModal';

import { CLOUD_AUDITS_PAGE } from 'helpers/constants/DataTableConstants';

import useFilterRows from 'helpers/customHooks/useFilterRows';
import { setSelectedRows, toggleDropdowns } from 'helpers/utils';
import {
  loadSitesAction,
  loadUsersAction,
  duplicateDeviceAction,
  deleteDeviceAction,
  loadCloudAssetsAction,
  activateCloudAppAction,
  deactivateCloudAppAction,
  loadAuditAction,
} from 'store/actions/Actions';
import Spinner from 'components/Spinner/Spinner';
import ActionStatusConstants from 'helpers/ActionStatusConstants';

const Audit = (props) => {
  const {
    SitesReducer,
    UsersReducer,
    loadSitesAction,
    loadUsersAction,
    loadCloudAssetsAction,
    devices,
    activateCloudAppAction,
    deactivateCloudAppAction,
    loadAuditAction,
    AuditReducer,
  } = props;

  const [search] = useSearchParams();
  const auditId = search.get('id');

  const [device, setDevice] = useState(() => {});
  const [showAddDeviceModal, setShowAddDeviceModal] = useState(() => false);
  // const [showDuplicateDeviceModal, setShowDuplicateDeviceModal] = useState(() => false);
  // const [showDecommissionDeviceModal, setShowDecommissionDeviceModal] = useState(() => false);
  const [showBulkUploadModal, setShowBulkUploadModal] = useState(() => false);
  const [showBulkUpdateModal, setShowBulkUpdateModal] = useState(() => false);
  // const [showConfirmModal, setShowConfirmModal, renderConfirmModal] = useConfirmModal();
  const [showEvidenceModal, setShowEvidenceModal] = useState(false);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (!SitesReducer.sites) {
      loadSitesAction();
    }
  }, [SitesReducer.sites, loadSitesAction]);

  useEffect(() => {
    if (!UsersReducer.users && AuditReducer.audit) {
      loadUsersAction({ site: AuditReducer.audit.site });
    }
  }, [UsersReducer.users, AuditReducer.audit, loadUsersAction]);

  useEffect(() => {
    if (UsersReducer.users && SitesReducer.sites) {
      // loadDeviceAction({"auditId" : auditId});
      loadCloudAssetsAction({ audit_id: auditId });
    }
  }, [loadCloudAssetsAction, auditId, UsersReducer.users, SitesReducer.sites]);

  useEffect(() => {
    if (!AuditReducer.audit && auditId) {
      loadAuditAction(auditId);
    }
  }, [AuditReducer.audit, auditId, loadAuditAction]);

  useEffect(() => {
    if (UsersReducer.status === ActionStatusConstants.SUCCESS && SitesReducer.status === ActionStatusConstants.SUCCESS && AuditReducer.status === ActionStatusConstants.SUCCESS) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [UsersReducer.users, SitesReducer.sites, AuditReducer.audit]);

  const noFilters = {
    text: '',
    type: '',
    'last-action': '',
    status: '',
    role: '',
    site: '',
    start: '',
    end: '',
    selected_sites: [],
    dateField: 'assigned',
  };

  const [filters, setFilters] = useState(noFilters);

  // const setFilter = filterName => e => {
  //     setFilters({
  //         ...filters,
  //         [filterName]: e.target.type === "checkbox" ? e.target.checked : e.target.value || ((e.target && e.target.attributes && e.target.attributes.value && e.target.attributes.value.value) || '')
  //     });
  // };

  // const setDateFilter = filterName => value => {
  //     setFilters({
  //         ...filters,
  //         [filterName]: value
  //     });
  // };

  const filteredRows = useFilterRows(filters, devices);

  const handleAdd = () => {
    setDevice({});
    setShowAddDeviceModal(true);
  };

  const handleActivate = (device) => {
    activateCloudAppAction({
      items: [device.id],
      auditId: auditId,
    });
  };

  const handleDeactivate = (device) => {
    deactivateCloudAppAction({
      items: [device.id],
      auditId: auditId,
    });
  };

  // const actDropdownRef = React.createRef();
  // const siteDropdownRef = React.createRef();
  const chooseActionDropdownRef = React.createRef();
  // const statusDropdownRef = React.createRef();

  const [selectedAuditIds, setSelectedAuditIds] = useState([]);
  const selectedIds = selectedAuditIds.map((item) => item.id);

  return (
    <AppLayoutWrapFull
      onClick={toggleDropdowns([
        // actDropdownRef,
        // siteDropdownRef,
        chooseActionDropdownRef,
        // statusDropdownRef,
      ])}
    >
      <PageHeader showStats={true}>
        <PageHeaderActions
          primaryButtonText="ADD ASSET"
          primaryButtonHandler={handleAdd}
          secondaryButtonText="BULK UPLOAD"
          secondaryButtonHandler={() => {
            setShowBulkUploadModal(!showBulkUploadModal);
          }}
          // terianryButtonHandler={() => setShowBulkUpdateModal(!showBulkUpdateModal)}
          // terianryButtonText="BULK UPDATE"
          // terianryButtonIcon="fal fa-plus"
          searchHandler={(e) => setFilters({ ...filters, text: e.target.value || '' })}
          searchValue={filters.text}
          chooseAction={[
            {
              actionName: 'Activate Selected',
              actionHandler: () => {
                props.activateCloudAppAction({
                  items: selectedIds,
                  auditId: auditId,
                });
              },
            },
            {
              actionName: 'Deactivate Selected',
              actionHandler: () => {
                props.deactivateCloudAppAction({
                  items: selectedIds,
                  auditId: auditId,
                });
              },
            },
            // {
            //     actionName: "Duplicate Selected",
            //     actionHandler: () => {
            //         props.duplicateDeviceAction({ items: selectedIds, auditId: auditId });
            //     }
            // },
            // {
            //     actionName: "Delete Selected",
            //     actionHandler: () => {
            //         const idCount = selectedIds.length;
            //         if (idCount > 0) {
            //             setShowConfirmModal({
            //                 title: 'Delete selected devices',
            //                 question: idCount === 1 ?
            //                     `Are you sure you want to delete the selected device?` :
            //                     `Are you sure you want to delete ${idCount} selected devices?`,
            //                 confirmAction: () => {
            //                     props.deleteDeviceAction({ items: selectedIds, auditId: auditId });
            //                 },
            //             });
            //         } else {
            //             setShowConfirmModal({
            //                 type: 'simple',
            //                 title: 'Nothing selected',
            //                 question: `Please select device to be deleted.`,
            //             });
            //         }
            //     }
            // },
          ]}
          chooseActionDropdownRef={chooseActionDropdownRef}
          // site={{
          //     onClick: (e) => {
          //         if (e.target.type === 'checkbox') {
          //             let selected_sites = filters.selected_sites.slice();
          //             if (e.target.checked) {
          //                 selected_sites.push(e.target.value);
          //             } else {
          //                 selected_sites = selected_sites.filter(site => site !== e.target.value);
          //             }
          //             setFilters({
          //                 ...filters,
          //                 site: selected_sites.length > 0 ? `Sites (${selected_sites.length})` : 'Site',
          //                 // site: "Site",
          //                 selected_sites: selected_sites
          //             });
          //         }
          //     },
          //     sites: SitesReducer.sites ? SitesReducer.sites.map(site => {
          //         return {
          //             id: site.id,
          //             siteName: site.name,
          //             value: filters.selected_sites.includes(site.id) ? true : false
          //         }
          //     }) : [],
          // }}
          // siteDropdownRef={siteDropdownRef}
          // status={{
          //     onChange: setFilter("status"),
          //     options: ["All", "Finalized", "Not Finalized"]
          // }}
          // statusDropdownRef={statusDropdownRef}
          // dateRange={{
          //     onChangeStart: setDateFilter("start"),
          //     onChangeEnd: setDateFilter("end")
          // }}
          filters={filters}
          resetFilters={() => {
            setFilters(noFilters);
          }}
        />
      </PageHeader>
      {devices && !isLoading ? (
        <Container wide fluid>
          {showBulkUploadModal && ReactDOM.createPortal(<BulkUploadCloudModal auditId={auditId} setShowModal={setShowBulkUploadModal} />, document.querySelector('#modal-root'))}
          {showBulkUpdateModal && ReactDOM.createPortal(<BulkUpdateCloudModal auditId={auditId} setShowModal={setShowBulkUpdateModal} />, document.querySelector('#modal-root'))}
          <div>
            <FullDataTable
              rowsData={filteredRows}
              definitions={TableDefCloudAudit}
              dataType={CLOUD_AUDITS_PAGE}
              onChangeVisibility={() => false}
              onRowClick={function (e) {
                if (e.currentTarget.classList.contains('col-actions') || e.currentTarget.classList.contains('col-row-selector')) {
                  return;
                } else {
                  setDevice(this.props.rowData);
                  setShowAddDeviceModal(true);
                }
              }}
              setSelectedRows={setSelectedRows(['id'], setSelectedAuditIds)}
              customTableProps={{
                handleActivate,
                handleDeactivate,
                evidence: (rowData) => {
                  setShowEvidenceModal(rowData);
                },
              }}
            />

            {showEvidenceModal && ReactDOM.createPortal(<EvidenceUploadModal entity_type="cloud_asset" showModal={showEvidenceModal} setShowModal={setShowEvidenceModal} />, document.querySelector('#modal-root'))}
            {showAddDeviceModal && ReactDOM.createPortal(<AddApplicationModal device={device} users={UsersReducer.users} setShowAddDeviceModal={setShowAddDeviceModal} auditId={auditId} />, document.querySelector('#modal-root'))}
            {/* {showAddDeviceModal && ReactDOM.createPortal(<AddDeviceModal device={device} users={UsersReducer.users} setShowAddDeviceModal={setShowAddDeviceModal} auditId={auditId} />, document.querySelector('#modal-root'))}
                    {showDuplicateDeviceModal && ReactDOM.createPortal(<AddDeviceModal device={device} setShowAddDeviceModal={setShowDuplicateDeviceModal} auditId={auditId} />, document.querySelector('#modal-root'))}
                    {showDecommissionDeviceModal && ReactDOM.createPortal(<DecommissionDeviceModal setShowAddDeviceModal={setShowDecommissionDeviceModal} auditId={auditId} device={device} />, document.querySelector('#modal-root'))} */}
          </div>
          {/* {showConfirmModal && renderConfirmModal(showConfirmModal, setShowConfirmModal)} */}
        </Container>
      ) : (
        <Spinner />
      )}
    </AppLayoutWrapFull>
  );
};

const mapStateToProps = (state) => ({
  SitesReducer: state.SitesReducer,
  UsersReducer: state.UsersReducer,
  devices: state.CloudAssetsReducer.devices,
  AuditReducer: state.AuditReducer,
});

const mapDispatchToProps = {
  loadSitesAction,
  loadUsersAction,
  duplicateDeviceAction,
  activateCloudAppAction,
  deactivateCloudAppAction,
  deleteDeviceAction,
  loadCloudAssetsAction,
  loadAuditAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Audit);
