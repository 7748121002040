import React, { useEffect } from 'react';
import MainLayout from 'v2/layouts/MainLayout/MainLayout';

import TasksTab from './TasksTab/TasksTab';
import { useAppDispatch, useAppSelector } from 'hooks';

import { loadDashboardTasksAction, loadSitesAction, loadUserCountersAction } from 'store/actions/Actions';

function MyTasks() {
  const SitesReducer = useAppSelector((state) => state.SitesReducer);
  const DashboardTasksReducer = useAppSelector((state) => state.DashboardTasksReducer);
  const tasks = DashboardTasksReducer?.tasks?.filter((task) => task.deactivated !== 't');

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!SitesReducer.sites) {
      dispatch(loadSitesAction());
    }
  }, [SitesReducer.sites]);

  useEffect(() => {
    if (SitesReducer.sites) {
      dispatch(loadDashboardTasksAction());
    }
  }, [SitesReducer.sites]);

  useEffect(() => {
    dispatch(loadUserCountersAction());
  }, [loadUserCountersAction, tasks]);  

  const trainingTasks = tasks?.filter((task) => task.type === 'training' && task.completed === 'f');
  const questionTasks = tasks?.filter((task) => task.type === 'question');
  const miscellaneousTasks = tasks?.filter((task) => task.type === 'task');

  return (
    <>
      <MainLayout>
        <div style={{ width: "100%", padding: '10px 50px' }}>
          <br />
          <h1 style={{ fontWeight: 500, marginBottom: '40px' }}>My Tasks</h1>
          {trainingTasks?.length > 0 && <TasksTab type="training" title="Training Assignments" dataType="trainingsDash" tasks={trainingTasks} />}
          {questionTasks?.length > 0 && <TasksTab type="question" title="Assessment Questions" dataType="questionsDash" tasks={questionTasks} />}
          {miscellaneousTasks?.length > 0 && <TasksTab type="my" title="Miscellaneous Tasks" dataType="miscDash" tasks={miscellaneousTasks} />}
          {(trainingTasks?.length === 0 && questionTasks?.length === 0 && miscellaneousTasks?.length === 0) &&
            <h2 style={{ fontWeight: 500, marginBottom: '40px', fontSize: '22px' }}>
              You currently do not have any tasks assigned that require your attention
            </h2>}
        </div>
      </MainLayout>
    </>
  );
}

export default MyTasks;
