import PropTypes from 'prop-types';
import React from 'react';

class DataTableHeader extends React.Component {
  renderHeaderCells() {
    const sortKey = this.props.customTableProps.sortKey;
    const sortOrder = this.props.customTableProps.sortOrder || 'asc';
    const onChangeSort = this.props.customTableProps.onChangeSort;

    const headerCells = this.props.colDefs.map((colDef, i) => {
      if (this.props.visibleColumnKeys && this.props.visibleColumnKeys.length && !this.props.visibleColumnKeys.includes(colDef.key)) {
        return null;
      }

      let cellClassName = `col col-${i} col-${colDef.key}`;
      if (colDef.className) {
        cellClassName += ` ${colDef.className}`;
      }

      return (
        <div className={cellClassName} key={i}>
          <div
            onClick={
              colDef.sortable === true
                ? () =>
                    colDef.key !== 'row-selector' &&
                    colDef.key !== 'actions' &&
                    onChangeSort({
                      sortKey: colDef.key,
                      sortOrder: sortKey === colDef.key ? (sortOrder === 'desc' ? 'asc' : 'desc') : 'asc',
                    })
                : null
            }
          >
            {typeof colDef.header === 'function' ? colDef.header({ customTableProps: this.props.customTableProps }) : colDef.header}
          </div>
          {colDef.sortable === true ? (
            <span
              className={`sort-control-wrap${sortKey === colDef.key ? ` active ${sortOrder}` : ''}`}
              onClick={() =>
                onChangeSort({
                  sortKey: colDef.key,
                  sortOrder: sortKey === colDef.key ? (sortOrder === 'desc' ? 'asc' : 'desc') : 'asc',
                })
              }
            >
              <i className="fas fa-caret-up sort-control sort-control-asc" />
              <i className="fas fa-caret-down sort-control sort-control-desc" />
            </span>
          ) : null}
        </div>
      );
    });

    return headerCells;
  }

  render() {
    let headerClassName = 'row table-header';
    if (this.props.index >= 0) {
      headerClassName += ' row-' + this.props.index;
    }

    if (this.props.className) {
      headerClassName += ` ${this.props.className}`;
    }

    return <div className={headerClassName}>{this.renderHeaderCells()}</div>;
  }

  static propTypes = {
    index: PropTypes.number,
    colDefs: PropTypes.array.isRequired,
    customTableProps: PropTypes.object,
    className: PropTypes.string,
    visibleColumnKeys: PropTypes.array,
  };
}

export default DataTableHeader;
