import React from 'react';
import moment from 'moment';

export default {
  TABLE: {
    className: 'data-table-document-history',
    initialSortKey: 'name',
    initialSortOrder: 'asc',
  },

  COLUMN: [
    {
      label: 'Name',
      header: 'NAME',
      key: 'name',
      className: 'name',
      sortable: true,
      format: ({ rowData, customTableProps }) => {
        return <span>{customTableProps.selectedFile['folder_name']}</span>;
      },
    },
    {
      label: 'Modified Date',
      header: 'MODIFIED DATE',
      key: 'last-modified',
      sortable: true,
      format: ({ rowData, customTableProps }) => {
        return <span>{moment(rowData['created']).format('MM/DD/YYYY')}</span>;
      },
    },
    {
      label: 'User',
      header: 'USER',
      key: 'user',
      sortable: true,
      format: ({ rowData, customTableProps }) => {
        return <span>{rowData['user']['name']}</span>;
      },
    },
    {
      label: '',
      header: '',
      key: 'restore',
      sortable: false,
      format: ({ rowData, customTableProps }) => {
        if (customTableProps.currentVersion === rowData.version) return <span>CURRENT</span>;
        else
          return (
            <span className="restore" onClick={() => customTableProps.restore(rowData, customTableProps.selectedFile)}>
              RESTORE
            </span>
          );
      },
    },
  ],
};
