import { call, put, takeLatest } from 'redux-saga/effects';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { actionApiCall } from 'helpers/api/ApiCalls';

function* readVendorAuditTokenSaga({ payload }) {
  try {
    const apiResponse = yield call(actionApiCall, {
      actionType: ActionTypeConstants.READ_VENDOR_AUDIT_TOKEN_AFFILIATE,
      data: {
        token: payload.token,
      },
    });
    if (apiResponse.res.data.response === 200) {
      const { data } = apiResponse.res.data;
      yield put({
        type: ActionTypeConstants.READ_VENDOR_AUDIT_TOKEN_AFFILIATE_SUCCESS,
        payload: {
          audit: data,
        },
      });
    } else {
      yield put({
        type: ActionTypeConstants.READ_VENDOR_AUDIT_TOKEN_AFFILIATE_SUCCESS,
        payload: {
          audit: {
            audit_complete: 't',
          },
        },
      });
    }
  } catch (err) {
    console.log(err);
    yield put({
      type: ActionTypeConstants.READ_VENDOR_AUDIT_TOKEN_AFFILIATE_FAILURE,
      payload: {
        audit: false,
      },
    });
  }
}

export default function* () {
  yield takeLatest(ActionTypeConstants.READ_VENDOR_AUDIT_TOKEN_AFFILIATE, readVendorAuditTokenSaga);
}
