import ActionStatusConstants from 'helpers/ActionStatusConstants';
import ActionTypeConstants from 'helpers/ActionTypeConstants';

const initialState = {
  status: ActionStatusConstants.INITIAL,
  isLoggedIn: false,
  userData: undefined,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypeConstants.AUTH_GET_USER: {
      return {
        ...state,
        status: ActionStatusConstants.ISBUSY,
      };
    }
    case ActionTypeConstants.AUTH_LOGGED_IN_SUCCESS: {
      return {
        ...state,
        status: ActionStatusConstants.SUCCESS,
        isLoggedIn: true,
        userData: payload,
      };
    }
    case ActionTypeConstants.AUTH_LOGGED_OUT_SUCCESS: {
      return {
        ...initialState,
        status: ActionStatusConstants.SUCCESS,
      };
    }
    default:
  }
  return state;
};
