import { call, put, takeLatest, delay, select } from 'redux-saga/effects';
import { push } from 'redux-first-history';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { actionApiCall } from 'helpers/api/ApiCalls';
import * as sagaConstants from 'helpers/constants/SagasConstants';

const getSelected = (state) => state.UserOrgSiteReducer.selected;
const getItems = (state) => state.SitesReducer.sites;
const getUsers = (state) => state.UsersReducer.users;

function transformTask(task, sites, users) {
  const t = JSON.parse(JSON.stringify(task));
  t.status = t.completed === 't' || t.completed === true ? 'Complete' : 'Incomplete';
  if (sites) {
    for (const item of sites) {
      if (item.id === t.site) {
        t.siteName = item.name;
      }
    }
  }
  if (users) {
    for (const user of users) {
      if (user.id === t.user_id) {
        t.assignee_name = `${user.name}${user.active === 'f' ? ' (Deactivated)' : ''}`;
        // t.deactivated = user.active === 'f' ? 't' : 'f';
        t.assignee = user.id;
      }
    }
  }
  return t;
}

function* attestDocumentDashboardSaga({ payload, type }) {
  const path = payload.path || "/dashboard";
  try {
    const selected = yield select(getSelected);
    const apiResponse = yield call(actionApiCall, {
      actionType: ActionTypeConstants.ATTEST_DOCUMENT_DASHBOARD,
      subtask: 'attestation',
      data: {
        organization: selected.org,
        site: selected.site,
        id: payload.id,
        understood: payload.understood,
        completed: false,
      },
    });

    if (apiResponse.res.data.response === 200) {
      yield put({
        type: ActionTypeConstants.ATTEST_DOCUMENT_DASHBOARD_SUCCESS,
      });

      // make second call, on attest success, load the task
      const apiResponse2 = yield call(actionApiCall, {
        actionType: ActionTypeConstants.LOAD_ONE_TASK,
        data: {
          id: payload.id,
        },
      });
      yield delay(250);
      if (apiResponse2?.res?.data?.response === 200) {
        const sites = yield select(getItems);
        const users = yield select(getUsers);
        const task = apiResponse2?.res?.data?.data || {};
        yield put({
          type: ActionTypeConstants.LOAD_ONE_TASK_SUCCESS,
          payload: {
            task: transformTask(task, sites, users),
          },
        });
      }
      yield put(push(`${path}/${payload.type}/${payload.id}/finalize`));
    } else {
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: apiResponse.res.data.message || `${sagaConstants.ISSUE_OCCURED} attesting the document`,
          color: 'danger',
        },
      });
      yield delay(10_000);
      yield put({ type: ActionTypeConstants.HIDE_ALERT });
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: ActionTypeConstants.SHOW_ALERT,
      payload: {
        message: `${sagaConstants.SERVER_ERROR}`,
        color: 'danger',
      },
    });
    yield delay(10_000);
    yield put({ type: ActionTypeConstants.HIDE_ALERT });
  }
}

export default function* AttestDocumentDashboardSaga() {
  yield takeLatest(ActionTypeConstants.ATTEST_DOCUMENT_DASHBOARD, attestDocumentDashboardSaga);
}
