import React, { RefObject } from 'react';
import ColVisibilitySelector from 'components/DataTable/ColVisibilitySelector';
import ItemActionsMenu from 'components/DataTable/ItemActionsMenu';
import Checkbox from 'components/Checkbox/Checkbox';
import moment from 'moment';
import Button from 'components/Button/Button';
import { getStatusButtonColor } from 'helpers/utils';

interface IAuditTable {
  TABLE;
  COLUMN: Array<{
    label: string;
    key: string;
    sortable?: boolean;
    header?;
    className?: string;
    format: ({ rowData, customTableProps }: { rowData; customTableProps }) => void;
    canBeHidden?: boolean;
  }>;
}

const tableDefenition: IAuditTable = {
  TABLE: {
    className: 'data-table-tasks',
    initialSortKey: 'due',
    initialSortOrder: 'desc',
  },

  COLUMN: [
    {
      label: 'Row Selector',
      key: 'row-selector',
      header: ({ customTableProps }) => {
        const isSelectedAll =
          customTableProps.selectedRows &&
          customTableProps.selectedRows.length &&
          customTableProps.rowsData &&
          customTableProps.rowsData.length &&
          customTableProps.selectedRows.length === customTableProps.rowsData.length
            ? true
            : false;
        return <Checkbox checked={isSelectedAll} onChange={() => customTableProps.doSelectAll()} name="row-selector" />;
      },
      className: 'col-row-selector',
      format: ({ rowData, customTableProps }) => {
        const isSelected =
          customTableProps.selectedRows &&
          customTableProps.selectedRows.length &&
          customTableProps.selectedRows.some((checkbox) => {
            return checkbox.id === rowData.id;
          })
            ? true
            : false;
        return (
          <Checkbox
            disabled={rowData.deactivated === 't' ? true : false}
            checked={rowData.deactivated === 't' ? false : isSelected}
            onChange={() => customTableProps.onRowSelect({ rowData })}
            name="row-selector"
          />
        );
      },
      canBeHidden: false,
    },
    {
      label: 'Title',
      header: 'TITLE',
      key: 'task',
      sortable: true,
      className: 'title',
      format: ({ rowData }) => {
        return (
          <span style={{ minWidth: 320 }}>
            <div style={{ fontWeight: 500 }}>{rowData['task']}</div>
            <small>{rowData['notes']}</small>
          </span>
        );
      },
    },
    {
      label: 'Program',
      header: 'PROGRAM',
      key: 'regulatory_act',
      sortable: true,
      className: 'type',
      format: ({ rowData }) => {
        return <span>{rowData['regulatory_act']}</span>;
      },
    },
    {
      label: 'Type',
      header: 'TYPE',
      key: 'type',
      sortable: true,
      className: 'type',
      format: ({ rowData }) => {
        return <span>{rowData['type']}</span>;
      },
    },
    // {
    //   label: 'Remediation',
    //   header: 'REMEDIATION',
    //   key: 'rem',
    //   sortable: true,
    //   className: 'type',
    //   format: ({ rowData }) => {
    //     return <span>{rowData['rem']}</span>;
    //   },
    // },
    {
      label: 'Risk',
      header: 'RISK',
      key: 'risk',
      sortable: true,
      className: 'type',
      format: ({ rowData }) => {
        return <span>{rowData['risk']}</span>;
      },
    },
    {
      label: 'Requested',
      header: 'REQUESTED',
      key: 'assigned',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{rowData['assigned'] ? moment(rowData['assigned']).format('MM/DD/YYYY') : '-'}</span>;
      },
    },
    {
      label: 'Due',
      header: 'DUE',
      key: 'due',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{rowData['due'] ? moment(rowData['due']).format('MM/DD/YYYY') : '-'}</span>;
      },
    },
    {
      label: 'Completed',
      header: 'COMPLETED',
      key: 'completed_on',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{rowData['completed_on'] ? moment(rowData['completed_on']).format('MM/DD/YYYY') : '-'}</span>;
      },
    },
    {
      label: 'Assignee',
      header: 'ASSIGNEE',
      key: 'assignee_name',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{rowData['assignee_name']}</span>;
      },
    },
    {
      label: 'Site',
      header: 'SITE',
      key: 'siteName',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{rowData.siteName || 'All Sites'}</span>;
      },
    },
    {
      label: 'Status',
      header: 'STATUS',
      key: 'status',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData, customTableProps }) => {
        const status = rowData.status;
        const color = getStatusButtonColor(status, ['Complete'], ['Incomplete']);
        return (
          <span>
            <Button
              cursorDefault={customTableProps.cursorDefault}
              color={color}
              size="md"
              icon={rowData.locked === 't' ? 'fas fa-lock' : undefined}
              text={status}
              onClick={() => null}
            />
          </span>
        );
      },
    },
    {
      label: 'Actions',
      key: 'actions',
      format: ({ rowData, customTableProps }) => {
        const actionMenuDropDown = React.createRef() as RefObject<ItemActionsMenu>;
        return (
          <ItemActionsMenu
            ref={actionMenuDropDown}
            actions={(() => {
              return [
                customTableProps.handleNotify && rowData.locked !== 't' && rowData.deactivated !== 't'
                  ? {
                      label: 'Notify',
                      action: () => {
                        if (actionMenuDropDown && actionMenuDropDown.current) {
                          actionMenuDropDown.current.handleMenuClose();
                        }
                        customTableProps.handleNotify(rowData);
                      },
                    }
                  : false,
                customTableProps.handleComplete && rowData.locked !== 't' && rowData.deactivated !== 't'
                  ? {
                      label: 'Mark Complete',
                      action: () => {
                        if (actionMenuDropDown && actionMenuDropDown.current) {
                          actionMenuDropDown.current.handleMenuClose();
                        }
                        customTableProps.handleComplete(rowData);
                      },
                    }
                  : false,
                customTableProps.handleIncomplete && rowData.locked !== 't' && rowData.deactivated !== 't'
                  ? {
                      label: 'Mark Incomplete',
                      action: () => {
                        if (actionMenuDropDown && actionMenuDropDown.current) {
                          actionMenuDropDown.current.handleMenuClose();
                        }
                        customTableProps.handleIncomplete(rowData);
                      },
                    }
                  : false,
                customTableProps.handleDelete && rowData.deactivated !== 't'
                  ? {
                      label: 'Delete',
                      action: () => {
                        if (actionMenuDropDown && actionMenuDropDown.current) {
                          actionMenuDropDown.current.handleMenuClose();
                        }
                        customTableProps.handleDelete(rowData);
                      },
                    }
                  : false,
              ];
            })()}
          />
        );
      },
      className: 'col-actions',
      header: ({ customTableProps }) => {
        return (
          <ColVisibilitySelector
            colDefs={customTableProps.colDefs}
            visibleColumnKeys={customTableProps.visibleColumnKeys}
            onChangeVisibility={(colKey) => customTableProps.onChangeVisibility(colKey)}
          />
        );
      },
    },
  ],
};

export default tableDefenition;
