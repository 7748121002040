import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import AppLayoutWrapFull from 'components/AppLayoutWrapFull';
import Container from 'components/Container/Container';
import FullDataTable from 'components/DataTable/FullDataTable';
import TableDefUserCertificates from 'table-defs/TableDefUserCertificates';

import { loadUserCertificatesAction } from 'store/actions/Actions';
import { useAppDispatch, useAppSelector } from 'hooks';
import Spinner from 'components/Spinner/Spinner';
import ActionStatusConstants from 'helpers/ActionStatusConstants';

function Certificates() {
  const PAGE_NAME = 'certificates_user';
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const CertificatesUserReducer = useAppSelector((state) => state.CertificatesUserReducer);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (!CertificatesUserReducer.certificates) {
      dispatch(loadUserCertificatesAction());
    }
  }, [CertificatesUserReducer.certificates, dispatch]);

  useEffect(() => {
    if (CertificatesUserReducer.status === ActionStatusConstants.SUCCESS) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [CertificatesUserReducer.certificates]);

  return (
    <AppLayoutWrapFull>
      {isLoading ? (
        <Spinner />
      ) : (
        <Container wide>
          <h1 style={{ paddingLeft: '48px', fontWeight: 500 }}>My Certificates</h1>
          {CertificatesUserReducer && CertificatesUserReducer.certificates && (
            <FullDataTable
              rowsData={CertificatesUserReducer.certificates}
              definitions={TableDefUserCertificates}
              dataType={PAGE_NAME}
              onRowClick={function (e) {
                if (e.currentTarget.classList.contains('col-actions') || e.currentTarget.classList.contains('col-row-selector')) {
                  return;
                }
                navigate(`/certificates/${this.props.rowData.id}`);
              }}
            />
          )}
        </Container>
      )}
    </AppLayoutWrapFull>
  );
}

export default Certificates;
