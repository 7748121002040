import { call, put, takeLatest, select, delay } from 'redux-saga/effects';

import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { actionApiCall } from 'helpers/api/ApiCalls';
import * as sagaConstants from 'helpers/constants/SagasConstants';

const getItems = (state) => state.SitesReducer.sites;
const getTypes = (state) => state.IncidentTypesReducer.incidentTypes;
const getSelected = (state) => state.UserOrgSiteReducer.selected;

const codes = ['Not Verified', 'Under Investigation', 'Verified', 'Completed'];

function transformIncidents(incidents, sites, incidentTypes) {
  const incidentTypesList = {};
  incidentTypes.forEach((item) => {
    incidentTypesList[item.id] = item.name;
  });

  const result = incidents.map((incident) => {
    incident.status = codes[incident.investigating];
    incident.affected = incident.five_hundred_affected;
    incident.typeText = incident.incident_type ? incidentTypesList[incident.incident_type] : '';
    if (sites) {
      sites.forEach((item) => {
        if (item.id === incident.site) {
          incident.siteName = item.name;
        }
      });
    }
    if (incident.tasks) {
      incident.tasks.map((task) => {
        task.status = task.completed === 't' || task.completed === true ? 'Complete' : 'Incomplete';
        return task;
      });
    }
    return incident;
  });
  return result;
}

function* loadIncidentsSaga({ payload }) {
  try {
    const selected = yield select(getSelected);
    const apiResponse = yield call(actionApiCall, {
      actionType: ActionTypeConstants.LOAD_INCIDENTS,
      data: {
        organization: selected.org,
      },
    });
    if (apiResponse.res.data.response === 200) {
      const { data } = apiResponse.res.data;
      const sites = yield select(getItems);
      const incidentTypes = yield select(getTypes);
      yield put({
        type: ActionTypeConstants.LOAD_INCIDENTS_SUCCESS,
        payload: {
          incidents: transformIncidents(data, sites, incidentTypes),
        },
      });
    } else {
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: apiResponse.res.data.message || `${sagaConstants.ISSUE_OCCURED} loading incidents`,
          color: 'danger',
        },
      });
      yield delay(10000);
      yield put({ type: ActionTypeConstants.HIDE_ALERT });
    }
  } catch (err) {
    yield put({
      type: ActionTypeConstants.SHOW_ALERT,
      payload: {
        message: `${sagaConstants.SERVER_ERROR}`,
        color: 'danger',
      },
    });
    yield delay(10000);
    yield put({ type: ActionTypeConstants.HIDE_ALERT });
  }
}

export default function* () {
  yield takeLatest(ActionTypeConstants.LOAD_INCIDENTS, loadIncidentsSaga);
}
