import { put, call, takeLatest, delay, select } from 'redux-saga/effects';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { actionApiCall } from 'helpers/api/ApiCalls';
import * as sagaConstants from 'helpers/constants/SagasConstants';

const getItems = (state) => state.SitesReducer.sites;
const getUsers = (state) => state.UsersReducer.users;
const getSelected = (state) => state.UserOrgSiteReducer.selected;

function transformTask(task, sites, users) {
  task.status = task.completed === 't' || task.completed === true ? 'Complete' : 'Incomplete';
  if (sites) {
    sites.forEach((item) => {
      if (item.id === task.site) {
        task.siteName = item.name;
      }
    });
  }
  if (users) {
    users.forEach((user) => {
      if (user.id === task.user_id) {
        task.assignee_name = `${user.name}${user.active === 'f' ? ' (Deactivated)' : ''}`;
        // task.deactivated = user.active === 'f' ? 't' : 'f';
        task.assignee = user.id;
      }
    });
  }
  return task;
}

function* loadOneTaskSaga({ payload }) {
  try {
    const selected = yield select(getSelected);
    const apiResponse = yield call(actionApiCall, {
      actionType: ActionTypeConstants.LOAD_ONE_TASK,
      data: {
        organization: selected.org,
        id: payload.id,
        evidence: true,
        notes: true,
      },
    });

    if (apiResponse && apiResponse.res.data.response === 200) {
      const sites = yield select(getItems);
      const users = yield select(getUsers);
      const task = apiResponse.res.data.data || {};
      yield put({
        type: ActionTypeConstants.LOAD_ONE_TASK_SUCCESS,
        payload: {
          task: transformTask(task, sites, users),
          refresh: payload.refresh || undefined,
        },
      });
    } else {
      yield put({
        type: ActionTypeConstants.LOAD_ONE_TASK_FAILURE,
        payload: { message: apiResponse.res.data.message },
      });
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: apiResponse.res.data.message || `${sagaConstants.ISSUE_OCCURED} loading the task`,
          color: 'danger',
        },
      });
      yield delay(10000);
      yield put({ type: ActionTypeConstants.HIDE_ALERT });
    }
  } catch (err) {
    console.log(err);
    yield put({
      type: ActionTypeConstants.SHOW_ALERT,
      payload: {
        message: `${sagaConstants.SERVER_ERROR}`,
        color: 'danger',
      },
    });
    yield delay(10000);
    yield put({ type: ActionTypeConstants.HIDE_ALERT });
  }
}

export default function* () {
  yield takeLatest(ActionTypeConstants.LOAD_ONE_TASK, loadOneTaskSaga);
}
