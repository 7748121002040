import React, { useEffect, useState } from 'react';
import { useNavigate, useMatch } from 'react-router-dom';
import { connect } from 'react-redux';

import MainLayout from 'v2/layouts/MainLayout/MainLayout';
import Container from 'components/Container/Container';
import { Button } from 'components/Button/Button';

import { loadSitesAction, completeTaskAction, loadTaskAction, loadUsersAction, updateTaskAction, attestDocumentDashboardAction } from 'store/actions/Actions';

import DocumentView from './views/DocumentView';
import DocumentAttest from './views/DocumentAttest';
import DocumentFinalize from './views/DocumentFinalize';

import VideoView from './views/VideoView';
import VideoAttest from './views/VideoAttest';
import VideoFinalize from './views/VideoFinalize';

import QuestionView from './views/QuestionView';
import QuestionFinalize from './views/QuestionFinalize';

import TaskView from './views/TaskView';
import TaskFinalize from './views/TaskFinalize';

import styles from './Stepper.module.scss';

// helper function for view settings
function views(props) {
  const { type, step, requires_attest = false, is_attested = false } = props;
  const view = {
    document_view: {
      name: 'document_view',
      title: 'Read Training Document',
      subtitle: requires_attest
        ? 'Please read the training document below and follow the steps to attest and finalize the task'
        : 'Please read the training document and finalize the task',
      steps: {
        read: 'complete',
        attest: requires_attest ? 'disabled' : undefined,
        finalize: 'disabled',
        read_title: 'Read Document',
        attest_title: 'Attest Document',
        finalize_title: 'Finalize Task',
      },
      currentStep: requires_attest ? 'STEP 1/3' : 'STEP 1/2',
      width: '100%',
    },
    document_attest: {
      name: 'document_attest',
      title: 'Attest Training Document',
      subtitle: 'Please attest that you have read and understood the the training document',
      steps: {
        read: 'complete',
        attest: 'complete',
        finalize: 'disabled',
        read_title: 'Read Document',
        attest_title: 'Attest Document',
        finalize_title: 'Finalize Task',
      },
      currentStep: 'STEP 2/3',
      width: '50%',
    },
    document_finalize: {
      name: 'document_finalize',
      // title: 'Finalize Task',
      // subtitle: 'Please confirm you want to finalize the task',
      steps: {
        read: 'complete',
        attest: requires_attest ? (is_attested ? 'complete' : 'active') : undefined,
        finalize: requires_attest ? (is_attested ? 'complete' : 'active') : 'complete',
        read_title: 'Read Document',
        attest_title: 'Attest Document',
        finalize_title: 'Finalize Task',
      },
      currentStep: requires_attest ? 'STEP 3/3' : 'STEP 2/2',
      width: '0%',
    },

    video_view: {
      name: 'video_view',
      title: 'Watch Training Video',
      subtitle: requires_attest ? 'Please watch training video and follow the steps to attest and finalize the task' : 'Please watch the training video and finalize the task',
      steps: {
        read: 'complete',
        attest: requires_attest ? 'disabled' : undefined,
        finalize: 'disabled',
        read_title: 'View Training',
        attest_title: 'Attest Video',
        finalize_title: 'Finalize Task',
      },
      currentStep: requires_attest ? 'STEP 1/3' : 'STEP 1/2',
      width: '100%',
    },
    video_attest: {
      name: 'video_attest',
      title: 'Attest Training Video',
      subtitle: 'Please attest that you have viewed and understood the training video',
      steps: {
        read: 'complete',
        attest: 'complete',
        finalize: 'disabled',
        read_title: 'View Training',
        attest_title: 'Attest Video',
        finalize_title: 'Finalize Task',
      },
      currentStep: 'STEP 2/3',
      width: '50%',
    },
    video_finalize: {
      name: 'video_finalize',
      // title: 'Finalize Task',
      // subtitle: requires_attest ? (is_attested ? 'Task is finalized' : 'Task is NOT finalized') : 'Task is finalized',
      steps: {
        read: 'complete',
        attest: requires_attest ? (is_attested ? 'complete' : 'active') : undefined,
        finalize: requires_attest ? (is_attested ? 'complete' : 'active') : 'complete',
        read_title: 'View Training',
        attest_title: 'Attest Video',
        finalize_title: 'Finalize Task',
      },
      currentStep: requires_attest ? 'STEP 3/3' : 'STEP 2/2',
      width: '0%',
    },
    question_view: {
      name: 'question_view',
      title: 'Answer Question',
      subtitle: 'Please answer the question and finalize the task',
      steps: {
        read: 'complete',
        attest: undefined,
        finalize: 'disabled',
        read_title: 'Answer Question',
        attest_title: undefined,
        finalize_title: 'Finalize Task',
      },
      currentStep: 'STEP 1/2',
      width: '100%',
    },
    question_finalize: {
      name: 'question_finalize',
      // title: 'Finalize Task',
      // subtitle: 'finalize the question task',
      steps: {
        read: 'complete',
        attest: undefined,
        finalize: 'complete',
        read_title: 'Answer Question',
        attest_title: undefined,
        finalize_title: 'Finalize Task',
      },
      currentStep: 'STEP 2/2',
      width: '0%',
    },
    task_view: {
      name: 'task_view',
      title: 'Mark task complete',
      subtitle: 'Please complete the task and mark it as complete',
      steps: {
        read: 'complete',
        attest: undefined,
        finalize: 'disabled',
        read_title: 'View Task',
        attest_title: undefined,
        finalize_title: 'Finalize Task',
      },
      currentStep: 'STEP 1/2',
      width: '100%',
    },
    task_finalize: {
      name: 'task_finalize',
      // title: 'Finalize Generic Task',
      // subtitle: 'Task is finalized',
      steps: {
        read: 'complete',
        attest: undefined,
        finalize: 'complete',
        read_title: 'View Task',
        attest_title: undefined,
        finalize_title: 'Finalize Task',
      },
      currentStep: 'STEP 2/2',
      width: '0%',
    },
  };

  if (type === 'document' && step === 'view') {
    return view.document_view;
  }
  if (type === 'document' && step === 'attest') {
    return view.document_attest;
  }
  if (type === 'document' && step === 'finalize') {
    return view.document_finalize;
  }
  if (type === 'video' && step === 'view') {
    return view.video_view;
  }
  if (type === 'video' && step === 'attest') {
    return view.video_attest;
  }
  if (type === 'video' && step === 'finalize') {
    return view.video_finalize;
  }
  if (type === 'question' && step === 'view') {
    return view.question_view;
  }
  if (type === 'question' && step === 'finalize') {
    return view.question_finalize;
  }
  if (type === 'task' && step === 'view') {
    return view.task_view;
  }
  if (type === 'task' && step === 'finalize') {
    return view.task_finalize;
  }
  return view.document_view;
}

// Component for all dashboard step pages
function TaskStepper(props) {
  const { TaskReducer, attestDocumentDashboardAction, loadTaskAction, completeTaskAction, loadSitesAction, SitesReducer, QuizReducer, url } = props;
  console.log(props);
  const match = useMatch(`${url}/:type/:taskId/:step`);
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [isUnderstood, setUnderstood] = useState(false);
  const [question, setQuestion]: any = useState({});

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    setLoading(false);
  }, [match.params]);

  useEffect(() => {
    loadTaskAction({ id: match.params.taskId });
  }, [match.params.taskId]);

  useEffect(() => {
    if (!SitesReducer.sites) {
      loadSitesAction();
    }
  }, [SitesReducer.sites, loadSitesAction]);

  useEffect(() => {
    if (!TaskReducer.task && SitesReducer.sites) {
      loadTaskAction({ id: match.params.taskId });
    }
  }, [match.params.taskId, TaskReducer.task, SitesReducer.sites]);

  useEffect(() => {
    if (TaskReducer.task) {
      setUnderstood(TaskReducer?.task?.subtask_data?.understood === 't');
    }
    if (TaskReducer?.task?.subtask === 'auditResponse') {
      const q = TaskReducer.task
        ? {
            id: TaskReducer.task.id,
            question: TaskReducer.task.subtask_data.question,
            answer: TaskReducer.task.subtask_data.answer || '',
            task: TaskReducer.task.subtask_data.task,
            risk: TaskReducer.task.subtask_data.risk || '5',
            notes: TaskReducer.task.subtask_data.notes || '',
            disabled: TaskReducer?.task.subtask_data.audit_finalized,
          }
        : {};
      if (JSON.stringify(question) === '{}') {
        setQuestion(q);
      }
    }
  }, [TaskReducer.task]);

  // handle task finalize
  const handleTaskComplete = (props) => {
    completeTaskAction([
      {
        id: TaskReducer.task.id,
        completed: true,
        dashboard: true,
        path: props.path,
      },
    ]);
  };

  // handle question finalize
  const handleQuestionFinalize = (props) => {
    if (question && question.task) {
      completeTaskAction([
        {
          id: question.id,
          answer: question.answer === '' ? '3' : question.answer,
          risk: question.risk,
          notes: question.notes,
          completed: true,
          subtask: 'audit_response',
          path: props.path,
          dashboard: true,
        },
      ]);
    }
  };

  // step: view, attest, finalize

  const requires_attest = TaskReducer?.task?.subtask_data?.requires_attest === 't';
  const is_attested = TaskReducer?.task?.subtask_data?.understood === 't';
  const is_completed = TaskReducer?.task?.completed === 't';

  const currentView: any = TaskReducer.task
    ? views({
        ...match.params,
        requires_attest,
        is_completed,
        is_attested,
      })
    : undefined;

  return (
    <MainLayout>
      {currentView ? (
        <div className={styles.stepper}>
          <div className={styles.stepper_holder}>
            <div>
              <Button className={styles.leftButton} text="CLOSE" color="secondary" size="md" onClick={() => navigate('/my-tasks')} icon="fal fa-times" />

              <div className={styles.stepper_content}>
                <div className={styles.line}>
                  <div className={styles.before} />
                  <div className={styles.after} style={{ width: currentView.width }} />
                </div>
                {currentView.steps.read && (
                  <div className={styles.step} style={{ alignItems: 'flex-start' }}>
                    <span className={`${styles.circle} ${styles[currentView.steps.read]}`} />
                    <span className={styles.step_title}>{currentView.steps.read_title}</span>
                  </div>
                )}
                {currentView.steps.attest && (
                  <div className={styles.step}>
                    <span className={`${styles.circle} ${styles[currentView.steps.attest]}`} />
                    <span className={styles.step_title}>{currentView.steps.attest_title}</span>
                  </div>
                )}
                {currentView.steps.finalize && (
                  <div className={styles.step}>
                    <span className={`${styles.circle} ${styles[currentView.steps.finalize]}`} />
                    <span className={styles.step_title}>{currentView.steps.finalize_title}</span>
                  </div>
                )}
              </div>

              <Button className={styles.rightButton} text={currentView.currentStep} color="label" size="md" icon="fal fa-check" />
            </div>
          </div>

          {/* header */}
          {currentView.title ? (
            <div className={styles.header}>
              {currentView.title && <h2>{currentView.title}</h2>}
              {currentView.subtitle && <p>{currentView.subtitle}</p>}
            </div>
          ) : (
            <div>
              <br />
              <br />
            </div>
          )}

          {/* content */}
          <Container className={styles.content}>
            {currentView.name === 'document_view' && <DocumentView task={TaskReducer.task || {}} />}
            {currentView.name === 'document_attest' && (
              <DocumentAttest isLoading={isLoading} isUnderstood={isUnderstood} setUnderstood={setUnderstood} task={TaskReducer.task || {}} />
            )}
            {currentView.name === 'document_finalize' && <DocumentFinalize task={TaskReducer.task || {}} />}

            {currentView.name === 'video_view' && <VideoView task={TaskReducer.task || {}} />}
            {currentView.name === 'video_attest' && <VideoAttest isLoading={isLoading} isUnderstood={isUnderstood} setUnderstood={setUnderstood} task={TaskReducer.task || {}} />}
            {currentView.name === 'video_finalize' && <VideoFinalize />}

            {currentView.name === 'question_view' && <QuestionView question={question} setQuestion={setQuestion} task={TaskReducer.task || {}} />}
            {currentView.name === 'question_finalize' && <QuestionFinalize task={TaskReducer.task || {}} />}

            {currentView.name === 'task_view' && <TaskView task={TaskReducer.task || {}} />}
            {currentView.name === 'task_finalize' && <TaskFinalize task={TaskReducer.task || {}} />}
          </Container>

          {/* next step */}
          {currentView.name === 'document_view' && (
            <div className={styles.footer}>
              <div style={{ justifyContent: 'center' }}>
                {requires_attest ? (
                  <Button
                    onClick={() => navigate(`/my-documents/document/${match.params.taskId}/attest`)}
                    text="ATTEST DOCUMENT"
                    size="lg"
                    iconEnd="fal fa-long-arrow-right"
                    className={styles.attestButton}
                  />
                ) : (
                  <Button
                    onClick={() => {
                      handleTaskComplete({
                        path: `/my-documents/document/${match.params.taskId}/finalize`,
                      });
                    }}
                    text="FINALIZE TASK"
                    size="lg"
                    // iconEnd="fal fa-long-arrow-right"
                    className={styles.attestButton}
                  />
                )}
              </div>
            </div>
          )}

          {currentView.name === 'document_attest' && (
            <div className={styles.footer}>
              <div>
                <Button onClick={() => navigate(`/my-documents/document/${match.params.taskId}/view`)} text="BACK" size="lg" color="secondary" icon="fal fa-long-arrow-left" />
                <Button
                  onClick={() => {
                    // attest document
                    if (match.params.taskId) {
                      setLoading(true);
                      attestDocumentDashboardAction({
                        id: match.params.taskId,
                        understood: isUnderstood,
                        type: 'document',
                        path: '/my-documents',
                      });
                    }
                  }}
                  text="FINALIZE TASK"
                  size="lg"
                  disabled={isLoading}
                  iconEnd={isLoading ? 'fad fa-spinner-third fa-spin' : undefined}
                />
              </div>
            </div>
          )}

          {currentView.name === 'document_finalize' && (
            <div className={styles.footer}>
              <div>
                {requires_attest ? (
                  <Button onClick={() => navigate(`/my-documents/document/${match.params.taskId}/attest`)} text="BACK" size="lg" color="secondary" icon="fal fa-long-arrow-left" />
                ) : (
                  <Button onClick={() => navigate(`/my-documents/document/${match.params.taskId}/view`)} text="BACK" size="lg" color="secondary" icon="fal fa-long-arrow-left" />
                )}
                {is_attested || !requires_attest ? (
                  <Button
                    // onClick={() => handleTaskComplete({ id: match.params.taskId })}
                    onClick={() => navigate('/my-documents')}
                    text="OK"
                    color="success"
                    size="lg"
                  />
                ) : (
                  <Button onClick={() => navigate('/my-documents')} text="OK" color="danger" size="lg" />
                )}
              </div>
            </div>
          )}

          {currentView.name === 'video_view' && (
            <div className={styles.footer}>
              <div style={{ justifyContent: 'center' }}>
                {requires_attest ? (
                  <Button
                    disabled={!!(QuizReducer && QuizReducer.section && QuizReducer.section.some((item) => item.passed === false))}
                    onClick={() => navigate(`/my-tasks/video/${match.params.taskId}/attest`)}
                    text="ATTEST VIDEO"
                    size="lg"
                    iconEnd="fal fa-long-arrow-right"
                    className={styles.attestButton}
                  />
                ) : (
                  <Button
                    onClick={() => {
                      setLoading(true);
                      handleTaskComplete({
                        path: `/my-tasks/video/${match.params.taskId}/finalize`,
                      });
                    }}
                    text="FINALIZE TASK"
                    size="lg"
                    className={styles.attestButton}
                    disabled={isLoading}
                    iconEnd={isLoading ? 'fad fa-spinner-third fa-spin' : undefined}
                  />
                )}
              </div>
            </div>
          )}

          {currentView.name === 'video_attest' && (
            <div className={styles.footer}>
              <div>
                <Button onClick={() => navigate(`/my-tasks/video/${match.params.taskId}/view`)} text="BACK" size="lg" color="secondary" icon="fal fa-long-arrow-left" />
                <Button
                  onClick={() => {
                    // attest video
                    if (match.params.taskId) {
                      setLoading(true);
                      attestDocumentDashboardAction({
                        id: match.params.taskId,
                        understood: isUnderstood,
                        type: 'video',
                        path: '/my-tasks',
                      });
                    }
                  }}
                  disabled={isLoading}
                  text="FINALIZE TASK"
                  size="lg"
                  iconEnd={isLoading ? 'fad fa-spinner-third fa-spin' : undefined}
                />
              </div>
            </div>
          )}
          {currentView.name === 'video_finalize' && (
            <div className={styles.footer}>
              <div>
                {requires_attest ? (
                  <Button onClick={() => navigate(`/my-tasks/video/${match.params.taskId}/attest`)} text="BACK" size="lg" color="secondary" icon="fal fa-long-arrow-left" />
                ) : (
                  <Button onClick={() => navigate(`/my-tasks/video/${match.params.taskId}/view`)} text="BACK" size="lg" color="secondary" icon="fal fa-long-arrow-left" />
                )}
                {is_attested || !requires_attest ? (
                  <Button
                    // onClick={() => handleTaskComplete({ id: match.params.taskId })}
                    // text="FINALIZE"
                    onClick={() => navigate('/my-tasks')}
                    text="OK"
                    color="success"
                    size="lg"
                  />
                ) : (
                  <Button onClick={() => navigate('/my-tasks')} text="OK" color="danger" size="lg" />
                )}
              </div>
            </div>
          )}

          {currentView.name === 'question_view' && (
            <div className={styles.footer}>
              <div style={{ justifyContent: 'center' }}>
                {!is_completed ? (
                  <Button
                    onClick={() => {
                      setLoading(true);
                      handleQuestionFinalize({
                        path: `/my-tasks/question/${match.params.taskId}/finalize`,
                      });
                    }}
                    // onClick={() => navigate(`/dashboard/question/${match.params.taskId}/finalize`)}
                    text="FINALIZE TASK"
                    size="lg"
                    disabled={isLoading}
                    iconEnd={isLoading ? 'fad fa-spinner-third fa-spin' : undefined}
                  />
                ) : (
                  <Button onClick={() => navigate('/my-tasks')} text="CLOSE" size="lg" color="secondary" />
                )}
              </div>
            </div>
          )}

          {currentView.name === 'question_finalize' && (
            <div className={styles.footer}>
              <div>
                {/* <Button
                            onClick={() => navigate(`/dashboard/question/${match.params.taskId}/view`)}
                            text="BACK"
                            size="lg"
                            color="secondary"
                            icon="fal fa-long-arrow-left"
                        /> */}
                <span />
                <Button onClick={() => navigate('/my-tasks')} text="OK" color="success" size="lg" className={styles.attestButton} />
                <span />
                {/* <Button
                            onClick={() => handleQuestionFinalize({ id: match.params.taskId })}
                            text="FINALIZE"
                            size="lg"
                        /> */}
              </div>
            </div>
          )}

          {currentView.name === 'task_view' && (
            <div className={styles.footer}>
              <div style={{ justifyContent: 'center' }}>
                <Button
                  onClick={() => {
                    setLoading(true);
                    handleTaskComplete({
                      path: `/my-tasks/task/${match.params.taskId}/finalize`,
                    });
                  }}
                  text="FINALIZE TASK"
                  size="lg"
                  disabled={isLoading}
                  iconEnd={isLoading ? 'fad fa-spinner-third fa-spin' : undefined}
                />
              </div>
            </div>
          )}

          {currentView.name === 'task_finalize' && (
            <div className={styles.footer}>
              <div>
                {/* <Button
                            onClick={() => navigate(`/dashboard/task/${match.params.taskId}/view`)}
                            text="BACK"
                            size="lg"
                            color="secondary"
                            icon="fal fa-long-arrow-left"
                        /> */}
                <span />
                <Button
                  // onClick={() => handleTaskComplete({ id: match.params.taskId })}
                  // text="FINALIZE"
                  onClick={() => navigate('/my-tasks')}
                  text="OK"
                  color="success"
                  size="lg"
                  className={styles.attestButton}
                />
                <span />
              </div>
            </div>
          )}
        </div>
      ) : undefined}
    </MainLayout>
  );
}

const mapStateToProps = (state) => ({
  SitesReducer: state.SitesReducer,
  UsersReducer: state.UsersReducer,
  // UserOrgSiteReducer: state.UserOrgSiteReducer,
  userData: state.AuthReducer.userData,
  TaskReducer: state.TaskReducer,
  AuditsReducer: state.AuditsReducer,
  QuizReducer: state.QuizReducer,
});

const mapDispatchToProps = {
  loadTaskAction,
  loadSitesAction,
  updateTaskAction,
  attestDocumentDashboardAction,
  loadUsersAction,
  completeTaskAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskStepper);
