import { call, takeLatest, put, delay } from 'redux-saga/effects';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { actionApiCall } from 'helpers/api/ApiCalls';
import { push } from 'redux-first-history';
import * as sagaConstants from 'helpers/constants/SagasConstants';

// const getSelected = state => state.UserOrgSiteReducer.selected;

function* loadUserOrgSiteSaga({ payload }) {
  try {
    // const selected = yield select(getSelected);
    const apiResponse = yield call(actionApiCall, {
      actionType: ActionTypeConstants.LOAD_USER_ORG_SITE,
      data: {
        organization: false,
      },
    });

    if (apiResponse.res.data.response === 200) {
      const { data } = apiResponse.res.data;
      let haveSites = false;
      if (data.organizations) {
        Object.values(data.organizations).map((org) => {
          if (Object.keys(org.sites).length !== 0) {
            haveSites = true;
          }
        });
      }

      if (haveSites) {
        yield put({
          type: ActionTypeConstants.LOAD_USER_ORG_SITE_SUCCESS,
          payload: {
            organizations: data.organizations,
          },
        });
      } else {
        // yield put({
        //     type: ActionTypeConstants.SHOW_ALERT,
        //     payload: {
        //         message: "Your site have been deactivated, please contact support.",
        //         color: "danger"
        //     }
        // });
        yield put(push('/login'));
        yield delay(10000);
        yield put({ type: ActionTypeConstants.HIDE_ALERT });
      }
    } else {
      // yield put({
      //     type: ActionTypeConstants.SHOW_ALERT,
      //     payload: {
      //         message: apiResponse.res.data.message || "An issue occurred while loading organizations",
      //         color: "danger"
      //     }
      // });
      yield put(push('/login'));
      yield delay(10000);
      // yield put({ type: ActionTypeConstants.HIDE_ALERT });
    }
  } catch (err) {
    yield put({
      type: ActionTypeConstants.SHOW_ALERT,
      payload: {
        message: `${sagaConstants.SERVER_ERROR}`,
        color: 'danger',
      },
    });
    yield delay(10000);
    yield put({ type: ActionTypeConstants.HIDE_ALERT });
  }
}

export default function* () {
  yield takeLatest(ActionTypeConstants.LOAD_USER_ORG_SITE, loadUserOrgSiteSaga);
}
