import React from 'react';
import moment from 'moment';

export default {
  TABLE: {
    className: 'data-table-user-certificates',
    initialSortKey: 'created_at',
    initialSortOrder: 'desc',
  },

  COLUMN: [
    {
      label: 'Training',
      header: 'TRAINING',
      key: 'training_name',
      sortable: true,
      className: 'training',
      canBeHidden: false,
      format: ({ rowData }) => {
        return <span>{rowData['training_name']}</span>;
      },
    },
    {
      label: 'Created At',
      header: 'CREATED AT',
      key: 'created_at',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{rowData['created_at'] ? moment(rowData['created_at']).format('MM/DD/YYYY') : '-'}</span>;
      },
    },
  ],
};
