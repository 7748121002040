export const getPermissions = (roles, _selected_org, _selected_site = undefined) => {
  const selected_org = _selected_org && `${_selected_org}`;
  const selected_site = _selected_site && `${_selected_site}`;
  let is_admin = false;
  let is_super = false;
  let is_coach = false;
  let is_officer = false;
  let is_org_admin = false;
  let is_site_admin = false;
  let is_org_admin_label = false;
  // if any of the roles is super or coach use first * role
  // if selcted org.. use selected org role
  // else use first role
  let permissions;

  if (roles.some((role) => role.name.includes('Coach') || role.name.includes('Super'))) {
    permissions = roles.filter((role) => role.organization === '*')[0];
  } else if (selected_org && selected_org !== '') {
    permissions = roles.filter((role) => role.organization === selected_org)[0];
  } else {
    permissions = roles[0];
  }

  // special case - site admin, with multiple sites, when site with user permissions is selected
  if (selected_site && roles.some((role) => role.name === 'Site Admin')) {
    const r = roles.some((role) => role.site === selected_site && role.name === 'Site Admin');
    if (r) {
      // site admin
      is_site_admin = true;
      is_admin = true;
    }
  }

  if (permissions?.name === 'Org Administrator') {
    is_org_admin = true;
    is_org_admin_label = true;
    is_admin = true;
  }
  if (permissions?.name.includes('Officer')) {
    is_officer = true;
    is_admin = true;
  }
  if (permissions?.name.includes('Coach')) {
    is_coach = true;
    is_admin = true;
  }
  if (permissions?.name.includes('Super')) {
    is_super = true;
    is_admin = true;
  }

  return {
    is_admin,
    is_super,
    is_coach,
    is_officer,
    is_org_admin,
    is_site_admin,
    is_org_admin_label,
  };
};
