import React, { useState } from 'react';
import { connect } from 'react-redux';
import styles from './DecommissionDeviceModal.module.scss';
import Button from 'components/Button/Button';
import Radio from 'components/Radio/Radio';
import Modal from 'components/Modal/Modal';
import FormElement from 'components/FormElement/FormElement';
import { loadAuditAction, decommissionDeviceAction } from 'store/actions/Actions';

const DecommissionDeviceModal = (props) => {
  const [notes, setNotes] = useState('');
  const [isDestroyed, setDestroyed] = useState(null);
  const [isValid, setValid] = useState(null);

  const handleDecommission = () => {
    if (isDestroyed === null) {
      setValid(false);
    } else {
      props.decommissionDeviceAction({
        how_destroyed: notes,
        destroyed_hdd: isDestroyed,
        items: [props.device.id],
        auditId: props.auditId,
      });
      props.setShowAddDeviceModal(false);
    }
  };

  return (
    <Modal className={styles['modal']} setShowModal={props.setShowAddDeviceModal}>
      <div>
        <p className={styles['modal-heading']}>Decommission Device</p>
      </div>

      <div className={styles['modal-content']}>
        {/* <div className={styles.text}>
                <p>You&#39;re about to decommission device - {props.device} ({props.device})</p>
            </div> */}

        <div>
          <div className={styles.question}>Is this device Hard Disk Drive (HDD) physically destroyed?</div>

          {isValid === false && <div className={styles.error}>Please, select option</div>}

          <div>
            <Radio
              checked={isDestroyed === true}
              labelText="Yes, this device Hard Disk Drive is physically destroyed."
              onChange={() => {
                setDestroyed(true);
                setValid(true);
              }}
              value={true}
            />
            <Radio
              checked={isDestroyed === false}
              labelText="No, this device Hard Disk Drive is NOT physically destroyed."
              onChange={() => {
                setDestroyed(false);
                setValid(true);
              }}
              value={false}
            />
          </div>
        </div>

        <div className={styles.notes}>
          <FormElement labelText="NOTES" htmlFor="notes">
            <textarea
              rows="4"
              placeholder="Additional details about device decommission"
              // className={styles.notes}
              value={notes}
              onChange={(e) => {
                setNotes(e.target.value);
              }}
            />
          </FormElement>
        </div>
      </div>

      <div className={styles['modal-footer']}>
        <Button text="CANCEL" onClick={() => props.setShowAddDeviceModal(false)} color="secondary" />
        <Button color="danger" className={styles.create} text="YES, DECOMMISSION DEVICE" onClick={handleDecommission} />
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => state.AuditReducer;

const mapDispatchToProps = {
  loadAuditAction,
  decommissionDeviceAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(DecommissionDeviceModal);
