import React from 'react';
// import moment from 'moment';

import TableDefAffiliates from 'table-defs/TableDefAffiliates';
import TableDefAllUsers from 'table-defs/TableDefAllUsers';
import TableDefAudits from 'table-defs/TableDefAudits';
import TableDefRemediation from 'table-defs/TableDefRemediation';
import TableDefIncidents from 'table-defs/TableDefIncidents';
import TableDefTraining from 'table-defs/TableDefTraining';
import TableDefTasks from 'table-defs/TableDefTasks';
import Dropdown from 'components/Dropdown/Dropdown';
import { useConfirmModal } from 'hooks';

import styles from './OneOrgHeader.module.scss';

interface IProps {
  org: {
    seal_expires_at: string;
    coach_name: string;
    org_name: string;
  };
  setDropdownOptions: (dropdownOptions) => void;
}

function OneOrgHeader({ org, setDropdownOptions }: IProps) {
  const [showConfirmModal, setShowConfirmModal, renderConfirmModal] = useConfirmModal();

  // Put #1 for tab1's total and #2 to tab2's total
  const dropdownOptions = [
    {
      id: '1',
      label: 'All info panels',
      toggleAll: true,
      checked: true,
    },
    {
      id: '2',
      label: 'Statistics',
      checked: true,
    },
    {
      id: '3',
      label: 'Overall Progress',
      checked: true,
    },
    {
      id: '4',
      link: '/people',
      rowDataProperty: 'unregistered_users',
      label: 'People',
      key: 'users',
      tab1: 'All Users (#1)',
      tab2: 'Unregistered Users (#2)',
      tableDefinition: TableDefAllUsers,
      checked: true,
    },
    {
      id: '5',
      link: '/vendors',
      rowDataProperty: 'under_review_affiliates',
      label: 'Vendors',
      key: 'affiliates',
      tab1: 'All Vendors (#1)',
      tab2: 'Under Review (#2)',
      tableDefinition: TableDefAffiliates,
      checked: true,
    },
    {
      id: '6',
      link: '/assessments',
      label: 'Recent Audits',
      key: 'audits',
      tab1: 'All Audits (#1)',
      tab2: undefined,
      tableDefinition: TableDefAudits,
      checked: true,
    },
    {
      id: '7',
      link: '/assessments/remediations',
      label: 'Remediation',
      key: 'remediations',
      tab1: 'All Remediations (#1)',
      tab2: undefined,
      tableDefinition: TableDefRemediation,
      checked: true,
    },
    {
      id: '8',
      link: '/incidents',
      label: 'Incidents',
      key: 'incidents',
      tab1: 'All Incidents (#1)',
      tab2: undefined,
      tableDefinition: TableDefIncidents,
      checked: true,
    },
    {
      id: '9',
      link: '/trainings',
      label: 'Trainings',
      key: 'trainings',
      tab1: 'All Trainings (#1)',
      tab2: undefined,
      tableDefinition: TableDefTraining,
      checked: true,
    },
    {
      id: '10',
      link: '/my-tasks',
      label: 'Tasks',
      key: 'tasks',
      tab1: 'All Tasks (#1)',
      tab2: undefined,
      tableDefinition: TableDefTasks,
      checked: true,
    },
  ];

  const onDropdownChange = (dropdownOptions) => {
    setDropdownOptions(dropdownOptions);
  };

  return (
    <div className={styles.org_header}>
      <div className={styles.top}>
        <div className={styles.org_name}>{org.org_name}</div>
        <div className={styles.right}>
          <div className={styles.settings}>
            <Dropdown button configuration options={dropdownOptions} dropdownHeader dropdowHeaderTitle="DASHBOARD SETTINGS / SHOW" multipleSelect onChange={onDropdownChange} />
          </div>
        </div>
      </div>
      {showConfirmModal && renderConfirmModal(showConfirmModal, setShowConfirmModal)}
    </div>
  );
}

export default OneOrgHeader;
