import { useState, Dispatch, SetStateAction } from 'react';

import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';

import { RootState, AppDispatch } from 'store';

import { IDataProps, renderConfirmModal } from 'components/ConfirmationModal/ConfirmationModal';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export function useConfirmModal() {
  const [showConfirmModal, setShowConfirmModal]: [IDataProps, Dispatch<SetStateAction<IDataProps>>] = useState();
  return <[IDataProps, Dispatch<SetStateAction<IDataProps>>, typeof renderConfirmModal]>[showConfirmModal, setShowConfirmModal, renderConfirmModal];
}
