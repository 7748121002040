import React from 'react';

import Modal from 'components/Modal/Modal';
import { Button } from 'components/Button/Button';

function CertificatePreviewModal(props) {
  return (
    <Modal setShowModal={props.setShowModal}>
      <Modal.Heading>Certificate Preview</Modal.Heading>
      <Modal.Content>{props.modalContent}</Modal.Content>
      <Modal.Footer>
        <Button text="CLOSE" color="primary" onClick={() => props.setShowModal(false)} />
      </Modal.Footer>
    </Modal>
  );
}

export default CertificatePreviewModal;
