import React from 'react';
import classNames from 'classnames';

import styles from './PageHeaderActions.module.scss';
import Button from 'components/Button/Button';
import ChooseAction from './components/ChooseAction/ChooseAction';
import Select from 'components/Select/Select';
import SelectMulti from 'components/SelectMulti/SelectMulti';
import Checkbox from 'components/Checkbox/Checkbox';
import DateInput from 'components/DateInput/DateInput';
// import Row from "components/Row/Row";
import SearchInput from 'components/SearchInput/SearchInput';
import FormElement from 'components/FormElement/FormElement';
import { useAppSelector } from 'hooks';

/**
 *
 * @param {String} className - additional className to add
 * @param {String} primaryButtonText - text shown on the primary button
 * @param {Function} primaryButtonHandler - event handler
 * @param {String} secondaryButtonText - text shown on the secondary button
 * @param {Function} secondaryButtonHandler - event handler
 * @param {Object[]} chooseAction - an array of objects in {actionName, actionHandler} format
 * @param {String} terianryButtonText - text shown on the terianry button
 * @param {Function} terianryButtonHandler - event handler
 * @param {String} chooseAction[].actionName - name of action to show in dropdown
 * @param {Function} chooseAction[].onClick - onClick event handler for name
 * @param {Object} chooseActionDropdownRef - ref used to close dropdown from parent
 * @param {Object} site - multiple Select
 * @param {Function} site.onClick - update parent state
 * @param {Object[]} site.sites - sites array in {siteName, value} format
 * @param {String} sites[].siteName - name to display
 * @param {Boolean} site[].value - true == checked; false == unchecked
 * @param {Object[]} dateRange - 2x DateInput
 * @param {String} dateRange[].value - value to show
 * @param {Function} dateRange[].onChange - event handler
 * @param {Object} status - Select status
 * @param {Function} status.onChange - event handler
 * @param {String[]} status.options - options (values) to show
 * @param {Object} statusDropdownRef - ref used to close dropdown from parent
 * @param {Function} searchHandler - onChange event handler for search
 * @param {String} searchValue - value for search input
 * parent
 * @param {Object} type - Select type
 * @param {Function} type.onChange - event handler
 * @param {String[]} type.options - options (values) to show
 * @param {Object} typeDropdownRef - ref used to close dropdown from parent
 * @param {Object} lastAction - Select lastAction
 * @param {Function} lastAction.onChange - event handler
 * @param {String[]} lastAction.options - options (values) to show
 * @param {Object} lastActionDropdownRef - ref used to close dropdown from parent
 * @param {Object} role - Select role
 * @param {Function} role.onChange - event handler
 * @param {String[]} role.options - options (roles) to show
 * @param {Object} roleDropdownRef - ref used to close dropdown from parent
 * @param {Function} resetFilters - function to reset filters
 * last action - Select
 * expiration date range - 2x DateInput
 * role - Select
 */
const PageHeaderActions = (props) => {
  const isMenuExpanded = useAppSelector((state) => state.NavigationReducer).isMenuExpanded;

  const stylePageHeaderActions = classNames(styles['page-header-actions'], isMenuExpanded ? styles.expanded : styles.collapsed);
  const stylePageHeaderRight = classNames(styles['page-header-right'], isMenuExpanded ? styles.expanded : styles.collapsed);
  const styleSearch = classNames(styles.search, isMenuExpanded ? styles.expanded : styles.collapsed);

  const secondaryButton = props.secondaryButtonText ? (
    <Button className={styles.button} onClick={props.secondaryButtonHandler} text={props.secondaryButtonText} icon="fal fa-plus" color="secondary" size="md" />
  ) : null;

  const terianryButton = props.terianryButtonText ? (
    <Button
      className={styles.button}
      onClick={props.terianryButtonHandler}
      text={props.terianryButtonText}
      icon={props.terianryButtonIcon || 'fal fa-edit'}
      // icon="fal fa-edit"
      color="secondary"
      size="md"
    />
  ) : null;

  const fourthButton = props.fourthButtonText ? (
    <Button className={styles.button} onClick={props.fourthButtonHandler} text={props.fourthButtonText} icon="fal fa-plus" color="secondary" size="md" />
  ) : null;

  const fifthButton = props.fifthButtonText ? (
    <Button className={styles.button} onClick={props.fifthButtonHandler} text={props.fifthButtonText} icon="fal fa-plus" color="secondary" size="md" />
  ) : null;

  const roleFilter = props.role ? (
    <FormElement
      className={styles['role-filter']}
      // labelText="role"
      htmlFor="role"
    >
      <Select
        value={props.filters.role || 'Role'}
        isActive={props && props.filters && props.filters.role && props.filters.role !== 'All'}
        placeholder="Role"
        size="md"
        onClick={(e) => {
          props.role.onChange(e);
        }}
        dropdownToggleRef={props.roleDropdownRef || null}
      >
        {props.role.options.map((el) => (
          <li value={el} key={`${el}-key_role`}>
            {el}
          </li>
        ))}
      </Select>
    </FormElement>
  ) : null;

  const siteFilter = props.site ? (
    <FormElement
      className={styles['site-filter']}
      // labelText="SITE"
      htmlFor="site"
    >
      <SelectMulti
        placeholder="Site"
        value={(props && props.filters && props.filters.site) || 'Site'}
        isActive={props && props.filters && props.filters.site && props.filters.site !== 'Site'}
        onClick={(e) => props.site.onClick(e)}
        size="md"
      >
        {props.site.sites.map((site, index) => (
          <Checkbox checked={site.value} name={site.siteName} labelText={site.siteName} key={site.id} value={site.id} />
        ))}
      </SelectMulti>
    </FormElement>
  ) : null;

  const remFilter = props.rem ? (
    <FormElement
      className={styles['type-filter']}
      // labelText="TYPE"
      htmlFor="rem"
    >
      <Select
        value={(props && props.filters && props.filters.rem) || 'Remediation Type'}
        placeholder="Rem"
        isActive={props && props.filters && props.filters.rem && props.filters.rem !== 'All remediation'}
        size="md"
        onClick={(e) => {
          props.rem.onChange(e);
        }}
        dropdownToggleRef={props.remDropdownRef || null}
      >
        {props.rem.options.map((el) => (
          <li value={el} key={`${el}-key_rem`}>
            {el}
          </li>
        ))}
      </Select>
    </FormElement>
  ) : null;

  const typeFilter = props.type ? (
    <FormElement
      className={styles['type-filter']}
      // labelText="TYPE"
      htmlFor="type"
    >
      <Select
        value={(props && props.filters && props.filters.type) || 'Task Type'}
        placeholder="Type"
        isActive={props && props.filters && props.filters.type && props.filters.type !== 'All task types'}
        size="md"
        onClick={(e) => {
          props.type.onChange(e);
        }}
        dropdownToggleRef={props.typeDropdownRef || null}
      >
        {props.type.options.map((el) => (
          <li value={el} key={`${el}-key_type`}>
            {el}
          </li>
        ))}
      </Select>
    </FormElement>
  ) : null;

  const coachFilter = props.coach ? (
    <FormElement
      className={styles['type-filter']}
      // labelText="TYPE"
      htmlFor="coach"
    >
      <Select
        value={(props && props.filters && props.filters.coach) || 'Coach Name'}
        placeholder="Coach"
        isActive={props && props.filters && props.filters.coach && props.filters.coach !== 'All coaches'}
        size="md"
        onClick={(e) => {
          props.coach.onChange(e);
        }}
        dropdownToggleRef={props.coachDropdownRef || null}
      >
        {props.coach.options.map((el) => (
          <li value={el} key={`kkf-${el}`}>
            {el}
          </li>
        ))}
      </Select>
    </FormElement>
  ) : null;

  const regFilter = props.reg ? (
    <FormElement
      className={styles['type-filter']}
      // labelText="TYPE"
      htmlFor="reg"
    >
      <Select
        value={(props && props.filters && props.filters.regulatory_act) || 'Program'}
        placeholder="Program"
        isActive={props && props.filters && props.filters.regulatory_act && props.filters.regulatory_act !== 'All programs'}
        size="md"
        onClick={(e) => {
          props.reg.onChange(e);
        }}
        dropdownToggleRef={props.regDropdownRef || null}
      >
        {props.reg.options.map((el) => (
          <li value={el} key={`${el}-key_reg`}>
            {el}
          </li>
        ))}
      </Select>
    </FormElement>
  ) : null;

  const programFilter = props.program ? (
    <FormElement
      className={styles['type-filter']}
      // labelText="TYPE"
      htmlFor="reg"
    >
      <Select
        value={(props && props.filters && props.filters.regulatory_act) || 'Program'}
        placeholder="Program"
        isActive={props && props.filters && props.filters.regulatory_act && props.filters.regulatory_act !== 'All programs'}
        size="md"
        onClick={(e) => {
          props.program.onChange(e);
        }}
        dropdownToggleRef={props.programDropdownRef || null}
      >
        {props.program.options.map((el) => (
          <li value={el} key={`${el}-key_reg`}>
            {el}
          </li>
        ))}
      </Select>
    </FormElement>
  ) : null;

  const userFilter = props.user ? (
    <FormElement
      className={styles['type-filter']}
      // labelText="TYPE"
      htmlFor="user"
    >
      <Select
        value={(props && props.filters && props.filters.user_name) || 'User'}
        placeholder="User"
        isActive={props && props.filters && props.filters.user_name && props.filters.user_name !== 'All Users'}
        size="md"
        onClick={(e) => {
          props.user.onChange(e);
        }}
        dropdownToggleRef={props.userDropdownRef || null}
      >
        {props.user.options.map((el) => (
          <li value={el} key={`${el}-key_user`}>
            {el}
          </li>
        ))}
      </Select>
    </FormElement>
  ) : null;

  const lastActionFilter = props.lastAction ? (
    <FormElement
      className={styles['last-action-filter']}
      // labelText="TYPE"
      htmlFor="type"
    >
      <Select
        value={props.filters['last-action'] || 'Last Action'}
        isActive={props && props.filters && props.filters['last-action'] && props.filters['last-action'] !== 'All'}
        placeholder="Last Action"
        size="md"
        onClick={(e) => {
          props.lastAction.onChange(e);
        }}
        dropdownToggleRef={props.lastActionDropdownRef || null}
      >
        {props.lastAction.options.map((el) => (
          <li value={el} key={`${el}-key_lastaction`}>
            {el}
          </li>
        ))}
      </Select>
    </FormElement>
  ) : null;

  const affiliateStatusFilter = props.affiliateStatus ? (
    <FormElement
      className={styles['status-filter']}
      // labelText="AFFILIATE STATUS"
      htmlFor="affiliate"
    >
      <Select
        value={(props && props.filters && props.filters.affiliate_status) || 'Vendor status'}
        isActive={props && props.filters && props.filters.affiliate_status && props.filters.affiliate_status !== 'All'}
        className={styles.button}
        placeholder="Vendor status"
        size="md"
        dropdownToggleRef={props.affiliateStatusDropdownRef || null}
        onClick={(e) => {
          props.affiliateStatus.onChange(e);
        }}
      >
        {props.affiliateStatus.options.map((el) => (
          <li value={el} key={`${el}-key_status`}>
            {el}
          </li>
        ))}
      </Select>
    </FormElement>
  ) : null;

  const statusFilter = props.status ? (
    <FormElement
      className={styles['status-filter']}
      // labelText="STATUS"
      htmlFor="status"
    >
      <Select
        value={(props && props.filters && props.filters.status) || 'Status'}
        isActive={props && props.filters && props.filters.status && props.filters.status !== 'All'}
        className={styles.button}
        placeholder="Status"
        size="md"
        dropdownToggleRef={props.statusDropdownRef || null}
        onClick={(e) => {
          props.status.onChange(e);
        }}
      >
        {props.status.options.map((el) => (
          <li value={el} key={`${el}-key_status`}>
            {el}
          </li>
        ))}
      </Select>
    </FormElement>
  ) : null;

  const dateRangeFilter = props.dateRange ? (
    <React.Fragment>
      <FormElement
        // labelText="DATE RANGE"
        htmlFor="date-range1"
        // errorMessage={addRemediationPlanForm["date-range"]}
      >
        <DateInput
          size="md"
          value={props.filters && (props.filters.start || '')}
          isActive={props && props.filters && props.filters.start && props.filters.start !== ''}
          onChange={(e) => props.dateRange.onChangeStart(e.target.value)}
        />
      </FormElement>
      <FormElement
        // labelText="END DATE"
        htmlFor="date-range2"
        // errorMessage={addRemediationPlanForm["end-date"]}
      >
        <DateInput
          size="md"
          value={props.filters && (props.filters.end || '')}
          isActive={props && props.filters && props.filters.end && props.filters.end !== ''}
          onChange={(e) => props.dateRange.onChangeEnd(e.target.value)}
        />
      </FormElement>
    </React.Fragment>
  ) : null;

  return (
    <div className={`${stylePageHeaderActions} ${props.className || ''}`}>
      <div className={styles['page-header-left']}>
        {/* REQUIRED
         * - primary button - custom event handler
         * - choose action button - custom actions as [{name:action}...
         * - search - SearchInput
         *
         * OPTIONAL
         * - secondary button - custom event handler
         */}
        {props.primaryButtonText && (
          <Button className={styles.button} text={props.primaryButtonText} icon={props.primaryButtonIcon || 'fal fa-plus'} onClick={props.primaryButtonHandler} size="md" />
        )}
        {secondaryButton}
        {terianryButton}
        {fourthButton}
        {fifthButton}
        {props.chooseAction ? <ChooseAction actions={props.chooseAction} dropdownToggleRef={props.chooseActionDropdownRef || null} /> : null}

        {props.searchValue !== false && (
          <SearchInput
            onChange={props.searchHandler || null}
            value={props.searchValue || ''}
            size="md"
            className={`${styleSearch} ${props.classNameSearch ? props.classNameSearch : ''}`}
          />
        )}

        {props.terinaryButtonText && (
          <Button
            className={styles.button}
            text={props.terinaryButtonText}
            // icon="fal fa-plus"
            icon={props.terinaryButtonIcon || 'fal fa-plus'}
            onClick={props.terinaryButtonHandler}
            size="md"
          />
        )}
        {props.userSearch}
      </div>

      <div className={`${stylePageHeaderRight} ${props.classNameRight ? props.classNameRight : ''}`}>
        {!props.noFilter && (
          <div className={styles['page-header-filters']}>
            {/* REQUIRED
             * filter icon - icon
             * x icon - reset filters
             */}

            {/* OPTIONAL:
             * - site - multiple Select
             * - date-range - 2x DateInput
             * - status - Select
             * - type - multiple Select
             * - last action - Select
             * - expiration date range - 2x DateInput
             * - role - Select
             */}
            <i className={`far fa-filter ${styles['filter-icon']}`} />
            {roleFilter}
            {siteFilter}
            {coachFilter}
            {regFilter}
            {programFilter}
            {userFilter}
            {remFilter}
            {typeFilter}
            {lastActionFilter}
            {dateRangeFilter}
            {affiliateStatusFilter}
            {statusFilter}
            <i className={`${styles['reset-filters']} far fa-times`} onClick={props.resetFilters || null} />
          </div>
        )}
      </div>
    </div>
  );
};

export default PageHeaderActions;
