import PropTypes from 'prop-types';
import React from 'react';

class DataTableRow extends React.Component {
  renderRowCells() {
    const rowCells = this.props.colDefs.map((colDef, i) => {
      if (this.props.visibleColumnKeys && this.props.visibleColumnKeys.length && !this.props.visibleColumnKeys.includes(colDef.key)) {
        return null;
      }

      let cellClassName = `col col-${i} col-${colDef.key}`;
      if (colDef.className) {
        cellClassName += ` ${colDef.className}`;
      }

      return (
        <div className={cellClassName} key={i} onClick={this.props.onRowClick ? this.props.onRowClick.bind(this) : null}>
          {colDef.format
            ? colDef.hideActionControls
              ? null
              : colDef.format({
                  rowData: this.props.rowData,
                  customTableProps: this.props.customTableProps,
                })
            : this.props.rowData[colDef.key]}
        </div>
      );
    });

    return rowCells;
  }

  disablePointerEffects = this.props.customTableProps.disablePointerEffects ? 'no-pointer-effects' : '';

  render() {
    let rowClassName = `row table-data ${this.disablePointerEffects}`;

    if (this.props.index >= 0) {
      rowClassName += ' row-' + this.props.index;
    }

    if (this.props.className) {
      rowClassName += ` ${this.props.className}`;
    }

    if (this.props.onRowClick) {
      rowClassName += ` clickable-row`;
    }

    if (this.props.allowDisable && this.props.rowData.locked === 't') {
      rowClassName += ` disabled-row`;
    }

    return <div className={rowClassName}>{this.renderRowCells()}</div>;
  }

  static propTypes = {
    index: PropTypes.number,
    onRowClick: PropTypes.func,
    rowData: PropTypes.object.isRequired,
    colDefs: PropTypes.array.isRequired,
    allowDisable: PropTypes.bool,
    customTableProps: PropTypes.object,
    className: PropTypes.string,
    visibleColumnKeys: PropTypes.array,
  };
}

export default DataTableRow;
