import { call, put, takeLatest, delay } from 'redux-saga/effects';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { actionApiCall } from 'helpers/api/ApiCalls';
import * as sagaConstants from 'helpers/constants/SagasConstants';

function* loadUserCountersSaga() {
  try {
    const apiResponse = yield call(actionApiCall, {
      actionType: ActionTypeConstants.LOAD_USER_COUNTERS,
      data: {},
    });

    if (apiResponse && apiResponse.res.data.response === 200) {
      const { data } = apiResponse.res.data;

      yield put({
        type: ActionTypeConstants.LOAD_USER_COUNTERS_SUCCESS,
        payload: {
          counters: data,
          message: apiResponse?.res?.data?.message ?? 'Success loading user stats for tasks and documents', 
        },
      });
    } else {
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: apiResponse.res.data.message || `${sagaConstants.ISSUE_OCCURED} loading user stats for tasks and documents`,
          color: 'danger',
        },
      });
      yield delay(10_000);
      yield put({ type: ActionTypeConstants.HIDE_ALERT });
    }
  } catch {
    yield put({
      type: ActionTypeConstants.SHOW_ALERT,
      payload: {
        message: `${sagaConstants.SERVER_ERROR}`,
        color: 'danger',
      },
    });
    yield delay(10_000);
    yield put({ type: ActionTypeConstants.HIDE_ALERT });
  }
}

export default function* LoadUserCountersSaga() {
  yield takeLatest(ActionTypeConstants.LOAD_USER_COUNTERS, loadUserCountersSaga);
}
