import React, { RefObject } from 'react';
import moment from 'moment';
import ColVisibilitySelector from 'components/DataTable/ColVisibilitySelector';
import ItemActionsMenu from 'components/DataTable/ItemActionsMenu';
import Checkbox from 'components/Checkbox/Checkbox';
import { Button } from 'components/Button/Button';
import { getStatusButtonColor } from 'helpers/utils';
import { Remediation } from '../types/Remediation';

interface IRemediationTable {
  TABLE;
  COLUMN: Array<{
    label: string;
    key: string;
    sortable?: boolean;
    header?;
    className?: string;
    format: ({ rowData, customTableProps }: { rowData: Remediation; customTableProps }) => void;
    canBeHidden?: boolean;
  }>;
}

const tableDefinition: IRemediationTable = {
  TABLE: {
    className: 'data-table-remediation',
    initialSortKey: 'start_date',
    initialSortOrder: 'asc',
  },

  COLUMN: [
    {
      label: 'Row Selector',
      key: 'row-selector',
      header: ({ customTableProps }) => {
        const isSelectedAll = !!(
          customTableProps.selectedRows &&
          customTableProps.selectedRows.length > 0 &&
          customTableProps.rowsData &&
          customTableProps.rowsData.length > 0 &&
          customTableProps.selectedRows.length === customTableProps.rowsData.length
        );
        return <Checkbox checked={isSelectedAll} onChange={() => customTableProps.doSelectAll()} name="row-selector" />;
      },
      className: 'col-row-selector',
      format: ({ rowData, customTableProps }) => {
        const isSelected = !!(customTableProps.selectedRows && customTableProps.selectedRows.some((checkbox) => checkbox.id === rowData.id));
        return (
          <Checkbox
            disabled={rowData.deactivated === 't'}
            checked={rowData.deactivated === 't' ? false : isSelected}
            onChange={() => customTableProps.onRowSelect({ rowData })}
            name="row-selector"
          />
        );
      },
    },
    // {
    //     label: "Id",
    //     header: "ID",
    //     key: "id",
    //     sortable: true,
    //     className: "id",
    //     format: ({ rowData, customTableProps }) => {
    //         return <span>{rowData["id"]}</span>
    //     }
    // },
    {
      label: 'Title',
      header: 'TITLE',
      key: 'title',
      sortable: true,
      className: 'remediation_title',
      format: ({ rowData }) => <span>{rowData.title}</span>,
    },
    {
      label: 'Program',
      header: 'PROGRAM',
      key: 'regulatory_act',
      sortable: true,
      className: 'type',
      format: ({ rowData }) => <span>{rowData.regulatory_act}</span>,
    },
    {
      label: 'Start Date',
      header: 'START DATE',
      key: 'start_date',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => <span>{rowData.start_date ? moment(rowData.start_date).format('MM/DD/YYYY') : ''}</span>,
    },
    {
      label: 'End Date',
      header: 'END DATE',
      key: 'end_date',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => <span>{rowData.end_date ? moment(rowData.end_date).format('MM/DD/YYYY') : ''}</span>,
    },
    {
      label: 'Open Tasks',
      header: 'OPEN TASKS',
      key: 'open_tasks',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => <span>{rowData.open_tasks}</span>,
    },
    {
      label: 'Site',
      header: 'SITE',
      key: 'siteName',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => <span>{rowData.siteName || 'All Sites'}</span>,
    },
    {
      label: 'Status',
      header: 'STATUS',
      key: 'status',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData, customTableProps }) => {
        const { status } = rowData;
        const color = getStatusButtonColor(status, ['Complete'], ['Incomplete', 'Closed incomplete']);
        return (
          <span>
            <Button cursorDefault={customTableProps.cursorDefault} color={color} size="md" text={status} />
          </span>
        );
      },
    },
    {
      label: 'Actions',
      key: 'actions',
      format: ({ rowData, customTableProps }) => {
        const actionMenuDropDown = React.createRef() as RefObject<ItemActionsMenu>;
        return (
          <ItemActionsMenu
            ref={actionMenuDropDown}
            actions={(() => [
              customTableProps.notify && rowData.deactivated !== 't'
                ? {
                    label: 'Notify',
                    action: () => {
                      if (actionMenuDropDown && actionMenuDropDown.current) {
                        actionMenuDropDown.current.handleMenuClose();
                      }
                      customTableProps.notify(rowData);
                    },
                  }
                : false,
              customTableProps.complete && rowData.deactivated !== 't'
                ? {
                    label: 'Mark Complete',
                    action: () => {
                      if (actionMenuDropDown && actionMenuDropDown.current) {
                        actionMenuDropDown.current.handleMenuClose();
                      }
                      customTableProps.complete(rowData);
                    },
                  }
                : false,
              customTableProps.incomplete && rowData.deactivated !== 't'
                ? {
                    label: 'Mark Incomplete',
                    action: () => {
                      if (actionMenuDropDown && actionMenuDropDown.current) {
                        actionMenuDropDown.current.handleMenuClose();
                      }
                      customTableProps.incomplete(rowData);
                    },
                  }
                : false,
              customTableProps.delete && rowData.deactivated !== 't'
                ? {
                    label: 'Delete',
                    action: () => {
                      if (actionMenuDropDown && actionMenuDropDown.current) {
                        actionMenuDropDown.current.handleMenuClose();
                      }
                      customTableProps.delete(rowData);
                    },
                  }
                : false,
            ])()}
          />
        );
      },
      className: 'col-actions',
      header: ({ customTableProps }) => (
        <ColVisibilitySelector
          colDefs={customTableProps.colDefs}
          visibleColumnKeys={customTableProps.visibleColumnKeys}
          onChangeVisibility={(colKey) => customTableProps.onChangeVisibility(colKey)}
        />
      ),
    },
  ],
};

export default tableDefinition;
