import classNames from 'classnames';
import React from 'react';

import styles from './HR.module.scss';

interface IHR {
  className?: string;
}

const defaultProps = {
  className: undefined,
};

/**
 * @param {String} [className] - additonal className to add
 */
function HR({ className }: IHR) {
  const style = classNames(styles.hr, className);
  return <hr className={style} data-testid="hr" />;
}

HR.defaultProps = defaultProps;

export default HR;
