import React, { useEffect, useState } from 'react';
import { hideAlertAction } from 'store/actions/Actions';
import ModalAlerts from 'components/ModalAlerts/ModalAlerts';
import ButtonElement from 'components/Button/Button';
import { useAppDispatch, useAppSelector } from 'hooks';

import styles from './AlertMultiline.module.scss';

function MultiLineAlert() {
  const [showModal, setShowModal] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { title, type, data } = useAppSelector((state) => state.AlertReducer);

  const handleConfirm = (): void => {
    setShowModal(false);
    dispatch(hideAlertAction());
  };

  const modalButtons: React.ReactNode = <ButtonElement text="OK" color="primary" className={styles.create} onClick={handleConfirm} />;

  useEffect(() => {
    if (type === 'modal') setShowModal(true);
  }, [type, setShowModal]);

  return (
    showModal && (
      <ModalAlerts className={styles.modal} setShowModal={setShowModal} hideAlertAction={hideAlertAction}>
        <div data-testid="alert-multiline">
          <p className={styles['modal-heading']}>{title}</p>

          <div className={styles['modal-content']}>
            <p className={styles['error-message-main']}>
              <strong>{data && data.imported_rows}</strong>
            </p>
            {data &&
              data.errors.map((err) => (
                <div key={err.affected_row} className={styles['error-messages']}>
                  <p className={styles['error-title']}>
                    <strong>{err.affected_row}</strong>
                  </p>
                  <div>
                    {err.messages.map((msg) => (
                      <p key={msg} className={styles['error-message']}>
                        {msg}
                      </p>
                    ))}
                  </div>
                </div>
              ))}
          </div>

          <div className={styles['modal-footer']}>{modalButtons}</div>
        </div>
      </ModalAlerts>
    )
  );
}

export default MultiLineAlert;
