import React, { useEffect, useState } from 'react';
import moment from 'moment';
import FormElement from 'components/FormElement/FormElement';
import { Link } from 'react-router-dom';
import styles from './MFAConfirm.module.scss';

interface IMFAConfirm {
  setCodeStr?: React.Dispatch<React.SetStateAction<string>>;
  chosenMethodObj?: { id: string; type: string; value: string };
  codeError?: string;
  codeExpiresAt?: number;
  handleSendCode?: () => void;
  isSubmitClickable?: boolean;
  handleConfirmCode?: () => void;
}

const defaultProps = {
  setCodeStr: () => false,
  chosenMethodObj: { id: '1', type: 'Add Method', value: '*******123' },
  codeError: undefined,
  codeExpiresAt: undefined,
  handleSendCode: () => false,
  isSubmitClickable: false,
  handleConfirmCode: () => false,
};

const handleOnFocus = (e) => {
  e.target.type = 'text';
  e.target.setSelectionRange(0, 0);
  e.target.type = 'number';
  e.target.focus();
};

const changeFocus = (index) => {
  const element = document.getElementById(`input${index}`);
  element.focus();
};
function MFAConfirm({ setCodeStr, chosenMethodObj, codeError, handleSendCode, codeExpiresAt, isSubmitClickable, handleConfirmCode }: IMFAConfirm) {
  const [code, setCode] = useState({
    input1: '',
    input2: '',
    input3: '',
    input4: '',
    input5: '',
    input6: '',
  });

  const COUNTDOWN_TIME = 180;
  const [countdownValue, setCountdownValue] = useState(COUNTDOWN_TIME);

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowLeft') {
      const index = Number.parseInt(e.target.name.slice(-1), 10);
      if (index !== 1) changeFocus(index - 1);
    }
    if (e.key === 'ArrowRight') {
      const index = Number.parseInt(e.target.name.slice(-1), 10);
      if (index !== 6) changeFocus(index + 1);
    }
    if (['Backspace', 'Delete'].includes(e.key)) {
      // on delete
      setCode((oldCode) => ({
        ...oldCode,
        [e.target.name]: '',
      }));
      const index = Number.parseInt(e.target.name.slice(-1), 10);
      if (index !== 1) changeFocus(index - 1);
    }
    if (e.key === 'Enter' && isSubmitClickable) {
      handleConfirmCode();
    }
  };

  const handleChange = (e) => {
    if (!Number.isNaN(Number.parseInt(e.target.value, 10))) {
      const index = Number.parseInt(e.target.name.slice(-1), 10);
      if (index === 6) {
        window.setTimeout(() => {
          handleOnFocus(e);
        }, 1);
      }
      const trimmedValue = e.target.value.trim();
      const value = code[e.target.name] ? trimmedValue.slice(0, -1) || trimmedValue : trimmedValue;
      const lastIndex = Math.min(6, index + value.length - 1);
      const codeObject = {};

      for (let i = index; i <= lastIndex; i += 1) {
        const currentValue = [...value][i - index];
        const currentName = `${e.target.name.slice(0, -1)}${i}`;
        codeObject[currentName] = currentValue;
      }

      setCode((oldCode) => ({
        ...oldCode,
        ...codeObject,
      }));

      changeFocus(lastIndex === 6 ? lastIndex : lastIndex + 1);
    }
  };

  const resendCode = (e) => {
    e.preventDefault();
    setCode({
      input1: '',
      input2: '',
      input3: '',
      input4: '',
      input5: '',
      input6: '',
    });
    handleSendCode();
    setCountdownValue(COUNTDOWN_TIME);
  };

  useEffect(() => {
    if (code) setCodeStr(Object.values(code).join(''));
  }, [code]);

  useEffect(() => {
    const timer = window.setInterval(() => {
      if (countdownValue < 1) return;
      if (sessionStorage.getItem('mfa_code_expire')) {
        const current = moment().unix();
        const expired = Number(sessionStorage.getItem('mfa_code_expire'));
        setCountdownValue(expired - current);
      }
    }, 1000);
    return () => {
      window.clearInterval(timer);
    };
  }, [countdownValue]);

  useEffect(() => {
    if (codeExpiresAt) {
      setCountdownValue(180);
      sessionStorage.setItem('mfa_code_expire', moment().add(180, 'seconds').unix().toString());
    }
  }, [codeExpiresAt]);

  const isExpired = countdownValue < 1;

  return (
    <div className={styles.mfa}>
      <p className={styles['mfa-title']}>Enter your code</p>
      <div className={styles['mfa-sub-title']}>
        {!isExpired && (
          <>
            <span>{`Please enter 6-digit security code we sent you to your ${chosenMethodObj?.type?.toLowerCase()} ${chosenMethodObj.value}.`}</span>
            <div style={{ fontSize: 14, marginTop: 10 }}>
              <span>The code will expire in </span>
              <span className={styles.countdown}>{`${countdownValue} seconds`}</span>
            </div>
          </>
        )}
        {isExpired && (
          <span>
            <span>Your code has expired!</span>
            <div className={styles.countdown}>Please generate a new code</div>
            <Link to="/" className="nav-link" onClick={resendCode}>
              Resend
            </Link>
          </span>
        )}
      </div>

      <FormElement htmlFor="mfa-code" errorMessage={codeError}>
        <div className={styles['code-wrapper']}>
          <div className={styles['input-box']}>
            <input
              type="number"
              max="9"
              min="0"
              autoFocus
              autoComplete="none"
              value={code?.input1}
              id="input1"
              name="input1"
              onChange={handleChange}
              onFocus={handleOnFocus}
              onKeyDown={handleKeyDown}
            />
          </div>
          <div className={styles['input-box']}>
            <input
              type="number"
              max="9"
              min="0"
              autoComplete="none"
              value={code?.input2}
              id="input2"
              name="input2"
              onChange={handleChange}
              onFocus={handleOnFocus}
              onKeyDown={handleKeyDown}
            />
          </div>
          <div className={styles['input-box']}>
            <input
              type="number"
              max="9"
              min="0"
              autoComplete="none"
              value={code?.input3}
              id="input3"
              name="input3"
              onChange={handleChange}
              onFocus={handleOnFocus}
              onKeyDown={handleKeyDown}
            />
          </div>
          <div className={styles['input-box']}>
            <input
              type="number"
              max="9"
              min="0"
              autoComplete="none"
              value={code?.input4}
              id="input4"
              name="input4"
              onChange={handleChange}
              onFocus={handleOnFocus}
              onKeyDown={handleKeyDown}
            />
          </div>
          <div className={styles['input-box']}>
            <input
              type="number"
              max="9"
              min="0"
              autoComplete="none"
              value={code?.input5}
              id="input5"
              name="input5"
              onChange={handleChange}
              onFocus={handleOnFocus}
              onKeyDown={handleKeyDown}
            />
          </div>
          <div className={styles['input-box']}>
            <input
              type="number"
              max="9"
              min="0"
              autoComplete="none"
              value={code?.input6}
              id="input6"
              name="input6"
              onChange={handleChange}
              onFocus={handleOnFocus}
              onKeyDown={handleKeyDown}
            />
          </div>
        </div>
      </FormElement>
    </div>
  );
}

MFAConfirm.defaultProps = defaultProps;

export default MFAConfirm;
