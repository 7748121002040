import React from 'react';
// import ColVisibilitySelector from "components/DataTable/ColVisibilitySelector";
import ItemActionsMenu from 'components/DataTable/ItemActionsMenu';
// import Checkbox from "components/Checkbox/Checkbox";
import moment from 'moment';

export default {
  TABLE: {
    className: 'data-table-tasks',
    initialSortKey: 'created_at',
    initialSortOrder: 'desc',
  },

  COLUMN: [
    // {
    //     label: "Row Selector",
    //     key: "row-selector",
    //     header: ({ customTableProps }) => {
    //         const isSelectedAll =
    //             customTableProps.selectedRows &&
    //             customTableProps.selectedRows.length &&
    //             customTableProps.rowsData &&
    //             customTableProps.rowsData.length &&
    //             customTableProps.selectedRows.length ===
    //                 customTableProps.rowsData.length
    //                 ? true
    //                 : false;
    //         return (
    //             <Checkbox
    //                 checked={isSelectedAll}
    //                 onChange={() => customTableProps.doSelectAll()}
    //                 name="row-selector"
    //             />
    //         );
    //     },
    //     className: "col-row-selector",
    //     format: ({ rowData, customTableProps }) => {
    //         const isSelected =
    //             customTableProps.selectedRows &&
    //             customTableProps.selectedRows.length &&
    //             customTableProps.selectedRows.includes(rowData)
    //                 ? true
    //                 : false;
    //         return (
    //             <Checkbox
    //                 checked={isSelected}
    //                 onChange={() => customTableProps.onRowSelect({ rowData })}
    //                 name="row-selector"
    //             />
    //         );
    //     },
    //     canBeHidden: true
    // },
    {
      label: 'Note',
      header: 'NOTE',
      key: 'notes',
      sortable: true,
      className: 'note',
      format: ({ rowData }) => {
        return <span>{rowData['notes']}</span>;
      },
    },
    {
      label: 'Date',
      header: 'DATE',
      key: 'created_at',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{rowData['created_at'] ? moment(rowData['created_at']).format('MM/DD/YYYY') : ''}</span>;
      },
    },
    {
      label: 'Added by',
      header: 'ADDED BY',
      key: 'name',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{rowData['name']}</span>;
      },
    },
    // {
    //     label: "Completed",
    //     header: "COMPLETED",
    //     key: "completed_on",
    //     sortable: true,
    //     canBeHidden: true,
    //     format: ({ rowData }) => {
    //         return <span>{rowData['completed_on'] ? moment(rowData['completed_on']).format('MM/DD/YYYY') : ''}</span>;
    //     }
    // },
    // {
    //     label: "Assignee",
    //     header: "ASSIGNEE",
    //     key: "assignee_name",
    //     sortable: true,
    //     canBeHidden: true,
    //     format: ({ rowData }) => {
    //         return <span>{rowData["assignee_name"]}</span>;
    //     }
    // },
    // {
    //     label: "Site",
    //     header: "SITE",
    //     key: "site",
    //     sortable: true,
    //     canBeHidden: true,
    //     format: ({ rowData }) => {
    //         return <span>{rowData.siteName == null ? 'All Sites' : rowData.siteName}</span>;
    //     }
    // },
    // {
    //     label: "Status",
    //     header: "STATUS",
    //     key: "status",
    //     sortable: true,
    //     canBeHidden: true,
    //     format: ({ rowData }) => {
    //         return <span>{rowData.status}</span>;
    //     }
    // },
    {
      label: 'Actions',
      key: 'actions',
      format: ({ rowData, customTableProps }) => {
        const actionMenuDropDown = React.createRef();
        return (
          <ItemActionsMenu
            ref={actionMenuDropDown}
            actions={(() => {
              return [
                customTableProps.removeNote
                  ? {
                      label: 'Remove Note',
                      action: () => {
                        if (actionMenuDropDown && actionMenuDropDown.current) {
                          actionMenuDropDown.current.handleMenuClose();
                        }
                        customTableProps.removeNote(rowData);
                      },
                    }
                  : false,
              ];
            })()}
          />
        );
      },
    },
  ],
};
