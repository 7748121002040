import ActionTypeConstants from 'helpers/ActionTypeConstants';

/**
 * Get user from token and reinitialize auth.
 */
export const authGetUserAction = () => ({
  type: ActionTypeConstants.AUTH_GET_USER,
});

/**
 * Execute logout logic
 */
export const authLogoutAction = () => ({
  type: ActionTypeConstants.AUTH_LOGOUT,
});

/**
 * Submit user login form and try to login.
 */
export const submitUserLoginFormAction = (payload) => ({
  type: ActionTypeConstants.SUBMIT_USER_LOGIN_FORM,
  payload,
});

export const submitUserRegisterFormAction = (payload) => ({
  type: ActionTypeConstants.SUBMIT_USER_REGISTER_FORM,
  payload,
});

export const getUserByKeyAction = (payload) => ({
  type: ActionTypeConstants.GET_USER_BY_KEY,
  payload,
});

export const submitUserSettingsFormAction = (payload) => ({
  type: ActionTypeConstants.SUBMIT_USER_SETTINGS_FORM,
  payload,
});

export const loadUserSettingsFormAction = (payload?) => ({
  type: ActionTypeConstants.LOAD_USER_SETTINGS_FORM,
  payload,
});

export const submitUserForgotPasswordAction = (payload) => ({
  type: ActionTypeConstants.SUBMIT_USER_FORGOT_PASSWORD,
  payload,
});

export const submitUserResetPasswordAction = (payload) => ({
  type: ActionTypeConstants.SUBMIT_USER_RESET_PASSWORD,
  payload,
});

// export const submitUserChangePasswordAction = (payload) => ({
//   type: ActionTypeConstants.SUBMIT_USER_CHANGE_PASSWORD,
//   payload,
// });

export const activateUserAction = (payload) => ({
  type: ActionTypeConstants.ACTIVATE_USER,
  payload,
});

export const deactivateUserAction = (payload) => ({
  type: ActionTypeConstants.DEACTIVATE_USER,
  payload,
});

export const revokeUserAccessAction = (payload) => ({
  type: ActionTypeConstants.REVOKE_USER_ACCESS,
  payload,
});

export const resendRegistrationEmailAction = (payload) => ({
  type: ActionTypeConstants.RESEND_REGISTRATION_EMAIL_TO_USER,
  payload,
});

export const resendRegistrationEmailToAllAction = (payload) => ({
  type: ActionTypeConstants.RESEND_REGISTRATION_EMAIL_TO_USER_ALL,
  payload,
});

// export const deleteUserAction = payload => ({
//     type: ActionTypeConstants.DELETE_USER,
//     payload
// });

// /maintain/users/add
export const submitAddUserFormAction = (payload) => ({
  type: ActionTypeConstants.SUBMIT_ADD_USER_FORM,
  payload,
});

// /maintain/users/add
export const submitAddOfficerFormAction = (payload) => ({
  type: ActionTypeConstants.SUBMIT_ADD_OFFICER_FORM,
  payload,
});

// /maintain/users/add
export const createUsersBulkAction = (payload) => ({
  type: ActionTypeConstants.CREATE_USERS_BULK,
  payload,
});

// /maintain/users/edit
export const submitEditUserFormAction = (payload) => ({
  type: ActionTypeConstants.SUBMIT_EDIT_USER_FORM,
  payload,
});

export const loadAllUsersListAction = () => ({
  type: ActionTypeConstants.LOAD_ALL_USERS_LIST,
});

export const resetAllUsersListAction = () => ({
  type: ActionTypeConstants.RESET_ALL_USERS_LIST,
});

export const loadAllSitesListAction = () => ({
  type: ActionTypeConstants.LOAD_ALL_SITES_LIST,
});

// load acts /achieve/audits
export const loadActsAction = (payload) => ({
  type: ActionTypeConstants.LOAD_ACTS,
  payload,
});

// load acts /achieve/audits
export const loadActsAllAction = (payload?) => ({
  type: ActionTypeConstants.LOAD_ACTS_ALL,
  payload,
});

// load acts /achieve/audits
export const loadSitesAction = (payload?) => ({
  type: ActionTypeConstants.LOAD_SITES,
  payload,
});

// load audits /achieve/audits
export const loadAuditsAction = (payload) => ({
  type: ActionTypeConstants.LOAD_AUDITS,
  payload,
});

// create audit /achieve/audits
export const createAuditAction = (payload) => ({
  type: ActionTypeConstants.CREATE_AUDIT,
  payload,
});

// load audit /achieve/audit/audit
export const loadAuditAction = (payload) => ({
  type: ActionTypeConstants.LOAD_AUDIT,
  payload,
});

// delete audit /achieve/audits
export const deleteAuditAction = (payload) => ({
  type: ActionTypeConstants.DELETE_AUDIT,
  payload,
});

// update audit /achieve/audit
export const updateAuditAction = (payload) => ({
  type: ActionTypeConstants.UPDATE_AUDIT,
  payload,
});

// edit audit configuration
export const editAuditAction = (payload) => ({
  type: ActionTypeConstants.EDIT_AUDIT,
  payload,
});

// notify audit /achieve/audit
export const notifyAuditAction = (payload) => ({
  type: ActionTypeConstants.NOTIFY_AUDIT,
  payload,
});

// notify audit /achieve/audit
export const completeAuditAction = (payload) => ({
  type: ActionTypeConstants.COMPLETE_AUDIT,
  payload,
});

// notify audit /achieve/audit
export const reopenAuditAction = (payload) => ({
  type: ActionTypeConstants.REOPEN_AUDIT,
  payload,
});

export const downloadAuditAction = (payload) => ({
  type: ActionTypeConstants.DOWNLOAD_AUDIT,
  payload,
});

export const uploadAuditAction = (payload) => ({
  type: ActionTypeConstants.UPLOAD_AUDIT,
  payload,
});

// load remediation plans /achieve/remediation
export const loadRemediationPlansAction = (payload) => ({
  type: ActionTypeConstants.LOAD_REMEDIATION_PLANS,
  payload,
});

// /achieve/remediation/add
export const submitAddRemediationForm = (payload) => ({
  type: ActionTypeConstants.SUBMIT_ADD_REMEDIATION_PLAN_FORM,
  payload,
});

// load edit remediation plan /achieve/remediation/edit
export const loadEditRemediationPlanFormAction = (payload) => ({
  type: ActionTypeConstants.LOAD_EDIT_REMEDIATION_PLAN_FORM,
  payload,
});

// edit remediation plan /achieve/remediation/edit
export const submitEditRemediationPlanFormAction = (payload) => ({
  type: ActionTypeConstants.SUBMIT_EDIT_REMEDIATION_PLAN_FORM,
  payload,
});

export const completeRemediationPlanAction = (payload) => ({
  type: ActionTypeConstants.COMPLETE_REMEDIATION_PLAN,
  payload,
});

export const incompleteRemediationPlanAction = (payload) => ({
  type: ActionTypeConstants.INCOMPLETE_REMEDIATION_PLAN,
  payload,
});

export const notifyRemediationPlanAction = (payload) => ({
  type: ActionTypeConstants.NOTIFY_REMEDIATION_PLAN,
  payload,
});

export const deleteRemediationPlanAction = (payload) => ({
  type: ActionTypeConstants.DELETE_REMEDIATION_PLAN,
  payload,
});

export const loadGapsAction = (payload) => ({
  type: ActionTypeConstants.LOAD_GAPS,
  payload,
});

// load affiliates /achieve/affiliates
export const loadAffiliatesAction = () => ({
  type: ActionTypeConstants.LOAD_AFFILIATES,
});

// load affiliate /achieve/affiliates/edit
export const loadEditAffiliateFormAction = (id) => ({
  type: ActionTypeConstants.LOAD_EDIT_AFFILIATE_FORM,
  id,
});

// delete affiliate /achieve/affiliates
export const deleteAffiliateAction = (payload) => ({
  type: ActionTypeConstants.DELETE_AFFILIATE,
  payload,
});

// edit affiliate /achieve/affiliates/edit
export const submitEditAffiliateFormAction = (payload) => ({
  type: ActionTypeConstants.SUBMIT_EDIT_AFFILIATE_FORM,
  payload,
});

// add affiliate /achieve/affiliates/add
export const submitAddAffiliateFormAction = (payload) => ({
  type: ActionTypeConstants.SUBMIT_ADD_AFFILIATE_FORM,
  payload,
});

// notify affiliate - achieve/affiliates
export const notifyAffiliateAction = (payload) => ({
  type: ActionTypeConstants.NOTIFY_AFFILIATE,
  payload,
});

export const sendVendorAuditAffiliateAction = (payload) => ({
  type: ActionTypeConstants.SEND_VENDOR_AUDIT_AFFILIATE,
  payload,
});

export const saveVendorAuditAffiliateAction = (payload) => ({
  type: ActionTypeConstants.SAVE_VENDOR_AUDIT_AFFILIATE,
  payload,
});

export const readVendorAuditAffiliateAction = (payload) => ({
  type: ActionTypeConstants.READ_VENDOR_AUDIT_AFFILIATE,
  payload,
});

export const readVendorAuditTokenAffiliateAction = (payload) => ({
  type: ActionTypeConstants.READ_VENDOR_AUDIT_TOKEN_AFFILIATE,
  payload,
});

export const approveAffiliateAction = (payload) => ({
  type: ActionTypeConstants.APPROVE_AFFILIATE,
  payload,
});

export const declineAffiliateAction = (payload) => ({
  type: ActionTypeConstants.DECLINE_AFFILIATE,
  payload,
});

export const sendBaAgreementAffiliateAction = (payload) => ({
  type: ActionTypeConstants.SEND_BA_AGREEMENT_AFFILIATE,
  payload,
});

export const sendConfAgreementAffiliateAction = (payload) => ({
  type: ActionTypeConstants.SEND_CONF_AGREEMENT_AFFILIATE,
  payload,
});

export const bulkUploadAffiliateAction = (payload) => ({
  type: ActionTypeConstants.BULK_UPLOAD_AFFILIATE,
  payload,
});

export const bulkUploadSiteAction = (payload) => ({
  type: ActionTypeConstants.BULK_UPLOAD_SITE,
  payload,
});

export const bulkUploadDeviceAction = (payload) => ({
  type: ActionTypeConstants.BULK_UPLOAD_DEVICE,
  payload,
});

export const downloadDevicesAction = (payload) => ({
  type: ActionTypeConstants.DOWNLOAD_DEVICES,
  payload,
});

export const uploadDocumentsAction = (payload) => ({
  type: ActionTypeConstants.UPLOAD_DOCUMENTS,
  payload,
});

export const deleteAffiliateDocumentAction = (payload) => ({
  type: ActionTypeConstants.DELETE_AFFILIATE_DOCUMENTS,
  payload,
});

export const approveAffiliateDocumentAction = (payload) => ({
  type: ActionTypeConstants.APPROVE_AFFILIATE_DOCUMENTS,
  payload,
});

export const unapproveAffiliateDocumentAction = (payload) => ({
  type: ActionTypeConstants.UNAPPROVE_AFFILIATE_DOCUMENTS,
  payload,
});

export const readAffiliateAgreementsAction = (payload) => ({
  type: ActionTypeConstants.READ_AFFILIATE_AGREEMENTS,
  payload,
});

// load tasks /achieve/tasks
export const loadTasksAction = (payload) => ({
  type: ActionTypeConstants.LOAD_TASKS,
  payload,
});

export const loadTasksFilteredAction = (payload) => ({
  type: ActionTypeConstants.LOAD_TASKS_FILTERED,
  payload,
});

export const loadTaskAction = (payload) => ({
  type: ActionTypeConstants.LOAD_ONE_TASK,
  payload,
});

// load tasks /achieve/tasks
export const loadTasksForUserAction = (payload) => ({
  type: ActionTypeConstants.GET_TASKS_FOR_USER,
  payload,
});

// create audit /achieve/audits
export const createTaskAction = (payload) => ({
  type: ActionTypeConstants.CREATE_TASK,
  payload,
});

export const updateTaskAction = (payload) => ({
  type: ActionTypeConstants.UPDATE_TASK,
  payload,
});

export const completeTaskAction = (payload) => ({
  type: ActionTypeConstants.COMPLETE_TASK,
  payload,
});

// delete task /achieve/tasks
export const deleteTaskAction = (payload) => ({
  type: ActionTypeConstants.DELETE_TASK,
  payload,
});

// delete task /achieve/tasks
export const notifyTaskAction = (payload) => ({
  type: ActionTypeConstants.NOTIFY_TASK,
  payload,
});

export const answerQuestionTaskAction = (payload) => ({
  type: ActionTypeConstants.UPDATE_TASK,
  payload,
});

// load incidents /maintain/incidents
export const loadIncidentsAction = (id) => ({
  type: ActionTypeConstants.LOAD_INCIDENTS,
  payload: id,
});

// delete incident /maintain/incidents
export const deleteIncidentAction = (payload) => ({
  type: ActionTypeConstants.DELETE_INCIDENT,
  payload,
});

export const approveIncidentAction = (payload) => ({
  type: ActionTypeConstants.APPROVE_INCIDENT,
  payload,
});

export const completeIncidentAction = (payload) => ({
  type: ActionTypeConstants.COMPLETE_INCIDENT,
  payload,
});

// marks incident as under invetigation
export const investigationIncidentAction = (payload) => ({
  type: ActionTypeConstants.INVESTIGATE_INCIDENT,
  payload,
});

export const notifyIncidentAction = (payload) => ({
  type: ActionTypeConstants.NOTIFY_INCIDENT,
  payload,
});

// load incident manager /maintain/incidents/edit
export const loadIncidentManagerAction = (id) => ({
  type: ActionTypeConstants.LOAD_INCIDENT,
  id,
});

// load incident manager /maintain/incidents/edit
export const loadIncidentTypesAction = (payload) => ({
  type: ActionTypeConstants.LOAD_INCIDENT_TYPES,
  payload,
});

// update incident manager /maintain/incidents/edit
export const updateIncidentManagerAction = (payload) => ({
  type: ActionTypeConstants.UPDATE_INCIDENT,
  payload,
});

// add new incident /maintain/incidents/edit
export const addNewIncidentAction = (payload) => ({
  type: ActionTypeConstants.ADD_NEW_INCIDENT,
  payload,
});

// add new incident /maintain/incidents/edit
export const addIncidentNoteAction = (payload) => ({
  type: ActionTypeConstants.ADD_INCIDENT_NOTE,
  payload,
});

export const deleteIncidentNoteAction = (payload) => ({
  type: ActionTypeConstants.DELETE_INCIDENT_NOTE,
  payload,
});

// add new incident /maintain/incidents/edit
export const loadIncidentNotesAction = (payload) => ({
  type: ActionTypeConstants.LOAD_INCIDENT_NOTES,
  payload,
});

// load org,site,dept /maintain/organization
export const loadOrgSiteDeptAction = (payload?) => ({
  type: ActionTypeConstants.LOAD_ORG_SITE_DEPT,
  payload,
});

export const loadOrganizationsAction = (payload) => ({
  type: ActionTypeConstants.LOAD_ORGANIZATIONS,
  payload,
});

export const loadOrganizationDetailsAction = (payload) => ({
  type: ActionTypeConstants.LOAD_ORGANIZATION_DETAILS,
  payload,
});

// update org /maintain/organization
export const updateOrgAction = (payload) => ({
  type: ActionTypeConstants.UPDATE_ORG,
  payload,
});

// update org /maintain/organization
export const updateOrgActsAction = (payload) => ({
  type: ActionTypeConstants.UPDATE_ORG_ACTS,
  payload,
});

// update site /maintain/organization
export const updateSiteAction = (payload) => ({
  type: ActionTypeConstants.UPDATE_SITE,
  payload,
});

// activate site /maintain/organization
export const activateSiteAction = (payload) => ({
  type: ActionTypeConstants.ACTIVATE_SITE,
  payload,
});

// deactivate site /maintain/organization
export const deactivateSiteAction = (payload) => ({
  type: ActionTypeConstants.DEACTIVATE_SITE,
  payload,
});

// create site /maintain/organization
export const createSiteAction = (payload) => ({
  type: ActionTypeConstants.CREATE_SITE,
  payload,
});

// create site /maintain/organization
export const createOrgAction = (payload) => ({
  type: ActionTypeConstants.CREATE_ORG,
  payload,
});

// update department /maintain/organization
export const updateDeptAction = (payload) => ({
  type: ActionTypeConstants.UPDATE_DEPT,
  payload,
});

// load departments for site id
export const loadDeptAction = (payload) => ({
  type: ActionTypeConstants.LOAD_DEPT,
  payload,
});

// add department /maintain/organization
export const addNewDeptAction = (payload) => ({
  type: ActionTypeConstants.ADD_NEW_DEPT,
  payload,
});

// delete department /maintain/organization
export const deleteDeptAction = (payload) => ({
  type: ActionTypeConstants.DELETE_DEPT,
  payload,
});

// DASHBOARD - load important documents tab /dashboard
export const loadImportantDocumentsAction = (payload) => ({
  type: ActionTypeConstants.LOAD_IMPORTANT_DOCUMENTS,
  payload,
});

// DASHBOARD - report an incident tab /dashboard
export const reportIncidentAction = (payload) => ({
  type: ActionTypeConstants.REPORT_INCIDENT,
  payload,
});

// DASHBOARD - load training documents tab /dashboard
export const loadTrainingDocumentsAction = (payload) => ({
  type: ActionTypeConstants.LOAD_TRAINING_DOCUMENTS,
  payload,
});

// DASHBOARD - load training media tab /dashboard
export const loadTrainingMediaAction = (payload) => ({
  type: ActionTypeConstants.LOAD_TRAINING_MEDIA,
  payload,
});

// ACHIVE/TRAINING - load training courses
export const loadTrainingCoursesAction = (payload?) => ({
  type: ActionTypeConstants.LOAD_TRAINING_COURSES,
  payload,
});

// ACHIVE/TRAINING - load training
export const loadTrainingAction = (payload?) => ({
  type: ActionTypeConstants.LOAD_TRAININGS,
  payload,
});

// ACHIVE/TRAINING - create training
export const createTrainingAction = (payload) => ({
  type: ActionTypeConstants.CREATE_TRAINING,
  payload,
});

// ACHIVE/TRAINING - delete training
export const deleteTrainingAction = (payload) => ({
  type: ActionTypeConstants.DELETE_TRAINING,
  payload,
});

// ACHIVE/TRAINING - delete training
export const updateTrainingAction = (payload) => ({
  type: ActionTypeConstants.UPDATE_TRAINING,
  payload,
});

export const notifyTrainingAction = (payload) => ({
  type: ActionTypeConstants.NOTIFY_TRAINING,
  payload,
});

// ACHIVE/TRAINING - delete training
export const completeTrainingAction = (payload) => ({
  type: ActionTypeConstants.COMPLETE_TRAINING,
  payload,
});

export const incompleteTrainingAction = (payload) => ({
  type: ActionTypeConstants.INCOMPLETE_TRAINING,
  payload,
});

export const suspendTrainingAction = (payload) => ({
  type: ActionTypeConstants.SUSPEND_TRAINING,
  payload,
});

export const checkQuizAnswersAction = (payload) => ({
  type: ActionTypeConstants.CHECK_QUIZ_ANSWERS,
  payload,
});

export const resetQuizAnswersAction = (payload) => ({
  type: ActionTypeConstants.RESET_QUIZ_ANSWERS,
  payload,
});

export const loadQuizQuestionsAction = (payload) => ({
  type: ActionTypeConstants.LOAD_QUIZ_QUESTIONS,
  payload,
});

export const unsuspendTrainingAction = (payload) => ({
  type: ActionTypeConstants.UNSUSPEND_TRAINING,
  payload,
});

export const bulkUploadTrainingAction = (payload) => ({
  type: ActionTypeConstants.BULK_UPLOAD_TRAINING,
  payload,
});

// load incidents /maintain/incidents
export const loadAlertsAction = (payload) => ({
  type: ActionTypeConstants.LOAD_ALERTS,
  payload,
});

// load incidents /maintain/incidents
export const updateAlertsAction = (payload) => ({
  type: ActionTypeConstants.UPDATE_ALERTS,
  payload,
});

// ALERT COMPONENT
export const hideAlertAction = (payload?) => ({
  type: ActionTypeConstants.HIDE_ALERT,
  payload,
});

export const showAlertAction = (payload) => ({
  type: ActionTypeConstants.SHOW_ALERT,
  payload,
});

// SESSION TIMEOUT COMPONENT
export const hideSessionModalAction = (payload?) => ({
  type: ActionTypeConstants.HIDE_SESSION_MODAL,
  payload,
});

// load incidents /maintain/incidents
export const loadUsersAction = (payload) => ({
  type: ActionTypeConstants.LOAD_USERS,
  payload,
});

export const loadUsersUnassignedAction = (payload) => ({
  type: ActionTypeConstants.LOAD_USERS_UNASSIGNED,
  payload,
});

// load incidents /maintain/incidents
export const loadUsersByOrgAction = (payload) => ({
  type: ActionTypeConstants.LOAD_USERS_BY_ORG,
  payload,
});

export const loadReportsAction = (payload) => ({
  type: ActionTypeConstants.LOAD_REPORTS,
  payload,
});

// create audit /achieve/audits
export const createReportAction = (payload) => ({
  type: ActionTypeConstants.CREATE_REPORT,
  payload,
});

export const deleteReportAction = (payload) => ({
  type: ActionTypeConstants.DELETE_REPORT,
  payload,
});

// load folders /achieve/documents
export const loadFoldersAction = (payload) => ({
  type: ActionTypeConstants.LOAD_FOLDERS,
  payload,
});

export const loadFolderAction = (payload) => ({
  type: ActionTypeConstants.LOAD_FOLDER,
  payload,
});

// load files /achieve/documents
export const loadFilesAction = (payload?) => ({
  type: ActionTypeConstants.LOAD_FILES,
  payload,
});

// load folders /achieve/documents
export const loadFolderContentsAction = (payload) => ({
  type: ActionTypeConstants.LOAD_FOLDER_CONTENTS,
  payload,
});

// load folders /achieve/documents
export const loadUnapprovedFilesAction = (payload) => ({
  type: ActionTypeConstants.LOAD_UNAPPROVED_FILES,
  payload,
});

// create folder /achieve/documents
export const createFolderAction = (payload) => ({
  type: ActionTypeConstants.CREATE_FOLDER,
  payload,
});

// create folder /achieve/documents
export const updateFolderAction = (payload) => ({
  type: ActionTypeConstants.UPDATE_FOLDER,
  payload,
});

// delete folder /achieve/documents
export const deleteFolderAction = (payload) => ({
  type: ActionTypeConstants.DELETE_FOLDER,
  payload,
});

// load folders /achieve/documents
export const loadDocumentAction = (payload) => ({
  type: ActionTypeConstants.LOAD_DOCUMENT,
  payload,
});

// edit document /achieve/documents
export const editDocumentAction = (payload) => ({
  type: ActionTypeConstants.EDIT_DOCUMENT,
  payload,
});

// restore document version from history /achieve/documents
export const restoreDocumentAction = (payload) => ({
  type: ActionTypeConstants.RESTORE_DOCUMENT,
  payload,
});

export const saveAsDocumentAction = (payload) => ({
  type: ActionTypeConstants.SAVE_AS_DOCUMENT,
  payload,
});

// load all roles
export const loadRolesAction = (payload?) => ({
  type: ActionTypeConstants.LOAD_ROLES,
  payload,
});

// load roles by user id
export const loadRolesUserAction = (payload) => ({
  type: ActionTypeConstants.LOAD_ROLES_USER,
  payload,
});

// load stats by org id
export const loadStatsAction = (payload?) => ({
  type: ActionTypeConstants.LOAD_STATS,
  payload,
});

// load stats by org id
export const loadUserStatsAction = (payload) => ({
  type: ActionTypeConstants.LOAD_USER_STATS,
  payload,
});

// load steps by site id
export const loadStepsAction = (payload) => ({
  type: ActionTypeConstants.LOAD_STEPS,
  payload,
});

// load steps by site id
export const loadActiveStepAction = (payload) => ({
  type: ActionTypeConstants.LOAD_ACTIVE_STEP,
  payload,
});

export const loadUserOrgSiteAction = (payload?) => ({
  type: ActionTypeConstants.LOAD_USER_ORG_SITE,
  payload,
});

// export const setUserOrgSiteAction = payload => ({
//     type: ActionTypeConstants.SET_USER_ORG_SITE,
//     payload
// });

// create audit /achieve/audits/devices
export const createDeviceAction = (payload) => ({
  type: ActionTypeConstants.CREATE_DEVICE,
  payload,
});

// create audit /achieve/audits/devices
export const updateDeviceAction = (payload) => ({
  type: ActionTypeConstants.UPDATE_DEVICE,
  payload,
});

export const loadDeviceAction = (payload) => ({
  type: ActionTypeConstants.LOAD_DEVICES,
  payload,
});

export const loadCloudAssetsAction = (payload) => ({
  type: ActionTypeConstants.LOAD_CLOUD_ASSETS,
  payload,
});

export const loadCloudAssetsV2Action = (payload) => ({
  type: ActionTypeConstants.LOAD_CLOUD_ASSETS_V2,
  payload,
});

export const createCloudAssetsAction = (payload) => ({
  type: ActionTypeConstants.CREATE_CLOUD_ASSETS,
  payload,
});

export const createCloudAssetsV2Action = (payload) => ({
  type: ActionTypeConstants.CREATE_CLOUD_ASSETS_V2,
  payload,
});

export const updateCloudAssetsAction = (payload) => ({
  type: ActionTypeConstants.UPDATE_CLOUD_ASSETS,
  payload,
});

export const updateCloudAssetsV2Action = (payload) => ({
  type: ActionTypeConstants.UPDATE_CLOUD_ASSETS_V2,
  payload,
});

export const activateCloudAppAction = (payload) => ({
  type: ActionTypeConstants.ACTIVATE_CLOUD_ASSETS,
  payload,
});

export const deactivateCloudAppAction = (payload) => ({
  type: ActionTypeConstants.DEACTIVATE_CLOUD_ASSETS,
  payload,
});

export const downloadTemplateCloudAppAction = (payload) => ({
  type: ActionTypeConstants.DOWNLOAD_TEMPLATE_CLOUD_ASSETS,
  payload,
});

export const downloadTemplateCloudAppV2Action = (payload) => ({
  type: ActionTypeConstants.DOWNLOAD_TEMPLATE_CLOUD_ASSETS_V2,
  payload,
});

export const bulkUploadCloudAppAction = (payload) => ({
  type: ActionTypeConstants.BULK_UPLOAD_CLOUD_ASSETS,
  payload,
});
export const bulkUploadCloudAppV2Action = (payload) => ({
  type: ActionTypeConstants.BULK_UPLOAD_CLOUD_ASSETS_V2,
  payload,
});

export const downloadUpdateTemplateCloudAppAction = (payload) => ({
  type: ActionTypeConstants.DOWNLOAD_UPDATE_TEMPLATE_CLOUD_ASSETS,
  payload,
});

export const bulkUpdateCloudAppAction = (payload) => ({
  type: ActionTypeConstants.BULK_UPDATE_CLOUD_ASSETS,
  payload,
});

export const decommissionDeviceAction = (payload) => ({
  type: ActionTypeConstants.DECOMMISSION_DEVICE,
  payload,
});

// duplicate audit /achieve/audits/devices
export const duplicateDeviceAction = (payload) => ({
  type: ActionTypeConstants.DUPLICATE_DEVICE,
  payload,
});

// delete device /achieve/audits/devices
export const deleteDeviceAction = (payload) => ({
  type: ActionTypeConstants.DELETE_DEVICE,
  payload,
});

// load files /dashboard/important documents (attestations)
export const loadAtteestationsAction = (payload) => ({
  type: ActionTypeConstants.LOAD_ATTESTATIONS,
  payload,
});

// load files /dashboard/important documents (attestations)
export const attestDocumentAction = (payload) => ({
  type: ActionTypeConstants.ATTEST_DOCUMENT,
  payload,
});

export const attestDocumentDashboardAction = (payload) => ({
  type: ActionTypeConstants.ATTEST_DOCUMENT_DASHBOARD,
  payload,
});

// check if audit is finalazable
export const verifyAuditAction = (payload) => ({
  type: ActionTypeConstants.VERIFY_AUDIT,
  payload,
});

export const approveDocumentAction = (payload) => ({
  type: ActionTypeConstants.APPROVE_DOCUMENT,
  payload,
});

export const initializeDocumentAction = (payload) => ({
  type: ActionTypeConstants.INITIALIZE_DOCUMENT,
  payload,
});

export const cleanupDocumentAction = (payload) => ({
  type: ActionTypeConstants.CLEANUP_DOCUMENT,
  payload,
});

export const loadEvidenceByIncidentAction = (payload) => ({
  type: ActionTypeConstants.LOAD_EVIDENCE_BY_INCIDENT,
  payload,
});

export const loadEvidenceByTaskAction = (payload) => ({
  type: ActionTypeConstants.LOAD_EVIDENCE_BY_TASK,
  payload,
});

export const loadEvidenceByCloudAssetAction = (payload) => ({
  type: ActionTypeConstants.LOAD_EVIDENCE_BY_CLOUD_ASSET,
  payload,
});

export const loadEvidenceByInventoryAction = (payload) => ({
  type: ActionTypeConstants.LOAD_EVIDENCE_BY_INVENTORY,
  payload,
});

export const loadEvidenceByAuditAction = (payload) => ({
  type: ActionTypeConstants.LOAD_EVIDENCE_BY_AUDIT,
  payload,
});

export const createEvidenceAction = (payload) => ({
  type: ActionTypeConstants.CREATE_EVIDENCE,
  payload,
});

export const deleteEvidenceAction = (payload) => ({
  type: ActionTypeConstants.DELETE_EVIDENCE,
  payload,
});

export const createTasknoteAction = (payload) => ({
  type: ActionTypeConstants.CREATE_TASKNOTE,
  payload,
});

export const deleteTasknoteAction = (payload) => ({
  type: ActionTypeConstants.DELETE_TASKNOTE,
  payload,
});

export const downloadEvidenceAction = (payload) => ({
  type: ActionTypeConstants.DOWNLOAD_EVIDENCE,
  payload,
});

export const downloadReportAction = (payload) => ({
  type: ActionTypeConstants.DOWNLOAD_REPORT,
  payload,
});

export const downloadUsersAction = (payload) => ({
  type: ActionTypeConstants.DOWNLOAD_USERS_CSV,
  payload,
});

export const downloadAffiliateFileAction = (payload) => ({
  type: ActionTypeConstants.DOWNLOAD_AFFILIATE_FILE,
  payload,
});

export const loadDashboardTasksAction = (payload?) => ({
  type: ActionTypeConstants.LOAD_DASHBOARD_TASKS,
  payload,
});

export const isFileUploadingAction = (payload) => ({
  type: ActionTypeConstants.UPLOAD_FILE,
  payload,
});

export const setRowsPerPageAction = (payload) => ({
  type: ActionTypeConstants.SET_ROWS_PER_PAGE,
  payload,
});

export const setPaginationAction = (payload) => ({
  type: ActionTypeConstants.SET_PAGINATION,
  payload,
});

export const setOrgWizardAction = (payload) => ({
  type: ActionTypeConstants.SET_ORG_WIZARD,
  payload,
});

export const submitNewOrgWizardAction = (payload) => ({
  type: ActionTypeConstants.SUBMIT_NEW_ORG_SAGA,
  payload,
});

export const emailAffiliateFilesAction = (payload) => ({
  type: ActionTypeConstants.EMAIL_AFFILIATE_FILES,
  payload,
});

export const loadSuperadminsAction = (payload) => ({
  type: ActionTypeConstants.LOAD_SUPERADMINS,
  payload,
});

export const setGrantSealAction = (payload) => ({
  type: ActionTypeConstants.SET_GRANT_SEAL,
  payload,
});

export const loadCertificateAction = (payload) => ({
  type: ActionTypeConstants.LOAD_CERTIFICATE,
  payload,
});

export const loadUserCertificateAction = (payload) => ({
  type: ActionTypeConstants.LOAD_USER_CERTIFICATE,
  payload,
});

export const loadCertificatesAction = (payload) => ({
  type: ActionTypeConstants.LOAD_CERTIFICATES,
  payload,
});

export const loadUserCertificatesAction = (payload?) => ({
  type: ActionTypeConstants.LOAD_USER_CERTIFICATES,
  payload,
});

export const searchUsersAction = (payload) => ({
  type: ActionTypeConstants.SEARCH_USERS,
  payload,
});

export const loadReasignableUsersAction = (payload) => ({
  type: ActionTypeConstants.LOAD_REASIGNABLE_USERS,
  payload,
});

export const reassignAllTasksAction = (payload) => ({
  type: ActionTypeConstants.REASSIGN_TASKS,
  payload,
});

export const createDeviceInventoryAction = (payload) => ({
  type: ActionTypeConstants.CREATE_DEVICE_INVENTORY,
  payload,
});

export const updateDeviceInventoryAction = (payload) => ({
  type: ActionTypeConstants.UPDATE_DEVICE_INVENTORY,
  payload,
});

export const loadDeviceInventoryAction = (payload) => ({
  type: ActionTypeConstants.LOAD_DEVICE_INVENTORY,
  payload,
});

export const createDeviceInventoryV2Action = (payload) => ({
  type: ActionTypeConstants.CREATE_DEVICE_INVENTORY_V2,
  payload,
});

export const updateDeviceInventoryV2Action = (payload) => ({
  type: ActionTypeConstants.UPDATE_DEVICE_INVENTORY_V2,
  payload,
});

export const loadDeviceInventoryV2Action = (payload) => ({
  type: ActionTypeConstants.LOAD_DEVICE_INVENTORY_V2,
  payload,
});

export const downloadTemplateDeviceInventoryAction = (payload) => ({
  type: ActionTypeConstants.DOWNLOAD_TEMPLATE_DEVICE_INVENTORY,
  payload,
});

export const downloadTemplateDeviceInventoryV2Action = (payload) => ({
  type: ActionTypeConstants.DOWNLOAD_TEMPLATE_DEVICE_INVENTORY_V2,
  payload,
});

export const bulkUploadDeviceInventoryAction = (payload) => ({
  type: ActionTypeConstants.BULK_UPLOAD_DEVICE_INVENTORY,
  payload,
});

export const bulkUploadDeviceInventoryV2Action = (payload) => ({
  type: ActionTypeConstants.BULK_UPLOAD_DEVICE_INVENTORY_V2,
  payload,
});

export const activateDeviceInventoryAction = (payload) => ({
  type: ActionTypeConstants.ACTIVATE_DEVICE_INVENTORY,
  payload,
});

export const deactivateDeviceInventoryAction = (payload) => ({
  type: ActionTypeConstants.DEACTIVATE_DEVICE_INVENTORY,
  payload,
});

export const destroyDeviceInventoryAction = (payload) => ({
  type: ActionTypeConstants.DESTROY_DEVICE_INVENTORY,
  payload,
});

export const bulkUpdateDeviceInventoryAction = (payload) => ({
  type: ActionTypeConstants.BULK_UPDATE_DEVICE_INVENTORY,
  payload,
});

export const downloadUpdateTemplateDeviceInventoryAction = (payload) => ({
  type: ActionTypeConstants.DOWNLOAD_UPDATE_TEMPLATE_DEVICE_INVENTORY,
  payload,
});

export const loadModulesAction = (payload?) => ({
  type: ActionTypeConstants.LOAD_MODULES,
  payload,
});

export const loadOrgModulesAction = () => ({
  type: ActionTypeConstants.LOAD_ORG_MODULES,
});

export const loadTopLevelFoldersAction = (payload?) => ({
  type: ActionTypeConstants.LOAD_TOP_LEVEL_FOLDERS,
  payload,
});

export const updateOrgModulesAction = (payload) => ({
  type: ActionTypeConstants.UPDATE_ORG_MODULES,
  payload,
});

export const loadOrgEntitiesAction = (payload?) => ({
  type: ActionTypeConstants.LOAD_ORG_ENTITIES,
  payload,
});

export const activateMFAAction = (payload?) => ({
  type: ActionTypeConstants.MFA_ACTIVATE,
  payload,
});

export const loadSsoOrganizationsAction = (payload) => ({
  type: ActionTypeConstants.LOAD_SSO_ORGANIZATIONS,
  payload,
});

export const connectSsoOrganizationAction = (payload) => ({
  type: ActionTypeConstants.CONNECT_SSO_ORGANIZATION,
  payload,
});

export const getSsoUsersAction = (payload) => ({
  type: ActionTypeConstants.GET_SSO_USERS,
  payload,
});

export const connectSsoUsersAction = (payload) => ({
  type: ActionTypeConstants.CONNECT_SSO_USERS,
  payload,
});

export const generateCodeAction = (payload) => ({
  type: ActionTypeConstants.MFA_GENERATE_CODE,
  payload,
});

export const verifyCodeAction = (payload) => ({
  type: ActionTypeConstants.MFA_VERIFY_CODE,
  payload,
});

export const makeLoginAttempt = (payload) => ({
  type: ActionTypeConstants.SSO_LOGIN_ATTEMPT,
  payload,
});

export const ssoLoginUser = (payload) => ({
  type: ActionTypeConstants.SSO_LOGIN,
  payload,
});

export const masterKeyAction = () => ({
  type: ActionTypeConstants.MFA_MASTER_KEY,
});

/* New design navigation toggle */
export const toggleNavigationAction = (payload) => ({
  type: ActionTypeConstants.TOGGLE_NAVIGATION,
  payload,
});

export const loadUserCountersAction = () => ({
  type: ActionTypeConstants.LOAD_USER_COUNTERS,
});
