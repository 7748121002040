import React, { useState } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';

import HamburgerNav from './Header/HamburgerNav';
import UserPanel from './Header/UserPanel';
import logoImg from 'assets/img/cg-logo.png';

import { useAppSelector } from 'hooks';

import styles from './Header.module.scss';

function getActiveMenu(pathname) {
  if (pathname.startsWith('/illustrate/')) {
    return 'ILLUSTRATE';
  }
  if (pathname.startsWith('/achieve/')) {
    return 'ACHIEVE';
  }
  if (pathname.startsWith('/maintain/')) {
    return 'MAINTAIN';
  }
  if (pathname === '/' || pathname.startsWith('/dashboard') || pathname.startsWith('/organization') || pathname.startsWith('/active') || pathname.startsWith('/settings')) {
    return 'DASHBOARD';
  }
  return 'DASHBOARD';
}

/**
 * Main app Header
 */
const HeaderNew = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeMenu, setActiveMenu] = useState(getActiveMenu(location.pathname));
  const UserOrgSiteReducer = useAppSelector((state) => state.UserOrgSiteReducer);
  const dashPath =
    (UserOrgSiteReducer.selected.is_super || UserOrgSiteReducer.selected.is_coach) && UserOrgSiteReducer.selected.org ? `/organization/${UserOrgSiteReducer.selected.org}` : '/';

  // Renders main navigation items
  const navItems = [
    {
      icon: 'fad fa-tachometer-fast',
      key: 'DASHBOARD',
      path: '/',
      onClick: () => {
        setActiveMenu('DASHBOARD');
        navigate(dashPath);
      },
      title: 'Dashboard',
    },
    {
      icon: 'fad fa-bullseye-pointer',
      key: 'ACHIEVE',
      path: null,
      onClick: () => setActiveMenu('ACHIEVE'),
      title: 'Achieve',
    },
    {
      icon: 'fad fa-bezier-curve',
      key: 'ILLUSTRATE',
      path: null,
      onClick: () => {
        setActiveMenu('ILLUSTRATE');
      },
      title: 'Illustrate',
    },
    {
      icon: 'fad fa-cogs',
      key: 'MAINTAIN',
      path: null,
      onClick: () => setActiveMenu('MAINTAIN'),
      title: 'Maintain',
    },
  ];

  const subItems = {
    DASHBOARD:
      UserOrgSiteReducer.selected.is_super || UserOrgSiteReducer.selected.is_coach
        ? [
            {
              title: 'All Organizations',
              path: '/organizations',
              onClick: () => navigate('/organizations'),
            },
            {
              title: 'Coach View',
              path: '/active',
              onClick: () => navigate('/active'),
            },
          ]
        : [],
    ACHIEVE: [
      {
        title: 'Audits',
        path: '/achieve/audits',
        onClick: () => navigate('/achieve/audits'),
      },
      {
        title: 'Remediation',
        path: '/achieve/remediation',
        onClick: () => navigate('/achieve/remediation'),
      },
      {
        title: 'Vendors',
        path: '/achieve/affiliates',
        onClick: () => navigate('/achieve/affiliates'),
      },
      {
        title: 'Documents',
        path: '/achieve/documents',
        onClick: () => navigate('/achieve/documents'),
      },
      {
        title: 'Training',
        path: '/achieve/training',
        onClick: () => navigate('/achieve/training'),
      },
      {
        title: 'Tasks',
        path: '/achieve/tasks',
        onClick: () => navigate('/achieve/tasks'),
      },
    ],
    ILLUSTRATE: [
      {
        title: 'Reports',
        path: '/illustrate/reports',
        onClick: () => navigate('/illustrate/reports'),
      },
      {
        title: 'Certificates',
        path: '/illustrate/certificates',
        onClick: () => navigate('/illustrate/certificates'),
      },
    ],
    MAINTAIN: [
      {
        title: 'Incidents',
        path: '/maintain/incidents',
        onClick: () => navigate('/maintain/incidents'),
      },
      {
        title: 'Users',
        path: '/maintain/users',
        onClick: () => navigate('/maintain/users'),
      },
      {
        title: 'Organization',
        path: '/maintain/organization',
        onClick: () => navigate('/maintain/organization'),
      },
    ],
  };

  return (
    <div className={styles.header}>
      <div>
        <div className={styles.logo}>
          <img onClick={() => navigate(UserOrgSiteReducer.selected.is_super || UserOrgSiteReducer.selected.is_coach ? '/organizations' : '/')} src={logoImg} alt="The Guard" />
        </div>
        <div className={styles.menu}>
          {activeMenu &&
            navItems.map((item) => {
              return (
                <div className={activeMenu === item.key ? styles.active : null} key={item.key} onClick={item.onClick}>
                  <i className={`${styles.icon} ${item.icon}`} />
                  {item.title}
                </div>
              );
            })}
        </div>

        <div className={styles.panel}>
          <div className="header">
            <div className="nav-panel">
              <div className="top-nav-panel">
                <div className="user-nav">
                  <UserPanel />
                  <div className={styles.panel_hamburger}>
                    <HamburgerNav />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.submenu}>
        {activeMenu &&
          subItems[activeMenu] &&
          subItems[activeMenu].map((item) => (
            <div className={`${styles.subItem} ${location.pathname.includes(item.path) ? styles.subactive : null}`} onClick={item.onClick} key={item.path}>
              {item.title}
            </div>
          ))}
      </div>
    </div>
  );
};

export default HeaderNew;
