import React, { useState, useEffect } from 'react';
import Modal from 'components/Modal/Modal';
import Select from 'components/Select/Select';
import { Button } from 'components/Button/Button';
import { toggleDropdowns } from 'helpers/utils';
import { useAppDispatch, useAppSelector } from 'hooks';
import { loadSitesAction } from 'store/actions/Actions';
import styles from './SiteDeactivationModal.module.scss';

function SiteDeactivationModal(props) {
  const dispatch = useAppDispatch();
  const SitesReducer = useAppSelector((state) => state.SitesReducer);

  const dropdowneRef = React.createRef();

  const [siteId, setSiteId] = useState(0);
  const [sites, setSites] = useState([]);

  const handleConfirm = () => {
    try {
      props.data.confirmAction(siteId);
      props.setShowModal(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!SitesReducer.sites) {
      dispatch(loadSitesAction());
    } else {
      const allSitesExcludingCurrent = SitesReducer.sites.filter((st) => st.id.toString() !== props.data.siteId.toString()) || [];
      allSitesExcludingCurrent.unshift({ id: '0', name: "Don't reassign" });
      setSites(allSitesExcludingCurrent);
    }
  }, [SitesReducer.sites]);

  const modalButtons = (
    <>
      <Button text="CANCEL" color="secondary" onClick={() => props.setShowModal(false)} />
      <Button text="DEACTIVATE" color="danger" className={styles.create} onClick={handleConfirm} />
    </>
  );

  return (
    <Modal className={styles.modal} setShowModal={props.setShowModal}>
      <div onClick={toggleDropdowns([dropdowneRef])}>
        <Modal.Heading>{props.data.title}</Modal.Heading>

        <div className={styles['modal-content']}>{props.data.question}</div>

        <div className={styles['modal-reassign']}>
          <div className={styles['modal-reassign-desc']}>If you want to reassign all the users from the deactivated site to different site please use the dropdown below</div>
          <Select
            value={(sites.find((st) => st.id.toString() === siteId.toString()) && sites.find((st) => st.id.toString() === siteId.toString()).name) || "Don't reassign"}
            size="md"
            onClick={(e) => setSiteId(e.target.value)}
            dropdownToggleRef={dropdowneRef}
          >
            {sites.map((el) => (
              <li value={el.id} key={el.id}>
                {el.name}
              </li>
            ))}
          </Select>
          <small className={styles['modal-reassign-note']}>
            <strong>Note:</strong> All users already assigned to the target site will not be affected.
          </small>
        </div>
        <Modal.Footer>{modalButtons}</Modal.Footer>
      </div>
    </Modal>
  );
}

export default SiteDeactivationModal;
