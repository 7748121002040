import { call, put, takeLatest, delay } from 'redux-saga/effects';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { actionApiCall } from 'helpers/api/ApiCalls';
import { push } from 'redux-first-history';
import * as sagaConstants from 'helpers/constants/SagasConstants';

function* completeTaskSaga({ payload }) {
  try {
    const apiResponse = yield call(actionApiCall, {
      actionType: ActionTypeConstants.COMPLETE_TASK,
      data: payload,
    });
    if (apiResponse.res.data.response === 200) {
      // check if this is dashboard or normal complete
      if (!payload[0].dashboard) {
        yield put({ type: ActionTypeConstants.COMPLETE_TASK_SUCCESS });
        if (payload[0].filter) {
          yield put({ type: ActionTypeConstants.LOAD_TASKS_FILTERED, payload: { filter: payload[0].filter } });
        } else {
          yield put({ type: ActionTypeConstants.LOAD_TASKS });
          yield put({ type: ActionTypeConstants.GET_TASKS_FOR_USER });
        }
        yield put({
          type: ActionTypeConstants.SHOW_ALERT,
          payload: {
            message: apiResponse.res.data.message || `Task ${sagaConstants.SUCCESSFULLY_UPDATED}`,
            color: 'success',
          },
        });
        yield delay(10000);
        yield put({ type: ActionTypeConstants.HIDE_ALERT });
      } else {
        // dashboard complete, redirect to next page
        if (payload[0].path) {
          yield put(push(payload[0].path));
        }
      }
    } else {
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: apiResponse.res.data.message || `${sagaConstants.ISSUE_OCCURED} updating the task`,
          color: 'danger',
        },
      });
      yield delay(10000);
      yield put({ type: ActionTypeConstants.HIDE_ALERT });
    }
  } catch (err) {
    yield put({
      type: ActionTypeConstants.SHOW_ALERT,
      payload: {
        message: `${sagaConstants.SERVER_ERROR}`,
        color: 'danger',
      },
    });
    yield delay(10000);
    yield put({ type: ActionTypeConstants.HIDE_ALERT });
  }
}

export default function* () {
  yield takeLatest(ActionTypeConstants.COMPLETE_TASK, completeTaskSaga);
}
