import React, { useEffect } from 'react';
import RadioGroup from 'components/RadioGroup/RadioGroup';
import styles from './MFAChoose.module.scss';

interface IMFAChoose {
  setChosenMethod?: React.Dispatch<React.SetStateAction<string>>;
  chosenMethod?: string;
  methods?: Array<{
    id?: string;
    type: string;
    value: string;
    email_activated_at?: string | null;
    phone_activated_at?: string | null;
  }>;
}

const defaultProps = {
  setChosenMethod: () => false,
  chosenMethod: '',
  methods: { id: '1', type: 'Add Method', value: '*******123' },
};

function MFAChoose({ setChosenMethod, chosenMethod, methods }: IMFAChoose) {
  useEffect(() => {
    if (methods) setChosenMethod(methods[0]?.type);
  }, [setChosenMethod, methods]);

  return (
    <div>
      <p className={styles['mfa-title']}>Multi-factor authentication</p>
      <p className={styles['mfa-desc']}>
        We will send a one-time use code <br /> to the selected verification method below.
        <br /> This code will be valid for three minutes.
      </p>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        <RadioGroup
          items={methods
            ?.filter((method) => method?.email_activated_at || method?.phone_activated_at)
            .map((method) => ({
              label: `${method.type === 'email' ? 'E-mail' : 'SMS'} (${method.value})`,
              value: method.type,
            }))}
          value={chosenMethod}
          name="mfa-choose"
          display="block"
          onChange={(e) => setChosenMethod(e.target.value)}
        />
      </div>
    </div>
  );
}

MFAChoose.defaultProps = defaultProps;

export default MFAChoose;
