import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Modal from 'components/Modal/Modal';
import { Button } from 'components/Button/Button';
import { bulkUploadAffiliateAction } from 'store/actions/Actions';
import Select from 'components/Select/Select';

import styles from './VendorAuditModal.module.scss';

function VendorAuditModal(props) {
  const { AffiliatesAuditReducer, setShowModal } = props;
  const [questions, setQuestions] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState({
    created_at: undefined,
    audit_json: undefined,
  });

  useEffect(() => {
    if (AffiliatesAuditReducer.audit) {
      setQuestions(AffiliatesAuditReducer.audit.filter((el) => el.completed === 't'));
    }
  }, [AffiliatesAuditReducer.audit]);

  useEffect(() => {
    if (questions) {
      setSelectedQuestion(questions[0]);
    }
  }, [questions]);

  const handleSelect = (e) => {
    setSelectedQuestion(questions && questions.find((question) => question.id === e.target.value.toString()));
  };

  return (
    <Modal setShowModal={setShowModal} className={styles.modal}>
      <div className={styles['modal-header']}>
        <h2 className={styles.heading}>Vendor Audit</h2>
      </div>
      <div className={styles['modal-body']}>
        {questions && questions.length > 1 && (
          <div style={{ margin: '20px auto', width: '250px', textAlign: 'center' }}>
            <span>Select audit to view:</span>
            <br />
            <br />
            <Select value={selectedQuestion && selectedQuestion.created_at} placeholder="Select Audit to View" size="md" onClick={handleSelect}>
              {questions &&
                questions
                  .filter((el) => el.completed === 't')
                  .map((el) => (
                    <li value={el.id} key={`${el.id}-key_created`}>
                      {el.created_at}
                    </li>
                  ))}
            </Select>
          </div>
        )}
        <div>
          {selectedQuestion &&
            selectedQuestion.audit_json &&
            Object.values(selectedQuestion.audit_json).map((section: any) => (
              <div key={section.id}>
                <div className={styles.section_head}>
                  <div>{section.title}</div>
                  <div>{section.desc}</div>
                </div>
                <div>
                  <div className={styles.questions}>
                    {Object.values(section.questions).map((q: any, id) => (
                      <div key={`${q.id}-all`}>
                        <span>{q.id}</span>
                        <span>{q.question}</span>
                        <span>
                          <input type="radio" id={`${q.id}-yes`} data-sid={section.id} data-qid={id + 1} name={`${q.id}-q`} value="Yes" readOnly checked={q.answer === 'Yes'} />
                          <label htmlFor={`${q.id}-yes`}>Yes</label>
                          <input type="radio" id={`${q.id}-no`} data-sid={section.id} data-qid={id + 1} name={`${q.id}-q`} value="No" readOnly checked={q.answer === 'No'} />
                          <label htmlFor={`${q.id}-no`}>No</label>
                          <input type="radio" id={`${q.id}-na`} data-sid={section.id} data-qid={id + 1} name={`${q.id}-q`} value="NA" readOnly checked={q.answer === 'NA'} />
                          <label htmlFor={`${q.id}-na`}>Not Applicable</label>
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className={styles['modal-footer']}>
        <Button className={styles.button} text="CLOSE" onClick={() => props.setShowModal(false)} color="secondary" />
      </div>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  AffiliatesAuditReducer: state.AffiliatesAuditReducer,
});

const mapDispatchToProps = {
  bulkUploadAffiliateAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(VendorAuditModal);
