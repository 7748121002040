import ColVisibilitySelector from 'components/DataTable/ColVisibilitySelector';
import ItemActionsMenu from 'components/DataTable/ItemActionsMenu';
import React from 'react';
import Checkbox from 'components/Checkbox/Checkbox';
import moment from 'moment';
import Button from 'components/Button/Button';
import { getStatusButtonColor } from 'helpers/utils';

export default {
  TABLE: {
    className: 'data-table-incidents',
    initialSortKey: 'occurred',
    initialSortOrder: 'desc',
  },

  COLUMN: [
    {
      label: 'Row Selector',
      key: 'row-selector',
      header: ({ customTableProps }) => {
        const isSelectedAll =
          customTableProps.selectedRows &&
          customTableProps.selectedRows.length &&
          customTableProps.rowsData &&
          customTableProps.rowsData.length &&
          customTableProps.selectedRows.length === customTableProps.rowsData.length
            ? true
            : false;
        return <Checkbox checked={isSelectedAll} onChange={() => customTableProps.doSelectAll()} name="row-selector" />;
      },
      className: 'col-row-selector',
      format: ({ rowData, customTableProps }) => {
        const isSelected =
          customTableProps.selectedRows &&
          customTableProps.selectedRows.length &&
          customTableProps.selectedRows.some((checkbox) => {
            return checkbox.id === rowData.id;
          })
            ? true
            : false;
        return <Checkbox checked={isSelected} onChange={() => customTableProps.onRowSelect({ rowData })} name="row-selector" />;
      },
      canBeHidden: false,
    },
    {
      label: 'Title',
      header: 'TITLE',
      key: 'name',
      sortable: true,
      className: 'title',
      format: ({ rowData, customTableProps }) => {
        return <span>{rowData['name']}</span>; // name -> title
      },
    },
    {
      label: 'Description',
      header: 'DESCRIPTION',
      key: 'description',
      sortable: true,
      className: 'description',
      canBeHidden: true,
      format: ({ rowData, customTableProps }) => {
        return <span>{rowData.description}</span>;
      },
    },
    {
      label: 'Program',
      header: 'PROGRAM',
      key: 'regulatory_act',
      sortable: true,
      className: 'type',
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{rowData['regulatory_act']}</span>;
      },
    },
    {
      label: 'Occurred',
      header: 'OCCURRED',
      key: 'occurred',
      sortable: true,
      className: 'occurred',
      canBeHidden: true,
      format: ({ rowData, customTableProps }) => {
        return <span>{rowData['occurred'] ? moment(rowData['occurred']).format('MM/DD/YYYY') : '-'}</span>;
      },
    },
    {
      label: 'Discovered',
      header: 'DISCOVERED',
      key: 'discovered',
      sortable: true,
      className: 'discovered',
      canBeHidden: true,
      format: ({ rowData, customTableProps }) => {
        return <span>{rowData['discovered'] ? moment(rowData['discovered']).format('MM/DD/YYYY') : '-'}</span>;
      },
    },
    {
      label: 'Reported',
      header: 'REPORTED',
      key: 'reported',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{rowData['reported'] ? moment(rowData['reported']).format('MM/DD/YYYY') : '-'}</span>;
      },
    },
    {
      label: 'Site',
      header: 'SITE',
      key: 'siteName',
      sortable: true,
      className: 'site',
      canBeHidden: true,
      format: ({ rowData, customTableProps }) => {
        return <span>{rowData.siteName}</span>;
      },
    },
    {
      label: 'Resolved',
      header: 'RESOLVED',
      key: 'resolved',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{rowData['resolved'] ? moment(rowData['resolved']).format('MM/DD/YYYY') : '-'}</span>;
      },
    },
    {
      label: 'Reported By',
      header: 'REPORTED BY',
      key: 'reported_by',
      sortable: true,
      canBeHidden: true,
      isHidden: true,
      format: ({ rowData }) => {
        return <span>{rowData['reported_by']}</span>;
      },
    },
    {
      label: 'Vendor',
      header: 'VENDOR',
      key: 'affiliate',
      sortable: true,
      canBeHidden: true,
      isHidden: true,
      format: ({ rowData }) => {
        return <span>{rowData['affiliate']}</span>;
      },
    },
    {
      label: 'Type',
      header: 'TYPE',
      key: 'typeText',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{rowData['incident_type_string'] || rowData['typeText'] || 'Other'}</span>;
      },
    },
    {
      label: 'Affected',
      header: 'AFFECTED',
      key: 'affected',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{rowData['affected'] || rowData['five_hundred_affected'] ? 'Yes' : 'No'}</span>;
      },
    },
    {
      label: 'Status',
      header: 'STATUS',
      key: 'status',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData, customTableProps }) => {
        const status = rowData.status;
        const color = getStatusButtonColor(status, ['Completed'], ['Not Verified']);
        return (
          <span>
            <Button cursorDefault={customTableProps.cursorDefault} color={color} size="md" text={status} onClick={() => null} />
          </span>
        );
      },
    },
    {
      label: 'Actions',
      key: 'actions',
      format: ({ rowData, customTableProps }) => {
        const actionMenuDropDown = React.createRef();
        return (
          <ItemActionsMenu
            ref={actionMenuDropDown}
            actions={(() => {
              return [
                customTableProps.viewIncident
                  ? {
                      label: 'View',
                      action: () => {
                        if (actionMenuDropDown && actionMenuDropDown.current) {
                          actionMenuDropDown.current.handleMenuClose();
                        }
                        customTableProps.viewIncident(rowData.id);
                      },
                    }
                  : false,
                customTableProps.invetiagation
                  ? {
                      label: 'Under Investigation',
                      action: () => {
                        if (actionMenuDropDown && actionMenuDropDown.current) {
                          actionMenuDropDown.current.handleMenuClose();
                        }
                        customTableProps.invetiagation(rowData.id);
                      },
                    }
                  : false,
                customTableProps.approve
                  ? {
                      label: 'Verify',
                      action: () => {
                        if (actionMenuDropDown && actionMenuDropDown.current) {
                          actionMenuDropDown.current.handleMenuClose();
                        }
                        customTableProps.approve(rowData.id);
                      },
                    }
                  : false,
                customTableProps.complete
                  ? {
                      label: 'Complete',
                      action: () => {
                        if (actionMenuDropDown && actionMenuDropDown.current) {
                          actionMenuDropDown.current.handleMenuClose();
                        }
                        customTableProps.complete(rowData.id);
                      },
                    }
                  : false,
                customTableProps.notify
                  ? {
                      label: 'Notify',
                      action: () => {
                        if (actionMenuDropDown && actionMenuDropDown.current) {
                          actionMenuDropDown.current.handleMenuClose();
                        }
                        customTableProps.notify(rowData.id);
                      },
                    }
                  : false,
                customTableProps.delete
                  ? {
                      label: 'Delete',
                      action: () => {
                        if (actionMenuDropDown && actionMenuDropDown.current) {
                          actionMenuDropDown.current.handleMenuClose();
                        }
                        customTableProps.delete(rowData.id);
                      },
                    }
                  : false,
              ];
            })()}
          />
        );
      },
      className: 'col-actions',
      header: ({ customTableProps }) => {
        return (
          <ColVisibilitySelector
            colDefs={customTableProps.colDefs}
            visibleColumnKeys={customTableProps.visibleColumnKeys}
            onChangeVisibility={(colKey) => customTableProps.onChangeVisibility(colKey)}
          />
        );
      },
    },
  ],
};
