import React from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';

import Modal from 'components/Modal/Modal';

import MsalAppProvider from './MsalAppProvider';
import UnAuthModal from './components/UnauthModal';
import AuthModal from './components/AuthModal';

import styles from './AzureADModal.module.scss';

function AzureAdModal(props) {
  return (
    <Modal className={styles.modal} setShowModal={props.setShowModal}>
      <Modal.Heading>Azure Active Directory Config</Modal.Heading>
      <MsalAppProvider>
        <AuthenticatedTemplate>
          <AuthModal setShowModal={props.setShowModal} />
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <UnAuthModal setShowModal={props.setShowModal} />
        </UnauthenticatedTemplate>
      </MsalAppProvider>
    </Modal>
  );
}

export default AzureAdModal;
