import classNames from 'classnames';
import React, { ChangeEventHandler } from 'react';

import styles from './RadioGroup.module.scss';

interface IRadioGroup {
  className?: string;
  labelClassName?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  value?: string;
  disabled?: boolean;
  items?: Array<{
    label: string;
    value: string;
  }>;
  name?: string;
  display: 'inline' | 'block';
}

const defaultProps = {
  items: [{ label: 'add labels', value: undefined }],
  className: undefined,
  disabled: false,
  labelClassName: undefined,
  onChange: () => false,
  name: undefined,
  value: undefined,
  display: 'inline',
};

function RadioGroup({ items, className, disabled, labelClassName, onChange, name, value, display }: IRadioGroup) {
  const styleRadioGroup = classNames(styles.radio, labelClassName);
  const styleInputClass = classNames(styles.hidden, className);

  return (
    <div className={styles[display]}>
      {items.map((item) => (
        <label key={item.label} className={styleRadioGroup}>
          <input disabled={disabled} checked={value === item.value} className={styleInputClass} type="radio" value={item.value} onChange={onChange} name={name} />
          <span className={styles['radio-span']} />
          {item.label}
        </label>
      ))}
    </div>
  );
}

RadioGroup.defaultProps = defaultProps;

export default RadioGroup;
