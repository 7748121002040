import { call, put, takeEvery } from 'redux-saga/effects';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
import Cookies from 'js-cookie';
import { actionApiCall } from 'helpers/api/ApiCalls';
import { getPermissions } from 'helpers/utils/getPermissions/getPermissions';
import * as sagaConstants from 'helpers/constants/SagasConstants';

/**
 * Initially get user by token. UI will be blocked until we have a response here.
 * Logic is the same as in UserLoginForm submission
 *
 * calling this on every page from Root.js
 *
 */
function* authGetUserSaga() {
  try {
    // Check if we're logged and we have at least selected org - via cookie
    const isLogged = yield Cookies.get('isLogged');
    const org = yield Cookies.get('org');
    const site = yield Cookies.get('site') || false;

    if (isLogged) {
      // load orgs for user
      yield put({ type: ActionTypeConstants.LOAD_USER_ORG_SITE });

      const apiResponse1 = yield call(actionApiCall, {
        actionType: ActionTypeConstants.LOAD_USER_ORG_SITE,
        data: {
          organization: false,
        },
      });

      // get use profile
      const apiResponse = yield call(actionApiCall, {
        actionType: ActionTypeConstants.LOAD_USER_SETTINGS_FORM,
        data: {},
      });

      if (apiResponse.res.data.response === 200) {
        // it's all good, we can login
        const { data } = apiResponse.res.data;
        const perms = yield getPermissions(data.role, org, site);
        const { is_admin, is_super, is_coach, is_officer, is_org_admin, is_site_admin, is_org_admin_label } = perms;

        const ui_version = apiResponse1?.res?.data?.data.organizations[org].ui_version || "0"

        yield put({
          type: ActionTypeConstants.SET_USER_ORG_SITE_SUCCESS,
          payload: {
            selected: {
              org,
              site,
              is_admin,
              is_super,
              is_coach,
              is_officer,
              is_org_admin,
              is_site_admin,
              is_org_admin_label,
              ui_version,
            },
          },
        });

        yield put({
          type: ActionTypeConstants.AUTH_LOGGED_IN_SUCCESS,
          payload: data,
        });
      } else {
        // not having required data
        // redirect to login
        yield put({ type: ActionTypeConstants.AUTH_LOGGED_OUT_SUCCESS });
      }

      yield put({
        type: ActionTypeConstants.LOAD_ORG_MODULES,
      });
    } else {
      yield put({ type: ActionTypeConstants.AUTH_LOGGED_OUT_SUCCESS });
    }

    // start auth check
    // yield put({ type: 'AUTH_CHECK' });
  } catch (error) {
    // Failure
    yield put({
      type: ActionTypeConstants.SHOW_ALERT,
      payload: {
        message: `${sagaConstants.SERVER_ERROR}`,
        color: 'danger',
      },
    });
  }
}

export default function* () {
  yield takeEvery(ActionTypeConstants.AUTH_GET_USER, authGetUserSaga);
}
