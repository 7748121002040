import React, { useState } from 'react';
import Modal from 'components/Modal/Modal';
import { Button } from 'components/Button/Button';
import { useAppDispatch, useAppSelector } from 'hooks';
import { updateOrgModulesAction } from 'store/actions/Actions';
import Modules from '../components/Modules/ModulesAlt';
import styles from './EditOrgModal.module.scss';

function EditOrgModal(props) {
  const { setShowModal } = props;

  const dispatch = useAppDispatch();
  const UserOrgSiteReducer = useAppSelector((state) => state.UserOrgSiteReducer);

  const [modules, setModules] = useState([]);
  const [selected, setSelected] = useState([]);
  const [documents, setDocuments] = useState([]);

  const updateActs = (e) => {
    dispatch(updateOrgModulesAction({ acts: selected, modules: [], files: documents }));
    setShowModal(false);
  };

  return (
    <Modal className={styles.modal} setShowModal={setShowModal}>
      <Modal.Heading>Edit Modules</Modal.Heading>

      <Modal.Content>
        <Modules
          editAccess={!(UserOrgSiteReducer.selected && UserOrgSiteReducer.selected.is_officer)}
          selected={selected}
          setSelected={setSelected}
          documents={documents}
          setDocuments={setDocuments}
          modules={modules}
          setModules={setModules}
          editMode
        />
      </Modal.Content>

      <Modal.Footer>
        <Button text="CANCEL" color="secondary" onClick={() => props.setShowModal(false)} />
        {!UserOrgSiteReducer.selected.is_officer && <Button text="UPDATE" className={styles.create} onClick={updateActs} />}
      </Modal.Footer>
    </Modal>
  );
}

export default EditOrgModal;
