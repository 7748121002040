// import ColVisibilitySelector from "components/DataTable/ColVisibilitySelector";
import React from 'react';
import { ONLY_OFFICE_CALLBACK_URL } from 'helpers/ApiEndpointConstants';
import ItemActionsMenu from 'components/DataTable/ItemActionsMenu';

export default {
  TABLE: {
    className: 'data-table-edit-affiliate',
    initialSortKey: 'document-name',
    initialSortOrder: 'desc',
  },

  COLUMN: [
    {
      label: 'Name',
      header: 'NAME',
      key: 'document-name',
      className: 'custom-class',
      format: ({ rowData, customTableProps }) => {
        return rowData['repository'] ? (
          <a href={`${ONLY_OFFICE_CALLBACK_URL}Storage/Affiliates/get.php?${rowData.repository}`} download={rowData.name}>
            {rowData['name']}
          </a>
        ) : (
          rowData['name']
        );
      },
    },
    {
      label: 'Type',
      header: 'TYPE',
      key: 'type',
      format: ({ rowData, customTableProps }) => {
        return <span>{rowData['type']}</span>;
      },
    },
    {
      label: 'Enacted Date',
      header: 'ENACTED DATE',
      key: 'enacted-date',
      format: ({ rowData }) => {
        return <span>{rowData['enacted_date']}</span>;
      },
    },
    {
      label: 'Expiration Date',
      header: 'EXPIRATION DATE',
      key: 'expiration-date',
      format: ({ rowData }) => {
        return <span>{rowData['expiration_date']}</span>;
      },
    },
    {
      label: 'Assignee',
      header: 'ASSIGNEE',
      key: 'assignee',
      format: ({ rowData }) => {
        return <span>{rowData['asignee']}</span>;
      },
    },
    {
      label: 'Approved',
      header: 'APPROVED',
      key: 'approved',
      format: ({ rowData }) => {
        return <span>{rowData['approved'] === true ? 'Yes' : 'No'}</span>;
      },
    },
    {
      label: 'Actions',
      key: 'actions',
      format: ({ rowData, customTableProps }) => {
        const actionMenuDropDown = React.createRef();
        return (
          <ItemActionsMenu
            ref={actionMenuDropDown}
            actions={(() => {
              return [
                customTableProps.approveDocument
                  ? {
                      label: 'Approve',
                      action: () => {
                        if (actionMenuDropDown && actionMenuDropDown.current) {
                          actionMenuDropDown.current.handleMenuClose();
                        }
                        customTableProps.approveDocument(rowData);
                      },
                    }
                  : false,
                customTableProps.unapproveDocument
                  ? {
                      label: 'Disapprove', // "Unapprove"
                      action: () => {
                        if (actionMenuDropDown && actionMenuDropDown.current) {
                          actionMenuDropDown.current.handleMenuClose();
                        }
                        customTableProps.unapproveDocument(rowData);
                      },
                    }
                  : false,
                customTableProps.removeDocument
                  ? {
                      label: 'Delete',
                      action: () => {
                        if (actionMenuDropDown && actionMenuDropDown.current) {
                          actionMenuDropDown.current.handleMenuClose();
                        }
                        customTableProps.removeDocument(rowData);
                      },
                    }
                  : false,
              ];
            })()}
          />
        );
      },
    },
  ],
};
