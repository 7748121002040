import React from 'react';
import moment from 'moment';
import classNames from 'classnames';

import { useAppSelector } from 'hooks';
import styles from './DashboardViews.module.scss';

function VideoFinalize() {
  const TaskReducer = useAppSelector((state) => state.TaskReducer);
  const { task, status } = TaskReducer;

  const is_attested = task?.subtask_data?.understood === 't';
  const requires_attest = task?.subtask_data?.requires_attest === 't';

  const style = classNames('fal fa-times-circle', styles.icon, styles.error);

  return status === 'ISBUSY' ? (
    <div className={styles.question}>Loading...</div>
  ) : (
    <div className={styles.question}>
      {requires_attest ? (
        <>
          {is_attested && (
            <div className={styles.title_holder}>
              <i className={`fal fa-check-circle ${styles.icon}`} />
              <h3>Task Finalized</h3>
              <p>You have attested that you completely read and understood the document.</p>
            </div>
          )}

          {!is_attested && (
            <div className={styles.title_holder}>
              <i className={style} />
              <h3>Finalize Task</h3>
              <p>
                {
                  "You have indicated that you did not read or did not understand the document and can't finalize this task. Please read or reread the document and contact your supervisor or your organization's compliance officer if you still do not understand the document."
                }
              </p>
            </div>
          )}
        </>
      ) : (
        <div className={styles.title_holder}>
          <i className={`fal fa-check-circle ${styles.icon}`} />
          <h3>Task Finalized</h3>
          <p>You have completed the task</p>
        </div>
      )}
      <div className={styles.task_holder}>
        <div>
          <div>Task name:</div>
          <div>{task.task}</div>
        </div>
        <div>
          <div>Requested date:</div>
          <div>{task.assigned ? moment(task.assigned).format('MM/DD/YYYY') : ''}</div>
        </div>
        <div>
          <div>Type:</div>
          <div>{requires_attest ? 'Training' : 'Document'}</div>
        </div>
        {task.siteName && (
          <div>
            <div>Site:</div>
            <div>{task.siteName}</div>
          </div>
        )}
      </div>
    </div>
  );
}

export default VideoFinalize;
