import React, { useEffect, useState } from 'react';

import MainLayout from 'v2/layouts/MainLayout/MainLayout';
import Tabs from 'v2/components/Tabs/Tabs';

import { useAppDispatch, useAppSelector } from 'hooks';
import { loadUserSettingsFormAction } from 'store/actions/Actions';

const profileTab = {
  title: 'Profile',
  path: '',
};
const securityTab = {
  title: 'Security',
  path: 'security',
};

function Profile() {
  const dispatch = useAppDispatch();
  const SettingsReducer = useAppSelector((state) => state.SettingsReducer);

  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    if (SettingsReducer.user) {
      if (SettingsReducer.user?.connected_to_sso) {
        setTabs([profileTab]);
      } else {
        setTabs([profileTab, securityTab]);
      }
    } else {
      dispatch(loadUserSettingsFormAction());
    }
  }, [SettingsReducer.user]);

  return (
    <MainLayout>
      <Tabs tabs={tabs} />
    </MainLayout>
  );
}

export default Profile;
