import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import AppLayoutWrapFull from 'components/AppLayoutWrapFull';
import PageHeader from 'components/PageHeader/PageHeader';
import Container from 'components/Container/Container';
import FormElement from 'components/FormElement/FormElement';
import Select from 'components/Select/Select';
import SelectWithSearch from 'components/SelectWithSearch/SelectWithSearch';
import Row from 'components/Row/Row';
import Radio from 'components/Radio/Radio';
import HR from 'components/HR/HR';
import { Button } from 'components/Button/Button';

import { submitAddAffiliateFormAction, readAffiliateAgreementsAction } from 'store/actions/Actions';
import { states, statesObj, toggleDropdowns } from 'helpers/utils';

import UploadDocuments from '../components/UploadDocuments/UploadDocuments';

import styles from './Add.module.scss';

function Add(props) {
  const { AffiliateAgreementsReducer, readAffiliateAgreementsAction, AddAffiliateFormReducer } = props;
  const navigate = useNavigate();
  const [state, setState] = useState({
    name: '',
    phi: false,
    contact_first: '',
    contact_last: '',
    contact_email: '',
    phone: '',
    address_1: '',
    address_2: '',
    city: '',
    state: '',
    state_name: '',
    // region: "",
    country_code: 235,
    postal_code: '',
    notes: '',
    ba_agreement_id: '',
    conf_agreement_id: '',
  });

  const [stateErrors, setStateErrors] = useState({
    name: undefined,
    postal_code: undefined,
    state_name: undefined,
    city: undefined,
    address_2: undefined,
    address_1: undefined,
    phone: undefined,
    contact_email: undefined,
    contact_last: undefined,
    contact_first: undefined,
    phi: undefined,
  });
  const [documents, setDocuments] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const baDropdownRef = React.createRef();
  const confDropdownRef = React.createRef();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    const state_name = statesObj[state.state] || state.state;
    if (state_name) {
      setState((s) => ({
        ...s,
        state_name,
      }));
    }
  }, [state.state]);

  useEffect(() => {
    if (!AffiliateAgreementsReducer.files) {
      readAffiliateAgreementsAction();
    }
  }, [AffiliateAgreementsReducer.files, readAffiliateAgreementsAction]);

  const handleChange = (inputName) => (e) => {
    e.persist();
    setState((s) => ({
      ...s,
      [inputName]: e.target.type === 'checkbox' ? e.target.checked : e.target.value === 'true' ? true : e.target.value === 'false' ? false : e.target.value,
    }));
    setStateErrors((s) => ({ ...s, [inputName]: '' }));
  };

  const handleRadioChange = (inputName) => (e) => {
    e.persist();
    setState((s) => ({
      ...s,
      [inputName]: !s[inputName],
    }));
    setStateErrors((s) => ({ ...s, [inputName]: '' }));
  };

  const handleSelectChange = (inputName) => (e) => {
    const inputType = e.target.getAttribute('value');
    setState((s) => ({ ...s, [inputName]: `${inputType}` }));
    setStateErrors((s) => ({ ...s, [inputName]: '' }));
  };

  const handleChangeSelectWithSearch = (type) => (data) => {
    let tmp = {};
    if (type === 'state_name') {
      tmp = {
        state: data.id || '',
        state_name: data.name || '',
      };
    }
    setState((s) => ({
      ...s,
      ...tmp,
    }));
    setStateErrors((s) => ({ ...s, [type]: '' }));
  };

  const submitAddAffiliate = () => {
    if (
      state.name === '' ||
      state.contact_first === '' ||
      state.contact_last === '' ||
      // || state.contact_email === ''
      state.phone === '' ||
      state.address_1 === '' ||
      state.city === '' ||
      // state.region === "" ||
      state.postal_code === ''
      // || !isEmailValid(state.contact_email)
    ) {
      setStateErrors({
        ...stateErrors,
        name: state.name === '' ? 'Name is required' : '',
        contact_first: state.contact_first === '' ? 'First name is required' : '',
        contact_last: state.contact_last === '' ? 'Last name is required' : '',
        // contact_email: state.contact_email === '' ? 'Email is required' : (!isEmailValid(state.contact_email) ? 'Invalid email' : ''),
        phone: state.phone === '' ? 'Phone is required' : '',
        address_1: state.address_1 === '' ? 'Address is required' : '',
        city: state.city === '' ? 'City is required' : '',
        // region: state.state_name === '' ? 'State/Region is required' : '',
        postal_code: state.postal_code === '' ? 'Postal code is required' : '',
      });
    } else {
      props.submitAddAffiliateFormAction({ ...state, documents });
    }
    window.scrollTo(0, 0);
  };

  const filesLabels = {};
  if (AffiliateAgreementsReducer.files) {
    for (const item of AffiliateAgreementsReducer.files) {
      filesLabels[item.id] = item.folder_name;
    }
  }

  return (
    <AppLayoutWrapFull>
      <PageHeader />
      <Container wide>
        <form aria-hidden="true" className={styles.form} onClick={toggleDropdowns([baDropdownRef, confDropdownRef])}>
          <FormElement labelText="NAME" htmlFor="name" smallText="*" errorMessage={stateErrors.name}>
            <input name="name" type="text" placeholder="Enter Vendor Name" value={state.name} onChange={handleChange('name')} />
          </FormElement>
          <FormElement labelText="AGREEMENT LEVEL" htmlFor="agreement-level" errorMessage={stateErrors.phi}>
            <Row className={styles.phi}>
              <Radio checked={state.phi} labelText="Business Associate" onChange={handleRadioChange('phi')} />
              <Radio checked={!state.phi} labelText="Confidentiality" onChange={handleRadioChange('phi')} />
            </Row>
          </FormElement>

          <FormElement labelText="FIRST NAME" htmlFor="contact_first" smallText="*" errorMessage={stateErrors.contact_first}>
            <input name="contact_first" type="text" placeholder="Enter Vendor Contact First Name" value={state.contact_first} onChange={handleChange('contact_first')} />
          </FormElement>

          <FormElement labelText="LAST NAME" htmlFor="contact_last" smallText="*" errorMessage={stateErrors.contact_last}>
            <input name="contact_last" type="text" placeholder="Enter Vendor Contact Last Name" value={state.contact_last} onChange={handleChange('contact_last')} />
          </FormElement>

          <FormElement labelText="CONTACT EMAIL" htmlFor="contact_email" className={styles['contact-email']} errorMessage={stateErrors.contact_email}>
            <input name="contact_email" type="email" placeholder="Enter Vendor Contact Email" value={state.contact_email} onChange={handleChange('contact_email')} />
          </FormElement>
          <FormElement
            labelText="CONTACT PHONE"
            // noLabel={true}
            smallText="*"
            errorMessage={stateErrors.phone}
          >
            <Row className={styles['form-group-row']}>
              <input
                className={styles['contact-phone-input']}
                name="phone"
                type="text"
                placeholder="Enter Vendor Contact Phone"
                value={state.phone}
                onChange={handleChange('phone')}
              />
            </Row>
          </FormElement>
          <FormElement labelText="ADDRESS" htmlFor="address_1" smallText="*" errorMessage={stateErrors.address_1}>
            <input name="address_1" type="text" placeholder="Street and number" value={state.address_1} onChange={handleChange('address_1')} />
          </FormElement>

          <span>&nbsp;</span>

          <FormElement
            // labelText="ADDRESS 2"
            htmlFor="address_2"
            errorMessage={stateErrors.address_2}
          >
            <input name="address_2" type="text" placeholder="Apartment, building, floor (optional)" value={state.address_2} onChange={handleChange('address_2')} />
          </FormElement>

          <span>&nbsp;</span>

          <Row className={`${styles['form-group-row']} ${styles['city-state-postal_code']}`}>
            <FormElement labelText="CITY" htmlFor="city" smallText="*" errorMessage={stateErrors.city}>
              <input name="city" type="text" placeholder="City" value={state.city} onChange={handleChange('city')} />
            </FormElement>

            <FormElement labelText="STATE / REGION" htmlFor="state" errorMessage={stateErrors.state_name}>
              <SelectWithSearch
                value={state.state}
                onChange={handleChangeSelectWithSearch('state_name')}
                options={states.map((item) => ({ ...item, id: item.abbrev }))}
                placeholder="State"
              />
            </FormElement>

            <FormElement labelText="POSTAL CODE" htmlFor="postal_code" smallText="*" errorMessage={stateErrors.postal_code}>
              <input name="postal_code" type="text" placeholder="Postal Code" value={state.postal_code} onChange={handleChange('postal_code')} />
            </FormElement>
          </Row>

          <span>&nbsp;</span>

          <FormElement labelText="BA Agreement" htmlFor="ba_agreement_id">
            <Select
              // name="ba_agreement_id"
              value={state.ba_agreement_id}
              placeholder="Select BA Agreement document"
              onClick={handleSelectChange('ba_agreement_id')}
              dropdownToggleRef={baDropdownRef}
              labels={filesLabels}
            >
              {AffiliateAgreementsReducer.files &&
                AffiliateAgreementsReducer.files.map((file) => (
                  <li key={`ba_agreement_id-${file.id}`} value={file.id}>
                    {file.folder_name}
                  </li>
                ))}
            </Select>
          </FormElement>

          <FormElement labelText="Confidentiality Agreement" htmlFor="conf_agreement_id">
            <Select
              // name="conf_agreement_id"
              value={state.conf_agreement_id}
              placeholder="Select Confidentiality Agreement document"
              onClick={handleSelectChange('conf_agreement_id')}
              dropdownToggleRef={confDropdownRef}
              labels={filesLabels}
            >
              {AffiliateAgreementsReducer.files &&
                AffiliateAgreementsReducer.files.map((file) => (
                  <li key={`conf_agreement_id-${file.id}`} value={file.id}>
                    {file.folder_name}
                  </li>
                ))}
            </Select>
          </FormElement>

          <br />

          <UploadDocuments documents={documents || []} setDocuments={setDocuments} setShowModal={setShowModal} showModal={showModal} className={styles['upload-documents']} />
          <FormElement labelText="NOTES" htmlFor="notes">
            <textarea className={styles.notes || ''} value={state.notes} onChange={handleChange('notes')} />
          </FormElement>
          <HR className={styles.hr} />
          <div className={styles['form-options']}>
            <Button onClick={() => navigate('/achieve/affiliates')} className={styles.button} text="CANCEL" color="secondary" />
            <Button
              className={styles.button}
              text="ADD VENDOR"
              disabled={AddAffiliateFormReducer.status === 'ISBUSY'}
              icon={AddAffiliateFormReducer.status === 'ISBUSY' ? 'fad fa-spinner-third fa-spin' : 'far fa-plus'}
              onClick={submitAddAffiliate}
            />
          </div>
        </form>
      </Container>
    </AppLayoutWrapFull>
  );
}

const mapStateToProps = (state) => ({
  AddAffiliateFormReducer: state.AddAffiliateFormReducer,
  AffiliateAgreementsReducer: state.AffiliateAgreementsReducer,
});

const mapDispatchToProps = {
  submitAddAffiliateFormAction,
  readAffiliateAgreementsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Add);
