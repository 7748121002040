import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import Reports from './Reports/Reports';
import Certificates from './Certificates/Certificates';

function Illustrate() {
  return (
    <Routes>
      <Route path="/certificates" element={<Certificates />} />
      <Route path="/reports" element={<Reports />} />
      <Route path="*" element={<Navigate to={'/404'} replace />} />
    </Routes>
  );
}

export default Illustrate;
