// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';

import {
  loadSsoOrganizationsAction,
  connectSsoOrganizationAction,
  getSsoUsersAction,
  connectSsoUsersAction,
  loadRolesAction,
  loadOrgSiteDeptAction,
  loadUsersAction,
} from 'store/actions/Actions';
import { useAppSelector, useAppDispatch } from 'hooks';

import ActionStatusConstants from 'helpers/ActionStatusConstants';

import Spinner from 'components/Spinner/Spinner';
import FormElement from 'components/FormElement/FormElement';
import SelectWithSearch from 'components/SelectWithSearch/SelectWithSearch';
import Checkbox from 'components/Checkbox/Checkbox';
import { SelectMulti } from 'components/SelectMulti/SelectMulti';
import { Button } from 'components/Button/Button';
import Modal from 'components/Modal/Modal';

import { loginRequest } from '../authConfig';
import { SignOutButton } from './SignOutButton';

import AccessPermissions from 'v2/views/People/Users/components/AccessPermissions/AccessPermissions';
import { generateOrgList, getInitState, getItemsList } from 'v2/views/People/Users/accessUtils';

import styles from '../AzureADModal.module.scss';

const provider_key = 'Azure';

function AuthModal(props) {
  const dispatch = useAppDispatch();
  const { instance, accounts } = useMsal();
  const OrganizationsAzureADReducer = useAppSelector((state) => state.OrganizationsAzureADReducer);
  const ConnectOrgAzureADReducer = useAppSelector((state) => state.ConnectOrgAzureADReducer);
  const UsersOrgAzureADReducer = useAppSelector((state) => state.UsersOrgAzureADReducer);
  const OrgSiteDeptReducer = useAppSelector((state) => state.OrgSiteDeptReducer);
  const UserOrgSiteReducer = useAppSelector((state) => state.UserOrgSiteReducer);
  const RolesReducer = useAppSelector((state) => state.RolesReducer);
  const UsersReducer = useAppSelector((state) => state.UsersReducer);

  const [selectedOrg, setSelectedOrg] = useState('');
  const [connectedUsers, setConnectedUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedNames, setSelectedNames] = useState([]);
  const [stateErrors, setStateErrors] = useState({
    orgError: '',
    usersError: '',
    access: '',
  });

  const getAzureAccessToken = async () => {
    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    let accessToken;
    try {
      // Silently acquires access token
      const data = await instance.acquireTokenSilent(request);
      accessToken = data.accessToken;
    } catch {
      // Popup to get access token
      const data = await instance.acquireTokenPopup(request);
      accessToken = data.accessToken;
    }
    return accessToken;
  };

  const getOrganizations = async () => {
    const access_token = await getAzureAccessToken();
    dispatch(
      loadSsoOrganizationsAction({
        provider_key,
        access_token,
      })
    );
  };

  const connectOrg = async (org) => {
    const access_token = await getAzureAccessToken();
    dispatch(
      connectSsoOrganizationAction({
        access_token,
        provider_key,
        connect: {
          id: org.id,
          secondary_key: org.secondary_key,
        },
      })
    );
  };

  const getUsers = async () => {
    const access_token = await getAzureAccessToken();
    dispatch(
      getSsoUsersAction({
        access_token,
        provider_key,
      })
    );
  };

  useEffect(() => {
    dispatch(loadUsersAction({ inactive: true }));
  }, []);

  useEffect(() => {
    if (!OrganizationsAzureADReducer?.orgList) {
      getOrganizations();
    }
  }, [OrganizationsAzureADReducer.orgList]);

  useEffect(() => {
    if (ConnectOrgAzureADReducer.status === ActionStatusConstants.SUCCESS) {
      getUsers();
    }
  }, [ConnectOrgAzureADReducer.status]);

  useEffect(() => {
    if (!RolesReducer.roles) {
      dispatch(loadRolesAction({}));
    }
  }, [RolesReducer.roles]);

  useEffect(() => {
    if (UsersReducer.users) {
      const data = (UsersReducer?.users || []).filter((user) => user.connected_to_sso).map((user) => user.email);
      setConnectedUsers(data);
    }
  }, [UsersReducer.users]);

  const handleOrgSelect = (data) => {
    setSelectedOrg(data?.id || '');
    setStateErrors({
      ...stateErrors,
      orgError: '',
    });
    if (data?.id) {
      connectOrg(data);
    }
  };

  const handleUserSelect = (e) => {
    const { value, name, checked } = e.target;
    if (value) {
      if (value === 'ALL' && name === 'ALL') {
        if (checked) {
          const filteredImportedUsers = UsersOrgAzureADReducer.users.filter((item) => !connectedUsers.includes(item.email));
          setSelectedUsers(filteredImportedUsers.map((item) => item.email));
          setSelectedNames(filteredImportedUsers.map((item) => item.name));
        } else {
          setSelectedUsers([]);
          setSelectedNames([]);
        }
        return;
      }
      if (!selectedUsers.includes(value)) {
        setSelectedUsers([...selectedUsers, value]);
        setSelectedNames([...selectedNames, name]);
      } else {
        setSelectedUsers(selectedUsers.filter((item) => item !== value));
        setSelectedNames(selectedNames.filter((item) => item !== name));
      }
    }
    setStateErrors({
      ...stateErrors,
      usersError: '',
    });
  };

  const name = accounts[0] && accounts[0].name;

  const [localBoxes, setLocalBoxes] = useState({});
  const orgs = generateOrgList(OrgSiteDeptReducer.organizations);
  const items = getItemsList(orgs);
  const initState = {
    ...getInitState({}, items, RolesReducer.roles),
    ...localBoxes,
  };

  const onSave = async () => {
    if (selectedOrg.length === 0) {
      setStateErrors({
        ...stateErrors,
        orgError: 'Organization is required!',
      });
      return;
    }
    if (selectedUsers.length === 0) {
      setStateErrors({
        ...stateErrors,
        usersError: 'User(s) is required!',
      });
      return;
    }
    const access = [];
    let errorAccess = false;
    const rolesElemnets = document.querySelectorAll('[name=role]');
    if (rolesElemnets.length === 0) {
      errorAccess = true;
      setStateErrors({
        ...stateErrors,
        access: errorAccess ? 'Access role(s) are required!' : '',
      });
      return;
    }
    for (const item of rolesElemnets) {
      if (item.value === '0') {
        errorAccess = true;
        setStateErrors({
          ...stateErrors,
          access: errorAccess ? 'Access role(s) are required!' : '',
        });
        return;
      }
      access.push({
        role_id: item.value,
        organization: item.dataset.org,
        site: item.dataset.site || undefined,
        department: item.dataset.dep || (item.dataset.partial === 'true' && 'partial') || undefined,
      });
    }
    const selectedOrgAccess = OrgSiteDeptReducer.organizations ? Object.keys(OrgSiteDeptReducer.organizations)[0] : undefined;
    const access_roles = access.length === 0 ? [{ organization: selectedOrgAccess }] : access;

    // console.log('selectedOrg, selectedUsers, access_roles', selectedOrg, selectedUsers, access_roles);
    const access_token = await getAzureAccessToken();
    dispatch(
      connectSsoUsersAction({
        provider_key,
        access_token,
        roles: access_roles,
        users: UsersOrgAzureADReducer.users.filter((user) => selectedUsers.includes(user.email)),
      })
    );
    props.setShowModal(false);
  };

  return (
    <>
      <div className={styles['modal-content']}>
        <div className={styles.title}>
          <h2 className="card-title">{`Welcome ${name}`}</h2>
          <SignOutButton />
        </div>
        {OrganizationsAzureADReducer?.orgList ? (
          <div className={styles.modal_content_wrapper}>
            <div className={styles.modal_content_left}>
              <div className={styles.modal_content}>
                <FormElement className={styles.org_list} labelText="ORGANIZATIONS" htmlFor="organizations" smallText="*" errorMessage={stateErrors.orgError}>
                  <SelectWithSearch
                    key="organizations"
                    value={selectedOrg}
                    onChange={handleOrgSelect}
                    options={OrganizationsAzureADReducer.orgList}
                    placeholder="Select Organization"
                  />
                </FormElement>

                {ConnectOrgAzureADReducer?.message ? <div className={styles.hint}>{ConnectOrgAzureADReducer?.message}</div> : undefined}

                {UsersOrgAzureADReducer?.users ? (
                  <>
                    <FormElement labelText="AZURE ACTIVE DIRECTORY USERS" htmlFor="users" smallText="*" errorMessage={stateErrors.usersError}>
                      <SelectMulti
                        key="users"
                        placeholder="Azure Active Directory Users "
                        value={selectedUsers.length > 0 ? `Users (${selectedUsers.length})` : undefined}
                        onClick={handleUserSelect}
                      >
                        <Checkbox
                          checked={
                            UsersOrgAzureADReducer.users &&
                            UsersOrgAzureADReducer.users.length === selectedUsers.length + UsersOrgAzureADReducer.users.filter((user) => connectedUsers.includes(user.email)).length
                          }
                          name="ALL"
                          labelText="ALL"
                          key="ALL"
                          value="ALL"
                        />
                        {UsersOrgAzureADReducer.users.map((user) => (
                          <Checkbox
                            disabled={connectedUsers.includes(user.email)}
                            checked={selectedUsers.includes(user.email) || connectedUsers.includes(user.email)}
                            name={user.name}
                            labelText={`${user.name} - ${user.email}`}
                            key={user.email}
                            value={user.email}
                          />
                        ))}
                      </SelectMulti>
                    </FormElement>
                    {selectedNames.length > 0 ? (
                      <div className={styles.user_list}>
                        {selectedUsers.map((email, index) => (
                          <div className={styles.user_list_item} key={email}>{`${selectedNames[index]} - ${email}`}</div>
                        ))}
                      </div>
                    ) : undefined}
                  </>
                ) : undefined}
              </div>
            </div>
            <div className={styles.modal_content_right}>
              {selectedNames.length > 0 ? (
                <div className={styles.modal_content}>
                  <AccessPermissions
                    organizations={OrgSiteDeptReducer.organizations}
                    initState={initState}
                    orgs={orgs}
                    userOrgs={UserOrgSiteReducer.organizations}
                    selected={UserOrgSiteReducer.selected}
                    setLocalBoxes={setLocalBoxes}
                    loadOrgSiteDeptAction={(orgId) => dispatch(loadOrgSiteDeptAction(orgId))}
                    roles={RolesReducer.roles}
                    error={stateErrors.access}
                    setStateErrors={setStateErrors}
                    // permissions={RolesUserReducer.roles}
                    placeholder="The Business Company"
                    UsersReducer={UsersReducer}
                  />
                </div>
              ) : undefined}
            </div>
          </div>
        ) : (
          <Spinner />
        )}
      </div>
      <Modal.Footer>
        <Button text="CANCEL" color="secondary" onClick={() => props.setShowModal(false)} />
        <Button className={styles.save_btn} text="SAVE" onClick={onSave} />
      </Modal.Footer>
    </>
  );
}

export default AuthModal;
