import { put, takeEvery, call, delay } from 'redux-saga/effects';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { actionApiCall } from 'helpers/api/ApiCalls';
import * as sagaConstants from 'helpers/constants/SagasConstants';

function* addNewOrgWizardSaga({ payload }) {
  try {
    const apiResponse = yield call(actionApiCall, {
      actionType: ActionTypeConstants.SUBMIT_NEW_ORG_SAGA,
      data: payload,
    });

    yield delay(1000);
    if (apiResponse.res.data.response === 200) {
      yield put({
        type: ActionTypeConstants.CREATE_ORG_SUCCESS,
        payload: {
          data: apiResponse.res.data.data,
          message: `A new organization ${sagaConstants.SUCCESSFULLY_CREATED}`,
          color: 'success',
        },
      });
    } else {
      yield put({
        type: ActionTypeConstants.CREATE_ORG_FAILURE,
        payload: {
          message: apiResponse.res.data.message || [`${sagaConstants.ISSUE_OCCURED} creating a new organization`],
          color: 'danger',
        },
      });
    }
  } catch (err) {
    yield put({
      type: ActionTypeConstants.CREATE_ORG_FAILURE,
      payload: {
        message: [`${sagaConstants.SERVER_ERROR}`],
        color: 'danger',
      },
    });
  }
}

export default function* () {
  yield takeEvery(ActionTypeConstants.SUBMIT_NEW_ORG_SAGA, addNewOrgWizardSaga);
}
