import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import { useNavigate, useSearchParams } from 'react-router-dom';

import AppLayoutWrapFull from 'components/AppLayoutWrapFull';
import Container from 'components/Container/Container';
import PageHeader from 'components/PageHeader/PageHeader';
import Question from './components/Question/Question';
import Button from 'components/Button/Button';
import Modal from 'components/Modal/Modal';
import Accordion from 'components/Accordion/Accordion';
import Select from 'components/Select/Select';
import FormElement from 'components/FormElement/FormElement';

import { toggleDropdowns, makeDeepCopy } from 'helpers/utils';
import EditEvidence from './components/Evidence/EditEvidence';
import { loadAuditAction, updateAuditAction, loadUsersAction, completeAuditAction, verifyAuditAction } from 'store/actions/Actions';
import Spinner from 'components/Spinner/Spinner';
import ActionStatusConstants from 'helpers/ActionStatusConstants';

import styles from './Audit.module.scss';

const Audit = (props) => {
  const initialState = [
    {
      name: '',
      question: '',
      answer: '3',
      risk: '',
      notes: '',
      user_id: '',
      site: null,
      questions: [],
      filtered_users: false,
    },
  ];
  const [state, setState] = useState(initialState);

  const navigate = useNavigate();
  const [search] = useSearchParams();
  const auditId = search.get('id');
  const questionId = search.get('questionId');

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [showDescription, setShowDescription] = useState(false);
  const [updated, setUpdated] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [errors, setErrors] = useState({ employee: '' });
  const [isLoading, setLoading] = useState(true);

  const firstRender = useRef(true);

  const { UsersReducer, AuditReducer, loadUsersAction, loadAuditAction, completeAuditAction, updateAuditAction, verifyAuditAction } = props;

  // Load data
  useEffect(() => {
    if (!UsersReducer.users && AuditReducer.audit) {
      loadUsersAction({ site: AuditReducer.audit.site || undefined });
    }
  }, [UsersReducer.users, AuditReducer.audit, loadUsersAction]);

  useEffect(() => {
    if (!AuditReducer.audit && auditId) {
      loadAuditAction(auditId);
    }
    if (AuditReducer.audit && auditId) {
      if (JSON.stringify(state) === JSON.stringify(initialState)) {
        // initial load
        setState(AuditReducer.audit);
      } else {
        // evidence update
        const newEvidence = AuditReducer.audit.questions[currentQuestionIndex].evidence;
        const stateCopy = makeDeepCopy(state);
        stateCopy.questions[currentQuestionIndex].evidence = newEvidence;
        setState((oldState) => ({ ...oldState, ...stateCopy }));
      }

      if (firstRender.current) {
        AuditReducer.audit.current && (questionId ?? setCurrentQuestionIndex(AuditReducer.audit.current));
        firstRender.current = false;
      }

      if (questionId) {
        const questionIndex = AuditReducer?.audit?.questions?.findIndex((question) => question?.question_id === questionId);
        questionIndex !== -1 && setCurrentQuestionIndex(questionIndex);
      }
    }
  }, [AuditReducer.audit, auditId, loadAuditAction, questionId]);

  useEffect(() => {
    if (AuditReducer.audit && auditId) {
      verifyAuditAction(auditId);
    }
  }, [state.currentQuestionIndex, auditId, AuditReducer.audit, verifyAuditAction]);

  useEffect(() => {
    if (UsersReducer.status === ActionStatusConstants.SUCCESS && AuditReducer.status === ActionStatusConstants.SUCCESS) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [UsersReducer.users, AuditReducer.audit]);

  const setNewState = (index) => (key) => (e) => {
    const questions = [...state.questions];
    questions[index] = {
      ...state.questions[index],
      [key]: (e.target.attributes && e.target.attributes.value && e.target.attributes.value.value) || e.target.value,
    };

    const {
      // questions: oldQuestions,
      ...rest
    } = state;

    setState({ ...rest, questions });
    setUpdated(true);
  };

  const changeQuestion = (direction) => {
    window.scrollTo(0, 0);
    if (direction && currentQuestionIndex < state.questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else if (!direction) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const setErrorIfNoAssignedEmployee = (user_id, answer) => {
    answer === '4' && user_id === '' ? setErrors({ employee: 'Please assign an employee' }) : setErrors({ employee: '' });
  };

  const handleBack = () => {
    const { user_id, response_id, question_id, answer, notes, risk } = state.questions[currentQuestionIndex];
    setErrorIfNoAssignedEmployee(user_id, answer);
    if (answer === '4' && user_id === '') return;
    window.scrollTo(0, 0);
    changeQuestion(0);
    if (updated) {
      if (!state.finalized) {
        updateAuditAction({
          user_id: answer !== '4' ? '' : user_id,
          response_id,
          question_id,
          answer: answer === '' ? '3' : answer,
          notes,
          risk,
          audit: auditId,
        });
        window.setTimeout(() => {
          verifyAuditAction(auditId);
        }, 1000);
      }
    }
    if (!updated) {
      setUpdated(true);
    }
  };

  const handleNext = () => {
    const { user_id, response_id, question_id, answer, notes, risk } = state.questions[currentQuestionIndex];
    setErrorIfNoAssignedEmployee(user_id, answer);
    if (answer === '4' && user_id === '') return;
    window.scrollTo(0, 0);
    changeQuestion(1);
    if (updated && state.configuration.status !== '8') {
      if (!state.finalized) {
        updateAuditAction({
          user_id: answer !== '4' ? '' : user_id,
          response_id,
          question_id,
          answer: answer === '' ? '3' : answer,
          notes,
          risk,
          audit: auditId,
        });
        window.setTimeout(() => {
          verifyAuditAction(auditId);
        }, 1000);
      }
    }
    if (!updated) {
      setUpdated(true);
    }
  };

  const handleFirst = () => {
    const { user_id, answer } = state.questions[currentQuestionIndex];
    setErrorIfNoAssignedEmployee(user_id, answer);
    if (answer === '4' && user_id === '') return;
    setCurrentQuestionIndex(0);
  };

  const handleFinalizeOpen = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setShowModal(true);
    return;
  };

  const handleClose = () => {
    navigate('/achieve/audits');
    return;
  };

  const isLast = currentQuestionIndex === (state.questions && state.questions.length - 1) || 0;
  const isFirst = currentQuestionIndex === 0;
  const isFinalized = state.finalized;

  const isLaunched = (AuditReducer.audit && AuditReducer.audit.configuration && AuditReducer.audit.configuration.launched) || false;

  const isFinalizable = AuditReducer.finalizable || false;
  const questionDropdownRef = React.createRef();

  return (
    <AppLayoutWrapFull onClick={toggleDropdowns([questionDropdownRef])}>
      {state.questions && (
        <PageHeader loadStats={false} breadcrumbs={`Achieve / Audits / ${state.name}`} className={`${styles['page-header']} ${showDescription ? styles['show-description'] : ''}`}>
          <p className={styles.heading}>
            {state.questions[currentQuestionIndex]['regulation_number']}
            <i className={`fal fa-question-circle ${styles['heading-icon']} ${showDescription ? styles['active'] : ''}`} onClick={() => setShowDescription(!showDescription)} />
          </p>
          <p>{showDescription && state.questions[currentQuestionIndex].description}</p>
        </PageHeader>
      )}
      {state.questions && AuditReducer.finalizable !== undefined && !isLoading ? (
        <Container fluid>
          <div style={{ display: 'flex' }}>
            <span className={styles.total}>
              {currentQuestionIndex + 1} of {state.questions.length} questions
            </span>

            <div style={{ marginLeft: 'auto' }} className={styles.question_holder}>
              <FormElement htmlFor="question">
                <Select
                  truncate={true}
                  size="fixed"
                  dropdownToggleRef={questionDropdownRef}
                  value={`Question ${currentQuestionIndex + 1}: ${state.questions[currentQuestionIndex].question}`}
                  onClick={() => null}
                  active={`aqid-${currentQuestionIndex}`}
                  labels={state.questions.map((item) => ({
                    [item.question_id]: item.question,
                  }))}
                >
                  {state.questions.map((item, idx) => (
                    <li
                      id={`aqid-${idx}`}
                      key={`${item.question_id}-${item.answer}-${idx}-${currentQuestionIndex}`}
                      data-attr="country_code"
                      data-id={item.question_id}
                      data-name={item.name}
                      data-pos={idx}
                      value={item.name}
                      onClick={(e) => {
                        setCurrentQuestionIndex(idx);
                        setNewState(idx);
                      }}
                    >
                      <div>
                        <div>
                          Question {idx + 1}: {item.question}
                        </div>
                      </div>
                    </li>
                  ))}
                </Select>
              </FormElement>
            </div>
          </div>

          <Question
            finalized={state.finalized || state.configuration.status === '8' || false}
            users={UsersReducer.users}
            question={state.questions[currentQuestionIndex]}
            setData={setNewState(currentQuestionIndex)}
            errors={errors}
          />

          <div style={{ padding: '0 50px 10px 50px' }}>
            <Accordion
              title={
                <span>
                  {isFinalized ? 'DOWNLOAD EVIDENCE' : 'ATTACH EVIDENCE'}
                  <span
                    style={{
                      color: 'grey',
                      fontSize: '1.3rem',
                      marginLeft: '10px',
                    }}
                  >
                    {isFinalized ? '' : '(Optional)'}
                  </span>
                </span>
              }
              extraTitle={`FILES: ${
                (state.questions &&
                  state.questions[currentQuestionIndex] &&
                  state.questions[currentQuestionIndex].evidence &&
                  state.questions[currentQuestionIndex].evidence.length) | 0
              }`}
              content={
                <EditEvidence
                  responseId={state.questions && state.questions[currentQuestionIndex] && state.questions[currentQuestionIndex].response_id}
                  files={state.questions && state.questions[currentQuestionIndex] && state.questions[currentQuestionIndex].evidence}
                  isFinalized={isFinalized}
                  auditId={auditId}
                  requestType={'audit_response'}
                />
              }
            />
          </div>

          <div className={styles.buttons}>
            <span style={{ marginRight: '20px' }}>
              <Button text="FIRST" color="secondary" icon="fal fa-long-arrow-left" onClick={handleFirst} disabled={isFirst ? true : false} />
            </span>

            <Button text="BACK" color="secondary" icon="fal fa-long-arrow-left" onClick={isFirst ? null : () => handleBack()} disabled={isFirst ? true : false} />

            {isLast ? (
              <span style={{ marginLeft: '20px' }}>{!isFinalized && <Button onClick={() => handleNext()} text={'SAVE'} />}</span>
            ) : (
              <span style={{ marginLeft: '20px' }}>
                <Button onClick={() => handleNext()} text={'CONTINUE'} iconEnd="fal fa-long-arrow-right" />
              </span>
            )}

            {!isFinalized && (
              <Button
                className={`${styles.finalize} ${isFinalized ? styles.finalized : ''}`}
                color="danger"
                onClick={isFinalizable ? handleFinalizeOpen : false}
                disabled={!isFinalizable || !isLaunched || state.configuration.status === '8'}
                text="FINALIZE"
              />
            )}

            {isFinalized && <Button className={styles.finalize} color="danger" onClick={handleClose} text="CLOSE" />}
          </div>
        </Container>
      ) : (
        <Spinner />
      )}
      {showModal ? (
        <Modal className={styles['modal']} setShowModal={setShowModal}>
          <div className={styles['modal-body']}>
            <div>
              <p className={styles['modal-heading']}>Finalize Audit</p>
            </div>

            <div className={styles['modal-content']}>
              <div>
                <p>Once you finalize the audit you can no longer change your answers.</p>
                <br />
                <b className={styles['confirmation-modal-warning']}>This action cannot be undone</b>
              </div>
            </div>
          </div>

          <div className={styles['modal-footer']}>
            <Button className={styles.button} text="CANCEL" color="secondary" onClick={() => setShowModal(false)} />
            <Button
              className={styles.button}
              text="FINALIZE"
              color="primary"
              onClick={() => {
                completeAuditAction({ auditId });
                setShowModal(false);
                navigate('/achieve/audits');
              }}
            />
          </div>
        </Modal>
      ) : (
        ''
      )}
    </AppLayoutWrapFull>
  );
};

const mapStateToProps = (state) => ({
  UsersReducer: state.UsersReducer,
  AuditReducer: state.AuditReducer,
});

const mapDispatchToProps = {
  loadAuditAction,
  completeAuditAction,
  loadUsersAction,
  updateAuditAction,
  verifyAuditAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Audit);
