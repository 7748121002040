import React, { useState, useEffect } from 'react';
import Modal from 'components/Modal/Modal';
import FormElement from 'components/FormElement/FormElement';
import { Button } from 'components/Button/Button';
import { country_list } from 'helpers/utils';
import styles from './SiteDetailsModal.module.scss';

function SiteDetailsModal(props) {
  const { formDataOrg, setFormDataOrg, item, setShowModal } = props;

  const [formData, setFormData] = useState({
    name: '',
    address_1: '',
    address_2: '',
    city: '',
    region: '',
    postal_code: '',
    country_code: 235,
  });

  const [currentSiteIndex, setCurrentSiteIndex] = useState(-1);
  const [stateErrors, setStateErrors] = useState({
    name: '',
  });

  useEffect(() => {
    const formDataCopyObj = JSON.parse(JSON.stringify(formDataOrg));
    const currentSiteIndexNum = formDataCopyObj.sites.findIndex((site) => item.id === site.id);
    setFormData({ ...formDataCopyObj.sites[currentSiteIndexNum] });
    setCurrentSiteIndex(currentSiteIndexNum);
  }, []);

  const handleChange = (e) => {
    e.persist();
    setFormData((s) => ({ ...s, [e.target.name]: e.target.value }));
    if (e.target.name === 'name') {
      setFormData((s) => ({ ...s, value: e.target.value }));
    }
  };

  const addModal = (e) => {
    if (formData.name !== '') {
      const formDataCopyObj = JSON.parse(JSON.stringify(formDataOrg));
      formDataCopyObj.sites[currentSiteIndex] = { ...formData };
      formDataCopyObj.sites[currentSiteIndex].isFilled = true;
      setFormDataOrg({ ...formDataCopyObj });
      setShowModal(false);
      return false;
    }
    if (formData.name === '') {
      setStateErrors((s) => ({
        ...s,
        name: 'Site name is required',
      }));
    } else {
      setStateErrors((s) => ({
        ...s,
        name: '',
      }));
    }

    return false;
  };

  return (
    <Modal className={styles['modal-site-details']} setShowModal={setShowModal}>
      <Modal.Heading>Add Site Details</Modal.Heading>

      <Modal.Content>
        <FormElement labelText="SITE" htmlFor="name" errorMessage={stateErrors.name}>
          <input placeholder="Site Name" name="name" type="text" maxLength={70} value={formData.name} onChange={handleChange} />
        </FormElement>

        <FormElement labelText="ADDRESS" htmlFor="address_1">
          <input placeholder="Company Address Line 1" type="text" name="address_1" id="address_1" value={formData.address_1} onChange={(e) => handleChange(e)} />
        </FormElement>

        <FormElement htmlFor="address_2">
          <input placeholder="Company Address Line 2" type="text" name="address_2" id="address_2" value={formData.address_2} onChange={(e) => handleChange(e)} />
        </FormElement>

        <FormElement labelText="CITY" htmlFor="city">
          <input placeholder="City" id="city" name="city" type="text" value={formData.city} onChange={(e) => handleChange(e)} />
        </FormElement>

        <FormElement labelText="REGION/STATE" htmlFor="region">
          <input placeholder="Region" name="region" id="region" type="text" value={formData.region} onChange={(e) => handleChange(e)} />
        </FormElement>

        <FormElement labelText="COUNTRY" htmlFor="country">
          <div className={styles.input_holder}>
            <select className={styles.input} name="country_code" value={formData.country_code} onChange={handleChange}>
              {country_list.map((item) => (
                <option key={item.id} data-name="country_code" data-value={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
        </FormElement>

        <FormElement labelText="POSTAL CODE" htmlFor="postal_code">
          <input placeholder="Postal Code" type="text" id="postal_code" name="postal_code" value={formData.postal_code} onChange={(e) => handleChange(e)} />
        </FormElement>
      </Modal.Content>

      <Modal.Footer>
        <Button text="CANCEL" color="secondary" onClick={() => props.setShowModal(false)} />
        <Button text="ADD" className={styles.create} onClick={(e) => addModal(e)} />
      </Modal.Footer>
    </Modal>
  );
}

export default SiteDetailsModal;
