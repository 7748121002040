import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import SpinnerIcon from './SpinnerIcon';

import styles from './Spinner.module.scss';

function Spinner() {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timeout = window.setTimeout(() => {
      setShow(true);
    }, 100);
    return () => {
      window.clearTimeout(timeout);
    };
  }, []);

  const style = classNames(styles.spinner, show && styles.visible);

  return (
    <div className={styles['spinner-wrapper']} data-testid="spinner">
      {show && <SpinnerIcon style={style} color="#29AAE2" />}
    </div>
  );
}

export default Spinner;
