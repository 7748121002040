import React from 'react';

import styles from './InputAdornment.module.scss';

/**
 * @param {String} className - additional className to add
 * @param {String} iconStart - icon to add at the start
 * @param {String} iconEnd - icon to add at the end
 */
const InputAdornment = (props) => {
  return (
    <div className={styles['input-adornment']}>
      {props.iconStart ? <i className={`${props.iconStart} ${styles['icon-start']}`} /> : null}
      {props.children}
      {props.iconEnd ? <i className={`${props.iconEnd} ${styles['icon-end']}`} /> : null}
    </div>
  );
};

export default InputAdornment;
