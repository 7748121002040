import ColVisibilitySelector from 'components/DataTable/ColVisibilitySelector';
import ItemActionsMenu from 'components/DataTable/ItemActionsMenu';
import React from 'react';
import Checkbox from 'components/Checkbox/Checkbox';
import moment from 'moment';
import Button from 'components/Button/Button';
import { getStatusButtonColor } from 'helpers/utils';

export default {
  TABLE: {
    className: 'data-table-training',
    initialSortKey: 'assigned',
    initialSortOrder: 'desc',
  },

  COLUMN: [
    {
      label: 'Row Selector',
      key: 'row-selector',
      header: ({ customTableProps }) => {
        const isSelectedAll =
          customTableProps.selectedRows &&
          customTableProps.selectedRows.length &&
          customTableProps.rowsData &&
          customTableProps.rowsData.length &&
          customTableProps.selectedRows.length === customTableProps.rowsData.length
            ? true
            : false;
        return <Checkbox checked={isSelectedAll} onChange={() => customTableProps.doSelectAll()} name="row-selector" />;
      },
      className: 'col-row-selector',
      format: ({ rowData, customTableProps }) => {
        const isSelected =
          customTableProps.selectedRows &&
          customTableProps.selectedRows.length &&
          customTableProps.selectedRows.some((checkbox) => {
            return checkbox.id === rowData.id;
          })
            ? true
            : false;
        return (
          <Checkbox
            disabled={rowData.configuration.status === '6' ? true : false}
            checked={rowData.configuration.status === '6' ? false : isSelected}
            onChange={() => customTableProps.onRowSelect({ rowData })}
            name="row-selector"
          />
        );
      },
      canBeHidden: false,
    },
    {
      label: 'Name',
      header: 'NAME',
      key: 'name',
      sortable: true,
      className: 'title',
      format: ({ rowData, customTableProps }) => {
        return (
          <span>
            {rowData['folder_name']} {rowData['has_quiz'] === 't' && '(Quiz)'}
          </span>
        );
      },
    },
    {
      label: 'Program',
      header: 'PROGRAM',
      key: 'regulatory_act',
      sortable: true,
      className: 'regulatory_act',
      format: ({ rowData }) => {
        return <span>{rowData['regulatory_act']}</span>;
      },
    },
    {
      label: 'Role',
      header: 'ROLE',
      key: 'role_str',
      sortable: true,
      className: 'role_str',
      format: ({ rowData, customTableProps }) => {
        return <span>{rowData['role_str']}</span>;
      },
    },
    {
      label: "Users",
      header: "USERS",
      key: "users",
      sortable: true,
      canBeHidden: true,
      className: "users",
      format: ({ rowData }) => {
          if (rowData["users"]?.length === 0) {
            // All users selected
            return <span>All Users</span>
          } else {
            // Individual users selected
            return <span>{rowData["users"]?.join(', ')?.substring(0, 30)}...</span>;
          }
      }
    },
    {
      label: 'Assigned',
      header: 'ASSIGNED',
      key: 'assigned',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{rowData['assigned'] ? moment(rowData['assigned']).format('MM/DD/YYYY') : '-'}</span>;
      },
    },
    {
      label: 'Launch',
      header: 'LAUNCH DATE',
      key: 'launch_date',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{rowData['launch_date'] ? moment(rowData['launch_date']).format('MM/DD/YYYY') : '-'}</span>;
      },
    },
    {
      label: 'Due',
      header: 'DUE DATE',
      key: 'due',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{rowData['due'] ? moment(rowData['due']).format('MM/DD/YYYY') : '-'}</span>;
      },
    },
    {
      label: 'Site',
      header: 'SITE',
      key: 'siteName',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{rowData.siteName || 'All Sites'}</span>;
      },
    },
    {
      label: 'Frequency',
      header: 'FREQUENCY',
      key: 'frequency_label',
      sortable: true,
      className: 'frequency',
      canBeHidden: true,
      format: ({ rowData, customTableProps }) => {
        return <span>{rowData.frequency_label}</span>;
      },
    },
    {
      label: 'Status',
      header: 'STATUS',
      key: 'status',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData, customTableProps }) => {
        const status = rowData.configuration && rowData.configuration.status_label;
        const color = getStatusButtonColor(status, ['Completed'], ['Not finalized', 'Error on launch', 'Error on reset', 'Closed incomplete']);
        return (
          <span>
            <Button cursorDefault={customTableProps.cursorDefault} color={color} size="md" text={status} onClick={() => null} />
          </span>
        );
      },
    },
    {
      label: 'Actions',
      key: 'actions',
      format: ({ rowData, customTableProps }) => {
        const actionMenuDropDown = React.createRef();
        return (
          <ItemActionsMenu
            ref={actionMenuDropDown}
            actions={(() => {
              return [
                customTableProps.handleComplete &&
                  rowData.deactivated !== 't' && {
                    label: 'Mark Complete',
                    action: () => {
                      if (actionMenuDropDown && actionMenuDropDown.current) {
                        actionMenuDropDown.current.handleMenuClose();
                      }
                      customTableProps.handleComplete(rowData);
                    },
                  },
                customTableProps.handleIncomplete &&
                  rowData.deactivated !== 't' && {
                    label: 'Mark Incomplete',
                    action: () => {
                      if (actionMenuDropDown && actionMenuDropDown.current) {
                        actionMenuDropDown.current.handleMenuClose();
                      }
                      customTableProps.handleIncomplete(rowData);
                    },
                  },
                customTableProps.handleSuspend &&
                  rowData.suspended !== 't' && {
                    label: 'Suspend',
                    action: () => {
                      if (actionMenuDropDown && actionMenuDropDown.current) {
                        actionMenuDropDown.current.handleMenuClose();
                      }
                      customTableProps.handleSuspend(rowData);
                    },
                  },
                customTableProps.handleUnsuspend &&
                  rowData.suspended === 't' && {
                    label: 'Unsuspend',
                    action: () => {
                      if (actionMenuDropDown && actionMenuDropDown.current) {
                        actionMenuDropDown.current.handleMenuClose();
                      }
                      customTableProps.handleUnsuspend(rowData);
                    },
                  },
                customTableProps.handleDelete &&
                  rowData.deactivated !== 't' && {
                    label: 'Delete',
                    action: () => {
                      if (actionMenuDropDown && actionMenuDropDown.current) {
                        actionMenuDropDown.current.handleMenuClose();
                      }
                      customTableProps.handleDelete(rowData);
                    },
                  },
              ];
            })()}
          />
        );
      },
      className: 'col-actions',
      header: ({ customTableProps }) => {
        return (
          <ColVisibilitySelector
            colDefs={customTableProps.colDefs}
            visibleColumnKeys={customTableProps.visibleColumnKeys}
            onChangeVisibility={(colKey) => customTableProps.onChangeVisibility(colKey)}
          />
        );
      },
    },
  ],
};
