import React from 'react';

function RowsPerPageSelector(props) {
  return (
    <div className="rows-per-page-selector">
      <span className="label">Rows per page: </span>
      <select value={props.rowsPerPage} onChange={(e) => props.onChangeRowsPerPage(Number.parseInt(e.target.value, 10))}>
        {props.rowsPerPageOpts.map((opt) => (
          <option key={`${opt}-rppk`} value={opt}>
            {opt}
          </option>
        ))}
      </select>
    </div>
  );
}

export default RowsPerPageSelector;
