import { call, put, takeLatest, delay } from 'redux-saga/effects';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { actionApiCall } from 'helpers/api/ApiCalls';
import { push } from 'redux-first-history';
import * as sagaConstants from 'helpers/constants/SagasConstants';

/**
 * Login user from login form submission.
 */
function* submitUserForgotPasswordSaga({ payload }) {
  try {
    const apiResponse = yield call(actionApiCall, {
      actionType: ActionTypeConstants.SUBMIT_USER_FORGOT_PASSWORD,
      data: { email: payload.email },
    });

    if (apiResponse && apiResponse.res && apiResponse.res.data.response === 200) {
      yield put({
        type: ActionTypeConstants.SUBMIT_USER_FORGOT_PASSWORD_SUCCESS,
      });
      yield put(push('/login/forgot-password-success'));
    } else {
      yield put({
        type: ActionTypeConstants.SUBMIT_USER_FORGOT_PASSWORD_FAILURE,
      });
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: apiResponse.res.data.message || `${sagaConstants.ISSUE_OCCURED} reseting the password`,
          color: 'danger',
        },
      });
    }
  } catch (error) {
    // console.log(error);
    // console.log('error in submitUserLoginFormSaga')
    yield put({
      type: ActionTypeConstants.SUBMIT_USER_FORGOT_PASSWORD_FAILURE,
    });
    yield put({
      type: ActionTypeConstants.SHOW_ALERT,
      payload: {
        message: `${sagaConstants.SERVER_ERROR}`,
        color: 'danger',
      },
    });
    yield delay(10000);
    yield put({ type: ActionTypeConstants.HIDE_ALERT });
  }
}

export default function* () {
  yield takeLatest(ActionTypeConstants.SUBMIT_USER_FORGOT_PASSWORD, submitUserForgotPasswordSaga);
}
