import React, { useState } from 'react';

import logo from 'assets/img/cg-logo-large.png';
import { Button } from 'components/Button/Button';
import { submitUserForgotPasswordAction } from 'store/actions/Actions';

import FormElement from 'components/FormElement/FormElement';
import InputAdornment from 'components/InputAdornment/InputAdornment';
import { isEmailValid } from 'helpers/utils';
import { useAppDispatch } from 'hooks';

import WelcomePanel from '../components/WelcomePanel/WelcomePanel';

import styles from './ForgotPassword.module.scss';

function ForgotPassword() {
  const dispatch = useAppDispatch();
  const [stateErrors, setStateErrors] = useState({
    user: undefined,
  });
  const handleForgot = () => {
    const email = (document.getElementById('user-login') as HTMLInputElement).value || '';
    if (!isEmailValid(email)) {
      setStateErrors((s) => ({ ...s, user: 'Invalid e-mail address!' }));
    } else {
      dispatch(submitUserForgotPasswordAction({ email }));
    }
  };

  return (
    <WelcomePanel>
      <div className={styles['login-panel-heading']}>
        <img className={styles.logo} src={logo} alt="" />
      </div>

      <div className={styles['login-panel-content']}>
        <p className={styles.welcome}>Enter your e-mail address!</p>

        <FormElement htmlFor="user" errorMessage={stateErrors.user}>
          <InputAdornment iconStart="fal fa-at">
            <input
              type="text"
              placeholder="E-mail"
              name="user"
              id="user-login"
              onChange={() => {
                setStateErrors((s) => ({
                  ...s,
                  user: '',
                }));
              }}
            />
          </InputAdornment>
        </FormElement>
      </div>

      <div className={styles['login-panel-footer']}>
        <Button text="SEND EMAIL" onClick={handleForgot} />
      </div>
    </WelcomePanel>
  );
}

export default ForgotPassword;
