import ActionStatusConstants from 'helpers/ActionStatusConstants';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { LOCATION_CHANGE } from 'redux-first-history';

const initialState = {
  status: ActionStatusConstants.INITIAL,
  message: undefined,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypeConstants.LOAD_TRAINING_DOCUMENTS: {
      return {
        ...state,
        status: ActionStatusConstants.ISBUSY,
      };
    }
    case ActionTypeConstants.LOAD_TRAINING_DOCUMENTS_SUCCESS: {
      return {
        ...state,
        'training-documents': payload['training-documents'],
        status: ActionStatusConstants.SUCCESS,
        message: payload?.message,
      };
    }
    case ActionTypeConstants.LOAD_TRAINING_DOCUMENTS_FAILURE: {
      return {
        ...state,
        status: ActionStatusConstants.FAILURE,
        message: payload?.message,
      };
    }
    case ActionTypeConstants.LOAD_TRAINING_MEDIA: {
      return {
        ...state,
        status: ActionStatusConstants.ISBUSY,
      };
    }
    case ActionTypeConstants.LOAD_TRAINING_MEDIA_SUCCESS: {
      return {
        ...state,
        'training-media': payload['training-media'],
        status: ActionStatusConstants.SUCCESS,
        message: payload?.message,
      };
    }
    case ActionTypeConstants.LOAD_TRAINING_MEDIA_FAILURE: {
      return {
        ...state,
        status: ActionStatusConstants.FAILURE,
        message: payload?.message,
      };
    }
    case ActionTypeConstants.LOAD_TRAININGS: {
      return {
        ...state,
        status: ActionStatusConstants.ISBUSY,
        message: payload?.message,
      };
    }
    case ActionTypeConstants.LOAD_TRAININGS_SUCCESS: {
      return {
        ...state,
        training: payload.training,
        status: ActionStatusConstants.SUCCESS,
        message: payload?.message,
      };
    }
    case ActionTypeConstants.LOAD_TRAININGS_FAILURE: {
      return {
        ...state,
        status: ActionStatusConstants.FAILURE,
        message: payload?.message,
      };
    }
    case ActionTypeConstants.LOAD_TRAINING_COURSES: {
      return {
        ...state,
        status: ActionStatusConstants.ISBUSY,
      };
    }
    case ActionTypeConstants.LOAD_TRAINING_COURSES_SUCCESS: {
      return {
        ...state,
        training_courses: payload.training_courses,
        status: ActionStatusConstants.SUCCESS,
        message: payload?.message,
      };
    }
    case ActionTypeConstants.LOAD_TRAINING_COURSES_FAILURE: {
      return {
        ...state,
        status: ActionStatusConstants.FAILURE,
        message: payload?.message,
      };
    }
    case LOCATION_CHANGE: {
      return {
        ...initialState,
      };
    }
    default:
  }
  return state;
};
