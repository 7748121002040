import { call, put, takeLatest, delay } from 'redux-saga/effects';

import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { actionApiCall } from 'helpers/api/ApiCalls';

function* deleteTaskSaga({ payload }) {
  try {
    if (payload.length === 0) {
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: 'You need to choose task first!',
          color: 'danger',
        },
      });
      yield delay(10000);
      yield put({ type: ActionTypeConstants.HIDE_ALERT });
      return;
    }
    const apiResponse = yield call(actionApiCall, {
      actionType: ActionTypeConstants.DELETE_TASK,
      data: payload,
    });

    if (apiResponse) {
      yield put({ type: ActionTypeConstants.DELETE_TASK_SUCCESS });
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: `Task${payload.length > 1 ? 's' : ''} deleted!`,
          color: 'success',
        },
      });
      if (payload[0].filter) {
        yield put({ type: ActionTypeConstants.LOAD_TASKS_FILTERED, payload: { filter: payload[0].filter } });
      } else {
        yield put({ type: ActionTypeConstants.LOAD_TASKS });
      }
      yield delay(10000);
      yield put({ type: ActionTypeConstants.HIDE_ALERT });
    } else {
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: `An issue occured - Task${payload.length > 0 && 's'} not deleted!`,
          color: 'danger',
        },
      });
      yield delay(10000);
      yield put({ type: ActionTypeConstants.HIDE_ALERT });
    }
  } catch (err) {
    yield put({
      type: ActionTypeConstants.SHOW_ALERT,
      payload: {
        message: `An issue occured - Task${payload.length > 0 && 's'} not deleted!`,
        color: 'danger',
      },
    });
    yield delay(10000);
    yield put({ type: ActionTypeConstants.HIDE_ALERT });
  }
}

export default function* () {
  yield takeLatest(ActionTypeConstants.DELETE_TASK, deleteTaskSaga);
}
