import React, { useState } from 'react';
import { connect } from 'react-redux';

import { useConfirmModal } from 'hooks';
import FormElement from 'components/FormElement/FormElement';
import Button from 'components/Button/Button';
import TableDefEvidence from 'table-defs/TableDefEvidence';
import FullDataTable from 'components/DataTable/FullDataTable';

import UploadDocument from 'components/UploadDocument/UploadDocument';

import { createEvidenceAction, deleteEvidenceAction, downloadEvidenceAction } from 'store/actions/Actions';

import styles from './EditEvidence.module.scss';

const EditEvidence = (props) => {
  const {
    files,
    responseId,
    auditId,
    taskId,
    requestType,
    CreateEvidenceReducer,
    createEvidenceAction,
    deleteEvidenceAction,
    downloadEvidenceAction,
    UserOrgSiteReducer,
    isFinalized,
    AuthReducer,
  } = props;

  const [showConfirmModal, setShowConfirmModal, renderConfirmModal] = useConfirmModal();

  const [state, setState] = useState({
    file: '',
    name: '',
  });

  const addEvidence = async () => {
    try {
      createEvidenceAction({
        entity_type: requestType,
        entity_id: responseId,
        auditId: auditId,
        taskId: taskId,
        file: {
          file: state['file'],
          name: state['name'],
        },
      });
      setState({
        file: '',
        name: '',
      });
    } catch (error) {
      console.log(error);
    }
  };

  const deleteEvidence = (payload) => {
    try {
      setShowConfirmModal({
        title: 'Delete evidence file',
        question: `You're about to permanently delete evidence file "${payload.filename}"! Are you sure?`,
        confirmAction: () =>
          deleteEvidenceAction({
            id: payload.id,
            entity_id: responseId,
            entity_type: requestType,
            auditId,
          }),
      });
    } catch (error) {
      console.log(error);
    }
  };

  const downloadEvidence = (payload) => {
    try {
      downloadEvidenceAction({ hash: payload.hash });
    } catch (error) {
      console.log(error);
    }
  };

  // const is_officer = (UserOrgSiteReducer.selected && UserOrgSiteReducer.selected.is_officer) || false;
  const is_super = (UserOrgSiteReducer.selected && UserOrgSiteReducer.selected.is_super) || false;
  const is_coach = (UserOrgSiteReducer.selected && UserOrgSiteReducer.selected.is_coach) || false;
  const currentUserId = AuthReducer.userData.id || false;

  return (
    <div className={`${styles['add-evidence']} ${props.className || ''}`}>
      {showConfirmModal && renderConfirmModal(showConfirmModal, setShowConfirmModal)}

      <div className={styles['add-evidence-data']}>
        <>
          {!isFinalized && (
            <div className={styles['holder']}>
              <FormElement labelText="DOCUMENTS" htmlFor="documents">
                <UploadDocument state={state} setState={setState} formats={['jpg', 'jpeg', 'png', 'gif', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'pdf']} />
              </FormElement>

              <Button
                className={styles.button}
                text="UPLOAD EVIDENCE"
                icon={CreateEvidenceReducer.status === 'ISBUSY' ? 'fad fa-spinner-third fa-spin' : 'fad fa-upload'}
                disabled={state['file'] === '' ? true : false}
                onClick={addEvidence}
              />
            </div>
          )}

          <br />
        </>

        <FullDataTable
          className={styles['add-evidence-table']}
          rowsData={files}
          definitions={TableDefEvidence}
          customTableProps={{
            deleteEvidence,
            downloadEvidence,
            is_coach,
            is_super,
            currentUserId,
            isFinalized,
          }}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  EvidenceReducer: state.EvidenceReducer,
  CreateEvidenceReducer: state.CreateEvidenceReducer,
  UserOrgSiteReducer: state.UserOrgSiteReducer,
  AuthReducer: state.AuthReducer,
});

const mapDispatchToProps = {
  createEvidenceAction,
  deleteEvidenceAction,
  downloadEvidenceAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditEvidence);
