import React from 'react';
import AppLayoutWrapFull from 'components/AppLayoutWrapFull';
import styles from './NotFound.module.scss';
import MainLayout from 'v2/layouts/MainLayout/MainLayout';
import { useAppSelector } from 'hooks';

function NotFound() {
  const UserOrgSiteReducer = useAppSelector((state) => state.UserOrgSiteReducer);
  const { ui_version } = UserOrgSiteReducer.selected;
  const view = () => (
    ui_version === "1" ? <MainLayout>
      <div className={styles.error}>
        <p>Page not found</p>
      </div>
    </MainLayout>
    :
    <AppLayoutWrapFull>
      <div className={styles.error}>
        <p>Page not found</p>
      </div>
    </AppLayoutWrapFull>
  );
  return view();
}

export default NotFound;
