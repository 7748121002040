import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'hooks';

import Container from 'components/Container/Container';
import FormElement from 'components/FormElement/FormElement';
import { Button } from 'components/Button/Button';
import PasswordValidation from 'components/PasswordValidation/PasswordValidation';

import { submitUserRegisterFormAction, getUserByKeyAction } from 'store/actions/Actions';
import { states, showPassword, isPasswordValid } from 'helpers/utils';

import logoImg from 'assets/img/cg-logo.png';
import styles from './UserRegister.module.scss';

const togglePasswordVisibility = (e, id, isPwdVisible, toggleSetter) => {
  e.preventDefault();
  toggleSetter(isPwdVisible);
  showPassword(id, isPwdVisible);
};

function Register() {
  const { url } = useParams();
  const dispatch = useAppDispatch();
  const UserLoginFormReducer = useAppSelector((state) => state.UserLoginFormReducer);
  const [state, setState] = useState({
    active: 't',
    privacy: false,
    security: false,
    status: 'active',
    firstname: '',
    lastname: '',
    email: '',
    address_1: '',
    address_2: '',
    country_name: '',
    country_code: '',
    city: '',
    state_code: '',
    state_name: '',
    postcode: '',
    password: '',
    'confirm-password': '',
    phone: '',
    showHints: true,
  });
  const [canSubmit, setCanSubmit] = useState(false);
  const [stateErrors, setStateErrors] = useState({
    email: '',
    firstname: '',
    lastname: '',
    password: '',
    'confirm-password': '',
  });
  const [togglePasswordIcon, setTogglePasswordIcon] = useState(false);
  const [togglePasswordIconConfirm, setTogglePasswordIconConfirm] = useState(false);

  useEffect(() => {
    dispatch(getUserByKeyAction({ url_key: url }));
  }, [url]);

  useEffect(() => {
    if (UserLoginFormReducer?.data) {
      const stateTmp = states.find((c) => c.abbrev === UserLoginFormReducer.data.state) || {
        abbrev: undefined,
        name: undefined,
      };
      setState((s) => ({
        ...s,
        firstname: UserLoginFormReducer?.data?.firstname || '',
        lastname: UserLoginFormReducer?.data?.lastname || '',
        email: UserLoginFormReducer?.data?.email || '',
        address_1: UserLoginFormReducer?.data?.address_1 || '',
        address_2: UserLoginFormReducer?.data?.address_2 || '',
        city: UserLoginFormReducer?.data?.city || '',
        state_code: stateTmp.abbrev || '',
        state_name: stateTmp.name || UserLoginFormReducer?.data?.state || '',
        country_code: UserLoginFormReducer?.data?.country_code || '',
        country_name: UserLoginFormReducer?.data?.country_name || '',
        postcode: UserLoginFormReducer?.data?.postcode || '',
        phone: UserLoginFormReducer?.data?.phone || '',
        url_key: url,
      }));
    }
  }, [UserLoginFormReducer?.data, url]);

  // handle error messages and allow to submit
  useEffect(() => {
    if (state.firstname !== '' && state.lastname !== '' && isPasswordValid(state.password) && state.password === state['confirm-password']) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
    setStateErrors({
      ...stateErrors,
      password: !isPasswordValid(state.password) ? 'The Password is not valid' : '',
      'confirm-password': state.password !== state['confirm-password'] ? "Passwords don't match" : '',
      firstname: state.firstname === '' ? 'First name is required' : '',
      lastname: state.lastname === '' ? 'Last name is required' : '',
    });
  }, [state]);

  const handleChange = (inputName) => (e) => {
    e.persist();
    setState((s) => ({
      ...s,
      [inputName]: e.target.value,
    }));
  };

  const handleSubmitRegistrationForm = (e) => {
    e.preventDefault();
    window.scrollTo({ top: 0, behavior: 'smooth' });
    dispatch(
      submitUserRegisterFormAction({
        ...state,
      })
    );
  };

  return (
    <div className={styles.register}>
      <Container>
        <form>
          <img src={logoImg} alt="The Guard" />
          <div className={styles.holder}>
            <div className={styles.container}>
              <h1>Welcome to The Guard!</h1>
              <h3>Your new account is ready!</h3>
              <p className={styles.text}>Choose a password and complete registration.</p>

              <FormElement className={styles.form_element} labelText="EMAIL" htmlFor="email" errorMessage={stateErrors.email}>
                <input name="email" type="email" disabled placeholder="Enter your email address" value={state.email} />
              </FormElement>

              <FormElement className={styles.form_element} labelText="FIRST NAME" htmlFor="firstname" errorMessage={stateErrors.firstname}>
                <input autoComplete="none" name="firstname" type="text" placeholder="Enter your first name" value={state.firstname} onChange={handleChange('firstname')} />
              </FormElement>

              <FormElement className={styles.form_element} labelText="LAST NAME" htmlFor="lastname" errorMessage={stateErrors.lastname}>
                <input
                  // autoComplete="none"
                  name="lastname"
                  type="text"
                  placeholder="Enter your last name"
                  value={state.lastname}
                  onChange={handleChange('lastname')}
                />
              </FormElement>

              <FormElement className={styles.form_element} labelText="PASSWORD" htmlFor="password">
                <input
                  autoComplete="none"
                  name="password"
                  type="password"
                  placeholder="Enter your password"
                  value={state.password}
                  className={styles.password}
                  onChange={handleChange('password')}
                  id="pass-register-user"
                />
                <span className={styles['show-password']}>
                  <button
                    type="button"
                    className={styles['password-button']}
                    onClick={(e) => togglePasswordVisibility(e, 'pass-register-user', !togglePasswordIcon, setTogglePasswordIcon)}
                  >
                    <i className={`fas ${togglePasswordIcon ? 'fa-eye-slash' : 'fa-eye'}`} title={`${togglePasswordIcon ? 'hide' : 'show'}  password`} />
                  </button>
                </span>
              </FormElement>

              {!isPasswordValid(state.password) ? <PasswordValidation password={state.password} /> : ''}

              <FormElement className={styles.form_element} labelText="CONFIRM PASSWORD" htmlFor="confirm-password" errorMessage={stateErrors['confirm-password']}>
                <input
                  name="confirm-password"
                  type="password"
                  placeholder="Confirm your password"
                  value={state['confirm-password']}
                  onChange={handleChange('confirm-password')}
                  className={styles.password}
                  id="pass-register-confirm"
                />
                <span className={styles['show-password']}>
                  <button
                    type="button"
                    className={styles['password-button']}
                    onClick={(e) => togglePasswordVisibility(e, 'pass-register-confirm', !togglePasswordIconConfirm, setTogglePasswordIconConfirm)}
                  >
                    <i className={`fas ${togglePasswordIconConfirm ? 'fa-eye-slash' : 'fa-eye'}`} title={`${togglePasswordIconConfirm ? 'hide' : 'show'}  password`} />
                  </button>
                </span>
              </FormElement>

              <div className={styles['submit-button-container']}>
                <Button disabled={!canSubmit} onClick={(e) => handleSubmitRegistrationForm(e)} text="COMPLETE REGISTRATION" />
              </div>
            </div>
          </div>
        </form>
      </Container>
    </div>
  );
}

export default Register;
