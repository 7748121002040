import { call, put, takeLatest, delay } from 'redux-saga/effects';

import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { actionApiCall } from 'helpers/api/ApiCalls';
import { EVIDENCE_AUDIT_RESPONSE, EVIDENCE_INCIDENT, EVIDENCE_TASK } from 'helpers/constants/EvidenceConstants';
import * as sagaConstants from 'helpers/constants/SagasConstants';

function* deleteEvidenceSaga({ payload }) {
  try {
    const apiResponse = yield call(actionApiCall, {
      actionType: ActionTypeConstants.DELETE_EVIDENCE,
      data: payload,
    });
    if (apiResponse.res.data.response === 200) {
      yield put({ type: ActionTypeConstants.DELETE_EVIDENCE_SUCCESS });
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: `Evidence ${sagaConstants.SUCCESSFULLY_DELETED}`,
          color: 'success',
        },
      });
      switch (payload.entity_type) {
        case 'audit':
          yield put({
            type: ActionTypeConstants.LOAD_EVIDENCE_BY_AUDIT,
            payload: { id: payload.entity_id },
          });
          break;
        case 'cloud_asset':
          yield put({
            type: ActionTypeConstants.LOAD_EVIDENCE_BY_CLOUD_ASSET,
            payload: { id: payload.entity_id },
          });
          break;
        case 'inventory_device':
          yield put({
            type: ActionTypeConstants.LOAD_EVIDENCE_BY_INVENTORY,
            payload: { id: payload.entity_id },
          });
          break;
        case EVIDENCE_TASK:
          yield put({
            type: ActionTypeConstants.LOAD_ONE_TASK,
            payload: { id: payload.entity_id },
          });
          break;
        case EVIDENCE_INCIDENT:
          yield put({
            type: ActionTypeConstants.LOAD_EVIDENCE_BY_INCIDENT,
            payload: { id: payload.entity_id },
          });
          break;
        case EVIDENCE_AUDIT_RESPONSE:
          if (payload.taskId) {
            yield put({
              type: ActionTypeConstants.LOAD_ONE_TASK,
              payload: { id: payload.taskId },
            });
          } else {
            yield put({
              type: ActionTypeConstants.LOAD_AUDIT,
              payload: payload.auditId,
            });
          }
          break;
        default:
          yield;
      }
      yield delay(10000);
      yield put({ type: ActionTypeConstants.HIDE_ALERT });
    } else {
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: (apiResponse && apiResponse.res && apiResponse.res.data && apiResponse.res.data.message) || `${sagaConstants.ISSUE_OCCURED} deleting evidence`,
          color: 'danger',
        },
      });
      yield delay(10000);
      yield put({ type: ActionTypeConstants.HIDE_ALERT });
    }
  } catch (err) {
    yield put({
      type: ActionTypeConstants.SHOW_ALERT,
      payload: {
        message: `${sagaConstants.SERVER_ERROR}`,
        color: 'danger',
      },
    });
    yield delay(10000);
    yield put({ type: ActionTypeConstants.HIDE_ALERT });
  }
}

export default function* () {
  yield takeLatest(ActionTypeConstants.DELETE_EVIDENCE, deleteEvidenceSaga);
}
