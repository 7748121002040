import ActionTypeConstants from './ActionTypeConstants';

// console.log('process.env', process.env);

/**
 * Backend API base URL.
 */
export const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
export const ONLY_OFFICE_JS = process.env.REACT_APP_ONLY_OFFICE_JS;
export const ONLY_OFFICE_CALLBACK_URL = process.env.REACT_APP_ONLY_OFFICE_CALLBACK_URL;
export const DOCUMENT_UPLOAD_URL = process.env.REACT_APP_DOCUMENT_UPLOAD_URL;

/**
 * API HTTP methods
 */
export const METHODS = {
  GET: 'GET',
  POST: 'POST',
};

/**
 * API URL definitions.
 *
 * KEY is the Action Type constantf
 * URL: Endpoint URL
 * METHOD: Method constant
 * IS_AUTH: Whether endpoint requires authentication
 */
export const URLS = {
  [ActionTypeConstants.LOAD_AFFILIATES]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'affiliates',
    METHOD: METHODS.POST,
    API_ACTION: 'read',
    IS_AUTH: true,
    RESPONSE_TYPE: 'json',
  },
  [ActionTypeConstants.SUBMIT_ADD_AFFILIATE_FORM]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'affiliates',
    METHOD: METHODS.POST,
    API_ACTION: 'create',
    IS_AUTH: true,
  },
  [ActionTypeConstants.LOAD_EDIT_AFFILIATE_FORM]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'affiliates',
    METHOD: METHODS.POST,
    API_ACTION: 'read',
    IS_AUTH: true,
  },
  [ActionTypeConstants.SUBMIT_EDIT_AFFILIATE_FORM]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'affiliates',
    METHOD: METHODS.POST,
    API_ACTION: 'update',
    IS_AUTH: true,
  },
  [ActionTypeConstants.DELETE_AFFILIATE]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'affiliates',
    METHOD: METHODS.POST,
    API_ACTION: 'delete',
    IS_AUTH: true,
  },
  [ActionTypeConstants.NOTIFY_AFFILIATE]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'affiliates',
    METHOD: METHODS.POST,
    API_ACTION: 'notify',
    IS_AUTH: true,
  },
  [ActionTypeConstants.SEND_VENDOR_AUDIT_AFFILIATE]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'affiliates',
    METHOD: METHODS.POST,
    API_ACTION: 'send_audit',
    IS_AUTH: true,
  },
  [ActionTypeConstants.SAVE_VENDOR_AUDIT_AFFILIATE]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'affiliates',
    METHOD: METHODS.POST,
    API_ACTION: 'save_audit',
    IS_AUTH: true,
  },
  [ActionTypeConstants.READ_VENDOR_AUDIT_AFFILIATE]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'affiliates',
    METHOD: METHODS.POST,
    API_ACTION: 'read_audit',
    IS_AUTH: true,
  },
  [ActionTypeConstants.READ_VENDOR_AUDIT_TOKEN_AFFILIATE]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'affiliates',
    METHOD: METHODS.POST,
    API_ACTION: 'read_by_token',
    IS_AUTH: false,
  },
  [ActionTypeConstants.APPROVE_AFFILIATE]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'affiliates',
    METHOD: METHODS.POST,
    API_ACTION: 'approve',
    IS_AUTH: true,
  },
  [ActionTypeConstants.DECLINE_AFFILIATE]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'affiliates',
    METHOD: METHODS.POST,
    API_ACTION: 'decline',
    IS_AUTH: true,
  },
  [ActionTypeConstants.SEND_BA_AGREEMENT_AFFILIATE]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'affiliates',
    METHOD: METHODS.POST,
    API_ACTION: 'agreement',
    IS_AUTH: true,
  },
  [ActionTypeConstants.SEND_CONF_AGREEMENT_AFFILIATE]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'affiliates',
    METHOD: METHODS.POST,
    API_ACTION: 'conf_agreement',
    IS_AUTH: true,
  },
  [ActionTypeConstants.BULK_UPLOAD_AFFILIATE]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'affiliates',
    METHOD: METHODS.POST,
    API_ACTION: 'bulk_upload',
    IS_AUTH: true,
  },
  [ActionTypeConstants.BULK_UPLOAD_SITE]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'sites',
    METHOD: METHODS.POST,
    API_ACTION: 'bulk_upload',
    IS_AUTH: true,
  },
  [ActionTypeConstants.BULK_UPLOAD_DEVICE]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'devices',
    METHOD: METHODS.POST,
    API_ACTION: 'bulk_upload',
    IS_AUTH: true,
  },
  [ActionTypeConstants.DELETE_AFFILIATE_DOCUMENTS]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'affiliates',
    METHOD: METHODS.POST,
    API_ACTION: 'delete_document',
    IS_AUTH: true,
  },
  [ActionTypeConstants.APPROVE_AFFILIATE_DOCUMENTS]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'affiliates',
    METHOD: METHODS.POST,
    API_ACTION: 'approve_document',
    IS_AUTH: true,
  },
  [ActionTypeConstants.UNAPPROVE_AFFILIATE_DOCUMENTS]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'affiliates',
    METHOD: METHODS.POST,
    API_ACTION: 'unapprove_document',
    IS_AUTH: true,
  },
  [ActionTypeConstants.READ_AFFILIATE_AGREEMENTS]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'affiliates',
    METHOD: METHODS.POST,
    API_ACTION: 'get_agreements',
    IS_AUTH: true,
  },
  [ActionTypeConstants.ADD_INCIDENT_NOTE]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'incidentnotes',
    METHOD: METHODS.POST,
    API_ACTION: 'create',
    IS_AUTH: true,
  },
  [ActionTypeConstants.DELETE_INCIDENT_NOTE]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'incidentnotes',
    METHOD: METHODS.POST,
    API_ACTION: 'delete',
    IS_AUTH: true,
  },
  [ActionTypeConstants.LOAD_INCIDENT_NOTES]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'incidentnotes',
    METHOD: METHODS.POST,
    API_ACTION: 'read',
    IS_AUTH: true,
  },
  [ActionTypeConstants.LOAD_INCIDENTS]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'incidents',
    METHOD: METHODS.POST,
    API_ACTION: 'read',
    IS_AUTH: true,
  },
  [ActionTypeConstants.LOAD_INCIDENT_TYPES]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'incidentType',
    METHOD: METHODS.POST,
    API_ACTION: 'read',
    IS_AUTH: true,
  },
  [ActionTypeConstants.ADD_NEW_INCIDENT]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'incidents',
    METHOD: METHODS.POST,
    API_ACTION: 'create',
    IS_AUTH: true,
  },
  [ActionTypeConstants.CREATE_AUDIT]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'audits',
    METHOD: METHODS.POST,
    API_ACTION: 'create',
    IS_AUTH: true,
  },
  [ActionTypeConstants.UPDATE_INCIDENT]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'incidents',
    METHOD: METHODS.POST,
    API_ACTION: 'update',
    IS_AUTH: true,
  },
  [ActionTypeConstants.LOAD_INCIDENT]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'incidents',
    METHOD: METHODS.POST,
    API_ACTION: 'read',
    IS_AUTH: true,
  },
  [ActionTypeConstants.DELETE_INCIDENT]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'incidents',
    METHOD: METHODS.POST,
    API_ACTION: 'delete',
    IS_AUTH: true,
  },
  [ActionTypeConstants.APPROVE_INCIDENT]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'incidents',
    METHOD: METHODS.POST,
    API_ACTION: 'approve',
    IS_AUTH: true,
  },
  [ActionTypeConstants.COMPLETE_INCIDENT]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'incidents',
    METHOD: METHODS.POST,
    API_ACTION: 'complete',
    IS_AUTH: true,
  },
  [ActionTypeConstants.INVESTIGATE_INCIDENT]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'incidents',
    METHOD: METHODS.POST,
    API_ACTION: 'under_investigation',
    IS_AUTH: true,
  },
  [ActionTypeConstants.NOTIFY_INCIDENT]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'incidents',
    METHOD: METHODS.POST,
    API_ACTION: 'notify',
    IS_AUTH: true,
  },
  [ActionTypeConstants.LOAD_AUDITS]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'audits',
    METHOD: METHODS.POST,
    API_ACTION: 'read',
    IS_AUTH: true,
  },
  [ActionTypeConstants.LOAD_ACTS]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'acts',
    METHOD: METHODS.POST,
    API_ACTION: 'read',
    IS_AUTH: true,
  },
  [ActionTypeConstants.LOAD_ACTS_ALL]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'acts',
    METHOD: METHODS.POST,
    API_ACTION: 'all',
    IS_AUTH: true,
  },
  [ActionTypeConstants.LOAD_SITES]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'sites',
    METHOD: METHODS.POST,
    API_ACTION: 'read',
    IS_AUTH: true,
  },
  [ActionTypeConstants.LOAD_AUDIT]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'audits',
    METHOD: METHODS.POST,
    API_ACTION: 'read',
    IS_AUTH: true,
  },
  [ActionTypeConstants.UPDATE_AUDIT]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'audits',
    METHOD: METHODS.POST,
    API_ACTION: 'update',
    IS_AUTH: true,
  },
  [ActionTypeConstants.EDIT_AUDIT]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'audits',
    METHOD: METHODS.POST,
    API_ACTION: 'update_configuration',
    IS_AUTH: true,
  },
  [ActionTypeConstants.LOAD_AUDIT]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'audits',
    METHOD: METHODS.POST,
    API_ACTION: 'read',
    IS_AUTH: true,
  },
  [ActionTypeConstants.VERIFY_AUDIT]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'audits',
    METHOD: METHODS.POST,
    API_ACTION: 'finalizable',
    IS_AUTH: true,
  },
  [ActionTypeConstants.COMPLETE_AUDIT]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'audits',
    METHOD: METHODS.POST,
    API_ACTION: 'finalize',
    IS_AUTH: true,
  },
  [ActionTypeConstants.REOPEN_AUDIT]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'audits',
    METHOD: METHODS.POST,
    API_ACTION: 'reopen',
    IS_AUTH: true,
  },
  [ActionTypeConstants.DELETE_AUDIT]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'audits',
    METHOD: METHODS.POST,
    API_ACTION: 'delete',
    IS_AUTH: true,
  },
  [ActionTypeConstants.NOTIFY_AUDIT]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'audits',
    METHOD: METHODS.POST,
    API_ACTION: 'notify',
    IS_AUTH: true,
  },
  [ActionTypeConstants.LOAD_ORG_SITE_DEPT]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'organizations',
    METHOD: METHODS.POST,
    API_ACTION: 'tree',
    IS_AUTH: true,
  },
  [ActionTypeConstants.UPDATE_ORG_MODULES]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'organizations',
    METHOD: METHODS.POST,
    API_ACTION: 'update_modules',
    IS_AUTH: true,
  },
  [ActionTypeConstants.UPDATE_ORG]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'organizations',
    METHOD: METHODS.POST,
    API_ACTION: 'update',
    IS_AUTH: true,
  },
  [ActionTypeConstants.UPDATE_ORG_ACTS]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'organizations',
    METHOD: METHODS.POST,
    API_ACTION: 'update_acts',
    IS_AUTH: true,
  },
  [ActionTypeConstants.UPDATE_SITE]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'sites',
    METHOD: METHODS.POST,
    API_ACTION: 'update',
    IS_AUTH: true,
  },
  [ActionTypeConstants.ACTIVATE_SITE]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'sites',
    METHOD: METHODS.POST,
    API_ACTION: 'reactivate',
    IS_AUTH: true,
  },
  [ActionTypeConstants.DEACTIVATE_SITE]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'sites',
    METHOD: METHODS.POST,
    API_ACTION: 'deactivate',
    IS_AUTH: true,
  },
  [ActionTypeConstants.CREATE_SITE]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'sites',
    METHOD: METHODS.POST,
    API_ACTION: 'create',
    IS_AUTH: true,
  },
  [ActionTypeConstants.CREATE_ORG]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'organizations',
    METHOD: METHODS.POST,
    API_ACTION: 'create',
    IS_AUTH: true,
  },
  [ActionTypeConstants.UPDATE_DEPT]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'departments',
    METHOD: METHODS.POST,
    API_ACTION: 'update',
    IS_AUTH: true,
  },
  [ActionTypeConstants.LOAD_DEPT]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'departments',
    METHOD: METHODS.POST,
    API_ACTION: 'read',
    IS_AUTH: true,
  },
  [ActionTypeConstants.ADD_NEW_DEPT]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'departments',
    METHOD: METHODS.POST,
    API_ACTION: 'create',
    IS_AUTH: true,
  },
  [ActionTypeConstants.DELETE_DEPT]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'departments',
    METHOD: METHODS.POST,
    API_ACTION: 'deactivate',
    IS_AUTH: true,
  },
  [ActionTypeConstants.LOAD_ONE_TASK]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'tasks',
    METHOD: METHODS.POST,
    API_ACTION: 'read',
    IS_AUTH: true,
  },
  [ActionTypeConstants.LOAD_TASKS]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'tasks',
    METHOD: METHODS.POST,
    API_ACTION: 'read',
    IS_AUTH: true,
  },
  [ActionTypeConstants.LOAD_TASKS_FILTERED]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'tasks',
    METHOD: METHODS.POST,
    API_ACTION: 'read_all',
    IS_AUTH: true,
  },
  [ActionTypeConstants.GET_TASKS_FOR_USER]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'tasks',
    METHOD: METHODS.POST,
    API_ACTION: 'get',
    IS_AUTH: true,
  },
  [ActionTypeConstants.DELETE_TASK]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'tasks',
    METHOD: METHODS.POST,
    API_ACTION: 'delete',
    IS_AUTH: true,
  },
  [ActionTypeConstants.NOTIFY_TASK]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'tasks',
    METHOD: METHODS.POST,
    API_ACTION: 'notify',
    IS_AUTH: true,
  },
  [ActionTypeConstants.CREATE_TASK]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'tasks',
    METHOD: METHODS.POST,
    API_ACTION: 'create',
    IS_AUTH: true,
  },
  [ActionTypeConstants.UPDATE_TASK]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'tasks',
    METHOD: METHODS.POST,
    API_ACTION: 'update',
    IS_AUTH: true,
  },
  [ActionTypeConstants.COMPLETE_TASK]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'tasks',
    METHOD: METHODS.POST,
    API_ACTION: 'complete',
    IS_AUTH: true,
  },
  [ActionTypeConstants.LOAD_DASHBOARD_TASKS]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'tasks',
    METHOD: METHODS.POST,
    API_ACTION: 'dashboard',
    IS_AUTH: true,
  },
  [ActionTypeConstants.LOAD_TRAINING_COURSES]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'training_course',
    METHOD: METHODS.POST,
    API_ACTION: 'read',
    IS_AUTH: true,
  },
  [ActionTypeConstants.LOAD_TRAININGS]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'training',
    METHOD: METHODS.POST,
    API_ACTION: 'read',
    IS_AUTH: true,
  },
  [ActionTypeConstants.CREATE_TRAINING]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'training',
    METHOD: METHODS.POST,
    API_ACTION: 'create',
    IS_AUTH: true,
  },
  [ActionTypeConstants.DELETE_TRAINING]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'training',
    METHOD: METHODS.POST,
    API_ACTION: 'delete',
    IS_AUTH: true,
  },
  [ActionTypeConstants.UPDATE_TRAINING]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'training',
    METHOD: METHODS.POST,
    API_ACTION: 'update',
    IS_AUTH: true,
  },
  [ActionTypeConstants.SUSPEND_TRAINING]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'training',
    METHOD: METHODS.POST,
    API_ACTION: 'suspend',
    IS_AUTH: true,
  },
  [ActionTypeConstants.UNSUSPEND_TRAINING]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'training',
    METHOD: METHODS.POST,
    API_ACTION: 'unsuspend',
    IS_AUTH: true,
  },
  [ActionTypeConstants.CHECK_QUIZ_ANSWERS]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'training',
    METHOD: METHODS.POST,
    API_ACTION: 'check_quiz_section_answers',
    IS_AUTH: true,
  },
  [ActionTypeConstants.LOAD_QUIZ_QUESTIONS]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'training',
    METHOD: METHODS.POST,
    API_ACTION: 'get_quiz',
    IS_AUTH: true,
  },
  [ActionTypeConstants.COMPLETE_TRAINING]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'training',
    METHOD: METHODS.POST,
    API_ACTION: 'complete',
    IS_AUTH: true,
  },
  [ActionTypeConstants.INCOMPLETE_TRAINING]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'training',
    METHOD: METHODS.POST,
    API_ACTION: 'incomplete',
    IS_AUTH: true,
  },
  [ActionTypeConstants.BULK_UPLOAD_TRAINING]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'training',
    METHOD: METHODS.POST,
    API_ACTION: 'bulk_upload',
    IS_AUTH: true,
  },
  [ActionTypeConstants.LOAD_ALERTS]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'alerts',
    METHOD: METHODS.POST,
    API_ACTION: 'read',
    IS_AUTH: true,
  },
  [ActionTypeConstants.UPDATE_ALERTS]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'alerts',
    METHOD: METHODS.POST,
    API_ACTION: 'update',
    IS_AUTH: true,
  },
  [ActionTypeConstants.LOAD_USERS]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'users',
    METHOD: METHODS.POST,
    API_ACTION: 'read',
    IS_AUTH: true,
  },
  [ActionTypeConstants.LOAD_USERS_UNASSIGNED]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'users',
    METHOD: METHODS.POST,
    API_ACTION: 'unassigned',
    IS_AUTH: true,
  },
  [ActionTypeConstants.LOAD_USERS_BY_ORG]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'training',
    METHOD: METHODS.POST,
    API_ACTION: 'users',
    IS_AUTH: true,
  },
  [ActionTypeConstants.SUBMIT_USER_LOGIN_FORM]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'users',
    METHOD: METHODS.POST,
    API_ACTION: 'login',
  },
  [ActionTypeConstants.SUBMIT_USER_LOGOUT_FORM]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'users',
    METHOD: METHODS.POST,
    API_ACTION: 'logout',
  },
  [ActionTypeConstants.SUBMIT_USER_REGISTER_FORM]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'users',
    METHOD: METHODS.POST,
    API_ACTION: 'register',
  },
  [ActionTypeConstants.SUBMIT_USER_SETTINGS_FORM]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'users',
    METHOD: METHODS.POST,
    API_ACTION: 'settings',
  },
  [ActionTypeConstants.LOAD_USER_SETTINGS_FORM]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'users',
    METHOD: METHODS.POST,
    API_ACTION: 'account',
  },
  [ActionTypeConstants.SUBMIT_ADD_OFFICER_FORM]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'users',
    METHOD: METHODS.POST,
    API_ACTION: 'create',
  },
  [ActionTypeConstants.SUBMIT_EDIT_USER_FORM]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'users',
    METHOD: METHODS.POST,
    API_ACTION: 'update',
  },
  [ActionTypeConstants.AUTH_GET_USER]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'users',
    METHOD: METHODS.POST,
    API_ACTION: 'login',
    IS_AUTH: true,
  },
  [ActionTypeConstants.AUTH_LOGOUT]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'users',
    METHOD: METHODS.POST,
    API_ACTION: 'logout',
    IS_AUTH: true,
  },
  [ActionTypeConstants.SUBMIT_USER_FORGOT_PASSWORD]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'users',
    METHOD: METHODS.POST,
    API_ACTION: 'forgot_password',
  },
  [ActionTypeConstants.SUBMIT_USER_RESET_PASSWORD]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'users',
    METHOD: METHODS.POST,
    API_ACTION: 'reset_password',
  },
  [ActionTypeConstants.GET_USER_BY_KEY]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'users',
    METHOD: METHODS.POST,
    API_ACTION: 'get_by_key',
    IS_AUTH: true,
  },
  // [ActionTypeConstants.SUBMIT_USER_CHANGE_PASSWORD]: {
  //   URL: `${BASE_API_URL}`,
  //   API_OBJECT: 'users',
  //   METHOD: METHODS.POST,
  //   API_ACTION: 'change_password',
  // },
  [ActionTypeConstants.ACTIVATE_USER]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'users',
    METHOD: METHODS.POST,
    API_ACTION: 'activate',
  },
  // [ActionTypeConstants.DELETE_USER]: {
  //     URL: `${BASE_API_URL}`,
  //     API_OBJECT: "users",
  //     METHOD: METHODS.POST,
  //     API_ACTION: "delete",
  // },
  [ActionTypeConstants.DEACTIVATE_USER]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'users',
    METHOD: METHODS.POST,
    API_ACTION: 'deactivate',
  },
  [ActionTypeConstants.LOAD_REASIGNABLE_USERS]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'users',
    METHOD: METHODS.POST,
    API_ACTION: 'reassignable_users',
  },
  [ActionTypeConstants.REVOKE_USER_ACCESS]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'users',
    METHOD: METHODS.POST,
    API_ACTION: 'remove_from_org',
  },
  [ActionTypeConstants.RESEND_REGISTRATION_EMAIL_TO_USER]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'users',
    METHOD: METHODS.POST,
    API_ACTION: 'resend_registration_email',
  },
  [ActionTypeConstants.RESEND_REGISTRATION_EMAIL_TO_USER_ALL]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'users',
    METHOD: METHODS.POST,
    API_ACTION: 'resend_registration_email_to_all',
  },
  [ActionTypeConstants.LOAD_REPORTS]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'reports',
    METHOD: METHODS.POST,
    API_ACTION: 'read',
    IS_AUTH: true,
  },
  [ActionTypeConstants.CREATE_REPORT]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'reports',
    METHOD: METHODS.POST,
    API_ACTION: 'create',
    IS_AUTH: true,
  },
  [ActionTypeConstants.DELETE_REPORT]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'reports',
    METHOD: METHODS.POST,
    API_ACTION: 'delete',
    IS_AUTH: true,
  },
  [ActionTypeConstants.LOAD_FOLDERS]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'folders',
    METHOD: METHODS.POST,
    API_ACTION: 'read',
    IS_AUTH: true,
  },
  [ActionTypeConstants.LOAD_FOLDER_CONTENTS]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'folders',
    METHOD: METHODS.POST,
    API_ACTION: 'read',
    IS_AUTH: true,
  },
  [ActionTypeConstants.CREATE_FOLDER]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'folders',
    METHOD: METHODS.POST,
    API_ACTION: 'create',
    IS_AUTH: true,
  },
  [ActionTypeConstants.UPDATE_FOLDER]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'folders',
    METHOD: METHODS.POST,
    API_ACTION: 'update',
    IS_AUTH: true,
  },
  [ActionTypeConstants.DELETE_FOLDER]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'folders',
    METHOD: METHODS.POST,
    API_ACTION: 'delete',
    IS_AUTH: true,
  },
  [ActionTypeConstants.UPLOAD_DOCUMENTS]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'documents',
    METHOD: METHODS.POST,
    API_ACTION: 'upload',
    IS_AUTH: true,
  },
  [ActionTypeConstants.LOAD_DOCUMENT]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'documents',
    METHOD: METHODS.POST,
    API_ACTION: 'read',
    IS_AUTH: true,
  },
  [ActionTypeConstants.EDIT_DOCUMENT]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'documents',
    METHOD: METHODS.POST,
    API_ACTION: 'edit',
    IS_AUTH: true,
  },
  [ActionTypeConstants.RESTORE_DOCUMENT]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'documents',
    METHOD: METHODS.POST,
    API_ACTION: 'restore',
    IS_AUTH: true,
  },
  [ActionTypeConstants.SAVE_AS_DOCUMENT]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'documents',
    METHOD: METHODS.POST,
    API_ACTION: 'save_as',
    IS_AUTH: true,
  },
  [ActionTypeConstants.SUBMIT_ADD_REMEDIATION_PLAN_FORM]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'remediations',
    METHOD: METHODS.POST,
    API_ACTION: 'create',
    IS_AUTH: true,
  },
  [ActionTypeConstants.SUBMIT_EDIT_REMEDIATION_PLAN_FORM]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'remediations',
    METHOD: METHODS.POST,
    API_ACTION: 'update',
    IS_AUTH: true,
  },
  [ActionTypeConstants.LOAD_REMEDIATION_PLANS]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'remediations',
    METHOD: METHODS.POST,
    API_ACTION: 'read',
    IS_AUTH: true,
  },
  [ActionTypeConstants.LOAD_EDIT_REMEDIATION_PLAN_FORM]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'remediations',
    METHOD: METHODS.POST,
    API_ACTION: 'read',
    IS_AUTH: true,
  },
  [ActionTypeConstants.COMPLETE_REMEDIATION_PLAN]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'remediations',
    METHOD: METHODS.POST,
    API_ACTION: 'complete',
    IS_AUTH: true,
  },
  [ActionTypeConstants.NOTIFY_REMEDIATION_PLAN]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'remediations',
    METHOD: METHODS.POST,
    API_ACTION: 'notify',
    IS_AUTH: true,
  },
  [ActionTypeConstants.INCOMPLETE_REMEDIATION_PLAN]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'remediations',
    METHOD: METHODS.POST,
    API_ACTION: 'incomplete',
    IS_AUTH: true,
  },
  [ActionTypeConstants.DELETE_REMEDIATION_PLAN]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'remediations',
    METHOD: METHODS.POST,
    API_ACTION: 'delete',
    IS_AUTH: true,
  },
  [ActionTypeConstants.LOAD_GAPS]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'gaps',
    METHOD: METHODS.POST,
    API_ACTION: 'read',
    IS_AUTH: true,
  },
  [ActionTypeConstants.LOAD_ROLES]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'role',
    METHOD: METHODS.POST,
    API_ACTION: 'read_all',
    IS_AUTH: true,
  },
  [ActionTypeConstants.LOAD_ROLES_USER]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'role',
    METHOD: METHODS.POST,
    API_ACTION: 'read_by_user',
    IS_AUTH: true,
  },
  [ActionTypeConstants.CREATE_USERS_BULK]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'users',
    METHOD: METHODS.POST,
    API_ACTION: 'create_bulk',
    IS_AUTH: true,
  },
  [ActionTypeConstants.LOAD_STATS]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'stats',
    METHOD: METHODS.POST,
    API_ACTION: 'all',
    IS_AUTH: true,
  },
  [ActionTypeConstants.LOAD_USER_STATS]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'stats',
    METHOD: METHODS.POST,
    API_ACTION: 'user',
    IS_AUTH: true,
  },
  [ActionTypeConstants.LOAD_STEPS]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'step',
    METHOD: METHODS.POST,
    API_ACTION: 'steps',
    IS_AUTH: true,
  },
  [ActionTypeConstants.LOAD_ACTIVE_STEP]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'step',
    METHOD: METHODS.POST,
    API_ACTION: 'read_all',
    IS_AUTH: true,
  },
  [ActionTypeConstants.LOAD_USER_ORG_SITE]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'users',
    METHOD: METHODS.POST,
    API_ACTION: 'org_site',
    IS_AUTH: true,
  },
  [ActionTypeConstants.CREATE_DEVICE]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'devices',
    METHOD: METHODS.POST,
    API_ACTION: 'create',
    IS_AUTH: true,
  },
  [ActionTypeConstants.UPDATE_DEVICE]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'devices',
    METHOD: METHODS.POST,
    API_ACTION: 'update',
    IS_AUTH: true,
  },
  [ActionTypeConstants.LOAD_DEVICES]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'devices',
    METHOD: METHODS.POST,
    API_ACTION: 'read',
    IS_AUTH: true,
  },
  [ActionTypeConstants.DOWNLOAD_DEVICES]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'devices',
    METHOD: METHODS.POST,
    API_ACTION: 'download',
    IS_AUTH: true,
    RESPONSE_TYPE: 'blob',
  },
  [ActionTypeConstants.LOAD_CLOUD_ASSETS]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'cloud_assets',
    METHOD: METHODS.POST,
    API_ACTION: 'read',
    IS_AUTH: true,
  },
  [ActionTypeConstants.LOAD_CLOUD_ASSETS_V2]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'cloud_assets',
    METHOD: METHODS.POST,
    API_ACTION: 'read_v2',
    IS_AUTH: true,
  },
  [ActionTypeConstants.CREATE_CLOUD_ASSETS]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'cloud_assets',
    METHOD: METHODS.POST,
    API_ACTION: 'create',
    IS_AUTH: true,
  },
  [ActionTypeConstants.CREATE_CLOUD_ASSETS_V2]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'cloud_assets',
    METHOD: METHODS.POST,
    API_ACTION: 'create_v2',
    IS_AUTH: true,
  },
  [ActionTypeConstants.UPDATE_CLOUD_ASSETS]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'cloud_assets',
    METHOD: METHODS.POST,
    API_ACTION: 'update',
    IS_AUTH: true,
  },
  [ActionTypeConstants.UPDATE_CLOUD_ASSETS_V2]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'cloud_assets',
    METHOD: METHODS.POST,
    API_ACTION: 'update_v2',
    IS_AUTH: true,
  },
  [ActionTypeConstants.DOWNLOAD_TEMPLATE_CLOUD_ASSETS]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'cloud_assets',
    METHOD: METHODS.POST,
    API_ACTION: 'template',
    IS_AUTH: true,
    RESPONSE_TYPE: 'blob',
  },
  [ActionTypeConstants.DOWNLOAD_TEMPLATE_CLOUD_ASSETS_V2]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'cloud_assets',
    METHOD: METHODS.POST,
    API_ACTION: 'template_v2',
    IS_AUTH: true,
    RESPONSE_TYPE: 'blob',
  },
  [ActionTypeConstants.BULK_UPLOAD_CLOUD_ASSETS]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'cloud_assets',
    METHOD: METHODS.POST,
    API_ACTION: 'bulk_upload',
    IS_AUTH: true,
  },
  [ActionTypeConstants.BULK_UPLOAD_CLOUD_ASSETS_V2]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'cloud_assets',
    METHOD: METHODS.POST,
    API_ACTION: 'bulk_upload_v2',
    IS_AUTH: true,
  },
  [ActionTypeConstants.DOWNLOAD_UPDATE_TEMPLATE_CLOUD_ASSETS]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'cloud_assets',
    METHOD: METHODS.POST,
    API_ACTION: 'bulk_update_template',
    IS_AUTH: true,
    RESPONSE_TYPE: 'blob',
  },
  [ActionTypeConstants.BULK_UPDATE_CLOUD_ASSETS]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'cloud_assets',
    METHOD: METHODS.POST,
    API_ACTION: 'bulk_update',
    IS_AUTH: true,
  },
  [ActionTypeConstants.ACTIVATE_CLOUD_ASSETS]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'cloud_assets',
    METHOD: METHODS.POST,
    API_ACTION: 'activate',
    IS_AUTH: true,
  },
  [ActionTypeConstants.DEACTIVATE_CLOUD_ASSETS]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'cloud_assets',
    METHOD: METHODS.POST,
    API_ACTION: 'deactivate',
    IS_AUTH: true,
  },
  [ActionTypeConstants.DECOMMISSION_DEVICE]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'devices',
    METHOD: METHODS.POST,
    API_ACTION: 'decommission',
  },
  [ActionTypeConstants.DUPLICATE_DEVICE]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'devices',
    METHOD: METHODS.POST,
    API_ACTION: 'duplicate',
    IS_AUTH: true,
  },
  [ActionTypeConstants.DELETE_DEVICE]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'devices',
    METHOD: METHODS.POST,
    API_ACTION: 'delete',
    IS_AUTH: true,
  },
  [ActionTypeConstants.CREATE_DEVICE_INVENTORY]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'inventory_device',
    METHOD: METHODS.POST,
    API_ACTION: 'create',
    IS_AUTH: true,
  },
  [ActionTypeConstants.UPDATE_DEVICE_INVENTORY]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'inventory_device',
    METHOD: METHODS.POST,
    API_ACTION: 'update',
    IS_AUTH: true,
  },
  [ActionTypeConstants.LOAD_DEVICE_INVENTORY]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'inventory_device',
    METHOD: METHODS.POST,
    API_ACTION: 'read',
    IS_AUTH: true,
  },
  [ActionTypeConstants.DOWNLOAD_TEMPLATE_DEVICE_INVENTORY]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'inventory_device',
    METHOD: METHODS.POST,
    API_ACTION: 'download_upload_template',
    IS_AUTH: true,
    RESPONSE_TYPE: 'blob',
  },
  [ActionTypeConstants.DOWNLOAD_TEMPLATE_DEVICE_INVENTORY_V2]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'inventory_device',
    METHOD: METHODS.POST,
    API_ACTION: 'download_upload_template_v2',
    IS_AUTH: true,
    RESPONSE_TYPE: 'blob',
  },
  [ActionTypeConstants.DOWNLOAD_UPDATE_TEMPLATE_DEVICE_INVENTORY]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'inventory_device',
    METHOD: METHODS.POST,
    API_ACTION: 'download_update_template',
    IS_AUTH: true,
    RESPONSE_TYPE: 'blob',
  },
  [ActionTypeConstants.BULK_UPLOAD_DEVICE_INVENTORY]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'inventory_device',
    METHOD: METHODS.POST,
    API_ACTION: 'bulk_upload',
    IS_AUTH: true,
  },
  [ActionTypeConstants.BULK_UPLOAD_DEVICE_INVENTORY_V2]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'inventory_device',
    METHOD: METHODS.POST,
    API_ACTION: 'bulk_upload_v2',
    IS_AUTH: true,
  },
  [ActionTypeConstants.BULK_UPDATE_DEVICE_INVENTORY]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'inventory_device',
    METHOD: METHODS.POST,
    API_ACTION: 'bulk_update',
    IS_AUTH: true,
  },
  [ActionTypeConstants.ACTIVATE_DEVICE_INVENTORY]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'inventory_device',
    METHOD: METHODS.POST,
    API_ACTION: 'activate',
    IS_AUTH: true,
  },
  [ActionTypeConstants.DEACTIVATE_DEVICE_INVENTORY]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'inventory_device',
    METHOD: METHODS.POST,
    API_ACTION: 'deactivate',
    IS_AUTH: true,
  },
  [ActionTypeConstants.DESTROY_DEVICE_INVENTORY]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'inventory_device',
    METHOD: METHODS.POST,
    API_ACTION: 'destroy',
    IS_AUTH: true,
  },
  [ActionTypeConstants.CREATE_DEVICE_INVENTORY_V2]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'inventory_device',
    METHOD: METHODS.POST,
    API_ACTION: 'create_v2',
    IS_AUTH: true,
  },
  [ActionTypeConstants.UPDATE_DEVICE_INVENTORY_V2]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'inventory_device',
    METHOD: METHODS.POST,
    API_ACTION: 'update_v2',
    IS_AUTH: true,
  },
  [ActionTypeConstants.LOAD_DEVICE_INVENTORY_V2]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'inventory_device',
    METHOD: METHODS.POST,
    API_ACTION: 'read_v2',
    IS_AUTH: true,
  },
  [ActionTypeConstants.LOAD_FILES]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'folders',
    METHOD: METHODS.POST,
    API_ACTION: 'read',
    IS_AUTH: true,
  },
  [ActionTypeConstants.LOAD_UNAPPROVED_FILES]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'folders',
    METHOD: METHODS.POST,
    API_ACTION: 'read_unapproved',
    IS_AUTH: true,
  },
  [ActionTypeConstants.LOAD_ATTESTATIONS]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'attestation',
    METHOD: METHODS.POST,
    API_ACTION: 'read',
    IS_AUTH: true,
  },
  [ActionTypeConstants.ATTEST_DOCUMENT]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'tasks', // todo: add proper obj
    METHOD: METHODS.POST,
    API_ACTION: 'understood', // todo: add proper action
    IS_AUTH: true,
  },
  [ActionTypeConstants.ATTEST_DOCUMENT_DASHBOARD]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'tasks',
    METHOD: METHODS.POST,
    API_ACTION: 'understood',
    IS_AUTH: true,
  },
  [ActionTypeConstants.REASSIGN_TASKS]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'tasks',
    METHOD: METHODS.POST,
    API_ACTION: 'reassign',
    IS_AUTH: true,
  },
  [ActionTypeConstants.APPROVE_DOCUMENT]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'folders',
    METHOD: METHODS.POST,
    API_ACTION: 'approve_document',
    IS_AUTH: true,
  },
  [ActionTypeConstants.INITIALIZE_DOCUMENT]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'folders',
    METHOD: METHODS.POST,
    API_ACTION: 'initialize_document',
    IS_AUTH: true,
  },
  [ActionTypeConstants.CLEANUP_DOCUMENT]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'documents',
    METHOD: METHODS.POST,
    API_ACTION: 'cleanup',
    IS_AUTH: true,
  },
  [ActionTypeConstants.CREATE_EVIDENCE]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'evidence',
    METHOD: METHODS.POST,
    API_ACTION: 'create',
    IS_AUTH: true,
  },
  [ActionTypeConstants.DELETE_EVIDENCE]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'evidence',
    METHOD: METHODS.POST,
    API_ACTION: 'delete',
    IS_AUTH: true,
  },
  [ActionTypeConstants.CREATE_TASKNOTE]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'tasknotes',
    METHOD: METHODS.POST,
    API_ACTION: 'create',
    IS_AUTH: true,
  },
  [ActionTypeConstants.DELETE_TASKNOTE]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'tasknotes',
    METHOD: METHODS.POST,
    API_ACTION: 'delete',
    IS_AUTH: true,
  },
  [ActionTypeConstants.LOAD_EVIDENCE_BY_INCIDENT]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'evidence',
    METHOD: METHODS.POST,
    API_ACTION: 'read_by_incident',
    IS_AUTH: true,
  },
  [ActionTypeConstants.LOAD_EVIDENCE_BY_TASK]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'evidence',
    METHOD: METHODS.POST,
    API_ACTION: 'read_by_task',
    IS_AUTH: true,
  },
  [ActionTypeConstants.LOAD_EVIDENCE_BY_CLOUD_ASSET]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'evidence',
    METHOD: METHODS.POST,
    API_ACTION: 'read_by_cloud_asset',
    IS_AUTH: true,
  },
  [ActionTypeConstants.LOAD_EVIDENCE_BY_INVENTORY]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'evidence',
    METHOD: METHODS.POST,
    API_ACTION: 'read_by_inventory_device',
    IS_AUTH: true,
  },
  [ActionTypeConstants.LOAD_EVIDENCE_BY_AUDIT]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'evidence',
    METHOD: METHODS.POST,
    API_ACTION: 'read_by_audit',
    IS_AUTH: true,
  },
  [ActionTypeConstants.DOWNLOAD_EVIDENCE]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'evidence',
    METHOD: METHODS.POST,
    API_ACTION: 'download',
    IS_AUTH: false,
    RESPONSE_TYPE: 'blob',
  },
  [ActionTypeConstants.DOWNLOAD_USERS_CSV]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'users',
    METHOD: METHODS.POST,
    API_ACTION: 'csv',
    IS_AUTH: false,
    RESPONSE_TYPE: 'blob',
  },
  [ActionTypeConstants.DOWNLOAD_REPORT]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'reports',
    METHOD: METHODS.POST,
    API_ACTION: 'download',
    IS_AUTH: false,
    RESPONSE_TYPE: 'blob',
  },
  [ActionTypeConstants.DOWNLOAD_AFFILIATE_FILE]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'affiliates',
    METHOD: METHODS.POST,
    API_ACTION: 'download_file',
    IS_AUTH: false,
    RESPONSE_TYPE: 'blob',
  },
  [ActionTypeConstants.DOWNLOAD_AUDIT]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'audits',
    METHOD: METHODS.POST,
    API_ACTION: 'bulk_download',
    IS_AUTH: true,
    RESPONSE_TYPE: 'blob',
  },
  [ActionTypeConstants.UPLOAD_AUDIT]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'audits',
    METHOD: METHODS.POST,
    API_ACTION: 'bulk_upload',
    IS_AUTH: true,
  },
  [ActionTypeConstants.SUBMIT_NEW_ORG_SAGA]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'setup',
    METHOD: METHODS.POST,
    API_ACTION: 'onepage',
    IS_AUTH: true,
  },
  [ActionTypeConstants.EMAIL_AFFILIATE_FILES]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'affiliates',
    METHOD: METHODS.POST,
    API_ACTION: 'send_documents',
    IS_AUTH: true,
  },
  [ActionTypeConstants.LOAD_ORGANIZATIONS]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'superadmin',
    METHOD: METHODS.POST,
    API_ACTION: 'list_organizations',
    IS_AUTH: true,
  },
  [ActionTypeConstants.LOAD_ORGANIZATION_DETAILS]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'dashboard_v2',
    METHOD: METHODS.POST,
    API_ACTION: 'organization_details',
    IS_AUTH: true,
  },
  [ActionTypeConstants.LOAD_SUPERADMINS]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'users',
    METHOD: METHODS.POST,
    API_ACTION: 'list_superadmins',
    IS_AUTH: true,
  },
  [ActionTypeConstants.LOAD_USER_COUNTERS]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'users',
    METHOD: METHODS.POST,
    API_ACTION: 'counter',
    IS_AUTH: true,
  },
  [ActionTypeConstants.SET_GRANT_SEAL]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'superadmin',
    METHOD: METHODS.POST,
    API_ACTION: 'grant_seal',
    IS_AUTH: true,
  },
  [ActionTypeConstants.LOAD_CERTIFICATE]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'certificate',
    METHOD: METHODS.POST,
    API_ACTION: 'get_by_id',
    IS_AUTH: true,
  },
  [ActionTypeConstants.LOAD_CERTIFICATES]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'certificate',
    METHOD: METHODS.POST,
    API_ACTION: 'get_by_organization',
    IS_AUTH: true,
  },
  [ActionTypeConstants.LOAD_USER_CERTIFICATE]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'certificate',
    METHOD: METHODS.POST,
    API_ACTION: 'get_by_user',
    IS_AUTH: true,
  },
  [ActionTypeConstants.LOAD_USER_CERTIFICATES]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'certificate',
    METHOD: METHODS.POST,
    API_ACTION: 'get_by_user',
    IS_AUTH: true,
  },
  [ActionTypeConstants.SEARCH_USERS]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'users',
    METHOD: METHODS.POST,
    API_ACTION: 'search',
    IS_AUTH: true,
  },
  [ActionTypeConstants.LOAD_MODULES]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'module',
    METHOD: METHODS.POST,
    API_ACTION: 'read',
    IS_AUTH: true,
  },
  [ActionTypeConstants.LOAD_ORG_MODULES]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'module',
    METHOD: METHODS.POST,
    API_ACTION: 'org_modules',
    IS_AUTH: true,
  },
  [ActionTypeConstants.LOAD_ORG_ENTITIES]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'module',
    METHOD: METHODS.POST,
    API_ACTION: 'org_entities',
    IS_AUTH: true,
  },
  [ActionTypeConstants.LOAD_TOP_LEVEL_FOLDERS]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'folders',
    METHOD: METHODS.POST,
    API_ACTION: 'read_fs',
    IS_AUTH: true,
  },
  [ActionTypeConstants.MFA_ACTIVATE]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'mfa',
    METHOD: METHODS.POST,
    API_ACTION: 'activate',
  },
  [ActionTypeConstants.MFA_GENERATE_CODE]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'mfa',
    METHOD: METHODS.POST,
    API_ACTION: 'generate_code',
    IS_AUTH: true,
  },
  [ActionTypeConstants.MFA_VERIFY_CODE]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'mfa',
    METHOD: METHODS.POST,
    API_ACTION: 'verify',
    IS_AUTH: true,
  },
  [ActionTypeConstants.LOAD_SSO_ORGANIZATIONS]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'organizations',
    METHOD: METHODS.POST,
    API_ACTION: 'sso_organizations',
    IS_AUTH: true,
  },
  [ActionTypeConstants.CONNECT_SSO_ORGANIZATION]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'organizations',
    METHOD: METHODS.POST,
    API_ACTION: 'sso_connect',
    IS_AUTH: true,
  },
  [ActionTypeConstants.GET_SSO_USERS]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'organizations',
    METHOD: METHODS.POST,
    API_ACTION: 'sso_users',
    IS_AUTH: true,
  },
  [ActionTypeConstants.CONNECT_SSO_USERS]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'organizations',
    METHOD: METHODS.POST,
    API_ACTION: 'sso_connect_users',
    IS_AUTH: true,
  },
  [ActionTypeConstants.SSO_LOGIN_ATTEMPT]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'users',
    METHOD: METHODS.POST,
    API_ACTION: 'login_attempt',
    IS_AUTH: true,
  },
  [ActionTypeConstants.SSO_LOGIN]: {
    URL: `${BASE_API_URL}`,
    API_OBJECT: 'users',
    METHOD: METHODS.POST,
    API_ACTION: 'sso_login',
    IS_AUTH: true,
  },
};
