import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import logo from 'assets/img/cg-logo-large.png';

import { useAppDispatch } from 'hooks';
import { Button } from 'components/Button/Button';

import { downloadAffiliateFileAction } from 'store/actions/Actions';
import WelcomePanel from '../components/WelcomePanel/WelcomePanel';

import styles from './DownloadAffiliate.module.scss';

function DownloadAffiliate() {
  const { hash } = useParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (hash) {
      dispatch(downloadAffiliateFileAction({ hash }));
    }
  }, [hash, dispatch]);

  return (
    <WelcomePanel>
      <div className={styles['heading']}>
        <img className={styles.logo} src={logo} alt="" />
      </div>
      <div className={styles['panel']}>
        <p>Your download is ready!</p>
        <p>Click the button, if the download didn&apos;t start automatically</p>
      </div>
      <div className={styles['footer']}>
        <Button text="DOWNLOAD" onClick={() => dispatch(downloadAffiliateFileAction({ hash }))} />
      </div>
    </WelcomePanel>
  );
}

export default DownloadAffiliate;
