import React, { useEffect, useState } from 'react';
import Drawer from 'v2/components/Drawer/Drawer';
import MainMenu from './MainMenu';
import UserPanel from 'components/Header/UserPanelNew';
import MenuIcon from '@mui/icons-material/Menu';
import { userMenu } from './MainMenuData';
import { Link } from 'react-router-dom';

import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import Button from '@mui/material/Button';

import { useAppDispatch, useAppSelector } from 'hooks';
import { toggleNavigationAction, loadUserCountersAction } from 'store/actions/Actions';

import styles from './MainLayout.module.scss';

interface IMainLayout {
  children: JSX.Element | JSX.Element[] | string;
}

function MainLayout({
    children,
}: IMainLayout) {
  const dispatch = useAppDispatch();
  const UserOrgSiteReducer = useAppSelector((state) => state.UserOrgSiteReducer);
  const UserCountersReducer = useAppSelector((state) => state.UserCountersReducer);
  const orgId = Number.parseInt(UserOrgSiteReducer?.selected?.org, 10);
  const companyName: string = UserOrgSiteReducer?.organizations[orgId]?.name ?? '';

  function getIcon(icon: string) {
    const iconMap = {
        PrivacyTipIcon,
    }

    return React.createElement(iconMap[icon]);
  }

  const [isExpanded, setIsExpanded] = useState(Boolean((localStorage.getItem('isMenuExpanded') ?? 'true') === 'true'));

  const handleMenuExpanded = () => {
    setIsExpanded(!isExpanded);
  }

  useEffect(() => {
    dispatch(toggleNavigationAction(isExpanded));
  }, [isExpanded])

  useEffect(() => {
    if (typeof UserCountersReducer?.counters === 'object' && UserCountersReducer?.counters !== null) return;
    dispatch(loadUserCountersAction());
  }, [loadUserCountersAction, UserCountersReducer?.counters]);

  return (
    <div className={styles['main-layout']}>
      <Drawer isExpanded={isExpanded} setIsExpanded={setIsExpanded} items={MainMenu({count: {
          myTasks: UserCountersReducer?.counters?.tasks ?? 0,
          myDocuments: UserCountersReducer?.counters?.documents ?? 0,
      }, isExpanded})}>
          <header className={styles['main-header']}>
              <div className={styles['main-header-left']}>
                <button className={styles['drawer-button']} onClick={handleMenuExpanded}>
                  <MenuIcon />
                </button>
                <div className={styles.company}>
                  {companyName}
                </div>
              </div>
              <div className={styles['main-header-right']}>
                <Button className={styles.report}>
                  <Link to="/report-incident">
                    <span>{getIcon('PrivacyTipIcon')}</span>
                    <span className={styles.label}>Report Incident</span>
                  </Link>
                </Button>
                <UserPanel menuData={userMenu} />
              </div>
          </header>
          <main className={styles['main-content']}>
              {children}
          </main>
      </Drawer>
      <div id="modal-root" />
    </div>
  );
}

export default MainLayout;
