import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import ReactDOM from 'react-dom';

import { loadDocumentAction, loadQuizQuestionsAction } from 'store/actions/Actions';

import QuizModal from './components/QuizModal/QuizModal';
import styles from './DashboardViews.module.scss';
import { useAppDispatch, useAppSelector } from 'hooks';

function VideoView(props) {
  const { task } = props;
  const dispatch = useAppDispatch();
  const [playing, setPlaying] = useState(true);
  const player = useRef(null);
  const [showQuizModal, setShowQuizModal] = useState(false);

  const DocumentReducer = useAppSelector((state) => state.DocumentReducer);
  const QuizReducer = useAppSelector((state) => state.QuizReducer);

  const { document } = DocumentReducer;

  const lastSection = QuizReducer?.section?.filter((s) => !s.passed)[0] || undefined;

  // load document
  useEffect(() => {
    dispatch(loadDocumentAction({ id: task.subtask_data.document }));
  }, [task.id]);

  // load quiz if task has quiz
  useEffect(() => {
    if (!QuizReducer.section && task && task.subtask_data && task.subtask_data.has_quiz === 't') {
      dispatch(loadQuizQuestionsAction({ training: task.subtask_data.training }));
    }
  }, [QuizReducer.section, task]);

  // play video if quiz is reloaded (when modal close button is clicked)
  useEffect(() => {
    if (QuizReducer.status === 'SUCCESS') {
      setPlaying(true);
    }
  }, [QuizReducer.status]);

  // pause video on tab/window change
  useEffect(() => {
    function vc() {
      setPlaying(!window.document.hidden);
    }
    window.document.addEventListener('visibilitychange', vc);
    return () => {
      window.document.removeEventListener('visibilitychange', vc);
    };
  });

  const handleOnSeek = (e) => {
    // exit full screen
    if (window.document.fullscreenElement) {
      window.document.exitFullscreen();
    }

    // disable if no section
    if (!lastSection) {
      return;
    }

    // disable if modal is open
    if (showQuizModal) {
      return;
    }

    // variant 2
    if (lastSection.watched && lastSection.end_time && e > lastSection.end_time) {
      player.current.seekTo(lastSection.end_time);
    }

    // variant 1
    if (!lastSection.watched && lastSection.end_time && e > lastSection.start_time) {
      player.current.seekTo(lastSection.start_time);
    }
  };

  const handleOnProgress = (e) => {
    // keep alive timer, while playing
    window.document.getElementById('root').dispatchEvent(
      new KeyboardEvent('keydown', {
        key: 'Enter',
      })
    );
    if (!lastSection) {
      return;
    }
    // open quiz modal
    if (e === lastSection.end_time) {
      setPlaying(false);
      player.current.seekTo(lastSection.end_time);
      setShowQuizModal(lastSection);
    }
  };

  const renderConfirmModal = () => {
    if (!lastSection) {
      return false;
    }
    return ReactDOM.createPortal(
      <QuizModal
        training_id={task.subtask_data.training}
        quiz_section={lastSection?.id}
        data={showQuizModal}
        setPlaying={setPlaying}
        setShowModal={setShowQuizModal}
        player={player}
      />,
      window.document.getElementById('modal-root')
    );
  };

  return (
    <div className={styles.video}>
      {showQuizModal && renderConfirmModal()}
      {document && document.config && document.config.document && document.config.document.url && (
        <ReactPlayer
          className={styles.video}
          height="auto"
          onProgress={(e) => handleOnProgress(Math.round(e.playedSeconds))}
          onReady={() => setPlaying(() => playing)}
          ref={player}
          onSeek={(e) => handleOnSeek(Math.round(e))} // round to seconds
          progressInterval={1000 / 2}
          controls
          width="100%"
          onPlay={() => setPlaying(true)} // bind playing state
          onPause={() => setPlaying(false)} // bind playing state
          playing={playing} // bind playing state
          style={{ maxWidth: 960 }}
          url={document.config.document.url}
        />
      )}
    </div>
  );
}

export default VideoView;
