import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import AppLayoutWrapFull from 'components/AppLayoutWrapFull';
import Spinner from 'components/Spinner/Spinner';
import ActionStatusConstants from 'helpers/ActionStatusConstants';
import Container from 'components/Container/Container';
import PageHeader from 'components/PageHeader/PageHeader';
import PageHeaderActions from 'components/PageHeader/components/PageHeaderActions/PageHeaderActions';
import FullDataTable from 'components/DataTable/FullDataTable';
import TableDefAudits from 'table-defs/TableDefAudits';

import {
  loadAuditsAction,
  deleteAuditAction,
  loadSitesAction,
  downloadAuditAction,
  reopenAuditAction,
  setPaginationAction,
  notifyAuditAction,
  loadActsAction,
} from 'store/actions/Actions';

import useFilterRows from 'helpers/customHooks/useFilterRows';

import { setSelectedRows, toggleDropdowns, exportAsCSV } from 'helpers/utils';
import { AUDITS_PAGE } from 'helpers/constants/DataTableConstants';
import { useAppDispatch, useAppSelector, useConfirmModal } from 'hooks';

import AddAuditModal from './components/AddAuditModal/AddAuditModal';
import EditAuditModal from './components/EditAuditModal/EditAuditModal';
import BulkUploadModal from './components/BulkUploadModal/BulkUploadModal';
import EvidenceUploadModal from './components/EvidenceUploadModal/EvidenceUploadModal';

const labels = () => ({
  name: {
    label: 'TITLE',
  },
  regulatory_act: {
    label: 'RETULATORY',
  },
  assigned: {
    label: 'ASSIGNED',
  },
  launch_date: {
    label: 'LAUNCH DATE',
  },
  taken: {
    label: 'TAKEN',
  },
  actName: {
    label: 'AUDIT',
  },
  frequency_label: {
    label: 'FREQUENCY',
  },
  status: {
    label: 'STATUS',
  },
  site: {
    label: 'SITE',
  },
});

function Audits(props) {
  const { loadSitesAction, loadAuditsAction, loadActsAction, setPaginationAction, deleteAuditAction, downloadAuditAction, notifyAuditAction, reopenAuditAction } = props;

  const { auditId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const DatatableReducer = useAppSelector((state) => state.DatatableReducer);
  const AuditsReducer = useAppSelector((state) => state.AuditsReducer);
  const ActsReducer = useAppSelector((state) => state.ActsReducer);
  const UserOrgSiteReducer = useAppSelector((state) => state.UserOrgSiteReducer);
  const SitesReducer = useAppSelector((state) => state.SitesReducer);

  const [state, setState] = useState({
    selectedRows: undefined,
    selected_sites: [],
  });

  const [activeReg, setActiveReg] = useState('');
  const [selectedAuditIds, setSelectedAuditIds] = useState(DatatableReducer?.datatable?.audits?.selectedIds || []);

  const [showConfirmModal, setShowConfirmModal, renderConfirmModal] = useConfirmModal();
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState<string | boolean>(false);
  const [showEvidenceModal, setShowEvidenceModal] = useState(false);
  const [showAuditUploadModal, setShowAuditUploadModal] = useState(false);
  const [sortKey, setSortKey] = useState(DatatableReducer.datatable[AUDITS_PAGE]?.sortKey);
  const [sortOrder, setSortOrder] = useState(DatatableReducer.datatable[AUDITS_PAGE]?.sortOrder);

  const noFilters = {
    text: '',
    type: '',
    regulatory_act: activeReg,
    'last-action': '',
    status: '',
    role: '',
    site: '',
    start: '',
    end: '',
    selected_sites: [],
    dateField: 'assigned',
  };
  const [filters, setFilters] = useState(DatatableReducer?.datatable?.audits?.filters || noFilters);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (auditId) {
      setShowEditModal(auditId);
    }
  }, [auditId]);

  useEffect(() => {
    if (!SitesReducer.sites) {
      loadSitesAction();
    }
  }, [SitesReducer.sites, loadSitesAction]);

  useEffect(() => {
    if (!ActsReducer.acts) {
      loadActsAction();
    }
  }, [ActsReducer.acts, loadActsAction]);

  useEffect(() => {
    if (!AuditsReducer.audits && SitesReducer.sites && ActsReducer.acts) {
      loadAuditsAction();
    }
  }, [AuditsReducer.audits, SitesReducer.sites, ActsReducer.acts, loadAuditsAction]);

  useEffect(() => {
    if (DatatableReducer.datatable?.dashboard?.activeReg) {
      setActiveReg(DatatableReducer.datatable.dashboard.activeReg);
    }
  }, [DatatableReducer.datatable]);

  useEffect(() => {
    if (activeReg) {
      setFilters((s) => ({
        ...s,
        regulatory_act: activeReg,
      }));
    }
  }, [activeReg]);

  useEffect(() => {
    if (
      AuditsReducer.status === ActionStatusConstants.SUCCESS &&
      SitesReducer.status === ActionStatusConstants.SUCCESS &&
      ActsReducer.status === ActionStatusConstants.SUCCESS &&
      DatatableReducer.status === ActionStatusConstants.SUCCESS
    ) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [DatatableReducer.datatable, AuditsReducer.audits, SitesReducer.sites, ActsReducer.acts]);

  const setFilter = (filterName) => (e) => {
    setFilters({
      ...filters,
      [filterName]: e.target.type === 'checkbox' ? e.target.checked : e.target.value || e.target?.attributes?.value?.value || '',
    });
    setPaginationAction({ dataType: AUDITS_PAGE, currentPage: 0 });
  };

  const setDateFilter = (filterName) => (value) => {
    setFilters({
      ...filters,
      [filterName]: value,
    });
    setPaginationAction({ dataType: AUDITS_PAGE, currentPage: 0 });
  };

  const filteredRows: typeof AuditsReducer.audits = useFilterRows(filters, AuditsReducer.audits);

  const getSitesList = () => {
    try {
      const sitesList = SitesReducer.sites
        ? SitesReducer.sites.map((site) => ({
            id: site.id,
            siteName: site.name,
            value: !!filters.selected_sites.includes(site.id),
          }))
        : [];
      sitesList.unshift({
        id: '',
        siteName: 'All Sites',
        value: filters.selected_sites.includes(''),
      });
      return sitesList;
    } catch (error) {
      console.error(error);
    }
    return [];
  };

  const actDropdownRef = useRef();
  const siteDropdownRef = useRef();
  const chooseActionDropdownRef = useRef();
  const statusDropdownRef = useRef();
  const regDropdownRef = useRef();

  const onChangeRowSelect = ({ selectedRows }) => {
    if (selectedRows !== state.selectedRows) {
      setState((s) => ({ ...s, selectedRows }));
    }
  };

  useEffect(() => {
    dispatch({
      type: 'SET_DATATABLE',
      payload: {
        datatable: {
          audits: {
            selectedIds: selectedAuditIds,
            filters,
            selectedRows: state.selectedRows,
            selected_sites: state.selected_sites,
            rowsPerPage: DatatableReducer?.datatable?.audits?.rowsPerPage || 10,
            currentPage: DatatableReducer?.datatable?.audits?.currentPage || 0,
            sortKey,
            sortOrder,
          },
        },
      },
    });
  }, [selectedAuditIds, filters, state, dispatch, sortKey, sortOrder]);

  const selectedIds = selectedAuditIds.map((item) => item.id);
  const is_super = UserOrgSiteReducer?.selected?.is_super || false;
  const is_coach = UserOrgSiteReducer?.selected?.is_coach || false;

  const handleDelte = ({ id, name }) => {
    setShowConfirmModal({
      title: 'Delete the audit',
      question: `Are you sure you want to remove the audit "${name}"`,
      confirmAction: () => {
        deleteAuditAction({ items: [id] });
      },
    });
  };

  const handleDelteSelected = () => {
    const idCount = selectedIds.length;
    if (idCount > 0) {
      setShowConfirmModal({
        title: 'Delete selected audits',
        question: idCount === 1 ? 'Are you sure you want to delete the selected audit?' : `Are you sure you want to delete ${idCount} selected audits?`,
        confirmAction: () => {
          deleteAuditAction({ items: selectedIds });
        },
      });
    } else {
      setShowConfirmModal({
        type: 'simple',
        title: 'Nothing selected',
        question: 'Please select audits to be deleted.',
      });
    }
  };

  const handleView = function onRowClick(e) {
    if (e.currentTarget.classList.contains('col-actions') || e.currentTarget.classList.contains('col-row-selector')) {
      return;
    }
    const { type, id } = this.props.rowData;
    const auditPath = type && ['cloud', 'device', 'inventory'].includes(type) ? `audit-${type}` : 'audit';
    navigate(`/achieve/audits/${auditPath}?id=${id}`);
  };

  const handleViewAlt = ({ id, type }) => {
    const auditPath = type && ['cloud', 'device', 'inventory'].includes(type) ? `audit-${type}` : 'audit';
    navigate(`/achieve/audits/${auditPath}?id=${id}`);
  };

  const handleNotifySelected = (selectedIds) => {
    const idCount = selectedIds.length;
    if (idCount > 0) {
      setShowConfirmModal({
        title: 'Notify Officers',
        question:
          idCount === 1 ? 'Are you sure you want to notify officers about the selected audit?' : `Are you sure you want to notify officers about ${idCount} selected audits?`,
        confirmAction: () => {
          notifyAuditAction({ items: selectedIds });
        },
      });
    } else {
      setShowConfirmModal({
        type: 'simple',
        title: 'Nothing selected',
        question: 'Please select audits.',
      });
    }
  };

  const handleExportSelected = () => {
    const data = AuditsReducer.audits.filter((item) => selectedAuditIds.map((i) => i.id).includes(item.id));
    const updatedData = data.map((item) => {
      const siteObj = item.site === '' ? { name: 'All Sites' } : SitesReducer.sites.find((site) => site.id === item.site);
      return {
        ...item,
        site: siteObj.name,
        frequency_label: item.configuration.frequency_label,
      };
    });
    exportAsCSV(updatedData, labels, 'audits.csv');
  };

  return (
    <AppLayoutWrapFull onClick={toggleDropdowns([actDropdownRef, siteDropdownRef, chooseActionDropdownRef, statusDropdownRef, regDropdownRef])}>
      <PageHeader showStats>
        <PageHeaderActions
          primaryButtonText="ADD AUDIT"
          primaryButtonHandler={() => setShowModal(!showModal)}
          searchHandler={(e) => {
            setFilters({ ...filters, text: e.target.value || '' });
            setPaginationAction({ dataType: AUDITS_PAGE, currentPage: 0 });
          }}
          searchValue={filters.text}
          chooseAction={[
            {
              actionName: 'Notify Selected',
              actionHandler: () => handleNotifySelected(selectedIds),
            },
            {
              actionName: 'Export Selected',
              actionHandler: handleExportSelected,
            },
            (is_super || is_coach) && {
              actionName: 'Delete Selected',
              actionHandler: handleDelteSelected,
            },
          ]}
          chooseActionDropdownRef={chooseActionDropdownRef}
          site={{
            onClick: (e) => {
              if (e.target.type === 'checkbox') {
                let selected_sites = [...filters.selected_sites];
                if (e.target.checked) {
                  selected_sites.push(e.target.value);
                } else {
                  selected_sites = selected_sites.filter((site) => site !== e.target.value);
                }
                setFilters({
                  ...filters,
                  site: selected_sites.length > 0 ? `Sites (${selected_sites.length})` : 'Site',
                  selected_sites,
                });
                setPaginationAction({ dataType: AUDITS_PAGE, currentPage: 0 });
              }
            },
            sites: getSitesList(),
          }}
          // siteDropdownRef={siteDropdownRef}
          status={{
            onChange: setFilter('status'),
            options: ['All', 'New', 'Ongoing', 'Not finalized', 'Error on launch', 'Error on reset', 'Reopened', 'Finalized', 'Closed incomplete', 'Deactivated incomplete'],
          }}
          statusDropdownRef={statusDropdownRef}
          reg={{
            onChange: setFilter('regulatory_act'),
            options: ['All regulatory', 'HIPAA', 'OSHA'],
          }}
          regDropdownRef={regDropdownRef}
          dateRange={{
            onChangeStart: setDateFilter('start'),
            onChangeEnd: setDateFilter('end'),
          }}
          filters={filters}
          resetFilters={() => {
            setFilters(noFilters);
            setPaginationAction({ dataType: AUDITS_PAGE, currentPage: 0 });
          }}
        />
      </PageHeader>

      {isLoading ? (
        <Spinner />
      ) : (
        <Container wide fluid>
          {AuditsReducer.audits && (
            <FullDataTable
              rowsData={filteredRows}
              definitions={TableDefAudits}
              dataType={AUDITS_PAGE}
              setSortKey={setSortKey}
              setSortOrder={setSortOrder}
              onChangeRowSelect={({ selectedRows }) => onChangeRowSelect({ selectedRows })}
              onRowClick={handleView}
              setSelectedRows={setSelectedRows(['id'], setSelectedAuditIds)}
              customTableProps={{
                selectedRows: state?.selectedRows || selectedAuditIds,
                view: handleViewAlt,
                notify: ({ id }) => handleNotifySelected([id]),
                edit: ({ id }) => setShowEditModal(id),
                evidence: (rowData) => setShowEvidenceModal(rowData),
                delete: handleDelte,
                reopen: ({ id }) => reopenAuditAction({ id }),
                download: ({ id, name }) => downloadAuditAction({ id, name }),
                upload: ({ id }) => setShowAuditUploadModal(id),
                is_super: is_super || is_coach,
              }}
            />
          )}
        </Container>
      )}

      {showConfirmModal && renderConfirmModal(showConfirmModal, setShowConfirmModal)}

      {showAuditUploadModal && ReactDOM.createPortal(<BulkUploadModal showAuditUploadModal={showAuditUploadModal} setShowModal={setShowAuditUploadModal} />, document.querySelector('#modal-root'))}

      {showEvidenceModal && ReactDOM.createPortal(<EvidenceUploadModal entity_type="audit" showModal={showEvidenceModal} setShowModal={setShowEvidenceModal} />, document.querySelector('#modal-root'))}

      {showModal && ReactDOM.createPortal(<AddAuditModal setShowModal={setShowModal} actDropdownRef={actDropdownRef} siteDropdownRef={siteDropdownRef} />, document.querySelector('#modal-root'))}

      {showEditModal && ReactDOM.createPortal(<EditAuditModal id={showEditModal} setShowModal={setShowEditModal} actDropdownRef={actDropdownRef} siteDropdownRef={siteDropdownRef} />, document.querySelector('#modal-root'))}
    </AppLayoutWrapFull>
  );
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  loadSitesAction,
  loadAuditsAction,
  setPaginationAction,
  loadActsAction,
  downloadAuditAction,
  deleteAuditAction,
  reopenAuditAction,
  notifyAuditAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Audits);
