import React, { useState } from 'react';

import Modal from 'components/Modal/Modal';
import FormElement from 'components/FormElement/FormElement';
import Button from 'components/Button/Button';

import { useAppDispatch } from 'hooks';
import { saveAsDocumentAction } from 'store/actions/Actions';

import styles from './SaveAsModal.module.scss';

const SaveAsModal = (props) => {
  const dispatch = useAppDispatch();

  const [state, setState] = useState({
    // number: props.tasks.length,
    file_name: '',
  });

  const [stateErrors, setStateErrors] = useState({
    task: '',
    due: '',
    assignee: '',
    selected_site: {},
    file_name: '',
  });

  const handleChange = (inputName) => (e) => {
    e.persist();
    setState((s) => ({
      ...s,
      [inputName]: e.target.value,
    }));
    setStateErrors((s) => ({ ...s, [inputName]: '' }));
  };

  const saveAsDocument = () => {
    if (!state.file_name) {
      setStateErrors({
        ...stateErrors,
        file_name: !state.file_name ? 'File name required' : '',
      });
      return;
    }

    let doc = {
      id: props.document.id,
    };
    // @ts-ignore
    doc.folder_name = state.file_name;
    dispatch(saveAsDocumentAction(doc));
    props.setShowModal(false);
  };

  return (
    <Modal className={styles.modal} setShowModal={props.setShowModal}>
      <div>
        <p className={styles['modal-heading']}>Save as new file</p>

        <div className={styles['modal-content']}>
          <FormElement labelText="File Name" htmlFor="file_name" errorMessage={stateErrors.task}>
            <input type="text" value={state['file_name']} placeholder="Enter file name" onChange={handleChange('file_name')} />
          </FormElement>
        </div>

        <div className={styles['modal-footer']}>
          <Button text="CANCEL" color="secondary" onClick={() => props.setShowModal(false)} />
          <Button text="Save" className={styles.create} onClick={() => saveAsDocument()} />
        </div>
      </div>
    </Modal>
  );
};

export default SaveAsModal;
