import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import styles from './Add.module.scss';

import AppLayoutWrapFull from 'components/AppLayoutWrapFull';
import PageHeader from 'components/PageHeader/PageHeader';
import Container from 'components/Container/Container';
import FormElement from 'components/FormElement/FormElement';
import DateInput from 'components/DateInput/DateInput';
import Select from 'components/Select/Select';
// import Row from "components/Row/Row";
// import Checkbox from "components/Checkbox/Checkbox";
import Tabs from 'components/Tabs/Tabs';
import AddNote from '../components/AddNote/AddNote';
import HR from 'components/HR/HR';
import ClearAllFields from 'components/ClearAllFields/ClearAllFields';
import Button from 'components/Button/Button';

import AddEvidence from '../components/Evidence/AddEvidence';

import { loadSitesAction, loadUsersAction, createTaskAction } from 'store/actions/Actions';
import { toggleDropdowns } from 'helpers/utils';

const Add = (props) => {
  const {
    SitesReducer,
    UserOrgSiteReducer,
    UsersReducer,
    OrgModulesReducer,
    // addNewIncidentAction,
    loadSitesAction,
    createTaskAction,
    loadUsersAction,
  } = props;
  const defaultReg = (OrgModulesReducer.data && OrgModulesReducer.data.map((r) => r.name)) || [];
  const defaultState = {
    task: '',
    notes: '',
    due: '',
    assignee: '',
    regulatory_act: defaultReg[0] || '',
    selected_site: {},
    assignee_name: '',
  };
  const navigate = useNavigate();
  const [state, setState] = useState(defaultState);
  const [stateErrors, setStateErrors] = useState({
    task: '',
    due: '',
    assignee: '',
    regulatory_act: '',
    completed: '',
    site: '',
    selected_site: {},
  });
  const [tasknotes, setTasknotes] = useState([]);
  const [files, setFiles] = useState([]);
  const [activeTab, setActiveTab] = useState('NOTES');

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    if (!SitesReducer.sites) {
      loadSitesAction();
    }
  }, [SitesReducer.sites]);

  useEffect(() => {
    if (state.selected_site) {
      loadUsersAction({ site: state.selected_site.id });
    }
  }, [state.selected_site]);

  const setTabAsActive = (heading) => () => {
    setActiveTab(heading);
  };

  const handleAssigneeChange = (e) => {
    setState((s) => ({
      ...s,
      assignee: e.target.value,
      assignee_name: e.target.dataset.name,
    }));
    setStateErrors((s) => ({ ...s, assignee: '' }));
  };

  const handleChange = (inputName) => (e) => {
    setState((s) => ({
      ...s,
      [inputName]: e.target.value,
    }));
    setStateErrors((s) => ({ ...s, [inputName]: '' }));
  };

  const handleRegChange = (e) => {
    setState((s) => ({
      ...s,
      regulatory_act: e.target.dataset.name,
    }));
    setStateErrors((s) => ({ ...s, regulatory_act: '' }));
  };

  const handleSiteChange = (e) => {
    setState({
      ...state,
      selected_site: {
        id: e.target.value,
        name: e.target.dataset.name,
      },
      assignee: '',
      assignee_name: '',
    });
    setStateErrors({
      ...stateErrors,
      site: '',
    });
  };

  const addTask = () => {
    if (!state.task || !state.due || !state.assignee || !state.regulatory_act || !state.selected_site.name) {
      setStateErrors({
        ...stateErrors,
        site: !state.selected_site.name ? 'Site is required' : '',
        regulatory_act: !state.regulatory_act ? 'Regulatory act is required' : '',
        task: !state.task ? 'Task description is required' : '',
        due: !state.due ? 'Target date is required' : '',
        assignee: !state.assignee ? 'Assignee is required' : '',
      });
      return;
    }
    createTaskAction({
      site: state.selected_site.id || '',
      notes: state.notes,
      task: state.task,
      assignee: state.assignee,
      due: state.due,
      regulatory_act: state.regulatory_act,
      tasknotes: tasknotes,
      files: files,
    });
    navigate('/achieve/tasks');
  };

  // Clear all fields
  const clearAllFields = () => {
    setState(defaultState);
    setTasknotes([]);
    setFiles([]);
  };

  // Close dropdown when clicking outside them
  const assigneeDropdownRef = React.createRef();
  const siteDropdownRef = React.createRef();
  const regDropdownRef = React.createRef();

  let usersList = {};
  if (UsersReducer && UsersReducer.users) {
    UsersReducer.users.forEach((user) => {
      usersList[user.id] = user.name || user.email;
    });
  }

  const is_officer = (UserOrgSiteReducer.selected && UserOrgSiteReducer.selected.is_officer) || false;
  const is_super = (UserOrgSiteReducer.selected && UserOrgSiteReducer.selected.is_super) || false;
  const is_coach = (UserOrgSiteReducer.selected && UserOrgSiteReducer.selected.is_coach) || false;

  let type = '';
  if (state.subtask === 'remediation') {
    type = 'Remediation';
  } else if (state.subtask === 'attestation') {
    type = 'Attestation';
  } else if (state.subtask === 'incident') {
    type = 'Incident';
  } else if (state.subtask === 'auditResponse') {
    type = 'Question';
  } else {
    type = 'Miscellaneous';
  }

  return (
    <AppLayoutWrapFull onClick={toggleDropdowns([siteDropdownRef, assigneeDropdownRef, regDropdownRef])}>
      <PageHeader />
      <div>
        <form className={styles['add-new-incident-form']}>
          <Container wide className={styles['add-new-incident-container']}>
            <FormElement labelText="TASK NAME" htmlFor="task" smallText="*" errorMessage={stateErrors.task}>
              <textarea
                style={{ padding: '10px', resize: 'vertical' }}
                rows={3}
                defaultValue={state['task']}
                placeholder="Enter task name/description"
                onChange={handleChange('task')}
              />
            </FormElement>

            <div style={{ display: 'flex' }}>
              <FormElement labelText="STATUS" htmlFor="completed" errorMessage={stateErrors.completed}>
                <Select labels={{ t: 'Complete', f: 'Incomplete' }} value={'f'} disabled onClick={null}>
                  <li key={`status-incomplete`} data-name={'f'}>
                    Incomplete
                  </li>
                </Select>
              </FormElement>

              <FormElement className={styles.marginLeft} labelText="TYPE" htmlFor="type" errorMessage={stateErrors.completed}>
                <Select value={type} disabled onClick={null}></Select>
              </FormElement>
            </div>

            <FormElement labelText="REGULATORY ACT" htmlFor="regulatory_act" errorMessage={stateErrors.regulatory_act}>
              <Select value={state.regulatory_act} placeholder="Select regulatory act" onClick={(e) => handleRegChange(e)} dropdownToggleRef={regDropdownRef}>
                {OrgModulesReducer &&
                  OrgModulesReducer.data &&
                  OrgModulesReducer.data.map((d) => (
                    <li key={`regid-${d.name.toLowerCase()}`} data-name={d.name}>
                      {d.name}
                    </li>
                  ))}
              </Select>
            </FormElement>

            <FormElement labelText="SITE" htmlFor="site" smallText="*" errorMessage={stateErrors.site}>
              <Select value={state.selected_site.name} placeholder="Select Site" onClick={handleSiteChange} dropdownToggleRef={siteDropdownRef}>
                <li key={`siteid-all`} data-name="All Sites" value="0">
                  All Sites
                </li>
                {SitesReducer &&
                  SitesReducer.sites &&
                  SitesReducer.sites
                    .filter((site) => site.active === 't')
                    .map((site) => (
                      <li key={`siteid-${site.id}`} data-name={site.name} value={site.id}>
                        {site.name}
                      </li>
                    ))}
              </Select>
            </FormElement>

            <FormElement labelText="ASSIGNEE" htmlFor="assignee" smallText="*" errorMessage={stateErrors.assignee}>
              <Select labels={usersList} value={state.assignee} placeholder="Select Assignee" onClick={handleAssigneeChange} dropdownToggleRef={assigneeDropdownRef}>
                {UsersReducer &&
                  UsersReducer.users &&
                  UsersReducer.users
                    .filter((item) => item.registered === 't')
                    .map((user) => (
                      <li key={user.email} data-name={user.name || user.email} value={user.id}>
                        {user.name || user.email}
                      </li>
                    ))}
              </Select>
            </FormElement>

            <FormElement labelText="TARGET DATE" htmlFor="due" smallText="*" errorMessage={stateErrors['due']}>
              <DateInput
                // size="full"
                onChange={handleChange('due')}
                value={state['due']}
              />
            </FormElement>

            <FormElement
              labelText="NOTES"
              htmlFor="notes"
              className={styles.last_note}
              // errorMessage={stateErrors.task}
            >
              <textarea value={state['notes']} placeholder="Enter task notes" onChange={handleChange('notes')} />
            </FormElement>
          </Container>
          <Tabs
            tabs={[
              {
                heading: 'NOTES',
                icon: 'fad fa-tasks',
              },
              {
                heading: 'EVIDENCE',
                icon: 'fad fa-cctv',
              },
            ]}
            activeTab={activeTab}
            setActiveTab={setTabAsActive}
          />
          <Container wide>
            {activeTab === 'NOTES' ? <AddNote userData={props.userData} notes={tasknotes} setTasknotes={setTasknotes} /> : null}

            {activeTab === 'EVIDENCE' ? <AddEvidence username={props.userData.name} files={files} setFiles={setFiles} allowAdd={is_super || is_coach || is_officer} /> : null}

            <HR className={styles.hr} />
            <div className={styles['form-actions']}>
              <ClearAllFields onClick={clearAllFields} />
              <Button className={styles['cancel-button']} text="CANCEL" color="secondary" onClick={() => navigate(-1)} />
              <Button className={styles['update-incident-button']} text="CREATE TASK" onClick={() => addTask()} />
            </div>
          </Container>
        </form>
      </div>
    </AppLayoutWrapFull>
  );
};

const mapStateToProps = (state) => ({
  SitesReducer: state.SitesReducer,
  UsersReducer: state.UsersReducer,
  UserOrgSiteReducer: state.UserOrgSiteReducer,
  userData: state.AuthReducer.userData,
  OrgModulesReducer: state.OrgModulesReducer,
});

const mapDispatchToProps = {
  // addNewIncidentAction,
  loadSitesAction,
  createTaskAction,
  loadUsersAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Add);
