import React from 'react';
import { useMsal } from '@azure/msal-react';
import { Button } from 'components/Button/Button';
import { loginRequest } from '../authConfig';
import styles from '../AzureADModal.module.scss';

export function SignInButton() {
  const { instance } = useMsal();

  const handleLogin = () => {
    instance.loginPopup(loginRequest).catch((error) => {
      console.log(error);
    });
  };

  return (
    <div className={styles.sign_in}>
      <Button className={styles.sign_in_btn} text="Log in with Microsoft" onClick={handleLogin} icon="fa-brands fa-microsoft" />
    </div>
  );
}

export default SignInButton;
