import React from 'react';
import classNames from 'classnames';

import styles from './CostInput.module.scss';

interface IProps {
  /** additional className to add */
  className?: string;
  /** the value of the cost input */
  value?: string;
  /** event handler for cost input (should be setState from parent) */
  onChange?: () => void;
  disabled?: boolean;
}

const defaultProps = {
  className: '',
  value: 0,
  onChange: () => false,
  disabled: false,
};

function CostInput(props: IProps) {
  const style = classNames(props.className, styles['cost-input']);

  return (
    <div className={style}>
      <i className={`fal fa-dollar-sign ${styles['cost-input-icon']}`} />
      <input disabled={props.disabled} type="number" step="100" placeholder="0" value={props.value} onChange={props.onChange} min="0" />
    </div>
  );
}

CostInput.defaultProps = defaultProps;

export default CostInput;
