/* eslint-disable react/no-array-index-key */
import React, { ReactNode } from 'react';
import classNames from 'classnames';
import Container from 'components/Container/Container';
import { ITabProps, Tab } from 'components/Tab/Tab';

import styles from './Tabs.module.scss';

interface IProps {
  className?: string;
  activeTab?: string;
  setActiveTab?: (el: string) => () => void;
  tabs?: ITabProps[];
  children?: ReactNode;
}

const defaultProps = {
  className: '',
  activeTab: '',
  setActiveTab: () => false,
  tabs: [],
  children: undefined,
};

export function Tabs({ className, activeTab, setActiveTab, tabs, children }: IProps) {
  const style = classNames(styles['dashboard-tabs'], {
    [styles[className]]: !!className,
  });

  return (
    <div className={style}>
      <div className={styles.tabs}>
        <Container wide className={styles['tabs-container']}>
          {tabs.map((el, index) => (
            <Tab key={`key-tab-${index}`} heading={el.heading} count={el.count} icon={el.icon} active={activeTab === el.heading} onClick={setActiveTab(el.heading)} />
          ))}
        </Container>
      </div>
      <div className={styles['tabs-content']}>{children}</div>
    </div>
  );
}

Tabs.defaultProps = defaultProps;

export default Tabs;
