import ActionStatusConstants from 'helpers/ActionStatusConstants';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
// import { LOCATION_CHANGE } from "redux-first-history"

const initialState = {
  status: ActionStatusConstants.INITIAL,
  message: null,
};

export default (state = initialState, action) => {
  const actionType = action && action.type ? action.type : null;

  switch (actionType) {
    case ActionTypeConstants.SHOW_SESSION_MODAL:
      return {
        ...state,
        status: ActionStatusConstants.ISBUSY,
        message: 'Session exipred',
      };
    case ActionTypeConstants.HIDE_SESSION_MODAL:
      return {
        ...state,
        status: ActionStatusConstants.ISBUSY,
        message: null,
      };
    default:
  }
  return state;
};
