import ActionStatusConstants from 'helpers/ActionStatusConstants';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { LOCATION_CHANGE } from 'redux-first-history';

const initialState = {
  status: ActionStatusConstants.INITIAL,
  task: undefined,
  message: undefined,
  formErrors: undefined,
};

const TasksReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case ActionTypeConstants.LOAD_ONE_TASK: {
      return {
        ...state,
        status: ActionStatusConstants.ISBUSY,
      };
    }
    case ActionTypeConstants.LOAD_ONE_TASK_SUCCESS: {
      return {
        ...state,
        task: payload?.task || [],
        refresh: payload?.refresh,
        status: ActionStatusConstants.SUCCESS,
        message: payload?.message,
      };
    }
    case ActionTypeConstants.LOAD_ONE_TASK_FAILURE: {
      return {
        ...state,
        status: ActionStatusConstants.FAILURE,
        message: payload?.message,
      };
    }
    case LOCATION_CHANGE: {
      return {
        ...initialState,
      };
    }
    default:
  }
  return state;
};

export default TasksReducer;
