// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useState, useEffect } from 'react';

import Modal from 'components/Modal/Modal';
import Button from 'components/Button/Button';
import Radio from 'components/Radio/Radio';

import Select from 'components/Select/Select';

import { useAppDispatch, useAppSelector } from 'hooks';
import { deleteFolderAction, loadFoldersAction } from 'store/actions/Actions';

import styles from './DeleteFolderModal.module.scss';
import { toggleDropdowns } from 'helpers/utils';

const DeleteFolderModal = (props) => {
  const { setShowModal, selectedFolder, selectedFolders } = props;

  const dispatch = useAppDispatch();
  const folders = useAppSelector((state) => state.FoldersReducer.folders);

  const [state, setState] = useState({
    moveToFolder: false,
  });

  const [parentFolderId, setParentFolderId] = useState(state.parentFolderId);
  const [parentFolder, setParentFolder] = useState(state.parentFolder);

  useEffect(() => {
    if (selectedFolders) {
      dispatch(loadFoldersAction({}));
    }
  }, [selectedFolders]);

  useEffect(() => {
    if (folders) {
      let selected = folders.filter((f) => f.id === parentFolderId);

      if (selected && selected.length === 1) {
        setParentFolder(selected[0].folder_name);
      }
    }
  }, [parentFolderId, folders]);

  const handleChange = (inputName) => (e) => {
    e.persist();
    if (inputName === 'moveToFolder') {
      setParentFolderId(null);
    }
    setState((s) => ({
      ...s,
      [inputName]: e.target.type === 'checkbox' ? e.target.checked : e.target.value === 'true' ? true : e.target.value === 'false' ? false : e.target.value,
    }));
  };

  const statusFolderDropdown = React.createRef();

  return (
    <Modal setShowModal={setShowModal} className={styles.modal}>
      <div onClick={toggleDropdowns([statusFolderDropdown])}>
        <div className={styles['modal-header']}>
          <h2 className={styles.heading}>Delete Item</h2>
        </div>

        {selectedFolders ? (
          <div className={styles['modal-body']}>
            <div className={styles['heading-line']}>The selected folder(s) contain one or more files. Please select a deletion option:</div>
            <div>
              <Radio
                checked={!state.moveToFolder}
                labelText={
                  <span>
                    <strong>Delete everything</strong> (NOTE: Deleted folders and files cannot be recovered).
                  </span>
                }
                onChange={handleChange('moveToFolder')}
                value={false}
              />
              <Radio
                checked={state.moveToFolder}
                labelText={
                  <span>
                    <strong>Move files to new Parent Folder:</strong>
                  </span>
                }
                onChange={handleChange('moveToFolder')}
                value={true}
              />
              <div className={styles['existing-folder']}>
                <Select
                  disabled={!state.moveToFolder}
                  value={parentFolder}
                  menuClassName={styles['parent-folder-select']}
                  dropdownToggleRef={statusFolderDropdown}
                  onClick={(e) => {
                    if (e.target.type !== 'text') {
                      statusFolderDropdown.current.click();
                      setParentFolderId(e.target.value + '');
                    }
                  }}
                >
                  <div className={styles['parent-folder']}>
                    {folders &&
                      folders.map((f) => (
                        <li value={f.id} key={f.id} className={`${styles.folder} ${styles[`depth-${f.level}`]}`}>
                          <i className={f.level === '0' ? 'fas fa-folder' : 'far fa-folder'} />
                          {f.folder_name}
                        </li>
                      ))}
                  </div>
                </Select>
              </div>
            </div>
          </div>
        ) : (
          <div className={styles['modal-body']}>
            <div>Are you sure you want to delete the selected item(s)?</div>
          </div>
        )}

        <div className={styles['modal-footer']}>
          <Button className={styles.button} text="NEVERMIND" onClick={() => setShowModal(false)} color="secondary" />
          <Button
            className={styles.button}
            text="YES, DELETE"
            onClick={() => {
              dispatch(
                deleteFolderAction({
                  items: selectedFolder,
                  parent_folder: props.parentFolder.id,
                  move: parentFolderId,
                })
              );
              setShowModal(false);
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default DeleteFolderModal;
