// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import styles from './AddTaskModal.module.scss';

import Modal from 'components/Modal/Modal';
import FormElement from 'components/FormElement/FormElement';
import Select from 'components/Select/Select';
import Button from 'components/Button/Button';
import DateInput from 'components/DateInput/DateInput';

import { loadUsersAction, loadSitesAction, createTaskAction } from 'store/actions/Actions';
import { toggleDropdowns } from 'helpers/utils';

const AddTaskModal = (props) => {
  const [state, setState] = useState({
    // number: props.tasks.length,
    task: '',
    notes: '',
    due: '',
    assignee: '',
    selected_site: {},
    assignee_name: '',
  });

  const [stateErrors, setStateErrors] = useState({
    task: '',
    due: '',
    assignee: '',
    selected_site: {},
  });

  const { loadSitesAction, createTaskAction, loadUsersAction, UsersReducer, SitesReducer } = props;

  useEffect(() => {
    if (!SitesReducer.sites) {
      loadSitesAction();
    }
  }, [SitesReducer.sites, loadSitesAction]);

  useEffect(() => {
    if (!UsersReducer.users) {
      loadUsersAction();
    }
  }, [UsersReducer.users, loadUsersAction]);

  useEffect(() => {
    if (state.selected_site.id) {
      loadUsersAction({ site: state.selected_site.id });
    }
  }, [loadUsersAction, state.selected_site.id]);

  const handleAssigneeChange = (e) => {
    e.persist();
    setState((s) => ({
      ...s,
      assignee: e.target.value,
      assignee_name: e.target.dataset.name,
    }));
    setStateErrors((s) => ({ ...s, assignee: '' }));
  };

  const handleChange = (inputName) => (e) => {
    e.persist();
    setState((s) => ({
      ...s,
      [inputName]: e.target.value,
    }));
    setStateErrors((s) => ({ ...s, [inputName]: '' }));
  };

  const addTask = () => {
    if (!state.task || !state.due || !state.assignee) {
      setStateErrors({
        ...stateErrors,
        site: !state.selected_site.id ? 'Site is required' : '',
        task: !state.task ? 'Task description is required' : '',
        due: !state.due ? 'Target date is required' : '',
        assignee: !state.assignee ? 'Assignee is required' : '',
      });
      return;
    }
    createTaskAction({
      site: state.selected_site.id,
      notes: state.notes,
      task: state.task,
      assignee: state.assignee,
      due: state.due,
    });
    props.setShowModal(false);
  };

  let usersList = {};
  if (UsersReducer && UsersReducer.users) {
    UsersReducer.users
      .filter((item) => item.registered === 't')
      .forEach((user) => {
        usersList[user.id] = user.name || user.email;
      });
  }

  const handleSiteChange = (e) => {
    setState({
      ...state,
      selected_site: {
        id: e.target.value,
        name: e.target.dataset.name,
      },
      assignee: '',
      assignee_name: '',
    });
    setStateErrors({
      ...stateErrors,
      site: '',
    });
  };

  const assigneeDropdownRef = React.createRef();
  const siteDropdownRef = React.createRef();

  return (
    <Modal className={styles.modal} setShowModal={props.setShowModal}>
      <div onClick={toggleDropdowns([siteDropdownRef, assigneeDropdownRef])}>
        <p className={styles['modal-heading']}>Add Task</p>

        <div className={styles['modal-content']}>
          <FormElement labelText="SITE" htmlFor="site" errorMessage={stateErrors.site}>
            <Select value={state.selected_site.name} placeholder="Select Site" onClick={handleSiteChange} dropdownToggleRef={siteDropdownRef}>
              {SitesReducer &&
                SitesReducer.sites &&
                SitesReducer.sites.map((site) => (
                  <li key={`siteid-${site.id}`} data-name={site.name} value={site.id}>
                    {site.name}
                  </li>
                ))}
            </Select>
          </FormElement>

          <br />
          <br />

          <FormElement labelText="TASK NAME" htmlFor="task" errorMessage={stateErrors.task}>
            <input type="text" value={state['task']} placeholder="Enter task name/description" onChange={handleChange('task')} />
          </FormElement>

          <br />
          <br />

          <FormElement
            labelText="NOTES"
            htmlFor="notes"
            // errorMessage={stateErrors.task}
          >
            <textarea
              style={{ padding: '10px', resize: 'vertical' }}
              // disabled
              type="text"
              value={state['notes']}
              placeholder="Enter task notes"
              onChange={handleChange('notes')}
            />
          </FormElement>

          <br />
          <br />

          <div className={styles.holder}>
            <FormElement labelText="TARGET DATE" htmlFor="due" errorMessage={stateErrors['due']}>
              <DateInput size="full" onChange={handleChange('due')} value={state['due']} />
            </FormElement>

            <FormElement labelText="ASSIGNEE" htmlFor="assignee" errorMessage={stateErrors.assignee}>
              <Select labels={usersList} value={state.assignee} placeholder="Select Assignee" onClick={handleAssigneeChange} dropdownToggleRef={assigneeDropdownRef}>
                {UsersReducer &&
                  UsersReducer.users &&
                  UsersReducer.users
                    .filter((item) => item.registered === 't')
                    .map((user) => (
                      <li key={user.email} data-name={user.name || user.email} value={user.id}>
                        {user.name || user.email}
                      </li>
                    ))}
              </Select>
            </FormElement>
          </div>
        </div>

        <div className={styles['modal-footer']}>
          <Button text="CANCEL" color="secondary" onClick={() => props.setShowModal(false)} />
          <Button text="ADD TASK" className={styles.create} onClick={() => addTask()} />
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  UsersReducer: state.UsersReducer,
  SitesReducer: state.SitesReducer,
});

const mapDispatchToProps = {
  loadUsersAction,
  loadSitesAction,
  createTaskAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTaskModal);
