import React from 'react';

import Line from './Line';

import styles from './LineChartBox.module.scss';

const LineChartBoxUser = ({ stats = {} }) => {
  // console.log(stats);
  return (
    <div className={styles.linechart}>
      <div className={styles.holder}>
        <div className={styles.left}>
          <h2>
            Progress
            <br />
            breakdown
          </h2>
          {/* <p>Click on each bar to see more information about the category</p> */}
        </div>
        <div className={styles.right}>
          <Line stats={stats.training || 0} backgroundColor={'#34c7594b'} color={'#34C759'} label={'Training Tasks'} />
          <Line stats={stats.audits || 0} backgroundColor={'#546ADC50'} color={'#546ADC'} label={'Audit Questions'} />
          <Line stats={stats.documents || 0} backgroundColor={'#FF2D5550'} color={'#FF2D55'} label={'Documents'} />
          <Line stats={stats.my || 0} backgroundColor={'#FF900050'} color={'#FF9000'} label={'Miscellaneous'} />
        </div>
      </div>
    </div>
  );
};

export default LineChartBoxUser;
