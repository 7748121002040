import ActionTypeConstants from 'helpers/ActionTypeConstants';

export interface IState {
  isMenuExpanded: Boolean;
}

const initialState: IState = {
  isMenuExpanded: false,
};

export default (state = initialState, { type, payload }): IState => {
  switch (type) {
    case ActionTypeConstants.TOGGLE_NAVIGATION: {
      return {
        ...state,
        isMenuExpanded: payload,
      };
    }
    default:
  }
  return state;
};
