import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

// import ProtectedRoute from "components/ProtectedRoute/ProtectedRoute";
import Affiliates from './Affiliates/Affiliates';
import EditAffiliates from './Affiliates/Edit/Edit';
import AddAffiliates from './Affiliates/Add/Add';
import Audits from './Audits/Audits';
import Documents from './Documents/Documents';
import UnapprovedDocuments from './Documents/UnapprovedDocuments';
import Editor from './Documents/Editor/Editor';
import Player from './Documents/Player/Player';
import Remediation from './Remediation/Remediation';
import Add from './Remediation/Add/Add';
import Edit from './Remediation/Edit/Edit';
import Tasks from './Tasks/Tasks';
import AddTask from './Tasks/Add/Add';
import EditTask from './Tasks/Edit/Edit';
import Training from './Training/Training';
// import TrainingEdit from "./Training/Training/Training";
import Audit from './Audits/Audit/Audit';
import AuditDevice from './Audits/Audit/AuditDevice';
import AuditCloud from './Audits/Audit/AuditCloud';
// import NotFound from "views/Welcome/NotFound";
import AuditDeviceInventory from './Audits/Audit/AuditDeviceInventory';

function Achieve() {
  return (
    <Routes>
      <Route path="affiliates" element={<Affiliates />} />
      <Route path="affiliates/edit" element={<EditAffiliates />} />
      <Route path="affiliates/add" element={<AddAffiliates />} />

      <Route path="audits/edit/:auditId" element={<Audits />} />
      <Route path="audits/audit" element={<Audit />} />
      <Route path="audits" element={<Audits />} />
      <Route path="audits/audit-device" element={<AuditDevice />} />
      <Route path="audits/audit-inventory" element={<AuditDeviceInventory />} />
      <Route path="audits/audit-cloud" element={<AuditCloud />} />

      <Route path="documents" element={<Documents />} />
      <Route path="documents/unapproved" element={<UnapprovedDocuments />} />
      <Route path="documents/editor/:id/:mode" element={<Editor />} />
      <Route path="documents/player/:id/" element={<Player />} />

      <Route path="documents/:folderId1" element={<Documents />}>
        <Route path=":folderId2" element={<Documents />}>
          <Route path=":folderId3" element={<Documents />}>
            <Route path=":folderId4" element={<Documents />}>
              <Route path=":folderId5" element={<Documents />}>
                <Route path=":folderId6" element={<Documents />}>
                  <Route path=":folderId7" element={<Documents />}>
                    <Route path=":folderId8" element={<Documents />} />
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>

      <Route path="remediation" element={<Remediation />} />
      <Route path="remediation/add" element={<Add />} />
      <Route path="remediation/edit" element={<Edit />} />

      <Route path="tasks" element={<Tasks />} />
      <Route path="tasks/add" element={<AddTask />} />
      <Route path="tasks/edit/:taskId" element={<EditTask />} />

      <Route path="training/:trainingId" element={<Training />} />
      <Route path="training" element={<Training />} />

      {/* <Route exact path="/achieve/training/edit" element={TrainingEdit} /> */}
      <Route path="*" element={<Navigate to="/404" replace />} />
    </Routes>
  );
}

export default Achieve;
