import { call, put, takeLatest, delay } from 'redux-saga/effects';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { actionApiCall } from 'helpers/api/ApiCalls';
import * as sagaConstants from 'helpers/constants/SagasConstants';

function transformUsers(users) {
  const result = users.map((user) => {
    user.status = user.registered === 'f' ? 'Awaiting Registration' : user.active === 't' ? 'Active' : 'Inactive';
    user.organizations = user.organizations ? user.organizations.join(', ') : '-';
    return user;
  });
  return result;
}

function* searchUsersSaga({ payload }) {
  try {
    const apiResponse = yield call(actionApiCall, {
      actionType: ActionTypeConstants.SEARCH_USERS,
      data: {
        column: payload.column,
        term: payload.term,
      },
    });
    if (apiResponse.res.data.response === 200) {
      let { data } = apiResponse.res.data;
      if (!Array.isArray(data)) {
        data = [data];
      }
      yield put({
        type: ActionTypeConstants.SEARCH_USERS_SUCCESS,
        payload: {
          users: transformUsers(data),
        },
      });
    } else {
      yield put({
        type: ActionTypeConstants.SEARCH_USERS_FAILURE,
        payload: {
          message: apiResponse.res.data.message || `${sagaConstants.ISSUE_OCCURED} searching users`,
        },
      });
    }
  } catch (err) {
    yield put({
      type: ActionTypeConstants.SEARCH_USERS_FAILURE,
      payload: {
        message: `${sagaConstants.SERVER_ERROR}`,
      },
    });
  }
}

function* searchUsersSagaFail({ payload }) {
  yield put({
    type: ActionTypeConstants.SHOW_ALERT,
    payload: {
      message: payload.message || 'An issue occurred while searching users',
      color: 'danger',
    },
  });
  yield delay(10000);
  yield put({ type: ActionTypeConstants.HIDE_ALERT });
}

export default function* () {
  yield takeLatest(ActionTypeConstants.SEARCH_USERS, searchUsersSaga);
  yield takeLatest(ActionTypeConstants.SEARCH_USERS_FAILURE, searchUsersSagaFail);
}
