import React, { useState } from 'react';
import Modal from 'components/Modal/Modal';
import { Button } from 'components/Button/Button';
import Radio from 'components/Radio/Radio';
import { connect } from 'react-redux';
import Label from 'components/Label/Label';

import { checkQuizAnswersAction, resetQuizAnswersAction, loadQuizQuestionsAction } from 'store/actions/Actions';

import styles from './QuizModal.module.scss';

function QuizModal(props) {
  const { checkQuizAnswersAction, training_id, quiz_section, QuizPassedReducer, data, player } = props;
  const [activeQuestion, setActiveQuestion] = useState(0);
  const [answers, setAnswers] = useState({});
  const [isSubmit, setSubmit] = useState(false);

  const q = data.questions[activeQuestion];

  const handleSubmit = () => {
    const result = {
      training_id,
      quiz_section,
      questions: Object.entries(answers).map((item) => ({
        quiz_question: item[0],
        answer: item[1],
      })),
    };
    checkQuizAnswersAction(result);
    setSubmit(true);
  };

  const canSubmit = data.questions.length === Object.keys(answers).length || false;

  const closeModal = (seek) => {
    props.setShowModal(false);
    props.loadQuizQuestionsAction({ training: training_id });
    props.resetQuizAnswersAction();
    if (seek === 'start') {
      player.current.seekTo(data.start_time);
    }
    if (seek === 'end') {
      player.current.seekTo(data.end_time);
    }
  };

  return (
    <Modal className={styles.modal} setShowModal={() => closeModal(QuizPassedReducer.section_passed ? 'end' : 'start')}>
      <div>
        <p className={styles['modal-heading']}>{isSubmit ? 'Quiz Summary' : data.title || 'Quiz'}</p>

        <div className={styles['modal-content']}>
          {QuizPassedReducer.status === 'ISBUSY' && <div className={styles.wait}>Please wait...</div>}

          {isSubmit && QuizPassedReducer.section_hints && (
            <div>
              {QuizPassedReducer.section_hints.map((s, index) => (
                <div className={styles['answer-wrapper']} key={s.question}>
                  <h5 style={{ margin: '0' }}>
                    <Label backgroundColor="#dddddd" color="#999999" margin="0 10px 0 0">
                      QUESTION {index + 1}
                    </Label>{' '}
                    {s.question_label}
                  </h5>
                  <div>
                    <p style={{ margin: '8px 0' }}>
                      <Label backgroundColor={!s.hint ? '#34C759' : '#FF2D55'} color="#ffffff" icon={!s.hint ? 'fal fa-check' : 'fal fa-times'} margin="0 10px 0 0">
                        ANSWER
                      </Label>{' '}
                      {s.answer_label}
                    </p>
                    <p style={{ margin: '0' }}>
                      {s.hint && (
                        <span>
                          <i className="fal fa-question-circle" style={{ color: 'lightgrey', marginRight: '10px' }} />
                          <span style={{ color: 'lightgrey' }}>{s.hint}</span>
                        </span>
                      )}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          )}

          {!isSubmit && (
            <div key={`qid-${q.id}`}>
              <Label backgroundColor="#C7E9F7" color="#000000" padding="8px 18px" icon="fal fa-check" borderRadius="25px" fontWeight="500">
                QUESTION {`${activeQuestion + 1}/${data.questions.length}`}
              </Label>
              <h3 style={{ margin: '20px 0 25px 0' }}>{q.label}</h3>
              {q.answers.map((a) => (
                <div key={`aid-${a.id}`} className={styles.answers}>
                  <Radio checked={answers[q.id] === a.id} labelText={a.label} onChange={() => setAnswers((s) => ({ ...s, [q.id]: a.id }))} />
                </div>
              ))}
              <br />
            </div>
          )}
        </div>

        <div className={styles['modal-footer']}>
          {isSubmit && !QuizPassedReducer.section_passed && (
            <>
              <Button
                text="REPLAY VIDEO"
                color="secondary"
                className={styles.back}
                onClick={() => {
                  closeModal('start');
                }}
              />
              <Button
                text="TRY QUIZ AGAIN"
                color="primary"
                className={styles.create}
                onClick={() => {
                  closeModal('end');
                }}
              />
            </>
          )}
          {isSubmit && QuizPassedReducer.section_passed && (
            <Button
              text="CLOSE"
              color="secondary"
              className={styles.create}
              onClick={() => {
                closeModal(false);
              }}
            />
          )}
          {!isSubmit && (
            <div className={styles['next-back-buttons-wrapper']}>
              <Button
                text="BACK"
                disabled={activeQuestion === 0}
                color="secondary"
                icon="fal fa-long-arrow-left"
                className={activeQuestion === 0 ? styles['back-disabled'] : styles.back}
                onClick={() => setActiveQuestion(activeQuestion - 1)}
              />
              <Button
                text="NEXT"
                disabled={activeQuestion === data.questions.length - 1}
                color="primary"
                iconEnd="fal fa-long-arrow-right"
                className={styles.create}
                onClick={() => setActiveQuestion(activeQuestion + 1)}
              />
            </div>
          )}
          {!isSubmit && !QuizPassedReducer.section_passed && (
            <div className={styles['submit-button-wrapper']}>
              <Button text="SUBMIT" color="primary" disabled={!(!isSubmit && canSubmit)} className={styles.submit} onClick={() => handleSubmit()} />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  QuizReducer: state.QuizReducer,
  QuizPassedReducer: state.QuizPassedReducer,
});

const mapDispatchToProps = {
  checkQuizAnswersAction,
  resetQuizAnswersAction,
  loadQuizQuestionsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuizModal);
