import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Accordion from 'components/Accordion/Accordion';

import EditEvidence from 'views/Achieve/Audits/Audit/components/Evidence/EditEvidence';

import styles from './DashboardViews.module.scss';

function QuestionFinalize(props) {
  const { task } = props;

  const [evidence, setEvidence] = useState([]);

  useEffect(() => {
    if (!task || !task.subtask_data) return;
    setEvidence(task && task.evidence);
  }, [task]);

  return (
    <div className={styles.question}>
      <div className={styles.title_holder}>
        <i className={`fal fa-check-circle ${styles.icon}`} />
        <h3>Task Finalized</h3>
        <p>You have submitted answer of the audit question.</p>
      </div>

      <div className={styles.task_holder}>
        <div>
          <div>Question:</div>
          <div>{task.task}</div>
        </div>

        <div>
          <div>Answer:</div>
          <div>{task.subtask_data.answer === '2' && 'Yes'}</div>
          <div>{task.subtask_data.answer === '1' && 'No'}</div>
          <div>{task.subtask_data.answer === '3' && 'Not Applicable'}</div>
          <div>{task.subtask_data.answer === '4' && 'Not Applicable'}</div>
        </div>

        <div>
          <div>Risk level:</div>
          <div>{task.subtask_data.risk}</div>
        </div>

        <div>
          <div>Notes:</div>
          <div>{task.subtask_data.notes}</div>
        </div>

        <div>
          <div>Requested date:</div>
          <div>{task.assigned ? moment(task.assigned).format('MM/DD/YYYY') : ''}</div>
        </div>

        <div>
          <div>Type:</div>
          <div>Question</div>
        </div>

        {task.siteName && (
          <div>
            <div>Site:</div>
            <div>{task.siteName}</div>
          </div>
        )}

        {evidence && evidence.length > 0 && (
          <div>
            <div style={{ padding: '0', width: '100%' }}>
              <Accordion
                title={<span>DOWNLOAD EVIDENCE</span>}
                extraTitle={`FILES: ${evidence?.length || 0}`}
                content={
                  <EditEvidence
                    responseId={task && task.id}
                    files={evidence || []}
                    isFinalized
                    auditId={task?.subtask_data?.audit}
                    allowDelete={false}
                    taskId={task && task.id}
                    requestType="task"
                  />
                }
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default QuestionFinalize;
