import React from 'react';
import styles from './StatisticsSnD.module.scss';

const StatisticsSnD = ({ statsTitle, totalSites, totalDepartments, iconClass }) => {
  return (
    <div className={styles.statistics}>
      <div className={styles.icon}>
        <div className={styles['icon__badge']}>
          <i className={iconClass}></i>
        </div>
      </div>
      <div className={styles.page}>
        <div className={styles['page-title']}>{statsTitle}</div>
        <div className={styles['page-stats']}>
          <div className={styles['page-stats__sites-departments']}>
            <div className={styles['page-stats__total']}>{totalSites}</div>
            <div className={styles['page-stats__title']}>
              <small>Total</small>
              <small>Sites</small>
            </div>
          </div>
          <div className={styles.divider}></div>
          <div className={styles['page-stats__sites-departments']}>
            <div className={styles['page-stats__total']}>{totalDepartments}</div>
            <div className={styles['page-stats__title']}>
              <small>Total</small>
              <small>Departments</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatisticsSnD;
