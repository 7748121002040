import { push } from 'redux-first-history';
import { call, put, takeLatest, delay, select } from 'redux-saga/effects';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { actionApiCall } from 'helpers/api/ApiCalls';
import * as sagaConstants from 'helpers/constants/SagasConstants';
import { redirectToRemediations } from 'helpers/utils';

const getSelected = (state) => state.UserOrgSiteReducer.selected;

function* submitEditRemediationPlanFormSaga({ payload }) {
  try {
    const selected = yield select(getSelected);
    const apiResponse = yield call(actionApiCall, {
      actionType: ActionTypeConstants.SUBMIT_EDIT_REMEDIATION_PLAN_FORM,
      data: {
        organization: selected.org,
        site: payload.site,
        associated_gap: payload.associated_gap,
        end_date: payload.end_date,
        estimated_cost: payload.estimated_cost,
        id: payload.id,
        notes: payload.notes,
        start_date: payload.start_date,
        status: payload.status,
        title: payload.title,
        regulatory_act: payload.regulatory_act,
        tasks: payload.tasks,
        user_id: payload.user_id,
        owner: payload.owner,
      },
    });
    if (apiResponse.res.data.response === 200) {
      yield put(push(redirectToRemediations(selected.ui_version)));
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: apiResponse.res.data.message || `The remediation plan ${sagaConstants.SUCCESSFULLY_UPDATED}`,
          color: 'success',
        },
      });
      yield delay(10000);
      yield put({ type: ActionTypeConstants.HIDE_ALERT });
    } else {
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: apiResponse.res.data.message || `${sagaConstants.ISSUE_OCCURED} updating the remediation plan`,
          color: 'danger',
        },
      });
      yield delay(10000);
      yield put({ type: ActionTypeConstants.HIDE_ALERT });
    }
  } catch (err) {
    yield put({
      type: ActionTypeConstants.SHOW_ALERT,
      payload: {
        message: `${sagaConstants.SERVER_ERROR}`,
        color: 'danger',
      },
    });
    yield delay(10000);
    yield put({ type: ActionTypeConstants.HIDE_ALERT });
  }
}

export default function* () {
  yield takeLatest(ActionTypeConstants.SUBMIT_EDIT_REMEDIATION_PLAN_FORM, submitEditRemediationPlanFormSaga);
}
