import React, { useState } from 'react';

// import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { ClickAwayListener } from '@mui/base';

import { Link } from 'react-router-dom';
import { authLogoutAction } from 'store/actions/Actions';
import classNames from 'classnames';
import { useAppDispatch, useAppSelector } from 'hooks';

function getRole({ is_site_admin, is_org_admin, is_org_admin_label, is_officer, is_super, is_coach }) {
  let userRole = '';
  if (is_site_admin) {
    userRole = ' / Site Admin';
  }
  if (is_org_admin || is_org_admin_label) {
    userRole = ' / Org Admin';
  }
  if (is_officer) {
    userRole = ' / Officer';
  }
  if (is_super) {
    userRole = ' / Super Admin';
  }
  if (is_coach) {
    userRole = ' / Coach';
  }
  return userRole;
}

function UserPanel() {
  const [state, setState] = useState({ isUserMenuOpen: false });
  const dispatch = useAppDispatch();
  const AuthReducer = useAppSelector((state) => state.AuthReducer);
  const UserOrgSiteReducer = useAppSelector((state) => state.UserOrgSiteReducer);

  const orgId = Number.parseInt(UserOrgSiteReducer?.selected?.org, 10);
  const siteName = (orgId && UserOrgSiteReducer?.organizations && UserOrgSiteReducer?.organizations[orgId]?.sites[UserOrgSiteReducer.selected.site]) || 'All Sites';
  const companyName = (UserOrgSiteReducer?.organizations && UserOrgSiteReducer?.organizations[orgId]?.name) || '';
  const userRole = UserOrgSiteReducer.selected ? getRole(UserOrgSiteReducer.selected) : '';

  const logout = async () => {
    dispatch(authLogoutAction());
  };

  const toggleUserMenu = () => {
    setState((s) => ({ isUserMenuOpen: !s.isUserMenuOpen }));
  };

  const userPanelClassNames = classNames('user-panel', {
    'user-menu-open': state.isUserMenuOpen === true,
  });
  const userMenuClassNames = classNames('user-menu', {
    open: state.isUserMenuOpen === true,
  });

  return (
    <ClickAwayListener onClickAway={() => toggleUserMenu()}>
      <>
        <div className={userPanelClassNames} aria-hidden="true" onClick={() => toggleUserMenu()}>
          <div className="user-info">
            <div className="user-name">{AuthReducer.userData ? AuthReducer.userData.name : ''}</div>
            <div className="user-company">
              {companyName}
              <br />
              <small>
                {siteName}
                {userRole}
              </small>
            </div>
          </div>
          <div className="user-menu-arrow">
            <i className="fas fa-caret-right" />
          </div>
        </div>
        <div className={userMenuClassNames}>
          <ul>
            <li>
              <Link to="/settings">
                <i className="fas fa-cogs" />
                <span>Settings</span>
              </Link>
            </li>
            <li>
              <Link to="/certificates">
                <i className="fas fa-file-certificate" />
                <span>Certificates</span>
              </Link>
            </li>
            <li>
              <a href="https://compliancygroup.freshdesk.com/support/home" target="_blank" rel="noopener noreferrer">
                <i className="fas fa-user-graduate" />
                <span>Knowledge Base</span>
              </a>
            </li>
            <li>
              <Link to="/login/site">
                <i className="fal fa-city" />
                <span>Change Org</span>
              </Link>
            </li>
            <li>
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  logout();
                }}
              >
                <i className="fas fa-sign-out" />
                <span>Logout</span>
              </a>
            </li>
          </ul>
        </div>
      </>
    </ClickAwayListener>
  );
}

export default UserPanel;
