import React from 'react';
import DataTableRow from './DataTableRow';

function DataTableRows(props) {
  const renderRows = () => {
    if (props.rowsData && props.rowsData.length > 0) {
      const rows = props.rowsData.map((rowData, i) => (
        <DataTableRow
          key={`${rowData.id}-${i}`}
          index={i}
          rowData={rowData}
          allowDisable={props.allowDisable}
          colDefs={props.colDefs}
          customTableProps={props.customTableProps}
          visibleColumnKeys={props.visibleColumnKeys}
          onRowClick={props.onRowClick}
        />
      ));
      return rows;
    }
    return false;
  };
  return renderRows();
}

export default DataTableRows;
