import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';

import moment from 'moment';
// import Container from 'components/Container/Container';

import FormElement from 'components/FormElement/FormElement';
import Select from 'components/Select/Select';
import Row from 'components/Row/Row';
import DateInput from 'components/DateInput/DateInput';
import Checkbox from 'components/Checkbox/Checkbox';
import HR from 'components/HR/HR';
import Button from 'components/Button/Button';
import { addNewIncidentAction, loadSitesAction, loadAffiliatesAction, loadIncidentTypesAction } from 'store/actions/Actions';
import { toggleDropdowns } from 'helpers/utils';
import AddEvidence from 'views/Maintain/Incidents/Evidence/AddEvidence';

import styles from './IncidentPage.module.scss';

const IncidentPage = (props) => {
  const navigate = useNavigate();

  const {
    isAdmin,
    // allowAdd,
    SitesReducer,
    IncidentTypesReducer,
    AffiliatesReducer,
    addNewIncidentAction,
    loadSitesAction,
    loadAffiliatesAction,
    loadIncidentTypesAction,
    OrgModulesReducer,
    // UserOrgSiteReducer,
    AddIncidentFormReducer,
  } = props;

  const defaultReg = (OrgModulesReducer.data && OrgModulesReducer.data.map((r) => r.name)) || [];
  const clearState = {
    site: '',
    affiliate: '',
    name: '',
    reported_by: 'Anonymous',
    type: '',
    reason: '',
    regulatory_act: defaultReg[0] || '',
    description: '',
    occurred: '',
    discovered: '',
    affected: false,
    active: true,
    investigating: true,
  };

  const clearErrors = {
    site: '',
    affiliate: '',
    name: '',
    reported_by: '',
    type: '',
    reason: '',
    description: '',
    occurred: '',
    discovered: '',
  };

  const [stateErrors, setStateErrors] = useState(clearErrors);

  const [state, setState] = useState(clearState);
  const [files, setFiles] = useState([]);
  const [clear, setClear] = useState(false);

  useEffect(() => {
    if (!SitesReducer.sites) {
      loadSitesAction();
    }
  }, [SitesReducer.sites, loadSitesAction]);

  useEffect(() => {
    if (!IncidentTypesReducer.incidentTypes && state.regulatory_act && state.regulatory_act !== '') {
      loadIncidentTypesAction({ regulatory_act: state.regulatory_act });
    }
  }, [IncidentTypesReducer.incidentTypes, loadIncidentTypesAction]);

  useEffect(() => {
    if (state.regulatory_act && state.regulatory_act !== '') {
      loadIncidentTypesAction({ regulatory_act: state.regulatory_act });
    }
  }, [loadIncidentTypesAction, state.regulatory_act]);

  useEffect(() => {
    if (!AffiliatesReducer.affiliates) {
      loadAffiliatesAction();
    }
  }, [AffiliatesReducer.affiliates, loadAffiliatesAction]);

  const handleCheckbox = (inputName) => (e) => {
    e.persist();
    setState((s) => ({
      ...s,
      [inputName]: e.target.checked,
    }));
    setStateErrors((s) => ({ ...s, [inputName]: '' }));
  };

  const handleSelect = (inputName) => (e) => {
    e.persist();
    setState((s) => ({
      ...s,
      [inputName]: e.target.attributes && e.target.attributes.value ? e.target.attributes.value.value : '',
    }));
    setStateErrors((s) => ({ ...s, [inputName]: '' }));
  };

  const handleRegSelect = (inputName) => (e) => {
    e.persist();
    setState((s) => ({
      ...s,
      [inputName]: e.target.attributes && e.target.attributes.value ? e.target.attributes.value.value : '',
      type: '',
    }));
    setStateErrors((s) => ({ ...s, [inputName]: '', type: '' }));
  };

  const handleChange = (inputName) => (e) => {
    e.persist();
    setState((s) => ({
      ...s,
      [inputName]: e.target.value,
    }));
    setStateErrors((s) => ({ ...s, [inputName]: '' }));
  };

  const openIncidents = () => navigate('/incidents');

  const siteRef = React.createRef();
  const vendorReportedRef = React.createRef();
  const incidentTypeRef = React.createRef();
  const reportedByRef = React.createRef();
  const regTypeRef = React.createRef();

  const submitIncident = () => {
    if (state.site === '' || state.name === '' || state.type === '' || state.regulatory_act === '' || state.occurred === '' || state.discovered === '') {
      setStateErrors({
        ...stateErrors,
        site: state.site === '' ? 'Site is required' : '',
        name: state.name === '' ? 'Incident title is required' : '',
        regulatory_act: state.regulatory_act === '' ? 'Regulatory act is required' : '',
        type: state.type === '' ? 'Incident type is required' : '',
        occurred: state.occurred === '' ? 'Incident date is required' : '',
        discovered: state.discovered === '' ? 'Discovery date is required' : '',
      });
    } else {
      // window.scrollTo({ top:0, behavior:"smooth" });
      addNewIncidentAction({ ...state, files });
      // setState(clearState);
      // setFiles([]);
    }
  };

  const typesList = {};
  IncidentTypesReducer.incidentTypes &&
    IncidentTypesReducer.incidentTypes.forEach((incident) => {
      typesList[incident.id] = incident.name;
    });

  const sitesList = {};
  SitesReducer &&
    SitesReducer.sites &&
    SitesReducer.sites.forEach((site) => {
      sitesList[site.id] = site.name;
    });

  const affiliatesList = {};
  AffiliatesReducer.affiliates &&
    AffiliatesReducer.affiliates.forEach((affiliate) => {
      affiliatesList[affiliate.id] = affiliate.name;
    });

  // const is_officer = (UserOrgSiteReducer.selected && UserOrgSiteReducer.selected.is_officer) || false;
  // const is_super = (UserOrgSiteReducer.selected && UserOrgSiteReducer.selected.is_super) || false;

  return (
    <>
      {/* <Container> */}
      <div className={styles['tab-incidents']} onClick={toggleDropdowns([siteRef, vendorReportedRef, incidentTypeRef, reportedByRef, regTypeRef])}>
        <div className={styles.header}>
          <h3>Report an Incident</h3>
          {isAdmin && <Button onClick={openIncidents} className={styles.button} text="GO TO INCIDENTS" iconEnd={`fal fa-long-arrow-right ${styles['button-arrow']}`} />}
        </div>
        <form className={styles['report-an-incident-form']}>
          <FormElement labelText="SITE" htmlFor="site" smallText="*" errorMessage={stateErrors.site || ''}>
            <Select labels={sitesList} value={state.site} placeholder="Select Site" onClick={handleSelect('site')} dropdownToggleRef={siteRef}>
              {SitesReducer.sites &&
                SitesReducer.sites.map((site) => (
                  <li key={`siteid-${site.id}`} data-name={site.name} value={site.id}>
                    {site.name}
                  </li>
                ))}
            </Select>
          </FormElement>
          <FormElement labelText="VENDOR REPORTED" htmlFor="affiliate" smallText="(if any)" errorMessage={stateErrors['affiliate'] || ''}>
            <Select
              labels={affiliatesList}
              placeholder="** No Vendor reported"
              value={state['affiliate']}
              onClick={handleSelect('affiliate')}
              dropdownToggleRef={vendorReportedRef}
            >
              {AffiliatesReducer.affiliates &&
                AffiliatesReducer.affiliates.map((type) => (
                  <li key={`it_id-${type.id}`} data-name={type.name} value={type.id}>
                    {type.name}
                  </li>
                ))}
            </Select>
          </FormElement>
          <FormElement labelText="INCIDENT TITLE" htmlFor="name" smallText="*" errorMessage={stateErrors['name'] || ''}>
            <input value={state['name']} onChange={handleChange('name')} />
          </FormElement>
          <FormElement labelText="REPORTED BY" htmlFor="reported_by" errorMessage={stateErrors['reported_by'] || ''}>
            <Select
              value={state['reported_by']}
              onClick={handleSelect('reported_by')}
              labels={{
                [`${props.userData.name}`]: `${props.userData.name}`,
                Anonymous: 'Anonymous',
              }}
              dropdownToggleRef={reportedByRef}
            >
              <li value={`${props.userData.name}`}>{props.userData.name}</li>
              <li value={'Anonymous'}>Anonymous</li>
            </Select>
          </FormElement>

          <FormElement labelText="PROGRAM" htmlFor="regulatory_act" smallText="*" errorMessage={stateErrors['regulatory_act'] || ''}>
            <Select placeholder="Select program" value={state.regulatory_act} onClick={handleRegSelect('regulatory_act')} dropdownToggleRef={regTypeRef}>
              {OrgModulesReducer &&
                OrgModulesReducer.data &&
                OrgModulesReducer.data.map((d) => (
                  <li key={`regid-${d.name.toLowerCase()}`} value={d.name}>
                    {d.name}
                  </li>
                ))}
              <li key={`regid-other`} value="Other">
                Other
              </li>
            </Select>
          </FormElement>

          <FormElement labelText="INCIDENT TYPE" htmlFor="type" smallText="*" errorMessage={stateErrors['type'] || ''}>
            <Select
              labels={typesList}
              placeholder="Unauthorized individual having access to company protected information"
              value={state['type']}
              onClick={handleSelect('type')}
              dropdownToggleRef={incidentTypeRef}
            >
              {IncidentTypesReducer.incidentTypes &&
                IncidentTypesReducer.incidentTypes
                  .filter((type) => type.regulatory_act === state.regulatory_act)
                  .map((type) => (
                    <li key={`it_id-${type.id}`} data-name={type.name} value={type.id}>
                      {type.name}
                    </li>
                  ))}
            </Select>
            {state.type !== '' && typesList[state.type] === 'Other' && (
              <input type="text" placeholder="Describe the other type of incident this is" onChange={handleChange('reason')} value={state['reason']} />
            )}
          </FormElement>
          <FormElement className={styles['description']} labelText="INCIDENT DESCRIPTION" htmlFor="description" errorMessage={stateErrors['description'] || ''}>
            <textarea
              value={state['description']}
              onChange={handleChange('description')}
              className={styles['incident-description-textarea']}
              placeholder="Incident description goes here"
            />
          </FormElement>
          <Row className={styles['date-inputs-row']}>
            <FormElement labelText="INCIDENT DATE" htmlFor="occurred" smallText="*" errorMessage={stateErrors['occurred'] || ''}>
              <DateInput className={styles['date-input']} value={state['occurred']} onChange={handleChange('occurred')} maxDate={moment().format('YYYY-MM-DD')} />
            </FormElement>
            <FormElement labelText="DISCOVERY DATE" htmlFor="discovered" smallText="*" errorMessage={stateErrors['discovered'] || ''}>
              <DateInput className={styles['date-input']} value={state['discovered']} onChange={handleChange('discovered')} maxDate={moment().format('YYYY-MM-DD')} />
            </FormElement>
          </Row>
          <Checkbox labelText="This incident affected more than 500 members" checked={state['affected']} onChange={handleCheckbox('affected')} className={styles['affected']} />
        </form>

        <br />

        <AddEvidence username={state.reported_by || 'Anonymous'} files={files} setFiles={setFiles} clear={clear} />

        <HR className={styles.hr} />

        <div className={styles.actions}>
          <Button
            text="CLEAR"
            color="secondary"
            onClick={() => {
              setFiles([]);
              setState(clearState);
              setStateErrors(clearErrors);
              setClear(true);
            }}
          />
          <Button
            icon={AddIncidentFormReducer.status === 'ISBUSY' ? 'fad fa-spinner-third fa-spin' : ''}
            text={AddIncidentFormReducer.status === 'ISBUSY' ? 'SUBMITTING INCIDENT' : 'SUBMIT INCIDENT'}
            onClick={submitIncident}
          />
        </div>
      </div>
      {/* </Container> */}
    </>
  );
};

const mapStateToProps = (state) => ({
  AffiliatesReducer: state.AffiliatesReducer,
  ReportIncidentReducer: state.ReportIncidentReducer,
  SitesReducer: state.SitesReducer,
  IncidentTypesReducer: state.IncidentTypesReducer,
  AddIncidentFormReducer: state.AddIncidentFormReducer,
  userData: state.AuthReducer.userData,
  UserOrgSiteReducer: state.UserOrgSiteReducer,
  OrgModulesReducer: state.OrgModulesReducer,
});

const mapDispatchToProps = {
  loadSitesAction,
  addNewIncidentAction,
  loadAffiliatesAction,
  loadIncidentTypesAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(IncidentPage);
