import React from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import styles from './Tabs.module.scss';

interface IProps {
  tabs: { title: string, path: string }[];
}

const styleTab = classNames(styles.tab);
const styleTabActive = classNames(styles.tab, styles.tabActive);

function Tabs(props: IProps) {
  const { tabs } = props;

  const tabsReverse = tabs.map((item) => item.path).reverse();
  const location = useLocation();

  const isActive = tabsReverse.find((item) => location.pathname.includes(item));

  return (
    <div className={styles.wrapper}>
      <div className={styles.tabs}>
        {tabs.map((tab) => (
          <Link
            key={tab.path}
            to={tab.path}
            className={isActive === tab.path ? styleTabActive : styleTab}
          >
            {tab.title}
          </Link>
        ))}
      </div>
      <div className={styles.content}>
        <Outlet />
      </div>
    </div>
  );
}

export default Tabs;
