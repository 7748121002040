import ColVisibilitySelector from 'components/DataTable/ColVisibilitySelector';
import ItemActionsMenu from 'components/DataTable/ItemActionsMenu';
import React from 'react';
import moment from 'moment';
import Checkbox from 'components/Checkbox/Checkbox';
import Button from 'components/Button/Button';
import { getStatusButtonColor } from 'helpers/utils';

export default {
  TABLE: {
    className: 'data-table-all-users',
    initialSortKey: 'name',
    initialSortOrder: 'asc',
  },

  COLUMN: [
    {
      label: 'Row Selector',
      key: 'row-selector',
      header: ({ customTableProps }) => {
        const isSelectedAll =
          customTableProps.selectedRows &&
          customTableProps.selectedRows.length &&
          customTableProps.rowsData &&
          customTableProps.rowsData.length &&
          customTableProps.selectedRows.length === customTableProps.rowsData.length
            ? true
            : false;
        return <Checkbox checked={isSelectedAll} onChange={() => customTableProps.doSelectAll()} name="row-selector" />;
      },
      className: 'col-row-selector',
      format: ({ rowData, customTableProps }) => {
        const isSelected =
          customTableProps.selectedRows &&
          customTableProps.selectedRows.length &&
          customTableProps.selectedRows.some((checkbox) => {
            return checkbox.id === rowData.id;
          })
            ? true
            : false;
        return <Checkbox checked={isSelected} onChange={() => customTableProps.onRowSelect({ rowData })} name="row-selector" />;
      },
      canBeHidden: false,
    },
    {
      label: 'Name',
      header: 'NAME',
      key: 'name',
      sortable: true,
      className: 'title',
      format: ({ rowData, customTableProps }) => {
        return <span>{rowData['name']}</span>; // name -> title
      },
    },
    {
      label: 'Email',
      header: 'EMAIL',
      key: 'email',
      sortable: true,
      canBeHidden: false,
    },
    {
      label: 'Organizations',
      header: 'ORGANIZATIONS',
      key: 'organizations',
      sortable: true,
      canBeHidden: false,
    },
    {
      label: 'Last Modified',
      header: 'MODIFIED',
      key: 'last_modified',
      format: ({ rowData }) => {
        const date = rowData.last_modified ? moment(rowData.last_modified).format('MM/DD/YYYY') : 'N/A';
        return <span>{date}</span>;
      },
      canBeHidden: true,
      sortable: true,
    },
    {
      label: 'Date Created',
      header: 'CREATED',
      key: 'created_at',
      format: ({ rowData }) => {
        const date = rowData.created_at ? moment(rowData.created_at).format('MM/DD/YYYY') : 'N/A';
        return <span>{date}</span>;
      },
      canBeHidden: true,
      sortable: true,
    },
    {
      label: 'Status',
      header: 'STATUS',
      key: 'status',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData, customTableProps }) => {
        const status = rowData.status;
        const color = getStatusButtonColor(status, ['Active'], ['Inactive']);
        return (
          <span>
            <Button cursorDefault={customTableProps.cursorDefault} color={color} size="md" text={status} onClick={() => null} />
          </span>
        );
      },
    },
    {
      label: 'Actions',
      key: 'actions',
      format: ({ rowData, customTableProps }) => {
        const actionMenuDropDown = React.createRef();
        return (
          <ItemActionsMenu
            ref={actionMenuDropDown}
            actions={(() => {
              return [
                {
                  label: 'Edit',
                  action: () => {
                    if (actionMenuDropDown && actionMenuDropDown.current) {
                      actionMenuDropDown.current.handleMenuClose();
                    }
                    customTableProps.edit(rowData);
                  },
                },
              ];
            })()}
          />
        );
      },
      className: 'col-actions',
      header: ({ customTableProps }) => {
        return (
          <ColVisibilitySelector
            colDefs={customTableProps.colDefs}
            visibleColumnKeys={customTableProps.visibleColumnKeys}
            onChangeVisibility={(colKey) => customTableProps.onChangeVisibility(colKey)}
          />
        );
      },
    },
  ],
};
