// import ColVisibilitySelector from "components/DataTable/ColVisibilitySelector";
import ItemActionsMenu from 'components/DataTable/ItemActionsMenu';
import React from 'react';
import Checkbox from 'components/Checkbox/Checkbox';
import moment from 'moment';
import ColVisibilitySelector from 'components/DataTable/ColVisibilitySelector';

function getDeviceType(typeId) {
  switch (typeId) {
    case '0':
      return 'Desktop Computer';
    case '1':
      return 'Laptop';
    case '2':
      return 'Server';
    case '3':
      return 'Network Device';
    case '4':
      return 'Cell Phone';
    case '5':
      return 'Tablet';
    case '6':
      return 'VoIP Phone';
    case '7':
      return 'Removable Media';
    case '8':
      return 'Scanner';
    case '9':
      return 'MDF Scanner/Printer';
    case '10':
      return 'Virtual Machine';
    case '11':
      return 'Other';
    default:
      return '';
  }
}

const getProtectionChoice = (choice) => {
  switch (choice) {
    case '0':
      return 'No';
    case '1':
      return 'Yes';
    case '-1':
      return 'N/A';
  }
};

export default {
  TABLE: {
    className: 'data-table-devices',
    initialSortKey: 'type',
    initialSortOrder: 'desc',
  },

  COLUMN: [
    {
      label: 'Row Selector',
      key: 'row-selector',
      header: ({ customTableProps }) => {
        const isSelectedAll =
          customTableProps.selectedRows &&
          customTableProps.selectedRows.length &&
          customTableProps.rowsData &&
          customTableProps.rowsData.length &&
          customTableProps.selectedRows.length === customTableProps.rowsData.length
            ? true
            : false;
        return <Checkbox checked={isSelectedAll} onChange={() => customTableProps.doSelectAll()} name="row-selector" />;
      },
      className: 'col-row-selector',
      format: ({ rowData, customTableProps }) => {
        const isSelected = customTableProps.selectedRows && customTableProps.selectedRows.length && customTableProps.selectedRows.includes(rowData) ? true : false;
        return <Checkbox checked={isSelected} onChange={() => customTableProps.onRowSelect({ rowData })} name="row-selector" />;
      },
      canBeHidden: false,
    },
    {
      label: 'Device Name',
      header: 'DEVICE NAME',
      key: 'name',
      sortable: true,
      format: ({ rowData }) => {
        return <span>{rowData['name']}</span>;
      },
    },
    {
      label: 'Device Type',
      header: 'TYPE',
      key: 'type',
      sortable: true,
      className: 'type',
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{getDeviceType(rowData['type'])}</span>;
      },
    },
    {
      label: 'Asset Tag',
      header: 'ASSET TAG',
      key: 'asset_tag',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{rowData['asset_tag']}</span>;
      },
    },
    {
      label: 'Model',
      header: 'MODEL',
      key: 'model',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{rowData['model']}</span>;
      },
    },
    {
      label: 'Serial Number',
      header: 'SERIAL NUMBER',
      key: 'serial_number',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{rowData.serial_number}</span>;
      },
    },
    {
      label: 'Physical Location',
      header: 'PHYSICAL LOCATION',
      key: 'physical_location',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{rowData['physical_location']}</span>;
      },
    },
    {
      label: 'Activated',
      header: 'ACTIVATED',
      key: 'activated',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{rowData['activated'] ? moment(rowData['activated']).format('MM/DD/YYYY') : ''}</span>;
      },
    },
    {
      label: 'Deactivated',
      header: 'DEACTIVATED',
      key: 'deactivated',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{rowData['deactivated'] ? moment(rowData['deactivated']).format('MM/DD/YYYY') : 'No'}</span>;
      },
    },
    {
      label: 'Destroyed',
      header: 'DESTROYED',
      key: 'destroyed',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{rowData['device_destroyed'] === 't' ? moment(rowData['device_desturction_date']).format('MM/DD/YYYY') : 'No'}</span>;
      },
    },
    {
      label: 'Last Known IP Address',
      header: 'LAST KNOWN IP ADDRESS',
      key: 'last_ip_address',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{rowData['last_ip_address']}</span>;
      },
    },
    {
      label: 'Risk Rating',
      header: 'RISK RATING',
      key: 'risk_rating',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{rowData['risk_rating']}</span>;
      },
    },
    {
      label: 'User assigned',
      header: 'USER ASSIGNED',
      key: 'user_assigned',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{rowData['user_assigned']}</span>;
      },
    },
    {
      label: 'Stores/Touches ePHI',
      header: 'STORES/TOUCHES ePHI',
      key: 'stores_touches_ephi',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{getProtectionChoice(rowData['stores_touches_ephi'])}</span>;
      },
    },
    {
      label: 'End Point Protection',
      header: 'END POINT PROTECTION',
      key: 'end_point_protection',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{getProtectionChoice(rowData['end_point_protection'])}</span>;
      },
    },
    {
      label: 'Encryption',
      header: 'ENCRYPTION',
      key: 'encryption',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{getProtectionChoice(rowData['encryption'])}</span>;
      },
    },
    {
      label: 'Notes',
      header: 'NOTES',
      key: 'notes',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{rowData['notes']}</span>;
      },
    },
    {
      label: 'Actions',
      key: 'actions',
      format: ({ rowData, customTableProps }) => {
        const actionMenuDropDown = React.createRef();
        const isDeactivated = rowData.deactivated !== '';
        let actionsList =
          rowData.device_destroyed === 'f'
            ? [
                // device not destroyed
                isDeactivated && {
                  label: 'Activate',
                  action: () => {
                    if (actionMenuDropDown && actionMenuDropDown.current) {
                      actionMenuDropDown.current.handleMenuClose();
                    }
                    customTableProps.activate(rowData);
                  },
                },
                !isDeactivated && {
                  label: 'Deactivate',
                  action: () => {
                    if (actionMenuDropDown && actionMenuDropDown.current) {
                      actionMenuDropDown.current.handleMenuClose();
                    }
                    customTableProps.deactivate(rowData);
                  },
                },
                {
                  label: 'Destroy',
                  action: () => {
                    if (actionMenuDropDown && actionMenuDropDown.current) {
                      actionMenuDropDown.current.handleMenuClose();
                    }
                    customTableProps.destroy(rowData);
                  },
                },
                customTableProps.evidence && {
                  label: 'Evidence Locker',
                  action: () => {
                    if (actionMenuDropDown && actionMenuDropDown.current) {
                      actionMenuDropDown.current.handleMenuClose();
                    }
                    customTableProps.evidence(rowData);
                  },
                },
              ]
            : [
                // device destroyed
                customTableProps.evidence && {
                  label: 'Evidence Locker',
                  action: () => {
                    if (actionMenuDropDown && actionMenuDropDown.current) {
                      actionMenuDropDown.current.handleMenuClose();
                    }
                    customTableProps.evidence(rowData);
                  },
                },
              ];
        return (
          <ItemActionsMenu
            ref={actionMenuDropDown}
            actions={(() => {
              return actionsList;
            })()}
          />
        );
      },
      className: 'col-actions',
      header: ({ customTableProps }) => {
        return (
          <ColVisibilitySelector
            colDefs={customTableProps.colDefs}
            visibleColumnKeys={customTableProps.visibleColumnKeys}
            onChangeVisibility={(colKey) => customTableProps.onChangeVisibility(colKey)}
          />
        );
      },
    },
  ],
};
