// import ColVisibilitySelector from "components/DataTable/ColVisibilitySelector";
import ItemActionsMenu from 'components/DataTable/ItemActionsMenu';
import React from 'react';
import Checkbox from 'components/Checkbox/Checkbox';

function getDeviceType(typeId) {
  switch (typeId) {
    case '0':
      return 'Desktop';
    case '1':
      return 'Laptop';
    case '2':
      return 'Server';
    case '3':
      return 'Phone';
    case '4':
      return 'Tablet';
    case '5':
      return 'Other';
    default:
      return '';
  }
}

export default {
  TABLE: {
    className: 'data-table-devices',
    initialSortKey: 'type',
    initialSortOrder: 'desc',
  },

  COLUMN: [
    {
      label: 'Row Selector',
      key: 'row-selector',
      header: ({ customTableProps }) => {
        const isSelectedAll =
          customTableProps.selectedRows &&
          customTableProps.selectedRows.length &&
          customTableProps.rowsData &&
          customTableProps.rowsData.length &&
          customTableProps.selectedRows.length === customTableProps.rowsData.length
            ? true
            : false;
        return <Checkbox checked={isSelectedAll} onChange={() => customTableProps.doSelectAll()} name="row-selector" />;
      },
      className: 'col-row-selector',
      format: ({ rowData, customTableProps }) => {
        const isSelected = customTableProps.selectedRows && customTableProps.selectedRows.length && customTableProps.selectedRows.includes(rowData) ? true : false;
        return <Checkbox checked={isSelected} onChange={() => customTableProps.onRowSelect({ rowData })} name="row-selector" />;
      },
      canBeHidden: false,
    },
    {
      label: 'Type',
      header: 'TYPE',
      key: 'type',
      sortable: true,
      className: 'type',
      format: ({ rowData }) => {
        return <span>{getDeviceType(rowData['type'])}</span>;
      },
    },
    {
      label: 'Location',
      header: 'LOCATION',
      key: 'location',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{rowData['location']}</span>;
      },
    },
    {
      label: 'Inventory',
      header: 'INVENTORY',
      key: 'inventory',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{rowData['inventory']}</span>;
      },
    },
    {
      label: 'Site',
      header: 'SITE',
      key: 'siteName',
      sortable: true,
      format: ({ rowData }) => {
        return <span>{rowData.siteName}</span>;
      },
    },
    {
      label: 'Tech Support',
      header: 'TECH SUPPORT',
      key: 'name',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{rowData['ownerName']}</span>;
      },
    },
    {
      label: 'Encrypted',
      header: 'ENCRYPTED',
      key: 'encrypted',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{rowData['encrypted'] ? 'YES' : 'NO'}</span>;
      },
    },
    {
      label: 'Strong password',
      header: 'STRONG PASSWORD',
      key: 'strong_password',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{rowData['strong_password'] ? 'YES' : 'NO'}</span>;
      },
    },
    {
      label: 'Antivirus',
      header: 'ANTIVIRUS/MALWARE PROTECTION',
      key: 'antivirus',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{rowData['antivirus'] ? 'YES' : 'NO'}</span>;
      },
    },
    {
      label: 'Malware protection',
      header: 'OTHER FORMS OF PROTECTION',
      key: 'protection_notes',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{rowData['protection_notes'] ? rowData['protection_notes'] : 'NO'}</span>;
      },
    },
    {
      label: 'Decommissioned',
      header: 'DECOMMISSIONED',
      key: 'decommissioned',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{rowData['decommissioned'] ? 'YES' : 'NO'}</span>;
      },
    },
    {
      label: 'Destroyed HDD',
      header: 'DESTROYED HDD',
      key: 'destroyed_hdd',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{rowData['destroyed_hdd'] ? 'YES' : 'NO'}</span>;
      },
    },
    {
      label: 'Actions',
      key: 'actions',
      format: ({ rowData, customTableProps }) => {
        const actionMenuDropDown = React.createRef();
        let actionsList =
          rowData.decommissioned === false
            ? [
                {
                  label: 'Duplicate',
                  action: () => {
                    if (actionMenuDropDown && actionMenuDropDown.current) {
                      actionMenuDropDown.current.handleMenuClose();
                    }
                    customTableProps.handleDuplicate(rowData);
                  },
                },
                {
                  label: 'Decommission',
                  action: () => {
                    if (actionMenuDropDown && actionMenuDropDown.current) {
                      actionMenuDropDown.current.handleMenuClose();
                    }
                    customTableProps.handleDecommission(rowData);
                  },
                },
                {
                  label: 'Delete',
                  action: () => {
                    if (actionMenuDropDown && actionMenuDropDown.current) {
                      actionMenuDropDown.current.handleMenuClose();
                    }
                    customTableProps.handleDelete(rowData);
                  },
                },
              ]
            : [
                {
                  label: 'Duplicate',
                  action: () => {
                    if (actionMenuDropDown && actionMenuDropDown.current) {
                      actionMenuDropDown.current.handleMenuClose();
                    }
                    customTableProps.handleDuplicate(rowData);
                  },
                },
              ];
        if (rowData.decommissioned === 'Yes') {
          actionsList = [
            {
              label: 'Duplicate',
              action: () => {
                if (actionMenuDropDown && actionMenuDropDown.current) {
                  actionMenuDropDown.current.handleMenuClose();
                }
                customTableProps.handleDuplicate(rowData);
              },
            },
          ];
        }
        return (
          <ItemActionsMenu
            ref={actionMenuDropDown}
            actions={(() => {
              return actionsList;
            })()}
          />
        );
      },
      // className: "col-actions",
      // header: ({ customTableProps }) => {
      //     return (
      //         <ColVisibilitySelector
      //             colDefs={customTableProps.colDefs}
      //             visibleColumnKeys={customTableProps.visibleColumnKeys}
      //             onChangeVisibility={colKey =>
      //                 customTableProps.onChangeVisibility(colKey)
      //             }
      //         />
      //     );
      // }
    },
  ],
};
