import ActionStatusConstants from 'helpers/ActionStatusConstants';
import ActionTypeConstants from 'helpers/ActionTypeConstants';

export interface IUserCounters {
  status: string;
  message?: string;
  counters?: {tasks: string; documents: string};
}

const initialState: IUserCounters = {
  status: ActionStatusConstants.INITIAL,
};

export default (state = initialState, { type, payload }): IUserCounters => {
  switch (type) {
    case ActionTypeConstants.LOAD_USER_COUNTERS: {
      return {
        ...state,
        status: ActionStatusConstants.ISBUSY,
      };
    }
    case ActionTypeConstants.LOAD_USER_COUNTERS_SUCCESS: {
      return {
        ...state,
        counters: payload?.counters,
        status: ActionStatusConstants.SUCCESS,
        message: payload?.message,
      };
    }
    case ActionTypeConstants.LOAD_USER_COUNTERS_FAILURE: {
      return {
        ...state,
        status: ActionStatusConstants.FAILURE,
        message: payload?.message,
      };
    }
    default:
  }
  return state;
};
