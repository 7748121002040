// import DataTableRow from './DataTableRow';
import PropTypes from 'prop-types';
import React from 'react';
// import classNames from 'classnames';

class PaginationSelector extends React.Component {
  renderPaginationInfo() {
    const startIndex = this.props.currentPage * this.props.rowsPerPage;
    const endIndex = startIndex + this.props.rowsPerPage;
    const dataSize = this.props.dataSize;

    return (
      <span className="label">
        {startIndex + 1}-{endIndex > dataSize ? dataSize : endIndex} of {dataSize}
      </span>
    );
  }

  renderPreviousPageBtn() {
    const isEnabled = this.props.currentPage > 0 ? true : false;
    return (
      <React.Fragment>
        <span
          className={`change-page-btn change-page-btn-first${isEnabled ? '' : ' disabled'}`}
          onClick={() => {
            if (isEnabled) {
              this.props.onChangeCurrentPage(0);
            }
          }}
        >
          <i className="far fa-chevron-double-left" />
        </span>
        <span
          className={`change-page-btn change-page-btn-prev${isEnabled ? '' : ' disabled'}`}
          onClick={() => {
            if (isEnabled) {
              this.props.onChangeCurrentPage(this.props.currentPage - 1);
            }
          }}
        >
          <i className="far fa-chevron-left" />
        </span>
      </React.Fragment>
    );
  }

  renderNextPageBtn() {
    const endIndex = this.props.currentPage * this.props.rowsPerPage + this.props.rowsPerPage;
    const isEnabled = endIndex < this.props.dataSize ? true : false;
    const lastPage = Math.ceil(this.props.dataSize / this.props.rowsPerPage) - 1;
    // console.log('LastPage', lastPage)
    return (
      <React.Fragment>
        <span
          className={`change-page-btn change-page-btn-next${isEnabled ? '' : ' disabled'}`}
          onClick={() => {
            if (isEnabled) {
              this.props.onChangeCurrentPage(this.props.currentPage + 1);
            }
          }}
        >
          <i className="far fa-chevron-right" />
        </span>
        <span
          className={`change-page-btn change-page-btn-last${isEnabled ? '' : ' disabled'}`}
          onClick={() => {
            if (isEnabled) {
              this.props.onChangeCurrentPage(lastPage);
            }
          }}
        >
          <i className="far fa-chevron-double-right" />
        </span>
      </React.Fragment>
    );
  }

  render() {
    return (
      <div className="pagination-selector">
        {this.renderPaginationInfo()}
        {this.renderPreviousPageBtn()}
        {this.renderNextPageBtn()}
      </div>
    );
  }

  static propTypes = {
    dataSize: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    onChangeCurrentPage: PropTypes.func.isRequired,
  };
}

export default PaginationSelector;
