// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useState, useEffect } from 'react';

import Modal from 'components/Modal/Modal';
import Button from 'components/Button/Button';
import Radio from 'components/Radio/Radio';
import FormElement from 'components/FormElement/FormElement';
import DateInput from 'components/DateInput/DateInput';

import { toggleDropdowns } from 'helpers/utils';

import Select from 'components/Select/Select';
import Checkbox from 'components/Checkbox/Checkbox';

import { useAppDispatch, useAppSelector } from 'hooks';
import { loadFoldersAction, loadSitesAction, loadDeptAction, createFolderAction, updateFolderAction } from 'store/actions/Actions';

import styles from './CreateFolderModal.module.scss';

const CreateFolderModal = (props) => {
  const { editFolder, setShowModal } = props;

  const dispatch = useAppDispatch();
  const folders = useAppSelector((state) => state.FoldersReducer.folders);
  const sites = useAppSelector((state) => state.SitesReducer.sites);
  const departments = useAppSelector((state) => state.DepartmentsReducer.departments);
  const parentFolderReducer = useAppSelector((state) => state.FolderContentsReducer.parentFolder);
  const OrgModulesReducer = useAppSelector((state) => state.OrgModulesReducer);

  const defaultReg = (OrgModulesReducer.data && OrgModulesReducer.data.map((r) => r.name)) || [];
  const [state, setState] = useState({
    folderName: '',
    parentFolderId: 0,
    parentFolder: '',
    docFolders: [],
    permissionLevel: true,
    siteId: '',
    site: '',
    regulatory_act: defaultReg[0] || '',
    departmentId: '',
    department: '',
    expiration_date: '',
    description: '',
    admins_only: false,
    label: 'Folder ',
    requires_attest: false,
  });

  const [docFolders, setDocFolders] = useState(state.docFolders);
  const [parentFolderId, setParentFolderId] = useState(state.parentFolderId);
  const [parentFolder, setParentFolder] = useState(state.parentFolder);
  const [siteId, setSiteId] = useState(state.siteId);
  const [site, setSite] = useState(state.site);
  const [departmentId, setDepartmentId] = useState(state.departmentId);
  const [department, setDepartment] = useState(state.department);

  const [stateErrors, setStateErrors] = useState({});

  useEffect(() => {
    dispatch(loadFoldersAction({}));
  }, []);

  useEffect(() => {
    if (editFolder) {
      if (editFolder.type === 'file') {
        setState((s) => ({
          ...s,
          label: 'File ',
        }));
      }

      setState((s) => ({
        ...s,
        folderName: editFolder.folder_name,
        permissionLevel: editFolder.permission_site ? false : true,
        description: editFolder.description,
      }));
      setParentFolderId(editFolder.parent_folder);
      if (editFolder.permission_site) {
        dispatch(loadSitesAction());
        setSiteId(editFolder.permission_site);
      }
      if (editFolder.permission_department) {
        dispatch(loadDeptAction({ site: parseInt(editFolder.permission_site) }));
        setDepartmentId(editFolder.permission_department);
      }

      if (editFolder.admins_only) {
        setState((s) => ({
          ...s,
          admins_only: editFolder.admins_only,
        }));
      }

      if (editFolder.regulatory_act) {
        setState((s) => ({
          ...s,
          regulatory_act: editFolder.regulatory_act,
        }));
      }

      if (editFolder.requires_attest) {
        setState((s) => ({
          ...s,
          requires_attest: editFolder.requires_attest,
        }));
      }
    }
  }, [editFolder]);

  const parentFolderProp = parentFolderReducer;
  // Put folder structure in local state
  useEffect(() => {
    setDocFolders(folders);
    if (parentFolderProp && parentFolderProp.id) {
      setParentFolderId(parentFolderProp.id);
    }
  }, [folders, parentFolderProp]);

  useEffect(() => {
    if (folders) {
      let selected = folders.filter((f) => f.id === parentFolderId);

      if (selected && selected.length === 1) {
        setParentFolder(selected[0].folder_name);
      }
    }
  }, [parentFolderId, folders]);

  useEffect(() => {
    if (sites) {
      let selected = sites.filter((f) => f.id === siteId);

      if (selected && selected.length === 1) {
        setSite(selected[0].name);
      }
    }
  }, [siteId, sites]);

  useEffect(() => {
    if (departments) {
      let selected = departments.filter((f) => f.id === departmentId);

      if (selected && selected.length === 1) {
        setDepartment(selected[0].name);
      }
    }
  }, [departmentId, departments]);

  const statusFolderDropdown = React.createRef();
  const siteDropdown = React.createRef();
  const departmentDropdown = React.createRef();
  const regTypeRef = React.createRef();

  const handleChangeRadio = (inputName) => (e) => {
    e.persist();
    setState((s) => ({
      ...s,
      [inputName]: e.target.value === 'true' ? true : false,
    }));
    if (inputName === 'permissionLevel') {
      dispatch(loadSitesAction());
    }
  };

  const handleChange = (inputName) => (e) => {
    e.persist();
    setState((s) => ({
      ...s,
      [inputName]: e.target.value,
    }));
    setStateErrors((s) => ({ ...s, [inputName]: '' }));
  };

  // const searchFolders = e => {
  //     setDocFolders(
  //         folders.filter(f =>
  //             f.folder_name.toUpperCase().includes(e.target.value.toUpperCase())
  //         )
  //     );
  // };

  const handleAdminsOnly = (e) => {
    e.persist();
    setState((s) => ({
      ...s,
      admins_only: !state.admins_only,
    }));
  };

  const handleRequiresAttest = (e) => {
    e.persist();
    setState((s) => ({
      ...s,
      requires_attest: !state.requires_attest,
    }));
  };

  const createFolder = async (e) => {
    if (state.folderName === '') {
      setStateErrors({
        ...stateErrors,
        folderName: state.folderName === '' ? state.label + ' name is required' : '',
      });
    } else {
      const { folderName } = state;
      let data = {
        folder_name: folderName,
        parent_folder: parentFolderId,
        permission_site: siteId,
        permission_department: departmentId,
        admins_only: state.admins_only,
        requires_attest: state.requires_attest,
        expiration_date: state.expiration_date,
        description: state.description,
      };

      if (editFolder) {
        data.id = editFolder.id;
        await dispatch(updateFolderAction(data));
      } else {
        await dispatch(createFolderAction(data));
      }
      setShowModal(false);
    }
  };

  const handleRegSelect = (inputName) => (e) => {
    e.persist();
    setState((s) => ({
      ...s,
      [inputName]: e.target.attributes && e.target.attributes.value ? e.target.attributes.value.value : '',
    }));
    // setStateErrors((s) => ({ ...s, [inputName]: '', type: '' }));
  };

  return (
    <Modal setShowModal={setShowModal} className={styles.modal}>
      <div className={styles['modal-header']}>{!editFolder ? <h2 className={styles.heading}>Create Folder</h2> : <h2 className={styles.heading}>Edit {state.label}</h2>}</div>
      <div className={styles['modal-body']} onClick={toggleDropdowns([statusFolderDropdown, siteDropdown, departmentDropdown, regTypeRef])}>
        {editFolder && editFolder.type === 'file' && (
          <>
            <div className={styles['existing-folder']}>
              <div className={styles.reg}>
                <strong>PROGRAM</strong>
              </div>

              <FormElement
                // labelText="PROGRAM"
                htmlFor="regulatory_act"
                // errorMessage={stateErrors["regulatory_act"] || ""}
              >
                <Select disabled={true} placeholder="Select regulatory act" value={state.regulatory_act} onClick={handleRegSelect('regulatory_act')} dropdownToggleRef={regTypeRef}>
                  {OrgModulesReducer &&
                    OrgModulesReducer.data &&
                    OrgModulesReducer.data.map((d) => (
                      <li key={`regid-${d.name.toLowerCase()}`} value={d.name}>
                        {d.name}
                      </li>
                    ))}
                </Select>
              </FormElement>
            </div>

            <br />
          </>
        )}

        <div className={styles['existing-folder']}>
          <div className={styles.reg}>
            <b>{state.label} NAME *</b>
          </div>
          <FormElement errorMessage={stateErrors['folderName'] || ''}>
            <input className={styles['folder-name']} placeholder="Folder Name" type="text" value={state.folderName} onChange={handleChange('folderName')}></input>
          </FormElement>

          {editFolder && editFolder.type === 'file' && (
            <>
              <div className={styles.reg}>
                <b>DESCRIPTION</b>
              </div>
              <FormElement>
                <textarea
                  className={styles.description}
                  onChange={handleChange('description')}
                  rows={2}
                  maxLength={100}
                  placeholder="Add description here"
                  value={state.description}
                />
              </FormElement>
            </>
          )}

          <div className={styles.reg}>
            <strong>PARENT FOLDER</strong>
            &nbsp;
            <small>(optional)</small>
          </div>
          <Select
            value={parentFolder}
            placeholder="Select folder"
            // className={styles["parent-folder-select"]}
            dropdownToggleRef={statusFolderDropdown}
            onClick={(e) => {
              if (e.target.type !== 'text') {
                setParentFolderId(e.target.value + '');
              }
            }}
          >
            <div className={styles['parent-folder']}>
              {/* <SearchInput
                                className={styles["search-input"]}
                                onChange={searchFolders}
                            /> */}
              {docFolders &&
                docFolders.map((f) => (
                  <li value={f.id} key={f.id} className={`${styles.folder} ${styles[`depth-${f.level}`]}`}>
                    <i className={f.level === '0' ? 'fas fa-folder' : 'far fa-folder'} />
                    {f.folder_name}
                  </li>
                ))}
            </div>
          </Select>
          <label className={styles['permission-level-label']}>
            <b>PERMISSION LEVEL</b>
          </label>
          <div>
            <Radio checked={state.permissionLevel} labelText="Entire Organization (default)" onChange={handleChangeRadio('permissionLevel')} value={true} />
            <div className={styles['site-dept']}>
              <Radio labelClassName={styles['permission-level']} checked={!state.permissionLevel} labelText={false} onChange={handleChangeRadio('permissionLevel')} value={false} />
              <Select
                disabled={Boolean(state.permissionLevel)}
                placeholder="Select Site"
                value={site}
                dropdownToggleRef={siteDropdown}
                onClick={(e) => {
                  siteDropdown.current.click();
                  setSiteId(e.target.value + '');
                  dispatch(loadDeptAction({ site: e.target.value }));
                }}
              >
                {sites &&
                  sites
                    .filter((site) => site.active === 't')
                    .map((f) => (
                      <li value={f.id} key={f.id}>
                        {f.name}
                      </li>
                    ))}
              </Select>
              <Select
                disabled={!site}
                placeholder="Select Department"
                dropdownToggleRef={departmentDropdown}
                value={department}
                onClick={(e) => {
                  departmentDropdown.current.click();
                  setDepartmentId(e.target.value + '');
                }}
              >
                {departments &&
                  departments
                    .filter((dep) => dep.active === 't')
                    .map((f) => (
                      <li value={f.id} key={f.id}>
                        {f.name}
                      </li>
                    ))}
              </Select>
            </div>

            <div className={styles['admin-only']}>
              <Checkbox onChange={handleAdminsOnly} checked={state.admins_only} labelText="Viewable by admins only" />
            </div>

            {editFolder.type === 'file' && (
              <div className={styles['admin-only']}>
                <Checkbox onChange={handleRequiresAttest} checked={state.requires_attest} labelText="Requires Attestation" />
              </div>
            )}
          </div>

          <label>
            <b>EXPIRATION DATE</b>
          </label>
          <DateInput
            className={styles['date-input']}
            value={state['expiration_date']}
            onChange={handleChange('expiration_date')}
            // maxDate={moment().format('YYYY-MM-DD')}
          />
        </div>
      </div>
      <div className={styles['modal-footer']}>
        <Button className={styles.button} text="CANCEL" onClick={() => setShowModal(false)} color="secondary" />
        <Button className={styles.button} text={editFolder ? 'UPDATE' : 'CREATE'} onClick={createFolder} />
      </div>
    </Modal>
  );
};

export default CreateFolderModal;
