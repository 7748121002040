import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import styles from './Select.module.scss';

interface IProps {
  value?: string;
  placeholder?: string;
  isActive?: boolean;
  size?: 'md' | 'lg';
  onClick?: (e: Event) => void;
  children?: React.ReactNode;
}

const defaultProps = {
  value: '',
  placeholder: 'Placeholder',
  isActive: false,
  size: 'lg',
  onClick: () => false,
  children: undefined,
};

export function SelectMulti({ value, placeholder, size, isActive, onClick, children }: IProps) {
  const wrapperRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showDropdown && wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef, showDropdown]);

  const toggleDropdown = (): void => setShowDropdown(!showDropdown);

  const valueClass = classNames({ [styles.placeholder]: value === '' });

  const className = classNames(styles['dropdown-toggle'], showDropdown ? 'show' : 'hide', styles[`select-${size}`], { [styles.active]: isActive });
  const menuClass = classNames(styles['dropdown-menu'], showDropdown ? '' : styles.hidden, styles[`select-${size}`]);

  const displayValue = value || placeholder;

  const handleClick = (e) => onClick(e);

  return (
    <div className={styles.dropdown} ref={wrapperRef}>
      <div className={className} role="none" onClick={toggleDropdown}>
        <span className={valueClass}>{displayValue}</span>
        <i className="fas fa-sort-down" />
      </div>
      <menu className={menuClass} role="none" onClick={handleClick}>
        {children}
      </menu>
    </div>
  );
}

SelectMulti.defaultProps = defaultProps;

export default SelectMulti;
