import React from 'react';
import MainLayout from 'v2/layouts/MainLayout/MainLayout';

import OrgSiteDept from 'v2/views/Settings/OrgSiteDept/OrgSiteDept';

function Settings() {
  return (
    <MainLayout>
      <OrgSiteDept />
    </MainLayout>
  );
}

export default Settings;
