import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import AppLayoutWrapFull from 'components/AppLayoutWrapFull';
import PageHeader from 'components/PageHeader/PageHeader';
import PageHeaderActions from 'components/PageHeader/components/PageHeaderActions/PageHeaderActions';
import Container from 'components/Container/Container';
import FullDataTable from 'components/DataTable/FullDataTable';
import TableDefUnapprovedDocuments from 'table-defs/TableDefUnapprovedDocuments';
import ApproveDocumentModal from './components/ApproveDocumentModal/ApproveDocumentModal';
import { deleteFolderAction, loadUnapprovedFilesAction } from 'store/actions/Actions';
import useFilterRows from 'helpers/customHooks/useFilterRows';

import styles from './UnapprovedDocuments.module.scss';

const UnapprovedDocuments = (props) => {
  const { files, loadUnapprovedFilesAction, parentFolder, UserOrgSiteReducer, deleteFolderAction } = props;
  const navigate = useNavigate();
  const [state, setState] = useState({
    selectedRows: null,
    parentFolder: 0,
    currentFolderName: null,
  });

  const noFilters = {
    text: '',
    type: '',
    'last-action': '',
    status: '',
    role: '',
    site: '',
    start: '',
    end: '',
    selected_sites: [],
    dateField: 'last_modified',
  };

  const [showApproveDocumentsModal, setShowApproveDocumentsModal] = useState(false);
  const [documentForApproval, setDocumentForApproval] = useState(false);
  const [filters, setFilters] = useState(noFilters);

  useEffect(() => {
    if (!files) {
      loadUnapprovedFilesAction({ parent_folder: 0 });
    }
  }, [files, parentFolder, loadUnapprovedFilesAction, noFilters]);

  useEffect(() => {
    if (files) {
      const processingFiles = files.filter((e) => e.status === 'processing');
      if (processingFiles && processingFiles.length > 0) {
        window.setTimeout(() => {
          loadUnapprovedFilesAction({ parent_folder: 0 });
        }, 20000);
      }
    }
  }, [files, loadUnapprovedFilesAction]);

  const setDateFilter = (filterName) => (value) => {
    setFilters({
      ...filters,
      [filterName]: value,
    });
  };

  const onChangeRowSelect = ({ selectedRows }) => {
    if (selectedRows !== state.selectedRows) {
      setState((s) => ({ ...s, selectedRows }));
    }
  };

  const filteredRows = useFilterRows(filters, files);

  const is_admin = UserOrgSiteReducer && UserOrgSiteReducer.selected && UserOrgSiteReducer.selected.is_admin ? true : false;
  const is_super = UserOrgSiteReducer && UserOrgSiteReducer.selected && UserOrgSiteReducer.selected.is_super ? true : false;
  const is_coach = UserOrgSiteReducer && UserOrgSiteReducer.selected && UserOrgSiteReducer.selected.is_coach ? true : false;
  const is_officer = UserOrgSiteReducer && UserOrgSiteReducer.selected && UserOrgSiteReducer.selected.is_officer ? true : false;

  return (
    <AppLayoutWrapFull>
      <PageHeader showStats={true}>
        <PageHeaderActions
          primaryButtonText="APPROVED DOCS"
          primaryButtonHandler={() => {
            navigate('/achieve/documents');
          }}
          primaryButtonIcon="fal fa-long-arrow-left"
          searchHandler={(e) => setFilters({ ...filters, text: e.target.value || '' })}
          searchValue={filters.text}
          dateRange={{
            onChangeStart: setDateFilter('start'),
            onChangeEnd: setDateFilter('end'),
          }}
          filters={filters}
          resetFilters={() => {
            setFilters(noFilters);
          }}
        />
      </PageHeader>
      <Container wide fluid>
        {showApproveDocumentsModal && ReactDOM.createPortal(<ApproveDocumentModal setShowModal={setShowApproveDocumentsModal} documentForApproval={documentForApproval} />, document.querySelector('#modal-root'))}

        <div className={styles['path']}>
          {parentFolder && parentFolder.id !== 0 ? (
            <div>
              <i onClick={() => loadUnapprovedFilesAction()} className={`fal fa-folder-open ${styles['up']} ${styles['link']}`}></i> {props.parentFolder.path}
            </div>
          ) : (
            <div>
              <i className={`fal fa-folder-open ${styles['up']}`}></i> /
            </div>
          )}
        </div>

        <FullDataTable
          rowsData={filteredRows}
          definitions={TableDefUnapprovedDocuments}
          onChangeRowSelect={({ selectedRows }) => onChangeRowSelect({ selectedRows })}
          onRowClick={function (e) {
            if (e.currentTarget.classList.contains('col-actions') || e.currentTarget.classList.contains('col-row-selector')) {
              return;
            }
            if (this.props.rowData['type'] === 'folder') {
              loadUnapprovedFilesAction();
            } else {
              navigate(`/achieve/documents/editor/${this.props.rowData.id}/review`);
            }
          }}
          customTableProps={{
            confirm: (rowData) => {
              setDocumentForApproval(rowData);
              setShowApproveDocumentsModal(true);
            },
            reject: (rowData) => {
              deleteFolderAction({ items: [rowData.id], parent_folder: 0 });
              loadUnapprovedFilesAction();
            },
            showActions: () => {
              return UserOrgSiteReducer.selected && (is_super || is_officer || is_admin || is_coach);
            },
          }}
        />
      </Container>
    </AppLayoutWrapFull>
  );
};

const mapStateToProps = (state) => ({
  files: state.UnapprovedFilesReducer.files,
  parentFolder: state.FolderContentsReducer.parentFolder,
  UserOrgSiteReducer: state.UserOrgSiteReducer,
});

const mapDispatchToProps = {
  loadUnapprovedFilesAction,
  deleteFolderAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(UnapprovedDocuments);
