import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { useAppSelector } from 'hooks';

export function ProtectedRoute({ children, allowUser = false }) {
  const AuthReducer = useAppSelector((state) => state.AuthReducer);
  const UserOrgSiteReducer = useAppSelector((state) => state.UserOrgSiteReducer);
  const { isLoggedIn } = AuthReducer;
  const { is_admin, is_coach, is_officer, is_org_admin, is_org_admin_label, is_site_admin, is_super } = UserOrgSiteReducer.selected;

  const isUser = !is_admin && !is_coach && !is_officer && !is_org_admin && !is_org_admin_label && !is_site_admin && !is_super;

  if (!isLoggedIn) {
    return <Navigate to="/login" replace />;
  }

  if (isUser && !allowUser) {
    return <Navigate to="/" replace />;
  }

  return children || <Outlet />;
}

export default ProtectedRoute;
