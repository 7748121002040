import React from 'react';
import { isCharacterLowerCase, isCharacterUpperCase, isCharacterSpecial, specialChars } from 'helpers/utils';
import styles from './PasswordValidation.module.scss';

interface IPassowordValidation {
  password?: string;
}

const defaultProps = {
  password: undefined,
};

function CheckIcon() {
  return <i className="fa-solid fa-square-check" />;
}
function ClearIcon() {
  return <i className="fa-solid fa-square-xmark" />;
}

function PassowordValidation({ password }: IPassowordValidation) {
  if (!password) return;
  return (
    <small className={styles['password-error']}>
      <span className={styles.label}>The password must:</span>
      <ul className={styles.list}>
        <li>
          {password && password?.length >= 8 ? (
            <span className={styles.correct}>
              <CheckIcon /> Be at least 8 characters long
            </span>
          ) : (
            <span className={styles.incorrect}>
              <ClearIcon /> Be at least 8 characters long
            </span>
          )}
        </li>
        <li>
          {[...password]?.some((char) => isCharacterLowerCase(char)) ? (
            <span className={styles.correct}>
              <CheckIcon /> Include at least 1 lowercase letter
            </span>
          ) : (
            <span className={styles.incorrect}>
              <ClearIcon /> Include at least 1 lowercase letter
            </span>
          )}
        </li>
        <li>
          {[...password]?.some((char) => isCharacterUpperCase(char)) ? (
            <span className={styles.correct}>
              <CheckIcon /> Include at least 1 capital letter
            </span>
          ) : (
            <span className={styles.incorrect}>
              <ClearIcon /> Include at least 1 capital letter
            </span>
          )}
        </li>
        <li>
          {[...password]?.some((letter) => !Number.isNaN(Number.parseInt(letter, 10))) ? (
            <span className={styles.correct}>
              <CheckIcon /> Include at least 1 number
            </span>
          ) : (
            <span className={styles.incorrect}>
              <ClearIcon /> Include at least 1 number
            </span>
          )}
        </li>
        <li>
          {[...password]?.some((char) => isCharacterSpecial(char)) ? (
            <span className={styles.correct}>
              <CheckIcon /> Include at least 1 special character ({specialChars})
            </span>
          ) : (
            <span className={styles.incorrect}>
              <ClearIcon /> Include at least 1 special character ({specialChars})
            </span>
          )}
        </li>
      </ul>
    </small>
  );
}

PassowordValidation.defaultProps = defaultProps;

export default PassowordValidation;
