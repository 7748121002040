const designVersion: string = '';

export const mainMenu = {
    topNav: [
        {
            label: 'My Tasks',
            id: 'myTasks',
            icon: 'CheckCircleIcon',
            showStats: true,
            link: `${designVersion}/my-tasks`,
        },
        {
            label: 'My Documents',
            id: 'myDocuments',
            icon: 'ReceiptLongIcon',
            showStats: true,
            link: `${designVersion}/my-documents`,
        },
    ],
    bottomNav: [
        {
            label: 'Dashboard',
            id: 'dashboard',
            icon: 'EqualizerIcon',
            link: `${designVersion}/my-dashboard`,
        },
        {
            label: 'Programs',
            id: 'programs',
            icon: 'AssignmentTurnedInIcon',
            link: `${designVersion}/assessments`,
            sub: [
                {
                    id: 'assignmentsFirst',
                    label: 'Assessments',
                    link: `${designVersion}/assessments`,
                },
                {
                    id: 'assignemntsRemediations',
                    label: 'Remediations',
                    link: `${designVersion}/assessments/remediations`,
                },
                {
                    id: 'assignmentsTasks',
                    label: 'Open Tasks',
                    link: `${designVersion}/assessments/tasks`,
                },
            ]
        },
        {
            id: 'people',
            label: 'People',
            icon: 'PeopleIcon',
            link: `${designVersion}/people`,
        },
        {
            label: 'Assets',
            id: 'assets',
            icon: 'WorkIcon',
            link: `${designVersion}/assets`,
            sub: [
                {
                    id: 'assetsDevices',
                    label: 'Devices',
                    link: `${designVersion}/assets`,
                },
                {
                    id: 'assetsApplications',
                    label: 'Applications',
                    link: `${designVersion}/assets/applications`,
                },
                {
                    id: 'assetsTasks',
                    label: 'Open Tasks',
                    link: `${designVersion}/assets/tasks`,
                },
            ]
        },
        {
            id: 'documents',
            label: 'Documents',
            icon: 'ReceiptLongIcon',
            link: `${designVersion}/documents`,
        },
        {
            label: 'Training',
            id: 'trainings',
            icon: 'SchoolIcon',
            link: `${designVersion}/trainings`,
            sub: [
                {
                    id: 'trainingTrainings',
                    label: 'Trainings',
                    link: `${designVersion}/trainings`,
                },
                {
                    id: 'trainingAssignments',
                    label: 'Assignments',
                    link: `${designVersion}/trainings/assignments`,
                },
            ]
        },
        {
            id: 'vendors',
            label: 'Vendors',
            icon: 'StorefrontIcon',
            link: `${designVersion}/vendors`,
        },
        {
            id: 'reports',
            label: 'Reports',
            icon: 'MonitorHeartIcon',
            link: `${designVersion}/reports`,
        },
        {
            id: 'incidents',
            label: 'Incidents',
            icon: 'PrivacyTipIcon',
            link: `${designVersion}/incidents`,
            sub: [
                {
                    id: 'incidentsIncidents',
                    label: 'Incidents',
                    link: `${designVersion}/incidents`,
                },
                {
                    id: 'incidentsAssignments',
                    label: 'Assignments',
                    link: `${designVersion}/incidents/assignments`,
                },
            ]
        },
        {
            id: 'admin',
            label: 'Admin',
            icon: 'SettingsIcon',
            link: `${designVersion}/admin`,
        },
    ]
}

export const userMenu = [
    {
        label: 'My Account',
        icon: 'AccountCircleIcon',
        link: `${designVersion}/profile`,
    },
    {
        label: 'Settings',
        icon: 'SettingsIcon',
        link: `${designVersion}/profile/security`,
    },
    {
        label: 'Change Org',
        icon: 'CorporateFareIcon',
        link: `/login/site`,
    },
]