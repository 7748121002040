/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { pdf, Page, Text, View, Image, Document, StyleSheet, PDFViewer, Font } from '@react-pdf/renderer';

import rr from 'assets/fonts/Roboto-Regular.ttf';
import rb from 'assets/fonts/Roboto-Bold.ttf';
import pr from 'assets/fonts/PlayfairDisplay-SemiBoldItalic.ttf';

import backgroundImg from 'assets/img/background-v2.jpg';
import compliancyLogo from 'assets/img/compliancy-group-logo.png';
import { loadCertificateAction } from 'store/actions/Actions';

import JSZip from 'jszip';
import { useAppDispatch, useAppSelector } from 'hooks';

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: rr,
    },
    {
      src: rb,
      fontWeight: 'bold',
    },
  ],
});

Font.register({
  family: 'Playfair',
  fonts: [
    {
      src: pr,
    },
  ],
});

const styles = StyleSheet.create({
  page: {},
  section: {
    flexGrow: 1,
    margin: 'auto',
    backgroundColor: 'white',
    width: '100%',
    textAlign: 'center',
  },
  image_logo: {
    height: '40px',
    width: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '25px',
  },
  report: {
    marginTop: '80px',
    fontSize: '36px',
    color: '#13509c',
    fontFamily: 'Playfair',
    fontWeight: 'bold',
  },
  msg: {
    marginTop: '15px',
    fontSize: '16px',
    fontFamily: 'Roboto',
    fontWeight: 'bold',
  },
  training: {
    margin: '0 auto',
    marginTop: '30px',
    fontFamily: 'Roboto',
    fontSize: '18px',
    borderBottom: '1px solid #e3e3e3',
    width: '300px',
  },
  image: {
    height: '100px',
    width: '100px',
    margin: 'auto',
  },
  image_bg: {
    height: '100vh',
    width: '100vw',
    marginTop: 'auto',
    marginBottom: '20px',
    position: 'absolute',
  },
  line: {
    margin: '0 auto',
    marginTop: '10px',
    fontFamily: 'Roboto',
    fontSize: '18px',
    borderBottom: '1px solid #e3e3e3',
    width: '300px',
  },
  label: {
    color: '#a3a3a3',
    marginTop: '30px',
    fontSize: '12px',
  },
});

const MyDocument: any = Document;
const MyPage: any = Page;

// cert template
export const myCert = (props) => (
  <MyDocument title="Certificate of Achievement" author="" subject="" creator="" producer="">
    <MyPage size="A4" orientation="landscape" style={styles.page}>
      <View style={styles.section}>
        <Image src={backgroundImg} style={styles.image_bg} />
        <Image src={compliancyLogo} style={styles.image_logo} />

        <Text style={styles.report}>Certificate of Achievement</Text>
        <Text style={styles.msg}>Successfully completed the course</Text>
        <Text style={styles.training}>{props.training_name}</Text>
        <Text style={styles.label}>First &amp; Last Name:</Text>
        <Text style={styles.line}>{props.user_name}</Text>
        <Text style={styles.label}>Organization Name:</Text>
        <Text style={styles.line}>{props.organization_name}</Text>
        <Text style={{ ...styles.label, marginTop: '60px' }}>Date:</Text>
        <Text style={styles.line}>{props.created_at ? moment(props.created_at).format('MM/DD/YYYY') : ''}</Text>
      </View>
    </MyPage>
  </MyDocument>
);

function PdfCertificate() {
  const { certId } = useParams();

  const dispatch = useAppDispatch();
  const CertificateReducer = useAppSelector((state) => state.CertificateReducer);

  useEffect(() => {
    dispatch(loadCertificateAction(certId));
  }, [certId, dispatch]);

  return (
    <div style={{ width: '100%', height: '100vh', display: 'flex' }}>
      {CertificateReducer && CertificateReducer.certificate && (
        <PDFViewer width="100%" height="auto" style={{ border: 0 }}>
          {myCert(CertificateReducer.certificate)}
        </PDFViewer>
      )}
    </div>
  );
}

/* use: <button onClick={() => generatePDFDocument(myCert)}>download</button> */
export const generatePDFDocument = async (template) => {
  const blob = await pdf(template).toBlob();
  saveAs(blob, 'certificate');
};

export const generatePDFDocuments = async (templatesAndData) => {
  const zip = new JSZip();
  const pdfs = zip.folder('certificates');

  for (const item of templatesAndData) {
    const blob = await pdf(item.template).toBlob();
    const training_name = item?.data?.training_name.replace(/\s+/g, '_') || '';
    const user_name = item?.data?.user_name.replace(/\s+/g, '_') || '';
    const filename = `${user_name}-${training_name}-${item.data.id}.pdf`;
    await pdfs.file(filename, blob);
  }

  zip
    .generateAsync({ type: 'blob' })
    .then((blob) => {
      saveAs(blob, 'certificates.zip');
      return true;
    })
    .catch(() => {});
};

export const previewPDFDocument = (template) => (
  <PDFViewer width="600px" height="425px" style={{ border: 0 }} showToolbar={false}>
    {template}
  </PDFViewer>
);

export default PdfCertificate;
