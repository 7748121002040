import { fork } from 'redux-saga/effects';

import AddOfficerFormSaga from './Users/AddOfficerFormSaga';
import AuthGetUserSaga from './Auth/AuthGetUserSaga';
import AuthLogoutSaga from './Auth/AuthLogoutSaga';
import EditUserFormSaga from './Users/EditUserFormSaga';
import UserLoginFormSaga from './Auth/SubmitUserLoginFormSaga';
import UserRegisterFormSaga from './Auth/UserRegisterFormSaga';
import SubmitUserForgotPasswordSaga from './Auth/SubmitUserForgotPasswordSaga';
import LoadAuditsSaga from './Audits/LoadAuditsSaga';
import CreateAuditSaga from './Audits/CreateAuditSaga';
import LoadActsSaga from './Audits/LoadActsSaga';
import LoadSitesSaga from './Audits/LoadSitesSaga';
import UpdateAuditSaga from './Audits/UpdateAuditSaga';
import EditAuditSaga from './Audits/EditAuditSaga';
import LoadAuditSaga from './Audits/LoadAuditSaga';
import DeleteAuditSaga from './Audits/DeleteAuditSaga';
import NotifyAuditSaga from './Audits/NotifyAuditSaga';
import CompleteAuditSaga from './Audits/CompleteAuditSaga';
import LoadRemediationPlansSaga from './Remediation/LoadRemediationPlansSaga';
import AddRemediationPlanFormSaga from './Remediation/AddRemediationPlanFormSaga';
import EditRemediationPlanFormSaga from './Remediation/EditRemediationPlanFormSaga';
import LoadEditRemediationPlanFormSaga from './Remediation/LoadEditRemediationPlanFormSaga';
import CompleteRemediationPlanSaga from './Remediation/CompleteRemediationPlanSaga';
import IncompleteRemediationPlanSaga from './Remediation/IncompleteRemediationPlanSaga';
import DeleteRemediationPlanSaga from './Remediation/DeleteRemediationPlanSaga';
import NotifyRemediationPlanSaga from './Remediation/NotifyRemediationPlanSaga';
import LoadGapsSaga from './Gaps/LoadGapsSaga';
import LoadRolesSaga from './Users/LoadRolesSaga';
import LoadAffiliatesSaga from './Affiliates/LoadAffiliatesSaga';
import EditAffiliateFormSaga from './Affiliates/EditAffiliateFormSaga';
import EmailAffiliateFilesSaga from './Affiliates/EmailAffiliateFilesSaga';
import AddAffiliateFormSaga from './Affiliates/AddAffiliateFormSaga';
import ApproveAffiliateSaga from './Affiliates/ApproveAffiliateSaga';
import DeclineAffiliateSaga from './Affiliates/DeclineAffiliateSaga';
import NotifyAffiliateSaga from './Affiliates/NotifyAffiliateSaga';
import SaveVendorAuditAffiliateSaga from './Affiliates/SaveVendorAuditAffiliateSaga';
import SendVendorAuditAffiliateSaga from './Affiliates/SendVendorAuditAffiliateSaga';
import SendBaAgreementAffiliateSaga from './Affiliates/SendBaAgreementAffiliateSaga';
import SendConfAgreementAffiliateSaga from './Affiliates/SendConfAgreementAffiliateSaga';
import LoadEditAffiliateFormSaga from './Affiliates/LoadEditAffiliateFormSaga';
import DeleteAffiliateSaga from './Affiliates/DeleteAffiliateSaga';
import LoadIncidentsSaga from './Incidents/LoadIncidentsSaga';
import LoadIncidentManagerSaga from './Incidents/LoadIncidentManagerSaga';
import LoadIncidentTypesSaga from './Incidents/LoadIncidentTypesSaga';
import UpdateIncidentManagerSaga from './Incidents/UpdateIncidentManagerSaga';
import AddNewIncidentSaga from './Incidents/AddNewIncidentSaga';
import DeleteIncidentSaga from './Incidents/DeleteIncidentSaga';
import ApproveIncidentSaga from './Incidents/ApproveIncidentSaga';
import InvestigateIncidentSaga from './Incidents/InvestigateIncidentSaga';
import CompleteIncidentSaga from './Incidents/CompleteIncidentSaga';
import CreateTaskSaga from './Tasks/CreateTaskSaga';
import LoadTasksSaga from './Tasks/LoadTasksSaga';
import LoadOneTaskSaga from './Tasks/LoadOneTaskSaga';
import UpdateTaskSaga from './Tasks/UpdateTaskSaga';
import DeleteTaskSaga from './Tasks/DeleteTaskSaga';
import NotifyTaskSata from './Tasks/NotifyTaskSaga';
// import LoadImportantDocumentsSaga from "./Dashboard/LoadImportantDocumentsSaga";
import LoadTrainingDocumentsSaga from './Dashboard/LoadTrainingDocumentsSaga';
import LoadTrainingMediaSaga from './Dashboard/LoadTrainingMediaSaga';
import LoadOrgsitedeptSaga from './Orgsitedept/LoadOrgsitedeptSaga';
import UpdateDeptSaga from './Orgsitedept/UpdateDeptSaga';
import UpdateOrgSaga from './Orgsitedept/UpdateOrgSaga';
import AddNewOrgWizardSaga from './Orgsitedept/AddNewOrgWizardSaga';
import UpdateSiteSaga from './Orgsitedept/UpdateSiteSaga';
import CreateSiteSaga from './Orgsitedept/CreateSiteSaga';
import CreateOrgSaga from './Orgsitedept/CreateOrgSaga';
import AddNewDepartmentSaga from './Orgsitedept/AddNewDepartmentSaga';
import DeleteDeptSaga from './Orgsitedept/DeleteDeptSaga';
import LoadTrainingSaga from './Training/LoadTrainingSaga';
import LoadTrainingCoursesSaga from './Training/LoadTrainingCoursesSaga';
import CreateTrainingSaga from './Training/CreateTrainingSaga';
import DeleteTrainingSaga from './Training/DeleteTrainingSaga';
import UpdateTrainingSaga from './Training/UpdateTrainingSaga';
import CompleteTrainingSaga from './Training/CompleteTrainingSaga';
import IncompleteTrainingSaga from './Training/IncompleteTrainingSaga';
import LoadAlertsSaga from './Alerts/LoadAlertsSaga';
import UpdateAlertsSaga from './Alerts/UpdateAlertsSaga';
import LoadUsersSaga from './Users/LoadUsersSaga';
import LoadRolesUserSaga from './Users/LoadRolesUserSaga';
import CreateUsersBulkSaga from './Users/CreateUsersBulkSaga';
import LoadReportsSaga from './Reports/LoadReportsSaga';
import CreateReportSaga from './Reports/CreateReportSaga';
import LoadFoldersSaga from './Documents/LoadFoldersSaga';
import LoadDepartmentsSaga from './Departments/LoadDepartmentsSaga';
import CreateFolderSaga from './Documents/CreateFolderSaga';
import UpdateFolderSaga from './Documents/UpdateFolderSaga';
import DeleteFolderSaga from './Documents/DeleteFolderSaga';
import DeleteReportSaga from './Reports/DeleteReportSaga';
import LoadFolderContentsSaga from './Documents/LoadFolderContentsSaga';
import LoadDocumentSaga from './Dashboard/LoadDocumentSaga';
import EditDocumentSaga from './Documents/EditDocumentSaga';
import RestoreDocumentSaga from './Documents/RestoreDocumentSaga';
import NotifyIncidentSaga from './Incidents/NotifyIncidentSaga';
import BulkUploadAffiliateSaga from './Affiliates/BulkUploadAffiliateSaga';
import BulkUploadSiteSaga from './Orgsitedept/BulkUploadSiteSaga';
import BulkUploadDeviceSaga from './Devices/BulkUploadDeviceSaga';
import BulkUploadTrainingSaga from './Training/BulkUploadTrainingSaga';
import DeleteAffiliateDocumentSaga from './Affiliates/DeleteAffiliateDocumentSaga';
import LoadStatsSaga from './Dashboard/LoadStatsSaga';
import LoadUserStatsSaga from './Dashboard/LoadUserStatsSaga';
import LoadStepsSaga from './Dashboard/LoadStepsSaga';
import ActivateUserSaga from './Users/ActivateUserSaga';
// import DeleteUserSaga from "./Users/DeleteUserSaga";
import DeactivateUserSaga from './Users/DeactivateUserSaga';
import LoadUserOrgSiteSaga from './Auth/LoadUserOrgSiteSaga';
import CreateDeviceSaga from './Devices/CreateDeviceSaga';
import UpdateDeviceSaga from './Devices/UpdateDeviceSaga';
import LoadDevicesSaga from './Devices/LoadDevicesSaga';
import DecommissionDevicesSaga from './Devices/DecommissionDevicesSaga';
import DuplicateDeviceSaga from './Devices/DuplicateDeviceSaga';
import LoadFilesSaga from './Documents/LoadFilesSaga';
import LoadUnapprovedFilesSaga from './Documents/LoadUnapprovedFilesSaga';
import CompleteTaskSaga from './Tasks/CompleteTaskSaga';
import LoadAttestationsSaga from './Tasks/LoadAttestationsSaga';
import AttestDocumentSaga from './Dashboard/AttestDocumentSaga';
import AttestDocumentDashboardSaga from './Dashboard/AttestDocumentDashboardSaga';
import SubmitUserResetPasswordSaga from './Auth/SubmitUserResetPasswordSaga';
import VerifyAuditSaga from './Audits/VerifyAuditSaga';
import ReopenAuditSaga from './Audits/ReopenAuditSaga';
import SubmitUserSettingsFormSaga from './Auth/SubmitUserSettingsFormSaga';
import LoadTasksForUserSaga from './Tasks/LoadTasksForUserSaga';
import ApproveDocumentSaga from './Documents/ApproveDocumentSaga';
import InitializeDocumentSaga from './Documents/InitializeDocumentSaga';
import CleanupDocumentSaga from './Documents/CleanupDocumentSaga';
import ReadVendorAuditSaga from './Affiliates/ReadVendorAuditSaga';
import ReadVendorAuditTokenSaga from './Affiliates/ReadVendorAuditTokenSaga';
import ReadAffiliateAgreementsSaga from './Affiliates/ReadAffiliateAgreementsSaga';
import LoadActsAllSaga from './Audits/LoadActsAllSaga';
import UpdateOrgActsSaga from './Orgsitedept/UpdateOrgActsSaga';
import LoadSettingsSaga from './Users/LoadSettingsSaga';
import LoadUserByKeySaga from './Users/LoadUserByKeySaga';
import ResendRegistrationEmailSaga from './Users/ResendRegistrationEmailSaga';
import ResendRegistrationEmailToAllSaga from './Users/ResendRegistrationEmailToAllSaga';

import AddIncidentNoteSaga from './Incidents/AddIncidentNoteSaga';
import LoadIncidentNotesSaga from './Incidents/LoadIncidentNotesSaga';
import LoadUserByOrgSaga from './Users/LoadUserByOrgSaga';
import SaveAsDocumentSaga from './Documents/SaveAsDocumentSaga';
import AuthCheckSaga from './Auth/AuthCheckSaga';
import DeleteDeviceSaga from './Devices/DeleteDeviceSaga';
import DeactivateSiteSaga from './Orgsitedept/DeactivateSiteSaga';
import ActivateSiteSaga from './Orgsitedept/ActivateSiteSaga';
import CreateEvidenceSaga from './Evidence/CreateEvidenceSaga';
import LoadEvidenceSaga from './Evidence/LoadEvidenceSaga';
import DeleteEvidenceSaga from './Evidence/DeleteEvidenceSaga';
import DownloadEvidenceSaga from './Evidence/DownloadEvidenceSaga';
import ApproveAffiliateDocumentSaga from './Affiliates/ApproveAffiliateDocumentSaga';
import UnapproveAffiliateDocumentSaga from './Affiliates/UnapproveAffiliateDocumentSaga';
import RevokeUserAccessSaga from './Users/RevokeUserAccessSaga';
import CreateTasknoteSaga from './Tasks/CreateTasknoteSaga';
import DeleteTasknoteSaga from './Tasks/DeleteTasknoteSaga';
import DeleteIncidentnoteSaga from './Incidents/DeleteIncidentnoteSaga';
import DownloadUsersCsvSaga from './Users/DownloadUsersCsvSaga';
import SetDatatableSaga from './Datatable/SetDatatableSaga';
import DownloadReportSaga from './Reports/DownloadReportSaga';
import LoadDashboardTasksSaga from './Dashboard/LoadDashboardTasksSaga';
import UploadAuditSaga from './Audits/UploadAuditSaga';
import DownloadAuditSaga from './Audits/DownloadAuditSaga';
import DownloadAffiliateFileSaga from './Affiliates/DownloadAffiliateFileSaga';
import LoadOrganizationsSaga from './SuperUser/LoadOrganizationsSaga';
import LoadSuperadminsSaga from './Orgsitedept/LoadSuperadminsSaga';
import LoadOrganiizationDetailsSaga from './SuperUser/LoadOrganizationDetailsSaga';
import SetGrantSealSaga from './Dashboard/SetGrantSealSaga';
import PaginationOrganizationsSaga from './SuperUser/PaginationOrganizationsSaga';
import LoadUsersUnassignedSaga from './Users/LoadUsersUnassignedSaga';
import LoadCertificateSaga from './Certificates/LoadCertificateSaga';
import LoadCertificatesSaga from './Certificates/LoadCertificatesSaga';
import LoadUserCertificatesSaga from './Certificates/LoadUserCertificatesSaga';
import LoadUserCertificateSaga from './Certificates/LoadUserCertificateSaga';
import SearchUsersSaga from './Users/SearchUsersSaga';
import SuspendTrainingSaga from './Training/SuspendTrainingSaga';
import UnsuspendTrainingSaga from './Training/UnsuspendTrainingSaga';
import CheckQuizAnswersSaga from './Training/CheckQuizAnswersSaga';
import LoadQuizQuestionsSaga from './Training/LoadQuizQuestionsSaga';
import LoadReasignableUsersSaga from './Users/LoadReasignableUsersSaga';
import ReassignAllTasksSaga from './Tasks/ReassignAllTasksSaga';
import UploadDocumentsSaga from './Documents/UploadDocumentsSaga';
import CreateDeviceInventorySaga from './DeviceInventory/CreateDeviceInventorySaga';
import createDeviceInventoryV2Saga from './DeviceInventory/CreateDeviceInventoryV2Saga';
import LoadCloudAssetsSaga from './CloudAssets/LoadCloudAssetsSaga';
import LoadCloudAssetsV2Saga from './CloudAssets/LoadCloudAssetsV2Saga';
import CreateCloudAssetsSaga from './CloudAssets/CreateCloudAssetsSaga';
import CreateCloudAssetsV2Saga from './CloudAssets/CreateCloudAssetsV2Saga';
import DownloadTemplateCloudAssetsSaga from './CloudAssets/DownloadTemplateCloudAssetsSaga';
import DownloadTemplateCloudAssetsV2Saga from './CloudAssets/DownloadTemplateCloudAssetsV2Saga';
import UpdateCloudAssetsSaga from './CloudAssets/UpdateCloudAssetsSaga';
import UpdateCloudAssetsV2Saga from './CloudAssets/UpdateCloudAssetsV2Saga';
import ActivateCloudAssetsSaga from './CloudAssets/ActivateCloudAssetsSaga';
import DeactivateCloudAssetsSaga from './CloudAssets/DeactivateCloudAssetsSaga';
import LoadDeviceInvetorySaga from './DeviceInventory/LoadDeviceInventorySaga';
import LoadDeviceInventoryV2Saga from './DeviceInventory/LoadDeviceInventoryV2Saga';
import DownloadTemaplateDeviceInventory from './DeviceInventory/DownloadTemaplateDeviceInventorySaga';
import DownloadTemplateDeviceInventoryV2Saga from './DeviceInventory/DownloadTemplateDeviceInventoryV2Saga';
import ActivateDeviceInventory from './DeviceInventory/ActivateDeviceInventorySaga';
import DeactivateDeviceInventory from './DeviceInventory/DeactivateDeviceInventorySaga';
import UpdateDeviceInventorySaga from './DeviceInventory/UpdateDeviceInventorySaga';
import UpdateDeviceInventoryV2Saga from './DeviceInventory/UpdateDeviceInventoryV2Saga';
import BulkUploadDeviceInventory from './DeviceInventory/BulkUploadDeviceInventorySaga';
import BulkUploadDeviceInventoryV2Saga from './DeviceInventory/BulkUploadDeviceInventoryV2Saga';
import BulkUploadCloudAssetsSaga from './CloudAssets/BulkUploadCloudAssetsSaga';
import BulkUploadCloudAssetsV2Saga from './CloudAssets/BulkUploadCloudAssetsV2Saga';
import DownloadUpdateTemplateCloudAssetsSaga from './CloudAssets/DownloadUpdateTemplateCloudAssetsSaga';
import BulkUpdateCloudAssetsSaga from './CloudAssets/BulkUpdateCloudAssetsSaga';
import DestroyDeviceInventorySaga from './DeviceInventory/DestroyDeviceInventorySaga';
import DownloadUpdateTemplateDeviceInventorySaga from './DeviceInventory/DownloadUpdateTemplateDeviceInventorySaga';
import BulkUpdateDeviceInventorySaga from './DeviceInventory/BulkUpdateDeviceInventorySaga';
import LoadEvidenceAssetSaga from './Evidence/LoadEvidenceAssetSaga';
import LoadEvidenceInventorySaga from './Evidence/LoadEvidenceInventorySaga';
import LoadEvidenceAuditSaga from './Evidence/LoadEvidenceAuditSaga';
import LoadModulesSaga from './Modules/LoadModulesSaga';
import LoadOrgModulesSaga from './Modules/LoadOrgModulesSaga';
import LoadTopLevelFoldersSaga from './Documents/LoadTopLevelFoldersSaga';
import LoadOrgEntitiesSaga from './Modules/LoadOrgEntitiesSaga';
import UpdateOrgModulesSaga from './Modules/UpdateOrgModulesSaga';
import MfaActivateSaga from './Mfa/MfaActivateSaga';
import MfaGenerateCodeSaga from './Mfa/MfaGenerateCodeSaga';
import VerifyCodeSaga from './Mfa/MfaVerifyCodeSaga';
import OrganizationsAzureADSaga from './MicrosoftAzureActiveDirectory/OrganizationsAzureADSaga';
import ConnectOrgAzureADSaga from './MicrosoftAzureActiveDirectory/ConnectOrgAzureADSaga';
import UsersOrgAzureADSaga from './MicrosoftAzureActiveDirectory/UsersOrgAzureADSaga';
import ConnectUsersAzureADSaga from './MicrosoftAzureActiveDirectory/ConnectUsersAzureADSaga';
import LoginAttemptSaga from './Auth/LoginAttemptSaga';
import SSOLoginSaga from './Auth/SSOLoginSaga';
import MfaMasterKeySaga from './Mfa/MfaMasterKeySaga';
import LoadTasksFilteredSaga from './Tasks/LoadTasksFilteredSaga';
import LoadUserCountersSaga from './Users/LoadUserCounters';
import DownloadDevicesSaga from './Devices/DownloadDevicesSaga';

function* RootSaga() {
  yield fork(UserLoginFormSaga);
  yield fork(UserRegisterFormSaga);
  yield fork(SubmitUserForgotPasswordSaga);
  yield fork(LoadAuditsSaga);
  yield fork(CreateAuditSaga);
  yield fork(UpdateAuditSaga);
  yield fork(EditAuditSaga);
  yield fork(LoadActsSaga);
  yield fork(LoadSitesSaga);
  yield fork(LoadAuditSaga);
  yield fork(DeleteAuditSaga);
  yield fork(NotifyAuditSaga);
  yield fork(CompleteAuditSaga);
  yield fork(LoadRemediationPlansSaga);
  yield fork(AddOfficerFormSaga);
  yield fork(EditUserFormSaga);
  yield fork(CreateUsersBulkSaga);
  yield fork(AuthGetUserSaga);
  yield fork(AuthLogoutSaga);
  yield fork(LoadUsersSaga);
  yield fork(LoadUsersUnassignedSaga);
  yield fork(AddRemediationPlanFormSaga);
  yield fork(EditRemediationPlanFormSaga);
  yield fork(LoadEditRemediationPlanFormSaga);
  yield fork(CompleteRemediationPlanSaga);
  yield fork(IncompleteRemediationPlanSaga);
  yield fork(DeleteRemediationPlanSaga);
  yield fork(NotifyRemediationPlanSaga);
  yield fork(LoadAffiliatesSaga);
  yield fork(EditAffiliateFormSaga);
  yield fork(EmailAffiliateFilesSaga);
  yield fork(AddAffiliateFormSaga);
  yield fork(DeleteAffiliateSaga);
  yield fork(LoadEditAffiliateFormSaga);
  yield fork(LoadIncidentsSaga);
  yield fork(LoadIncidentManagerSaga);
  yield fork(LoadIncidentTypesSaga);
  yield fork(UpdateIncidentManagerSaga);
  yield fork(AddNewIncidentSaga);
  yield fork(DeleteIncidentSaga);
  yield fork(ApproveIncidentSaga);
  yield fork(InvestigateIncidentSaga);
  yield fork(CompleteIncidentSaga);
  yield fork(NotifyIncidentSaga);
  yield fork(CreateTaskSaga);
  yield fork(LoadTasksSaga);
  yield fork(LoadTasksFilteredSaga);
  yield fork(LoadOneTaskSaga);
  yield fork(DeleteTaskSaga);
  yield fork(UpdateTaskSaga);
  // yield fork(LoadImportantDocumentsSaga);
  yield fork(LoadTrainingDocumentsSaga);
  yield fork(LoadTrainingMediaSaga);
  yield fork(LoadOrgsitedeptSaga);
  yield fork(UpdateOrgSaga);
  yield fork(AddNewOrgWizardSaga);
  yield fork(UpdateSiteSaga);
  yield fork(CreateSiteSaga);
  yield fork(CreateOrgSaga);
  yield fork(UpdateDeptSaga);
  yield fork(AddNewDepartmentSaga);
  yield fork(DeleteDeptSaga);
  yield fork(ApproveAffiliateSaga);
  yield fork(DeclineAffiliateSaga);
  yield fork(NotifyAffiliateSaga);
  yield fork(SaveVendorAuditAffiliateSaga);
  yield fork(SendVendorAuditAffiliateSaga);
  yield fork(SendBaAgreementAffiliateSaga);
  yield fork(SendConfAgreementAffiliateSaga);
  yield fork(ReadAffiliateAgreementsSaga);
  yield fork(ReadVendorAuditSaga);
  yield fork(ReadVendorAuditTokenSaga);
  yield fork(LoadTrainingSaga);
  yield fork(LoadTrainingCoursesSaga);
  yield fork(CreateTrainingSaga);
  yield fork(DeleteTrainingSaga);
  yield fork(UpdateTrainingSaga);
  yield fork(CompleteTrainingSaga);
  yield fork(IncompleteTrainingSaga);
  yield fork(LoadAlertsSaga);
  yield fork(UpdateAlertsSaga);
  yield fork(LoadReportsSaga);
  yield fork(CreateReportSaga);
  yield fork(LoadFoldersSaga);
  yield fork(LoadDepartmentsSaga);
  yield fork(CreateFolderSaga);
  yield fork(UpdateFolderSaga);
  yield fork(DeleteReportSaga);
  yield fork(LoadFolderContentsSaga);
  yield fork(DeleteFolderSaga);
  yield fork(LoadDocumentSaga);
  yield fork(RestoreDocumentSaga);
  yield fork(BulkUploadAffiliateSaga);
  yield fork(BulkUploadSiteSaga);
  yield fork(BulkUploadDeviceSaga);
  yield fork(BulkUploadTrainingSaga);
  yield fork(LoadGapsSaga);
  yield fork(DeleteAffiliateDocumentSaga);
  yield fork(ApproveAffiliateDocumentSaga);
  yield fork(UnapproveAffiliateDocumentSaga);
  yield fork(LoadRolesSaga);
  yield fork(LoadRolesUserSaga);
  yield fork(LoadStatsSaga);
  yield fork(LoadStepsSaga);
  yield fork(DeactivateUserSaga);
  // yield fork(DeleteUserSaga);
  yield fork(ActivateUserSaga);
  yield fork(LoadUserOrgSiteSaga);
  yield fork(CreateDeviceSaga);
  yield fork(UpdateDeviceSaga);
  yield fork(LoadDevicesSaga);
  yield fork(LoadCloudAssetsSaga);
  yield fork(LoadCloudAssetsV2Saga);
  yield fork(DecommissionDevicesSaga);
  yield fork(DuplicateDeviceSaga);
  yield fork(DeleteDeviceSaga);
  yield fork(DownloadDevicesSaga);
  yield fork(LoadFilesSaga);
  yield fork(CompleteTaskSaga);
  yield fork(NotifyTaskSata);
  yield fork(LoadAttestationsSaga);
  yield fork(AttestDocumentSaga);
  yield fork(AttestDocumentDashboardSaga);
  yield fork(SubmitUserResetPasswordSaga);
  yield fork(VerifyAuditSaga);
  yield fork(ReopenAuditSaga);
  yield fork(SubmitUserSettingsFormSaga);
  yield fork(LoadTasksForUserSaga);
  yield fork(LoadUserStatsSaga);
  yield fork(EditDocumentSaga);
  yield fork(LoadUnapprovedFilesSaga);
  yield fork(ApproveDocumentSaga);
  yield fork(InitializeDocumentSaga);
  yield fork(CleanupDocumentSaga);
  yield fork(LoadActsAllSaga);
  yield fork(UpdateOrgActsSaga);
  yield fork(LoadSettingsSaga);
  yield fork(AddIncidentNoteSaga);
  yield fork(LoadIncidentNotesSaga);
  yield fork(LoadUserByKeySaga);
  yield fork(ResendRegistrationEmailSaga);
  yield fork(LoadUserByOrgSaga);
  yield fork(SaveAsDocumentSaga);
  yield fork(AuthCheckSaga);
  yield fork(ResendRegistrationEmailToAllSaga);
  yield fork(DeactivateSiteSaga);
  yield fork(ActivateSiteSaga);
  yield fork(CreateEvidenceSaga);
  yield fork(CreateTasknoteSaga);
  yield fork(DeleteTasknoteSaga);
  yield fork(LoadEvidenceSaga);
  yield fork(DeleteEvidenceSaga);
  yield fork(DownloadEvidenceSaga);
  yield fork(RevokeUserAccessSaga);
  yield fork(DeleteIncidentnoteSaga);
  yield fork(DownloadUsersCsvSaga);
  yield fork(SetDatatableSaga);
  yield fork(DownloadReportSaga);
  yield fork(LoadDashboardTasksSaga);
  yield fork(UploadAuditSaga);
  yield fork(DownloadAuditSaga);
  yield fork(DownloadAffiliateFileSaga);
  yield fork(LoadOrganizationsSaga);
  yield fork(LoadOrganiizationDetailsSaga);
  yield fork(LoadSuperadminsSaga);
  yield fork(SetGrantSealSaga);
  yield fork(PaginationOrganizationsSaga);
  yield fork(LoadCertificateSaga);
  yield fork(LoadCertificatesSaga);
  yield fork(LoadUserCertificatesSaga);
  yield fork(LoadUserCertificateSaga);
  yield fork(SearchUsersSaga);
  yield fork(SuspendTrainingSaga);
  yield fork(UnsuspendTrainingSaga);
  yield fork(CheckQuizAnswersSaga);
  yield fork(LoadQuizQuestionsSaga);
  yield fork(LoadReasignableUsersSaga);
  yield fork(ReassignAllTasksSaga);
  yield fork(UploadDocumentsSaga);
  yield fork(CreateDeviceInventorySaga);
  yield fork(createDeviceInventoryV2Saga);
  yield fork(CreateCloudAssetsSaga);
  yield fork(CreateCloudAssetsV2Saga);
  yield fork(UpdateCloudAssetsSaga);
  yield fork(UpdateCloudAssetsV2Saga);
  yield fork(DownloadTemplateCloudAssetsSaga);
  yield fork(DownloadTemplateCloudAssetsV2Saga);
  yield fork(BulkUploadCloudAssetsSaga);
  yield fork(BulkUploadCloudAssetsV2Saga);
  yield fork(DownloadUpdateTemplateCloudAssetsSaga);
  yield fork(BulkUpdateCloudAssetsSaga);
  yield fork(ActivateCloudAssetsSaga);
  yield fork(DeactivateCloudAssetsSaga);
  yield fork(LoadDeviceInvetorySaga);
  yield fork(LoadDeviceInventoryV2Saga);
  yield fork(DownloadTemaplateDeviceInventory);
  yield fork(DownloadTemplateDeviceInventoryV2Saga);
  yield fork(ActivateDeviceInventory);
  yield fork(DeactivateDeviceInventory);
  yield fork(UpdateDeviceInventorySaga);
  yield fork(UpdateDeviceInventoryV2Saga);
  yield fork(BulkUploadDeviceInventory);
  yield fork(BulkUploadDeviceInventoryV2Saga);
  yield fork(DestroyDeviceInventorySaga);
  yield fork(DownloadUpdateTemplateDeviceInventorySaga);
  yield fork(BulkUpdateDeviceInventorySaga);
  yield fork(LoadEvidenceAssetSaga);
  yield fork(LoadEvidenceInventorySaga);
  yield fork(LoadEvidenceAuditSaga);
  yield fork(LoadModulesSaga);
  yield fork(LoadOrgModulesSaga);
  yield fork(LoadTopLevelFoldersSaga);
  yield fork(LoadOrgEntitiesSaga);
  yield fork(UpdateOrgModulesSaga);
  yield fork(MfaActivateSaga);
  yield fork(MfaGenerateCodeSaga);
  yield fork(VerifyCodeSaga);
  yield fork(OrganizationsAzureADSaga);
  yield fork(ConnectOrgAzureADSaga);
  yield fork(UsersOrgAzureADSaga);
  yield fork(ConnectUsersAzureADSaga);
  yield fork(LoginAttemptSaga);
  yield fork(SSOLoginSaga);
  yield fork(MfaMasterKeySaga);
  yield fork(LoadUserCountersSaga);
}

export default RootSaga;
