import { call, put, takeLatest, delay } from 'redux-saga/effects';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { actionApiCall } from 'helpers/api/ApiCalls';
import * as sagaConstants from 'helpers/constants/SagasConstants';

function* loadOrganizationsSaga({ payload }) {
  try {
    const apiResponse = yield call(actionApiCall, {
      actionType: ActionTypeConstants.LOAD_ORGANIZATIONS,
      data: {
        page: payload.page,
        sort_by: payload.sort_by,
        sort_direction: payload.sort_direction,
        limit: payload.rowsPerPage,
        filter_by: {
          ...payload.filterBy,
          active: payload.filterBy && payload.filterBy.active ? (payload.filterBy.active === 2 ? false : true) : undefined,
        },
      },
    });
    if (apiResponse.res.data.response === 200) {
      const { data } = apiResponse.res.data;
      const d = data.organizations.map((org) => {
        return {
          ...org,
          ...org.stats,
          // name: org.org_name,
          coach: org.coach_name,
          ctp: Number((parseInt(org.stats['completed_tasks']) / parseInt(org.stats['total_tasks']) || 0) * 100).toFixed(0),
          cap: Number((parseInt(org.stats['finalized_audits']) / parseInt(org.stats['total_audits']) || 0) * 100).toFixed(0),
          status: org['active'] === 't' ? 'Active' : 'Inactive',
        };
      });
      yield put({
        type: ActionTypeConstants.LOAD_ORGANIZATIONS_SUCCESS,
        payload: {
          organizations: d,
          coaches: data.coaches,
          count: parseInt(data.count),
          page: data.page,
        },
      });
    } else {
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: apiResponse.res.data.message || `${sagaConstants.ISSUE_OCCURED} loading organizations`,
          color: 'danger',
        },
      });
      yield delay(10000);
      yield put({ type: ActionTypeConstants.HIDE_ALERT });
    }
  } catch (err) {
    yield put({
      type: ActionTypeConstants.SHOW_ALERT,
      payload: {
        message: `${sagaConstants.SERVER_ERROR}`,
        color: 'danger',
      },
    });
    yield delay(10000);
    yield put({ type: ActionTypeConstants.HIDE_ALERT });
  }
}

export default function* () {
  yield takeLatest(ActionTypeConstants.LOAD_ORGANIZATIONS, loadOrganizationsSaga);
}
