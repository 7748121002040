import React, { useEffect, useState } from 'react';
import styles from './SubmitForm.module.scss';
import Button from 'components/Button/Button';
import { country_list } from 'helpers/utils';
import { loadActsAllAction, loadRolesAction, submitNewOrgWizardAction } from 'store/actions/Actions';
import { connect } from 'react-redux';
import Dimmer from 'components/Dimmer/Dimmer';

const SubmitForm = (props) => {
  const { ActsReducer, RolesReducer, loadRolesAction, submitNewOrgWizardAction, CreateOrgReducer } = props;

  const formData = props.formDataOrg;
  const cancel = props.cancelFun;
  const stepsFun = props.stepsFun;
  const steps = props.steps;
  const currentStep = props.currentStep;
  const setCurrentStep = props.setCurrentStep;
  const close = props.closeFun;
  const cleanup = props.cleanupFun;

  const [isLoading, setIsLoading] = useState(false);
  const [isSaveSuccessful, setIsSaveSuccessful] = useState(false);
  const [isPreviewVisible, setIsPreviewVisible] = useState(true);
  const [isSaveError, setIsSaveError] = useState(false);
  const [saveErrorMessages, setSaveErrorMessages] = useState([]);

  const getCountryName = (countryCode) => {
    if (country_list && country_list.length > 0) {
      const country = country_list.find((country) => parseInt(country.id, 10) === parseInt(countryCode, 10));
      if (country && country.name) {
        return country.name;
      }
      return false;
    }
  };

  const getActName = (actId) => {
    if (ActsReducer.all && ActsReducer.all.length > 0) {
      const act = ActsReducer.all.find((act) => parseInt(act.id, 10) === parseInt(actId, 10));
      if (act && act.name) {
        return act.name;
      }
      return false;
    }
  };

  const getRoleName = (level, roleId) => {
    if (RolesReducer.roles && RolesReducer.roles.length > 0) {
      const roles = RolesReducer.roles.filter((role) => role[level] === true);
      const theRole = roles.find((role) => parseInt(role.id, 10) === parseInt(roleId, 10));
      return theRole.name;
    }
  };

  useEffect(() => {
    if (CreateOrgReducer.status === 'SUCCESS') {
      setIsSaveError(false);
      setSaveErrorMessages([]);
      setIsSaveSuccessful(true);
      setIsPreviewVisible(false);
      cleanup();
      const stepsArr = JSON.parse(JSON.stringify(steps));
      stepsArr[2].active = true;
      stepsFun(stepsArr);
    } else if (CreateOrgReducer.status === 'FAILURE') {
      setIsSaveError(true);
      setSaveErrorMessages(CreateOrgReducer.message);
    }
    setIsLoading(false);
  }, [CreateOrgReducer]);

  const handleSubmit = () => {
    setIsLoading(true);
    submitNewOrgWizardAction(formData);
  };

  const handleBack = () => {
    const stepsArr = JSON.parse(JSON.stringify(steps));
    stepsArr[currentStep - 2].active = false;
    setCurrentStep(currentStep - 1);
    stepsFun(stepsArr);
  };

  useEffect(() => {
    if (!ActsReducer.all) {
      loadActsAllAction();
    }
    if (!RolesReducer.roles) {
      loadRolesAction();
    }
  }, [ActsReducer.all, RolesReducer.roles, loadActsAllAction]);

  return (
    <>
      <Dimmer active={isLoading} />
      <div className={styles['org-wrapper']}>
        {isPreviewVisible && !isSaveSuccessful && (
          <div>
            <div className={styles.org}>
              <div className={styles.title}>Organization:</div>
              {formData.name ? (
                <div>
                  <strong>Organization Name:</strong> {formData.name}
                </div>
              ) : null}
              {formData.customer_id ? (
                <div>
                  <strong>Customer ID:</strong> {formData.customer_id}
                </div>
              ) : null}
              {formData.address_1 ? (
                <div>
                  <strong>Address 1:</strong> {formData.address_1}
                </div>
              ) : null}
              {formData.address_2 ? (
                <div>
                  <strong>Address 2:</strong> {formData.address_2}
                </div>
              ) : null}
              {formData.city ? (
                <div>
                  <strong>City:</strong> {formData.city}
                </div>
              ) : null}
              {formData.region ? (
                <div>
                  <strong>Region:</strong> {formData.region}
                </div>
              ) : null}
              {formData.country_code ? (
                <div>
                  <strong>Country:</strong> {getCountryName(formData.country_code)}
                </div>
              ) : null}
              {formData.postal_code ? (
                <div>
                  <strong>Postal Code:</strong> {formData.postal_code}
                </div>
              ) : null}
              {formData.site_limit ? (
                <div>
                  <strong>Site Limit:</strong> {formData.site_limit}
                </div>
              ) : null}
              {formData.users && formData.users.length > 0 ? (
                <div>
                  <strong>Users: </strong>
                  <ul>
                    {formData.users.map((role) => (
                      <li key={role.id}>
                        <strong>{getRoleName('org_level', role.role)}: </strong>
                        {role.emails &&
                          role.emails.length > 0 &&
                          role.emails.map((email, index) => (
                            <span key={index + email}>
                              {email}
                              {index !== role.emails.length - 1 ? ', ' : null}
                            </span>
                          ))}
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null}
              {formData.acts && formData.acts.length > 0 ? (
                <div>
                  <strong>Acts: </strong>
                  {formData.acts.map((actId, index) => (
                    <span key={actId}>
                      {getActName(actId)}
                      {index !== formData.acts.length - 1 ? ', ' : null}
                    </span>
                  ))}
                </div>
              ) : null}
            </div>
            <div className={styles.sites}>
              {formData.sites && formData.sites.length > 0 ? <div className={styles.title}>Sites:</div> : null}
              {formData.sites.map((site, index) => {
                return (
                  <div key={site.id}>
                    {site.name ? (
                      <div>
                        <strong>Site Name:</strong> {site.name}
                      </div>
                    ) : null}
                    {site.address_1 ? (
                      <div>
                        <strong>Address 1:</strong> {site.address_1}
                      </div>
                    ) : null}
                    {site.address_2 ? (
                      <div>
                        <strong>Address 2:</strong> {site.address_2}
                      </div>
                    ) : null}
                    {site.city ? (
                      <div>
                        <strong>City:</strong> {site.city}
                      </div>
                    ) : null}
                    {site.region ? (
                      <div>
                        <strong>Region:</strong> {site.region}
                      </div>
                    ) : null}
                    {site.country_code ? (
                      <div>
                        <strong>Country:</strong> {getCountryName(site.country_code)}
                      </div>
                    ) : null}
                    {site.postal_code ? (
                      <div>
                        <strong>Postal Code:</strong> {site.postal_code}
                      </div>
                    ) : null}
                    {site.users && site.users.length > 0 ? (
                      <div>
                        <strong>Users: </strong>
                        <ul>
                          {site.users.map((role) => (
                            <li key={role.id}>
                              <strong>{getRoleName('site_level', role.role)}: </strong>
                              {role.emails &&
                                role.emails.length > 0 &&
                                role.emails.map((email, index) => (
                                  <span key={index + email}>
                                    {email}
                                    {index !== role.emails.length - 1 ? ', ' : null}
                                  </span>
                                ))}
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : null}
                    {site.departments && site.departments.length > 0 ? (
                      <div>
                        <strong>Departments: </strong>
                        <ul>
                          {site.departments.map((department, index) => {
                            return (
                              <li key={department.id}>
                                <strong>{department.name}</strong>
                                {department.users && department.users.length > 0
                                  ? department.users.map((user) => {
                                      return <span key={user.id}>{` - Users: ${user.emails.join(', ')}`}</span>;
                                    })
                                  : null}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    ) : null}
                    {index !== formData.sites.length - 1 ? <div className={styles.divider}></div> : null}
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {isSaveSuccessful && !isPreviewVisible && !isLoading && (
          <div className={styles['org-setup-success-message']}>
            <div>The new organization is saved successfully.</div>
            <div>
              <i className={`fas fa-check-circle ${styles['save-success-icon']}`}></i>
            </div>
          </div>
        )}
      </div>
      <div className={styles['org-site-bottom-buttons']}>
        {!isSaveSuccessful && (
          <>
            <Button text="CANCEL" color="secondary" onClick={() => cancel()} />
            <Button icon="fas fa-arrow-left" text="BACK" onClick={handleBack} />
            <Button type="submit" icon="far fa-save" text="SAVE" onClick={handleSubmit} />
          </>
        )}
        {isSaveSuccessful && <Button text="DONE" onClick={() => close()} />}
      </div>
      <div>
        {isSaveError &&
          saveErrorMessages.map((errorMessage, index) => (
            <div key={index} className={styles['org-setup-error-message']}>
              {errorMessage}
            </div>
          ))}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  ActsReducer: state.ActsReducer,
  RolesReducer: state.RolesReducer,
  CreateOrgReducer: state.CreateOrgReducer,
});

const mapDispatchToProps = {
  loadActsAllAction,
  loadRolesAction,
  submitNewOrgWizardAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(SubmitForm);
