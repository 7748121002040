import { call, put, takeLatest, delay, select } from 'redux-saga/effects';
import { push } from 'redux-first-history';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { actionApiCall } from 'helpers/api/ApiCalls';
import * as sagaConstants from 'helpers/constants/SagasConstants';
import { redirectToPeople } from 'helpers/utils';

const getSelected = (state) => state.UserOrgSiteReducer.selected;

/**
 * Add user from Add form submission with role user.
 */
function* submitEditUserFormSaga({ payload }) {
  try {
    const selected = yield select(getSelected);
    const apiResponse = yield call(actionApiCall, {
      actionType: ActionTypeConstants.SUBMIT_EDIT_USER_FORM,
      data: {
        id: payload.id,
        email: payload.email,
        organization: selected.org,
        role: payload.role,
        password: payload.password,
        sso: payload.sso,
        firstname: payload.firstname,
        lastname: payload.lastname,
        address_1: payload.address_1,
        address_2: payload.address_2,
        city: payload.city,
        country_name: payload.country_name,
        country_code: payload.country_code,
        state: payload.state,
        postcode: payload.postcode,
        phone: payload.phone,
        // image_data: img_data,
        // image_type: img_type,
        active: payload.active,
        registered: payload.registered === 't' ? true : false,
      },
    });
    if (apiResponse.res.data.response === 200) {
      yield put({
        type: ActionTypeConstants.SUBMIT_EDIT_USER_FORM_SUCCESS,
        payload,
      });
      yield put(push(redirectToPeople(selected.ui_version)));

      yield put({
        type: ActionTypeConstants.AUTH_GET_USER,
      });

      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: apiResponse.res.data.message || `The account ${sagaConstants.SUCCESSFULLY_UPDATED}`,
          color: 'success',
        },
      });
      yield delay(10000);
      yield put({ type: ActionTypeConstants.HIDE_ALERT });
    } else {
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: apiResponse.res.data.message || `${sagaConstants.ISSUE_OCCURED} updating the account`,
          color: 'danger',
        },
      });
      yield delay(10000);
      yield put({ type: ActionTypeConstants.HIDE_ALERT });
    }
  } catch (err) {
    yield put({
      type: ActionTypeConstants.SHOW_ALERT,
      payload: {
        message: `${sagaConstants.SERVER_ERROR}`,
        color: 'danger',
      },
    });
    yield delay(10000);
    yield put({ type: ActionTypeConstants.HIDE_ALERT });
  }
}

export default function* () {
  yield takeLatest(ActionTypeConstants.SUBMIT_EDIT_USER_FORM, submitEditUserFormSaga);
}
