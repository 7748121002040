import React, { useState, useEffect } from 'react';
import moment from 'moment';
// import axios from 'axios';

import { connect } from 'react-redux';
import classNames from 'classnames';

// import Container from "components/Container/Container";
import Modal from 'components/Modal/Modal';
import { Button } from 'components/Button/Button';
import Radio from 'components/Radio/Radio';
import Select from 'components/Select/Select';
import Checkbox from 'components/Checkbox/Checkbox';
import { DateInput } from 'components/DateInput/DateInput';
import FormElement from 'components/FormElement/FormElement';
import UploadZone from 'components/UploadZone/UploadZone';

import { toggleDropdowns, uploadFiles } from 'helpers/utils';
import { useAppSelector } from 'hooks';

import { loadFoldersAction, loadFolderContentsAction, showAlertAction, loadSitesAction, loadDeptAction, isFileUploadingAction } from 'store/actions/Actions';

// import { DOCUMENT_UPLOAD_URL } from 'helpers/ApiEndpointConstants';

import styles from './UploadDocumentsModal.module.scss';

function UploadDocumentsModal(props) {
  const { loadFoldersAction, loadSitesAction, loadFolderContentsAction, setShowModal } = props;

  const UserOrgSiteReducer = useAppSelector((state) => state.UserOrgSiteReducer);
  const OrgModulesReducer = useAppSelector((state) => state.OrgModulesReducer);
  const FoldersReducer = useAppSelector((state) => state.FoldersReducer);
  const AuthReducer = useAppSelector((state) => state.AuthReducer);
  const SitesReducer = useAppSelector((state) => state.SitesReducer);
  const DepartmentsReducer = useAppSelector((state) => state.DepartmentsReducer);

  const { folders } = FoldersReducer;
  const { departments } = DepartmentsReducer;
  const { sites } = SitesReducer;
  const { userData } = AuthReducer;

  const defaultReg = OrgModulesReducer?.data?.map((r) => r.name) || [];
  const [state, setState] = useState({
    existingFolder: true,
    parentFolderId: '',
    parentFolder: '',
    newFolder: '',
    regulatory_act: defaultReg[0] || '',
    siteId: '',
    site: '',
    departmentId: '',
    department: '',
    expiration_date: '',
    frequency: '0',
    lock_tasks: false,
    launch_date: '',
    due: '',
    permissionLevel: true,
    admins_only: false,
    requires_attest: true,
    disableSiteDepartmentSelection: false,
  });
  const [parentFolderId, setParentFolderId] = useState(state.parentFolderId);
  const [parentFolder, setParentFolder] = useState(state.parentFolder);
  const [newFolder, setNewFolder] = useState(state.newFolder);
  const [siteId, setSiteId] = useState(state.siteId);
  const [site, setSite] = useState(state.site);
  const [departmentId, setDepartmentId] = useState(state.departmentId);
  const [department, setDepartment] = useState(state.department);
  const [stateErrors, setStateErrors] = useState({
    launch_date: undefined,
    document: undefined,
    due: undefined,
    site: undefined,
    parentFolderId: '',
    regulatory_act: '',
  });
  const [document, setDocument] = useState([]);

  useEffect(() => {
    loadFoldersAction();
  }, [loadFoldersAction]);

  const parentFolderProp = props.parentFolder;

  // Put folder structure in local state
  useEffect(() => {
    if (parentFolderProp && parentFolderProp.id) {
      setParentFolderId(parentFolderProp.id);

      if (parentFolderProp.permission_department) {
        state.permissionLevel = false;
        setSiteId(parentFolderProp.permission_site);
        props.loadDeptAction({ site: parentFolderProp.permission_site });
        setDepartmentId(parentFolderProp.permission_department);
        state.disableSiteDepartmentSelection = true;
      }
    }
  }, [folders, parentFolderProp]);

  useEffect(() => {
    if (!document) return;
    if (document.length > 0) {
      setStateErrors({ ...stateErrors, document: '' });
    }
  }, [document]);

  useEffect(() => {
    if (folders) {
      const selected = folders.filter((f) => f.id === parentFolderId);
      if (selected && selected.length === 1) {
        setParentFolder(selected[0].folder_name);
      }
    }
  }, [parentFolderId, folders]);

  useEffect(() => {
    if (!state.existingFolder) {
      setParentFolderId('0');
      setParentFolder('');
    } else {
      setNewFolder('');
    }
  }, [state.existingFolder]);

  useEffect(() => {
    if (!state.permissionLevel) {
      loadSitesAction();
    }
  }, [state.permissionLevel, loadSitesAction]);

  useEffect(() => {
    if (sites) {
      const selected = sites.filter((f) => f.id === siteId);
      if (selected && selected.length === 1) {
        setSite(selected[0].name);
        setState((s) => ({
          ...s,
          site: selected[0].name,
          siteId: selected[0].id,
        }));
      }
    }
  }, [siteId, sites]);

  useEffect(() => {
    if (departments) {
      const selected = departments.filter((f) => f.id === departmentId);
      if (selected && selected.length === 1) {
        setDepartment(selected[0].name);
        setState((s) => ({
          ...s,
          department: selected[0].name,
          departmentId: selected[0].id,
        }));
      }
    }
  }, [departmentId, departments]);

  useEffect(() => {
    if (state.due) {
      setStateErrors({ ...stateErrors, due: '' });
    }
  }, [state.due]);

  const handleChangeRadio = (inputName) => (e) => {
    e.persist();
    setState((s) => ({
      ...s,
      [inputName]: e.target.value === 'true',
    }));
    setDepartment('');
    setDepartmentId('');
    setSite('');
    setSiteId('');
    setStateErrors((s) => ({ ...s, site: '' }));
  };

  const handleChange = (inputName) => (e) => {
    e.persist();
    setState((s) => ({
      ...s,
      [inputName]: e.target.value,
    }));
    // setStateErrors((s) => ({ ...s, [inputName]: '' }));
  };

  const statusFolderDropdown = React.createRef();
  const siteDropdown = React.createRef();
  const departmentDropdown = React.createRef();
  const regTypeRef = React.createRef();

  const handleAdminsOnly = (e) => {
    e.persist();
    setState((s) => ({
      ...s,
      admins_only: !state.admins_only,
    }));
  };

  const handleRequiresAttest = (e) => {
    e.persist();
    setState((s) => ({
      ...s,
      requires_attest: !state.requires_attest,
    }));
  };

  const onReady = () => loadFolderContentsAction({ parent_folder: parentFolderId });

  const uploadDocuments = async (document) => {
    if (
      !state.regulatory_act ||
      (document && document.length === 0) ||
      parentFolderId === '' ||
      (!state.permissionLevel && state.site === '') ||
      (departmentId !== '' && (!state.launch_date || !state.due))
    ) {
      // Check for validation errors
      setStateErrors({
        regulatory_act: !state.regulatory_act ? 'Regulatory Act is required' : '',
        parentFolderId: parentFolderId === '' ? 'Please select folder' : '',
        document: document && document.length === 0 ? 'Please add one ore more documents' : '',
        launch_date: !state.launch_date ? 'Launch date is required' : '',
        due: !state.due ? 'Due date is required' : '',
        site: !state.permissionLevel && state.site === '' ? 'Site is required' : '',
      });
      return;
    }

    // uploadDocumentsAction({
    //     permission_organization: parseInt(props.UserOrgSiteReducer.selected.org),
    //     parent_folder: parentFolderId,
    //     newFolder: newFolder,
    //     userid: props.userData.id,
    //     username: props.userData.name,
    //     permission_site: siteId,
    //     permission_department: departmentId,
    //     admins_only: state.admins_only,
    //     requires_attest: state.requires_attest,
    //     expiration_date: state.expiration_date,
    //     regulatory_act: state.regulatory_act,
    //     launch_date: state.launch_date,
    //     frequency: state.frequency,
    //     lock_tasks: state.lock_tasks,
    //     due: state.due,
    //     files: document.map(d => ({
    //         file: d.content,
    //         name: d.name,
    //     })),
    // });

    const formData = new FormData();

    formData.append('permission_organization', UserOrgSiteReducer?.selected?.org);

    if (parentFolderId) {
      formData.append('parent_folder', `${parentFolderId}`);
    }

    if (newFolder) {
      formData.append('newFolder', newFolder);
    }

    if (userData) {
      formData.append('userid', userData.id);
      formData.append('username', userData.name);
    }

    if (siteId) {
      formData.append('permission_site', siteId);
    }

    if (departmentId) {
      formData.append('permission_department', departmentId);
    }

    if (state.admins_only) {
      formData.append('admins_only', `${state.admins_only}`);
    }

    if (state.requires_attest) {
      formData.append('requires_attest', `${state.requires_attest}`);
    }

    if (state.expiration_date) {
      formData.append('expiration_date', state.expiration_date);
    }

    if (state.regulatory_act) {
      formData.append('regulatory_act', state.regulatory_act);
    }

    if (state.launch_date) {
      formData.append('launch_date', state.launch_date);
    }

    if (state.frequency) {
      formData.append('frequency', state.frequency);
    }

    if (state.lock_tasks) {
      formData.append('lock_tasks', `${state.lock_tasks}`);
    }

    if (state.due) {
      formData.append('due', state.due);
    }

    // for (const element of document) {
    //   formData.append('files[]', element.file);
    // }

    uploadFiles(document, formData, setDocument, onReady);
  };

  const handleRegSelect = (inputName) => (e) => {
    e.persist();
    setState((s) => ({
      ...s,
      [inputName]: e.target.attributes && e.target.attributes.value ? e.target.attributes.value.value : '',
    }));
    setStateErrors((s) => ({ ...s, [inputName]: '', type: '' }));
  };

  const handleChangeFreq = (inputName) => (e) => {
    e.persist();
    setState((s) => ({
      ...s,
      [inputName]: e.target.value,
      due: e.target.value === 0 ? state.due : moment(state.launch_date, 'YYYY-MM-DD').add(e.target.value, 'month').format('YYYY-MM-DD'),
    }));
    // setStateErrors((s) => ({ ...s, [inputName]: '' }));
  };

  const handleChangeLaunch = (inputName) => (e) => {
    e.persist();
    setState((s) => ({
      ...s,
      [inputName]: e.target.value,
      due: Number.parseInt(state.frequency, 10) === 0 ? state.due : e.target.value && moment(e.target.value, 'YYYY-MM-DD').add(state.frequency, 'month').format('YYYY-MM-DD'),
    }));
    setStateErrors((s) => ({ ...s, [inputName]: '' }));
  };

  const handleCheckbox = (inputName) => (e) => {
    e.persist();
    setState((s) => ({
      ...s,
      [inputName]: e.target.checked,
    }));
  };

  const frequencyListLabels = {
    0: 'One Time',
    1: 'Monthly',
    3: 'Quarterly',
    6: 'Biannually',
    12: 'Annually',
  };

  const isAllUploaded = !(document.every((d) => d.status === 'new') || document.some((d) => d.status === 'inprogress'));
  const areSomeUploading = document.some((d) => d.status === 'inprogress');

  return (
    <Modal setShowModal={setShowModal} className={styles.modal} disableModalClose={areSomeUploading}>
      <div className={styles['modal-header']}>
        <h2 className={styles.heading}>Upload Documents</h2>
      </div>
      <div aria-hidden="true" className={styles['modal-body']} onClick={toggleDropdowns([statusFolderDropdown, siteDropdown, departmentDropdown, regTypeRef])}>
        <div className={styles['existing-folder']}>
          <div className={styles.reg}>
            <strong>PROGRAM</strong>
          </div>

          <FormElement
            // labelText="PROGRAM"
            htmlFor="regulatory_act"
            errorMessage={stateErrors.regulatory_act}
          >
            <Select placeholder="Select regulatory act" value={state.regulatory_act} onClick={handleRegSelect('regulatory_act')} dropdownToggleRef={regTypeRef}>
              {OrgModulesReducer &&
                OrgModulesReducer.data &&
                OrgModulesReducer.data.map((d) => (
                  <li key={`regid-${d.name.toLowerCase()}`} value={d.name}>
                    {d.name}
                  </li>
                ))}
            </Select>
          </FormElement>
        </div>

        <br />

        <div className={styles['existing-folder']}>
          <div className={styles.reg}>
            <strong>FOLDER</strong>
          </div>
          <FormElement errorMessage={stateErrors.parentFolderId}>
            <Select
              disabled={!state.existingFolder}
              placeholder="Select Existing Folder"
              value={parentFolder}
              dropdownToggleRef={statusFolderDropdown}
              onClick={(e) => {
                setParentFolderId(`${e.target.value || ''}`);
                setStateErrors((s) => ({ ...s, parentFolderId: undefined }));
              }}
            >
              <div className={styles['parent-folder']}>
                {folders &&
                  folders.map((f) => (
                    <li value={f.id} key={f.id} className={classNames(styles.folder, styles[`depth-${f.level}`])}>
                      <i className={f.level === '0' ? 'fas fa-folder' : 'far fa-folder'} />
                      {f.folder_name}
                    </li>
                  ))}
              </div>
            </Select>
          </FormElement>
        </div>

        <div className={styles['existing-folder']}>
          <div className={styles.permissions}>
            <b>PERMISSION LEVEL</b>
          </div>
          <div>
            {!state.disableSiteDepartmentSelection && (
              <Radio checked={state.permissionLevel} labelText="Entire Organization (default)" onChange={handleChangeRadio('permissionLevel')} value="true" />
            )}
            <div className={styles['permission-level']}>
              <Radio
                checked={!state.permissionLevel}
                onChange={handleChangeRadio('permissionLevel')}
                value={parentFolderProp && parentFolderProp.department ? parentFolderProp.site : false}
              />
              <FormElement
                // labelText="PROGRAM"
                // htmlFor="regulatory_act"
                errorMessage={stateErrors.site}
              >
                <Select
                  disabled={Boolean(state.permissionLevel) || state.disableSiteDepartmentSelection}
                  placeholder="Select Site"
                  value={site}
                  dropdownToggleRef={siteDropdown}
                  onClick={(e) => {
                    // siteDropdown.current.click();
                    setSiteId(`${e.target.value || ''}`);
                    setDepartment('');
                    setDepartmentId('');
                    props.loadDeptAction({ site: e.target.value });
                  }}
                >
                  {sites &&
                    sites
                      .filter((site) => site.active === 't')
                      .map((f) => (
                        <li value={f.id} key={f.id}>
                          {f.name}
                        </li>
                      ))}
                </Select>
              </FormElement>
            </div>
            <div className={styles['permission-level']}>
              <div className={styles['permission-department']} />
              <Select
                disabled={!site || state.disableSiteDepartmentSelection}
                placeholder="Select Department"
                dropdownToggleRef={departmentDropdown}
                value={department}
                onClick={(e) => {
                  setDepartmentId(`${e.target.value || ''}`);
                }}
              >
                {departments &&
                  departments
                    .filter((d) => d.active === 't')
                    .map((f) => (
                      <li value={f.id} key={f.id}>
                        {f.name}
                      </li>
                    ))}
              </Select>
            </div>
            <div>
              <div className={styles['permission-level']}>
                <Checkbox onChange={handleAdminsOnly} checked={state.admins_only} labelText="Viewable by admins only" />
              </div>
              <div className={styles['permission-level']}>
                <Checkbox onChange={handleRequiresAttest} checked={state.requires_attest} labelText="Requires Attestation" />
              </div>
            </div>
          </div>
        </div>

        {departmentId !== '' && (
          <div className={styles['existing-folder']} style={{ marginTop: 20 }}>
            <div className={styles.training}>
              <b>TRAINING SETTINGS</b>
            </div>

            <div className={styles.holder}>
              <FormElement labelText="FREQUENCY" htmlFor="frequency">
                <Select
                  value={state.frequency}
                  // name="frequency"
                  onClick={handleChangeFreq('frequency')}
                  labels={frequencyListLabels}
                >
                  {Object.entries(frequencyListLabels).map((frequency) => (
                    <li key={frequency[0]} value={frequency[0]}>
                      {frequency[1]}
                    </li>
                  ))}
                </Select>
              </FormElement>

              <FormElement labelText="LAUNCH DATE" smallText="*" htmlFor="launch_date" className={styles.reminder} errorMessage={stateErrors.launch_date}>
                <DateInput onChange={handleChangeLaunch('launch_date')} value={state.launch_date} />
              </FormElement>
            </div>

            <br />

            <FormElement labelText="DUE DATE" smallText="*" htmlFor="due" errorMessage={stateErrors.due}>
              <DateInput disabled={Number.parseInt(state.frequency, 10) !== 0} onChange={handleChange('due')} value={state.due} />
            </FormElement>

            <br />

            <div className={styles.holder}>
              <Checkbox
                labelText="Lock tasks"
                disabled={Number.parseInt(state.frequency, 10) === 0}
                checked={Number.parseInt(state.frequency, 10) === 0 ? false : state.lock_tasks}
                onChange={handleCheckbox('lock_tasks')}
                className={styles.complete}
              />
            </div>
          </div>
        )}

        <div className={styles['expiration-date']}>
          <div className={styles['expiration-date-label']}>
            <b>EXPIRATION DATE</b>
          </div>

          <div>
            <DateInput
              className={styles['date-input']}
              value={state.expiration_date}
              onChange={handleChange('expiration_date')}
              // maxDate={moment().format('YYYY-MM-DD')}
            />
          </div>
        </div>

        <UploadZone
          parentFolderProp={parentFolderProp}
          files={document}
          setFiles={setDocument}
          formats={['jpg', 'jpeg', 'png', 'gif', 'mp4', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'pdf', 'csv']}
          maxFileSize={100 * 1024 * 1024}
          maxFileNameLength={235}
          multipleUploads
        />
        {stateErrors.document && <span className={styles['validation-error']}>{stateErrors.document}</span>}
      </div>
      <div className={styles['modal-footer']}>
        <Button className={styles.button} disabled={areSomeUploading} text={isAllUploaded ? 'CLOSE' : 'CANCEL'} onClick={() => props.setShowModal(false)} color="secondary" />
        {!isAllUploaded && (
          <Button
            disabled={stateErrors.document || document.length === 0 || areSomeUploading}
            className={styles.button}
            text="UPLOAD"
            // icon="fad fa-upload"
            icon={areSomeUploading ? 'fad fa-spinner-third fa-spin' : 'fad fa-upload'}
            onClick={() => uploadDocuments(document)}
          />
        )}
      </div>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  // folders: state.FoldersReducer.folders,
  parentFolder: state.FolderContentsReducer.parentFolder,
  // userData: state.AuthReducer.userData,
  // sites: state.SitesReducer.sites,
  // departments: state.DepartmentsReducer.departments,
  // UserOrgSiteReducer: state.UserOrgSiteReducer,
  // OrgModulesReducer: state.OrgModulesReducer,
});

const mapDispatchToProps = {
  loadFoldersAction,
  loadFolderContentsAction,
  showAlertAction,
  loadSitesAction,
  loadDeptAction,
  isFileUploadingAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadDocumentsModal);
