import classNames from 'classnames';
import React from 'react';

import styles from './ClearAllFields.module.scss';

interface IClearAllFields {
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
}

const defaultProps = {
  className: undefined,
  onClick: undefined,
};

function ClearAllFields({ className, onClick }: IClearAllFields) {
  const styleWrapper = classNames(styles['clear-all-fields'], className);
  const styleIcon = classNames('fal fa-times-circle', styles['times-icon']);

  return (
    <button className={styleWrapper} onClick={onClick} type="button">
      <i className={styleIcon} />
      <span className={styles['clear-all-fields-text']}>Clear All Fields</span>
    </button>
  );
}

ClearAllFields.defaultProps = defaultProps;

export default ClearAllFields;
