import React, { useState, useEffect } from 'react';

import FormElement from 'components/FormElement/FormElement';
import Radio from 'components/Radio/Radio';
import Row from 'components/Row/Row';
import Select from 'components/Select/Select';

import Accordion from 'components/Accordion/Accordion';

import EditEvidence from 'views/Achieve/Audits/Audit/components/Evidence/EditEvidence';

import styles from './DashboardViews.module.scss';

function QuestionView(props) {
  const { task, setQuestion, question } = props;

  const riskDropdownRef = React.createRef();
  const [evidence, setEvidence] = useState([]);

  useEffect(() => {
    if (!task) return;
    setEvidence(task && task.evidence);
  }, [task]);

  const handleChange = (name) => (e) => {
    const { value } = e.target;
    setQuestion((s) => ({
      ...s,
      [name]: value,
    }));
    e.persist();
  };

  return (
    <div className={styles.question}>
      <br />
      <div>
        <span>
          {task.subtask_data.act_name} / {task.subtask_data.regulation_number}
        </span>
        <h3>{task.task}</h3>
      </div>

      <FormElement htmlFor="answer">
        <Row className={styles['status-row']}>
          {/* 2 = Yes */}
          <Radio disabled={task.completed === 't'} checked={question.answer === '2'} labelText="Yes" onChange={handleChange('answer')} value="2" />
          {/* 1 = No */}
          <Radio disabled={task.completed === 't'} checked={question.answer === '1'} labelText="No" onChange={handleChange('answer')} value="1" />
          {/* 3 = Not Applicatble */}
          <Radio
            disabled={task.completed === 't'}
            checked={question.answer === '3' || question.answer === '4'}
            labelText="Not Applicable"
            onChange={handleChange('answer')}
            value="3"
          />
        </Row>
      </FormElement>

      <br />

      <FormElement className={styles.risk} htmlFor="risk" labelText="DEGREE OF RISK">
        <Select
          labels={{
            1: '1',
            2: '2',
            3: '3',
            4: '4',
            5: '5',
            6: '6',
            7: '7',
            8: '8',
            9: '9',
            10: '10',
          }}
          disabled={task.completed === 't'}
          value={question.risk}
          onClick={handleChange('risk')}
          dropdownToggleRef={riskDropdownRef}
        >
          <li value="1">1</li>
          <li value="2">2</li>
          <li value="3">3</li>
          <li value="4">4</li>
          <li value="5">5</li>
          <li value="6">6</li>
          <li value="7">7</li>
          <li value="8">8</li>
          <li value="9">9</li>
          <li value="10">10</li>
        </Select>
      </FormElement>

      <br />

      <FormElement className={styles.notes} htmlFor="notes" labelText="NOTES">
        <textarea disabled={task.completed === 't'} className={styles.textarea} rows={5} onChange={handleChange('notes')} value={question.notes} />
      </FormElement>

      <br />

      <div style={{ padding: '0', width: '100%' }}>
        <Accordion
          title={
            <span>
              ATTACH EVIDENCE
              <span
                style={{
                  color: 'grey',
                  fontSize: '1.3rem',
                  marginLeft: '10px',
                }}
              >
                (Optional)
              </span>
            </span>
          }
          extraTitle={`FILES: ${evidence?.length || 0}`}
          content={
            <EditEvidence
              responseId={task && task.id}
              files={evidence || []}
              isFinalized={task.completed === 't'}
              auditId={task && task.subtask_data && task.subtask_data.audit}
              allowDelete
              taskId={task && task.id}
              requestType="task"
            />
          }
        />
      </div>
    </div>
  );
}

export default QuestionView;
