import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import styles from './AddApplicationModal.module.scss';

import Button from 'components/Button/Button';
import Modal from 'components/Modal/Modal';
import RadioGroup from 'components/RadioGroup/RadioGroup';

import FormElement from 'components/FormElement/FormElement';
import Select from 'components/Select/Select';
import Checkbox from 'components/Checkbox/Checkbox';
import DateInput from 'components/DateInput/DateInput';

import {
  loadAuditAction,
  loadSitesAction,
  loadUsersAction,
  updateCloudAssetsV2Action,
  createDeviceAction,
  updateDeviceAction,
  createCloudAssetsV2Action,
} from 'store/actions/Actions';
import { toggleDropdowns } from 'helpers/utils';

const AddApplicationModal = (props) => {
  const [device, setDevice] = useState({
    audit_id: '',
    user_id: '',
    application: '',
    activated: moment(),
    deactivated: '',
    license_type: '',
    license_number: '',
    user_access: '',
    url: '',
    encryption: '',
    anti_virus: '',
    strong_password: '',
    mfa: '',
    backups: '',
    notes: '',
    baa_with_vendor: undefined,
    risk_rating: '5',
    remediation_notes: '',
    site_id: -1,
  });
  const [isLoaded, setLoaded] = useState(false);

  useEffect(() => {
    if (props.device && !isLoaded) {
      setDevice((s) => ({
        ...s,
        ...props.device,
        site_id: props?.device?.site_id || -1,
        other: props.device.notes ? true : false,
      }));
      setLoaded(true);
    }
  }, [props.device, isLoaded]);

  const {
    UsersReducer,
    loadUsersAction,
    SitesReducer,
    loadSitesAction,
    UserOrgSiteReducer,
    // auditId,
    // AuditReducer,
  } = props;

  // useEffect(() => {
  //   if (!UsersReducer.users && AuditReducer.audit) {
  //     loadUsersAction({ site: AuditReducer.audit.site });
  //   }
  // }, [UsersReducer.users, AuditReducer.audit, loadUsersAction]);

  useEffect(() => {
    if (!UsersReducer.users) {
      loadUsersAction();
    }
  }, [UsersReducer.users, loadUsersAction]);

  // useEffect(() => {
  //   if (!AuditReducer.audit && auditId) {
  //     loadAuditAction(auditId);
  //   }
  // }, [AuditReducer.audit, auditId, loadAuditAction]);

  useEffect(() => {
    if (!SitesReducer.sites) {
      loadSitesAction();
    }
  }, [SitesReducer.sites]);

  useEffect(() => {
    if (!props.device) {
      if (UserOrgSiteReducer.selected.site !== device.site_id) {
        setDevice({
          ...device,
          site_id: props?.device?.site_id || -1,
          // auditId,
        });
      }
    }
  }, [UserOrgSiteReducer.selected, device, props.device]);

  const [stateErrors, setStateErrors] = useState({
    application: '',
    user_access: '',
    user_id: '',
    security_controls: '',
    activated: '',
    site_id: '',
  });

  const addDevice = (device) => {
    if (
      device.application === '' ||
      device.user_access === '' ||
      device.user_id === '' ||
      !device.activated ||
      moment(device.activated).isAfter(moment()) ||
      (device.activated !== '' && moment(device.activated).isAfter(moment(device.deactivated))) ||
      device.encryption === '' ||
      device.anti_virus === '' ||
      device.strong_password === '' ||
      device.mfa === '' ||
      device.backups === '' ||
      !device.site_id
    ) {
      setStateErrors({
        ...stateErrors,
        application: device.application === '' ? 'Application is required' : '',
        user_access: device.user_access === '' ? 'User access is required' : '',
        user_id: device.user_id === '' ? 'Administrator is required' : '',
        activated: !device.activated ? 'Activation date is required' : moment(device.activated).isAfter(moment()) ? 'Activation date can be only in present or past' : '',
        deactivated: device.activated !== '' && moment(device.activated).isAfter(moment(device.deactivated)) ? 'Deactivation date can be only in present or past' : '',
        security_controls:
          device.encryption === '' || device.anti_virus === '' || device.strong_password === '' || device.mfa === '' || device.backups === ''
            ? 'Security controls are required'
            : '',
        site_id: !device.site_id ? 'Site is required' : '',
      });
      return false;
    }
    props.createCloudAssetsV2Action({
      ...device,
      site_id: device.site_id !== -1 ? device.site_id : undefined,
      notes: device.other ? device.notes : '',
      activated: moment(device.activated).format('YYYY-MM-DD'),
      deactivated: device.deactivated !== '' ? moment(device.deactivated).format('YYYY-MM-DD') : undefined,
      baa_with_vendor: device.baa_with_vendor === 't' ? true : false,
      // audit_id: auditId,
      risk_rating: `${device.risk_rating}`,
    });
    props.setShowAddDeviceModal(false);
  };

  const saveDevice = (device) => {
    if (
      device.application === '' ||
      device.user_access === '' ||
      device.user_id === '' ||
      device.encryption === '' ||
      device.anti_virus === '' ||
      device.strong_password === '' ||
      device.mfa === '' ||
      device.backups === '' ||
      !device.site_id
    ) {
      setStateErrors({
        ...stateErrors,
        application: device.application === '' ? 'Application is required' : '',
        user_access: device.user_access === '' ? 'User access is required' : '',
        user_id: device.user_id === '' ? 'Administrator is required' : '',
        security_controls:
          device.encryption === '' || device.anti_virus === '' || device.strong_password === '' || device.mfa === '' || device.backups === ''
            ? 'Security controls are required'
            : '',
        site_id: !device.site_id ? 'Site is required' : '',
      });
      return false;
    }
    props.updateCloudAssetsV2Action({
      ...device,
      site_id: device.site_id !== -1 ? device.site_id : undefined,
      notes: device.other ? device.notes : '',
      activated: moment().format('YYYY-MM-DD'),
      baa_with_vendor: device.baa_with_vendor === 't' ? true : false,
      // audit_id: auditId,
    });
    props.setShowAddDeviceModal(false);
  };

  const userAccessRef = React.createRef();
  const baaRef = React.createRef();
  const riskRef = React.createRef();
  const userRef = React.createRef();
  const associatedSiteRef = React.createRef();

  const handleChangeAlt = (inputName) => (e) => {
    e.persist();
    setDevice((s) => ({
      ...s,
      [inputName]: e.target.value,
    }));
    setStateErrors((s) => ({ ...s, [inputName]: '' }));
  };

  const handleChange = (e, name) => {
    setDevice({
      ...device,
      [name]:
        e.target.type === 'checkbox'
          ? e.target.checked === true
            ? true
            : false
          : e.target.value || (e.target && e.target.attributes && e.target.attributes.value && e.target.attributes.value.value) || '',
    });
    setStateErrors((s) => ({ ...s, [name]: '' }));
  };

  let usersList = {};
  if (props && props.users) {
    props.users
      .filter((item) => item.registered === 't')
      .forEach((user) => {
        usersList[user.id] = user.name;
      });
  }

  const sitesList = {
    '-1': 'All Sites',
  };
  SitesReducer.sites &&
    SitesReducer.sites.forEach((site) => {
      sitesList[site.id] = site.name;
    });

  const isEditModal = props.device && (props.device.id === '' || !props.device.id) ? false : true;
  const isDisabled = props.device && props.device.deactivated && props.device.deactivated !== '' ? true : false;

  return (
    <Modal className={styles['modal']} setShowModal={props.setShowAddDeviceModal}>
      <div className={styles['modal-body']} onClick={toggleDropdowns([userAccessRef, baaRef, riskRef, userRef, associatedSiteRef])}>
        <div>
          <p className={styles['modal-heading']}>{isEditModal ? 'Edit Asset' : 'Add New Asset'}</p>
        </div>

        <div className={styles['modal-content']}>
          <div>
            <FormElement labelText="APPLICATION" smallText="*" htmlFor="application" errorMessage={stateErrors.application}>
              <input disabled={isEditModal} type="text" maxLength={64} onChange={handleChangeAlt('application')} value={device.application || ''} />
            </FormElement>

            <br />

            <FormElement labelText="URL" htmlFor="url" errorMessage={stateErrors.url}>
              <input disabled={isDisabled} type="text" maxLength={128} onChange={handleChangeAlt('url')} value={device.url || ''} />
            </FormElement>

            <br />

            <FormElement labelText="VERSION OR LICENSE TYPE" htmlFor="license_type" errorMessage={stateErrors.license_type}>
              <input disabled={isEditModal} type="text" maxLength={128} onChange={handleChangeAlt('license_type')} value={device.license_type || ''} />
            </FormElement>

            <br />

            <FormElement labelText="LICENSE NUMBER" htmlFor="license_number" errorMessage={stateErrors.license_number}>
              <input disabled={isEditModal} type="text" maxLength={128} onChange={handleChangeAlt('license_number')} value={device.license_number || ''} />
            </FormElement>

            <br />

            <FormElement labelText="ACTIVATION DATE" smallText="*" htmlFor="activated" errorMessage={stateErrors.activated}>
              <DateInput
                disabled={isDisabled || isEditModal}
                onChange={handleChangeAlt('activated')}
                value={moment(device.activated).format('YYYY-MM-DD')}
                maxDate={moment().format('YYYY-MM-DD')}
              />
            </FormElement>

            <br />

            <FormElement labelText="DEACTIVATION DATE" htmlFor="deactivated" errorMessage={stateErrors.deactivated}>
              <DateInput
                disabled={isDisabled || isEditModal}
                onChange={handleChangeAlt('deactivated')}
                minDate={moment(device.activated).format('YYYY-MM-DD')}
                maxDate={moment().format('YYYY-MM-DD')}
                value={device.deactivated !== '' ? moment(device.deactivated).format('YYYY-MM-DD') : device.deactivated}
              />
            </FormElement>

            <br />

            <FormElement labelText="USER ACCESS" htmlFor="user_access" smallText="*" errorMessage={stateErrors.user_access}>
              <Select
                disabled={isDisabled}
                labels={{
                  'All users': 'All users',
                  Departmental: 'Departmental',
                  'Management only': 'Management only',
                }}
                name="user_access"
                value={device.user_access || 0}
                placeholder="Select User Access"
                onClick={(e) => handleChange(e, 'user_access')}
                dropdownToggleRef={userAccessRef}
              >
                <li value={'All users'}>All users</li>
                <li value={'Departmental'}>Departmental</li>
                <li value={'Management only'}>Management only</li>
              </Select>
            </FormElement>

            <div className={styles.fe_notes}>
              <FormElement labelText="NOTES" htmlFor="remediation_notes">
                <textarea
                  disabled={isDisabled}
                  rows="4"
                  maxLength={240}
                  className={styles.notes}
                  value={device.remediation_notes || ''}
                  onChange={(e) => handleChange(e, 'remediation_notes')}
                />
              </FormElement>
            </div>

            <br />

            <FormElement labelText="ADMINISTRATOR" htmlFor="user_id" smallText="*" errorMessage={stateErrors.user_id}>
              <Select
                disabled={isDisabled}
                labels={usersList}
                value={device.user_id || ''}
                placeholder="Select assigned user"
                onClick={(e) => handleChange(e, 'user_id')}
                dropdownToggleRef={userRef}
              >
                {props.users &&
                  props.users
                    .slice()
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .filter((item) => item.registered === 't' && item.name !== '')
                    .map((item) => {
                      return (
                        <li key={item.id} value={item.id}>
                          {item.name}
                        </li>
                      );
                    })}
              </Select>
            </FormElement>
          </div>

          <div>
            <FormElement className={styles.custom_fe} labelText="SECURITY CONTROLS" smallText="*" htmlFor="protection" errorMessage={stateErrors.security_controls}></FormElement>

            <div className={styles.flex}>
              <FormElement className={styles.custom_radio_group} labelText="Encryption" htmlFor="encryption">
                <div style={{ display: 'flex' }}>
                  <RadioGroup
                    items={[
                      {
                        label: 'Yes',
                        value: '1',
                      },
                      {
                        label: 'No',
                        value: '0',
                      },
                      {
                        label: 'N/A',
                        value: '-1',
                      },
                    ]}
                    disabled={isDisabled}
                    value={device.encryption}
                    onChange={(e) => {
                      handleChange(e, 'encryption');
                      setStateErrors((s) => ({ ...s, security_controls: '' }));
                    }}
                    name="encryption"
                  />
                </div>
              </FormElement>

              <FormElement className={styles.custom_radio_group} labelText="Antivirus/Malware protection" htmlFor="anti_virus">
                <div style={{ display: 'flex' }}>
                  <RadioGroup
                    items={[
                      {
                        label: 'Yes',
                        value: '1',
                      },
                      {
                        label: 'No',
                        value: '0',
                      },
                      {
                        label: 'N/A',
                        value: '-1',
                      },
                    ]}
                    disabled={isDisabled}
                    value={device.anti_virus}
                    onChange={(e) => {
                      handleChange(e, 'anti_virus');
                      setStateErrors((s) => ({ ...s, security_controls: '' }));
                    }}
                    name="anti_virus"
                  />
                </div>
              </FormElement>

              <FormElement className={styles.custom_radio_group} labelText="Strong password" htmlFor="strong_password">
                <div style={{ display: 'flex' }}>
                  <RadioGroup
                    items={[
                      {
                        label: 'Yes',
                        value: '1',
                      },
                      {
                        label: 'No',
                        value: '0',
                      },
                      {
                        label: 'N/A',
                        value: '-1',
                      },
                    ]}
                    disabled={isDisabled}
                    value={device.strong_password}
                    onChange={(e) => {
                      handleChange(e, 'strong_password');
                      setStateErrors((s) => ({ ...s, security_controls: '' }));
                    }}
                    name="strong_password"
                  />
                </div>
              </FormElement>

              <FormElement className={styles.custom_radio_group} labelText="Multi-factor Authentication" htmlFor="mfa">
                <div style={{ display: 'flex' }}>
                  <RadioGroup
                    items={[
                      {
                        label: 'Yes',
                        value: '1',
                      },
                      {
                        label: 'No',
                        value: '0',
                      },
                      {
                        label: 'N/A',
                        value: '-1',
                      },
                    ]}
                    disabled={isDisabled}
                    value={device.mfa}
                    onChange={(e) => {
                      handleChange(e, 'mfa');
                      setStateErrors((s) => ({ ...s, security_controls: '' }));
                    }}
                    name="mfa"
                  />
                </div>
              </FormElement>

              <FormElement className={styles.custom_radio_group} labelText="Backups" htmlFor="backups">
                <div style={{ display: 'flex' }}>
                  <RadioGroup
                    items={[
                      {
                        label: 'Yes',
                        value: '1',
                      },
                      {
                        label: 'No',
                        value: '0',
                      },
                      {
                        label: 'N/A',
                        value: '-1',
                      },
                    ]}
                    disabled={isDisabled}
                    value={device.backups}
                    onChange={(e) => {
                      handleChange(e, 'backups');
                      setStateErrors((s) => ({ ...s, security_controls: '' }));
                    }}
                    name="backups"
                  />
                </div>
              </FormElement>

              <FormElement className={styles.custom_fe_last} labelText="Other forms of protection" htmlFor="other">
                <Checkbox disabled={isDisabled} isSwitch={true} checked={device['other'] || ''} onChange={(e) => handleChange(e, 'other')} />
              </FormElement>
            </div>

            {device['other'] && (
              <div className={styles.fe_other}>
                <FormElement labelText="Describe other forms of protection" htmlFor="notes" errorMessage={stateErrors.notes}>
                  <textarea disabled={isDisabled} rows="4" className={styles.notes} value={device.notes || ''} onChange={(e) => handleChange(e, 'notes')} />
                </FormElement>
              </div>
            )}

            <br />

            <FormElement labelText="ASSOCIATED SITE" htmlFor="site" smallText="*" errorMessage={stateErrors.site_id}>
              <Select
                labels={sitesList}
                value={device.site_id}
                name="site_id"
                placeholder="Select Site"
                onClick={(e) => handleChange(e, 'site_id')}
                disabled={isDisabled}
                dropdownToggleRef={associatedSiteRef}
              >
                {UserOrgSiteReducer.selected && !UserOrgSiteReducer.selected.site && (
                  <li key={`siteid-all`} value={-1}>
                    All Sites
                  </li>
                )}
                {SitesReducer.sites &&
                  SitesReducer.sites.map((site) => (
                    <li key={`siteid-${site.id}`} data-name={site.name} value={site.id}>
                      {site.name}
                    </li>
                  ))}
              </Select>
            </FormElement>

            <br />

            <FormElement labelText="BAA WITH VENDOR" htmlFor="baa_with_vendor" errorMessage={stateErrors.baa_with_vendor}>
              <Select
                disabled={isDisabled}
                labels={{ t: 'Yes', f: 'No' }}
                name="baa_with_vendor"
                value={device.baa_with_vendor || 'f'}
                placeholder="Select Type"
                onClick={(e) => handleChange(e, 'baa_with_vendor')}
                dropdownToggleRef={baaRef}
              >
                <li value={'t'}>Yes</li>
                <li value={'f'}>No</li>
              </Select>
            </FormElement>

            <br />

            <FormElement labelText="Risk Rating (1-10, 10 highest)" htmlFor="risk_rating" errorMessage={stateErrors.risk_rating}>
              <Select
                labels={{
                  1: 1,
                  2: 2,
                  3: 3,
                  4: 4,
                  5: 5,
                  6: 6,
                  7: 7,
                  8: 8,
                  9: 9,
                  10: 10,
                }}
                name="risk_rating"
                disabled={isDisabled}
                value={device.risk_rating || '5'}
                placeholder="Select Risk Rating"
                onClick={(e) => handleChange(e, 'risk_rating')}
                dropdownToggleRef={riskRef}
              >
                <li value="1">1</li>
                <li value="2">2</li>
                <li value="3">3</li>
                <li value="4">4</li>
                <li value="5">5</li>
                <li value="6">6</li>
                <li value="7">7</li>
                <li value="8">8</li>
                <li value="9">9</li>
                <li value="10">10</li>
              </Select>
            </FormElement>
          </div>
        </div>
      </div>

      <div className={styles['modal-footer']}>
        <Button className={styles.button} text={isDisabled ? 'CLOSE' : 'CANCEL'} onClick={() => props.setShowAddDeviceModal(false)} color="secondary" />
        {isEditModal && !isDisabled && (
          <Button
            className={styles.button}
            text="SAVE ASSET"
            onClick={() => {
              saveDevice(device);
            }}
          />
        )}

        {!isEditModal && (
          <Button
            className={styles.button}
            text="ADD ASSET"
            onClick={() => {
              addDevice(device);
            }}
          />
        )}
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  UsersReducer: state.UsersReducer,
  UserOrgSiteReducer: state.UserOrgSiteReducer,
  SitesReducer: state.SitesReducer,
  AuditReducer: state.AuditReducer,
});

const mapDispatchToProps = {
  loadAuditAction,
  updateDeviceAction,
  updateCloudAssetsV2Action,
  createCloudAssetsV2Action,
  loadUsersAction,
  loadSitesAction,
  createDeviceAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddApplicationModal);
