import { call, put, takeLatest, delay } from 'redux-saga/effects';

import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { actionApiCall } from 'helpers/api/ApiCalls';
import * as sagaConstants from 'helpers/constants/SagasConstants';
import { downloadFile } from 'helpers/utils';

function* downloadAuditSaga({ payload }) {
  try {
    const apiResponse = yield call(actionApiCall, {
      actionType: ActionTypeConstants.DOWNLOAD_AUDIT,
      data: {
        audit: payload.id,
      },
    });
    if (apiResponse.res.data && payload.name) {
      // SUCCESS
      downloadFile({
        data: apiResponse.res.data,
        filename: `${payload.name}.xlsx`,
      });
    } else {
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: apiResponse.res.data.message || `${sagaConstants.ISSUE_OCCURED} downloading the audit`,
          color: 'danger',
        },
      });
      yield delay(10_000);
      yield put({ type: ActionTypeConstants.HIDE_ALERT });
    }
  } catch {
    yield put({
      type: ActionTypeConstants.SHOW_ALERT,
      payload: {
        message: `${sagaConstants.SERVER_ERROR}`,
        color: 'danger',
      },
    });
    yield delay(10_000);
    yield put({ type: ActionTypeConstants.HIDE_ALERT });
  }
}

export default function* DownloadAuditSaga() {
  yield takeLatest(ActionTypeConstants.DOWNLOAD_AUDIT, downloadAuditSaga);
}
