import React, { useEffect, useState } from 'react';
import { ONLY_OFFICE_JS } from 'helpers/ApiEndpointConstants';
import { useAppDispatch, useAppSelector } from 'hooks';
import { loadDocumentAction } from 'store/actions/Actions';

import styles from './DashboardViews.module.scss';

function DocumentView(props) {
  const { task } = props;
  const dispatch = useAppDispatch();
  const DocumentReducer = useAppSelector((state) => state.DocumentReducer);
  const { document } = DocumentReducer;

  const [isEditorLoaded, setIsEditorLoaded] = useState(false);

  useEffect(() => {
    // inject only office widget javascript
    const script = window.document.createElement('script');
    script.src = ONLY_OFFICE_JS;
    script.async = false;
    window.document.body.append(script);
    script.addEventListener('load', () => {
      setIsEditorLoaded(true);
    });
    return () => {
      script.remove();
    };
  }, []);

  useEffect(() => {
    if (task.id && task.subtask_data.document) {
      dispatch(loadDocumentAction({ id: task.subtask_data.document }));
    }
  }, [task.id, dispatch]);

  useEffect(() => {
    if (document) {
      const config = JSON.parse(JSON.stringify(document.config));
      config.height = '1000px';
      config.document.permissions.comment = false;
      config.document.permissions.download = false;
      config.document.permissions.edit = false;
      config.document.permissions.fillForms = false;
      config.document.permissions.modifyContentControl = false;
      config.document.permissions.modifyFilter = false;
      config.editorConfig.mode = 'view';

      // config['editorConfig']['user']['id'] = props.userData.id;
      // config['editorConfig']['user']['name'] = props.userData.name;

      config.editorConfig.customization.about = false;
      config.editorConfig.customization.help = false;
      config.editorConfig.customization.mentionShare = false;
      config.editorConfig.customization.plugins = false;
      config.editorConfig.customization.spellcheck = false;
      config.editorConfig.customization.chat = false;
      config.editorConfig.customization.comments = false;
      config.editorConfig.customization.compactHeader = true;
      config.editorConfig.customization.compactToolbar = true;
      config.editorConfig.customization.feedback = false;
      config.editorConfig.customization.hideNotes = true;
      config.editorConfig.customization.hideRightMenu = true;
      config.editorConfig.customization.hideRulers = true;
      config.editorConfig.customization.anonymous = {
        request: true,
        label: 'Guest',
      };

      if (isEditorLoaded && window.DocsAPI) {
        window.DocsAPI.DocEditor('editor', config);
      }
    }
  }, [document, isEditorLoaded]);

  return (
    <div className={styles.document}>
      <div className={styles.viewer}>
        <div id="editor">Loading document...</div>
      </div>
    </div>
  );
}

export default DocumentView;
