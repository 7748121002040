import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import AppLayoutWrapFull from 'components/AppLayoutWrapFull';
import InfoPanel from 'components/InfoPanel/InfoPanel';

import { loadOrganizationDetailsAction } from 'store/actions/Actions';
import { useAppDispatch, useAppSelector } from 'hooks';
import { calcPerc } from 'helpers/utils';

import ActionStatusConstants from 'helpers/ActionStatusConstants';
import Spinner from 'components/Spinner/Spinner';
import OneOrgHeader from './components/OneOrgHeader';
import Statistics from '../../components/Statistics/Statistics';
import StatisticsSnD from '../../components/StatisticsSnD/StatisticsSnD';
import PieChartBox from '../Dashboard/components/PieChartBox/PieChartBox';
import LineChartBox from '../Dashboard/components/LineChartBox/LineChartBox';

import styles from './OneOrg.module.scss';

function OneOrg() {
  const { orgId } = useParams();
  const dispatch = useAppDispatch();
  const SuperUserOneOrgReducer = useAppSelector((state) => state.SuperUserOneOrgReducer);

  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(loadOrganizationDetailsAction({ id: orgId }));
  }, [orgId, dispatch]);

  useEffect(() => {
    if (SuperUserOneOrgReducer.status === ActionStatusConstants.SUCCESS) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [SuperUserOneOrgReducer.organization]);

  return (
    <AppLayoutWrapFull>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className={styles.holder}>
          <OneOrgHeader org={SuperUserOneOrgReducer?.organization?.organization} setDropdownOptions={(dropdownOptions) => setDropdownOptions(dropdownOptions)} />
          {dropdownOptions && dropdownOptions.some((option) => option.label === 'Statistics' && option.checked) && (
            <div className={styles.stats}>
              <StatisticsSnD
                statsTitle="Sites &amp; Departments"
                totalSites={SuperUserOneOrgReducer?.organization?.statistics?.number_of_sites}
                totalDepartments={SuperUserOneOrgReducer?.organization?.statistics?.number_of_departments}
                iconClass="fal fa-folder-tree"
              />

              <Statistics
                statsTitle="Users"
                statsTotal={SuperUserOneOrgReducer?.organization?.statistics?.number_of_users}
                percentageTitle="Unregistered"
                percentage={calcPerc(SuperUserOneOrgReducer?.organization?.statistics?.number_of_users, SuperUserOneOrgReducer?.organization?.statistics?.unregistered_users)}
                graphPercentagePathColor="#FF9000"
                graphFullCircleColor="#FFD580"
                iconClass="fal fa-users"
              />

              <Statistics
                statsTitle="Vendors"
                statsTotal={SuperUserOneOrgReducer?.organization?.statistics?.total_affiliates}
                percentageTitle="Approved"
                percentage={calcPerc(SuperUserOneOrgReducer?.organization?.statistics?.total_affiliates, SuperUserOneOrgReducer?.organization?.statistics?.approved_affiliates)}
                graphPercentagePathColor="#21AAE1"
                graphFullCircleColor="#add8e6"
                iconClass="fal fa-chart-network"
              />
            </div>
          )}
          {dropdownOptions && dropdownOptions.some((option) => option.label === 'Overall Progress' && option.checked) && (
            <div className={styles.panels}>
              <PieChartBox
                key="tasks"
                title="Total progress"
                desc="Number of tasks that are assigned and completed versus not completed"
                complete={SuperUserOneOrgReducer?.organization?.progress?.tasks?.complete}
                total={SuperUserOneOrgReducer?.organization?.progress?.tasks?.total}
                color="#21AAE1"
                bg="#C7E9F7"
                link="/achieve/tasks?filter="
              />
              <LineChartBox stats={SuperUserOneOrgReducer?.organization?.progress} />
            </div>
          )}
          <div className={styles.panels}>
            {dropdownOptions &&
              dropdownOptions
                .filter((option) => option.key && option.tab1 && option.tableDefinition && option.checked)
                .map((option) => (
                  <InfoPanel
                    tab1total={SuperUserOneOrgReducer?.organization[option.key]?.total}
                    tab2total={
                      SuperUserOneOrgReducer?.organization?.configuration[option.rowDataProperty] && SuperUserOneOrgReducer?.organization?.configuration[option.rowDataProperty]?.total
                    }
                    link={option.link}
                    key={option.id}
                    rowsData={{
                      tab1: SuperUserOneOrgReducer?.organization[option.key]?.data || [],
                      tab2: option.rowDataProperty ? SuperUserOneOrgReducer?.organization?.configuration[option.rowDataProperty]?.data : [],
                    }}
                    definitions={option.tableDefinition}
                    title={option.label}
                    tab1={option.tab1}
                    tab2={option.tab2}
                  />
                ))}
          </div>
        </div>
      )}
    </AppLayoutWrapFull>
  );
}

export default OneOrg;
