import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
// import { useSearchParams } from 'react-router-dom';

import FullDataTable from 'components/DataTable/FullDataTable';
import PageHeader from 'v2/components/PageHeader/PageHeader';
import PageHeaderActions from 'v2/components/PageHeader/components/PageHeaderActions/PageHeaderActions';
import Container from 'components/Container/Container';

import TableDefDeviceInventory from 'v2/table-defs/TableDefDeviceInventory';

import { useConfirmModal } from 'hooks';
import AddDeviceModal from './components/AddDeviceInventoryModal/AddDeviceInventoryModal';
import DeviceInventoryBulkUploadModal from './components/DeviceInventoryBulkUploadModal/DeviceInventoryBulkUploadModal';

import EvidenceUploadModal from 'v2/views/Assets/components/EvidenceUploadModal/EvidenceUploadModal';

import useFilterRows from 'helpers/customHooks/useFilterRows';
import { setSelectedRows, toggleDropdowns } from 'helpers/utils';
import {
  loadSitesAction,
  loadUsersAction,
  duplicateDeviceAction,
  deleteDeviceAction,
  loadDeviceInventoryAction,
  activateDeviceInventoryAction,
  deactivateDeviceInventoryAction,
  destroyDeviceInventoryAction,
  loadAuditAction,
  loadDeviceInventoryV2Action,
  setPaginationAction,
} from 'store/actions/Actions';
import { DEVICE_INVENTORY_PAGE } from 'helpers/constants/DataTableConstants';
import Spinner from 'components/Spinner/Spinner';
import ActionStatusConstants from 'helpers/ActionStatusConstants';

const Audit = (props) => {
  const {
    SitesReducer,
    UsersReducer,
    DeviceInventoryV2Reducer,
    UserOrgSiteReducer,
    loadSitesAction,
    loadUsersAction,
    // loadDeviceInventoryAction,
    activateDeviceInventoryAction,
    deactivateDeviceInventoryAction,
    destroyDeviceInventoryAction,
    // loadAuditAction,
    // AuditReducer,
    loadDeviceInventoryV2Action,
  } = props;

  const { devices } = DeviceInventoryV2Reducer;
  // const [search] = useSearchParams();
  // const auditId = search.get('id');

  const [device, setDevice] = useState(() => {});
  const [showAddDeviceModal, setShowAddDeviceModal] = useState(() => false);
  const [showBulkUploadModal, setShowBulkUploadModal] = useState(() => false);
  const [showConfirmModal, setShowConfirmModal, renderConfirmModal] = useConfirmModal();
  const [showEvidenceModal, setShowEvidenceModal] = useState(false);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (!SitesReducer.sites) {
      loadSitesAction();
    }
  }, [SitesReducer.sites, loadSitesAction]);

  useEffect(() => {
    const payload = UserOrgSiteReducer.selected.site ? { site: UserOrgSiteReducer.selected.site } : undefined;
    if (!UsersReducer.users) {
      loadUsersAction(payload);
    }
  }, [UsersReducer.users]);

  useEffect(() => {
    if (UsersReducer.users && SitesReducer.sites) {
      loadDeviceInventoryV2Action();
    }
  }, [UsersReducer.users, SitesReducer.sites]);

  useEffect(() => {
    if (
      UsersReducer.status === ActionStatusConstants.SUCCESS &&
      DeviceInventoryV2Reducer.status === ActionStatusConstants.SUCCESS &&
      SitesReducer.status === ActionStatusConstants.SUCCESS
    ) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [UsersReducer.users, DeviceInventoryV2Reducer.devices, SitesReducer.sites]);

  const noFilters = {
    text: '',
    type: '',
    'last-action': '',
    status: '',
    role: '',
    site: '',
    start: '',
    end: '',
    selected_sites: [],
    dateField: 'assigned',
  };

  const setFilter = (filterName) => (e) => {
    setFilters({
      ...filters,
      [filterName]: e.target.type === 'checkbox' ? e.target.checked : e.target.value || e?.target?.attributes?.value?.value || '',
    });
    setPaginationAction({ dataType: DEVICE_INVENTORY_PAGE, currentPage: 0 });
  };

  const [filters, setFilters] = useState(noFilters);

  const filteredRows = useFilterRows(filters, devices);

  const handleAdd = () => {
    setDevice({});
    setShowAddDeviceModal(true);
  };

  // const actDropdownRef = React.createRef();
  // const siteDropdownRef = React.createRef();
  const chooseActionDropdownRef = React.createRef();
  const statusDropdownRef = React.createRef();

  const [selectedAuditIds, setSelectedAuditIds] = useState([]);
  const selectedIds = selectedAuditIds.map((item) => item.id);

  return (
    <div
      onClick={toggleDropdowns([
        // actDropdownRef,
        // siteDropdownRef,
        chooseActionDropdownRef,
        statusDropdownRef,
      ])}
    >
      <PageHeader title="Devices">
        <PageHeaderActions
          primaryButtonText="ADD DEVICE"
          primaryButtonHandler={handleAdd}
          secondaryButtonText="BULK UPLOAD"
          secondaryButtonHandler={() => {
            setShowBulkUploadModal(!showBulkUploadModal);
          }}
          // terianryButtonHandler={() => setShowBulkUpdateModal(!showBulkUpdateModal)}
          // terianryButtonText="BULK UPDATE"
          // terianryButtonIcon="fal fa-plus"
          searchHandler={(e) => setFilters({ ...filters, text: e.target.value || '' })}
          searchValue={filters.text}
          chooseAction={[
            {
              actionName: 'Activate Selected',
              actionHandler: () => {
                const idCount = selectedIds.length;
                if (idCount > 0) {
                  setShowConfirmModal({
                    title: 'Activate selected devices',
                    question: idCount === 1 ? `Are you sure you want to activate the selected device?` : `Are you sure you want to activate ${idCount} selected devices?`,
                    confirmAction: () => {
                      props.activateDeviceInventoryAction({
                        ids: selectedIds,
                        // audit_id: auditId,
                      });
                    },
                  });
                } else {
                  setShowConfirmModal({
                    type: 'simple',
                    title: 'Nothing selected',
                    question: `Please select device(s) to be deleted.`,
                  });
                }
              },
            },
            {
              actionName: 'Deactivate Selected',
              actionHandler: () => {
                const idCount = selectedIds.length;
                if (idCount > 0) {
                  setShowConfirmModal({
                    title: 'Deactivate selected devices',
                    question: idCount === 1 ? `Are you sure you want to deactivate the selected device?` : `Are you sure you want to deactivate ${idCount} selected devices?`,
                    confirmAction: () => {
                      props.deactivateDeviceInventoryAction({
                        ids: selectedIds,
                        // audit_id: auditId,
                      });
                    },
                  });
                } else {
                  setShowConfirmModal({
                    type: 'simple',
                    title: 'Nothing selected',
                    question: `Please select device(s) to be deleted.`,
                  });
                }
              },
            },
          ]}
          chooseActionDropdownRef={chooseActionDropdownRef}
          // site={{
          //     onClick: (e) => {
          //         if (e.target.type === 'checkbox') {
          //             let selected_sites = filters.selected_sites.slice();
          //             if (e.target.checked) {
          //                 selected_sites.push(e.target.value);
          //             } else {
          //                 selected_sites = selected_sites.filter(site => site !== e.target.value);
          //             }
          //             setFilters({
          //                 ...filters,
          //                 site: selected_sites.length > 0 ? `Sites (${selected_sites.length})` : 'Site',
          //                 // site: "Site",
          //                 selected_sites: selected_sites
          //             });
          //         }
          //     },
          //     sites: SitesReducer.sites ? SitesReducer.sites.map(site => {
          //         return {
          //             id: site.id,
          //             siteName: site.name,
          //             value: filters.selected_sites.includes(site.id) ? true : false
          //         }
          //     }) : [],
          // }}
          // siteDropdownRef={siteDropdownRef}
          status={{
              onChange: setFilter("status"),
              options: ["All", "Activated", "Deactivated", "Destroyed"]
          }}
          statusDropdownRef={statusDropdownRef}
          // dateRange={{
          //     onChangeStart: setDateFilter("start"),
          //     onChangeEnd: setDateFilter("end")
          // }}
          filters={filters}
          resetFilters={() => {
            setFilters(noFilters);
          }}
          noFilter={false}
        />
      </PageHeader>
      {devices && !isLoading ? (
        <Container wide fluid>
          {showBulkUploadModal &&
            ReactDOM.createPortal(
              <DeviceInventoryBulkUploadModal
                // auditId={auditId}
                setShowModal={setShowBulkUploadModal}
              />,
              document.querySelector('#modal-root')
            )}
          <div>
            <FullDataTable
              rowsData={filteredRows}
              definitions={TableDefDeviceInventory}
              dataType={DEVICE_INVENTORY_PAGE}
              // onChangeVisibility={() => false}
              onRowClick={function (e) {
                if (e.currentTarget.classList.contains('col-actions') || e.currentTarget.classList.contains('col-row-selector')) {
                  return;
                } else {
                  setDevice(this.props.rowData);
                  setShowAddDeviceModal(true);
                }
              }}
              setSelectedRows={setSelectedRows(['id'], setSelectedAuditIds)}
              customTableProps={{
                evidence: (rowData) => {
                  setShowEvidenceModal(rowData);
                },
                activate: (rowData) => {
                  activateDeviceInventoryAction({
                    // audit_id: auditId,
                    ids: [rowData.id],
                  });
                },
                deactivate: (rowData) => {
                  deactivateDeviceInventoryAction({
                    // audit_id: auditId,
                    ids: [rowData.id],
                  });
                },
                destroy: (rowData) => {
                  setShowConfirmModal({
                    title: 'Device Destruction',
                    question: `Are you sure you want to destroy the device? The operation can't be undone.`,
                    confirmAction: () => {
                      destroyDeviceInventoryAction({
                        // audit_id: auditId,
                        id: rowData.id,
                      });
                    },
                  });
                },
              }}
            />
            {showEvidenceModal &&
              ReactDOM.createPortal(
                <EvidenceUploadModal entity_type="inventory_device" showModal={showEvidenceModal} setShowModal={setShowEvidenceModal} />,
                document.querySelector('#modal-root')
              )}

            {showAddDeviceModal &&
              ReactDOM.createPortal(
                <AddDeviceModal
                  device={device}
                  // users={UsersReducer.users}
                  setShowAddDeviceModal={setShowAddDeviceModal}
                  // auditId={auditId}
                />,
                document.querySelector('#modal-root')
              )}
          </div>
          {showConfirmModal && renderConfirmModal(showConfirmModal, setShowConfirmModal)}
        </Container>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  SitesReducer: state.SitesReducer,
  UsersReducer: state.UsersReducer,
  DeviceInventoryV2Reducer: state.DeviceInventoryV2Reducer,
  UserOrgSiteReducer: state.UserOrgSiteReducer,
  AuditReducer: state.AuditReducer,
});

const mapDispatchToProps = {
  loadSitesAction,
  loadUsersAction,
  loadDeviceInventoryAction,
  duplicateDeviceAction,
  deleteDeviceAction,
  activateDeviceInventoryAction,
  deactivateDeviceInventoryAction,
  destroyDeviceInventoryAction,
  loadAuditAction,
  loadDeviceInventoryV2Action,
  setPaginationAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Audit);
