import 'shadow-container-query-polyfill';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { store, history } from 'store';
import Root from 'Root';

import { appendScript } from 'helpers/utils';
import { msalConfig } from './views/Maintain/OrgSiteDept/MicrosoftAzureActiveDirectory/authConfig';

import { AwsRum, AwsRumConfig } from 'aws-rum-web';

let awsRum: AwsRum;
try {
  const config: AwsRumConfig = {
    sessionSampleRate: 1,
    guestRoleArn: 'arn:aws:iam::405234022601:role/RUM-Monitor-us-east-1-405234022601-8080867685761-Unauth',
    identityPoolId: 'us-east-1:37dc66f7-df52-41be-b69c-67ad8604f134',
    endpoint: 'https://dataplane.rum.us-east-1.amazonaws.com',
    telemetries: ['performance', 'errors', 'http'],
    allowCookies: true,
    enableXRay: true,
  };

  const APPLICATION_ID: string = 'e63bbe56-bbe4-45a9-8eec-857905d19a2f';
  const APPLICATION_VERSION: string = '1.0.0';
  const APPLICATION_REGION: string = 'us-east-1';

  awsRum = new AwsRum(APPLICATION_ID, APPLICATION_VERSION, APPLICATION_REGION, config);
} catch (error) {
  // Ignore errors thrown during CloudWatch RUM web client initialization
}

export function LogEventToAwsCloudwatch(name: string, data: object): void {
  if (awsRum) {
    console.log('tracking event to aws');
    awsRum.recordEvent(name, data);
  }
}

appendScript('/pureChat.js');
const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.createRoot(document.getElementById('root')).render(
  // <React.StrictMode>
  <Provider store={store}>
    <MsalProvider instance={msalInstance}>
      <Router history={history}>
        <Root />
      </Router>
    </MsalProvider>
  </Provider>
  // </React.StrictMode>
);
