import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { connect, useSelector, useDispatch } from 'react-redux';
import Spinner from 'components/Spinner/Spinner';

import AppLayoutWrapFull from 'components/AppLayoutWrapFull';
import Container from 'components/Container/Container';
import PageHeader from 'components/PageHeader/PageHeader';
import PageHeaderActions from 'components/PageHeader/components/PageHeaderActions/PageHeaderActions';
import FullDataTable from 'components/DataTable/FullDataTable';
import TableDefTraining from 'table-defs/TableDefTraining';
import AddTrainingModal from './components/AddTrainingModal/AddTrainingModal';
import EditTrainingModal from './components/EditTrainingModal/EditTrainingModal';
import useFilterRows from 'helpers/customHooks/useFilterRows';
import TrainingBulkUploadModal from './components/TrainingBulkUploadModal/TrainingBulkUploadModal';
import styles from './Training.module.scss';
import { useConfirmModal } from 'hooks';
import { useParams } from 'react-router-dom';
import ActionStatusConstants from 'helpers/ActionStatusConstants';

import {
  loadSitesAction,
  loadTrainingCoursesAction,
  loadTrainingAction,
  deleteTrainingAction,
  completeTrainingAction,
  incompleteTrainingAction,
  updateTrainingAction,
  suspendTrainingAction,
  unsuspendTrainingAction,
  setPaginationAction,
  loadRolesAction,
} from 'store/actions/Actions';

import { setSelectedRows, toggleDropdowns, exportAsCSV } from 'helpers/utils';
import { TRAININGS_PAGE } from 'helpers/constants/DataTableConstants';

const Training = (props) => {
  const dispatch = useDispatch();
  const { trainingId } = useParams();
  const DatatableReducer = useSelector((state) => state.DatatableReducer);

  const [state, setState] = useState({
    selectedRows: null,
    selected_sites: [],
  });
  const [isLoading, setLoading] = useState(true);
  const [sortKey, setSortKey] = useState(DatatableReducer.datatable[TRAININGS_PAGE]?.sortKey);
  const [sortOrder, setSortOrder] = useState(DatatableReducer.datatable[TRAININGS_PAGE]?.sortOrder);

  const { loadSitesAction, loadTrainingCoursesAction, loadTrainingAction, setPaginationAction, SitesReducer, TrainingReducer, RolesReducer, loadRolesAction, UserOrgSiteReducer } =
    props;

  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showBulkUploadModal, setShowBulkUploadModal] = useState(false);

  const [showConfirmModal, setShowConfirmModal, renderConfirmModal] = useConfirmModal();

  useEffect(() => {
    if (trainingId) {
      setShowEditModal(trainingId);
    }
  }, [trainingId]);

  useEffect(() => {
    if (!SitesReducer.sites) {
      loadSitesAction();
    }
  }, [SitesReducer.sites, loadSitesAction]);

  useEffect(() => {
    if (!TrainingReducer.training && SitesReducer.sites && RolesReducer.roles) {
      loadTrainingAction();
    }
  }, [TrainingReducer.training, SitesReducer.sites, loadTrainingAction, RolesReducer.roles]);

  useEffect(() => {
    if (!RolesReducer.roles) {
      loadRolesAction();
    }
  }, [RolesReducer.roles, loadRolesAction]);

  useEffect(() => {
    if (!TrainingReducer.training_courses && SitesReducer.sites) {
      loadTrainingCoursesAction();
    }
  }, [TrainingReducer.training_courses, SitesReducer.sites, loadTrainingCoursesAction]);

  useEffect(() => {
    if (
      TrainingReducer.status === ActionStatusConstants.SUCCESS &&
      SitesReducer.status === ActionStatusConstants.SUCCESS &&
      RolesReducer.status === ActionStatusConstants.SUCCESS &&
      TrainingReducer.status === ActionStatusConstants.SUCCESS
    ) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [TrainingReducer.training, SitesReducer.sites, RolesReducer.roles, TrainingReducer.training_courses]);

  let roleList = {};
  if (RolesReducer && RolesReducer.roles) {
    RolesReducer.roles.map((role) => {
      if (role.name && !role.name.includes('Super') && !role.name.includes('Coach')) {
        roleList[role.id] = { id: role.id, name: role.name };
      }
      return false;
    });
  }

  const [activeReg, setActiveReg] = useState('');

  useEffect(() => {
    if (DatatableReducer.datatable && DatatableReducer.datatable.dashboard && DatatableReducer.datatable.dashboard.activeReg) {
      setActiveReg(DatatableReducer.datatable.dashboard.activeReg);
    }
  }, [DatatableReducer.datatable]);

  useEffect(() => {
    if (activeReg) {
      setFilters({
        ...DatatableReducer.datatable.trainings.filters,
        regulatory_act: activeReg,
      });
    }
  }, [activeReg]);

  const noFilters = {
    text: '',
    type: '',
    'last-action': '',
    status: '',
    role: '',
    site: '',
    start: '',
    end: '',
    regulatory_act: activeReg,
    selected_sites: [],
    dateField: 'assigned',
  };

  const [filters, setFilters] = useState(
    DatatableReducer.datatable && DatatableReducer.datatable.trainings && DatatableReducer.datatable.trainings.filters ? DatatableReducer.datatable.trainings.filters : noFilters
  );

  const [selectedTrainingIds, setSelectedTrainingIds] = useState(
    DatatableReducer.datatable && DatatableReducer.datatable.trainings && DatatableReducer.datatable.trainings.selectedIds ? DatatableReducer.datatable.trainings.selectedIds : []
  );

  useEffect(() => {
    dispatch({
      type: 'SET_DATATABLE',
      payload: {
        datatable: {
          trainings: {
            selectedIds: selectedTrainingIds,
            filters,
            selectedRows: state.selectedRows,
            selected_sites: state.selected_sites,
            rowsPerPage:
              DatatableReducer.datatable && DatatableReducer.datatable.trainings && DatatableReducer.datatable.trainings.rowsPerPage
                ? DatatableReducer.datatable.trainings.rowsPerPage
                : 10,
            currentPage:
              DatatableReducer.datatable && DatatableReducer.datatable.trainings && DatatableReducer.datatable.trainings.currentPage
                ? DatatableReducer.datatable.trainings.currentPage
                : 0,
            sortKey,
            sortOrder,
          },
        },
      },
    });
  }, [selectedTrainingIds, filters, state, sortKey, sortOrder]);

  const setFilter = (filterName) => (e) => {
    setFilters({
      ...filters,
      [filterName]:
        e.target.type === 'checkbox' ? e.target.checked : e.target.value || (e.target && e.target.attributes && e.target.attributes.value && e.target.attributes.value.value) || '',
    });
    setPaginationAction({ dataType: TRAININGS_PAGE, currentPage: 0 });
  };

  const setDateFilter = (filterName) => (value) => {
    setFilters({
      ...filters,
      [filterName]: value,
    });
    setPaginationAction({ dataType: TRAININGS_PAGE, currentPage: 0 });
  };

  const onChangeRowSelect = ({ selectedRows }) => {
    if (selectedRows !== state.selectedRows) {
      setState((s) => ({ ...s, selectedRows }));
    }
  };

  const filteredRows = useFilterRows(filters, TrainingReducer.training);

  const actDropdownRef = React.createRef();
  // const siteDropdownRef = React.createRef();
  const chooseActionDropdownRef = React.createRef();
  const statusDropdownRef = React.createRef();
  const regDropdownRef = React.createRef();

  // no BE for this feature
  // const handleNotify = (selectedTrainingIds) => {
  //     // props.notifyTrainingAction({"items" : selectedTrainingIds.map(item => item.id) });
  // }

  const handleSuspend = (selectedTrainingIds) => {
    props.suspendTrainingAction({
      items: selectedTrainingIds.map((item) => item.id),
    });
  };

  const handleUnsuspend = (selectedTrainingIds) => {
    props.unsuspendTrainingAction({
      items: selectedTrainingIds.map((item) => item.id),
    });
  };

  const handleComplete = (selectedTrainingIds) => {
    props.completeTrainingAction({
      items: selectedTrainingIds.map((item) => item.id),
    });
  };

  const handleIncomplete = (selectedTrainingIds) => {
    props.incompleteTrainingAction({
      items: selectedTrainingIds.map((item) => item.id),
    });
  };

  const handleDelete = (selectedTrainingIds) => {
    props.deleteTrainingAction({
      items: selectedTrainingIds.map((item) => item.id),
    });
  };

  const getSitesList = () => {
    try {
      const sitesList = SitesReducer.sites
        ? SitesReducer.sites.map((site) => {
            return {
              id: site.id,
              siteName: site.name,
              value: filters.selected_sites.includes(site.id) ? true : false,
            };
          })
        : [];
      sitesList.unshift({
        id: '',
        siteName: 'All Sites',
        value: filters.selected_sites.includes(''),
      });
      return sitesList;
    } catch (error) {
      console.log('error with site list', error);
    }
    return [];
  };

  const labels = () => ({
    folder_name: {
      label: 'NAME',
    },
    regulatory_act: {
      label: 'REGULATORY',
    },
    role_str: {
      label: 'ROLE',
    },
    assigned: {
      label: 'ASSIGNED',
    },
    launch_date: {
      label: 'LAUNCH DATE',
    },
    due: {
      label: 'DUE DATE',
    },
    siteName: {
      label: 'SITE',
    },
    frequency_label: {
      label: 'FREQUENCY',
    },
    status: {
      label: 'STATUS',
    },
  });

  const is_super = (UserOrgSiteReducer.selected && UserOrgSiteReducer.selected.is_super) || false;
  const is_site_admin = (UserOrgSiteReducer.selected && UserOrgSiteReducer.selected.is_site_admin) || false;

  return (
    <AppLayoutWrapFull
      onClick={toggleDropdowns([
        chooseActionDropdownRef,
        // siteDropdownRef,
        actDropdownRef,
        statusDropdownRef,
        regDropdownRef,
      ])}
    >
      <PageHeader showStats={true}>
        <PageHeaderActions
          className={styles['page-header-actions-training']}
          classNameSearch={styles['search']}
          classNameRight={styles['page-header-right']}
          primaryButtonText="ADD TRAINING"
          primaryButtonHandler={() => setShowModal(!showModal)}
          // secondaryButtonText="UPLOAD TRAINING"
          // secondaryButtonHandler={() =>
          //     setShowBulkUploadModal(!showBulkUploadModal)
          // }
          searchHandler={(e) => {
            setFilters({ ...filters, text: e.target.value || '' });
            setPaginationAction({ dataType: TRAININGS_PAGE, currentPage: 0 });
          }}
          searchValue={filters.text}
          chooseActionDropdownRef={chooseActionDropdownRef}
          chooseAction={[
            // {
            //     actionName: "Notify Selected",
            //     actionHandler: () => {
            //         const idCount = selectedTrainingIds.length;
            //         if (idCount > 0) {
            //             setShowConfirmModal({
            //                 title: 'Notify Users',
            //                 question: idCount === 1 ?
            //                     `Are you sure you want to notify the assigned user about the selected training?` :
            //                     `Are you sure you want to notify users about ${idCount} selected trainings?`,
            //                 confirmAction: () => {
            //                     handleNotify(selectedTrainingIds);
            //                 },
            //             });
            //         } else {
            //             setShowConfirmModal({
            //                 type: 'simple',
            //                 title: 'Nothing selected',
            //                 question: `Please select trainings.`,
            //             });
            //         }
            //     }
            // },
            {
              actionName: 'Mark Selected Complete',
              actionHandler: () => handleComplete(selectedTrainingIds),
            },
            {
              actionName: 'Mark Selected Incomplete',
              actionHandler: () => handleIncomplete(selectedTrainingIds),
            },
            {
              actionName: 'Export Selected',
              actionHandler: () => {
                const data = TrainingReducer.training.filter((item) => selectedTrainingIds.map((i) => i.id).includes(item.id));
                const updatedData = data.map((item) => {
                  return {
                    ...item,
                    frequency_label: item.configuration.frequency_label,
                  };
                });
                exportAsCSV(updatedData, labels, 'trainings.csv');
              },
            },
            {
              actionName: 'Delete Selected',
              actionHandler: () => {
                const idCount = selectedTrainingIds.length;

                if (idCount > 0) {
                  setShowConfirmModal({
                    title: 'Delete selected trainings',
                    question: idCount === 1 ? `Are you sure you want to delete the selected training?` : `Are you sure you want to delete ${idCount} selected trainings?`,
                    confirmAction: () => {
                      handleDelete(selectedTrainingIds);
                    },
                  });
                } else {
                  setShowConfirmModal({
                    type: 'simple',
                    title: 'Nothing selected',
                    question: `Please select trainings to be deleted.`,
                  });
                }
              },
            },
          ]}
          site={{
            onClick: (e) => {
              if (e.target.type === 'checkbox') {
                let selected_sites = filters.selected_sites.slice();
                if (e.target.checked) {
                  selected_sites.push(e.target.value);
                } else {
                  selected_sites = selected_sites.filter((site) => site !== e.target.value);
                }
                setFilters({
                  ...filters,
                  site: selected_sites.length > 0 ? `Sites (${selected_sites.length})` : 'Site',
                  // site: "Site",
                  selected_sites: selected_sites,
                });
                setPaginationAction({
                  dataType: TRAININGS_PAGE,
                  currentPage: 0,
                });
              }
            },
            sites: getSitesList(),
          }}
          // siteDropdownRef={siteDropdownRef}
          status={{
            onChange: setFilter('status'),
            options: ['All', 'New', 'Ongoing', 'Error on launch', 'Error on reset', 'Completed', 'Incomplete', 'Closed incomplete', 'Deactivated incomplete'],
          }}
          statusDropdownRef={statusDropdownRef}
          dateRange={{
            onChangeStart: setDateFilter('start'),
            onChangeEnd: setDateFilter('end'),
          }}
          reg={{
            onChange: setFilter('regulatory_act'),
            options: ['All regulatory', 'HIPAA', 'OSHA'],
          }}
          regDropdownRef={regDropdownRef}
          filters={filters}
          resetFilters={() => {
            setFilters(noFilters);
            setPaginationAction({ dataType: TRAININGS_PAGE, currentPage: 0 });
          }}
        />
      </PageHeader>
      {isLoading ? (
        <Spinner />
      ) : (
        <Container wide fluid>
          {showBulkUploadModal && ReactDOM.createPortal(
            <TrainingBulkUploadModal
              setShowModal={setShowBulkUploadModal}
            />, document.querySelector('#modal-root'))}
          {TrainingReducer.training && (
            <FullDataTable
              onRowClick={function (e) {
                if (e.currentTarget.classList.contains('col-actions') || e.currentTarget.classList.contains('col-row-selector')) {
                  return;
                }
                setShowEditModal(this.props.rowData.id);
                // navigate(`/achieve/training/edit?id=${this.props.rowData.id}`);
              }}
              rowsData={filteredRows}
              definitions={TableDefTraining}
              dataType={TRAININGS_PAGE}
              setSortKey={setSortKey}
              setSortOrder={setSortOrder}
              onChangeRowSelect={({ selectedRows }) => onChangeRowSelect({ selectedRows })}
              customTableProps={{
                selectedRows: state.selectedRows ? state.selectedRows : selectedTrainingIds,
                // handleNotify: item => {
                //     setShowConfirmModal({
                //         title: 'Notify User',
                //         question: 'Are you sure you want to notify the user about the training?',
                //         confirmAction: () => {
                //             handleNotify([{ id: item.id }]);
                //         },
                //     });
                // },
                handleSuspend:
                  !is_site_admin &&
                  ((item) => {
                    setShowConfirmModal({
                      title: 'Suspend training',
                      question: `Are you sure you want to suspend the training "${item.folder_name}"`,
                      confirmAction: () => {
                        handleSuspend([{ id: item.id }]);
                      },
                    });
                  }),
                handleUnsuspend:
                  is_super &&
                  ((item) => {
                    setShowConfirmModal({
                      title: 'Unsuspend training',
                      question: `Are you sure you want to unsuspend the training "${item.folder_name}"`,
                      confirmAction: () => {
                        handleUnsuspend([{ id: item.id }]);
                      },
                    });
                  }),
                handleComplete: (item) => handleComplete([{ id: item.id }]),
                handleIncomplete: (item) => handleIncomplete([{ id: item.id }]),
                handleDelete: ({ id, folder_name }) => {
                  setShowConfirmModal({
                    title: 'Delete training',
                    question: `Are you sure you want to remove the training "${folder_name}"`,
                    confirmAction: () => {
                      handleDelete([{ id }]);
                    },
                  });
                },
              }}
              setSelectedRows={setSelectedRows(['id'], setSelectedTrainingIds)}
            />
          )}
          {showConfirmModal && renderConfirmModal(showConfirmModal, setShowConfirmModal)}
        </Container>
      )}

      {showModal ? ReactDOM.createPortal(
        <AddTrainingModal
          training_courses={TrainingReducer.training_courses}
          setShowModal={setShowModal}
          // actDropdownRef={actDropdownRef}
          // siteDropdownRef={siteDropdownRef}
        />, document.querySelector('#modal-root')) : null}

      {showEditModal ? ReactDOM.createPortal(
        <EditTrainingModal
          training_courses={TrainingReducer.training_courses}
          setShowModal={setShowEditModal}
          training_id={showEditModal}
          // actDropdownRef={actDropdownRef}
          // siteDropdownRef={siteDropdownRef}
        />, document.querySelector('#modal-root')) : null}
    </AppLayoutWrapFull>
  );
};

const mapStateToProps = (state) => ({
  TrainingReducer: state.TrainingReducer,
  SitesReducer: state.SitesReducer,
  RolesReducer: state.RolesReducer,
  UserOrgSiteReducer: state.UserOrgSiteReducer,
});

const mapDispatchToProps = {
  loadSitesAction,
  loadTrainingCoursesAction,
  loadTrainingAction,
  deleteTrainingAction,
  completeTrainingAction,
  incompleteTrainingAction,
  suspendTrainingAction,
  unsuspendTrainingAction,
  updateTrainingAction,
  setPaginationAction,
  loadRolesAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Training);
