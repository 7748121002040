import React from 'react';
import MainLayout from 'v2/layouts/MainLayout/MainLayout';

import DocumentsView from 'v2/views/Documents/Documents/Documents';

function Documents() {
  return (
    <MainLayout>
      <DocumentsView />
    </MainLayout>
  );
}

export default Documents;
