// import ColVisibilitySelector from "components/DataTable/ColVisibilitySelector";
import ItemActionsMenu from 'components/DataTable/ItemActionsMenu';
import React from 'react';
import Checkbox from 'components/Checkbox/Checkbox';
// import moment from 'moment';
import ColVisibilitySelector from 'components/DataTable/ColVisibilitySelector';

function getDeviceType(typeId) {
  switch (typeId) {
    case '0':
      return 'Desktop Computer';
    case '1':
      return 'Laptop';
    case '2':
      return 'Server';
    case '3':
      return 'Network Device';
    case '4':
      return 'Cell Phone';
    case '5':
      return 'Tablet';
    case '6':
      return 'VoIP Phone';
    case '7':
      return 'Removable Media';
    case '8':
      return 'Scanner';
    case '9':
      return 'MDF Scanner/Printer';
    case '10':
      return 'Virtual Machine';
    case '11':
      return 'Other';
    default:
      return '';
  }
}

const getProtectionChoice = (choice) => {
  switch (choice) {
    case '0':
      return 'No';
    case '1':
      return 'Yes';
    case '-1':
      return 'N/A';
  }
};

const getDeviceStatus = (rowData) => {
  if (rowData['status'] === 'Destroyed') {
    return <span style={{ display: 'flex', alignItems: 'center' }}><i style={{ fontSize: 8, color: 'grey', marginRight: 10 }} className="fa-solid fa-circle" />Destroyed</span>
  } else if (rowData['status'] === 'Deactivated') {
    return <span style={{ display: 'flex', alignItems: 'center' }}><i style={{ fontSize: 8, color: 'red', marginRight: 10 }} className="fa-solid fa-circle" />Deactivated</span>
  } else if (rowData['status'] === 'Activated') {
    return <span style={{ display: 'flex', alignItems: 'center' }}><i style={{ fontSize: 8, color: 'green', marginRight: 10 }} className="fa-solid fa-circle" />Activated</span>
  }
};

export default {
  TABLE: {
    className: 'data-table-devices',
    initialSortKey: 'type',
    initialSortOrder: 'desc',
  },

  COLUMN: [
    {
      label: 'Row Selector',
      key: 'row-selector',
      header: ({ customTableProps }) => {
        const isSelectedAll =
          customTableProps.selectedRows &&
          customTableProps.selectedRows.length &&
          customTableProps.rowsData &&
          customTableProps.rowsData.length &&
          customTableProps.selectedRows.length === customTableProps.rowsData.length
            ? true
            : false;
        return <Checkbox checked={isSelectedAll} onChange={() => customTableProps.doSelectAll()} name="row-selector" />;
      },
      className: 'col-row-selector',
      format: ({ rowData, customTableProps }) => {
        const isSelected = customTableProps.selectedRows && customTableProps.selectedRows.length && customTableProps.selectedRows.includes(rowData) ? true : false;
        return <Checkbox checked={isSelected} onChange={() => customTableProps.onRowSelect({ rowData })} name="row-selector" />;
      },
      canBeHidden: false,
    },
    {
      label: 'Device Name',
      header: 'DEVICE NAME',
      key: 'name',
      sortable: true,
      format: ({ rowData }) => {
        return <span><strong>{rowData['name']}</strong><br /><small>{rowData['model']}</small></span>;
      },
    },
    {
      label: 'Type',
      header: 'TYPE',
      key: 'type',
      sortable: true,
      className: 'type',
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{getDeviceType(rowData['type'])}</span>;
      },
    },
    {
      label: 'User',
      header: 'USER',
      key: 'user_assigned',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{rowData['user_assigned']}</span>;
      },
    },
    {
      label: 'Site',
      header: 'SITE',
      key: 'siteName',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{rowData.siteName || 'All Sites'}</span>;
      },
    },
    {
      label: 'Location',
      header: 'LOCATION',
      key: 'physical_location',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{rowData['physical_location']}</span>;
      },
    },
    {
      label: 'IP Address',
      header: 'IP ADDRESS',
      key: 'last_ip_address',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{rowData['last_ip_address']}</span>;
      },
    },
    {
      label: 'Status',
      header: 'STATUS',
      key: 'status',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{getDeviceStatus(rowData)}</span>;
      },
    },
    {
      label: 'Risk Rating',
      header: 'RISK RATING',
      key: 'risk_rating',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{rowData['risk_rating']}</span>;
      },
    },
    {
      label: 'ePHI',
      header: 'ePHI',
      key: 'stores_touches_ephi',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{getProtectionChoice(rowData['stores_touches_ephi'])}</span>;
      },
    },
    {
      label: 'Actions',
      key: 'actions',
      format: ({ rowData, customTableProps }) => {
        const actionMenuDropDown = React.createRef();
        const isDeactivated = rowData.deactivated !== '';
        let actionsList =
          rowData.device_destroyed === 'f'
            ? [
                // device not destroyed
                isDeactivated && {
                  label: 'Activate',
                  action: () => {
                    if (actionMenuDropDown && actionMenuDropDown.current) {
                      actionMenuDropDown.current.handleMenuClose();
                    }
                    customTableProps.activate(rowData);
                  },
                },
                !isDeactivated && {
                  label: 'Deactivate',
                  action: () => {
                    if (actionMenuDropDown && actionMenuDropDown.current) {
                      actionMenuDropDown.current.handleMenuClose();
                    }
                    customTableProps.deactivate(rowData);
                  },
                },
                {
                  label: 'Destroy',
                  action: () => {
                    if (actionMenuDropDown && actionMenuDropDown.current) {
                      actionMenuDropDown.current.handleMenuClose();
                    }
                    customTableProps.destroy(rowData);
                  },
                },
                customTableProps.evidence && {
                  label: 'Evidence Locker',
                  action: () => {
                    if (actionMenuDropDown && actionMenuDropDown.current) {
                      actionMenuDropDown.current.handleMenuClose();
                    }
                    customTableProps.evidence(rowData);
                  },
                },
              ]
            : [
                // device destroyed
                customTableProps.evidence && {
                  label: 'Evidence Locker',
                  action: () => {
                    if (actionMenuDropDown && actionMenuDropDown.current) {
                      actionMenuDropDown.current.handleMenuClose();
                    }
                    customTableProps.evidence(rowData);
                  },
                },
              ];
        return (
          <ItemActionsMenu
            ref={actionMenuDropDown}
            actions={(() => {
              return actionsList;
            })()}
          />
        );
      },
      className: 'col-actions',
      header: ({ customTableProps }) => {
        return (
          <ColVisibilitySelector
            colDefs={customTableProps.colDefs}
            visibleColumnKeys={customTableProps.visibleColumnKeys}
            onChangeVisibility={(colKey) => customTableProps.onChangeVisibility(colKey)}
          />
        );
      },
    },
  ],
};
