import React, { useState } from 'react';
import { connect } from 'react-redux';

import styles from './AddNote.module.scss';

import HR from 'components/HR/HR';
import FormElement from 'components/FormElement/FormElement';
import Button from 'components/Button/Button';
import TableDefTaskNotes from 'table-defs/TableDefTaskNotes';
import FullDataTable from 'components/DataTable/FullDataTable';
import { randomId } from 'helpers/utils';

const AddNote = (props) => {
  const { notes, setTasknotes, userData } = props;
  const defaultState = {
    notes: '',
    name: '',
    created_at: '',
  };
  const [state, setState] = useState(defaultState);
  const [stateErrors, setStateErrors] = useState({
    notes: '',
  });

  const handleChange = (inputName) => (e) => {
    e.persist();
    setState((s) => ({
      ...s,
      [inputName]: e.target.value,
    }));
    setStateErrors((s) => ({ ...s, [inputName]: '' }));
  };

  const removeNote = (data) => {
    setTasknotes(notes.filter((n) => n.key !== data.key));
  };

  const addNote = () => {
    if (!state.notes) {
      setStateErrors((s) => ({
        ...s,
        notes: 'Note is required',
      }));
    } else {
      setTasknotes((s) => [
        ...s,
        {
          key: `rk-${randomId()}`,
          notes: state.notes,
          name: userData.name,
          created_at: new Date(),
        },
      ]);
      setState(defaultState);
    }
  };

  return (
    <div className={`${styles['add-note']} ${props.className || ''}`}>
      <p className={styles['add-note-heading']}>Add New Note</p>
      <HR />
      <div className={styles['add-note-data']}>
        <div className={styles.note_holder}>
          <FormElement labelText="NOTE" htmlFor="description" errorMessage={stateErrors.notes}>
            <textarea rows="2" value={state.notes} placeholder="Enter notes" onChange={handleChange('notes')} />
          </FormElement>

          <Button className={`${styles['add-note-button']}`} text="ADD NOTE" icon="far fa-plus" onClick={addNote} />
        </div>
        <FullDataTable
          className={styles['add-note-table']}
          rowsData={notes}
          definitions={TableDefTaskNotes}
          customTableProps={{
            removeNote,
          }}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AddNote);
