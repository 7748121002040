import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import FormElement from 'components/FormElement/FormElement';
import InputAdornment from 'components/InputAdornment/InputAdornment';
import { Button } from 'components/Button/Button';
import logo from 'assets/img/cg-logo-large.png';
import { submitUserResetPasswordAction } from 'store/actions/Actions';
import PassowordValidation from 'components/PasswordValidation/PasswordValidation';
import { showPassword, isPasswordValid } from 'helpers/utils';

import { useAppDispatch } from 'hooks';

import WelcomePanel from '../components/WelcomePanel/WelcomePanel';

import styles from './ResetPassword.module.scss';

const togglePasswordVisibility = (e, id, isPwdVisible, toggleSetter) => {
  e.preventDefault();
  toggleSetter(isPwdVisible);
  showPassword(id, isPwdVisible);
};

function ResetPassword() {
  const { hash } = useParams();
  const dispatch = useAppDispatch();
  const [stateErrors, setStateErrors] = useState({
    'main-pass': '',
    'confirm-pass': '',
  });
  const [passwords, setPasswords] = useState({
    main: '',
    confirm: '',
  });
  const [togglePasswordIcon, setTogglePasswordIcon] = useState(false);
  const [togglePasswordIconConfirm, setTogglePasswordIconConfirm] = useState(false);

  const handleForgot = () => {
    const pass = document.getElementById('pass-reset') as HTMLFormElement;
    const confirm = document.getElementById('pass-reset-confirm') as HTMLFormElement;
    const passwordMain = pass.value;
    const passwordConfirm = confirm.value;

    if (!passwordMain || !isPasswordValid(passwordMain)) {
      setStateErrors((s) => ({
        ...s,
        'main-pass': 'The password is not valid!',
      }));
    } else if (passwordMain !== passwordConfirm) {
      setStateErrors((s) => ({
        ...s,
        'confirm-pass': "Passwords don't match",
      }));
    } else {
      dispatch(submitUserResetPasswordAction({ password: passwordMain, hash }));
    }
  };

  const handlePasswordChange = (event) => {
    setPasswords((s) => ({
      ...s,
      main: event.target.value,
    }));
  };

  return (
    <WelcomePanel>
      <div className={styles['login-panel-heading']}>
        <img className={styles.logo} src={logo} alt="" />
      </div>
      <div className={styles['login-panel-content']}>
        <p className={styles.welcome}>Enter your new password!</p>
        <FormElement errorMessage={stateErrors['main-pass']}>
          <InputAdornment iconStart="fal fa-lock-alt">
            <input onChange={handlePasswordChange} type="password" autoComplete="off" placeholder="Enter new password" name="pass" className={styles.password} id="pass-reset" />
            <span className={styles['show-password']}>
              <button type="button" className={styles['password-button']} onClick={(e) => togglePasswordVisibility(e, 'pass-reset', !togglePasswordIcon, setTogglePasswordIcon)}>
                <i className={togglePasswordIcon ? 'hide' : 'fas fa-eye'} title="show password" />
                <i className={!togglePasswordIcon ? 'hide' : 'fas fa-eye-slash'} title="hide password" />
              </button>
            </span>
          </InputAdornment>
        </FormElement>

        {!isPasswordValid(passwords.main) && (
          <div
            style={{
              textAlign: 'left',
              marginTop: '10px',
              marginBottom: '-30px',
            }}
          >
            <PassowordValidation password={passwords.main} />
          </div>
        )}

        <FormElement errorMessage={stateErrors['confirm-pass']}>
          <InputAdornment iconStart="fal fa-lock-alt">
            <input
              onChange={() => setStateErrors((s) => ({ ...s, 'confirm-pass': '' }))}
              type="password"
              autoComplete="off"
              placeholder="Confirm new password"
              name="pass"
              className={styles.password}
              id="pass-reset-confirm"
            />
            <span className={styles['show-password']}>
              <button
                type="button"
                className={styles['password-button']}
                onClick={(e) => togglePasswordVisibility(e, 'pass-reset-confirm', !togglePasswordIconConfirm, setTogglePasswordIconConfirm)}
              >
                <i className={togglePasswordIconConfirm ? 'hide' : 'fas fa-eye'} title="show password" />
                <i className={!togglePasswordIconConfirm ? 'hide' : 'fas fa-eye-slash'} title="hide password" />
              </button>
            </span>
          </InputAdornment>
        </FormElement>
      </div>

      <div className={styles['login-panel-footer']}>
        <Button text="CHANGE PASSWORD" onClick={handleForgot} />
      </div>
    </WelcomePanel>
  );
}

export default ResetPassword;
