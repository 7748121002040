import ActionStatusConstants from 'helpers/ActionStatusConstants';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { LOCATION_CHANGE } from 'redux-first-history';

export interface Task {
  id: string;
  organization: string;
  site: string;
  task: string;
  notes: string;
  user_id: string;
  assigned: string;
  // from transform
  type: string;
  typeLabel: string;
  buttonLabel: string;
  statusType: string;
  statusLabel: string;
  status: string;
  subtype: string;
  subtypeLabel: string;
  first: string;
  //
  completed: string;
  completed_on: string;
  active: string;
  locked: string;
  requires_approval: string;
  approved: string;
  due: string;
  regulatory_act: string;
  risk: string;
  subtask: string;
  deactivated: string;
  remediation_type: any[];
  subtask_data: SubtaskData;
}

export interface SubtaskData {
  id: string;
  task: string;
  remediation: string;
  audit: string;
  audit_response: string;
  active: boolean;
  version: number;
  last_modified: null;
}

export interface IDashboardTaskState {
  status: string;
  message?: string;
  tasks?: Task[];
  activeTab?: string;
}

const initialState: IDashboardTaskState = {
  status: ActionStatusConstants.INITIAL,
  message: undefined,
};

export default (state = initialState, { type, payload }): IDashboardTaskState => {
  switch (type) {
    case ActionTypeConstants.LOAD_DASHBOARD_TASKS: {
      return {
        ...state,
        status: ActionStatusConstants.ISBUSY,
      };
    }
    case ActionTypeConstants.LOAD_DASHBOARD_TASKS_SUCCESS: {
      return {
        ...state,
        tasks: payload.tasks,
        status: ActionStatusConstants.SUCCESS,
        message: payload?.message,
      };
    }
    case ActionTypeConstants.LOAD_DASHBOARD_TASKS_FAILURE: {
      return {
        ...state,
        status: ActionStatusConstants.FAILURE,
        message: payload?.message,
      };
    }

    case ActionTypeConstants.SET_DASHBOARD_TAB: {
      return {
        ...state,
        activeTab: payload.activeTab,
      };
    }
    case LOCATION_CHANGE: {
      return {
        ...initialState,
        activeTab: state.activeTab,
      };
    }
    default:
  }
  return state;
};
