import React from 'react';
import styles from './Statistics.module.scss';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';

const Statistics = ({ statsTitle, statsTotal, percentageTitle, percentage, graphPercentagePathColor, graphFullCircleColor, iconClass }) => {
  return (
    <div className={styles.statistics}>
      <div className={styles.icon}>
        <div className={styles['icon__badge']}>
          <i className={iconClass}></i>
        </div>
      </div>
      <div className={styles.page}>
        <div className={styles['page__title']}>{statsTitle}</div>
        <div>
          <div className={styles['page__stats']}>{statsTotal}</div>
          <div className={styles['page__stats-title']}>
            <small>Total</small>
            <small>{statsTitle}</small>
          </div>
        </div>
      </div>
      <div className={styles.chart}>
        <div className={styles['chart__graph']}>
          <CircularProgressbarWithChildren
            styles={{
              path: {
                stroke: graphPercentagePathColor,
                strokeLinecap: 'butt',
              },
              trail: {
                stroke: graphFullCircleColor,
                strokeLinecap: 'butt',
                transform: 'rotate(0.25turn)',
                transformOrigin: 'center center',
              },
            }}
            value={percentage}
            strokeWidth={10}
          ></CircularProgressbarWithChildren>
        </div>
        <div className={styles['chart__stats']}>
          <div className={styles['chart__stats-title']}>{percentageTitle}</div>
          <div className={styles['chart__stats-percent']}>{percentage}%</div>
        </div>
      </div>
    </div>
  );
};

export default Statistics;
