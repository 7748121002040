import ActionStatusConstants from 'helpers/ActionStatusConstants';
import ActionTypeConstants from 'helpers/ActionTypeConstants';

const initialState = {
  status: ActionStatusConstants.INITIAL,
  allSitesList: null,
};

export default (state = initialState, action) => {
  const actionType = action && action.type ? action.type : null;
  const payload = action && action.payload ? action.payload : null;

  switch (actionType) {
    case ActionTypeConstants.LOAD_ALL_SITES_LIST:
      return {
        ...state,
        status: ActionStatusConstants.ISBUSY,
      };
    case ActionTypeConstants.LOAD_ALL_SITES_LIST_SUCCESS:
      return {
        ...state,
        status: ActionStatusConstants.SUCCESS,
        allSitesList: payload.allSitesList,
      };
    default:
  }
  return state;
};
