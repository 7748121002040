import { push } from 'redux-first-history';
import { call, put, takeLatest, delay, select } from 'redux-saga/effects';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { actionApiCall } from 'helpers/api/ApiCalls';
import * as sagaConstants from 'helpers/constants/SagasConstants';
import { redirectToProfile } from 'helpers/utils';

const getSelected = (state) => state.UserOrgSiteReducer.selected;

function* submitUserSettingsFormSaga({ payload }) {
  try {
    const apiResponse = yield call(actionApiCall, {
      actionType: ActionTypeConstants.SUBMIT_USER_SETTINGS_FORM,
      data: {
        id: payload.id,
        email: payload.email,
        firstname: payload.firstname,
        lastname: payload.lastname,
        // image_data: img_data,
        // image_type: img_type,
        phone: payload.phone,
        address_1: payload.address_1,
        address_2: payload.address_2,
        postcode: payload.postcode,
        city: payload.city,
        country_name: payload.country_name,
        country_code: payload.country_code,
        state: payload.state,
        registered: true,
        active: true,
        password: payload.password,
        sso: payload.sso || 'No',
      },
    });

    if (apiResponse.res.data.response === 200) {
      const selected = yield select(getSelected);
      yield put({
        type: ActionTypeConstants.SUBMIT_EDIT_USER_FORM_SUCCESS,
        payload,
      });

      yield put({
        type: ActionTypeConstants.AUTH_GET_USER,
      });
      // yield put({ type: ActionTypeConstants.SUBMIT_USER_SETTINGS_FORM_SUCCESS });
      yield put(push(redirectToProfile(selected.ui_version)));
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: apiResponse.res.data.message || `Settings ${sagaConstants.SUCCESSFULLY_UPDATED}`,
          color: 'success',
        },
      });
      yield delay(10000);
      yield put({ type: ActionTypeConstants.HIDE_ALERT });
    } else {
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: apiResponse.res.data.message || `${sagaConstants.ISSUE_OCCURED} updating settings`,
          color: 'danger',
        },
      });
      yield delay(10000);
      yield put({ type: ActionTypeConstants.HIDE_ALERT });
    }
  } catch (err) {
    console.log(err);
    yield put({
      type: ActionTypeConstants.SHOW_ALERT,
      payload: {
        message: `${sagaConstants.SERVER_ERROR}`,
        color: 'danger',
      },
    });
    yield delay(10000);
    yield put({ type: ActionTypeConstants.HIDE_ALERT });
  }
}

export default function* () {
  yield takeLatest(ActionTypeConstants.SUBMIT_USER_SETTINGS_FORM, submitUserSettingsFormSaga);
}
