import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';

import Modal from 'components/Modal/Modal';
import Button from 'components/Button/Button';

import FormElement from 'components/FormElement/FormElement';
import TableDefEvidenceModal from 'table-defs/TableDefEvidenceModal';
import FullDataTable from 'components/DataTable/FullDataTable';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';

import UploadDocument from 'components/UploadDocument/UploadDocument';

import {
  createEvidenceAction,
  deleteEvidenceAction,
  downloadEvidenceAction,
  loadEvidenceByCloudAssetAction,
  loadEvidenceByInventoryAction,
  loadEvidenceByAuditAction,
} from 'store/actions/Actions';

import styles from './EvidenceUploadModal.module.scss';

const EvidenceUploadModal = (props) => {
  const [state, setState] = useState({
    file: '',
    name: '',
  });

  const {
    // taskId,
    entity_type,
    EvidenceReducer,
    showModal,
    loadEvidenceByInventoryAction,
    loadEvidenceByAuditAction,
    // setShowModal,
    createEvidenceAction,
    downloadEvidenceAction,
    deleteEvidenceAction,
    loadEvidenceByCloudAssetAction,
    UserOrgSiteReducer,
  } = props;

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const entity_id = showModal.id;

  useEffect(() => {
    if (entity_type === 'cloud_asset') {
      loadEvidenceByCloudAssetAction({ id: entity_id });
    }
    if (entity_type === 'inventory_device') {
      loadEvidenceByInventoryAction({ id: entity_id });
    }
    if (entity_type === 'audit') {
      loadEvidenceByAuditAction({ id: entity_id });
    }
  }, [entity_id, entity_type]);

  const addEvidence = () => {
    try {
      createEvidenceAction({
        entity_type: entity_type,
        entity_id: entity_id,
        file: {
          file: state['file'],
          name: state['name'],
        },
      });
      setState({
        file: '',
        name: '',
      });
    } catch (error) {
      console.log(error);
    }
  };

  const deleteEvidence = (payload) => {
    try {
      setShowConfirmModal({
        title: 'Delete evidence file',
        question: `You're about to permanently delete evidence file "${payload.filename}"! Are you sure?`,
        confirmAction: () =>
          deleteEvidenceAction({
            id: payload.id,
            entity_id: entity_id,
            entity_type: entity_type,
          }),
      });
    } catch (error) {
      console.log(error);
    }
  };
  const downloadEvidence = (payload) => {
    try {
      downloadEvidenceAction({ hash: payload.hash });
    } catch (error) {
      console.log(error);
    }
  };

  const renderConfirmModal = () => {
    return ReactDOM.createPortal(<ConfirmationModal data={showConfirmModal} setShowModal={setShowConfirmModal} />, document.getElementById('modal-evidence'));
  };

  const evidence = EvidenceReducer.evidence || [];

  const is_officer = (UserOrgSiteReducer.selected && UserOrgSiteReducer.selected.is_officer) || false;
  const is_super = (UserOrgSiteReducer.selected && UserOrgSiteReducer.selected.is_super) || false;

  return (
    <>
      {showConfirmModal && renderConfirmModal()}
      <Modal setShowModal={props.setShowModal} className={styles.modal}>
        <div className={styles['modal-header']}>
          <h2 className={styles.heading}>Evidence Locker</h2>
          <p className={styles.description}>{showModal.name || showModal.application}</p>
        </div>

        <div className={styles['modal-body']}>
          <>
            <div className={styles['holder']}>
              <FormElement labelText="DOCUMENTS" htmlFor="documents">
                <UploadDocument state={state} setState={setState} formats={['jpg', 'jpeg', 'png', 'gif', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'pdf']} />
              </FormElement>

              <Button
                className={styles.button}
                text="UPLOAD EVIDENCE"
                icon={props.CreateEvidenceReducer.status === 'ISBUSY' ? 'fad fa-spinner-third fa-spin' : 'fad fa-upload'}
                disabled={state['file'] === '' ? true : false}
                onClick={addEvidence}
              />
            </div>

            <br />
            <br />
          </>

          <FullDataTable
            className={styles['add-evidence-table']}
            rowsData={evidence}
            definitions={TableDefEvidenceModal}
            dataType={'task-evidence-audit-edit'}
            customTableProps={is_officer || is_super ? { downloadEvidence, deleteEvidence } : { downloadEvidence }}
          />
        </div>
        <div className={styles['modal-footer']}>
          <Button className={styles.button} text="CLOSE" onClick={() => props.setShowModal(false)} color="secondary" />
        </div>
      </Modal>
      <div id="modal-evidence"></div>
    </>
  );
};

const mapStateToProps = (state) => ({
  CreateEvidenceReducer: state.CreateEvidenceReducer,
  EvidenceReducer: state.EvidenceReducer,
  UserOrgSiteReducer: state.UserOrgSiteReducer,
});

const mapDispatchToProps = {
  loadEvidenceByCloudAssetAction,
  createEvidenceAction,
  deleteEvidenceAction,
  downloadEvidenceAction,
  loadEvidenceByInventoryAction,
  loadEvidenceByAuditAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(EvidenceUploadModal);
