import React, { useState } from 'react';
import Button from 'components/Button/Button';

import styles from './ChooseAction.module.scss';

/**
 *
 * @param {Objectp[]} actions
 * @param {String} actions[].actionName - name of the action to show in menu
 * @param {Function} actions[].actionHandler - function to execute on click of action
 * @param {Object} [dropdownToggleRef] - a way to toggle from outside
 */
const ChooseAction = (props) => {
  const actions =
    props.actions && props.actions.length
      ? props.actions
      : [
          {
            actionName: 'Notify',
            actionHandler: () => console.log('Notify'),
          },
          {
            actionName: 'Mark Complete',
            actionHandler: () => console.log('Mark Complete'),
          },
          {
            actionName: 'Mark Incomplete',
            actionHandler: () => console.log('Mark Incomplete'),
          },
          {
            actionName: 'Delete',
            actionHandler: () => console.log('Delete'),
          },
        ];

  const [showActions, setShowActions] = useState(false);
  const toggleShowActions = () => {
    setShowActions(!showActions);
  };

  return (
    <div className={styles['choose-action']}>
      <Button
        className={`${styles['choose-action-button']} ${showActions ? 'show' : ''}`}
        text="CHOOSE ACTION"
        color="secondary"
        size="md"
        iconEnd="fas fa-sort-down"
        onClick={toggleShowActions}
        dropdownToggleRef={props.dropdownToggleRef || null}
      />
      <div className={`${styles.actions} ${showActions ? '' : styles.hide}`}>
        {actions.map((action, index) =>
          action && action.actionName ? (
            <div className={styles.action} key={action.actionName} onClick={action.actionHandler}>
              {action.actionName}
            </div>
          ) : null
        )}
      </div>
    </div>
  );
};

export default ChooseAction;
