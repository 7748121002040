import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import MainLayout from 'v2/layouts/MainLayout/MainLayout';
import PageHeader from 'v2/components/PageHeader/PageHeader';
import PageHeaderActions from 'v2/components/PageHeader/components/PageHeaderActions/PageHeaderActions';
import Container from 'components/Container/Container';
import Checkbox from 'components/Checkbox/Checkbox';
import Select from 'components/Select/Select';
import FormElement from 'components/FormElement/FormElement';
import FullDataTable from 'components/DataTable/FullDataTable';
import TableDefAllUsersUnassigned from 'table-defs/TableDefAllUsersUnassigned';

import useFilterRows from 'helpers/customHooks/useFilterRows';
import { setSelectedRows, exportAsCSV, toggleDropdowns } from 'helpers/utils';

import { useAppDispatch, useAppSelector } from 'hooks';
import { loadUsersUnassignedAction, loadSitesAction, setPaginationAction, searchUsersAction } from 'store/actions/Actions';

import styles from './UsersUnassigned.module.scss';

const labels = () => ({
  name: {
    label: 'NAME',
  },
  email: {
    label: 'EMAIL',
  },
  organizations: {
    label: 'ORGANIZATIONS',
  },
  status: {
    label: 'STATUS',
  },
  last_modified: {
    label: 'MODIFIED',
  },
  created_at: {
    label: 'CREATED',
  },
});

function Users() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const DatatableReducer = useAppSelector((state) => state.DatatableReducer);
  const UsersUnassignedReducer = useAppSelector((state) => state.UsersUnassignedReducer);
  const SitesReducer = useAppSelector((state) => state.SitesReducer);
  const UserOrgSiteReducer = useAppSelector((state) => state.UserOrgSiteReducer);
  const UsersSearchReducer = useAppSelector((state) => state.UsersSearchReducer);

  const noFilters = {
    text: '',
    role: '',
    sites: '',
    status: '',
    selected_sites: [],
  };

  const [filters, setFilters] = useState(DatatableReducer?.datatable?.users_unassigned?.filters || noFilters);

  const [state, setState] = useState({
    selectedRows: undefined,
    selected_sites: [],
  });

  const [searchOrg, setSearchOrg] = useState(false);
  const [searchType, setSearchType] = useState('0');

  useEffect(() => {
    if (searchOrg && filters.text.length > 2) {
      dispatch(
        searchUsersAction({
          column: searchType === '0' ? 'name' : 'email',
          term: filters.text,
        })
      );
    } else {
      dispatch({ type: 'SEARCH_USERS_SUCCESS', payload: { users: undefined } });
    }
  }, [searchOrg, filters.text, searchType]);

  useEffect(() => {
    if (!SitesReducer.sites) {
      dispatch(loadSitesAction());
    }
  }, [SitesReducer.sites]);

  useEffect(() => {
    if (!UsersUnassignedReducer.users && SitesReducer.sites) {
      dispatch(loadUsersUnassignedAction({ inactive: true }));
    }
  }, [UsersUnassignedReducer.users, SitesReducer.sites]);

  const onChangeRowSelect = ({ selectedRows }) => {
    if (selectedRows !== state.selectedRows) {
      setState((s) => ({ ...s, selectedRows }));
    }
  };

  const [selectedUsersIds, setSelectedUsersIds] = useState(DatatableReducer?.datatable?.users_unassigned?.selectedIds || []);

  useEffect(() => {
    dispatch({
      type: 'SET_DATATABLE',
      payload: {
        datatable: {
          users_unassigned: {
            selectedIds: selectedUsersIds,
            filters,
            selectedRows: state.selectedRows,
            selected_sites: state.selected_sites,
            rowsPerPage: DatatableReducer?.datatable?.users_unassigned?.rowsPerPage || 10,
            currentPage: DatatableReducer?.datatable?.users_unassigned?.currentPage || 0,
          },
        },
      },
    });
  }, [selectedUsersIds, filters, state]);

  const setFilter = (filterName) => (e) => {
    setFilters({
      ...filters,
      [filterName]: e.target.type === 'checkbox' ? e.target.checked : e.target.value || e?.target?.attributes?.value?.value || '',
    });
    dispatch(setPaginationAction({ dataType: 'users_unassigned', currentPage: 0 }));
  };

  const filteredRows = useFilterRows(filters, searchOrg ? UsersSearchReducer.users : UsersUnassignedReducer.users);

  const statusDropdownRef = React.createRef();
  const chooseActionDropdownRef = React.createRef();

  const userSearchRender = (
    <div className={styles.user_search}>
      <FormElement className={styles.checkbox}>
        <Checkbox onChange={() => setSearchOrg(!searchOrg)} labelText="Org-wide search" />
      </FormElement>
      {searchOrg && (
        <FormElement className={styles.search}>
          <Select onClick={(e) => setSearchType(e.target.value)} size="md" value={searchType} labels={{ 0: 'Name', 1: 'E-mail' }}>
            <li value="0">Name</li>
            <li value="1">E-mail</li>
          </Select>
        </FormElement>
      )}
    </div>
  );

  const is_super = UserOrgSiteReducer?.selected?.is_super || false;

  return (
    <MainLayout>
      <div onClick={toggleDropdowns([statusDropdownRef, chooseActionDropdownRef])}>
        <PageHeader title="Unassigned people">
          <PageHeaderActions
            primaryButtonText="ADMIN"
            primaryButtonHandler={() => {
              navigate('/admin');
            }}
            primaryButtonIcon="fal fa-long-arrow-left"
            searchHandler={(e) => {
              setFilters({ ...filters, text: e.target.value || '' });
              dispatch(
                setPaginationAction({
                  dataType: 'users_unassigned',
                  currentPage: 0,
                })
              );
            }}
            userSearch={true || userSearchRender} // temp hide
            searchValue={filters.text}
            statusDropdownRef={statusDropdownRef}
            chooseActionDropdownRef={chooseActionDropdownRef}
            chooseAction={[
              {
                actionName: 'Export Selected',
                actionHandler: () => {
                  const u = searchOrg ? UsersSearchReducer.users : UsersUnassignedReducer.users;
                  const data = u.filter((item) => selectedUsersIds.map((i) => i.id).includes(item.id));
                  const updatedData = data.map((item) => ({
                    ...item,
                    created_at: item.created_at.slice(0, 10),
                    last_modified: item.last_modified.slice(0, 10),
                  }));
                  exportAsCSV(updatedData, labels, 'users_unassigned.csv');
                },
              },
            ]}
            status={{
              onChange: setFilter('status'),
              options: ['All', 'Awaiting Registration', 'Inactive', 'Active'],
            }}
            resetFilters={() => {
              setFilters(noFilters);
              dispatch(
                setPaginationAction({
                  dataType: 'users_unassigned',
                  currentPage: 0,
                })
              );
            }}
            filters={filters}
          />
        </PageHeader>
        <Container wide fluid>
          {UsersUnassignedReducer.users && is_super && (
            <FullDataTable
              onRowClick={function onRowClick(e) {
                if (e.currentTarget.classList.contains('col-actions') || e.currentTarget.classList.contains('col-row-selector')) {
                  return;
                }
                navigate(`/people/edit?id=${this.props.rowData.id}`);
              }}
              rowsData={filteredRows}
              definitions={TableDefAllUsersUnassigned}
              dataType="users_unassigned"
              onChangeRowSelect={({ selectedRows }) => {
                onChangeRowSelect({ selectedRows });
              }}
              setSelectedRows={setSelectedRows(['id'], setSelectedUsersIds)}
              customTableProps={{
                selectedRows: state.selectedRows || selectedUsersIds,
                edit: (rowData) => {
                  navigate(`/people/edit?id=${rowData.id}`);
                },
              }}
            />
          )}
        </Container>
      </div>
    </MainLayout>
  );
}

export default Users;
