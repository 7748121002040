import React, { useState } from 'react';
import { connect } from 'react-redux';

import styles from './EditNote.module.scss';

import HR from 'components/HR/HR';
import FormElement from 'components/FormElement/FormElement';
import Button from 'components/Button/Button';
import TableDefTaskNotes from 'table-defs/TableDefTaskNotes';
import FullDataTable from 'components/DataTable/FullDataTable';
import { createTasknoteAction, deleteTasknoteAction } from 'store/actions/Actions';

const EditNote = (props) => {
  const { notes, taskId, createTasknoteAction, deleteTasknoteAction, UserOrgSiteReducer, allowAdd = true } = props;
  const defaultState = {
    note: '',
    name: '',
    date: '',
  };
  const [state, setState] = useState(defaultState);
  const [stateErrors, setStateErrors] = useState({
    note: '',
  });

  const handleChange = (inputName) => (e) => {
    e.persist();
    setState((s) => ({
      ...s,
      [inputName]: e.target.value,
    }));
    setStateErrors((s) => ({ ...s, [inputName]: '' }));
  };

  const removeNote = (data) => {
    deleteTasknoteAction({ entity_id: taskId, id: data.id });
  };

  const addNote = () => {
    if (!state.note) {
      setStateErrors((s) => ({
        ...s,
        note: 'Note is required',
      }));
    } else {
      createTasknoteAction({ id: taskId, notes: state.note });
      setState(defaultState);
    }
  };

  const is_super = (UserOrgSiteReducer && UserOrgSiteReducer.selected && UserOrgSiteReducer.selected.is_super) || false;
  const is_officer = (UserOrgSiteReducer && UserOrgSiteReducer.selected && UserOrgSiteReducer.selected.is_officer) || false;

  return (
    <div className={`${styles['add-note']} ${props.className || ''}`}>
      <p className={styles['add-note-heading']}>Add New Note</p>
      <HR />

      <div className={styles['add-note-data']}>
        <div className={styles.note_holder}>
          <FormElement labelText="NOTE" htmlFor="description" errorMessage={stateErrors.note}>
            <textarea rows="2" value={state.note} placeholder="Enter notes" onChange={handleChange('note')} />
          </FormElement>

          <Button disabled={!allowAdd} className={`${styles['add-note-button']}`} text="ADD NOTE" icon="far fa-plus" onClick={addNote} />
        </div>
        <FullDataTable
          className={styles['add-note-table']}
          rowsData={notes || []}
          dataType={'task-notes-edit'}
          definitions={TableDefTaskNotes}
          customTableProps={is_super || is_officer ? { removeNote } : null}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  UserOrgSiteReducer: state.UserOrgSiteReducer,
});

const mapDispatchToProps = {
  createTasknoteAction,
  deleteTasknoteAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditNote);
