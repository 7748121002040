/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import ReactPlayer from 'react-player';

import Button from 'components/Button/Button';

import styles from './Player.module.scss';

import AppLayoutWrapFull from 'components/AppLayoutWrapFull';

// import PageHeader from "components/PageHeader/PageHeader";
// import { ONLY_OFFICE_JS } from  "helpers/ApiEndpointConstants";

import { loadFoldersAction, loadSitesAction } from 'store/actions/Actions';

const Player = (props) => {
  const { loadFoldersAction, UserOrgSiteReducer, loadSitesAction } = props;
  const is_admin = UserOrgSiteReducer && UserOrgSiteReducer.selected && UserOrgSiteReducer.selected.is_admin ? true : false;

  const { id } = useParams();
  const navigate = useNavigate();

  const [isActive, setActive] = useState(true);

  useEffect(() => {
    const vc = function (event) {
      if (window.document.hidden) {
        setActive(false);
      } else {
        setActive(true);
      }
    };
    window.document.addEventListener('visibilitychange', vc);
    return () => {
      window.document.removeEventListener('visibilitychange', vc);
    };
  });

  useEffect(() => {
    loadFoldersAction({ id });
  }, [id, loadFoldersAction]);

  var getFolderIcon = function () {
    return is_admin ? (
      <div className={styles['path']}>
        <a href="/achieve/documents">
          <i className={`fal fa-folder-open ${styles['up']}`}></i>
        </a>{' '}
        {props.folders.path}
      </div>
    ) : (
      <div className={styles['path']}></div>
    );
  };

  return (
    <AppLayoutWrapFull>
      {props.folders && getFolderIcon()}

      <div className={styles['holder']}>
        {props.folders && (
          <div id="video" className={styles.video}>
            <div className={styles['close']}>
              <Button className={styles.button} text={'CLOSE'} icon="fal fa-times" onClick={() => navigate(-1)} size="md" />
            </div>

            {props.folders.url && (
              <ReactPlayer
                className={styles.video}
                height="auto"
                onProgress={(e) => isActive && loadSitesAction()}
                progressInterval={1000 * 60 * 5}
                controls
                width="100%"
                style={{ maxWidth: 960 }}
                playing
                url={props.folders.url}
              />
            )}
          </div>
        )}
      </div>
    </AppLayoutWrapFull>
  );
};

const mapStateToProps = (state) => ({
  folders: state.FoldersReducer.folders,
  UserOrgSiteReducer: state.UserOrgSiteReducer,
});

const mapDispatchToProps = {
  loadFoldersAction,
  loadSitesAction,
};

// export default Documents;
export default connect(mapStateToProps, mapDispatchToProps)(Player);
