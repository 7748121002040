import React, { useEffect, useState } from 'react';
import moment from 'moment';

import ActionStatusConstants from 'helpers/ActionStatusConstants';
import Spinner from 'components/Spinner/Spinner';
import { useAppDispatch, useAppSelector } from 'hooks';
import { loadUserSettingsFormAction } from 'store/actions/Actions';

import Method from './components/Method/Method';

import styles from './MFASettings.module.scss';

function MFASettings() {
  const dispatch = useAppDispatch();

  const SettingsReducer = useAppSelector((state) => state.SettingsReducer);
  const MfaReducer = useAppSelector((state) => state.MfaReducer);

  const [isLoading, setLoading] = useState(true);
  const [counter, setCounter] = useState(undefined);

  useEffect(() => {
    if (SettingsReducer.status === ActionStatusConstants.ISBUSY) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [SettingsReducer?.status]);

  useEffect(() => {
    if (!SettingsReducer.user) {
      dispatch(loadUserSettingsFormAction());
    } else if (SettingsReducer.user?.mfa?.code_expire_at) {
      const start = moment().unix();
      const end = SettingsReducer.user.mfa.code_expire_at;
      setCounter(end - start);
      sessionStorage.setItem('mfa_code_expire', end.toString());
    }
  }, [SettingsReducer?.user]);

  useEffect(() => {
    if (MfaReducer?.mfa?.codeExpiresAt) {
      const start = moment().unix();
      const end = MfaReducer?.mfa?.codeExpiresAt;
      setCounter(end - start);
      sessionStorage.setItem('mfa_code_expire', end.toString());
    }
  }, [MfaReducer]);

  return (
    <div className={styles.wrapper}>
      {isLoading ? (
        <div className={styles.loader}>
          <Spinner />
        </div>
      ) : undefined}
      <div className={styles.main_title}>Two-Factor Authentication (2FA)</div>
      <div className={styles.main_text}>We&#39;ll ask for a verification code via your security method if we notice a login from an unrecognized device or browser.</div>

      <div className={styles.type}>
        <Method type="email" user={SettingsReducer.user} counter={counter} setCounter={setCounter} />
      </div>
      <div className={styles.type}>
        <Method type="phone" user={SettingsReducer.user} counter={counter} setCounter={setCounter} />
      </div>
    </div>
  );
}

export default MFASettings;
