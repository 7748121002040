import classNames from 'classnames';
import React, { useState } from 'react';

import styles from './AddRemoveItems.module.scss';

/*
    USAGE:
    1. Create allItems and setAllItems - item must have id and label properties
        e.g. item = {id: user.user_id, label: user.name}
    2. All added items will have added property
    3. Initially load added items - add "added" property to allItems array provided as the prop
*/

interface IItem {
    id: string; 
    label: string;
    selected?: boolean;
    added?: boolean;
}

interface IItems extends Array<IItem> {}

interface IProps {
    searchPlaceholder?: string;
    searchCustomClassName?: string;
    allItemsTitle?: string;
    addedItemsTitle?: string;
    allItems: IItems,
    setAllItems?: React.Dispatch<React.SetStateAction<IItems>>,
    disabled?: boolean,
    errorMessage?: string,
    required?: boolean,
}

export function AddRemoveItems({
    searchPlaceholder = 'Search...',
    searchCustomClassName = undefined,
    allItemsTitle = 'ALL ITEMS',
    addedItemsTitle = 'ADDED ITEMS',
    allItems = [],
    setAllItems = undefined,
    disabled = false,
    errorMessage = '',
    required = false,
}: IProps) {

    const [searchValue, setSearchValue] = useState('');

    const onSearchChange = (e) => {
        setSearchValue(e.target?.value?.toLowerCase());
    }

    const handleSelectItem = (e) => {
        if (disabled) return;
        const selectedItemIndex = allItems.findIndex((item) => item.id === e.target.id);
        const newItems = [...allItems];
        if (newItems[selectedItemIndex].selected) {
            delete newItems[selectedItemIndex].selected;
        } else {
            newItems[selectedItemIndex].selected = true;
        }
        setAllItems([...newItems]);
    }

    const handleAddItems = () => {
        const newAllItems = allItems.map(item => {
            if (!item.added && item.selected) {
                item.added = true;
                delete item.selected;
                return item;
            }
            return item;
        })
        setAllItems(newAllItems);
    }

    const handleRemoveItems = () => {
        const newAllItems = allItems.map(item => {
            if (item.added && item.selected) {
                delete item.added;
                delete item.selected;
                return item;
            }
            return item;
        })
        setAllItems(newAllItems);
    }

    const style = classNames(styles.search, styles[`search-md`], searchCustomClassName);
    const styleSelectedItems = classNames(styles.item, styles[`item-selected`]);
    const disabledAllArea = classNames(styles['all-items-data'], styles.disabled);
    const disabledAddedArea = classNames(styles['added-items-data'], styles.disabled);
    
    return (
      <div className={styles['add-remove-items']}>
        <div className={styles['all-items']}>
            <div className={styles['items-title']}>
                {allItemsTitle}
            </div>
            <div className={style}>
                <input
                    className={styles['search-input']}
                    type="text"
                    autoComplete="none"
                    placeholder={searchPlaceholder}
                    onChange={onSearchChange}
                    value={searchValue}
                    data-testid="search-input-field"
                    disabled={disabled}
                />
                <i className="fas fa-search" />
            </div>
            <div className={disabled ? disabledAllArea : styles['all-items-data']}>
                <ul>
                   {allItems?.filter(item => item.label.toLowerCase().includes(searchValue)).map((item) => <li id={item.id} className={item.added ? styles['item-removed'] : (item.selected ? styleSelectedItems : styles.item)} key={item.id} onClick={handleSelectItem}>{item.label}</li>)} 
                </ul>
            </div>
        </div>
        <div className={styles.buttons}>
            <button type="button" className={styles.button} onClick={handleAddItems} disabled={disabled}>
                <i className="fa-light fa-arrow-right"></i>
            </button>
            <button type="button" className={styles.button} onClick={handleRemoveItems} disabled={disabled}>
                <i className="fa-light fa-arrow-left"></i>
            </button>
        </div>
        <div className={styles['added-items']}>
            <div className={styles['items-title']}>
                {addedItemsTitle} {required && <span className={styles['small-text']}>*</span>}
            </div>
            <div className={disabled ? disabledAddedArea : styles['added-items-data']}>
                <ul>
                   {allItems?.filter(item => item.added).map((item) => <li id={item.id} className={item.selected ? styleSelectedItems : styles.item} key={item.id} onClick={handleSelectItem}>{item.label}</li>)} 
                </ul>
            </div>
            {!!errorMessage.length && <div className={styles['error-message']}>{errorMessage}</div>}
        </div>
      </div>
    );
  }
  
  export default AddRemoveItems;
