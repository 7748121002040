import { push } from 'redux-first-history';
import { call, put, takeLatest, delay, select } from 'redux-saga/effects';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { actionApiCall } from 'helpers/api/ApiCalls';
import * as sagaConstants from 'helpers/constants/SagasConstants';
import { redirectToVendors } from 'helpers/utils';

const getSelected = (state) => state.UserOrgSiteReducer.selected;

function* declineAffiliateSaga({ payload }) {
  try {
    if ((payload.items.length || 0) < 1) {
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: 'Please, select one or more vendors',
          color: 'danger',
        },
      });
      yield delay(10000);
      yield put({ type: ActionTypeConstants.HIDE_ALERT });
    } else {
      const selected = yield select(getSelected);
      const apiResponse = yield call(actionApiCall, {
        actionType: ActionTypeConstants.DECLINE_AFFILIATE,
        data: {
          organization: selected.org,
          items: payload.items,
        },
      });

      if (apiResponse.res.data.response === 200) {
        yield put({ type: ActionTypeConstants.LOAD_AFFILIATES });
        yield put(push(redirectToVendors(selected.ui_version)));
        yield put({
          type: ActionTypeConstants.SHOW_ALERT,
          payload: {
            message: apiResponse.res.data.message || `The vendor ${sagaConstants.SUCCESSFULLY_DECLINED}`,
            color: 'success',
          },
        });
        yield delay(10000);
        yield put({ type: ActionTypeConstants.HIDE_ALERT });
      } else {
        // Failure to DECLINE affiliate
        yield put({
          type: ActionTypeConstants.SHOW_ALERT,
          payload: {
            message: apiResponse.res.data.message || `${sagaConstants.ISSUE_OCCURED} declining the vendor`,
            color: 'danger',
          },
        });
        yield delay(10000);
        yield put({ type: ActionTypeConstants.HIDE_ALERT });
      }
    }
  } catch (err) {
    yield put({
      type: ActionTypeConstants.SHOW_ALERT,
      payload: {
        message: `${sagaConstants.SERVER_ERROR}`,
        color: 'danger',
      },
    });
    yield delay(10000);
    yield put({ type: ActionTypeConstants.HIDE_ALERT });
  }
}

export default function* () {
  yield takeLatest(ActionTypeConstants.DECLINE_AFFILIATE, declineAffiliateSaga);
}
