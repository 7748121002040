import { call, put, takeLatest } from 'redux-saga/effects';

import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { actionApiCall } from 'helpers/api/ApiCalls';
import * as sagaConstants from 'helpers/constants/SagasConstants';

function* loadSettingsSaga({ payload }) {
  // console.log('loadsetting saga');
  try {
    const apiResponse = yield call(actionApiCall, {
      actionType: ActionTypeConstants.LOAD_USER_SETTINGS_FORM,
      data: {
        id: payload ? payload.id : null,
      },
    });
    if (apiResponse.res.data.response === 200) {
      const { data } = apiResponse.res.data;
      // if (!Array.isArray(data)) {
      //     data = [data];
      // }
      yield put({
        type: ActionTypeConstants.LOAD_USER_SETTINGS_FORM_SUCCESS,
        payload: {
          user: data,
        },
      });
    } else {
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: apiResponse.res.data.message || `${sagaConstants.ISSUE_OCCURED} loading user settings`,
          color: 'danger',
        },
      });
    }
  } catch (err) {
    yield put({
      type: ActionTypeConstants.SHOW_ALERT,
      payload: {
        message: `${sagaConstants.SERVER_ERROR}`,
        color: 'danger',
      },
    });
  }
}

export default function* () {
  yield takeLatest(ActionTypeConstants.LOAD_USER_SETTINGS_FORM, loadSettingsSaga);
}
