import ActionStatusConstants from 'helpers/ActionStatusConstants';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { LOCATION_CHANGE } from 'redux-first-history';

const initialState = {
  status: ActionStatusConstants.INITIAL,
  data: undefined,
  message: undefined,
  formErrors: undefined,
};

const UserLoginFormReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypeConstants.GET_USER_BY_KEY:
    case ActionTypeConstants.SUBMIT_USER_LOGIN_FORM: {
      return {
        ...state,
        status: ActionStatusConstants.ISBUSY,
      };
    }
    case ActionTypeConstants.GET_USER_BY_KEY_SUCCESS:
    case ActionTypeConstants.SUBMIT_USER_LOGIN_FORM_SUCCESS: {
      return {
        ...state,
        data: payload,
        status: ActionStatusConstants.SUCCESS,
        message: payload?.message,
      };
    }
    case ActionTypeConstants.GET_USER_BY_KEY_FAILURE:
    case ActionTypeConstants.SUBMIT_USER_LOGIN_FORM_FAILURE: {
      return {
        ...state,
        status: ActionStatusConstants.FAILURE,
        message: payload?.message,
      };
    }
    case ActionTypeConstants.SUBMIT_USER_LOGIN_FORM_RESET:
    case LOCATION_CHANGE: {
      return {
        ...initialState,
      };
    }
    default:
  }
  return state;
};

export default UserLoginFormReducer;
