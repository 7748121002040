import classNames from 'classnames';
import React, { ChangeEventHandler } from 'react';

import styles from './SearchInput.module.scss';

interface ISearchInput {
  onChange?: ChangeEventHandler<HTMLInputElement>;
  value?: string;
  size?: string;
  className?: string;
  placeholder?: string;
}

const defaultProps = {
  onChange: undefined,
  value: '',
  size: 'md',
  className: undefined,
  placeholder: 'Search',
};

function SearchInput({ onChange, value, size, className, placeholder }: ISearchInput) {
  const style = classNames(styles.search, styles[`search-${size}`], className);
  return (
    <div className={style}>
      <input className={styles['search-input']} type="text" autoComplete="none" placeholder={placeholder} onChange={onChange} value={value} data-testid="search-input-field" />
      <i className="fas fa-search" />
    </div>
  );
}

SearchInput.defaultProps = defaultProps;

export default SearchInput;
