import React, { useEffect } from 'react';

// import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import { loadStatsAction } from 'store/actions/Actions';
import { useAppDispatch, useAppSelector } from 'hooks';

// import Stat from './components/Stat/Stat';

import styles from './PageHeader.module.scss';

function PageHeader(props) {
  // const [showStats, setShowStats] = useState(true);
  const { loadStats = true, title, children, heading,
    // breadcrumbs,
    className, disableHints, hints } = props;

  const dispatch = useAppDispatch();
  const StatsReducer = useAppSelector((state) => state.StatsReducer);

  // const toggleStats = () => {
  //   setShowStats(!showStats);
  // };

  useEffect(() => {
    if (!StatsReducer.stats && loadStats) {
      dispatch(loadStatsAction());
    }
  }, [StatsReducer.stats, loadStatsAction, loadStats]);

  const renderHints = () => (
    <div className={styles.hints}>
      <i className="far fa-question-circle" />
      <span onClick={disableHints} aria-hidden="true">
        Hide Helpful Hints
      </span>
    </div>
  );

  return (
    <div className={`${styles['page-header']} ${className || ''}`}>
      {title && <div className={styles['page-header-container']}>
        <h1 style={{ fontWeight: 500 }}>{title}</h1>
      </div>}

      {(children || heading) && (
        <div className={styles['page-header-children']}>
          {heading ? (
            <span className={styles.heading}>
              {heading}
              {hints && renderHints()}
            </span>
          ) : (
            children || ''
          )}
        </div>
      )}
    </div>
  );
}

export default PageHeader;
