import React from 'react';
import TypeIcon from './TypeIcon';
import styles from './Method.module.scss';

function InitialMethod(props) {
  const { type, onInitType } = props;
  return (
    <div className={styles.link} role="none" onClick={onInitType}>
      <TypeIcon type={type} />
      {type === 'email' ? 'Add email' : 'Add mobile phone number'}
    </div>
  );
}

export default InitialMethod;
