import { call, put, takeLatest, delay, select } from 'redux-saga/effects';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { actionApiCall } from 'helpers/api/ApiCalls';
import * as sagaConstants from 'helpers/constants/SagasConstants';

const getSelected = (state) => state.UserOrgSiteReducer.selected;

function* loadTrainingMediaSaga({ payload }) {
  try {
    const selected = yield select(getSelected);
    const apiResponse = yield call(actionApiCall, {
      actionType: ActionTypeConstants.LOAD_TRAINING_MEDIA,
      data: {
        organization: selected.org,
        site: selected.site,
      },
    });
    if (apiResponse.res.data.response === 200) {
      const { data } = apiResponse.res.data;
      yield put({
        type: ActionTypeConstants.LOAD_TRAINING_MEDIA_SUCCESS,
        payload: {
          'training-media': data,
        },
      });
      // yield put({
      //     type: ActionTypeConstants.LOAD_TRAINING_MEDIA_SUCCESS,
      //     payload: {
      //         // Modal can outgrow page if we show too many
      //         // Solution 1 - limit default perPage

      //         "training-media": [...Array(10)].map((el, index) => ({
      //             id: index,
      //             index,
      //             "media-name": 'static text'
      //         }))
      //     }
      // });
    } else {
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: apiResponse.res.data.message || `${sagaConstants.ISSUE_OCCURED} loading training media`,
          color: 'danger',
        },
      });
      yield delay(10000);
      yield put({ type: ActionTypeConstants.HIDE_ALERT });
    }
  } catch (err) {
    yield put({
      type: ActionTypeConstants.SHOW_ALERT,
      payload: {
        message: `${sagaConstants.SERVER_ERROR}`,
        color: 'danger',
      },
    });
    yield delay(10000);
    yield put({ type: ActionTypeConstants.HIDE_ALERT });
  }
}

export default function* () {
  yield takeLatest(ActionTypeConstants.LOAD_TRAINING_MEDIA, loadTrainingMediaSaga);
}
