import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Modal from 'components/Modal/Modal';
import Button from 'components/Button/Button';
import FullDataTable from 'components/DataTable/FullDataTable';
import TableDefDocumentHistory from 'table-defs/TableDefDocumentHistory';

import { useAppDispatch, useAppSelector } from 'hooks';
import { loadDocumentAction, restoreDocumentAction } from 'store/actions/Actions';

import styles from './ViewHistoryModal.module.scss';

const ViewHistoryModal = (props) => {
  const { selectedFile, setShowModal } = props;

  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const document = useAppSelector((state) => state.DocumentReducer.document);

  useEffect(() => {
    dispatch(loadDocumentAction({ id: selectedFile.id }));
  }, [selectedFile]);

  return (
    <Modal setShowModal={setShowModal} className={styles.modal}>
      <div className={styles['modal-header']}>
        <h2 className={styles.heading}>Document Version History</h2>
      </div>
      <div className={styles['modal-body']}>
        <div className={styles['flex-container']}>
          <div className={styles['row']}>
            <div className={styles['label']}>
              <label>FOLDER:</label>
            </div>
            <div className={styles['text']}>{document && document.path.replace(selectedFile['folder_name'], '')}</div>
          </div>
          <div className={styles['row']}>
            <div className={styles['label']}>
              <label>FILENAME:</label>
            </div>
            <div className={styles['text']}>{selectedFile['folder_name']}</div>
          </div>
        </div>
        <div className={styles['table']}>
          <FullDataTable
            rowsData={document && document.history && document.history.history ? document.history.history.sort((a, b) => (a.id > b.id ? 1 : -1)) : []}
            definitions={TableDefDocumentHistory}
            customTableProps={{
              selectedFile,
              currentVersion: document && document.history ? document.history.currentVersion : 0,
              restore: (historyData, selectedFile) => {
                // console.log(historyData, selectedFile);
                dispatch(
                  restoreDocumentAction({
                    id: selectedFile.id,
                    version: historyData.version,
                  })
                );
                navigate(`/documents/editor/${selectedFile.id}/edit`);
              },
            }}
          />
        </div>
      </div>
      <div className={`${styles['modal-footer']} text-center`}>
        <Button className={styles.button} text="Close" onClick={() => setShowModal(false)} color="secondary" />
      </div>
    </Modal>
  );
};

export default ViewHistoryModal;
