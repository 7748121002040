import { push } from 'redux-first-history';
import { call, put, takeLatest, delay } from 'redux-saga/effects';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { actionApiCall } from 'helpers/api/ApiCalls';
import * as sagaConstants from 'helpers/constants/SagasConstants';

/**
 * Register user from Registration form submission.
 */
function* submitUserRegisterFormSaga({ payload }) {
  try {
    const apiResponse = yield call(actionApiCall, {
      actionType: ActionTypeConstants.SUBMIT_USER_REGISTER_FORM,
      data: {
        email: payload.email,
        firstname: payload.firstname,
        lastname: payload.lastname,
        phone: payload.phone,
        address_1: payload.address_1,
        address_2: payload.address_2,
        postcode: payload.postcode,
        city: payload.city,
        country_name: payload.country_name,
        country_code: payload.country_code,
        state: payload.state_code,
        registered: true,
        active: true,
        password: payload.password,
        'confirm-password': payload['confirm-password'],
        url_key: payload.url_key,
      },
    });

    if (apiResponse.res.data.response === 200) {
      yield put({
        type: ActionTypeConstants.SUBMIT_USER_REGISTER_FORM_SUCCESS,
      });
      yield put(push('/login'));
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: apiResponse.res.data.message || `Registration ${sagaConstants.SUCCESSFULLY_COMPLETED}`,
          color: 'success',
        },
      });
      yield delay(10000);
      yield put({ type: ActionTypeConstants.HIDE_ALERT });
    } else {
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: apiResponse.res.data.message || `${sagaConstants.ISSUE_OCCURED} registering`,
          color: 'danger',
        },
      });
      yield delay(10000);
      yield put({ type: ActionTypeConstants.HIDE_ALERT });
    }
  } catch (err) {
    console.log(err);
    yield put({
      type: ActionTypeConstants.SHOW_ALERT,
      payload: {
        message: `${sagaConstants.SERVER_ERROR}`,
        color: 'danger',
      },
    });
    yield delay(10000);
    yield put({ type: ActionTypeConstants.HIDE_ALERT });
  }
}

export default function* () {
  yield takeLatest(ActionTypeConstants.SUBMIT_USER_REGISTER_FORM, submitUserRegisterFormSaga);
}
