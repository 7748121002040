import React, { useState } from 'react';
import { useAppSelector } from 'hooks';
import Modal from 'components/Modal/Modal';
import { Button } from 'components/Button/Button';
import { copyToClipboard } from 'helpers/utils';

import styles from './MasterKeyModal.module.scss';

interface IProps {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

function MasterKeyModal(props: IProps) {
  const { setShowModal } = props;

  const MfaReducer = useAppSelector((state) => state.MfaReducer);
  const [showClipboardMessage, setShowClipboardMessage] = useState(false);

  const handleCopyToClipboard = () => {
    const elementValue = document?.querySelector('.copy-to-clipboard')?.textContent;
    copyToClipboard(elementValue)
      .then(() => {
        setShowClipboardMessage(true);
        window.setTimeout(() => {
          setShowClipboardMessage(false);
        }, 10_000);
        return true;
      })
      .catch(() => console.log('An error occured when tried to save to the clipboard'));
  };

  return (
    <Modal className={styles.modal} setShowModal={setShowModal}>
      <div>
        <p className={styles['modal-heading']}>Master Key</p>

        <div className={styles['modal-content']}>
          <div className={styles['master-desc']}>
            This is your master key. It will be shown to you only once. <br />
            Please save and keep it for the case you can&apos;t access your account.
          </div>
          <div className={`${styles['master-key']} copy-to-clipboard`}>{MfaReducer?.mfa?.masterKey}</div>

          <button type="button" className={styles['clipboard-button']} onClick={handleCopyToClipboard}>
            <i className="fa-light fa-clipboard" /> save to clipboard
          </button>
          {showClipboardMessage && <div className={styles['error-message-success']}>Successfully saved to the clipboard</div>}
        </div>

        <div className={styles['modal-footer']}>
          <Button text="OK" color="primary" onClick={() => setShowModal(false)} />
        </div>
      </div>
    </Modal>
  );
}

export default MasterKeyModal;
