export const SUPER_ADMIN_ORGANIZATIONS = 'super-admin-organizations';
export const CERTIFICATES_PAGE = 'certificates';
export const TASKS_PAGE = 'tasks';
export const TRAININGS_PAGE = 'trainings';
export const DOCUMENTS_PAGE = 'documents';
export const AFFILIATES_PAGE = 'affiliates';
export const REMEDIATIONS_PAGE = 'remediations';
export const AUDITS_PAGE = 'audits';
export const REPORTS_PAGE = 'reports';
export const INCIDENTS_PAGE = 'incidents';
export const USERS_PAGE = 'users';
export const CLOUD_AUDITS_PAGE = 'cloud-audits';
export const DEVICE_INVENTORY_PAGE = 'device_inventory';

export const ASSESSMENTS_PAGE = "assessments_page"
export const ASSIGNMENT_TASKS_PAGE = 'assignments_tasks';
export const ASSET_TASKS_PAGE = 'assets_tasks';
export const INCIDENT_TASKS_PAGE = 'incidents_tasks';
export const ASSESSMENT_TASKS_PAGE = 'assessment_tasks';
