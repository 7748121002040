import Button from 'components/Button/Button';
import FullDataTable from 'components/DataTable/FullDataTable';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './InfoPanel.module.scss';

const InfoPanel = (props) => {
  const [activeTab, setActiveTab] = useState('tab1');
  const { link, title, tab1, tab2, definitions, rowsData = [], tab1total = '0', tab2total = '0' } = props;
  const navigate = useNavigate();

  return (
    <div className={styles.info_panel}>
      <div className={styles.holder}>
        <div className={styles.title}>{title}</div>
        <div className={styles.tabs}>
          {tab1 && (
            <div className={activeTab === 'tab1' ? styles.active : undefined} onClick={() => setActiveTab('tab1')}>
              {tab1.replace('#1', tab1total || '0')}
            </div>
          )}
          {tab2 && (
            <div className={activeTab === 'tab2' ? styles.active : undefined} onClick={() => setActiveTab('tab2')}>
              {tab2.replace('#2', tab2total || '0')}
            </div>
          )}
        </div>
      </div>

      <div className={styles.table}>
        <FullDataTable
          className={styles.empty}
          definitions={definitions}
          dataType={`super-dashboard-${title}`}
          hideRowsPage={true}
          rowsData={rowsData[activeTab]}
          hideActionControls
          hideActionCheckboxes
          customTableProps={{
            cursorDefault: true,
            disablePointerEffects: true,
          }}
        />
      </div>
      <div className={styles.footer}>
        {/* <div>
                    <Button
                        className={`${styles["choose-action-button"]}`}
                        text="CHOOSE ACTION"
                        color="secondary"
                        size="md"
                        iconEnd="fas fa-sort-down"
                        onClick={(e) => console.log(e.target.value)}
                        dropdownToggleRef={props.dropdownToggleRef || null}
                    />
                </div> */}
        <div></div>
        <div>
          <Button size="md" text="VIEW MORE" iconEnd="fal fa-long-arrow-right" onClick={(e) => navigate(link)} />
        </div>
      </div>
    </div>
  );
};

export default InfoPanel;
