import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { useConfirmModal } from 'hooks';
import AppLayoutWrapFull from 'components/AppLayoutWrapFull';
import Container from 'components/Container/Container';
import PageHeader from 'components/PageHeader/PageHeader';
import AddOrgModal from './AddOrgModal/AddOrgModal';
import AddSiteModal from './AddSiteModal/AddSiteModal';
import PageHeaderActions from 'components/PageHeader/components/PageHeaderActions/PageHeaderActions';
import OrgSiteDeptSites from './OrgSiteDeptSites';
import OrgSiteDeptEditForm from './OrgSiteDeptEditForm';
import { updateOrgAction, updateSiteAction, loadOrgSiteDeptAction, setOrgWizardAction } from 'store/actions/Actions';
import { transformOrganizations, toggleDropdowns } from 'helpers/utils';
import styles from './OrgSiteDept.module.scss';
import EditOrgModal from './EditOrgModal/EditOrgModal';
import BulkUploadModal from './BulkUploadModal/BulkUploadModal';
import Stepper from '../../../components/Stepper/Stepper';
import OrgForm from './Stepper/OrganizationForm/OrganizationForm';
import SitesDeptsTree from './Stepper/SitesDeptsTree/SitesDeptsTree';
import SiteDetailsModal from './Stepper/SiteDetailsModal/SiteDetailsModal';
import UsersModal from './Stepper/UsersModal/UsersModal';
import SubmitForm from './Stepper/SubmitForm/SubmitForm';
import ReactDOM from 'react-dom';
import SiteDeactivationModal from './SiteDeactivationModal/SiteDeactivationModal';
import ActionStatusConstants from 'helpers/ActionStatusConstants';
import Spinner from 'components/Spinner/Spinner';
import AzureADModal from './MicrosoftAzureActiveDirectory/AzureADModal';

const OrgSiteDept = (props) => {
  const [state, setState] = useState(() => ({
    actions: 'Add actions as obj here, or add as separate properties to state',
    selectedRows: null,
    organizations: null,
    sitesData: null,
  }));
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showActsModal, setShowActsModal] = useState(false);
  const [showSiteModal, setShowSiteModal] = useState(false);
  const [showBulkUploadModal, setShowBulkUploadModal] = useState(false);
  const [showOrgWizardPage, setShowOrgWizardPage] = useState(false);
  const [showUsersModal, setShowUsersModal] = useState(false);
  const [showAzureADModal, setShowAzureADModal] = useState(false);
  const [stepperSteps, setStepperSteps] = useState([
    { label: 'Organization', active: false, step: 1 },
    { label: 'Sites, Departments and Users', active: false, step: 2 },
    { label: 'Preview and Save', active: false, step: 3 },
  ]);
  const [currentStep, setCurrentStep] = useState(1);
  const [orgTreeList, setOrgTreeList] = useState([]);
  const [showSiteDetailsModal, setShowSiteDetailsModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);

  const [currentOrg, setCurrentOrg] = useState(null);
  const [isLoading, setLoading] = useState(true);

  const [showSiteDeactivationModal, setShowSiteDeactivationModal] = useState(false);
  const renderSiteDeactivationModal = () => {
    return ReactDOM.createPortal(<SiteDeactivationModal data={showSiteDeactivationModal} setShowModal={setShowSiteDeactivationModal} />, document.getElementById('modal-root'));
  };

  const [showConfirmModal, setShowConfirmModal, renderConfirmModal] = useConfirmModal();

  const formDataOrgInitialState = {
    parent: 0,
    name: '',
    id: '',
    customer_id: '',
    address_1: '',
    type: 'org',
    address_2: '',
    city: '',
    region: '',
    country_code: 235,
    postal_code: '',
    site_limit: '',
    acts: [],
    sites: [],
    users: [],
  };
  const [formDataOrg, setFormDataOrg] = useState(props.DatatableReducer && props.DatatableReducer.orgWizardData ? props.DatatableReducer.orgWizardData : formDataOrgInitialState);

  useEffect(() => {
    if (formDataOrg) {
      props.setOrgWizardAction(formDataOrg);
    }
    const orgList = [
      {
        ...formDataOrg,
        parent: 0,
        id: 1,
        type: 'org',
        name: formDataOrg.name,
        value: formDataOrg.name,
        isEditing: false,
      },
    ];
    formDataOrg.sites.forEach((site) => {
      orgList.push({
        ...site,
      });
      if (site.departments && site.departments.length > 0) {
        site.departments.forEach((department) => {
          orgList.push({
            ...department,
          });
        });
      }
    });
    setOrgTreeList(orgList);
  }, [formDataOrg]);

  const { UserOrgSiteReducer, OrgSiteDeptReducer, loadOrgSiteDeptAction, updateOrgAction, updateSiteAction } = props;

  useEffect(() => {
    if (!OrgSiteDeptReducer.organizations) {
      loadOrgSiteDeptAction();
    }
  }, [OrgSiteDeptReducer.organizations, loadOrgSiteDeptAction]);

  useEffect(() => {
    if (
      OrgSiteDeptReducer &&
      OrgSiteDeptReducer.organizations &&
      UserOrgSiteReducer &&
      UserOrgSiteReducer.selected &&
      UserOrgSiteReducer.selected.org &&
      OrgSiteDeptReducer.organizations[UserOrgSiteReducer.selected.org]
    ) {
      const currentOrg = OrgSiteDeptReducer.organizations[UserOrgSiteReducer.selected.org];
      setCurrentOrg(currentOrg);
      setFormData((oldState) => ({
        ...oldState,
        ...currentOrg,
        company_name: currentOrg.name,
        type: 'org',
      }));
    }
  }, [OrgSiteDeptReducer, UserOrgSiteReducer]);

  useEffect(() => {
    if (OrgSiteDeptReducer.organizations) {
      setState((s) => ({
        ...s,
        organizations: OrgSiteDeptReducer.organizations,
        sitesData: transformOrganizations(OrgSiteDeptReducer.organizations),
      }));
    }
  }, [OrgSiteDeptReducer.organizations, loadOrgSiteDeptAction]);

  useEffect(() => {
    if (OrgSiteDeptReducer.status === ActionStatusConstants.SUCCESS && UserOrgSiteReducer.status === ActionStatusConstants.SUCCESS) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [OrgSiteDeptReducer.organizations, UserOrgSiteReducer]);

  const [formData, setFormData] = useState({
    company_name: '',
    id: '',
    address_1: '',
    type: '',
    address_2: '',
    city: '',
    region: '',
    country_code: '235',
    postal_code: '',
    site_limit: '',
    coach_id: '0',
  });

  const cancelWizardFun = () => {
    props.setOrgWizardAction(null);
    setShowOrgWizardPage(false);
    setFormDataOrg(formDataOrgInitialState);
    setCurrentStep(1);
    setCurrentItem(null);
    const stepsArr = stepperSteps.map((step) => {
      step.active = false;
      return step;
    });
    setStepperSteps(stepsArr);
  };

  const cleanUpWizardDataFun = () => {
    props.setOrgWizardAction(null);
    setFormDataOrg(formDataOrgInitialState);
    setCurrentStep(4);
    setCurrentItem(null);
  };

  const closeWizardFun = () => {
    setShowOrgWizardPage(false);
  };

  const countryDropdownToggleRef = React.createRef();
  const is_super = (UserOrgSiteReducer.selected && UserOrgSiteReducer.selected.is_super && !UserOrgSiteReducer.selected.is_site_admin) || false;
  const is_officer = (UserOrgSiteReducer.selected && UserOrgSiteReducer.selected.is_officer) || false;
  const is_coach = (UserOrgSiteReducer.selected && UserOrgSiteReducer.selected.is_coach) || false;
  // const is_admin = (UserOrgSiteReducer.selected && UserOrgSiteReducer.selected.is_admin) || false;
  const is_site_admin = (UserOrgSiteReducer.selected && UserOrgSiteReducer.selected.is_site_admin) || false;

  const handleSiteDetailsForm = (item) => {
    setShowSiteDetailsModal(true);
    setCurrentItem(item);
  };

  const handleAddUsersForm = (item) => {
    setShowUsersModal(true);
    setCurrentItem(item);
  };

  return (
    <AppLayoutWrapFull onClick={toggleDropdowns([countryDropdownToggleRef])}>
      <PageHeader showStats={true}>
        {!is_officer && (is_super || is_coach) ? (
          <PageHeaderActions
            primaryButtonText="CREATE ORGANIZATION"
            primaryButtonHandler={() => {
              setShowModal(true);
            }}
            secondaryButtonText="CREATE SITE"
            secondaryButtonHandler={() => {
              setShowSiteModal(true);
            }}
            terianryButtonText="EDIT ACTS"
            terianryButtonHandler={() => {
              setShowActsModal(true);
            }}
            terinaryButtonText={is_super ? 'UNASSIGNED USERS' : null}
            terinaryButtonHandler={
              is_super
                ? () => {
                    navigate('/maintain/organization/unassigned');
                  }
                : null
            }
            terinaryButtonIcon="fal fa-empty"
            fourthButtonText="BULK UPLOAD"
            fourthButtonHandler={() => {
              setShowBulkUploadModal(!showBulkUploadModal);
            }}
            fifthButtonText={currentOrg?.sso_enabled ? "AZURE AD" : null}
            fifthButtonHandler={() => setShowAzureADModal(true)}
            // fifthButtonText="ORG WIZARD"
            // fifthButtonHandler={() => {
            //     setShowOrgWizardPage(true);
            //     setCurrentStep(1);
            //     const stepsArr = stepperSteps.map((step) => {
            //         step.active = false;
            //         return step;
            //     });
            //     setStepperSteps(stepsArr);
            // }}
            searchValue={false}
            noFilter={true}
          />
        ) : (
          ''
        )}
        {UserOrgSiteReducer && UserOrgSiteReducer.selected && UserOrgSiteReducer.selected.is_org_admin ? (
          <PageHeaderActions
            fifthButtonText={currentOrg?.sso_enabled ? "AZURE AD" : null}
            fifthButtonHandler={() => setShowAzureADModal(true)}
            searchValue={false}
            noFilter={true}
          />
        ) : (
          ''
        )}
        {UserOrgSiteReducer && UserOrgSiteReducer.selected && UserOrgSiteReducer.selected.is_officer ? (
          <PageHeaderActions
            // primaryButtonText="CREATE ORGANIZATION"
            // primaryButtonHandler={() => {
            //     setShowModal(true);
            // }}
            // secondaryButtonText="CREATE SITE"
            // secondaryButtonHandler={() => {
            //     setShowSiteModal(true);
            // }}
            terianryButtonText="EDIT ACTS"
            terianryButtonHandler={() => {
              setShowActsModal(true);
            }}
            // fourthButtonText="BULK UPLOAD"
            // fourthButtonHandler={() => {
            //     setShowBulkUploadModal(!showBulkUploadModal)
            // }}
            searchValue={false}
            noFilter={true}
          />
        ) : (
          ''
        )}
      </PageHeader>
      {isLoading ? (
        <Spinner />
      ) : (
        <Container wide fluid>
          {showBulkUploadModal && ReactDOM.createPortal(<BulkUploadModal setShowModal={setShowBulkUploadModal} />, document.querySelector('#modal-root'))}
          {showOrgWizardPage ? (
            <div className={styles['organizations-wrap']}>
              <h2>Organization Setup Wizard</h2>
              <Stepper steps={stepperSteps} />
              <div className={styles['org-info-holder']}>
                <div className={styles['org-info']}>
                  {currentStep === 1 && (
                    <OrgForm
                      formDataOrg={formDataOrg}
                      setFormDataOrg={setFormDataOrg}
                      cancelFun={cancelWizardFun}
                      stepsFun={setStepperSteps}
                      steps={stepperSteps}
                      currentStep={currentStep}
                      setCurrentStep={setCurrentStep}
                    />
                  )}
                  {currentStep === 2 && (
                    <SitesDeptsTree
                      sitesData={orgTreeList}
                      formDataOrg={formDataOrg}
                      setFormDataOrg={setFormDataOrg}
                      cancelFun={cancelWizardFun}
                      stepsFun={setStepperSteps}
                      steps={stepperSteps}
                      currentStep={currentStep}
                      setCurrentStep={setCurrentStep}
                      handleSiteDetailsForm={handleSiteDetailsForm}
                      handleAddUsersForm={handleAddUsersForm}
                    />
                  )}
                  {(currentStep === 3 || currentStep === 4) && (
                    <SubmitForm
                      formDataOrg={formDataOrg}
                      cancelFun={cancelWizardFun}
                      stepsFun={setStepperSteps}
                      steps={stepperSteps}
                      currentStep={currentStep}
                      setCurrentStep={setCurrentStep}
                      cleanupFun={cleanUpWizardDataFun}
                      closeFun={closeWizardFun}
                    />
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className={styles['organizations-wrap']}>
              <h2 style={{ fontWeight: 500 }}>Organization / Sites / Departments</h2>
              <div className={styles['org-info-holder']}>
                <div className={styles['org-sites']}>
                  {state.organizations ? (
                    <div>
                      <OrgSiteDeptSites
                        sitesData={state.sitesData}
                        setFormData={setFormData}
                        state={state}
                        setState={setState}
                        isSiteAdmin={is_site_admin}
                        setShowSiteDeactivationModal={setShowSiteDeactivationModal}
                        setShowConfirmModal={setShowConfirmModal}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <div className={styles['org-info']}>
                  <OrgSiteDeptEditForm
                    isCoach={is_coach}
                    isSuper={is_super}
                    isSiteAdmin={is_site_admin}
                    formData={formData}
                    setFormData={setFormData}
                    updateOrgAction={updateOrgAction}
                    updateSiteAction={updateSiteAction}
                    countryDropdownToggleRef={countryDropdownToggleRef}
                    country={formData.country_code}
                    currentOrg={currentOrg}
                  />
                </div>
              </div>
            </div>
          )}
          {showSiteDeactivationModal && renderSiteDeactivationModal()}
          {showConfirmModal && renderConfirmModal(showConfirmModal, setShowConfirmModal)}
        </Container>
      )}
      {showModal ? ReactDOM.createPortal(<AddOrgModal setShowModal={setShowModal} />, document.querySelector('#modal-root')) : null}
      {showSiteModal ? ReactDOM.createPortal(<AddSiteModal setShowModal={setShowSiteModal} />, document.querySelector('#modal-root')) : null}
      {showActsModal ? ReactDOM.createPortal(<EditOrgModal setShowModal={setShowActsModal} />, document.querySelector('#modal-root')) : null}
      {showSiteDetailsModal ? ReactDOM.createPortal(<SiteDetailsModal setShowModal={setShowSiteDetailsModal} formDataOrg={formDataOrg} setFormDataOrg={setFormDataOrg} item={currentItem} />, document.querySelector('#modal-root')) : null}
      {showUsersModal ? ReactDOM.createPortal(<UsersModal setShowModal={setShowUsersModal} formDataOrg={formDataOrg} setFormDataOrg={setFormDataOrg} item={currentItem} />, document.querySelector('#modal-root')) : null}
      {showAzureADModal ? ReactDOM.createPortal(<AzureADModal setShowModal={setShowAzureADModal} />, document.querySelector('#modal-root')) : null}
    </AppLayoutWrapFull>
  );
};

const mapStateToProps = (state) => ({
  OrgSiteDeptReducer: state.OrgSiteDeptReducer,
  UserOrgSiteReducer: state.UserOrgSiteReducer,
  DatatableReducer: state.DatatableReducer,
});

const mapDispatchToProps = {
  updateOrgAction,
  updateSiteAction,
  loadOrgSiteDeptAction,
  setOrgWizardAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrgSiteDept);
