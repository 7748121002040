import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import styles from './AddDeviceModal.module.scss';

import Button from 'components/Button/Button';
import Modal from 'components/Modal/Modal';

import FormElement from 'components/FormElement/FormElement';
import Select from 'components/Select/Select';
import Checkbox from 'components/Checkbox/Checkbox';

import { loadAuditAction, loadUsersAction, createDeviceAction, updateDeviceAction } from 'store/actions/Actions';
import { toggleDropdowns } from 'helpers/utils';

const AddDeviceModal = (props) => {
  const [device, setDevice] = useState({
    id: '',
    site_id: '',
    type: '',
    location: '',
    inventory: '',
    owner: '',
    encrypted: '',
    strong_password: '',
    antivirus: '',
    protection_notes: null,
    notes: '',
  });
  const [isLoaded, setLoaded] = useState(false);

  useEffect(() => {
    if (props.device && !isLoaded) {
      setDevice({
        ...props.device,
        other: props.device.protection_notes ? true : false,
      });
      setLoaded(true);
    }
  }, [props.device, isLoaded]);

  const { UsersReducer, SitesReducer, loadUsersAction, UserOrgSiteReducer, auditId } = props;

  useEffect(() => {
    if (!UsersReducer.users) {
      loadUsersAction();
    }
  }, [UsersReducer.users, loadUsersAction]);

  useEffect(() => {
    if (!props.device) {
      if (UserOrgSiteReducer.selected.site !== device.site_id) {
        setDevice({
          ...device,
          auditId,
        });
      }
    }
  }, [UserOrgSiteReducer.selected, device, auditId, props.device]);

  const [stateErrors, setStateErrors] = useState({
    type: '',
    owner: '',
    protection_notes: '',
  });

  const addDevice = (device) => {
    if (
      !device.site_id ||
      device.site_id === '' ||
      !device.type ||
      device.type === '' ||
      !device.owner ||
      device.owner === '' ||
      !device.location ||
      device.location === '' ||
      (device.other && (!device.protection_notes || device.protection_notes === ''))
    ) {
      setStateErrors({
        ...stateErrors,
        type: !device.type || device.type === '' ? 'Device type is required' : '',
        location: !device.location || device.location === '' ? 'Device location is required' : '',
        owner: !device.owner || device.owner === '' ? 'Device owner is required' : '',
        site_id: !device.site_id || device.site_id === '' ? 'Site is required' : '',
        protection_notes: device.other ? 'Field is required' : '',
      });
      return false;
    }
    props.createDeviceAction({
      ...device,
      protection_notes: device.other ? device.protection_notes : '',
      auditId,
    });
    props.setShowAddDeviceModal(false);
  };

  const saveDevice = (device) => {
    if (
      !device.site_id ||
      device.site_id === '' ||
      !device.type ||
      device.type === '' ||
      !device.owner ||
      device.owner === '' ||
      !device.location ||
      device.location === '' ||
      (device.other && (!device.protection_notes || device.protection_notes === ''))
    ) {
      setStateErrors({
        ...stateErrors,
        type: !device.type || device.type === '' ? 'Device type is required' : '',
        location: !device.location || device.location === '' ? 'Device location is required' : '',
        owner: !device.owner || device.owner === '' ? 'Device owner is required' : '',
        site_id: !device.site_id || device.site_id === '' ? 'Site is required' : '',
        protection_notes: device.other && (!device.protection_notes || device.protection_notes === '') ? 'Field is required' : '',
      });
      return false;
    }
    props.updateDeviceAction({
      ...device,
      protection_notes: device.other ? device.protection_notes : '',
      auditId,
    });
    props.setShowAddDeviceModal(false);
  };

  const deviceTypeRef = React.createRef();
  const deviceOwnerRef = React.createRef();

  const handleChangeAlt = (inputName) => (e) => {
    e.persist();
    setDevice((s) => ({
      ...s,
      [inputName]: e.target.value,
    }));
    setStateErrors((s) => ({ ...s, [inputName]: '' }));
  };

  const handleChange = (e, name) => {
    setDevice({
      ...device,
      [name]:
        e.target.type === 'checkbox'
          ? e.target.checked === true
            ? true
            : false
          : e.target.value || (e.target && e.target.attributes && e.target.attributes.value && e.target.attributes.value.value) || '',
    });
    setStateErrors((s) => ({ ...s, [name]: '' }));
  };

  let usersList = {};
  if (props && props.users) {
    props.users
      .filter((item) => item.registered === 't')
      .forEach((user) => {
        usersList[user.id] = user.name;
      });
  }

  const isEditModal = device && (device.id === '' || !device.id) ? false : true;
  const sitesList = {};
  SitesReducer &&
    SitesReducer.sites &&
    SitesReducer.sites.forEach((site) => {
      sitesList[site.id] = site.name;
    });
  const siteRef = React.createRef();

  return (
    <Modal className={styles['modal']} setShowModal={props.setShowAddDeviceModal}>
      <div className={styles['modal-body']} onClick={toggleDropdowns([deviceTypeRef, deviceOwnerRef, siteRef])}>
        <div>
          <p className={styles['modal-heading']}>{isEditModal ? 'Edit Device' : 'Add New Device'}</p>
        </div>

        <div className={styles['modal-content']}>
          <div>
            <FormElement labelText="SITE" htmlFor="site_id" smallText="*" errorMessage={stateErrors.site_id || ''}>
              <Select labels={sitesList} value={device.site_id} placeholder="Select Site" onClick={(e) => handleChange(e, 'site_id')} dropdownToggleRef={siteRef}>
                {SitesReducer.sites &&
                  SitesReducer.sites.map((site) => (
                    <li key={`siteid-${site.id}`} data-name={site.name} value={site.id}>
                      {site.name}
                    </li>
                  ))}
              </Select>
            </FormElement>

            <br />

            <FormElement labelText="TYPE" htmlFor="type" smallText="*" errorMessage={stateErrors.type}>
              <Select
                labels={['Desktop', 'Laptop', 'Server', 'Phone', 'Tablet', 'Other']}
                name="type"
                value={device.type || ''}
                placeholder="Select Type"
                onClick={(e) => handleChange(e, 'type')}
                dropdownToggleRef={deviceTypeRef}
              >
                <li value="0">Desktop</li>
                <li value="1">Laptop</li>
                <li value="2">Server</li>
                <li value="3">Phone</li>
                <li value="4">Tablet</li>
                <li value="5">Other</li>
              </Select>
            </FormElement>

            <br />

            <FormElement labelText="LOCATION" htmlFor="location" errorMessage={stateErrors.location} smallText="*">
              <input type="text" onChange={handleChangeAlt('location')} value={device.location} />
            </FormElement>

            <br />

            <FormElement labelText="INVENTORY" htmlFor="inventory">
              <input type="text" onChange={handleChangeAlt('inventory')} value={device.inventory} />
            </FormElement>

            <br />

            <FormElement labelText="TECH SUPPORT" htmlFor="owner" smallText="*" errorMessage={stateErrors.owner}>
              <Select labels={usersList} value={device.owner || ''} placeholder="Select Tech Support" onClick={(e) => handleChange(e, 'owner')} dropdownToggleRef={deviceOwnerRef}>
                {props.users &&
                  props.users
                    .filter((item) => item.registered === 't')
                    .map((item) => {
                      return (
                        <li key={item.id} value={item.id}>
                          {item.name}
                        </li>
                      );
                    })}
              </Select>
            </FormElement>
          </div>

          <div>
            <FormElement className={styles.custom_fe} labelText="PROTECTIONS" htmlFor="protection"></FormElement>

            <div className={styles.flex}>
              <FormElement className={styles.custom_fe} labelText="Encrypted" htmlFor="encrypted">
                <Checkbox isSwitch={true} checked={device.encrypted || ''} onChange={(e) => handleChange(e, 'encrypted')} />
              </FormElement>

              <FormElement className={styles.custom_fe} labelText="Strong password" htmlFor="strong_password">
                <Checkbox isSwitch={true} checked={device['strong_password'] || ''} onChange={(e) => handleChange(e, 'strong_password')} />
              </FormElement>

              <FormElement className={styles.custom_fe} labelText="Antivirus/Malware protection" htmlFor="antivirus">
                <Checkbox isSwitch={true} checked={device['antivirus'] || ''} onChange={(e) => handleChange(e, 'antivirus')} />
              </FormElement>

              <FormElement className={styles.custom_fe} labelText="Other forms of protection" htmlFor="other">
                <Checkbox isSwitch={true} checked={device['other'] || ''} onChange={(e) => handleChange(e, 'other')} />
              </FormElement>
            </div>

            {device['other'] && (
              <div className={styles.fe_other}>
                <FormElement labelText="Describe other forms of protection" htmlFor="protection_notes" errorMessage={stateErrors.protection_notes}>
                  <textarea rows="4" className={styles.notes} value={device.protection_notes || ''} onChange={(e) => handleChange(e, 'protection_notes')} />
                </FormElement>
              </div>
            )}

            <div className={styles.fe_notes}>
              <FormElement labelText="NOTES" htmlFor="notes">
                <textarea rows="4" className={styles.notes} value={device.notes || ''} onChange={(e) => handleChange(e, 'notes')} />
              </FormElement>
            </div>
          </div>
        </div>
      </div>

      <div className={styles['modal-footer']}>
        <Button className={styles.button} text="CANCEL" onClick={() => props.setShowAddDeviceModal(false)} color="secondary" />
        {isEditModal ? (
          <Button
            className={styles.button}
            text="SAVE DEVICE"
            onClick={() => {
              saveDevice(device);
            }}
          />
        ) : (
          <Button
            className={styles.button}
            text="ADD DEVICE"
            onClick={() => {
              addDevice(device);
            }}
          />
        )}
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  UsersReducer: state.UsersReducer,
  UserOrgSiteReducer: state.UserOrgSiteReducer,
  SitesReducer: state.SitesReducer,
});

const mapDispatchToProps = {
  loadAuditAction,
  updateDeviceAction,
  loadUsersAction,
  createDeviceAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddDeviceModal);
