// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useState } from 'react';

import Modal from 'components/Modal/Modal';
import Button from 'components/Button/Button';
import Radio from 'components/Radio/Radio';

import { useAppDispatch } from 'hooks';
import { approveDocumentAction } from 'store/actions/Actions';

import styles from './ApproveDocumentModal.module.scss';

const ApproveDocumentModal = (props) => {
  const dispatch = useAppDispatch();

  const [state, setState] = useState({
    requireAttesatation: false,
  });

  const handleChange = (inputName) => (e) => {
    e.persist();

    setState((s) => ({
      ...s,
      [inputName]: e.target.type === 'checkbox' ? e.target.checked : e.target.value === 'true' ? true : e.target.value === 'false' ? false : e.target.value,
    }));
  };

  return (
    <Modal setShowModal={props.setShowModal} className={styles.modal}>
      <div className={styles['modal-header']}>
        <h2 className={styles.heading}>Approve Document</h2>
      </div>

      <div className={styles['modal-body']}>
        <div className={styles['heading-line']}>Does this document require users to attest to it ?</div>
        <div>
          <Radio
            checked={state.requireAttesatation}
            labelText={
              <span>
                <strong>Yes request attestation</strong>
              </span>
            }
            onChange={handleChange('requireAttesatation')}
            value={true}
          />
          <Radio
            checked={!state.requireAttesatation}
            labelText={
              <span>
                <strong>No, do not request attestation</strong>
              </span>
            }
            onChange={handleChange('requireAttesatation')}
            value={false}
          />
          <div className={styles['existing-folder']}></div>
        </div>
      </div>

      <div className={styles['modal-footer']}>
        <Button className={styles.button} text="NEVERMIND" onClick={() => props.setShowModal(false)} color="secondary" />
        <Button
          className={styles.button}
          text="YES, APPROVE"
          onClick={() => {
            if (props.documentForApproval) {
              dispatch(
                approveDocumentAction({
                  id: props.documentForApproval.id,
                  requires_attest: state.requireAttesatation,
                })
              );
              props.setShowModal(false);
            }
          }}
        />
      </div>
    </Modal>
  );
};

export default ApproveDocumentModal;
