// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import styles from './EditTrainingModal.module.scss';
import Modal from 'components/Modal/Modal';
import FormElement from 'components/FormElement/FormElement';
import Select from 'components/Select/Select';
import Button from 'components/Button/Button';
import DateInput from 'components/DateInput/DateInput';
import Checkbox from 'components/Checkbox/Checkbox';
import AddRemoveItems from "components/AddRemoveItems/AddRemoveItems";
import RadioGroup from 'components/RadioGroup/RadioGroup';

import {
  loadActsAction,
  loadSitesAction,
  loadDeptAction,
  loadUsersByOrgAction,
  loadTrainingAction,
  loadTrainingCoursesAction,
  loadFilesAction,
  loadRolesAction,
  updateTrainingAction,
} from 'store/actions/Actions';


const EditTrainingModal = (props) => {
  const [state, setState] = useState({
    site: '',
    due: '',
    reminder: 0,
    selected_course: {},
    selected_site: {
      id: false,
      name: 'All',
    },
    selected_dep: {
      id: false,
      name: 'All',
    },
    current_training: {
      configuration: {},
    },
    users: [],
    regulatory_act: '',
    training_courses: [],
    sites: [],
    selected_roles: {},
    selected_roles_names: undefined,
    frequency: '0',
    lock_tasks: false,
    has_quiz: false,
    launch_date: '',
    reset: false,
    launched: false,
  });

  const [currentTraining, setCurrentTraining] = useState({users: []});

  const [stateErrors, setStateErrors] = useState({
    site: '',
    due: '',
    selected_course: {},
    selected_site: {},
    course: '',
    sites: [],
    training_courses: [],
    role: '',
    launch_date: '',
  });

  const {
    OrgModulesReducer,
    UsersByOrgReducer,
    RolesReducer,
    loadRolesAction,
    loadUsersByOrgAction,
    loadFilesAction,
    loadSitesAction,
    files,
    SitesReducer,
    DepartmentsReducer,
    loadDeptAction,
    training_id,
    updateTrainingAction,
    loadTrainingAction,
    TrainingReducer,
    loadTrainingCoursesAction,
    training_courses,
  } = props;

  const [isUserSelectionVisible, setIsUserSelectionVisible] = useState('0');

  useEffect(() => {
    if (currentTraining?.users?.length > 0) {
      setIsUserSelectionVisible('1')
    }
  }, [currentTraining]);

  useEffect(() => {
    if (state.selected_site.id) {
      loadDeptAction({ site: state.selected_site.id });
    }
  }, [state.selected_site.id])

  useEffect(() => {
    if (!files) {
      loadFilesAction();
    }
  }, [files, loadFilesAction]);

  useEffect(() => {
    if (!training_courses) {
      loadTrainingCoursesAction();
    }
  }, [training_courses, loadTrainingCoursesAction]);

  useEffect(() => {
    if (!TrainingReducer.training) {
      loadTrainingAction();
      return;
    }
    setCurrentTraining(TrainingReducer.training.find(training => training.id === training_id));
  }, [TrainingReducer.training, loadTrainingAction]);

  useEffect(() => {
    if (!SitesReducer.sites) {
      loadSitesAction();
    }
  }, [SitesReducer.sites, loadSitesAction]);

  useEffect(() => {
    if (!RolesReducer.roles) {
      loadRolesAction();
    }
  }, [RolesReducer.roles, loadRolesAction]);

  let roleList = {};
  if (RolesReducer && RolesReducer.roles) {
    RolesReducer.roles.map((role) => {
      if (role.name && !role.name.includes('Super') && !role.name.includes('Coach')) {
        roleList[role.id] = { id: role.id, name: role.name };
      }
      return false;
    });
  }

  useEffect(() => {
    try {
      if (state.current_training.role_id) {
        let newRoleNames = '';
        const rolesArray = state.current_training.role_id.split(',').map(Number);
        // generate role string name
        Object.values(rolesArray).forEach((key) => {
          if (roleList[key]) {
            newRoleNames = `${newRoleNames}, ${roleList[key].name}`;
          }
        });
        // trim leading space and commma
        newRoleNames = newRoleNames.substring(2);
        setState((s) => ({
          ...s,
          selected_roles_names: newRoleNames,
        }));
      }
    } catch (error) {
      console.log(error, 'Error in loading roles for training!');
    }
  }, [state.current_training]);

  useEffect(() => {
    loadUsersByOrgAction({
      site: state.selected_site.id ? String(state.selected_site.id) : undefined,
      department: state.selected_dep.id ? String(state.selected_dep.id) : undefined,
      role: state.selected_roles !== {} ? Object.keys(state.selected_roles).map(Number) : undefined,
    });
  }, [state.selected_site.id, state.selected_dep.id, state.selected_roles]);

  // useEffect(() => {
  //     if (!DepartmentsReducer.departments && SitesReducer.sites) {
  //         loadDeptAction({ site: state.selected_site.id });
  //     }
  // }, [DepartmentsReducer.departments, loadDeptAction, SitesReducer.sites, state.selected_site.id]);

  useEffect(() => {
    if (!TrainingReducer.training) return;
    const current_training = TrainingReducer.training.filter((training) => training.id === training_id)[0];
    if (!current_training) return; // wrong id
    let department_name = 'All';
    if (DepartmentsReducer.departments) {
      const filtered_dep = DepartmentsReducer.departments.filter((department) => department.id === current_training.department);
      if (filtered_dep && filtered_dep[0] && filtered_dep[0].name) {
        department_name = filtered_dep[0].name;
      }
    }
    setState((s) => ({
      ...s,
      reminder: current_training.reminder || 0,
      current_training: current_training,
      selected_course: {
        id: current_training.document_id,
        name: current_training.folder_name,
      },
      selected_site: {
        id: current_training.site || 0,
        name: current_training.siteName || 'All',
      },
      selected_dep: {
        id: current_training.department || false,
        name: department_name || 'All',
      },
      regulatory_act: current_training.regulatory_act,
      users: current_training.users || [],
      launch_date: current_training.launch_date,
      due: current_training.due,
      has_quiz: current_training.has_quiz,
      frequency: current_training.configuration && current_training.configuration.frequency,
      lock_tasks: current_training.configuration && current_training.configuration.lock_tasks,
      reset: current_training.configuration.reset,
      launched: current_training.configuration.launched,
    }));
  }, [TrainingReducer.training, DepartmentsReducer.departments, training_id]);

  useEffect(() => {
    if (state.due) setStateErrors((s) => ({ ...s, due: '' }));
  }, [state.due]);

  const handleCourseChange = (e) => {
    setState((s) => ({
      ...s,
      selected_course: {
        id: e.target.value,
        name: e.target.dataset.name,
      },
    }));
    setStateErrors({
      ...stateErrors,
      course: '',
    });
  };

  const handleSiteChange = (e) => {
    setState((s) => ({
      ...s,
      users: [],
      selected_site: {
        id: e.target.value,
        name: e.target.dataset.name,
      },
      selected_dep: {
        id: false,
        name: 'All',
      },
    }));

    loadDeptAction({ site: e.target.value });
    setStateErrors({
      ...stateErrors,
      site: '',
    });
  };

  const handleDepChange = (e) => {
    setState((s) => ({
      ...s,
      users: [],
      selected_dep: {
        id: e.target.value,
        name: e.target.dataset.name,
      },
    }));

    setStateErrors({
      ...stateErrors,
      dep: '',
    });
  };

  const handleRoleChange = (e) => {
    if (!e.target.value) {
      return false;
    }

    const role_id = e.target.value;
    const role_name = e.target.name;
    const role_active = e.target.checked;
    const newRoles = Object.assign({}, state.selected_roles);

    // update new roles
    if (role_id && role_name) {
      if (role_active) {
        // add
        newRoles[role_id] = true;
      } else {
        // remove
        delete newRoles[role_id];
      }
    }

    let newRoleNames = '';

    // generate role string name
    Object.keys(newRoles).forEach((key) => {
      if (roleList[key] && newRoles[key]) {
        newRoleNames = `${newRoleNames}, ${roleList[key].name}`;
      }
    });
    // trim leading space and commma
    newRoleNames = newRoleNames.substring(2);

    setState((s) => ({
      ...s,
      users: [],
      selected_roles: newRoles,
      selected_roles_names: newRoleNames,
    }));

    setStateErrors({
      ...stateErrors,
      role: '',
    });
  };

  const handleChange = (inputName) => (e) => {
    setState((s) => ({
      ...s,
      [inputName]: e.target.value,
    }));
    setStateErrors((s) => ({ ...s, [inputName]: '' }));
  };

  const handleChangeFreq = (inputName) => (e) => {
    setState((s) => ({
      ...s,
      [inputName]: e.target.value,
      due: e.target.value === 0 ? state.due : moment(state.launch_date, 'YYYY-MM-DD').add(e.target.value, 'month').format('YYYY-MM-DD'),
    }));
    setStateErrors((s) => ({ ...s, [inputName]: '' }));
  };

  const handleChangeLaunch = (inputName) => (e) => {
    setState((s) => ({
      ...s,
      [inputName]: e.target.value,
      due: state.frequency === 0 ? state.due : e.target.value && moment(e.target.value, 'YYYY-MM-DD').add(state.frequency, 'month').format('YYYY-MM-DD'),
    }));
    setStateErrors((s) => ({ ...s, [inputName]: '' }));
  };

  const handleCheckbox = (inputName) => (e) => {
    setState((s) => ({
      ...s,
      [inputName]: e.target.checked,
    }));
  };

  const saveTraining = async () => {
    if (!state.due || !state.launch_date) {
      setStateErrors({
        ...stateErrors,
        due: !state.due ? 'Due date is required' : '',
        launch_date: !state.launch_date ? 'Launch date is required' : '',
      });
      return;
    }

    try {
      const course = {
        id: training_id,
        reminder: state.reminder,
        training_course: state.selected_course.id,
        assigned: state.current_training.assigned,
        department: state.selected_dep.id,
        due: state.due,
        site: state.selected_site.id,
        user: state.users,
        completed: state.current_training.completed === 't' ? true : false,
        launch_date: state.launch_date,
        configuration: {
          lock_tasks: state.frequency == 0 ? false : state.lock_tasks,
          frequency: state.frequency,
        },
      };
      await updateTrainingAction(course);
      props.setShowModal(false);
      // navigate(`/achieve/training`);
    } catch (error) {
      console.log(error);
      console.log('Error in updateTrainingAction');
    }
  };

  const courseDropdownRef = useRef();
  const siteDropdownRef = useRef();
  const roleDropdownRef = useRef();
  const roleRef = useRef();
  const depDropdownRef = useRef();
  const userDropdownRef = useRef();
  const reminderDropdownRef = useRef();

  const toggleRoleDropdown = (e) => {
    if (courseDropdownRef?.current?.classList?.contains('show')) {
      courseDropdownRef.current.click();
    }
    if (siteDropdownRef?.current?.classList?.contains('show')) {
      siteDropdownRef.current.click();
    }
    if (depDropdownRef?.current?.classList?.contains('show')) {
      depDropdownRef.current.click();
    }
    if (userDropdownRef?.current?.classList?.contains('show')) {
      userDropdownRef.current.click();
    }
    if (reminderDropdownRef?.current?.classList?.contains('show')) {
      reminderDropdownRef.current.click();
    }
    if (roleDropdownRef?.current?.classList?.contains('show') && !(roleRef.current && roleRef.current.contains(e.target))) {
      roleDropdownRef.current.click();
    }
  };

  let usersList = {};
  usersList[0] = 'All';
  if (UsersByOrgReducer && UsersByOrgReducer.users) {
    UsersByOrgReducer.users.map((user) => {
      usersList[user.user_id] = user.name || user.email;
      return false;
    });
  }

  const reminderList = {
    0: 'None',
    1: 'Quarter',
    2: 'Half',
    3: '3 Quarters',
  };

  const frequencyListLabels = {
    0: 'One Time',
    1: 'Monthly',
    3: 'Quarterly',
    6: 'Biannually',
    12: 'Annually',
  };

  return (
    <Modal className={styles.modal} setShowModal={props.setShowModal}>
      <div onClick={toggleRoleDropdown}>
        <p className={styles['modal-heading']}>Edit Training</p>

        <div className={styles['modal-content']}>
          <FormElement labelText="REGULATORY ACT" htmlFor="regulatory_act">
            <Select disabled placeholder="Select regulatory act" value={state.regulatory_act}>
              {OrgModulesReducer &&
                OrgModulesReducer.data &&
                OrgModulesReducer.data.map((d) => (
                  <li key={`regid-${d.name.toLowerCase()}`} value={d.name}>
                    {d.name}
                  </li>
                ))}
            </Select>
          </FormElement>

          <br />

          <div className={styles.holder}>
            <FormElement labelText="SITE" htmlFor="site" errorMessage={stateErrors.site}>
              <Select disabled value={state.selected_site.name} placeholder="Select Site" onClick={handleSiteChange} dropdownToggleRef={siteDropdownRef}>
                <li key={`siteid-all`} data-name="All" value={0}>
                  All
                </li>
                {SitesReducer &&
                  SitesReducer.sites &&
                  SitesReducer.sites.map((site) => (
                    <li key={`siteid-${site.id}`} data-name={site.name} value={site.id}>
                      {site.name}
                    </li>
                  ))}
              </Select>
            </FormElement>

            <FormElement
              className={styles.department}
              labelText="DEPARTMENT"
              htmlFor="department"
              // errorMessage={stateErrors.site}
            >
              <Select disabled value={state.selected_dep.name} placeholder="Select Department" onClick={handleDepChange} dropdownToggleRef={depDropdownRef}>
                <li key={`depid-all`} data-name={'All'} value={'0'}>
                  All
                </li>
                {DepartmentsReducer &&
                  DepartmentsReducer.departments &&
                  DepartmentsReducer.departments.map((department) => (
                    <li key={`siteid-${department.id}`} data-name={department.name} value={department.id}>
                      {department.name}
                    </li>
                  ))}
              </Select>
            </FormElement>
          </div>

          <br />

          <FormElement labelText="COURSE NAME" htmlFor="course" errorMessage={stateErrors.course}>
            <Select disabled value={state.selected_course.name} placeholder="Select course" onClick={handleCourseChange} dropdownToggleRef={courseDropdownRef}>
              {files &&
                files.map((file) => {
                  return (
                    <li key={`courseid-${file.id}`} data-name={file.folder_name} value={file.id}>
                      {file.path.replace(/[/]$/, '')}
                    </li>
                  );
                })}
            </Select>
          </FormElement>

          {state.has_quiz === 't' && (
            <div className={styles.holder}>
              <Checkbox disabled labelText="Quiz" checked={state.has_quiz} onChange={handleCheckbox('has_quiz')} className={styles['complete']} />
            </div>
          )}

          <br />

          <div className={`${styles.holder}`}>
            <FormElement labelText="ROLE" htmlFor="role" errorMessage={stateErrors.role}>
              <div ref={roleRef} name="roleRef">
                <Select disabled multiple value={state.selected_roles_names} placeholder="Select Roles" onClick={handleRoleChange} dropdownToggleRef={roleDropdownRef}>
                  {Object.keys(roleList).map((key) => (
                    <Checkbox checked={roleList[key].value} name={roleList[key].name} labelText={roleList[key].name} key={roleList[key].id} value={roleList[key].id} />
                  ))}
                </Select>
              </div>
            </FormElement>

            <FormElement className={styles.custom_radio_group} labelText="" htmlFor="users">
              <div style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: 10 }}>
                <RadioGroup
                  items={[
                    {
                      label: 'All Users',
                      value: '0',
                    },
                    {
                      label: 'Individual',
                      value: '1',
                    },
                  ]}
                  disabled={true}
                  value={isUserSelectionVisible}
                  name="users"
                />
              </div>
            </FormElement>
          </div>

          <br />

          {isUserSelectionVisible === '1' && <div className={`${styles.items}`}>
              <AddRemoveItems
                  allItemsTitle="ALL USERS"
                  addedItemsTitle="ADDED USERS"
                  searchPlaceholder="Search users..."
                  allItems={currentTraining?.users?.map((user, index) => ({id: index.toString(), label: user, added: true}))}
                  disabled={true}
              />
          </div>}

          <br />

          <div className={styles.holder}>
            <FormElement labelText="FREQUENCY" htmlFor="frequency">
              <Select
                disabled={state.reset || state.launched || state.current_training.configuration.status === '6'}
                value={state.frequency}
                name="frequency"
                onClick={handleChangeFreq('frequency')}
                labels={frequencyListLabels}
              >
                {Object.entries(frequencyListLabels).map((frequency) => (
                  <li key={frequency[0]} value={frequency[0]}>
                    {frequency[1]}
                  </li>
                ))}
              </Select>
            </FormElement>

            <FormElement labelText="LAUNCH DATE" htmlFor="launch_date" className={styles.reminder} errorMessage={stateErrors.launch_date}>
              <DateInput
                disabled={state.reset || state.launched || state.current_training.configuration.status === '6'}
                onChange={handleChangeLaunch('launch_date')}
                value={state.launch_date}
              />
            </FormElement>
          </div>

          <br />

          <div className={styles.holder}>
            <FormElement labelText="DUE DATE" htmlFor="due" smallText="*" errorMessage={stateErrors.due}>
              <DateInput
                disabled={
                  (state.current_training && state.current_training.completed === 't') ||
                  (state.frequency !== '0' && state.frequency !== 0) ||
                  state.current_training.configuration.status === '6'
                    ? true
                    : false
                }
                onChange={handleChange('due')}
                value={state.due}
              />
            </FormElement>

            <FormElement className={styles.reminder} labelText="REMINDER" htmlFor="reminder">
              <Select
                disabled={(state.current_training && state.current_training.completed === 't') || state.current_training.configuration.status === '6' ? true : false}
                labels={reminderList}
                value={String(state.reminder)}
                name="reminder"
                onClick={handleChange('reminder')}
                dropdownToggleRef={reminderDropdownRef}
              >
                <li key={`reminder-0`} value={'0'}>
                  None
                </li>
                <li key={`reminder-1`} value={'1'}>
                  Quarter
                </li>
                <li key={`reminder-2`} value={'2'}>
                  Half
                </li>
                <li key={`reminder-3`} value={'3'}>
                  3 Quarters
                </li>
              </Select>
            </FormElement>
          </div>

          <div className={styles.holder}>
            <Checkbox
              labelText="Lock tasks"
              disabled={state.frequency == 0 || state.current_training.configuration.status === '6' ? true : false}
              checked={state.frequency == 0 ? false : state.lock_tasks}
              onChange={handleCheckbox('lock_tasks')}
              className={styles['complete']}
            />
          </div>
        </div>

        <div className={styles['modal-footer']}>
          <Button text={state.current_training && state.current_training.completed === 'f' ? 'CANCEL' : 'CLOSE'} color="secondary" onClick={() => props.setShowModal(false)} />
          {state.current_training && state.current_training.completed === 'f' && !state.reset && (
            <Button disabled={state.current_training.configuration.status === '6' ? true : false} text="SAVE TRAINING" className={styles.create} onClick={saveTraining} />
          )}
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = ({ ActsReducer, SitesReducer, UsersByOrgReducer, DepartmentsReducer, TrainingReducer, FilesReducer, RolesReducer, OrgModulesReducer }) => ({
  ActsReducer,
  SitesReducer,
  UsersByOrgReducer,
  TrainingReducer,
  DepartmentsReducer,
  files: FilesReducer.files,
  RolesReducer,
  OrgModulesReducer,
});

const mapDispatchToProps = {
  loadActsAction,
  loadSitesAction,
  loadUsersByOrgAction,
  loadDeptAction,
  loadTrainingAction,
  loadTrainingCoursesAction,
  loadFilesAction,
  loadRolesAction,
  updateTrainingAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditTrainingModal);
