import React, { useState } from 'react';
import PhoneInput, { isPossiblePhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import FormElement from 'components/FormElement/FormElement';
import { useAppDispatch } from 'hooks';
import { activateMFAAction } from 'store/actions/Actions';
import { isEmailValid } from 'helpers/utils';

import { Button } from 'components/Button/Button';

import TypeIcon from './TypeIcon';

import 'react-phone-number-input/style.css';
import styles from './Method.module.scss';

function ActiveMethod(props) {
  const { type, userId, userValue, onCancel } = props;

  const dispatch = useAppDispatch();

  const [value, setValue] = useState('');
  const [valueError, setValueError] = useState('');

  const onChangeValue = (value) => {
    setValue(value);
    setValueError('');
  };

  const sendVerificationCode = (e) => {
    e.preventDefault();
    if (type === 'email') {
      if (value === '' || !isEmailValid(value)) {
        setValueError(value.length > 0 ? 'Invalid email address' : 'Email address is required');
      } else {
        dispatch(
          activateMFAAction({
            type,
            user_id: userId,
            email: value,
          })
        );
      }
    }
    if (type === 'phone') {
      if (value) {
        if (isPossiblePhoneNumber(value)) {
          const phone = parsePhoneNumber(value);
          dispatch(
            activateMFAAction({
              type,
              user_id: userId,
              phone: phone.nationalNumber,
              phone_prefix: `+${phone.countryCallingCode}`,
            })
          );
        } else {
          setValueError('Invalid phone number');
        }
      } else {
        setValueError('Phone number required');
      }
    }
  };

  return (
    <div className={styles.form}>
      <TypeIcon type={type} />
      {type === 'email' ? (
        <FormElement className={styles.form_element} htmlFor="email" errorMessage={valueError}>
          <input autoComplete="none" name="email" type="email" placeholder="Enter email address" value={value} onChange={(e) => onChangeValue(e.target.value)} />
        </FormElement>
      ) : undefined}

      {type === 'phone' ? (
        <FormElement className={styles.form_element} htmlFor="phone" errorMessage={valueError}>
          <PhoneInput className={styles.PhoneInput} defaultCountry="US" placeholder="Enter phone number" value={value} onChange={(value) => onChangeValue(value)} />
        </FormElement>
      ) : undefined}
      <Button size="md" text="Send verification code" onClick={sendVerificationCode} />
      {!userValue && <Button size="md" text="Cancel" color="secondary" className={styles.cancel} onClick={onCancel} />}
    </div>
  );
}

export default ActiveMethod;
