import React from 'react';

import hipaaSealLogo from 'assets/img/cg-seal-logo.png';

import styles from './Footer.module.scss';

function Footer() {
  return (
    <footer className={styles.footer} data-testid="footer">
      <img className={styles['hipaa-seal-logo']} src={hipaaSealLogo} alt="HIPAA Seal of Compliance Logo" />
      <div className={styles.copyright}>
        <span>The Guard - Version 4.1</span>
        <span>Copyright &#169; 2023 Compliancy Group, LLC. All Rights Reserved</span>
      </div>
      <button className={styles['screen-tutorial-button']} onClick={() => window.open('https://compliancygroup.freshdesk.com/support/home')}>
        <i className="fas fa-info-circle" />
        SUPPORT
      </button>
      <div />
    </footer>
  );
}

export default Footer;
