import React, { useEffect, useState, Dispatch } from 'react';

import InitialMethod from './InitialMethod';
import ActiveMethod from './ActiveMethod';
import NotVerifiedMethod from './NotVerifiedMethod';
import VerifiedMethod from './VerifiedMethod';

interface IProps {
  type: 'email' | 'phone';
  user: {
    id: string;
    mfa: {
      code_expire_at: string | undefined;
      verifiable_by: {
        type: string;
        value: string;
        email_activated_at: string | undefined;
        phone_activated_at: string | undefined;
      }[];
    };
  };
  counter?: number | undefined;
  setCounter?: Dispatch<any>;
}

function Method(props: IProps) {
  const { type, user, counter, setCounter } = props;

  const [status, setStatus] = useState('off');
  const [data, setData] = useState({
    value: '',
    email_activated_at: undefined,
    phone_activated_at: undefined,
  });

  useEffect(() => {
    if (user?.mfa?.verifiable_by) {
      const tmp = user?.mfa?.verifiable_by.find((item) => item.type === type);
      if (tmp) {
        setData(tmp);
        setStatus('off');
      } else {
        setStatus('off');
        setData({
          value: '',
          email_activated_at: undefined,
          phone_activated_at: undefined,
        });
      }
    }
  }, [user]);

  const onCancel = () => {
    setStatus('off');
  };

  const onInitType = () => {
    setStatus('init');
  };

  const isActiveType = () => {
    if (type === 'email') {
      return !!data.email_activated_at;
    }
    if (type === 'phone') {
      return !!data.phone_activated_at;
    }
    return false;
  };

  if (status === 'off' && !data.value && !isActiveType()) {
    return <InitialMethod type={type} onInitType={onInitType} />;
  }
  if (status === 'init' && !isActiveType()) {
    return <ActiveMethod type={type} userId={user?.id} userValue={data.value} onCancel={onCancel} />;
  }
  if (data.value && !isActiveType()) {
    return <NotVerifiedMethod type={type} value={data.value} userId={user?.id} onInitType={onInitType} counter={counter} setCounter={setCounter} />;
  }
  if (data.value && isActiveType()) {
    return <VerifiedMethod type={type} value={data.value} userId={user?.id} />;
  }
}

export default Method;
