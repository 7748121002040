// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import MainLayout from 'v2/layouts/MainLayout/MainLayout';
import PageLayout from 'v2/layouts/PageLayout/PageLayout';
import MessageBox from 'components/MessageBox/MessageBox';
import { Button } from 'components/Button/Button';
import Modal from 'components/Modal/Modal';
import HR from 'components/HR/HR';
import { toggleDropdowns } from 'helpers/utils';
import { useAppDispatch, useAppSelector } from 'hooks';
import { loadOrgSiteDeptAction, loadRolesAction, createUsersBulkAction } from 'store/actions/Actions';
import modalStyles from 'components/ConfirmationModal/ConfirmationModal.module.scss';

import AccessPermissions from '../components/AccessPermissions/AccessPermissions';
import UserEmailAddresses from '../components/UserEmailAddresses/UserEmailAddresses';
import { generateOrgList, getInitState, getItemsList } from '../accessUtils';

import styles from './Add.module.scss';

function Add() {
  const dispatch = useAppDispatch();
  const CreateUsersBulkReducer = useAppSelector((state) => state.CreateUsersBulkReducer);
  const RolesReducer = useAppSelector((state) => state.RolesReducer);
  const OrgSiteDeptReducer = useAppSelector((state) => state.OrgSiteDeptReducer);
  const UserOrgSiteReducer = useAppSelector((state) => state.UserOrgSiteReducer);

  const [emails, setEmails] = useState('');
  const [stateErrors, setStateErrors] = useState({
    access: '',
    emails: '',
  });
  const navigate = useNavigate();

  const steps = [
    'Select Access Permissions for the Users you wish to notify of the requirement to register. Selections apply to all Users included in the next step, but may be edited later.',
    'Paste a list of User email addresses (comma separated). Users will be notified via email of their requirement to register once you click "Save".',
    'Click "Save"',
  ];

  useEffect(() => {
    if (!RolesReducer.roles) {
      dispatch(loadRolesAction({}));
    }
  }, [RolesReducer.roles]);

  useEffect(() => {
    if (!OrgSiteDeptReducer.organizations) {
      dispatch(loadOrgSiteDeptAction({}));
    }
  }, [OrgSiteDeptReducer.organizations]);

  const orgList = [];
  if (OrgSiteDeptReducer.organizations) {
    let keyId = 0;
    for (const [idx, org] of Object.values(OrgSiteDeptReducer.organizations).entries()) {
      keyId += 1;
      orgList.push({
        key: keyId,
        id: org.id,
        name: org.name,
        sites: [],
      });
      if (org.sites) {
        for (const [idz, site] of Object.values(org.sites).entries()) {
          keyId += 1;
          orgList[idx].sites.push({
            key: keyId,
            id: site.id,
            name: site.name,
            deps: [],
          });
          for (const dep of Object.entries(site.departments)) {
            keyId += 1;
            orgList[idx].sites[idz].deps.push({
              key: keyId,
              id: dep[0],
              name: dep[1],
            });
          }
        }
      }
    }
  }

  const handleSubmitAddBulkForm = (e) => {
    try {
      e.preventDefault();
      const access = [];
      let errorAccess = false;
      const errorDepartment = false;
      const rolesElemnets = document.querySelectorAll('[name=role]');
      if (rolesElemnets.length === 0) {
        errorAccess = true;
      }
      for (const item of rolesElemnets) {
        if (item.value === '0') {
          errorAccess = true;
        }
        access.push({
          role_id: item.value,
          organization: item.dataset.org,
          site: item.dataset.site || undefined,
          department: item.dataset.dep || (item.dataset.partial === 'true' && 'partial') || undefined,
        });
      }
      let errorEmails = false;
      let emails_string = document.querySelector('[name=users-emails]').value;
      // remove whitespace
      emails_string = emails_string.replace(/\s+/g, ',');
      emails_string = emails_string.replace(/\n/g, ',');
      emails_string = emails_string.replace(/,+/g, ',');
      emails_string = emails_string.replace(/,\s*$/, '');
      const emails = emails_string.split(',');
      for (const email of emails) {
        if (!email.includes('@')) {
          errorEmails = true;
        }
      }

      // const userRoleId = RolesReducer.roles.filter(role => role.name.includes("User"))[0].id || "";
      // const partials = access.filter(elem => (elem.department === "partial" && elem.role_id === userRoleId)) || [];
      // // foreach user partial, check for childrens, if any of partial does not have children it's error // all site for user should have at least one dep selected
      // partials.forEach(partial => {
      //   const total_departments = access.filter(acc => acc.site === partial.site).length || 0;
      //   if (total_departments < 2) {
      //     errorAccess = true;
      //     errorDepartment = true;
      //   }
      // });

      if (errorAccess || errorEmails) {
        setStateErrors((s) => ({
          ...s,
          access: errorAccess ? (errorDepartment ? 'At least one department per site is required!' : 'Access role(s) are required!') : '',
          emails: errorEmails ? 'Error with emails' : '',
        }));
      } else {
        dispatch(createUsersBulkAction({ access, emails }));
      }
    } catch {
      // eslint-disable-next-line no-console
      console.log('error submitting bulk form');
    }
  };

  const [localBoxes, setLocalBoxes] = useState({});

  const orgs = generateOrgList(OrgSiteDeptReducer.organizations);
  const items = getItemsList(orgs);
  const initState = {
    ...getInitState({}, items, RolesReducer.roles),
    ...localBoxes,
  };

  const stateDropdownRef = React.createRef();
  const selectOrgDropdownToggleRef = React.createRef();

  // eslint-disable-next-line consistent-return
  const renderModal = () => {
    if (CreateUsersBulkReducer && CreateUsersBulkReducer.message) {
      return (
        <Modal className={modalStyles.modal} disableCloseButton>
          <Modal.Heading>Results:</Modal.Heading>
          <div className={modalStyles['modal-content']}>
            {/* eslint-disable-next-line react/no-array-index-key */}
            {CreateUsersBulkReducer.message.split('\\n').map((str, key) => (
              <div key={key}>{str}</div>
            ))}
          </div>

          <Modal.Footer>
            <Button text="OK" color="primary" className={styles.create} onClick={() => navigate('/people')} />
          </Modal.Footer>
        </Modal>
      );
    }
  };

  return (
    <MainLayout>
      <PageLayout>
        <div onClick={toggleDropdowns([stateDropdownRef, selectOrgDropdownToggleRef])}>
          <div className={styles.title}>
            <h3>Add Bulk</h3>
          </div>
          <div className={styles.formWrapper}>
            {renderModal()}
            <div>
              <form className={styles['add-user-form']}>
                <div className={styles.holder}>
                  <div className={styles.left}>
                    <MessageBox boldedText="Step One: " className={styles['step-one-box']} text={steps[0]} />

                    <AccessPermissions
                      // className={styles["access-permissions"]}
                      // options={orgList}
                      // error={stateErrors.access}
                      // setStateErrors={setStateErrors}
                      // roles={RolesReducer.roles}
                      // placeholder="The Business Company"
                      className={styles['access-permissions']}
                      organizations={OrgSiteDeptReducer.organizations}
                      initState={initState}
                      orgs={orgs}
                      userOrgs={UserOrgSiteReducer.organizations}
                      selected={UserOrgSiteReducer.selected}
                      setLocalBoxes={setLocalBoxes}
                      loadOrgSiteDeptAction={loadOrgSiteDeptAction}
                      roles={RolesReducer.roles}
                      error={stateErrors.access}
                      setStateErrors={setStateErrors}
                      placeholder="The Business Company"
                      selectOrgDropdownToggleRef={selectOrgDropdownToggleRef}
                    />
                  </div>

                  <div className={styles.right}>
                    <MessageBox boldedText="Step Two: " className={styles['step-two-box']} text={steps[1]} />
                    <UserEmailAddresses state={emails} error={stateErrors.emails} setStateErrors={setStateErrors} setState={setEmails} />

                    <MessageBox boldedText="Step Three: " className={styles['step-three-box']} text={steps[2]} />
                  </div>
                </div>
                <HR className={styles.hr} />

                <div className={styles['submit-box']}>
                  <Button text="Cancel" color="secondary" onClick={() => navigate('/people')} />
                  <Button icon="far fa-save" text="Save" onClick={handleSubmitAddBulkForm} />
                </div>
              </form>
            </div>
          </div>
        </div>
      </PageLayout>
    </MainLayout>
  );
}

export default Add;
