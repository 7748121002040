import { put, call, takeLatest, delay, select } from 'redux-saga/effects';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { actionApiCall } from 'helpers/api/ApiCalls';
import * as sagaConstants from 'helpers/constants/SagasConstants';

const getItems = (state) => state.SitesReducer.sites;

function transformTasks(tasks, sites) {
  const result = tasks.map((task) => {
    task.first = 'view';
    // status
    if (task.completed === 't') {
      task.statusType = 'success';
      task.statusLabel = 'COMPLETE';
      task.buttonLabel = 'VIEW';
      task.status = 'Complete';
    } else {
      // if ((task.subtask_data && task.subtask_data.requires_attest === "t") || task.subtask === 'auditResponse' || task.subtask === 'audit' || task.subtask === 'remediation') {
      //     task.statusType = 'fail';
      //     task.statusLabel = 'INCOMPLETE';
      //     task.status = 'Incomplete';
      // } else {
      //     task.statusType = 'secondary';
      //     task.statusLabel = 'OPTIONAL';
      //     task.status = 'Optional';
      // }

      task.statusType = 'fail';
      task.statusLabel = 'INCOMPLETE';
      task.status = 'Incomplete';

      task.buttonLabel = 'VIEW';
    }

    if (task.subtask === 'auditResponse') {
      // question
      task.type = 'question';
      task.subtype = 'question';
      task.typeLabel = 'Questions';
      task.buttonLabel = 'ANSWER';
      if (task.completed === 't') {
        task.first = 'finalize';
        task.buttonLabel = 'VIEW';
      }
    } else if (task.subtask_data && task.subtask_data.requires_attest === 't') {
      // training
      task.type = 'training';
      task.typeLabel = 'Trainings';
      task.buttonLabel = 'ATTEST';

      if (task.subtask_data.understood === 't') {
        task.statusType = 'success';
        task.statusLabel = 'COMPLETE';
        task.buttonLabel = 'VIEW';
        task.status = 'Complete';
      } else {
        task.statusType = 'fail';
        task.statusLabel = 'INCOMPLETE';
        task.buttonLabel = 'VIEW';
        task.status = 'Incomplete';
      }

      if (task.task.includes('.mp4')) {
        task.subtype = 'video';
        task.subtypeLabel = 'video';
      } else {
        task.subtype = 'document';
        task.subtypeLabel = 'Document';
      }
    } else if (task.subtask_data && task.subtask_data.requires_attest === 'f') {
      // document
      task.type = 'document';
      task.typeLabel = 'Documents';
      task.buttonLabel = 'VIEW';

      if (task.completed === 't') {
        task.statusType = 'success';
        task.statusLabel = 'COMPLETE';
        task.buttonLabel = 'VIEW';
        task.status = 'Complete';
      } else {
        task.statusType = 'fail';
        task.statusLabel = 'INCOMPLETE';
        task.buttonLabel = 'VIEW';
        task.status = 'Incomplete';
      }

      if (task.task.includes('.mp4')) {
        task.subtype = 'video';
        task.subtypeLabel = 'Video';
      } else {
        task.subtype = 'document';
        task.subtypeLabel = 'Document';
      }
    } else {
      // generic
      task.type = 'task';
      task.typeLabel = 'Miscellaneous';
      task.subtype = 'task';
      if (task.completed === 't') {
        task.first = 'finalize';
      }
    }

    // sites
    if (sites) {
      sites.forEach((item) => {
        if (item.id === task.site) {
          task.siteName = item.name;
        }
      });
    }
    return task;
  });
  return result;
}

function* loadDashboardTasksSaga({ payload }) {
  try {
    const apiResponse = yield call(actionApiCall, {
      actionType: ActionTypeConstants.LOAD_DASHBOARD_TASKS,
      data: {
        type: undefined,
      },
    });

    if (apiResponse && apiResponse.res.data.response === 200) {
      const sites = yield select(getItems);
      const tasksData = apiResponse.res.data.data || [];
      const tasks = yield transformTasks(tasksData, sites);
      yield put({
        type: ActionTypeConstants.LOAD_DASHBOARD_TASKS_SUCCESS,
        payload: { tasks },
      });
    } else {
      yield put({
        type: ActionTypeConstants.LOAD_DASHBOARD_TASKS_FAILURE,
        payload: { message: apiResponse.res.data.message },
      });
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: apiResponse.res.data.message || `${sagaConstants.ISSUE_OCCURED} loading tasks`,
          color: 'danger',
        },
      });
      yield delay(10000);
      yield put({ type: ActionTypeConstants.HIDE_ALERT });
    }
  } catch (err) {
    yield put({
      type: ActionTypeConstants.SHOW_ALERT,
      payload: {
        message: `${sagaConstants.SERVER_ERROR}`,
        color: 'danger',
      },
    });
    yield delay(10000);
    yield put({ type: ActionTypeConstants.HIDE_ALERT });
  }
}

export default function* () {
  yield takeLatest(ActionTypeConstants.LOAD_DASHBOARD_TASKS, loadDashboardTasksSaga);
}
