import React from 'react';

import styles from './UserEmailAddresses.module.scss';
import FormElement from 'components/FormElement/FormElement';

/**
 * @param {String} className - Additional className to add
 * @param {String} state - state to keep email addresses in
 * @param {Function} setState - function to update state
 * @param {String} errorMessage - an error message to show
 */
const UserEmailAddresses = (props) => {
  const { error, setStateErrors, className, errorMessage, state, setState } = props;

  const handleChange = (e) => {
    setState(e.target.value);
    setStateErrors((s) => ({
      ...s,
      emails: '',
    }));
  };

  return (
    <FormElement className={`${styles['user-email-addresses']} ${className || ''}`} labelText="USER EMAIL ADDRESSES" smallText="*" htmlFor="name" errorMessage={errorMessage || ''}>
      <textarea
        name="users-emails"
        className={`${styles['email-addresses']} ${error ? styles['error'] : ''}`}
        placeholder="Example list: bob@gmail.com, marc@gmail.com, joe@gmail.com, alan@gmail.com, stan@gmail.com"
        onChange={handleChange}
        defaultValue={state}
      />
      {error && <p className={styles['error-msg']}>Please, enter valid email address(s)</p>}
    </FormElement>
  );
};

export default UserEmailAddresses;
