import { put, takeLatest, delay } from 'redux-saga/effects';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
import Cookies from 'js-cookie';

export function* authCheckSaga(action) {
  yield delay(60000);
  try {
    const active = Cookies.get('active');
    const isLogged = Cookies.get('isLogged');

    if (isLogged === 'true') {
      if (!active) {
        yield put({ type: ActionTypeConstants.AUTH_LOGOUT });
      }
      yield put({ type: 'AUTH_CHECK' });
    }
  } catch (error) {
    yield put({ type: ActionTypeConstants.AUTH_LOGOUT });
  }
}

export default function* () {
  yield takeLatest('AUTH_CHECK', authCheckSaga);
}
