import { call, put, takeLatest, select } from 'redux-saga/effects';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { actionApiCall } from 'helpers/api/ApiCalls';

const getSelected = (state) => state.UserOrgSiteReducer.selected;

function* initializeDocumentSaga({ payload }) {
  try {
    const selected = yield select(getSelected);
    const apiResponse = yield call(actionApiCall, {
      actionType: ActionTypeConstants.INITIALIZE_DOCUMENT,
      data: {
        organization: selected.org,
        id: payload.id,
      },
    });

    if (apiResponse.res.data.response === 200) {
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        // payload: {
        //     message: apiResponse.res.data.message || "Successfully saved document",
        //     color: "success"
        // }
      });
      // yield delay(10000)
      // yield put({ type: ActionTypeConstants.HIDE_ALERT });
    } else {
      // Failure to initialize document
      // yield put({
      //     type: ActionTypeConstants.SHOW_ALERT,
      //     payload: {
      //         message: apiResponse.res.data.message || "We ran into an issue while saveing document",
      //         color: "danger"
      //     }
      // });
      // yield delay(10000)
      // yield put({ type: ActionTypeConstants.HIDE_ALERT });
    }
  } catch (err) {
    // yield put({
    //     type: ActionTypeConstants.SHOW_ALERT,
    //     payload: {
    //         message: "We ran into an issue while initializing document",
    //         color: "danger"
    //     }
    // });
    // yield delay(10000)
    // yield put({ type: ActionTypeConstants.HIDE_ALERT });
  }
}

export default function* () {
  yield takeLatest(ActionTypeConstants.INITIALIZE_DOCUMENT, initializeDocumentSaga);
}
