import { put, call, takeLatest, delay, select } from 'redux-saga/effects';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { actionApiCall } from 'helpers/api/ApiCalls';
import * as sagaConstants from 'helpers/constants/SagasConstants';

const getItems = (state) => state.SitesReducer.sites;
const getUsers = (state) => state.UsersReducer.users;
const getSelected = (state) => state.UserOrgSiteReducer.selected;

function transformTasks(tasks, sites, users) {
  const result = tasks.map((task) => {
    task.status = task.completed === 't' || task.completed === true ? 'Complete' : 'Incomplete';
    if (sites) {
      sites.forEach((item) => {
        if (item.id === task.site) {
          task.siteName = item.name;
        }
      });
    }
    if (users) {
      users.forEach((user) => {
        if (user.id === task.user_id) {
          task.assignee_name = user.name;
          task.assignee = user.id;
        }
      });
    }
    return task;
  });
  return result;
}

function* LoadTasksForUserSaga({ payload }) {
  try {
    const selected = yield select(getSelected);
    const apiResponse = yield call(actionApiCall, {
      actionType: ActionTypeConstants.GET_TASKS_FOR_USER,
      data: {
        organization: selected.org,
      },
    });

    if (apiResponse && apiResponse.res.data.response === 200) {
      const sites = yield select(getItems);
      const users = yield select(getUsers);
      const tasks = apiResponse.res.data.data || [];
      yield put({
        type: ActionTypeConstants.GET_TASKS_FOR_USER_SUCCESS,
        payload: {
          tasks: transformTasks(tasks, sites, users),
        },
      });
    } else {
      yield put({
        type: ActionTypeConstants.GET_TASKS_FOR_USER_FAILURE,
        payload: { message: apiResponse.res.data.message },
      });
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: apiResponse.res.data.message || `${sagaConstants.ISSUE_OCCURED} loading tasks`,
          color: 'danger',
        },
      });
      yield delay(10000);
      yield put({ type: ActionTypeConstants.HIDE_ALERT });
    }
  } catch (err) {
    yield put({
      type: ActionTypeConstants.SHOW_ALERT,
      payload: {
        message: `${sagaConstants.SERVER_ERROR}`,
        color: 'danger',
      },
    });
    yield delay(10000);
    yield put({ type: ActionTypeConstants.HIDE_ALERT });
  }
}

export default function* () {
  yield takeLatest(ActionTypeConstants.GET_TASKS_FOR_USER, LoadTasksForUserSaga);
}
