import { call, put, takeLatest, select } from 'redux-saga/effects';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { actionApiCall } from 'helpers/api/ApiCalls';
import * as sagaConstants from 'helpers/constants/SagasConstants';

const getSelected = (state) => state.UserOrgSiteReducer.selected;

function filterRegistered(items) {
  try {
    const _data = {};
    let i = 0;
    Object.values(items).forEach((item) => {
      i += 1;
      if (item.registered === 't') {
        _data[i] = item;
      }
    });
    return _data;
  } catch (error) {
    return {};
  }
}

function* loadUserByOrgSaga({ payload }) {
  try {
    const selected = yield select(getSelected);
    const apiResponse = yield call(actionApiCall, {
      actionType: ActionTypeConstants.LOAD_USERS_BY_ORG,
      data: {
        organization: selected.org,
        site: payload.site,
        department: payload.department,
        file_id: payload.file_id,
        role: payload.role,
      },
    });
    if (apiResponse.res.data.response === 200) {
      const { data } = apiResponse.res.data;
      yield put({
        type: ActionTypeConstants.LOAD_USERS_BY_ORG_SUCCESS,
        payload: filterRegistered(data),
      });
    } else {
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: apiResponse.res.data.message || `${sagaConstants.ISSUE_OCCURED} loading user data`,
          color: 'danger',
        },
      });
    }
  } catch (err) {
    yield put({
      type: ActionTypeConstants.SHOW_ALERT,
      payload: {
        message: `${sagaConstants.SERVER_ERROR}`,
        color: 'danger',
      },
    });
  }
}

export default function* () {
  yield takeLatest(ActionTypeConstants.LOAD_USERS_BY_ORG, loadUserByOrgSaga);
}
