import { call, put, takeLatest, delay, select } from 'redux-saga/effects';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { actionApiCall } from 'helpers/api/ApiCalls';
import * as sagaConstants from 'helpers/constants/SagasConstants';

function transformAffiliates(audits) {
  const result = audits.map((element, index) => {
    const el = element;
    el.index = index;
    el['agreement-type'] = element.phi === 't' || element.phi === true ? 'Business Associate' : 'Confidentiality';
    el.status = element.approved === '2' ? 'Approved' : element.approved === '1' ? 'Declined' : 'Under Review';
    el.affiliate_status = element.active === 't' || element.active === true ? 'Active' : 'Inactive';
    return el;
  });
  return result;
}
const getSelected = (state) => state.UserOrgSiteReducer.selected;

function* loadAffiliatesSaga({ payload }) {
  try {
    const selected = yield select(getSelected);
    const apiResponse = yield call(actionApiCall, {
      actionType: ActionTypeConstants.LOAD_AFFILIATES,
      data: {
        organization: selected.org,
        site: selected.site,
      },
    });
    if (apiResponse.res.data.response === 200) {
      const { data } = apiResponse.res.data;
      yield put({
        type: ActionTypeConstants.LOAD_AFFILIATES_SUCCESS,
        payload: {
          affiliates: transformAffiliates(data),
        },
      });
    } else {
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: apiResponse.res.data.message || `${sagaConstants.ISSUE_OCCURED} loading vendors`,
          color: 'danger',
        },
      });
      yield delay(10_000);
      yield put({ type: ActionTypeConstants.HIDE_ALERT });
    }
  } catch {
    yield put({
      type: ActionTypeConstants.SHOW_ALERT,
      payload: {
        message: `${sagaConstants.SERVER_ERROR}`,
        color: 'danger',
      },
    });
    yield delay(10_000);
    yield put({ type: ActionTypeConstants.HIDE_ALERT });
  }
}

export default function* LoadAffiliatesSaga() {
  yield takeLatest(ActionTypeConstants.LOAD_AFFILIATES, loadAffiliatesSaga);
}
