import React from 'react';
// import moment from 'moment';

import ColVisibilitySelector from 'components/DataTable/ColVisibilitySelector';
import ItemActionsMenu from 'components/DataTable/ItemActionsMenu';
import Checkbox from 'components/Checkbox/Checkbox';

const getDeviceStatus = (rowData) => {
  if (rowData['status'] === 'Destroyed') {
    return <span style={{ display: 'flex', alignItems: 'center' }}><i style={{ fontSize: 8, color: 'grey', marginRight: 10 }} className="fa-solid fa-circle" />Destroyed</span>
  } else if (rowData['status'] === 'Deactivated') {
    return <span style={{ display: 'flex', alignItems: 'center' }}><i style={{ fontSize: 8, color: 'red', marginRight: 10 }} className="fa-solid fa-circle" />Deactivated</span>
  } else if (rowData['status'] === 'Activated') {
    return <span style={{ display: 'flex', alignItems: 'center' }}><i style={{ fontSize: 8, color: 'green', marginRight: 10 }} className="fa-solid fa-circle" />Activated</span>
  }
};

function getLabel(label) {
  if (label === '-1') {
    return 'N/A';
  } else if (label === '0') {
    return 'No';
  } else if (label === '1') {
    return 'Yes';
  }
  return '';
}

export default {
  TABLE: {
    className: 'data-table-cloud',
    initialSortKey: 'application',
    initialSortOrder: 'desc',
  },
  COLUMN: [
    {
      label: 'Row Selector',
      key: 'row-selector',
      header: ({ customTableProps }) => {
        const isSelectedAll =
          customTableProps.selectedRows &&
          customTableProps.selectedRows.length &&
          customTableProps.rowsData &&
          customTableProps.rowsData.length &&
          customTableProps.selectedRows.length === customTableProps.rowsData.length
            ? true
            : false;
        return <Checkbox checked={isSelectedAll} onChange={() => customTableProps.doSelectAll()} name="row-selector" />;
      },
      className: 'col-row-selector',
      format: ({ rowData, customTableProps }) => {
        const isSelected = customTableProps.selectedRows && customTableProps.selectedRows.length && customTableProps.selectedRows.includes(rowData) ? true : false;
        return <Checkbox checked={isSelected} onChange={() => customTableProps.onRowSelect({ rowData })} name="row-selector" />;
      },
      canBeHidden: false,
    },
    {
      label: 'App',
      header: 'APPLICATION',
      key: 'application',
      sortable: true,
      className: 'name',
      format: ({ rowData }) => {
        return <span><strong>{rowData['application']}</strong><br /><small>{rowData['url']}</small></span>;
      },
    },
    // {
    //   label: 'Activated',
    //   header: 'ACTIVATED',
    //   key: 'activated',
    //   sortable: true,
    //   canBeHidden: true,
    //   format: ({ rowData }) => {
    //     return <span>{rowData.activated ? moment(rowData.activated).format('MM/DD/YYYY') : 'No'}</span>;
    //   },
    // },
    // {
    //   label: 'Deactivated',
    //   header: 'DEACTIVATED',
    //   key: 'deactivated',
    //   sortable: true,
    //   canBeHidden: true,
    //   format: ({ rowData }) => {
    //     return <span>{rowData.deactivated ? moment(rowData.deactivated).format('MM/DD/YYYY') : 'No'}</span>;
    //   },
    // },
    {
      label: 'Version',
      header: 'VERSION OR LICENSE TYPE',
      key: 'license_type',
      sortable: true,
      format: ({ rowData }) => {
        return <span>{rowData.license_type}</span>;
      },
    },
    {
      label: 'License #',
      header: 'LICENSE NUMBER',
      key: 'license_number',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{rowData.license_number}</span>;
      },
    },
    {
      label: 'User access',
      header: 'USER ACCESS',
      key: 'user_access',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{rowData['user_access']}</span>;
      },
    },
    {
      label: 'Status',
      header: 'STATUS',
      key: 'status',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{getDeviceStatus(rowData)}</span>;
      },
    },
    {
      label: 'User assigned',
      header: 'ADMINISTRATOR',
      key: 'ownerName',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{rowData['ownerName']}</span>;
      },
    },
    {
      label: 'Encryption',
      header: 'ENCRYPTION',
      key: 'encryption',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{getLabel(rowData.encryption)}</span>;
      },
    },
    {
      label: 'Antivirus',
      header: 'ANTIVIRUS/MALWARE PROTECTION',
      key: 'anti_virus',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{getLabel(rowData.anti_virus)}</span>;
      },
    },
    {
      label: 'Strong password',
      header: 'STRONG PASSWORD',
      key: 'strong_password',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{getLabel(rowData.strong_password)}</span>;
      },
    },
    {
      label: 'Mfa',
      header: 'MFA',
      key: 'mfa',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{getLabel(rowData.mfa)}</span>;
      },
    },
    {
      label: 'Backup',
      header: 'BACKUP',
      key: 'backup',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{getLabel(rowData.backups)}</span>;
      },
    },
    {
      label: 'Malware protection',
      header: 'OTHER',
      key: 'notes',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{rowData['notes'] ? rowData['notes'] : 'No'}</span>;
      },
    },
    {
      label: 'BAA with vendor',
      header: 'BAA WITH VENDOR',
      key: 'baa_with_vendor',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{rowData.baa_with_vendor === 't' ? 'Yes' : 'No'}</span>;
      },
    },
    {
      label: 'Risk',
      header: 'RISK',
      key: 'risk_rating',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{rowData['risk_rating']}</span>;
      },
    },
    {
      label: 'Site',
      header: 'SITE',
      key: 'siteName',
      sortable: true,
      canBeHidden: true,
      format: ({ rowData }) => {
        return <span>{rowData.siteName || 'All Sites'}</span>;
      },
    },
    {
      label: 'Actions',
      key: 'actions',
      format: ({ rowData, customTableProps }) => {
        const actionMenuDropDown = React.createRef();
        return (
          <ItemActionsMenu
            ref={actionMenuDropDown}
            actions={(() => {
              return [
                customTableProps.evidence && {
                  label: 'Evidence Locker',
                  action: () => {
                    if (actionMenuDropDown && actionMenuDropDown.current) {
                      actionMenuDropDown.current.handleMenuClose();
                    }
                    customTableProps.evidence(rowData);
                  },
                },
                rowData.deactivated && {
                  label: 'Activate',
                  action: () => {
                    if (actionMenuDropDown && actionMenuDropDown.current) {
                      actionMenuDropDown.current.handleMenuClose();
                    }
                    customTableProps.handleActivate(rowData);
                  },
                },
                !rowData.deactivated && {
                  label: 'Deactivate',
                  action: () => {
                    if (actionMenuDropDown && actionMenuDropDown.current) {
                      actionMenuDropDown.current.handleMenuClose();
                    }
                    customTableProps.handleDeactivate(rowData);
                  },
                },
              ];
            })()}
          />
        );
      },
      className: 'col-actions',
      header: ({ customTableProps }) => {
        return (
          <ColVisibilitySelector
            colDefs={customTableProps.colDefs}
            visibleColumnKeys={customTableProps.visibleColumnKeys}
            onChangeVisibility={(colKey) => customTableProps.onChangeVisibility(colKey)}
          />
        );
      },
    },
  ],
};
