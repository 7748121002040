import React from 'react';
import styles from './Method.module.scss';

interface IProps {
  type: 'email' | 'phone';
}

function Icon({ type }: IProps) {
  return (
    <div className={styles.icon}>
      {type === 'email' && <div className="fa-solid fa-envelope" />}
      {type === 'phone' && <div className="fa-solid fa-phone" />}
    </div>
  );
}

export default Icon;
