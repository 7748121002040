import React from 'react';

import { ClickAwayListener } from '@mui/base';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import PropTypes from 'prop-types';
import Checkbox from 'components/Checkbox/Checkbox';

import styles from './styles.module.scss';

class ColVisibilitySelector extends React.Component {
  state = {
    anchorEl: null,
  };

  handleMenuToggle(e) {
    this.setState({
      anchorEl: this.state.anchorEl ? null : e.currentTarget,
    });
  }

  handleMenuClose() {
    this.setState({ anchorEl: null });
  }

  moveScrollbar() {
    window.setTimeout(() => {
      window.document.querySelector('.data-table').scrollLeft += 1200;
      // document.getElementById('data-table-scrollbox').scrollLeft += 1200;
    }, 0);
  }

  renderColumnItems() {
    let items = [];

    if (this.props.colDefs && this.props.colDefs.length) {
      items = this.props.colDefs.map((colDef, i) => {
        if (colDef.canBeHidden) {
          return (
            <div className={styles.item} key={i}>
              <Checkbox
                checked={this.checkIsVisible(colDef.key) ? true : false}
                name={`rowVisibilitySelector-${i}`}
                labelText={colDef.label}
                onChange={() => {
                  this.props.onChangeVisibility(colDef.key);
                  this.moveScrollbar();
                }}
                key={`rowVisibilitySelector-${i}`}
                value={`rowVisibilitySelector-${i}`}
              />
            </div>
          );
        }
      });
    }

    return items;
  }

  checkIsVisible(key) {
    if (this.props.visibleColumnKeys && this.props.visibleColumnKeys.length) {
      if (this.props.visibleColumnKeys.includes(key)) {
        return true;
      }
    }
    return false;
  }

  render() {
    const { anchorEl } = this.state;
    return (
      <ClickAwayListener onClickAway={() => this.handleMenuClose()}>
        <div className="col-visibility-selector actions-menu-container">
          <i className="fas fa-columns" onClick={(e) => this.handleMenuToggle(e)} />
          <Popper id="simple-menu" className="actions-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} placement="bottom-end" disablePortal={true}>
            <Paper style={{ maxHeight: '250px', overflow: 'auto' }}>
              <div className="paper-content">
                {/* <div
                                    className="item"
                                    onClick={() =>
                                        this.props.onChangeVisibility()
                                    }
                                >
                                    Reset
                                </div> */}
                {this.renderColumnItems()}
              </div>
            </Paper>
          </Popper>
        </div>
      </ClickAwayListener>
    );
  }

  static propTypes = {
    colDefs: PropTypes.array.isRequired,
    visibleColumnKeys: PropTypes.array,
    onChangeVisibility: PropTypes.func.isRequired,
  };
}

export default ColVisibilitySelector;
