import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import styles from './AddReportModal.module.scss';
import Modal from 'components/Modal/Modal';
import FormElement from 'components/FormElement/FormElement';
import DateInput from 'components/DateInput/DateInput';
import Select from 'components/Select/Select';
import Button from 'components/Button/Button';
// import Checkbox from "components/Checkbox/Checkbox";

import { loadSitesAction, createReportAction, loadAuditsAction, loadDeptAction } from 'store/actions/Actions';
import { toggleDropdowns } from 'helpers/utils';

const AddReportModal = (props) => {
  const { OrgModulesReducer, SitesReducer, AuditsReducer, UserOrgSiteReducer, DepartmentsReducer, loadSitesAction, loadDeptAction, loadAuditsAction, createReportAction } = props;
  const defaultReg = (OrgModulesReducer.data && OrgModulesReducer.data.map((r) => r.name)) || [];
  const [state, setState] = useState({
    site: '',
    report: '',
    audit: '',
    selected_report: {},
    selected_site: {},
    selected_audit: {},
    selected_dep: {
      id: false,
      name: 'All',
    },
    selected_inactive: {
      id: 'false',
      name: 'Exclude inactive users',
    },
    selected_attestation: {
      id: 'undefined',
      name: 'All',
    },
    regulatory_act: defaultReg[0] || '',
    inactive_users: true,
    attestation_status: false,
    unfinished: false,
    date_from: '',
    date_to: '',
  });

  const [stateErrors, setStateErrors] = useState({
    site: '',
    report: '',
    regulatory_act: '',
    audit: '',
    selected_report: {},
    selected_audit: {},
    selected_site: {},
    date_from: '',
    date_to: '',
  });

  useEffect(() => {
    if (!SitesReducer.sites) {
      loadSitesAction();
    }
  }, [SitesReducer.sites, loadSitesAction]);

  useEffect(() => {
    if (!AuditsReducer.audits) {
      loadAuditsAction();
    }
  }, [loadAuditsAction, AuditsReducer.audits]);

  const handleReportChange = (e) => {
    setState({
      ...state,
      report: e.target.dataset.name,
      selected_report: {
        type: e.target.dataset.type,
        name: e.target.dataset.name,
      },
      selected_site: {},
      site: '',
      selected_audit: {},
      audit: '',
    });
    setStateErrors({
      ...stateErrors,
      report: '',
    });
  };

  const handleSiteChange = (e) => {
    let site_id = undefined;
    if (e.target.value === -1) {
      site_id = undefined;
    }
    if (e.target.value === 0) {
      site_id = false;
    }
    if (e.target.value > 0) {
      site_id = e.target.value;
    }
    loadDeptAction({ site: e.target.value });
    setState({
      ...state,
      site: e.target.dataset.name,
      selected_site: {
        id: site_id,
        name: e.target.dataset.name,
      },
      selected_audit: {},
    });
    setStateErrors({
      ...stateErrors,
      site: '',
    });
  };

  const handleDepChange = (e) => {
    setState((s) => ({
      ...s,
      userId: '0',
      selected_dep: {
        id: e.target.value,
        name: e.target.dataset.name,
      },
    }));

    setStateErrors({
      ...stateErrors,
      dep: '',
    });
  };

  const handleRegChange = (e) => {
    setState((s) => ({
      ...s,
      regulatory_act: e.target.dataset.name,
      selected_report: {},
      selected_site: {},
      site: '',
      selected_audit: {},
    }));
    setStateErrors((s) => ({ ...s, regulatory_act: '' }));
  };

  const handleFilterInactiveChange = (e) => {
    setState((s) => ({
      ...s,
      selected_inactive: {
        id: e.target.dataset.value,
        name: e.target.dataset.name,
      },
    }));
  };

  const handleFilterAttestationChange = (e) => {
    setState((s) => ({
      ...s,
      selected_attestation: {
        id: e.target.dataset.value,
        name: e.target.dataset.name,
      },
    }));
  };

  const handleAuditChange = (e) => {
    setState({
      ...state,
      audit: e.target.dataset.name,
      selected_audit: {
        id: e.target.value,
        name: e.target.dataset.name,
      },
    });
    setStateErrors({
      ...stateErrors,
      audit: '',
    });
  };

  const handleChange = (inputName) => (e) => {
    setState((s) => ({
      ...s,
      [inputName]: e.target.value,
    }));
    setStateErrors((s) => ({ ...s, [inputName]: '' }));
  };

  const addReport = async (e) => {
    if (
      state.report === '' ||
      state.regulatory_act === '' ||
      (state.site === '' && state.selected_report.type !== 'task' && state.selected_report.type !== 'device') ||
      (state.audit === '' && (state.selected_report.type === 'risk' || state.selected_report.type === 'cloud' || state.selected_report.type === 'inventory')) ||
      ((state.date_from === '' || state.date_to === '') && state.selected_report.type === 'attestation')
    ) {
      setStateErrors({
        ...stateErrors,
        audit: state.audit === '' ? 'Audit is required' : '',
        regulatory_act: state.regulatory_act === '' ? 'Regulatory Act is required' : '',
        site: state.site === '' ? 'Site is required' : '',
        report: state.report === '' ? 'Report is required' : '',
        date_from: state.date_from === '' ? 'Date from is required' : '',
        date_to: state.date_to === '' ? 'Date to is required' : '',
      });
    } else {
      await createReportAction({
        audit: state.selected_audit.id,
        site: state.selected_report.type === 'device' ? false : state.selected_site.id,
        department: state.selected_dep.id,
        type: state.selected_report.type,
        name: state.selected_report.type !== 'risk' ? state.selected_report.name : `${state.selected_report.name} - ${state.selected_audit.name}`,
        date_from: state.date_from,
        date_to: state.date_to,
        filter: {
          osha_regulatory_act: state.regulatory_act === 'OSHA' ? true : undefined,
          hipaa_regulatory_act: state.regulatory_act === 'HIPAA' ? true : undefined,
          inactive_users: state.selected_inactive.id === 'true' ? true : false,
          attestation_status: state.selected_attestation.id === 'undefined' ? undefined : state.selected_attestation.id === 'true' ? true : false,
        },
      });
      props.setShowModal(false);
    }
  };

  const reportDropdownRef = useRef();
  const siteDropdownRef = useRef();
  const regDropdownRef = useRef();
  const auditDropdownRef = useRef();
  const depDropdownRef = useRef();
  const f1DropdownRef = useRef();
  const f2DropdownRef = useRef();

  const is_site_admin = (UserOrgSiteReducer.selected && UserOrgSiteReducer.selected.is_site_admin) || false;
  const isSiteSelected = UserOrgSiteReducer.selected.site && UserOrgSiteReducer.selected.site !== '0' && UserOrgSiteReducer.selected.site !== '' ? true : false;

  return (
    <Modal className={styles.modal} setShowModal={props.setShowModal}>
      <div onClick={toggleDropdowns([reportDropdownRef, siteDropdownRef, regDropdownRef, auditDropdownRef, depDropdownRef, f1DropdownRef, f2DropdownRef])}>
        <p className={styles['modal-heading']}>Add Report</p>

        <div className={styles['modal-content']}>
          <FormElement labelText="REGULATORY ACT" htmlFor="regulatory_act" errorMessage={stateErrors.regulatory_act}>
            <Select value={state.regulatory_act} placeholder="Select regulatory act" onClick={(e) => handleRegChange(e)} dropdownToggleRef={regDropdownRef}>
              {OrgModulesReducer &&
                OrgModulesReducer.data &&
                OrgModulesReducer.data.map((d) => (
                  <li key={`regid-${d.name.toLowerCase()}`} data-name={d.name}>
                    {d.name}
                  </li>
                ))}
            </Select>
          </FormElement>

          <FormElement labelText="REPORT NAME" htmlFor="report" smallText="*" errorMessage={stateErrors.report}>
            <Select value={state.selected_report.name} placeholder="Select Report" onClick={handleReportChange} dropdownToggleRef={reportDropdownRef}>
              {props.reportsList.map((report) => {
                if (report.isOrgWide && is_site_admin) {
                  return false;
                }
                if (!report.acts.includes(state.regulatory_act)) {
                  return false;
                }
                return (
                  <li key={`reportid-${report.key}`} data-name={report.name} data-type={report.type} value={report.name}>
                    {report.name}
                  </li>
                );
              })}
            </Select>
          </FormElement>

          {state.selected_report.type === 'attestation' ? (
            <div>
              <div className={styles.dates_holder}>
                <FormElement className={styles.department} labelText="USERS STATUS" htmlFor="inactive_users">
                  <Select name="inactive_users" value={state.selected_inactive.name} placeholder="" onClick={handleFilterInactiveChange} dropdownToggleRef={f1DropdownRef}>
                    <li key={`depid-exclude`} value={false} data-value="false" data-name={'Exclude inactive users'}>
                      Exclude inactive users
                    </li>
                    <li key={`depid-include`} value={true} data-value="true" data-name={'Include inactive users'}>
                      Include inactive users
                    </li>
                  </Select>
                </FormElement>
                <FormElement className={styles.department} labelText="ATTESTATION STATUS" htmlFor="attestation_status">
                  <Select
                    name="attestation_status"
                    value={state.selected_attestation.name}
                    placeholder=""
                    onClick={handleFilterAttestationChange}
                    dropdownToggleRef={f2DropdownRef}
                  >
                    <li key={`depid-all`} value={undefined} data-value="undefined" data-name={'All'}>
                      All
                    </li>
                    <li key={`depid-completed`} value={true} data-value="true" data-name={'Only completed'}>
                      Only completed
                    </li>
                    <li key={`depid-incompleted`} value={false} data-value="false" data-name={'Only incompleted'}>
                      Only incompleted
                    </li>
                  </Select>
                </FormElement>
              </div>
            </div>
          ) : (
            ''
          )}

          {state.selected_report.type !== 'task' && state.selected_report.type !== 'device' && (
            <>
              <FormElement labelText="SITE" htmlFor="site" smallText="*" errorMessage={stateErrors.site}>
                <Select value={state.selected_site.name} placeholder="Select Site" onClick={handleSiteChange} dropdownToggleRef={siteDropdownRef}>
                  {is_site_admin || isSiteSelected
                    ? ''
                    : (state.selected_report.type === 'gap' ||
                        state.selected_report.type === 'remediation' ||
                        state.selected_report.type === 'attestation' ||
                        state.selected_report.type === 'vendor') && (
                        <li key={`siteid-orgwide`} data-name={'Org-wide report'} value={-1}>
                          Org-wide report
                        </li>
                      )}

                  {is_site_admin || isSiteSelected ? (
                    ''
                  ) : state.selected_report.type === 'risk' || state.selected_report.type === 'cloud' || state.selected_report.type === 'inventory' ? (
                    <li key={`siteid-all`} data-name={'Org-wide report'} value={-1}>
                      Org-wide report
                    </li>
                  ) : (
                    <li key={`siteid-all`} data-name={'Individual reports by site'} value={false}>
                      Individual reports by site
                    </li>
                  )}
                  {SitesReducer.sites.map((site) => (
                    <li key={`siteid-${site.id}`} data-name={site.name} value={site.id}>
                      {site.name}
                    </li>
                  ))}
                </Select>
              </FormElement>
            </>
          )}

          {state.selected_report.type === 'attestation' && (
            <>
              <FormElement
                className={styles.department}
                labelText="DEPARTMENT"
                htmlFor="department"
                // errorMessage={stateErrors.site}
              >
                <Select value={state.selected_dep.name} placeholder="Select Department" onClick={handleDepChange} dropdownToggleRef={depDropdownRef}>
                  <li key={`depid-all`} data-name={'All'} value={'0'}>
                    All
                  </li>
                  {DepartmentsReducer &&
                    DepartmentsReducer.departments &&
                    DepartmentsReducer.departments
                      .filter((dep) => dep.active === 't')
                      .map((department) => (
                        <li key={`siteid-${department.id}`} data-name={department.name} value={department.id}>
                          {department.name}
                        </li>
                      ))}
                </Select>
              </FormElement>
            </>
          )}

          {state.selected_report.type === 'risk' && (
            <>
              <FormElement labelText="AUDIT" smallText="*" htmlFor="audit" errorMessage={stateErrors.audit}>
                <Select value={state.selected_audit.name} placeholder="Select Audit" onClick={handleAuditChange} dropdownToggleRef={auditDropdownRef}>
                  {AuditsReducer.audits &&
                    AuditsReducer.audits
                      .filter((audit) => audit.type !== 'cloud')
                      .filter((audit) => audit.type !== 'inventory')
                      .filter((audit) => audit.type !== 'device')
                      .filter((audit) => audit.regulatory_act === state.regulatory_act)
                      .filter((audit) => (state.selected_site.id ? audit.site === `${state.selected_site.id}` : audit.site === ''))
                      .map((audit) => (
                        <li key={`auditid-${audit.id}`} data-name={audit.name} value={audit.id}>
                          {audit.name}
                        </li>
                      ))}
                </Select>
              </FormElement>
            </>
          )}

          {(state.selected_report.type === 'cloud' || state.selected_report.type === 'inventory') && (
            <>
              <FormElement labelText="AUDIT" smallText="*" htmlFor="audit" errorMessage={stateErrors.audit}>
                <Select value={state.selected_audit.name} placeholder="Select Audit" onClick={handleAuditChange} dropdownToggleRef={auditDropdownRef}>
                  {state.site !== '' &&
                    AuditsReducer.audits &&
                    AuditsReducer.audits
                      .filter((audit) => audit.type === state.selected_report.type)
                      .filter((audit) => audit.regulatory_act === state.regulatory_act)
                      .filter((audit) => (state.selected_site.id ? audit.site === `${state.selected_site.id}` : audit.site === ''))
                      .map((audit) => (
                        <li key={`auditid-${audit.id}`} data-name={audit.name} value={audit.id}>
                          {audit.name}
                        </li>
                      ))}
                </Select>
              </FormElement>
            </>
          )}

          {state.selected_report.type === 'attestation' ? (
            <div className={styles.dates_holder}>
              <FormElement labelText="FROM DATE" smallText="*" htmlFor="date_from" errorMessage={stateErrors['date_from']}>
                <DateInput onChange={handleChange('date_from')} value={state['date_from']} />
              </FormElement>
              <FormElement labelText="TO DATE" smallText="*" htmlFor="date_to" errorMessage={stateErrors['date_to']}>
                <DateInput onChange={handleChange('date_to')} value={state['date_to']} />
              </FormElement>
            </div>
          ) : (
            ''
          )}
        </div>

        <div className={styles['modal-footer']}>
          <Button text="CANCEL" color="secondary" onClick={() => props.setShowModal(false)} />
          <Button text="ADD REPORT" className={styles.create} onClick={addReport} />
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = ({ ActsReducer, AuditsReducer, DepartmentsReducer, SitesReducer, UserOrgSiteReducer, OrgModulesReducer }) => ({
  ActsReducer,
  AuditsReducer,
  DepartmentsReducer,
  SitesReducer,
  UserOrgSiteReducer,
  OrgModulesReducer,
});

const mapDispatchToProps = {
  loadSitesAction,
  loadAuditsAction,
  loadDeptAction,
  createReportAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddReportModal);
