import { call, put, takeLatest, delay } from 'redux-saga/effects';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { actionApiCall } from 'helpers/api/ApiCalls';
import * as sagaConstants from 'helpers/constants/SagasConstants';

// const getSelected = state => state.UserOrgSiteReducer.selected;

function transformData(org) {
  const result = {};
  if (org.id) {
    const ns = {};
    if (org.sites) {
      org.sites.forEach((site) => {
        const nd = {};
        if (site.departments) {
          site.departments.forEach((dep) => {
            nd[dep.id] = dep.name;
          });
        }
        site['departments'] = nd;
        ns[site.id] = site;
      });
      org['sites'] = ns;
    }
    org.sso_enabled = org.sso_enabled === 't';
    result[org.id] = org;
  }
  return result;
}

function* loadOrgsitedeptSaga({ payload }) {
  try {
    const apiResponse = yield call(actionApiCall, {
      actionType: ActionTypeConstants.LOAD_ORG_SITE_DEPT,
      data: payload && payload.organization ? { ...payload } : {},
    });
    if (apiResponse.res.data.response === 200) {
      const { data } = apiResponse.res.data;
      yield put({
        type: ActionTypeConstants.LOAD_ORG_SITE_DEPT_SUCCESS,
        payload: {
          organizations: transformData(data),
        },
      });
    } else {
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: apiResponse.res.data.message || `${sagaConstants.ISSUE_OCCURED} loading organizations`,
          color: 'danger',
        },
      });
      yield delay(10000);
      yield put({ type: ActionTypeConstants.HIDE_ALERT });
    }
  } catch (err) {
    yield put({
      type: ActionTypeConstants.SHOW_ALERT,
      payload: {
        message: `${sagaConstants.SERVER_ERROR}`,
        color: 'danger',
      },
    });
    yield delay(10000);
    yield put({ type: ActionTypeConstants.HIDE_ALERT });
  }
}

export default function* () {
  yield takeLatest(ActionTypeConstants.LOAD_ORG_SITE_DEPT, loadOrgsitedeptSaga);
}
