import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import AppLayoutWrapFull from 'components/AppLayoutWrapFull';
import PageHeader from 'components/PageHeader/PageHeader';
import Container from 'components/Container/Container';
import PageHeaderActions from 'components/PageHeader/components/PageHeaderActions/PageHeaderActions';
// import { setSelectedRows } from "helpers/utils";
import useFilterRows from 'helpers/customHooks/useFilterRows';
import FullDataTable from 'components/DataTable/FullDataTable';
import TableDefReports from 'table-defs/TableDefReports';
import { connect } from 'react-redux';

import { loadSitesAction, loadReportsAction, setPaginationAction, deleteReportAction, downloadReportAction } from 'store/actions/Actions';
import { setSelectedRows, toggleDropdowns, exportAsCSV } from 'helpers/utils';
import { useAppDispatch, useAppSelector, useConfirmModal } from 'hooks';
import { REPORTS_PAGE } from 'helpers/constants/DataTableConstants';
import Spinner from 'components/Spinner/Spinner';
import ActionStatusConstants from 'helpers/ActionStatusConstants';

import AddReportModal from './components/AddReportModal/AddReportModal';

const labels = () => ({
  id: {
    label: 'ID',
  },
  name: {
    label: 'TITLE',
  },
  regulatory_act: {
    label: 'REGULATORY',
  },
  siteName: {
    label: 'SITE',
  },
  date_from: {
    label: 'DATE FROM',
  },
  date_to: {
    label: 'DATE TO',
  },
  created_at: {
    label: 'CREATED',
  },
  status: {
    label: 'STATUS',
  },
});

const reportsList = [
  {
    key: '1',
    name: 'Gap Analysis',
    type: 'gap',
    acts: ['OSHA', 'HIPAA'],
  },
  {
    key: '2',
    name: 'Remediation Plans',
    type: 'remediation',
    acts: ['OSHA', 'HIPAA'],
  },
  {
    key: '3',
    name: 'Task Manager Report',
    isOrgWide: true,
    type: 'task',
    acts: ['OSHA', 'HIPAA'],
  },
  {
    key: '4',
    name: 'Risk Analysis Summary',
    type: 'risk',
    acts: ['OSHA', 'HIPAA'],
  },
  {
    key: '5',
    name: 'IT Device Audit Summary',
    type: 'device',
    acts: ['HIPAA'],
  },
  {
    key: '6',
    name: 'Vendor Report',
    type: 'vendor',
    acts: ['HIPAA'],
  },
  {
    key: '7',
    name: 'Training and Attestation Report',
    type: 'attestation',
    acts: ['OSHA', 'HIPAA'],
  },
  {
    key: '8',
    name: 'Incident Summary',
    type: 'incident',
    acts: ['OSHA', 'HIPAA'],
  },
  {
    key: '9',
    name: 'Cloud and Application Audit Summary',
    type: 'cloud',
    acts: ['HIPAA'],
  },
  {
    key: '10',
    name: 'Device Inventory Audit Summary',
    type: 'inventory',
    acts: ['HIPAA'],
  },
];

function Reports(props) {
  const dispatch = useAppDispatch();
  const DatatableReducer = useAppSelector((state) => state.DatatableReducer);
  const SitesReducer = useAppSelector((state) => state.SitesReducer);
  const ReportsReducer = useAppSelector((state) => state.ReportsReducer);

  const [state, setState] = useState({
    selectedRows: undefined,
    selected_sites: [],
  });
  const [showModal, setShowModal] = useState(false);
  const [activeReg, setActiveReg] = useState('');
  const [isLoading, setLoading] = useState(true);
  const [sortKey, setSortKey] = useState(DatatableReducer.datatable[REPORTS_PAGE]?.sortKey);
  const [sortOrder, setSortOrder] = useState(DatatableReducer.datatable[REPORTS_PAGE]?.sortOrder);

  useEffect(() => {
    if (DatatableReducer?.datatable?.dashboard?.activeReg) {
      setActiveReg(DatatableReducer.datatable.dashboard.activeReg);
    }
  }, [DatatableReducer.datatable]);

  const { loadSitesAction, loadReportsAction, deleteReportAction, downloadReportAction, setPaginationAction } = props;
  const noFilters = {
    text: '',
    type: '',
    'last-action': '',
    status: '',
    role: '',
    site: '',
    start: '',
    end: '',
    regulatory_act: activeReg,
    selected_sites: [],
    dateField: 'created_at',
  };

  const [showConfirmModal, setShowConfirmModal, renderConfirmModal] = useConfirmModal();

  useEffect(() => {
    if (!SitesReducer.sites) {
      loadSitesAction();
    }
  }, [SitesReducer.sites, loadSitesAction]);

  useEffect(() => {
    if (!ReportsReducer.reports && SitesReducer.sites) {
      loadReportsAction();
    }
  }, [ReportsReducer.reports, SitesReducer.sites, loadReportsAction]);

  useEffect(() => {
    if (
      DatatableReducer.status === ActionStatusConstants.SUCCESS &&
      ReportsReducer.status === ActionStatusConstants.SUCCESS &&
      SitesReducer.status === ActionStatusConstants.SUCCESS
    ) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [DatatableReducer.datatable, ReportsReducer.reports, SitesReducer.sites]);

  const [filters, setFilters] = useState(DatatableReducer?.datatable?.reports?.filters || noFilters);

  const setFilter = (filterName) => (e) => {
    setFilters({
      ...filters,
      [filterName]: e.target.type === 'checkbox' ? e.target.checked : e.target.value || e?.target?.attributes?.value?.value || '',
    });
    setPaginationAction({ dataType: REPORTS_PAGE, currentPage: 0 });
  };

  useEffect(() => {
    if (activeReg && DatatableReducer?.datatable?.reports) {
      setFilters({
        ...DatatableReducer.datatable.reports.filters,
        regulatory_act: activeReg,
      });
    }
  }, [activeReg]);

  const [selectedReportIds, setSelectedReportIds] = useState(DatatableReducer?.datatable?.reports?.selectedIds || []);

  useEffect(() => {
    dispatch({
      type: 'SET_DATATABLE',
      payload: {
        datatable: {
          reports: {
            selectedIds: selectedReportIds,
            filters,
            selectedRows: state.selectedRows,
            selected_sites: state.selected_sites,
            rowsPerPage: DatatableReducer?.datatable?.reports?.rowsPerPage || 10,
            currentPage: DatatableReducer?.datatable?.reports?.currentPage || 0,
            sortKey,
            sortOrder,
          },
        },
      },
    });
  }, [selectedReportIds, filters, state, sortKey, sortOrder]);

  const filteredRows = useFilterRows(filters, ReportsReducer.reports);

  const onChangeRowSelect = ({ selectedRows }) => {
    if (selectedRows !== state.selectedRows) {
      setState((s) => ({ ...s, selectedRows }));
    }
  };

  const setDateFilter = (filterName) => (value) => {
    setFilters({ ...filters, [filterName]: value });
    setPaginationAction({ dataType: REPORTS_PAGE, currentPage: 0 });
  };

  const actDropdownRef = React.createRef();
  const chooseActionDropdownRef = React.createRef();
  const associatedSiteRef = React.createRef();
  const regDropdownRef = React.createRef();

  const handleDelete = (ids) => {
    deleteReportAction(ids);
  };

  const handleView = ({ hash, file_type = 'pdf' }) => {
    if (file_type && hash) {
      downloadReportAction({ file_type, hash });
    }
  };

  const getSitesList = () => {
    try {
      const sitesList = SitesReducer.sites
        ? SitesReducer.sites.map((site) => ({
            id: site.id,
            siteName: site.name,
            value: !!filters.selected_sites.includes(site.id),
          }))
        : [];
      sitesList.unshift({
        id: '',
        siteName: 'Org-wide report',
        value: filters.selected_sites.includes(undefined),
      });
      return sitesList;
    } catch (error) {
      console.log(error);
    }
    return [];
  };

  const handleDelteSelected = () => {
    const idCount = state.selectedRows.length;

    if (idCount > 0) {
      setShowConfirmModal({
        title: 'Delete selected reports',
        question: idCount === 1 ? 'Are you sure you want to delete the selected report?' : `Are you sure you want to delete ${idCount} selected reports?`,
        confirmAction: () => {
          handleDelete(state.selectedRows.map((item) => item.id));
        },
      });
    } else {
      setShowConfirmModal({
        type: 'simple',
        title: 'Nothing selected',
        question: 'Please select reports to be deleted.',
      });
    }
  };

  const handleExportSelected = () => {
    const data = ReportsReducer.reports.filter((item) => selectedReportIds.map((i) => i.id).includes(item.id));
    const updatedData = data.map((item) => ({
      ...item,
      created_at: item.created_at ? item.created_at.slice(0, 10) : '',
      date_from: item.date_from ? item.date_from.slice(0, 10) : '',
      date_to: item.date_to ? item.date_to.slice(0, 10) : '',
    }));
    exportAsCSV(updatedData, labels, 'reports.csv');
  };

  return (
    <AppLayoutWrapFull onClick={toggleDropdowns([associatedSiteRef, actDropdownRef, chooseActionDropdownRef])}>
      <PageHeader showStats>
        <PageHeaderActions
          primaryButtonText="ADD REPORT"
          primaryButtonHandler={() => setShowModal(!showModal)}
          searchHandler={(e) => {
            setFilters({ ...filters, text: e.target.value || '' });
            setPaginationAction({ dataType: REPORTS_PAGE, currentPage: 0 });
          }}
          searchValue={filters.text}
          chooseAction={[
            {
              actionName: 'Export Selected',
              actionHandler: () => handleExportSelected(),
            },
            {
              actionName: 'Delete Selected',
              actionHandler: () => handleDelteSelected(),
            },
          ]}
          chooseActionDropdownRef={chooseActionDropdownRef}
          site={{
            onClick: (e) => {
              if (e.target.type === 'checkbox') {
                let selected_sites = [...filters.selected_sites];
                if (e.target.checked) {
                  selected_sites.push(e.target.value);
                } else if (e.target.value === '') {
                  selected_sites = selected_sites.filter((site) => site !== undefined);
                } else {
                  selected_sites = selected_sites.filter((site) => site !== e.target.value);
                }
                for (let i = 0; i < selected_sites.length; i += 1) {
                  if (selected_sites[i] === '') {
                    selected_sites[i] = undefined;
                  }
                }
                setFilters({
                  ...filters,
                  site: selected_sites.length > 0 ? `Sites (${selected_sites.length})` : 'Site',
                  selected_sites,
                });
                setPaginationAction({ dataType: REPORTS_PAGE, currentPage: 0 });
              }
            },
            sites: getSitesList(),
          }}
          dateRange={{
            onChangeStart: setDateFilter('start'),
            onChangeEnd: setDateFilter('end'),
          }}
          reg={{
            onChange: setFilter('regulatory_act'),
            options: ['All regulatory', 'HIPAA', 'OSHA'],
          }}
          regDropdownRef={regDropdownRef}
          filters={filters}
          resetFilters={() => {
            setFilters(noFilters);
            setPaginationAction({ dataType: REPORTS_PAGE, currentPage: 0 });
          }}
        />
      </PageHeader>
      {isLoading ? (
        <Spinner />
      ) : (
        <Container wide fluid>
          {ReportsReducer.reports && (
            <FullDataTable
              rowsData={filteredRows}
              definitions={TableDefReports}
              dataType={REPORTS_PAGE}
              setSortKey={setSortKey}
              setSortOrder={setSortOrder}
              onChangeRowSelect={({ selectedRows }) => onChangeRowSelect({ selectedRows })}
              setSelectedRows={setSelectedRows(['id'], setSelectedReportIds)}
              customTableProps={{
                selectedRows: state.selectedRows || selectedReportIds,
                pdf: ({ hash }) => handleView({ hash, file_type: 'pdf' }),
                csv: ({ hash }) => handleView({ hash, file_type: 'csv' }),
                delete: ({ id, name }) => {
                  setShowConfirmModal({
                    title: 'Delete the report',
                    question: `Are you sure you want to remove the report "${name}"`,
                    confirmAction: () => {
                      handleDelete([id]);
                    },
                  });
                },
              }}
            />
          )}
          {showConfirmModal && renderConfirmModal(showConfirmModal, setShowConfirmModal)}
        </Container>
      )}

      {showModal ? ReactDOM.createPortal(<AddReportModal reportsList={reportsList} setShowModal={setShowModal} loadReportsAction={loadReportsAction} />, document.querySelector('#modal-root')) : undefined}
    </AppLayoutWrapFull>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  loadSitesAction,
  loadReportsAction,
  setPaginationAction,
  deleteReportAction,
  downloadReportAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
