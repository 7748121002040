import React from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

import * as RouteHelper from 'RouteHelper';

import styles from './Breadcrumbs.module.scss';

const renderCrumbs = (breadcrumbChain) => {
  if (!(breadcrumbChain && breadcrumbChain.length > 0)) {
    return false;
  }

  const crumbs = breadcrumbChain.map((curr, index) => {
    const style = classNames(styles.breadcrumb, breadcrumbChain.length === index + 1 && styles['breadcrumb-current']);
    return (
      <span className={style} key={`breadcrumb-${curr.key}`}>
        {breadcrumbChain.length === index + 1 ? (
          curr.title
        ) : (
          <>
            {curr.title} <span className={styles.separator}>/</span>
          </>
        )}
      </span>
    );
  });

  return crumbs && crumbs.length > 0 && crumbs;
};

/**
 * Shows clickable breadcrumb navigation based on route
 */
function Breadcrumbs(props) {
  const location = useLocation();

  const routeDef = RouteHelper.getDefByPathname(location.pathname);
  const breadcrumbChain = RouteHelper.getBreadcrumbChain(routeDef);

  if (props.breadcrumbs) {
    return <div className={styles.breadcrumbs}>{props.breadcrumbs}</div>;
  }

  return <div className={styles.breadcrumbs}>{renderCrumbs(breadcrumbChain)}</div>;
}

export default Breadcrumbs;
