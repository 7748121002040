import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import styles from './TasksTab.module.scss';
import FullDataTable from 'components/DataTable/FullDataTable';
import TableDefTasksDashboard from 'table-defs/TableDefTasksDashboard';
// import TableDefTasksDashboardRemediation from 'table-defs/TableDefTasksDashboardRemediation';
import PageHeader from 'components/PageHeader/PageHeaderDashboard';
import PageHeaderActions from 'components/PageHeader/components/PageHeaderActions/PageHeaderActions';
import useFilterRows from 'helpers/customHooks/useFilterRows';
import { toggleDropdowns } from 'helpers/utils';

import { loadTasksForUserAction, loadSitesAction, setPaginationAction } from 'store/actions/Actions';

const TasksTab = (props) => {
  const { SitesReducer, tasks, type, dataType, setPaginationAction } = props;

  const navigate = useNavigate();

  const noFilters = {
    text: '',
    type: '',
    status: '',
    site: '',
    selected_sites: [],
  };

  const [filters, setFilters] = useState(noFilters);

  const setFilter = (filterName) => (e) => {
    setFilters({
      ...filters,
      [filterName]:
        e.target.type === 'checkbox' ? e.target.checked : e.target.value || (e.target && e.target.attributes && e.target.attributes.value && e.target.attributes.value.value) || '',
    });
    setPaginationAction({ dataType, currentPage: 0 });
  };

  const getSitesList = () => {
    try {
      const sitesList = SitesReducer.sites
        ? SitesReducer.sites.map((site) => {
            return {
              id: site.id,
              siteName: site.name,
              value: filters.selected_sites.includes(site.id) ? true : false,
            };
          })
        : [];
      sitesList.unshift({
        id: '',
        siteName: 'All Sites',
        value: filters.selected_sites.includes(''),
      });
      return sitesList;
    } catch (error) {
      console.log('error with site list', error);
    }
    return [];
  };

  const statusDropdownRef = React.createRef();
  // const siteDropdownRef = React.createRef();
  // const typeDropdownRef = React.createRef();
  // const regDropdownRef = React.createRef();

  const filteredRows = useFilterRows(filters, tasks);

  return (
    <div
      className={styles['tab-tasks']}
      onClick={toggleDropdowns([
        statusDropdownRef,
        // siteDropdownRef,
      ])}
    >
      <div className={styles['header']}>
        <h3>{type === 'my' ? 'Miscellaneous' : type} tasks</h3>
      </div>

      <PageHeader className={styles.pageHeader}>
        <PageHeaderActions
          className={styles['page-header-actions-dashboard']}
          searchValue={filters.text}
          searchHandler={(e) => {
            setFilters({ ...filters, text: e.target.value || '' });
            setPaginationAction({ dataType, currentPage: 0 });
          }}
          site={{
            onClick: (e) => {
              if (e.target.type === 'checkbox') {
                let selected_sites = filters.selected_sites.slice();
                if (e.target.checked) {
                  selected_sites.push(e.target.value);
                } else {
                  selected_sites = selected_sites.filter((site) => site !== e.target.value);
                }
                setFilters({
                  ...filters,
                  site: selected_sites.length > 0 ? `Sites (${selected_sites.length})` : 'Site',
                  // site: "Site",
                  selected_sites: selected_sites,
                });
                setPaginationAction({ dataType, currentPage: 0 });
              }
            },
            sites: getSitesList(),
          }}
          // siteDropdownRef={siteDropdownRef}
          status={{
            onChange: setFilter('status'),
            options: ['All', 'Complete', 'Incomplete', 'Optional'],
          }}
          statusDropdownRef={statusDropdownRef}
          // type={{
          //     onChange: setFilter("type"),
          //     options: ["All", "Remediation", "Attestation", "Question", "Miscellaneous"]
          // }}
          // typeDropdownRef={typeDropdownRef}
          // reg={{
          //     onChange: setFilter("regulatory_act"),
          //     options: ["All", "HIPAA", "OSHA"]
          // }}
          // regDropdownRef={regDropdownRef}
          filters={filters}
          resetFilters={() => {
            setFilters(noFilters);
            setPaginationAction({ dataType, currentPage: 0 });
          }}
        />
      </PageHeader>

      <FullDataTable
        className="data-table-tasks-dashboard"
        rowsData={filteredRows}
        definitions={TableDefTasksDashboard}
        dataType={dataType}
        allowDisable={true}
        onRowClick={function (e) {
          if (e.currentTarget.classList.contains('col-certificate')) {
            return;
          } else {
            if (this.props.rowData.locked !== 't') {
              navigate(`/dashboard/${this.props.rowData.subtype}/${this.props.rowData.id}/${this.props.rowData.first}`);
            }
          }
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  TasksForUserReducer: state.TasksForUserReducer,
  SitesReducer: state.SitesReducer,
});

const mapDispatchToProps = {
  loadTasksForUserAction,
  loadSitesAction,
  setPaginationAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(TasksTab);
