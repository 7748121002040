import { call, put, takeLatest, delay, select } from 'redux-saga/effects';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { actionApiCall } from 'helpers/api/ApiCalls';
import * as sagaConstants from 'helpers/constants/SagasConstants';

const getSelected = (state) => state.UserOrgSiteReducer.selected;
const getUsers = (state) => state.UsersReducer.users;
const getSites = (state) => state.SitesReducer.sites;

function transformDevices(devices, sites, users) {
  const result = devices.map((device) => {
    if (sites) {
      sites.forEach((item) => {
        if (item.id === device.site_id) {
          device.siteName = item.name;
          device.site = device.site_id;
        }
      });
    }
    if (users) {
      users.forEach((user) => {
        if (user.id === device.user_id) {
          device.ownerName = user.name;
          device.owner = user.id;
        }
      });
    }
    return device;
  });
  return result;
}

function* loadDevicesSaga({ payload }) {
  try {
    const selected = yield select(getSelected);
    const apiResponse = yield call(actionApiCall, {
      actionType: ActionTypeConstants.LOAD_DEVICES,
      data: {
        organization: selected.org,
        auditId: payload.auditId,
      },
    });
    if (apiResponse.res.data.response === 200) {
      const sites = yield select(getSites);
      const users = yield select(getUsers);
      const devices = apiResponse.res.data.data || [];
      yield put({
        type: ActionTypeConstants.LOAD_DEVICES_SUCCESS,
        payload: {
          devices: transformDevices(devices, sites, users),
        },
      });
    } else {
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: apiResponse.res.data.message || `${sagaConstants.ISSUE_OCCURED} loading devices`,
          color: 'danger',
        },
      });
      yield put({ type: ActionTypeConstants.LOAD_DEVICES_FAILURE });
      yield delay(10000);
      yield put({ type: ActionTypeConstants.HIDE_ALERT });
    }
  } catch (err) {
    yield put({
      type: ActionTypeConstants.SHOW_ALERT,
      payload: {
        message: `${sagaConstants.SERVER_ERROR}`,
        color: 'danger',
      },
    });
    yield delay(10000);
    yield put({ type: ActionTypeConstants.HIDE_ALERT });
    yield put({ type: ActionTypeConstants.LOAD_DEVICES_FAILURE });
  }
}

export default function* () {
  yield takeLatest(ActionTypeConstants.LOAD_DEVICES, loadDevicesSaga);
}
