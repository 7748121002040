import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import { Button } from 'components/Button/Button';
import FormElement from 'components/FormElement/FormElement';
import InputAdornment from 'components/InputAdornment/InputAdornment';
import Checkbox from 'components/Checkbox/Checkbox';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

import { submitUserLoginFormAction, generateCodeAction, verifyCodeAction, makeLoginAttempt } from 'store/actions/Actions';
import { isEmailValid, showPassword } from 'helpers/utils';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useMsal } from '@azure/msal-react';

import logo from 'assets/img/cg-logo-large.png';
import MicrosoftLogInButton from './components/MicrosoftLogInButtonSSO/MicrosoftLogInButtonSSO';

import WelcomePanel from '../components/WelcomePanel/WelcomePanel';
import MFAChoose from '../MFAChoose/MFAChoose';
import MFAConfirm from '../MFAConfirm/MFAConfirm';

import styles from './Login.module.scss';

function Login() {
  const LOGIN_UN_STEP = 1;
  const LOGIN_PWD_STEP = 2;
  const LOGIN_CHOOSE_METHOD_STEP = 3;
  const LOGIN_CONFIRM_CODE_STEP = 4;
  const LOGIN_3RD_PARTY_STEP = 5;
  const LOGIN_MASTER_KEY_STEP = 10;

  const [state, setState] = useState({ user: '', pass: '', masterKey: '' });
  const [stateErrors, setStateErrors] = useState({
    user: undefined,
    pass: undefined,
    masterKey: undefined,
  });
  const [error, setError] = useState('');
  const [togglePasswordIcon, setTogglePasswordIcon] = useState(false);
  const [loginStep, setLoginStep] = useState(LOGIN_UN_STEP);
  const [chosenMethod, setChosenMethod] = useState('');
  const [chosenMethodObj, setChosenMethodObj] = useState();
  const [codeStr, setCodeStr] = useState('');
  const [rememberMe, setRembmerMe] = useState(false);
  const [codeExpiresAt, setCodeExpiresAt] = useState(undefined);
  const [isSsoUserMismatch, setIsSsoUserMismatch] = useState(false);
  const [ssoMessage, setSsoMessage] = useState('');
  const { hash } = useParams();
  const dispatch = useAppDispatch();
  const AuthReducer = useAppSelector((state) => state.AuthReducer);
  const MfaReducer = useAppSelector((state) => state.MfaReducer);
  const LoginAttemptReducer = useAppSelector((state) => state.LoginAttemptReducer);

  window.history.pushState({ page: 1 }, '', '');

  const { accounts } = useMsal();

  const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} arrow classes={{ popper: className }} placement="top" />)(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
      padding: '10px',
      textAlign: 'center',
    },
  }));

  useEffect(() => {
    if (AuthReducer.isLoggedIn && AuthReducer?.userData?.mfa?.verifiable_by?.some((method) => method.email_activated_at || method.phone_activated_at)) {
      setLoginStep(LOGIN_CHOOSE_METHOD_STEP);
    }
  }, [AuthReducer]);

  useEffect(() => {
    if (AuthReducer?.userData?.mfa?.verifiable_by) setChosenMethodObj(AuthReducer?.userData?.mfa?.verifiable_by.find((method) => method.type === chosenMethod));
  }, [AuthReducer?.userData?.mfa?.verifiable_by, chosenMethod]);

  useEffect(() => {
    if (loginStep === LOGIN_MASTER_KEY_STEP) {
      setStateErrors((oldValue) => ({
        ...oldValue,
        masterKey: MfaReducer?.message,
      }));
      return;
    }
    setError(MfaReducer?.message);
  }, [MfaReducer?.message]);

  useEffect(() => {
    if (MfaReducer?.mfa?.codeExpiresAt) setCodeExpiresAt(MfaReducer?.mfa?.codeExpiresAt);
  }, [MfaReducer?.mfa?.codeExpiresAt]);

  useEffect(() => {
    if (state.user && isEmailValid(state.user) && accounts.length > 0 && accounts.findIndex((account) => account.username === state.user) === -1) {
      // E-mail mismatch
      setIsSsoUserMismatch(true);
      setSsoMessage(`You are currently logged in as "${accounts[0]?.username}",
      but trying to log in as "${state.user}". Do you want to log out?`);
    }
  }, [state.user]);

  const handleChange = (inputName) => (e) => {
    e.persist();
    setState((s) => ({
      ...s,
      [inputName]: e.target.value,
    }));
    if (e.target.value === '' && inputName === 'user') {
      setStateErrors((s) => ({ ...s, user: 'Email is required!' }));
    }
    if (e.target.value === '' && inputName === 'pass') {
      setStateErrors((s) => ({ ...s, pass: 'Password is required!' }));
    }
    if (e.target.value !== '') {
      setStateErrors((s) => ({ ...s, [inputName]: '' }));
    }
  };

  const handleLogin = () => {
    try {
      if (state.user === '' || state.pass === '') {
        if (state.user === '') {
          setStateErrors((s) => ({ ...s, user: 'Email is required!' }));
        }
        if (state.pass === '') {
          setStateErrors((s) => ({ ...s, pass: 'Password is required!' }));
        }
      } else {
        dispatch(
          submitUserLoginFormAction({
            email: state.user,
            password: state.pass,
            redirect: hash,
          })
        );
      }
    } catch {
      setStateErrors((s) => ({ ...s, user: 'Error submitting the form!' }));
    }
  };

  useEffect(() => {
    if (LoginAttemptReducer?.data && LoginAttemptReducer?.data['3rd_party_auth']?.length === 0) {
      // Own authentication
      setLoginStep(LOGIN_PWD_STEP);
    }
    if (LoginAttemptReducer?.data && LoginAttemptReducer?.data['3rd_party_auth']?.length > 0) {
      // 3rd party authentication
      setLoginStep(LOGIN_3RD_PARTY_STEP);
    }
  }, [LoginAttemptReducer?.data]);

  const handleLoginAttempt = () => {
    dispatch(makeLoginAttempt({ email: state.user }));
  };

  const handleSendCode = () => {
    dispatch(
      generateCodeAction({
        user_id: AuthReducer?.userData?.id,
        type: chosenMethod,
      })
    );
    setError('');
    setLoginStep(LOGIN_CONFIRM_CODE_STEP);
  };

  const handleConfirmCode = () => {
    dispatch(
      verifyCodeAction({
        user_id: AuthReducer?.userData?.id,
        type: chosenMethod,
        code: codeStr,
        for_deactivation: false,
        remember_device: rememberMe,
      })
    );
  };

  const handleMasterKey = (e) => {
    setState((oldState) => ({ ...oldState, masterKey: e?.target?.value }));
    setStateErrors((oldValues) => ({ ...oldValues, masterKey: '' }));
  };

  const handleBack = (step) => {
    setLoginStep(step);
  };

  const submitMasterKey = () => {
    if (state?.masterKey?.trim()) {
      dispatch(
        verifyCodeAction({
          user_id: AuthReducer?.userData?.id,
          type: chosenMethod,
          code: state?.masterKey,
          for_deactivation: false,
          remember_device: false,
          fallback: true,
        })
      );
      return;
    }
    setStateErrors((oldValues) => ({
      ...oldValues,
      masterKey: 'Master key is required',
    }));
  };

  const toggleShowPassword = (isPasswordVisible) => {
    setTogglePasswordIcon(isPasswordVisible);
    showPassword('current-password', isPasswordVisible);
  };

  const stepsButtonsData = {
    step1: { buttonLabel: 'NEXT', buttonHandleFunction: handleLoginAttempt },
    step2: { buttonLabel: 'LOGIN', buttonHandleFunction: handleLogin },
    step3: { buttonLabel: 'SEND CODE', buttonHandleFunction: handleSendCode },
    step4: { buttonLabel: 'CONTINUE', buttonHandleFunction: handleConfirmCode },
    step10: { buttonLabel: 'SUBMIT', buttonHandleFunction: submitMasterKey },
  };

  const handleKeyPress = (e, step) => {
    if (e.key !== 'Enter') return;
    switch (step) {
      case LOGIN_UN_STEP: {
        handleLoginAttempt();
        break;
      }
      case LOGIN_PWD_STEP: {
        handleLogin();
        break;
      }
      case LOGIN_MASTER_KEY_STEP: {
        submitMasterKey();
        break;
      }
      default: {
        break;
      }
    }
  };

  return (
    <WelcomePanel>
      <div className={styles['login-panel-heading']}>
        <img className={styles.logo} src={logo} alt="" />
      </div>

      <div className={styles['login-panel-content']}>
        {loginStep === LOGIN_UN_STEP && (
          <>
            <p className={styles.welcome}>Welcome to The Guard!</p>
            <FormElement htmlFor="user" errorMessage={stateErrors.user}>
              <InputAdornment iconStart="fal fa-at">
                <input
                  onChange={handleChange('user')}
                  type="email"
                  placeholder="E-mail"
                  name="user"
                  data-testid="login"
                  value={state.user}
                  id="user-login"
                  autoComplete="username"
                  onKeyDown={(e) => handleKeyPress(e, LOGIN_UN_STEP)}
                />
              </InputAdornment>
            </FormElement>
          </>
        )}

        {loginStep === LOGIN_PWD_STEP && (
          <>
            <p className={styles.welcome}>Please enter your password</p>
            <FormElement htmlFor="pass" errorMessage={stateErrors.pass}>
              <InputAdornment iconStart="fal fa-lock-alt">
                <input
                  onChange={handleChange('pass')}
                  type="password"
                  placeholder="Password"
                  name="pass"
                  data-testid="password"
                  value={state.pass}
                  className={styles.password}
                  autoComplete="current-password"
                  id="current-password"
                  onKeyDown={(e) => handleKeyPress(e, LOGIN_PWD_STEP)}
                />
                <input
                  type="email"
                  autoComplete="username"
                  value={state.user}
                  readOnly
                  style={{display: 'none'}}
                />
                <span className={styles['show-password']}>
                  <button type="button" className={styles['password-button']} onClick={() => toggleShowPassword(!togglePasswordIcon)}>
                    <i className={togglePasswordIcon ? 'hide' : 'fas fa-eye'} title="show password" />
                    <i className={!togglePasswordIcon ? 'hide' : 'fas fa-eye-slash'} title="hide password" />
                  </button>
                </span>
              </InputAdornment>
            </FormElement>

            <Link to="/login/forgot-password">Forgot your password?</Link>
            <Link to="/login" onClick={() => handleBack(LOGIN_UN_STEP)}>
              ← Go Back
            </Link>
          </>
        )}

        {loginStep === LOGIN_CHOOSE_METHOD_STEP && (
          <>
            <MFAChoose setChosenMethod={setChosenMethod} chosenMethod={chosenMethod} methods={AuthReducer?.userData?.mfa?.verifiable_by} />
            <Link to="/login" onClick={() => setLoginStep(LOGIN_MASTER_KEY_STEP)}>
              I lost access to my e-mail/phone
            </Link>
          </>
        )}

        {loginStep === LOGIN_CONFIRM_CODE_STEP && (
          <>
            <MFAConfirm
              setCodeStr={setCodeStr}
              chosenMethodObj={chosenMethodObj}
              codeError={error}
              codeExpiresAt={codeExpiresAt}
              handleSendCode={handleSendCode}
              handleConfirmCode={handleConfirmCode}
              isSubmitClickable={stepsButtonsData[`step${loginStep}`]?.buttonLabel === 'CONTINUE' && codeStr.length === 6}
            />
            {AuthReducer?.userData?.mfa?.verifiable_by.filter((method) => method?.email_activated_at || method?.phone_activated_at).length > 1 && (
              <Link to="/login" onClick={() => setLoginStep(LOGIN_CHOOSE_METHOD_STEP)}>
                Choose different method
              </Link>
            )}
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Checkbox
                labelText={
                  <span>
                    Remember this device{' '}
                    <BootstrapTooltip
                      title={
                        <span
                          style={{
                            fontSize: '13px',
                            letterSpacing: '1px',
                            fontWeight: '200',
                          }}
                        >
                          Your device will be remembered for 30 days. You will not be prompted for additional authentication steps while logging in for that time period.
                        </span>
                      }
                    >
                      <i className="fa fa-circle-info" aria-hidden="true" />
                    </BootstrapTooltip>
                  </span>
                }
                checked={rememberMe}
                onChange={() => setRembmerMe(!rememberMe)}
                name="remember-me"
              />
            </div>
            <Link to="/login" onClick={() => setLoginStep(LOGIN_MASTER_KEY_STEP)}>
              I lost access to my e-mail/phone
            </Link>
          </>
        )}

        {loginStep === LOGIN_MASTER_KEY_STEP && (
          <>
            <div style={{ fontSize: '2rem' }}>Please enter your master key in the field below</div>
            <FormElement htmlFor="master-key" errorMessage={stateErrors.masterKey}>
              <input
                onChange={handleMasterKey}
                type="text"
                placeholder="Your Master Key"
                name="master_key"
                value={state.masterKey}
                className={styles.password}
                id="master-key"
                onKeyDown={(e) => handleKeyPress(e, LOGIN_MASTER_KEY_STEP)}
              />
            </FormElement>
            <Link to="/login" onClick={() => setLoginStep(LOGIN_CHOOSE_METHOD_STEP)}>
              Choose a method
            </Link>
          </>
        )}
        {loginStep === LOGIN_3RD_PARTY_STEP && (
          <>
            {isSsoUserMismatch && <p>{ssoMessage}</p>}
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              {LoginAttemptReducer?.data && LoginAttemptReducer?.data['3rd_party_auth'] && (
                <div>
                  <MicrosoftLogInButton loginEmail={state.user} setIsSsoUserMismatch={setIsSsoUserMismatch} isSsoUserMismatch={isSsoUserMismatch} setSsoMessage={setSsoMessage} />
                  <br />
                  <Link to="/login" onClick={() => handleBack(LOGIN_UN_STEP)}>
                    ← Go Back
                  </Link>
                </div>
              )}
            </div>
          </>
        )}
      </div>

      <div className={styles['login-panel-footer']}>
        {loginStep !== LOGIN_3RD_PARTY_STEP && (
          <Button
            text={stepsButtonsData[`step${loginStep}`]?.buttonLabel}
            onClick={stepsButtonsData[`step${loginStep}`]?.buttonHandleFunction}
            disabled={stepsButtonsData[`step${loginStep}`]?.buttonLabel === 'CONTINUE' && codeStr.length !== 6}
          />
        )}
      </div>
    </WelcomePanel>
  );
}

export default Login;
