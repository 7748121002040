import { call, put, takeLatest } from 'redux-saga/effects';

import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { actionApiCall } from 'helpers/api/ApiCalls';
import { push } from 'redux-first-history';
import * as sagaConstants from 'helpers/constants/SagasConstants';

function* LoadUserByKeySaga({ payload }) {
  try {
    const apiResponse = yield call(actionApiCall, {
      actionType: ActionTypeConstants.GET_USER_BY_KEY,
      data: {
        url_key: payload ? payload.url_key : null,
      },
    });
    if (apiResponse.res.data.response === 200) {
      const { data } = apiResponse.res.data;
      // if (!Array.isArray(data)) {
      //     data = [data];
      // }
      yield put({
        type: ActionTypeConstants.GET_USER_BY_KEY_SUCCESS,
        payload: data,
      });
    } else {
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: apiResponse.res.data.message || `${sagaConstants.ISSUE_OCCURED} loading user data`,
          color: 'danger',
        },
      });
      yield put(push('/login'));
    }
  } catch (err) {
    yield put({
      type: ActionTypeConstants.SHOW_ALERT,
      payload: {
        message: `${sagaConstants.SERVER_ERROR}`,
        color: 'danger',
      },
    });
  }
}

export default function* () {
  yield takeLatest(ActionTypeConstants.GET_USER_BY_KEY, LoadUserByKeySaga);
}
