import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import styles from './Edit.module.scss';

import AppLayoutWrapFull from 'components/AppLayoutWrapFull';
import PageHeader from 'components/PageHeader/PageHeader';
import Container from 'components/Container/Container';
import FormElement from 'components/FormElement/FormElement';
import DateInput from 'components/DateInput/DateInput';
import Select from 'components/Select/Select';
import Tabs from 'components/Tabs/Tabs';
import EditNote from './EditNote/EditNote';
import HR from 'components/HR/HR';
// import ClearAllFields from "components/ClearAllFields/ClearAllFields";
import Button from 'components/Button/Button';

import Spinner from 'components/Spinner/Spinner';
import ActionStatusConstants from 'helpers/ActionStatusConstants';

import EditEvidence from '../components/Evidence/EditEvidence';
import { Link, useParams, useNavigate } from 'react-router-dom';

import { loadSitesAction, completeTaskAction, loadTaskAction, loadUsersAction, updateTaskAction } from 'store/actions/Actions';
import { toggleDropdowns } from 'helpers/utils';

const Edit = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const defaultState = {
    task: '',
    notes: '',
    due: '',
    assignee: '',
    selected_site: {},
    assignee_name: '',
  };
  const [state, setState] = useState(defaultState);
  const [isLoading, setLoading] = useState(true);
  const [stateErrors, setStateErrors] = useState({
    task: '',
    due: '',
    assignee: '',
    completed: '',
    regulatory_act: '',
    site: '',
    selected_site: {},
  });
  const [tasknotes, setTasknotes] = useState([]);
  // const [files, setFiles] = useState([]);
  const [activeTab, setActiveTab] = useState('NOTES');
  const [parent, setParent] = useState({ id: '', label: '' });
  const parentLabels = {
    training: 'training',
    audit: 'audit',
    auditDevice: 'audit_device',
    auditCloud: 'audit-cloud',
    auditInventory: 'audit-inventory',
    remediation: 'remediation',
    incident: 'incident',
  };
  const { SitesReducer, UserOrgSiteReducer, TaskReducer, UsersReducer, OrgModulesReducer, updateTaskAction, loadSitesAction, loadTaskAction, loadUsersAction, completeTaskAction } =
    props;

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    if (state.selected_site.id) {
      loadUsersAction({ site: state.selected_site.id, inactive: true });
    }
  }, [loadUsersAction, state.selected_site.id]);

  useEffect(() => {
    if (!SitesReducer.sites) {
      loadSitesAction();
    }
  }, [SitesReducer.sites, loadSitesAction]);

  useEffect(() => {
    if (!UsersReducer.users) {
      loadUsersAction({ inactive: true });
    }
  }, [UsersReducer.users, loadUsersAction]);

  useEffect(() => {
    if (!TaskReducer.task && UsersReducer.users && SitesReducer.sites) {
      loadTaskAction({ id: params.taskId });
    }
  }, [TaskReducer.task, UsersReducer.users, SitesReducer.sites, loadTaskAction, params.taskId]);

  useEffect(() => {
    if (TaskReducer.task && SitesReducer.sites && UsersReducer.users) {
      // skip setState, when task notes or evidence are added
      if (!TaskReducer.refresh) {
        setState({
          ...TaskReducer.task,
          task: TaskReducer.task.task,
          due: TaskReducer.task.due,
          assignee: TaskReducer.task.assignee,
          selected_site: {
            id: TaskReducer.task.site,
            name: TaskReducer.task.siteName,
          },
          assignee_name: TaskReducer.task.assignee_name,
        });
      }
      setTasknotes(TaskReducer.task.task_notes);
      // setFiles(TaskReducer.task.evidence);
      setParent({ id: '', label: '' });
      if (TaskReducer.task.subtask === 'attestation')
        setParent({
          id: TaskReducer.task.subtask_data.training,
          label: parentLabels.training,
        });
      if (TaskReducer.task.subtask === 'auditResponse')
        setParent({
          id: TaskReducer.task.subtask_data.audit,
          label: parentLabels.audit,
        });
      if (TaskReducer.task.subtask === 'audit' && TaskReducer.task.subtask_data.device_id !== '')
        setParent({
          id: TaskReducer.task.subtask_data.audit,
          label: parentLabels.auditDevice,
        });
      if (TaskReducer.task.subtask === 'audit' && TaskReducer.task.subtask_data.cloud_asset_id !== '')
        setParent({
          id: TaskReducer.task.subtask_data.audit,
          label: parentLabels.auditCloud,
        });
      if (TaskReducer.task.subtask === 'audit' && TaskReducer.task.subtask_data.inventory_device_id !== '')
        setParent({
          id: TaskReducer.task.subtask_data.audit,
          label: parentLabels.auditInventory,
        });
      if (TaskReducer.task.subtask === 'remediation')
        setParent({
          id: TaskReducer.task.subtask_data.remediation,
          label: parentLabels.remediation,
        });
      if (TaskReducer.task.subtask === 'incident')
        setParent({
          id: TaskReducer.task.subtask_data.incident,
          label: parentLabels.incident,
        });
    }
  }, [TaskReducer.task, SitesReducer.sites, UsersReducer.users, params.taskId]);

  useEffect(() => {
    if (TaskReducer.status === ActionStatusConstants.SUCCESS && SitesReducer.status === ActionStatusConstants.SUCCESS && UsersReducer.status === ActionStatusConstants.SUCCESS) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [TaskReducer.task, SitesReducer.sites, UsersReducer.users]);

  const setTabAsActive = (heading) => () => {
    setActiveTab(heading);
  };

  const handleAssigneeChange = (e) => {
    e.persist();
    setState((s) => ({
      ...s,
      assignee: e.target.value,
      assignee_name: e.target.dataset.name,
    }));
    setStateErrors((s) => ({ ...s, assignee: '' }));
  };

  const handleComplete = (selectedTaskIds) => {
    const payload = selectedTaskIds.map((item) => {
      return { ...item, completed: true };
    });
    completeTaskAction(payload);
    window.setTimeout(() => {
      loadTaskAction({ id: params.taskId });
    }, 900);
  };

  const handleIncomplete = (selectedTaskIds) => {
    const payload = selectedTaskIds.map((item) => {
      return { ...item, completed: false };
    });
    completeTaskAction(payload);
    window.setTimeout(() => {
      loadTaskAction({ id: params.taskId });
    }, 900);
  };

  const handleChange = (inputName) => (e) => {
    e.persist();
    setState((s) => ({
      ...s,
      [inputName]: e.target.value,
    }));
    setStateErrors((s) => ({ ...s, [inputName]: '' }));
  };

  const handleRegChange = (e) => {
    e.persist();
    setState((s) => ({
      ...s,
      regulatory_act: e.target.dataset.name,
    }));
    setStateErrors((s) => ({ ...s, regulatory_act: '' }));
  };

  const handleSiteChange = (e) => {
    setState({
      ...state,
      selected_site: {
        id: e.target.value,
        name: e.target.dataset.name,
      },
      assignee: '',
      assignee_name: '',
    });
    setStateErrors({
      ...stateErrors,
      site: '',
    });
  };

  const saveTask = () => {
    if (!state.task || !state.due || !state.assignee) {
      setStateErrors({
        ...stateErrors,
        site: !state.selected_site.id ? 'Site is required' : '',
        task: !state.task ? 'Task description is required' : '',
        due: !state.due ? 'Target date is required' : '',
        assignee: !state.assignee ? 'Assignee is required' : '',
      });
    } else {
      updateTaskAction({
        id: params.taskId,
        site: state.selected_site.id,
        notes: state.notes,
        task: state.task,
        assignee: state.assignee,
        due: state.due,
        // tasknotes: tasknotes,
        // files: files,
      });
      navigate('/achieve/tasks');
    }
  };

  // // Clear all fields
  // const clearAllFields = () => {
  //     setState(defaultState);
  //     setTasknotes([]);
  //     setFiles([]);
  // };

  // Close dropdown when clicking outside them
  const assigneeDropdownRef = React.createRef();
  const siteDropdownRef = React.createRef();
  const statusDropdownRef = React.createRef();

  let usersList = {};
  if (UsersReducer && UsersReducer.users) {
    UsersReducer.users.forEach((user) => {
      usersList[user.id] = user.name || user.email;
    });
  }

  const is_officer = (UserOrgSiteReducer.selected && UserOrgSiteReducer.selected.is_officer) || false;
  const is_super = (UserOrgSiteReducer.selected && UserOrgSiteReducer.selected.is_super) || false;
  const is_coach = (UserOrgSiteReducer.selected && UserOrgSiteReducer.selected.is_coach) || false;
  const is_admin = (UserOrgSiteReducer.selected && UserOrgSiteReducer.selected.is_admin) || false;

  const hasAuditResponse = parent.label === parentLabels.remediation && parent.id && TaskReducer?.task?.subtask_data?.audit_response;
  const noAuditResponse = parent.label === parentLabels.remediation && parent.id && !TaskReducer?.task?.subtask_data?.audit_response;

  let type = '';
  if (state.subtask === 'remediation') {
    type = 'Remediation';
  } else if (state.subtask === 'attestation') {
    type = 'Attestation';
  } else if (state.subtask === 'incident') {
    type = 'Incident';
  } else if (state.subtask === 'auditResponse') {
    type = 'Question';
  } else {
    type = 'Miscellaneous';
  }

  return (
    <AppLayoutWrapFull onClick={toggleDropdowns([siteDropdownRef, assigneeDropdownRef, statusDropdownRef])}>
      <PageHeader breadcrumbs="Achieve &nbsp;/&nbsp; Tasks &nbsp;/&nbsp; Edit Task" />
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <div>
            <form className={styles['add-new-incident-form']}>
              <Container wide className={styles['add-new-incident-container']}>
                <FormElement labelText="TASK NAME" htmlFor="task" smallText="*" errorMessage={stateErrors.task}>
                  <textarea
                    disabled={state.locked === 't' || state.deactivated === 't' ? true : false}
                    style={{ padding: '10px', resize: 'vertical' }}
                    rows={3}
                    defaultValue={state['task']}
                    placeholder="Enter task name/description"
                    onChange={handleChange('task')}
                  />
                </FormElement>

                <div style={{ display: 'flex' }}>
                  <FormElement labelText="STATUS" htmlFor="completed" errorMessage={stateErrors.completed}>
                    <Select labels={{ t: 'Complete', f: 'Incomplete' }} dropdownToggleRef={statusDropdownRef} value={state.completed} disabled onClick={null}>
                      <li key={`status-complete`} data-name={'t'}>
                        Complete
                      </li>
                      <li key={`status-incomplete`} data-name={'f'}>
                        Incomplete
                      </li>
                    </Select>
                  </FormElement>

                  <FormElement className={styles.marginLeft} labelText="TYPE" htmlFor="completed" errorMessage={stateErrors.completed}>
                    <Select
                      // labels={{ t: 'Complete', f: 'Incomplete' }}
                      // dropdownToggleRef={statusDropdownRef}
                      value={type}
                      disabled
                      onClick={null}
                    >
                      {/* <li key={`status-complete`} data-name={'t'}>Complete</li> */}
                      {/* <li key={`status-incomplete`} data-name={'f'}>Incomplete</li> */}
                    </Select>
                  </FormElement>
                </div>

                <FormElement labelText="REGULATORY ACT" htmlFor="regulatory_act" errorMessage={stateErrors.regulatory_act}>
                  <Select disabled={true} value={state.regulatory_act} placeholder=" " onClick={handleRegChange} dropdownToggleRef={siteDropdownRef}>
                    {OrgModulesReducer &&
                      OrgModulesReducer.data &&
                      OrgModulesReducer.data.map((d) => (
                        <li key={`regid-${d.name.toLowerCase()}`} data-name={d.name}>
                          {d.name}
                        </li>
                      ))}
                  </Select>
                </FormElement>

                <FormElement labelText="SITE" htmlFor="site" errorMessage={stateErrors.site}>
                  <Select disabled={true} value={state.selected_site.name || 'All Sites'} placeholder="Select Site" onClick={handleSiteChange} dropdownToggleRef={siteDropdownRef}>
                    {SitesReducer &&
                      SitesReducer.sites &&
                      SitesReducer.sites.map((site) => (
                        <li key={`siteid-${site.id}`} data-name={site.name} value={site.id}>
                          {site.name}
                        </li>
                      ))}
                  </Select>
                </FormElement>

                <FormElement labelText="ASSIGNEE" htmlFor="assignee" errorMessage={stateErrors.assignee}>
                  <Select
                    disabled={(state.subtask && state.subtask === 'attestation') || state.locked === 't' || state.deactivated === 't' ? true : false}
                    value={state.assignee_name}
                    placeholder="Select Assignee"
                    onClick={handleAssigneeChange}
                    dropdownToggleRef={assigneeDropdownRef}
                  >
                    {UsersReducer &&
                      UsersReducer.users &&
                      UsersReducer.users
                        .filter((item) => item.registered === 't')
                        .map((user) => (
                          <li key={user.email} data-name={user.name || user.email} value={user.id}>
                            {user.name || user.email}
                          </li>
                        ))}
                  </Select>
                </FormElement>

                <FormElement labelText="TARGET DATE" smallText="*" htmlFor="due" errorMessage={stateErrors['due']}>
                  <DateInput
                    disabled={state.locked === 't' || state.deactivated === 't' ? true : false}
                    // size="full"
                    onChange={handleChange('due')}
                    value={state['due'] || ''}
                  />
                </FormElement>

                {parent.label === parentLabels.training && parent.id && <Link to={`/achieve/training/${parent.id}`}>Go to parent training</Link>}
                {parent.label === parentLabels.audit && parent.id && <Link to={`/achieve/audits/edit/${parent.id}`}>Go to parent audit</Link>}
                {parent.label === parentLabels.auditDevice && parent.id && <Link to={`/achieve/audits/audit-device?id=${parent.id}`}>Go to parent audit</Link>}
                {parent.label === parentLabels.auditCloud && parent.id && <Link to={`/achieve/audits/audit-cloud?id=${parent.id}`}>Go to parent audit</Link>}
                {parent.label === parentLabels.auditInventory && parent.id && <Link to={`/achieve/audits/audit-inventory?id=${parent.id}`}>Go to parent audit</Link>}
                {noAuditResponse && <Link to={`/achieve/remediation/edit?id=${parent.id}`}>Go to parent remediation</Link>}
                {hasAuditResponse && (
                    <div style={{gridColumn: '1/3'}}>
                        <p className={styles['box-title']}>RELATED AUDIT INFORMATION</p>
                        <div className={styles['parent-info-box']}>
                            <p><span className={styles.bold}>AUDIT:</span> {TaskReducer?.task?.subtask_data?.parent_question?.audit_name}</p>
                            <p><span className={styles.bold}>AUDIT QUESTION: </span> 
                              {TaskReducer?.task?.subtask_data?.parent_question?.id}: {TaskReducer?.task?.subtask_data?.parent_question?.question} <Link className={styles['icon-link']} to={`/achieve/audits/audit?id=${TaskReducer?.task?.subtask_data?.audit}&questionId=${TaskReducer?.task?.subtask_data?.parent_question?.id}`}><i className="fa-solid fa-up-right-from-square"></i></Link>
                            </p>
                            <p><span className={styles.bold}>REMEDIATION: </span> 
                              {TaskReducer?.task?.subtask_data?.parent_remediation?.control} {TaskReducer?.task?.subtask_data?.parent_remediation?.title} <Link className={styles['icon-link']} to={`/achieve/remediation/edit?id=${parent.id}`}><i className="fa-solid fa-up-right-from-square"></i></Link>
                            </p>
                        </div>  
                    </div>                  
                )}
                {parent.label === parentLabels.incident && parent.id && <Link to={`/maintain/incidents/edit?id=${parent.id}`}>Go to parent incident</Link>}

                <FormElement
                  labelText="NOTES"
                  htmlFor="notes"
                  className={styles.last_note}
                  // errorMessage={stateErrors.task}
                >
                  <textarea
                    style={{
                      padding: '10px',
                      height: '160px',
                      resize: 'vertical',
                      marginBottom: '20px',
                    }}
                    disabled={state.locked === 't' || state.deactivated === 't' ? true : false}
                    value={state['notes']}
                    placeholder="Enter task notes"
                    onChange={handleChange('notes')}
                  />
                </FormElement>
              </Container>
              <Tabs
                tabs={[
                  {
                    heading: 'NOTES',
                    icon: 'fad fa-tasks',
                  },
                  {
                    heading: 'EVIDENCE',
                    icon: 'fad fa-cctv',
                  },
                ]}
                activeTab={activeTab}
                setActiveTab={setTabAsActive}
              />
              <Container wide>
                {activeTab === 'NOTES' ? <EditNote allowAdd={state.deactivated === 't' ? false : true} taskId={params.taskId} notes={tasknotes} /> : null}

                {activeTab === 'EVIDENCE' ? (
                  <EditEvidence
                    allowAdd={state.deactivated !== 't'}
                    taskId={params.taskId}
                    files={TaskReducer.task && TaskReducer.task.evidence ? TaskReducer.task.evidence : []}
                    isFinalized={state.completed === 't'}
                  />
                ) : null}

                <HR className={styles.hr} />
                <div className={styles['form-actions']}>
                  {/* <ClearAllFields onClick={clearAllFields} /> */}

                  {(is_officer || is_super || is_admin || is_coach) && state.completed === 'f' && (
                    <Button
                      disabled={state.locked === 't' || state.deactivated === 't' ? true : false}
                      text="MARK TASK AS COMPLETE"
                      onClick={() => handleComplete([{ id: state.id }])}
                    />
                  )}

                  {(is_super || is_coach) && state.completed === 't' && (
                    <Button
                      disabled={state.locked === 't' || state.deactivated === 't' ? true : false}
                      text="MARK TASK AS INCOMPLETE"
                      onClick={() => handleIncomplete([{ id: state.id }])}
                    />
                  )}

                  <Button className={styles['cancel-button']} text="CANCEL" color="secondary" onClick={() => navigate(-1)} />
                  <Button
                    icon={state.locked === 't' ? 'fas fa-lock' : undefined}
                    disabled={state.locked === 't' || state.deactivated === 't' ? true : false}
                    className={styles['update-incident-button']}
                    text="SAVE TASK"
                    onClick={() => saveTask()}
                  />
                </div>
              </Container>
            </form>
          </div>
        </>
      )}
    </AppLayoutWrapFull>
  );
};

const mapStateToProps = (state) => ({
  SitesReducer: state.SitesReducer,
  UsersReducer: state.UsersReducer,
  UserOrgSiteReducer: state.UserOrgSiteReducer,
  userData: state.AuthReducer.userData,
  TaskReducer: state.TaskReducer,
  OrgModulesReducer: state.OrgModulesReducer,
  AuditsReducer: state.AuditsReducer,
});

const mapDispatchToProps = {
  loadTaskAction,
  loadSitesAction,
  completeTaskAction,
  updateTaskAction,
  loadUsersAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
