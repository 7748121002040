import { call, put, takeEvery, delay } from 'redux-saga/effects';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { actionApiCall } from 'helpers/api/ApiCalls';
import * as sagaConstants from 'helpers/constants/SagasConstants';

function* activateSiteSaga({ payload }) {
  try {
    const apiResponse = yield call(actionApiCall, {
      actionType: ActionTypeConstants.ACTIVATE_SITE,
      data: {
        ...payload,
      },
    });
    if (apiResponse.res.data.response === 200) {
      yield put({
        type: ActionTypeConstants.ACTIVATE_SITE_SUCCESS,
        payload,
      });
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: apiResponse.res.data.message || `Site details ${sagaConstants.SUCCESSFULLY_UPDATED}`,
          color: 'success',
        },
      });
      yield put({
        type: ActionTypeConstants.LOAD_ORG_SITE_DEPT,
      });
      yield delay(10000);
      yield put({ type: ActionTypeConstants.HIDE_ALERT });
    } else {
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: apiResponse.res.data.message || `${sagaConstants.ISSUE_OCCURED} updating the site`,
          color: 'danger',
        },
      });
      yield delay(10000);
      yield put({ type: ActionTypeConstants.HIDE_ALERT });
    }
  } catch (err) {
    yield put({
      type: ActionTypeConstants.SHOW_ALERT,
      payload: {
        message: `${sagaConstants.SERVER_ERROR}`,
        color: 'danger',
      },
    });
    yield delay(10000);
    yield put({ type: ActionTypeConstants.HIDE_ALERT });
  }
}

export default function* () {
  yield takeEvery(ActionTypeConstants.ACTIVATE_SITE, activateSiteSaga);
}
