import ActionStatusConstants from 'helpers/ActionStatusConstants';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { LOCATION_CHANGE } from 'redux-first-history';

type MFA = {
  isCodeVerified: boolean;
  masterKey: string | undefined;
  codeExpiresAt: number | undefined;
};

export interface IMfaState {
  status: string;
  message?: string;
  mfa?: MFA;
}

const initialState: IMfaState = {
  status: ActionStatusConstants.INITIAL,
};

export default (state = initialState, { type, payload }): IMfaState => {
  switch (type) {
    case ActionTypeConstants.MFA_VERIFY_CODE: {
      return {
        ...state,
        status: ActionStatusConstants.ISBUSY,
      };
    }
    case ActionTypeConstants.MFA_VERIFY_CODE_SUCCESS: {
      return {
        ...state,
        mfa: payload?.mfa,
        status: ActionStatusConstants.SUCCESS,
        message: payload?.message,
      };
    }
    case ActionTypeConstants.MFA_GENERATE_CODE_SUCCESS: {
      return {
        ...state,
        mfa: payload?.mfa,
        status: ActionStatusConstants.SUCCESS,
        message: payload?.message,
      };
    }
    case ActionTypeConstants.MFA_VERIFY_CODE_FAILURE: {
      return {
        ...state,
        status: ActionStatusConstants.FAILURE,
        message: payload?.message,
      };
    }
    case ActionTypeConstants.MFA_MASTER_KEY_SUCCESS: {
      return {
        ...initialState,
      };
    }
    case LOCATION_CHANGE: {
      return {
        ...initialState,
      };
    }
    default:
  }
  return state;
};
