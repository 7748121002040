import React, { useState, useEffect } from 'react';
import Modal from 'components/Modal/Modal';
import FormElement from 'components/FormElement/FormElement';
import Checkbox from 'components/Checkbox/Checkbox';
import { Button } from 'components/Button/Button';
import { useAppDispatch, useAppSelector } from 'hooks';
import { loadRolesAction } from 'store/actions/Actions';
import { isEmailValid, makeDeepCopy, randomId } from 'helpers/utils';
import styles from './UsersModal.module.scss';

function UsersModal(props) {
  const { formDataOrg, setFormDataOrg, item, setShowModal } = props;

  const dispatch = useAppDispatch();
  const RolesReducer = useAppSelector((state) => state.RolesReducer);

  const [formData, setFormData] = useState([]);
  const [stateErrors, setStateErrors] = useState([]);

  useEffect(() => {
    if (!RolesReducer.roles) {
      dispatch(loadRolesAction({}));
    }
    if (item.type === 'org') {
      if (formDataOrg.users && formDataOrg.users.length > 0) {
        setFormData(formDataOrg.users);
        const errors = [];
        for (const user of formDataOrg.users) {
          errors.push({
            id: user.id,
            role: '',
            emails: '',
          });
        }
        setStateErrors(errors);
      } else {
        const uuid = randomId();
        setFormData([
          {
            id: uuid,
            role: 0,
            emails: [],
          },
        ]);
        setStateErrors([
          {
            id: uuid,
            role: '',
            emails: '',
          },
        ]);
      }
    }
    if (item.type === 'site') {
      const currentSiteIndex = formDataOrg.sites.findIndex((site) => item.id === site.id);
      if (formDataOrg.sites[currentSiteIndex].users && formDataOrg.sites[currentSiteIndex].users.length > 0) {
        setFormData(formDataOrg.sites[currentSiteIndex].users);
        const errors = [];
        for (const user of formDataOrg.sites[currentSiteIndex].users) {
          errors.push({
            id: user.id,
            role: '',
            emails: '',
          });
        }
        setStateErrors(errors);
      } else {
        const uuid = randomId();
        setFormData([
          {
            id: uuid,
            role: 0,
            emails: [],
          },
        ]);
        setStateErrors([
          {
            id: uuid,
            role: '',
            emails: '',
          },
        ]);
      }
    }
  }, []);

  const handleSelect = (e, formItem) => {
    const formDataCopy = makeDeepCopy(formData);
    const index = formDataCopy.findIndex((fi) => fi.id === formItem.id);
    formDataCopy[index].role = e.target.value;
    setFormData([...formDataCopy]);
  };

  const handleChange = (e, formItem) => {
    const formDataCopy = makeDeepCopy(formData);
    const index = formDataCopy.findIndex((fi) => fi.id === formItem.id);
    const rawEmails = e.target.value.split(',');
    const emails = [];
    if (rawEmails && rawEmails.length > 0) {
      for (const email of rawEmails) {
        if (email.trim() !== '') {
          emails.push(email.trim());
        }
      }
    }
    formDataCopy[index].emails = emails;
    setFormData(formDataCopy);
  };

  const handleAddNewUserGroup = () => {
    const uuid = randomId();
    setFormData((s) => [
      ...s,
      {
        id: uuid,
        role: 0,
        emails: [],
      },
    ]);
    setStateErrors((s) => [
      ...s,
      {
        id: uuid,
        role: '',
        emails: '',
      },
    ]);
  };

  const handleDeleteUserGroup = (formItem) => {
    const formDataCopy = makeDeepCopy(formData);
    const index = formDataCopy.findIndex((fi) => fi.id === formItem.id);
    const stateErrorsCopy = makeDeepCopy(stateErrors);
    const errorIndex = stateErrorsCopy.findIndex((error) => error.id === formItem.id);
    stateErrorsCopy.splice(errorIndex, 1);
    formDataCopy.splice(index, 1);
    setFormData(formDataCopy);
    setStateErrors(stateErrorsCopy);
  };

  const handleCheckbox = (e) => {
    const formDataCopy = makeDeepCopy(formData);
    const { departmentId, formItemId } = JSON.parse(e.target.value);
    const currentFormItem = formDataCopy.find((fi) => fi.id === formItemId);

    if (e.target.checked) {
      if (typeof currentFormItem.departments === 'undefined') {
        currentFormItem.departments = [];
      }

      currentFormItem.departments.push(departmentId);
    } else {
      const curentDepartmentIndex = currentFormItem.departments.indexOf(departmentId);
      currentFormItem.departments.splice(curentDepartmentIndex, 1);
    }

    setFormData(formDataCopy);
  };

  const sortUsersForFormDataOrg = (formDataOrg) => {
    // formDataOrg passed by reference
    formDataOrg.usersAllSeparate = []; // reset the array
    formDataOrg &&
      formDataOrg.users &&
      formDataOrg.users.length > 0 &&
      formDataOrg.users.forEach((orgLevelUser) => {
        // organization level users
        for (const orgUseremail of orgLevelUser.emails) {
          formDataOrg.usersAllSeparate.push({
            email: orgUseremail,
            access: [
              {
                role: orgLevelUser.role,
                site: undefined,
                department: undefined,
              },
            ],
          });
        }
      });

    // List all access rules
    formDataOrg &&
      formDataOrg.sites &&
      formDataOrg.sites.length > 0 &&
      formDataOrg.sites.forEach((site) => {
        if (site.departments && site.departments.length > 0) {
          for (const dept of site.departments) {
            if (dept.users && dept.users.length > 0) {
              for (const deptLevelUser of dept.users) {
                // departments have users
                deptLevelUser.emails &&
                  deptLevelUser.emails.forEach((deptUserEmail) => {
                    formDataOrg.usersAllSeparate.push({
                      email: deptUserEmail,
                      access: [
                        {
                          role: deptLevelUser.role,
                          site: site.name,
                          department: dept.name,
                        },
                      ],
                    });
                  });
              }
            }

            site.users &&
              site.users.length > 0 &&
              site.users.forEach((siteLevelUser) => {
                siteLevelUser.emails &&
                  siteLevelUser.emails.length > 0 &&
                  siteLevelUser.emails.forEach((siteUserEmail) => {
                    if (!formDataOrg.usersAllSeparate.some((user) => user.email === siteUserEmail)) {
                      formDataOrg.usersAllSeparate.push({
                        email: siteUserEmail,
                        access: [
                          {
                            role: siteLevelUser.role,
                            site: site.name,
                            department: undefined,
                          },
                        ],
                      });
                    }
                  });
              });
          }
        } else {
          // site level users without departments attached
          site.users &&
            site.users.length > 0 &&
            site.users.forEach((siteLevelUser) => {
              siteLevelUser.emails &&
                siteLevelUser.emails.length > 0 &&
                siteLevelUser.emails.forEach((siteUserEmail) => {
                  formDataOrg.usersAllSeparate.push({
                    email: siteUserEmail,
                    access: [
                      {
                        role: siteLevelUser.role,
                        site: site.name,
                        department: undefined,
                      },
                    ],
                  });
                });
            });
        }
      });

    // Combine users listed under usersAllSeparate
    formDataOrg.usersAll = []; // reset the array

    formDataOrg.usersAllSeparate &&
      formDataOrg.usersAllSeparate.length > 0 &&
      formDataOrg.usersAllSeparate.forEach((userSeparate, index) => {
        if (index === 0) {
          formDataOrg.usersAll.push(userSeparate);
        } else {
          let isAccessUpdated = false;
          for (const userAll of formDataOrg.usersAll) {
            if (userAll.email === userSeparate.email) {
              // Users with the same email - join accesses
              userAll.access.push(...userSeparate.access);
              isAccessUpdated = true;
            }
          }
          if (!isAccessUpdated) {
            // Add a new user
            formDataOrg.usersAll.push(userSeparate);
          }
        }
      });

    delete formDataOrg.usersAllSeparate; // remove unnecessary property
  };

  const addModal = (e) => {
    let isError = false;
    const stateErrorsCopy = makeDeepCopy(stateErrors);
    const allEmails = [];
    for (const formItem of formData) allEmails.push(...formItem.emails);
    const allRoles = [];
    for (const formItem of formData) allRoles.push(formItem.role);

    for (const error of stateErrors) {
      let isRoleError = false;
      let isEmailError = false;
      const inputGroup = formData.find((inputItem) => inputItem.id === error.id);
      const stateErrorIndex = stateErrorsCopy.findIndex((err) => err.id === error.id);
      // Reset error messages
      stateErrorsCopy[stateErrorIndex].role = '';
      stateErrorsCopy[stateErrorIndex].emails = '';

      // Set error messages - precedence from the top
      if (inputGroup.role === 0) {
        // Role can't be empty
        stateErrorsCopy[stateErrorIndex].role = 'Role is required';
        isRoleError = true;
        isError = true;
      }
      if (inputGroup.emails && inputGroup.emails.length === 0) {
        // Emails field can't be empty
        stateErrorsCopy[stateErrorIndex].emails = 'At least one email is required';
        isEmailError = true;
        isError = true;
      }
      if (
        !isRoleError &&
        allRoles.find((roleId) => RolesReducer.roles.find((role) => role.name === 'User').id !== roleId) &&
        allRoles.filter((rle) => rle === inputGroup.role).length > 1
      ) {
        // Can be only one role of the same type
        stateErrorsCopy[stateErrorIndex].role = 'This role already exists';
        isRoleError = true;
        isError = true;
      }
      if (
        !isEmailError &&
        [
          RolesReducer && RolesReducer.roles && RolesReducer.roles.find((role) => role.name === 'Compliancy Officer').id,
          RolesReducer && RolesReducer.roles && RolesReducer.roles.find((role) => role.name === 'Security Officer').id,
          RolesReducer && RolesReducer.roles && RolesReducer.roles.find((role) => role.name === 'Privacy Officer').id,
        ].includes(inputGroup.role) &&
        inputGroup.emails &&
        inputGroup.emails.length > 1
      ) {
        // Compliancy, privacy and security officer can be only one
        stateErrorsCopy[stateErrorIndex].emails = 'Only one user with this role allowed';
        isEmailError = true;
        isError = true;
      }
      if (!isEmailError) {
        for (const email of inputGroup.emails) {
          if (email.trim() === '') {
            // Emails field can't be empty
            stateErrorsCopy[stateErrorIndex].emails = 'At least one email is required';
            isEmailError = true;
            isError = true;
          }
          if (!isEmailError && allEmails.filter((eml) => eml.trim() === email.trim()).length > 1) {
            // A user can have only one role, not two or more
            stateErrorsCopy[stateErrorIndex].emails = "A user can't have more than one role";
            isEmailError = true;
            isError = true;
          }
          if (!isEmailError && !isEmailValid(email)) {
            // E-mail validation
            stateErrorsCopy[stateErrorIndex].emails = 'Not valid e-mail';
            isEmailError = true;
            isError = true;
          }
        }
      }
    }

    setStateErrors(stateErrorsCopy);

    if (isError) {
      return false;
    }
    const formDataOrgCopy = makeDeepCopy(formDataOrg);

    if (item.type === 'org') {
      formDataOrgCopy.users = formData;
    }
    if (item.type === 'site') {
      const currentSiteIndex = formDataOrgCopy.sites.findIndex((site) => item.id === site.id);
      formDataOrgCopy.sites[currentSiteIndex].users = formData;

      // Reset user array for each department
      if (formDataOrgCopy && formDataOrgCopy.sites && formDataOrgCopy.sites[currentSiteIndex] && formDataOrgCopy.sites[currentSiteIndex].departments) {
        for (const dept of formDataOrgCopy.sites[currentSiteIndex].departments) {
          dept.users = [];
        }
      }

      for (const formItem of formData) {
        if (formItem.departments && formItem.departments.length > 0) {
          for (const deptId of formItem.departments) {
            const currentDepartment = formDataOrgCopy.sites[currentSiteIndex].departments.find((dept) => dept.id === deptId);
            // reset users array
            currentDepartment.users = [];
            currentDepartment.users.push({
              id: randomId(),
              role: RolesReducer.roles.find((role) => role.name === 'User').id,
              emails: formItem.emails,
            });
          }
        }
      }
    }

    sortUsersForFormDataOrg(formDataOrgCopy);
    setFormDataOrg(formDataOrgCopy);
    setShowModal(false);
  };

  return (
    <Modal className={styles['modal-users']} setShowModal={setShowModal}>
      <Modal.Heading>Add Users</Modal.Heading>

      <div className={styles['modal-content']}>
        {formData &&
          formData.map((formItem, index) => (
            <div key={formItem.id}>
              {index !== 0 ? <div className={styles.divider} /> : undefined}
              <FormElement
                labelText="ROLES"
                htmlFor="role"
                errorMessage={stateErrors.find((error) => error.id === formItem.id) ? stateErrors.find((error) => error.id === formItem.id).role : undefined}
              >
                <span className={styles.drop}>
                  {item.type === 'org' && (
                    <select key={`${item.id}-ok`} defaultValue={formItem.role} onChange={(e) => handleSelect(e, formItem)} name="role" data-org={item.id}>
                      <option value="0" disabled>
                        Choose role
                      </option>
                      {RolesReducer &&
                        RolesReducer.roles &&
                        RolesReducer.roles
                          .filter((role) => role.org_level)
                          .map((role) => (
                            <option key={`so-${role.id}`} value={role.id}>
                              {role.name}
                            </option>
                          ))}
                    </select>
                  )}
                  {item.type === 'site' && (
                    <select key={`${item.id}-ok`} defaultValue={formItem.role} onChange={(e) => handleSelect(e, formItem)} name="role" data-org={item.id}>
                      <option value="0" disabled>
                        Choose role
                      </option>
                      {RolesReducer &&
                        RolesReducer.roles &&
                        RolesReducer.roles
                          .filter((role) => role.site_level)
                          .map((role) => (
                            <option key={`so-${role.id}`} value={role.id}>
                              {role.name}
                            </option>
                          ))}
                    </select>
                  )}
                </span>
              </FormElement>
              <FormElement
                className={styles['user-email-addresses-users-modal']}
                labelText="EMAIL ADDRESSES"
                htmlFor="emails"
                errorMessage={stateErrors.find((error) => error.id === formItem.id) ? stateErrors.find((error) => error.id === formItem.id).emails : undefined}
              >
                <textarea
                  name="emails"
                  className={styles['email-addresses']}
                  defaultValue={formItem.emails && formItem.emails.join(', ')}
                  placeholder="Example list: bob@gmail.com, marc@gmail.com, joe@gmail.com, alan@gmail.com, stan@gmail.com"
                  onChange={(e) => handleChange(e, formItem)}
                />
              </FormElement>
              {item.type === 'site' && RolesReducer && RolesReducer.roles && RolesReducer.roles.find((role) => role.name === 'User').id === formData[index].role
                ? item &&
                  item.formData &&
                  item.formData.sites &&
                  item.formData.sites.find((site) => site.name === item.name) &&
                  item.formData.sites.find((site) => site.name === item.name).departments &&
                  item.formData.sites
                    .find((site) => site.name === item.name)
                    .departments.map((dept) => (
                      <span key={dept.id}>
                        <span className={styles.checkbox}>
                          <Checkbox
                            id={dept.id}
                            name={`${dept.name}-${index}`}
                            onChange={handleCheckbox}
                            labelText={dept.name}
                            value={JSON.stringify({
                              departmentId: dept.id,
                              formItemId: formItem.id,
                            })}
                            checked={!!(formItem && formItem.departments && formItem.departments.includes(dept.id))}
                          />
                        </span>
                        <div className={styles.clear} />
                      </span>
                    ))
                : undefined}
              {formData.length > 1 ? (
                <i role="none" onClick={() => handleDeleteUserGroup(formItem)} className={`fal fa-trash-alt ${styles['delete-user-group']}`} title="Delete User Group" />
              ) : undefined}
            </div>
          ))}
        <i role="none" onClick={handleAddNewUserGroup} className={`fal fa-plus-circle ${styles['add-user-group']}`} title="Add User Group" />
      </div>

      <Modal.Footer>
        <Button text="CANCEL" color="secondary" onClick={() => props.setShowModal(false)} />
        <Button text="ADD" className={styles.create} onClick={(e) => addModal(e)} />
      </Modal.Footer>
    </Modal>
  );
}

export default UsersModal;
