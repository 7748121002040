import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styles from './BulkUploadModal.module.scss';
import Modal from 'components/Modal/Modal';
import Button from 'components/Button/Button';

import { toggleDropdowns } from 'helpers/utils';

import { bulkUploadDeviceAction } from 'store/actions/Actions';
import UploadZone from 'components/UploadZone/UploadZone';

const BulkUploadModal = (props) => {
  const [template, setTemplate] = useState({
    name: '',
    data: '',
  });
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (files) {
      setTemplate(files[0]);
    }
  }, [files]);

  const siteRef = React.createRef();

  return (
    <Modal setShowModal={props.setShowModal} className={styles.modal}>
      <div className={styles['modal-header']}>
        <h2 className={styles.heading}>HIPAA Devices - Bulk Upload</h2>
        <p className={styles.description}>Fill out the HIPAA Device Excel template and upload below.</p>
      </div>
      <div className={styles['modal-body']} onClick={toggleDropdowns([siteRef])}>
        <p>If you need the template, you can download it here:</p>
        <a className={styles['download-button']} href={`${process.env.PUBLIC_URL}/DevicesWorksheet.xlsx`}>
          DOWNLOAD TEMPLATE
        </a>
        <UploadZone files={files} setFiles={setFiles} formats={['xls', 'xlsx']} maxFileSize={10 * 1024 * 1024} maxFileNameLength={235} />
      </div>
      <div className={styles['modal-footer']}>
        <Button className={styles.button} text="CANCEL" onClick={() => props.setShowModal(false)} color="secondary" />
        <Button
          className={styles.button}
          text="UPLOAD"
          // disabled={!site}
          onClick={() => {
            if (template.data) {
              props.bulkUploadDeviceAction({
                file: template.data,
                site: null,
                auditId: props.auditId,
              });
              props.setShowModal(false);
            }
          }}
        />
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  bulkUploadDeviceAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(BulkUploadModal);
