import { call, put, takeLatest, select } from 'redux-saga/effects';

import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { actionApiCall } from 'helpers/api/ApiCalls';
import * as sagaConstants from 'helpers/constants/SagasConstants';

const getItems = (state) => state.SitesReducer.sites;

function transformUsers(users, sites) {
  const result = users.map((user) => {
    user.status = user.registered === 'f' ? 'Awaiting Registration' : user.active === 't' ? 'Active' : 'Inactive';
    return user;
  });
  return result;
}

function* loadUsersUnassignedSaga({ payload }) {
  try {
    const apiResponse = yield call(actionApiCall, {
      actionType: ActionTypeConstants.LOAD_USERS_UNASSIGNED,
      data: {
        inactive: payload && payload.inactive ? true : undefined,
        id: payload ? payload.id : null,
        site: payload && payload.site ? String(payload.site) : undefined,
      },
    });
    if (apiResponse.res.data.response === 200) {
      let { data } = apiResponse.res.data;
      if (!Array.isArray(data)) {
        data = [data];
      }
      const sites = yield select(getItems);
      yield put({
        type: ActionTypeConstants.LOAD_USERS_UNASSIGNED_SUCCESS,
        payload: {
          users: transformUsers(data, sites),
        },
      });
    } else {
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: apiResponse.res.data.message || `${sagaConstants.ISSUE_OCCURED} loading users`,
          color: 'danger',
        },
      });
    }
  } catch (err) {
    console.log(err);
    yield put({
      type: ActionTypeConstants.SHOW_ALERT,
      payload: {
        message: `${sagaConstants.SERVER_ERROR}`,
        color: 'danger',
      },
    });
  }
}

export default function* () {
  yield takeLatest(ActionTypeConstants.LOAD_USERS_UNASSIGNED, loadUsersUnassignedSaga);
}
