import React from 'react';

import styles from './WelcomePanel.module.scss';

function WelcomePanel(props) {
  const year = new Date().getFullYear();
  return (
    <div className={styles['login-panel-wrap']} role="none" onClick={props.onClick || undefined}>
      <div className={styles['login-panel']}>{props.children}</div>
      <div className={styles.copyright}>&#169; {`${year}`} Compliancy Group LLC</div>
    </div>
  );
}

export default WelcomePanel;
