import ActionStatusConstants from 'helpers/ActionStatusConstants';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { LOCATION_CHANGE } from 'redux-first-history';
import { Affiliate } from 'types/Affiliate';

export interface IAffiliateState {
  status: string;
  message?: string;
  affiliates?: Affiliate[];
}

const initialState: IAffiliateState = {
  status: ActionStatusConstants.INITIAL,
};

export default (state = initialState, { type, payload }): IAffiliateState => {
  switch (type) {
    case ActionTypeConstants.LOAD_AFFILIATES: {
      return {
        ...state,
        status: ActionStatusConstants.ISBUSY,
      };
    }
    case ActionTypeConstants.LOAD_AFFILIATES_SUCCESS: {
      return {
        ...state,
        affiliates: payload.affiliates,
        status: ActionStatusConstants.SUCCESS,
        message: payload?.message,
      };
    }
    case ActionTypeConstants.LOAD_AFFILIATES_FAILURE: {
      return {
        ...state,
        status: ActionStatusConstants.FAILURE,
        message: payload?.message,
      };
    }
    case LOCATION_CHANGE: {
      return {
        ...initialState,
      };
    }
    default:
  }
  return state;
};
