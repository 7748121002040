import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import styles from './Add.module.scss';

import AppLayoutWrapFull from 'components/AppLayoutWrapFull';
import PageHeader from 'components/PageHeader/PageHeader';
import Container from 'components/Container/Container';
import FormElement from 'components/FormElement/FormElement';
import DateInput from 'components/DateInput/DateInput';
import Select from 'components/Select/Select';
import Row from 'components/Row/Row';
import Checkbox from 'components/Checkbox/Checkbox';
import Tabs from 'components/Tabs/Tabs';
import AddTask from 'views/Maintain/Incidents/AddTask/AddTask';
import HR from 'components/HR/HR';
import ClearAllFields from 'components/ClearAllFields/ClearAllFields';
import Button from 'components/Button/Button';
import FullDataTable from 'components/DataTable/FullDataTable';
import TableDefNotes from 'table-defs/TableDefNotes';

import AddEvidence from 'views/Maintain/Incidents/Evidence/AddEvidence';

import { addNewIncidentAction, loadIncidentTypesAction, loadSitesAction, loadAffiliatesAction } from 'store/actions/Actions';
import { toggleDropdowns, randomId } from 'helpers/utils';
import { useNavigate } from 'react-router-dom';

const Add = (props) => {
  const {
    OrgModulesReducer,
    SitesReducer,
    AffiliatesReducer,
    UserOrgSiteReducer,
    IncidentTypesReducer,
    AddIncidentFormReducer,
    addNewIncidentAction,
    loadSitesAction,
    loadAffiliatesAction,
    loadIncidentTypesAction,
  } = props;
  const defaultReg = (OrgModulesReducer.data && OrgModulesReducer.data.map((r) => r.name)) || [];
  const defaultState = {
    id: '',
    site: '',
    affiliate: '',
    name: '',
    reported_by: 'Anonymous',
    type: '',
    reason: '',
    regulatory_act: defaultReg[0] || 'Other',
    investigating: '1',
    occurred: '',
    discovered: '',
    affected: false,
    description: '',
  };
  const navigate = useNavigate();
  const [state, setState] = useState(defaultState);
  const [stateErrors, setStateErrors] = useState({});
  const [tasks, setTasks] = useState([]);
  const [files, setFiles] = useState([]);
  const [activeTab, setActiveTab] = useState('DESCRIPTION');

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    if (!SitesReducer.sites) {
      loadSitesAction();
    }
  }, [SitesReducer.sites, loadSitesAction]);

  useEffect(() => {
    if (!IncidentTypesReducer.incidentTypes && state.regulatory_act && state.regulatory_act !== '') {
      loadIncidentTypesAction({ regulatory_act: state.regulatory_act });
    }
  }, [IncidentTypesReducer.incidentTypes, loadIncidentTypesAction]);

  useEffect(() => {
    if (state.regulatory_act && state.regulatory_act !== '') {
      loadIncidentTypesAction({ regulatory_act: state.regulatory_act });
    }
  }, [loadIncidentTypesAction, state.regulatory_act]);

  useEffect(() => {
    if (!AffiliatesReducer.affiliates) {
      loadAffiliatesAction();
    }
  }, [AffiliatesReducer.affiliates, loadAffiliatesAction]);

  const setTabAsActive = (heading) => () => {
    setActiveTab(heading);
  };

  const sitesList = {};
  SitesReducer.sites &&
    SitesReducer.sites.forEach((site) => {
      sitesList[site.id] = site.name;
    });

  const affiliatesList = {};
  AffiliatesReducer.affiliates &&
    AffiliatesReducer.affiliates.forEach((affiliate) => {
      affiliatesList[affiliate.id] = affiliate.name;
    });

  const typesList = {};
  IncidentTypesReducer.incidentTypes &&
    IncidentTypesReducer.incidentTypes.forEach((incident) => {
      typesList[incident.id] = incident.name;
    });

  const handleCheckbox = (inputName) => (e) => {
    e.persist();
    setState((s) => ({
      ...s,
      [inputName]: e.target.checked,
    }));
    setStateErrors((s) => ({ ...s, [inputName]: '' }));
  };

  const handleSelect = (inputName) => (e) => {
    e.persist();
    setState((s) => ({
      ...s,
      [inputName]: e.target.attributes && e.target.attributes.value ? e.target.attributes.value.value : '',
    }));
    setStateErrors((s) => ({ ...s, [inputName]: '' }));
  };

  const handleRegSelect = (inputName) => (e) => {
    e.persist();
    setState((s) => ({
      ...s,
      [inputName]: e.target.attributes && e.target.attributes.value ? e.target.attributes.value.value : '',
      type: '',
    }));
    setStateErrors((s) => ({ ...s, [inputName]: '', type: '' }));
  };

  const handleChange = (inputName) => (e) => {
    e.persist();
    setState((s) => ({
      ...s,
      [inputName]: e.target.value,
    }));
    setStateErrors((s) => ({ ...s, [inputName]: '' }));
  };

  const saveNewIncident = () => {
    var today = moment();

    if (
      state.site === '' ||
      state.name === '' ||
      state.type === '' ||
      state.occurred === '' ||
      state.regulatory_act === '' ||
      state.discovered === '' ||
      today.diff(state.occurred) < 0 ||
      today.diff(state.discovered) < 0
    ) {
      setStateErrors({
        ...stateErrors,
        site: state.site === '' ? 'Site is required' : '',
        name: state.name === '' ? 'Incident title is required' : '',
        regulatory_act: state.regulatory_act === '' ? 'Regulatory act is required' : '',
        type: state.type === '' ? 'Incident type is required' : '',
        occurred: state.occurred === '' ? 'Incident date is required' : today.diff(state.occurred) < 0 ? 'Incident date must be in the past' : '',
        discovered: state.discovered === '' ? 'Discovery date is required' : today.diff(state.discovered) < 0 ? 'Discovery date must be in the past' : '',
      });
    } else {
      const result = {
        ...state,
        tasks: tasks.map((task) => {
          return {
            task: task.task,
            assignee: task.assignee,
            due: task.due,
          };
        }),
        files: files,
        notes: notes,
      };

      addNewIncidentAction(result);
    }
  };

  // Clear all fields
  const clearAllFields = () => {
    setState(defaultState);
    setTasks([]);
    setFiles([]);
  };

  // Close dropdown when clicking outside them
  const associatedSiteRef = React.createRef();
  const affiliateReportedRef = React.createRef();
  const reportedByRef = React.createRef();
  const incidentTypeRef = React.createRef();
  const regTypeRef = React.createRef();
  const investigationStatusRef = React.createRef();

  const is_officer = (UserOrgSiteReducer.selected && UserOrgSiteReducer.selected.is_officer) || false;
  const is_super = (UserOrgSiteReducer.selected && UserOrgSiteReducer.selected.is_super) || false;

  const onlyOwn = is_officer || is_super ? false : true;
  const userId = props.userData.id;

  const [note, setNote] = useState('');
  const [notes, setNotes] = useState([]);

  const addIncidentNote = () => {
    if (note && note !== '') {
      setNotes((notes) => [
        ...notes,
        {
          note_id: randomId(),
          name: props.userData.name,
          email: props.userData.email,
          notes: note,
          created_at: moment(),
        },
      ]);
      setNote('');
    }
  };

  const handleDelete = (data) => {
    let filteredNoets = notes.filter((note) => note.note_id != data.note_id);
    setNotes(filteredNoets);
  };

  const renderNotes = () => {
    return (
      <div className={styles.notes_tab}>
        <p className={styles['add-note-heading']}>Add Note</p>
        <div className={styles.notes_add}>
          <FormElement labelText="INCIDENT NOTE" htmlFor="note">
            <textarea
              rows="2"
              // className={styles.notes}
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
          </FormElement>
        </div>
        <Button size="md" className={styles['add-note-button']} text="ADD NOTE" onClick={addIncidentNote} />
        <div className={styles.notes_list}>
          <FullDataTable
            className={styles['add-note-table']}
            rowsData={notes}
            definitions={TableDefNotes}
            customTableProps={{
              onlyOwn,
              userId,
              handleDelete,
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <AppLayoutWrapFull onClick={toggleDropdowns([associatedSiteRef, regTypeRef, affiliateReportedRef, reportedByRef, incidentTypeRef, investigationStatusRef])}>
      <PageHeader
      // heading="Add New Incident"
      />
      <div>
        <form className={styles['add-new-incident-form']}>
          <Container wide className={styles['add-new-incident-container']}>
            <FormElement labelText="ASSOCIATED SITE" htmlFor="site" smallText="*" errorMessage={stateErrors.site || ''}>
              <Select
                labels={sitesList}
                value={state.site}
                placeholder="Select Site"
                disabled={state.investigating === '3' || (tasks && tasks.length > 0) ? true : false}
                onClick={handleSelect('site')}
                dropdownToggleRef={associatedSiteRef}
              >
                {SitesReducer.sites &&
                  SitesReducer.sites.map((site) => (
                    <li key={`siteid-${site.id}`} data-name={site.name} value={site.id}>
                      {site.name}
                    </li>
                  ))}
              </Select>
            </FormElement>
            <FormElement labelText="VENDOR REPORTED" htmlFor="affiliate" smallText="(if any)">
              <Select
                labels={affiliatesList}
                placeholder="** No Vendor reported"
                value={state['affiliate']}
                onClick={handleSelect('affiliate')}
                dropdownToggleRef={affiliateReportedRef}
              >
                {AffiliatesReducer &&
                  AffiliatesReducer.affiliates &&
                  AffiliatesReducer.affiliates.map((type) => (
                    <li key={`it_id-${type.id}`} data-name={type.name} value={type.id}>
                      {type.name}
                    </li>
                  ))}
              </Select>
            </FormElement>
            <FormElement labelText="INCIDENT TITLE" smallText="*" htmlFor="name" errorMessage={stateErrors['name'] || ''}>
              <input value={state['name']} onChange={handleChange('name')} />
            </FormElement>
            <FormElement labelText="REPORTED BY" htmlFor="reported_by" errorMessage={stateErrors['reported_by'] || ''}>
              <Select
                value={state['reported_by']}
                onClick={handleSelect('reported_by')}
                labels={{
                  [`${props.userData.name}`]: `${props.userData.name}`,
                  Anonymous: 'Anonymous',
                }}
                dropdownToggleRef={reportedByRef}
              >
                <li value={`${props.userData.name}`}>{props.userData.name}</li>
                <li value={'Anonymous'}>Anonymous</li>
              </Select>
            </FormElement>

            <FormElement labelText="REGULATORY ACT" htmlFor="regulatory_act" errorMessage={stateErrors['regulatory_act'] || ''}>
              <Select placeholder="Select regulatory act" value={state.regulatory_act} onClick={handleRegSelect('regulatory_act')} dropdownToggleRef={regTypeRef}>
                {OrgModulesReducer &&
                  OrgModulesReducer.data &&
                  OrgModulesReducer.data.map((d) => (
                    <li key={`regid-${d.name.toLowerCase()}`} value={d.name}>
                      {d.name}
                    </li>
                  ))}
                <li value="Other">Other</li>
              </Select>
            </FormElement>

            <FormElement labelText="INCIDENT TYPE" smallText="*" htmlFor="type" errorMessage={stateErrors['type'] || ''}>
              <Select labels={typesList} placeholder="Select incident type" value={state['type']} onClick={handleSelect('type')} dropdownToggleRef={incidentTypeRef}>
                {IncidentTypesReducer.incidentTypes &&
                  IncidentTypesReducer.incidentTypes
                    .filter((type) => type.regulatory_act === state.regulatory_act)
                    .map((type) => (
                      <li key={`it_id-${type.id}`} data-name={type.name} value={type.id}>
                        {type.name}
                      </li>
                    ))}
              </Select>

              {state.type !== '' && typesList[state.type] === 'Other' && (
                <input type="text" placeholder="Describe the other type of incident this is" onChange={handleChange('reason')} value={state['reason']} />
              )}
            </FormElement>

            <FormElement labelText="INVESTIGATION STATUS" htmlFor="investigating" className={styles['investigating']}>
              <Select disabled placeholder="" value="Not Verified" onClick={() => false} dropdownToggleRef={investigationStatusRef}>
                <li key="nv_id-0" data-name="Not Verified" value="Not Verified">
                  Not Verified
                </li>
              </Select>
            </FormElement>
            <Row className={styles['date-inputs-row']}>
              <FormElement labelText="INCIDENT DATE" htmlFor="occurred" smallText="*" errorMessage={stateErrors['occurred'] || ''}>
                <DateInput className={styles['date-input']} value={state['occurred']} onChange={handleChange('occurred')} maxDate={moment().format('YYYY-MM-DD')} />
              </FormElement>
              <FormElement labelText="DISCOVERY DATE" htmlFor="discovered" smallText="*" errorMessage={stateErrors['discovered'] || ''}>
                <DateInput className={styles['date-input']} value={state['discovered']} onChange={handleChange('discovered')} maxDate={moment().format('YYYY-MM-DD')} />
              </FormElement>
            </Row>
            <Checkbox labelText="This incident affected more than 500 members" checked={state['affected']} onChange={handleCheckbox('affected')} className={styles['affected']} />
          </Container>
          <Tabs
            tabs={[
              {
                heading: 'DESCRIPTION',
                icon: 'fad fa-engine-warning',
              },
              {
                heading: 'INVESTIGATION',
                icon: 'fad fa-tasks',
              },
              {
                heading: 'NOTES',
                icon: 'fad fa-pen',
              },
              {
                heading: 'EVIDENCE',
                icon: 'fad fa-cctv',
              },
            ]}
            activeTab={activeTab}
            setActiveTab={setTabAsActive}
          />
          <Container wide>
            {activeTab === 'NOTES' ? renderNotes() : ''}
            {activeTab === 'DESCRIPTION' ? (
              <textarea maxLength="8192" className={styles['incident-description']} rows="5" value={state.description} onChange={handleChange('description')} />
            ) : null}
            {activeTab === 'INVESTIGATION' ? (
              <AddTask regulatory_act={state.regulatory_act} setParentErrors={setStateErrors} tasks={tasks} site={state.site} setTasks={setTasks} allowAdd={true} />
            ) : null}

            {activeTab === 'EVIDENCE' ? (
              <AddEvidence
                username={state.reported_by || 'Anonymous'}
                files={files}
                setFiles={setFiles}
                // allowAdd={is_super || is_officer}
              />
            ) : null}

            <HR className={styles.hr} />
            <div className={styles['form-actions']}>
              <ClearAllFields onClick={clearAllFields} />
              <Button className={styles['cancel-button']} text="CANCEL" color="secondary" onClick={() => navigate(-1)} />
              <Button
                icon={AddIncidentFormReducer.status === 'ISBUSY' ? 'fad fa-spinner-third fa-spin' : ''}
                className={styles['update-incident-button']}
                text={AddIncidentFormReducer.status === 'ISBUSY' ? 'SAVING NEW INCIDENT' : 'SAVE NEW INCIDENT'}
                onClick={saveNewIncident}
              />
            </div>
          </Container>
        </form>
      </div>
    </AppLayoutWrapFull>
  );
};

const mapStateToProps = (state) => ({
  SitesReducer: state.SitesReducer,
  AffiliatesReducer: state.AffiliatesReducer,
  IncidentTypesReducer: state.IncidentTypesReducer,
  AddIncidentFormReducer: state.AddIncidentFormReducer,
  UserOrgSiteReducer: state.UserOrgSiteReducer,
  userData: state.AuthReducer.userData,
  OrgModulesReducer: state.OrgModulesReducer,
});

const mapDispatchToProps = {
  addNewIncidentAction,
  loadSitesAction,
  loadAffiliatesAction,
  loadIncidentTypesAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Add);
