import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import AppLayoutWrapFull from 'components/AppLayoutWrapFull';
import FullDataTable from 'components/DataTable/FullDataTable';
import Container from 'components/Container/Container';
import PageHeader from 'components/PageHeader/PageHeader';
import PageHeaderActions from 'components/PageHeader/components/PageHeaderActions/PageHeaderActions';

import TableDefDevices from 'table-defs/TableDefDevices';

import { useConfirmModal } from 'hooks';
import AddDeviceModal from './../components/AddDeviceModal/AddDeviceModal';
import DecommissionDeviceModal from './../components/DecommissionDeviceModal/DecommissionDeviceModal';
import BulkUploadModal from './components/BulkUploadModal/BulkUploadModal';

import useFilterRows from 'helpers/customHooks/useFilterRows';
import { setSelectedRows, toggleDropdowns } from 'helpers/utils';
import { loadSitesAction, loadUsersAction, loadDeviceAction, duplicateDeviceAction, deleteDeviceAction } from 'store/actions/Actions';
import Spinner from 'components/Spinner/Spinner';
import ActionStatusConstants from 'helpers/ActionStatusConstants';

const Audit = (props) => {
  const { SitesReducer, UsersReducer, UserOrgSiteReducer, loadSitesAction, loadUsersAction, loadDeviceAction, devices } = props;
  const [search] = useSearchParams();
  const auditId = search.get('id');

  const [device, setDevice] = useState(() => {});
  const [showAddDeviceModal, setShowAddDeviceModal] = useState(() => false);
  const [showDuplicateDeviceModal, setShowDuplicateDeviceModal] = useState(() => false);
  const [showDecommissionDeviceModal, setShowDecommissionDeviceModal] = useState(() => false);
  const [showBulkUploadModal, setShowBulkUploadModal] = useState(() => false);
  const [showConfirmModal, setShowConfirmModal, renderConfirmModal] = useConfirmModal();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (!SitesReducer.sites) {
      loadSitesAction();
    }
  }, [SitesReducer.sites, loadSitesAction]);

  useEffect(() => {
    const payload = UserOrgSiteReducer.selected.site ? { site: UserOrgSiteReducer.selected.site } : undefined;
    if (!UsersReducer.users) {
      loadUsersAction(payload);
    }
  }, [UsersReducer.users, loadUsersAction, UserOrgSiteReducer.selected.site]);

  useEffect(() => {
    if (UsersReducer.users && SitesReducer.sites) {
      loadDeviceAction({ auditId: auditId });
    }
  }, [loadDeviceAction, auditId, UsersReducer.users, SitesReducer.sites]);

  useEffect(() => {
    if (
      UsersReducer.status === ActionStatusConstants.SUCCESS &&
      SitesReducer.status === ActionStatusConstants.SUCCESS &&
      UserOrgSiteReducer.status === ActionStatusConstants.SUCCESS
    ) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [UsersReducer.users, SitesReducer.sites, UserOrgSiteReducer.selected]);

  const noFilters = {
    text: '',
    type: '',
    'last-action': '',
    status: '',
    role: '',
    site: '',
    start: '',
    end: '',
    selected_sites: [],
    dateField: 'assigned',
  };

  const [filters, setFilters] = useState(noFilters);

  // const setFilter = filterName => e => {
  //     setFilters({
  //         ...filters,
  //         [filterName]: e.target.type === "checkbox" ? e.target.checked : e.target.value || ((e.target && e.target.attributes && e.target.attributes.value && e.target.attributes.value.value) || '')
  //     });
  // };

  // const setDateFilter = filterName => value => {
  //     setFilters({
  //         ...filters,
  //         [filterName]: value
  //     });
  // };

  const filteredRows = useFilterRows(filters, devices);

  const handleAdd = () => {
    setDevice({});
    setShowAddDeviceModal(true);
  };

  const handleDuplicate = (device) => {
    props.duplicateDeviceAction({
      items: [device.id],
      auditId: auditId,
    });
  };

  const handleDelete = (device) => {
    props.deleteDeviceAction({
      items: [device.id],
      auditId: auditId,
    });
  };

  const handleDecommission = (device) => {
    setDevice(device);
    setShowDecommissionDeviceModal(true);
  };

  // const actDropdownRef = React.createRef();
  // const siteDropdownRef = React.createRef();
  const chooseActionDropdownRef = React.createRef();
  // const statusDropdownRef = React.createRef();

  const [selectedAuditIds, setSelectedAuditIds] = useState([]);
  const selectedIds = selectedAuditIds.map((item) => item.id);

  return (
    <AppLayoutWrapFull
      onClick={toggleDropdowns([
        // actDropdownRef,
        // siteDropdownRef,
        chooseActionDropdownRef,
        // statusDropdownRef,
      ])}
    >
      <PageHeader showStats={true}>
        <PageHeaderActions
          primaryButtonText="ADD DEVICE"
          primaryButtonHandler={handleAdd}
          secondaryButtonText="BULK UPLOAD"
          secondaryButtonHandler={() => {
            setShowBulkUploadModal(!showBulkUploadModal);
          }}
          searchHandler={(e) => setFilters({ ...filters, text: e.target.value || '' })}
          searchValue={filters.text}
          chooseAction={[
            {
              actionName: 'Duplicate Selected',
              actionHandler: () => {
                props.duplicateDeviceAction({
                  items: selectedIds,
                  auditId: auditId,
                });
              },
            },
            {
              actionName: 'Delete Selected',
              actionHandler: () => {
                const idCount = selectedIds.length;
                if (idCount > 0) {
                  setShowConfirmModal({
                    title: 'Delete selected devices',
                    question: idCount === 1 ? `Are you sure you want to delete the selected device?` : `Are you sure you want to delete ${idCount} selected devices?`,
                    confirmAction: () => {
                      props.deleteDeviceAction({
                        items: selectedIds,
                        auditId: auditId,
                      });
                    },
                  });
                } else {
                  setShowConfirmModal({
                    type: 'simple',
                    title: 'Nothing selected',
                    question: `Please select device to be deleted.`,
                  });
                }
              },
            },
          ]}
          chooseActionDropdownRef={chooseActionDropdownRef}
          site={{
            onClick: (e) => {
              if (e.target.type === 'checkbox') {
                let selected_sites = filters.selected_sites.slice();
                if (e.target.checked) {
                  selected_sites.push(e.target.value);
                } else {
                  selected_sites = selected_sites.filter((site) => site !== e.target.value);
                }
                setFilters({
                  ...filters,
                  site: selected_sites.length > 0 ? `Sites (${selected_sites.length})` : 'Site',
                  // site: "Site",
                  selected_sites: selected_sites,
                });
              }
            },
            sites: SitesReducer.sites
              ? SitesReducer.sites.map((site) => {
                  return {
                    id: site.id,
                    siteName: site.name,
                    value: filters.selected_sites.includes(site.id) ? true : false,
                  };
                })
              : [],
          }}
          // siteDropdownRef={siteDropdownRef}
          // status={{
          //     onChange: setFilter("status"),
          //     options: ["All", "Finalized", "Not Finalized"]
          // }}
          // statusDropdownRef={statusDropdownRef}
          // dateRange={{
          //     onChangeStart: setDateFilter("start"),
          //     onChangeEnd: setDateFilter("end")
          // }}
          filters={filters}
          resetFilters={() => {
            setFilters(noFilters);
          }}
        />
      </PageHeader>
      {devices && !isLoading ? (
        <Container wide fluid>
          {showBulkUploadModal && <BulkUploadModal auditId={auditId} setShowModal={setShowBulkUploadModal} />}
          <div>
            <FullDataTable
              rowsData={filteredRows}
              definitions={TableDefDevices}
              onChangeVisibility={() => false}
              onRowClick={function (e) {
                if (e.currentTarget.classList.contains('col-actions') || e.currentTarget.classList.contains('col-row-selector')) {
                  return;
                } else {
                  setDevice(this.props.rowData);
                  setShowAddDeviceModal(true);
                }
              }}
              setSelectedRows={setSelectedRows(['id'], setSelectedAuditIds)}
              customTableProps={{
                handleDuplicate,
                handleDecommission,
                handleDelete,
              }}
            />

            {showAddDeviceModal && ReactDOM.createPortal(<AddDeviceModal device={device} users={UsersReducer.users} setShowAddDeviceModal={setShowAddDeviceModal} auditId={auditId} />, document.querySelector('#modal-root'))}
            {showDuplicateDeviceModal && ReactDOM.createPortal(<AddDeviceModal device={device} setShowAddDeviceModal={setShowDuplicateDeviceModal} auditId={auditId} />, document.querySelector('#modal-root'))}
            {showDecommissionDeviceModal && ReactDOM.createPortal(<DecommissionDeviceModal setShowAddDeviceModal={setShowDecommissionDeviceModal} auditId={auditId} device={device} />, document.querySelector('#modal-root'))}
          </div>
          {showConfirmModal && renderConfirmModal(showConfirmModal, setShowConfirmModal)}
        </Container>
      ) : (
        <Spinner />
      )}
    </AppLayoutWrapFull>
  );
};

const mapStateToProps = (state) => ({
  SitesReducer: state.SitesReducer,
  UsersReducer: state.UsersReducer,
  devices: state.DevicesReducer.devices,
  UserOrgSiteReducer: state.UserOrgSiteReducer,
});

const mapDispatchToProps = {
  loadSitesAction,
  loadUsersAction,
  loadDeviceAction,
  duplicateDeviceAction,
  deleteDeviceAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Audit);
