import React from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import styles from './LineChartBox.module.scss';

function getPercent({ complete, total }) {
  return Math.floor((complete / total) * 100) || 0;
}

const Line = ({ url, stats, backgroundColor, color, label }) => {
  const navigate = useNavigate();
  const style = classNames(styles.line, url && styles.isLink);
  return (
    <div className={style} onClick={() => (url ? navigate(url) : null)}>
      <div className={styles.line_holder}>
        <span>{label}</span>
        <span className={styles.line_inner}>
          <span style={{ marginRight: '10px' }}>
            <span style={{ color: '#1C1C1E' }}>{stats.complete}</span> <span style={{ color: '#999' }}>/ {stats.total} total</span>
          </span>
          <span className={styles.percent} style={{ color }}>{`${getPercent(stats)}%`}</span>
        </span>
      </div>
      <div className={styles.line_graph} style={{ backgroundColor }}>
        <span style={{ width: `${getPercent(stats)}%`, backgroundColor: color }}>&nbsp;</span>
      </div>
    </div>
  );
};

export default Line;
