import { call, put, takeLatest, select, delay } from 'redux-saga/effects';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { actionApiCall } from 'helpers/api/ApiCalls';
import * as sagaConstants from 'helpers/constants/SagasConstants';

const getSites = (state) => state.SitesReducer.sites;
const getSelected = (state) => state.UserOrgSiteReducer.selected;

function transformRemediations(plans, sites) {
  const statusCodes = {
    0: 'Pending',
    1: 'Incomplete',
    2: 'Complete',
    3: 'Closed incomplete',
    4: 'Deactivated incomplete',
  };
  const result = plans
    .filter((item) => item.active === 't')
    .map((element, index) => {
      element.index = index;
      element['deactivated'] = element['status'] === '4' ? 't' : 'f';
      element['status'] = statusCodes[element['status']];
      if (sites) {
        sites.forEach((item) => {
          if (item.id === element.site) {
            element.siteName = item.name;
          }
        });
      }
      return element;
    });
  return result;
}

function* loadRemediationPlansSaga({ payload }) {
  try {
    const selected = yield select(getSelected);
    const apiResponse = yield call(actionApiCall, {
      actionType: ActionTypeConstants.LOAD_REMEDIATION_PLANS,
      data: {
        organization: selected.org,
        // site: 1,
        id: payload && payload.id ? payload.id : 0,
      },
    });
    if (apiResponse.res.data.response === 200) {
      const { data } = apiResponse.res.data;
      const sites = yield select(getSites);
      yield put({
        type: ActionTypeConstants.LOAD_REMEDIATION_PLANS_SUCCESS,
        payload: {
          remediation_plans: transformRemediations(data, sites),
        },
      });
    } else {
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: apiResponse.res.data.message || `${sagaConstants.ISSUE_OCCURED} loading remediation plans`,
          color: 'danger',
        },
      });
      yield delay(10000);
      yield put({ type: ActionTypeConstants.HIDE_ALERT });
    }
  } catch (err) {
    yield put({
      type: ActionTypeConstants.SHOW_ALERT,
      payload: {
        message: `${sagaConstants.SERVER_ERROR}`,
        color: 'danger',
      },
    });
    yield delay(10000);
    yield put({ type: ActionTypeConstants.HIDE_ALERT });
  }
}

export default function* () {
  yield takeLatest(ActionTypeConstants.LOAD_REMEDIATION_PLANS, loadRemediationPlansSaga);
}
