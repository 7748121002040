import classNames from 'classnames';
import { useAppDispatch } from 'hooks';
import React, { useEffect } from 'react';

import styles from './ModalAlerts.module.scss';

interface IModalAlerts {
  setShowModal?: (showModal: boolean) => void;
  hideAlertAction?: () => void;
  overlayClassName?: string;
  disableCloseButton?: boolean;
  children?: string | React.ReactNode;
  className?: string;
}

const defaultProps = {
  setShowModal: undefined,
  hideAlertAction: undefined,
  overlayClassName: '',
  disableCloseButton: undefined,
  children: undefined,
  className: undefined,
};

export const useKeyPress = (targetKey, fn) => {
  function downHandler({ key }) {
    if (key === targetKey) {
      fn();
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  });
};

function ModalAlerts({ setShowModal, hideAlertAction, overlayClassName, disableCloseButton, children, className }: IModalAlerts) {
  const dispatch = useAppDispatch();

  useKeyPress('Escape', () => {
    if (setShowModal) {
      setShowModal(false);
      dispatch(hideAlertAction());
    }
  });

  const handleClose = (e) => {
    if (setShowModal) {
      setShowModal(false);
      dispatch(hideAlertAction());
      e.preventDefault();
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
    }
    return false;
  };

  const styleOverlay = classNames(styles.overlay, overlayClassName);
  const style = classNames('fal fa-times', styles['close-modal']);
  const styleModal = classNames(styles.modal, className);

  return (
    <>
      <div className={styleOverlay} onClick={(e) => handleClose(e)} aria-hidden="true" />
      <div className={styles.holder}>
        <div className={styleModal}>
          {!disableCloseButton && (
            <i
              className={style}
              onClick={() => {
                if (setShowModal) {
                  setShowModal(false);
                  dispatch(hideAlertAction());
                }
              }}
              aria-hidden="true"
            />
          )}
          {children}
        </div>
      </div>
    </>
  );
}

ModalAlerts.defaultProps = defaultProps;

export default ModalAlerts;
