import ActionStatusConstants from 'helpers/ActionStatusConstants';
import ActionTypeConstants from 'helpers/ActionTypeConstants';

const initialState = {
  status: ActionStatusConstants.INITIAL,
  message: undefined,
  formErrors: undefined,
  selected: {},
  organizations: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypeConstants.LOAD_USER_ORG_SITE: {
      return {
        ...state,
        status: ActionStatusConstants.ISBUSY,
      };
    }
    case ActionTypeConstants.LOAD_USER_ORG_SITE_SUCCESS: {
      return {
        ...state,
        organizations: payload.organizations,
        status: ActionStatusConstants.SUCCESS,
        message: payload?.message,
      };
    }
    case ActionTypeConstants.LOAD_USER_ORG_SITE_FAILURE: {
      return {
        ...state,
        status: ActionStatusConstants.FAILURE,
        message: payload?.message,
      };
    }
    case ActionTypeConstants.SET_USER_ORG_SITE_SUCCESS: {
      return {
        ...state,
        organizations: payload?.organizations || state.organizations,
        selected: payload?.selected || state.selected,
        status: ActionStatusConstants.SUCCESS,
        message: payload?.message,
      };
    }
    // case LOCATION_CHANGE:
    //   return {
    //     ...initialState
    //   };
    default:
  }
  return state;
};
