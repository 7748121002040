import React, { useState } from 'react';

import HR from 'components/HR/HR';
import FormElement from 'components/FormElement/FormElement';

import UploadDocument from 'components/UploadDocument/UploadDocument';

import Select from 'components/Select/Select';
import { Button } from 'components/Button/Button';
import FullDataTable from 'components/DataTable/FullDataTable';
import TableDefEditAffiliates from 'table-defs/TableDefEditAffiliates';

import { toggleDropdowns, randomId } from 'helpers/utils';
import { useConfirmModal } from 'hooks';

import styles from './UploadDocuments.module.scss';

function UploadDocuments(props) {
  // Current state
  const [state, setState] = useState({
    name: '',
    'document-type': '',
    type: 'Vendor Audit',
    file: '',
  });
  const [showConfirmModal, setShowConfirmModal, renderConfirmModal] = useConfirmModal();

  const handleSelectChange = (inputName) => (e) => {
    const inputType = e.target.getAttribute('value');
    setState((s) => ({ ...s, [inputName]: `${inputType}` }));
  };

  const removeDocument = (payload) => {
    try {
      if (props.deleteDocument && payload.repository) {
        setShowConfirmModal({
          title: 'Delete document',
          question: `You're about to permanently delete document "${payload.name}"! Are you sure?`,
          confirmAction: () => {
            props.deleteDocument({ items: [payload.id] });
            props.setDocuments(props.documents.filter((doc) => doc.id !== payload.id));
          },
        });
      } else {
        props.setDocuments(props.documents.filter((doc) => doc.id !== payload.id));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const approveDocument = (rawData) => {
    if (rawData.id) {
      const d = props.documents.map((doc) => ({
        ...doc,
        approved: doc.id === rawData.id ? true : doc.approved || false,
      }));
      props.setDocuments(d);
      // props.approveDocument({ items: [rawData.id] });
    }
  };

  const unapproveDocument = (rawData) => {
    if (rawData.id) {
      const d = props.documents.map((doc) => ({
        ...doc,
        approved: doc.id === rawData.id ? false : doc.approved || false,
      }));
      props.setDocuments(d);
      // props.unapproveDocument({ items: [rawData.id] });
    }
  };

  // "Uploads" document to parent state
  const uploadDocument = () => {
    props.setDocuments([
      ...props.documents,
      {
        id: `tmpid_${randomId()}`,
        name: state.name,
        type: state.type,
        file: state.file,
      },
    ]);

    // clear doc after update
    setState({
      name: '',
      'document-type': '',
      type: 'Vendor Audit',
      file: '',
    });
  };

  // Used to close the dropdown when clicking outside of it if it's open
  const typeDropdownRef = React.createRef();
  const docDropdownRef = React.createRef();

  return (
    <div className={`${styles['upload-documents']} ${props.className || ''}`} onClick={toggleDropdowns([typeDropdownRef, docDropdownRef])} aria-hidden="true">
      <p className={styles.subheading}>Upload Documents</p>

      <HR className={styles.hr} />

      {showConfirmModal && renderConfirmModal(showConfirmModal, setShowConfirmModal)}

      <div className={styles['upload-document-options']}>
        <FormElement labelText="DOCUMENTS" htmlFor="documents">
          <UploadDocument state={state} setState={setState} formats={['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'pdf']} />
        </FormElement>
        <FormElement labelText="TYPE" htmlFor="type">
          <Select value={state.type} placeholder="Select document type" onClick={handleSelectChange('type')} dropdownToggleRef={typeDropdownRef}>
            <li value="Vendor Audit">Vendor Audit</li>
            <li value="BA Agreement">BA Agreement</li>
            <li value="Miscellaneous">Miscellaneous</li>
          </Select>
        </FormElement>
        <Button className={styles['upload-button']} text="UPLOAD" icon="fas fa-arrow-circle-up" onClick={uploadDocument} disabled={state.file === ''} />
      </div>

      <FullDataTable
        className={styles['upload-documents-table']}
        rowsData={props.documents}
        definitions={TableDefEditAffiliates}
        customTableProps={{
          removeDocument: props.deleteDocument ? removeDocument : undefined,
          approveDocument,
          unapproveDocument,
        }}
      />
    </div>
  );
}

export default UploadDocuments;
