import React, { useState } from 'react';
import { MainNavigationTree } from 'RouteHelper';
// import SwipeableDrawer from "@material-ui/core/SwipeableDrawer"
import { SwipeableDrawer } from '@mui/material';

import { useAppSelector } from 'hooks';
import NavListItem from './NavListItem';

/**
 * List item for navigation.
 */
function HamburgerNav() {
  const UserOrgSiteReducer = useAppSelector((state) => state.UserOrgSiteReducer);
  const [state, setState] = useState({
    isDrawerOpen: false,
    selectedMainNavItem: MainNavigationTree[1],
    selectedSubNavItem: MainNavigationTree[1].children[1],
  });

  // Fix as per https://material-ui.com/demos/drawers/
  const isIOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

  // Changes selected main navigation item
  const changeSelectedMainNavItem = (navItem) => {
    if (state.selectedMainNavItem !== navItem) {
      setState((s) => ({
        ...s,
        selectedMainNavItem: navItem,
      }));
    }
  };

  const resetSelectedMainNavItem = () => {
    setState((s) => ({
      ...s,
      selectedMainNavItem: MainNavigationTree[1],
      selectedSubNavItem: MainNavigationTree[1].children[1],
    }));
  };

  // Renders subnavigation items for current main item
  const renderSubNavNodes = (is_admin) => {
    let navNodes = [];
    const navItemsUser = [
      {
        routeDefinition: {
          classname: 'dashboard',
          icon: 'fal fa-tachometer',
          key: 'DASHBOARD',
          path: '/',
          title: 'Dashboard',
        },
      },
      {
        routeDefinition: {
          classname: 'dashboard',
          icon: 'fal fa-copy',
          key: 'DOCUMENTS',
          path: '/documents',
          title: 'Report incident',
        },
      },
      {
        routeDefinition: {
          classname: 'dashboard',
          icon: 'fal fa-comment-alt-exclamation',
          key: 'INCIDENTS',
          path: 'https://compliancygroup.freshdesk.com/support/home',
          title: 'Knowledge Base',
        },
      },
    ];

    const navItems = is_admin
      ? state.selectedMainNavItem && state.selectedMainNavItem.children && state.selectedMainNavItem.children.length > 0
        ? state.selectedMainNavItem.children
        : undefined
      : navItemsUser;
    // MOBILE DRAWER
    // ADD support for not showing all route definitions as subnavnode
    if (navItems) {
      navNodes = navItems.map((navItem) => {
        const routeDef = navItem.routeDefinition;
        return (
          !routeDef.hidden && (
            <NavListItem
              className="sub"
              key={routeDef.key}
              path={routeDef.path}
              isActive={state.selectedSubNavItem === navItem}
              isDisabled={!routeDef.path}
              title={routeDef.title}
            />
          )
        );
      });
    }
    return navNodes;
  };

  const toggleDrawer = ({ closeOnly = false } = {}) => {
    if (state.isDrawerOpen === false) {
      resetSelectedMainNavItem();
    }
    if (closeOnly === true) {
      if (state.isDrawerOpen) {
        setState((s) => ({
          ...s,
          isDrawerOpen: false,
        }));
      }
    } else {
      setState((s) => ({
        ...s,
        isDrawerOpen: !state.isDrawerOpen,
      }));
    }
  };

  const is_admin = !!(UserOrgSiteReducer && UserOrgSiteReducer.selected && UserOrgSiteReducer.selected.is_admin);

  // Renders main navigation items
  const renderMainNavNodes = (is_admin) => {
    let navNodes = [];
    const navItems = is_admin ? (MainNavigationTree && MainNavigationTree.length > 0 ? MainNavigationTree : undefined) : undefined;

    if (navItems) {
      navNodes = navItems.map((navItem) => {
        const routeDef = navItem.routeDefinition;
        return (
          <NavListItem
            className="main"
            key={routeDef.key}
            path={routeDef.path === '/' ? routeDef.path : undefined}
            isActive={state.selectedMainNavItem === navItem}
            onClick={() => changeSelectedMainNavItem(navItem)}
            icon={<i className={`icon ${routeDef.icon || 'fal fa-link'}`} />}
            title={routeDef.title}
          />
        );
      });
    }

    return navNodes;
  };

  return (
    <>
      <button type="button" className="hamburger-menu-toggle" onClick={() => toggleDrawer()}>
        <i className="fas fa-bars" />
      </button>
      <SwipeableDrawer
        className="nav-drawer"
        open={state.isDrawerOpen}
        onClose={() => toggleDrawer()}
        onOpen={() => toggleDrawer()}
        disableBackdropTransition={!isIOS}
        disableDiscovery={isIOS}
      >
        <div className="nav-drawer-content">
          <div className="main-nav">
            <ul>{renderMainNavNodes(is_admin)}</ul>
          </div>
          <div className="sub-nav">
            <ul>{renderSubNavNodes(is_admin)}</ul>
          </div>
        </div>
      </SwipeableDrawer>
    </>
  );
}

export default HamburgerNav;
