export default {
  SUBMIT_USER_LOGIN_FORM: 'SUBMIT_USER_LOGIN_FORM',
  SUBMIT_USER_LOGIN_FORM_SUCCESS: 'SUBMIT_USER_LOGIN_FORM_SUCCESS',
  SUBMIT_USER_LOGIN_FORM_FAILURE: 'SUBMIT_USER_LOGIN_FORM_FAILURE',
  SUBMIT_USER_LOGIN_FORM_RESET: 'SUBMIT_USER_LOGIN_FORM_RESET',

  SUBMIT_USER_LOGOUT_FORM: 'SUBMIT_USER_LOGOUT_FORM',
  SUBMIT_USER_LOGOUT_FORM_SUCCESS: 'SUBMIT_USER_LOGOUT_FORM_SUCCESS',
  SUBMIT_USER_LOGOUT_FORM_FAILURE: 'SUBMIT_USER_LOGOUT_FORM_FAILURE',
  SUBMIT_USER_LOGOUT_FORM_RESET: 'SUBMIT_USER_LOGOUT_FORM_RESET',

  AUTH_GET_USER: 'AUTH_GET_USER',
  AUTH_LOGOUT: 'AUTH_LOGOUT',
  AUTH_LOGGED_IN_SUCCESS: 'AUTH_LOGGED_IN_SUCCESS',
  AUTH_LOGGED_OUT_SUCCESS: 'AUTH_LOGGED_OUT_SUCCESS',

  // Registration Form Action types
  SUBMIT_USER_REGISTER_FORM: 'SUBMIT_USER_REGISTER_FORM',
  SUBMIT_USER_REGISTER_FORM_SUCCESS: 'SUBMIT_USER_REGISTER_FORM_SUCCESS',
  SUBMIT_USER_REGISTER_FORM_FAILURE: 'SUBMIT_USER_REGISTER_FORM_FAILURE',

  GET_USER_BY_KEY: 'GET_USER_BY_KEY',
  GET_USER_BY_KEY_SUCCESS: 'GET_USER_BY_KEY_SUCCESS',
  GET_USER_BY_KEY_FAILURE: 'GET_USER_BY_KEY_FAILURE',

  SUBMIT_USER_FORGOT_PASSWORD: 'SUBMIT_USER_FORGOT_PASSWORD',
  SUBMIT_USER_FORGOT_PASSWORD_SUCCESS: 'SUBMIT_USER_FORGOT_PASSWORD_SUCCESS',
  SUBMIT_USER_FORGOT_PASSWORD_FAILURE: 'SUBMIT_USER_FORGOT_PASSWORD_FAILURE',

  SUBMIT_USER_RESET_PASSWORD: 'SUBMIT_USER_RESET_PASSWORD',
  SUBMIT_USER_RESET_PASSWORD_SUCCESS: 'SUBMIT_USER_RESET_PASSWORD_SUCCESS',
  SUBMIT_USER_RESET_PASSWORD_FAILURE: 'SUBMIT_USER_RESET_PASSWORD_FAILURE',

  SUBMIT_USER_SETTINGS_FORM: 'SUBMIT_USER_SETTINGS_FORM',
  SUBMIT_USER_SETTINGS_FORM_SUCCESS: 'SUBMIT_USER_SETTINGS_FORM_SUCCESS',
  SUBMIT_USER_SETTINGS_FORM_FAILURE: 'SUBMIT_USER_SETTINGS_FORM_FAILURE',

  LOAD_USER_SETTINGS_FORM: 'LOAD_USER_SETTINGS_FORM',
  LOAD_USER_SETTINGS_FORM_SUCCESS: 'LOAD_USER_SETTINGS_FORM_SUCCESS',
  LOAD_USER_SETTINGS_FORM_FAILURE: 'LOAD_USER_SETTINGS_FORM_FAILURE',

  // Load all users list Action types
  LOAD_ALL_USERS_LIST: 'LOAD_ALL_USERS_LIST',
  LOAD_ALL_USERS_LIST_SUCCESS: 'LOAD_ALL_USERS_LIST_SUCCESS',
  LOAD_ALL_USERS_LIST_FAILURE: 'LOAD_ALL_USERS_LIST_FAILURE',
  RESET_ALL_USERS_LIST: 'RESET_ALL_USERS_LIST',

  // Load all states list Action types
  LOAD_ALL_SITES_LIST: 'LOAD_ALL_SITES_LIST',
  LOAD_ALL_SITES_LIST_SUCCESS: 'LOAD_ALL_SITES_LIST_SUCCESS',
  LOAD_ALL_SITES_LIST_FAILURE: 'LOAD_ALL_SITES_LIST_FAILURE',

  // ADD USER Form Action types
  SUBMIT_ADD_USER_FORM: 'SUBMIT_ADD_USER_FORM',
  SUBMIT_ADD_USER_FORM_SUCCESS: 'SUBMIT_ADD_USER_FORM_SUCCESS',
  SUBMIT_ADD_USER_FORM_FAILURE: 'SUBMIT_ADD_USER_FORM_FAILURE',

  // ADD OFFICER Form Action types
  SUBMIT_ADD_OFFICER_FORM: 'SUBMIT_ADD_OFFICER_FORM',
  SUBMIT_ADD_OFFICER_FORM_SUCCESS: 'SUBMIT_ADD_OFFICER_FORM_SUCCESS',
  SUBMIT_ADD_OFFICER_FORM_FAILURE: 'SUBMIT_ADD_OFFICER_FORM_FAILURE',

  // EDIT USER Form Action types
  SUBMIT_EDIT_USER_FORM: 'SUBMIT_EDIT_USER_FORM',
  SUBMIT_EDIT_USER_FORM_SUCCESS: 'SUBMIT_EDIT_USER_FORM_SUCCESS',
  SUBMIT_EDIT_USER_FORM_FAILURE: 'SUBMIT_EDIT_USER_FORM_FAILURE',

  // LOAD ACTS Action types
  LOAD_ACTS: 'LOAD_ACTS',
  LOAD_ACTS_SUCCESS: 'LOAD_ACTS_SUCCESS',
  LOAD_ACTS_FAILURE: 'LOAD_ACTS_FAILURE',

  // LOAD ACTS Action types
  LOAD_ACTS_ALL: 'LOAD_ACTS_ALL',
  LOAD_ACTS_ALL_SUCCESS: 'LOAD_ACTS_ALL_SUCCESS',
  LOAD_ACTS_ALL_FAILURE: 'LOAD_ACTS_ALL_FAILURE',

  // LOAD SITES Action types
  LOAD_SITES: 'LOAD_SITES',
  LOAD_SITES_SUCCESS: 'LOAD_SITES_SUCCESS',
  LOAD_SITES_FAILURE: 'LOAD_SITES_FAILURE',

  // LOAD AUDITS Action types
  LOAD_AUDITS: 'LOAD_AUDITS',
  LOAD_AUDITS_SUCCESS: 'LOAD_AUDITS_SUCCESS',
  LOAD_AUDITS_FAILURE: 'LOAD_AUDITS_FAILURE',

  // UPDATE AUDIT Action types
  UPDATE_AUDIT: 'UPDATE_AUDIT',
  UPDATE_AUDIT_SUCCESS: 'UPDATE_AUDIT_SUCCESS',
  UPDATE_AUDIT_FAILURE: 'UPDATE_AUDIT_FAILURE',

  // UPDATE AUDIT Action types
  EDIT_AUDIT: 'EDIT_AUDIT',
  EDIT_AUDIT_SUCCESS: 'EDIT_AUDIT_SUCCESS',
  EDIT_AUDIT_FAILURE: 'EDIT_AUDIT_FAILURE',

  // CREATE AUDIT Action types
  CREATE_AUDIT: 'CREATE_AUDIT',
  CREATE_AUDIT_SUCCESS: 'CREATE_AUDIT_SUCCESS',
  CREATE_AUDIT_FAILURE: 'CREATE_AUDIT_FAILURE',

  // LOAD AUDIT Action types
  LOAD_AUDIT: 'LOAD_AUDIT',
  LOAD_AUDIT_SUCCESS: 'LOAD_AUDIT_SUCCESS',
  LOAD_AUDIT_FAILURE: 'LOAD_AUDIT_FAILURE',

  // DELETE AUDIT Action types
  DELETE_AUDIT: 'DELETE_AUDIT',
  DELETE_AUDIT_SUCCESS: 'DELETE_AUDIT_SUCCESS',
  DELETE_AUDIT_FAILURE: 'DELETE_AUDIT_FAILURE',

  // DELETE AUDIT Action types
  NOTIFY_AUDIT: 'NOTIFY_AUDIT',
  NOTIFY_AUDIT_SUCCESS: 'NOTIFY_AUDIT_SUCCESS',
  NOTIFY_AUDIT_FAILURE: 'NOTIFY_AUDIT_FAILURE',

  // COMPLETE AUDIT Action types
  COMPLETE_AUDIT: 'COMPLETE_AUDIT',
  COMPLETE_AUDIT_SUCCESS: 'COMPLETE_AUDIT_SUCCESS',
  COMPLETE_AUDIT_FAILURE: 'COMPLETE_AUDIT_FAILURE',

  // REOPEN AUDIT Action types
  REOPEN_AUDIT: 'REOPEN_AUDIT',
  REOPEN_AUDIT_SUCCESS: 'REOPEN_AUDIT_SUCCESS',
  REOPEN_AUDIT_FAILURE: 'REOPEN_AUDIT_FAILURE',

  DOWNLOAD_AUDIT: 'DOWNLOAD_AUDIT',
  DOWNLOAD_AUDIT_SUCCESS: 'DOWNLOAD_AUDIT_SUCCESS',
  DOWNLOAD_AUDIT_FAILURE: 'DOWNLOAD_AUDIT_FAILURE',

  UPLOAD_AUDIT: 'UPLOAD_AUDIT',
  UPLOAD_AUDIT_SUCCESS: 'UPLOAD_AUDIT_SUCCESS',
  UPLOAD_AUDIT_FAILURE: 'UPLOAD_AUDIT_FAILURE',

  // ADD REMEDIATION PLAN Action types
  LOAD_REMEDIATION_PLANS: 'LOAD_REMEDIATION_PLANS',
  LOAD_REMEDIATION_PLANS_SUCCESS: 'LOAD_REMEDIATION_PLANS_SUCCESS',
  LOAD_REMEDIATION_PLANS_FAILURE: 'LOAD_REMEDIATION_PLANS_FAILURE',

  // ADD REMEDIATION PLAN Action types
  SUBMIT_ADD_REMEDIATION_PLAN_FORM: 'SUBMIT_ADD_REMEDIATION_PLAN_FORM',
  SUBMIT_ADD_REMEDIATION_PLAN_FORM_SUCCESS: 'SUBMIT_ADD_REMEDIATION_PLAN_FORM_SUCCESS',
  SUBMIT_ADD_REMEDIATION_PLAN_FORM_FAILURE: 'SUBMIT_ADD_REMEDIATION_PLAN_FORM_FAILURE',

  // LOAD EDIT REMEDIATION PLAN Action types
  LOAD_EDIT_REMEDIATION_PLAN_FORM: 'LOAD_EDIT_REMEDIATION_PLAN_FORM',
  LOAD_EDIT_REMEDIATION_PLAN_FORM_SUCCESS: 'LOAD_EDIT_REMEDIATION_PLAN_FORM_SUCCESS',
  LOAD_EDIT_REMEDIATION_PLAN_FORM_FAILURE: 'LOAD_EDIT_REMEDIATION_PLAN_FORM_FAILURE',

  // EDIT REMEDIATION PLAN Action types
  SUBMIT_EDIT_REMEDIATION_PLAN_FORM: 'SUBMIT_EDIT_REMEDIATION_PLAN_FORM',
  SUBMIT_EDIT_REMEDIATION_PLAN_FORM_SUCCESS: 'SUBMIT_EDIT_REMEDIATION_PLAN_FORM_SUCCESS',
  SUBMIT_EDIT_REMEDIATION_PLAN_FORM_FAILURE: 'SUBMIT_EDIT_REMEDIATION_PLAN_FORM_FAILURE',

  COMPLETE_REMEDIATION_PLAN: 'COMPLETE_REMEDIATION_PLAN',
  INCOMPLETE_REMEDIATION_PLAN: 'INCOMPLETE_REMEDIATION_PLAN',
  NOTIFY_REMEDIATION_PLAN: 'NOTIFY_REMEDIATION_PLAN',
  DELETE_REMEDIATION_PLAN: 'DELETE_REMEDIATION_PLAN',

  // LOAD AFFILIATE Action types
  LOAD_AFFILIATES: 'LOAD_AFFILIATES',
  LOAD_AFFILIATES_SUCCESS: 'LOAD_AFFILIATES_SUCCESS',
  LOAD_AFFILIATES_FAILURE: 'LOAD_AFFILIATES_FAILURE',

  // ADD AFFILIATE Action types
  // ADD_EDIT_AFFILIATE_FORM: "ADD_EDIT_AFFILIATE_FORM",
  // ADD_EDIT_AFFILIATE_FORM_SUCCESS: "ADD_EDIT_AFFILIATE_FORM_SUCCESS",
  // ADD_EDIT_AFFILIATE_FORM_FAILURE: "ADD_EDIT_AFFILIATE_FORM_FAILURE",

  // LOAD EDIT AFFILIATE Action types
  LOAD_EDIT_AFFILIATE_FORM: 'LOAD_EDIT_AFFILIATE_FORM',
  LOAD_EDIT_AFFILIATE_FORM_SUCCESS: 'LOAD_EDIT_AFFILIATE_FORM_SUCCESS',
  LOAD_EDIT_AFFILIATE_FORM_FAILURE: 'LOAD_EDIT_AFFILIATE_FORM_FAILURE',

  // EDIT AFFILIATE Action types
  SUBMIT_EDIT_AFFILIATE_FORM: 'SUBMIT_EDIT_AFFILIATE_FORM',
  SUBMIT_EDIT_AFFILIATE_FORM_SUCCESS: 'SUBMIT_EDIT_AFFILIATE_FORM_SUCCESS',
  SUBMIT_EDIT_AFFILIATE_FORM_FAILURE: 'SUBMIT_EDIT_AFFILIATE_FORM_FAILURE',

  // ADD AFFILIATE Action types
  SUBMIT_ADD_AFFILIATE_FORM: 'SUBMIT_ADD_AFFILIATE_FORM',
  SUBMIT_ADD_AFFILIATE_FORM_SUCCESS: 'SUBMIT_ADD_AFFILIATE_FORM_SUCCESS',
  SUBMIT_ADD_AFFILIATE_FORM_FAILURE: 'SUBMIT_ADD_AFFILIATE_FORM_FAILURE',

  // DELETE AFFILIATE Action types
  DELETE_AFFILIATE: 'DELETE_AFFILIATE',
  DELETE_AFFILIATE_SUCCESS: 'DELETE_AFFILIATE_SUCCESS',
  DELETE_AFFILIATE_FAILURE: 'DELETE_AFFILIATE_FAILURE',

  // NOTIFY_AFFILIATE Action types
  NOTIFY_AFFILIATE: 'NOTIFY_AFFILIATE',
  NOTIFY_AFFILIATE_SUCCESS: 'NOTIFY_AFFILIATE_SUCCESS',
  NOTIFY_AFFILIATE_FAILURE: 'NOTIFY_AFFILIATE_FAILURE',

  // SEND_VENDOR_AUDIT_AFFILIATE Action types
  SEND_VENDOR_AUDIT_AFFILIATE: 'SEND_VENDOR_AUDIT_AFFILIATE',
  SEND_VENDOR_AUDIT_AFFILIATE_SUCCESS: 'SEND_VENDOR_AUDIT_AFFILIATE_SUCCESS',
  SEND_VENDOR_AUDIT_AFFILIATE_FAILURE: 'SEND_VENDOR_AUDIT_AFFILIATE_FAILURE',

  SAVE_VENDOR_AUDIT_AFFILIATE: 'SAVE_VENDOR_AUDIT_AFFILIATE',
  SAVE_VENDOR_AUDIT_AFFILIATE_SUCCESS: 'SAVE_VENDOR_AUDIT_AFFILIATE_SUCCESS',
  SAVE_VENDOR_AUDIT_AFFILIATE_FAILURE: 'SAVE_VENDOR_AUDIT_AFFILIATE_FAILURE',

  READ_VENDOR_AUDIT_AFFILIATE: 'READ_VENDOR_AUDIT_AFFILIATE',
  READ_VENDOR_AUDIT_AFFILIATE_SUCCESS: 'READ_VENDOR_AUDIT_AFFILIATE_SUCCESS',
  READ_VENDOR_AUDIT_AFFILIATE_FAILURE: 'READ_VENDOR_AUDIT_AFFILIATE_FAILURE',

  READ_VENDOR_AUDIT_TOKEN_AFFILIATE: 'READ_VENDOR_AUDIT_TOKEN_AFFILIATE',
  READ_VENDOR_AUDIT_TOKEN_AFFILIATE_SUCCESS: 'READ_VENDOR_AUDIT_TOKEN_AFFILIATE_SUCCESS',
  READ_VENDOR_AUDIT_TOKEN_AFFILIATE_FAILURE: 'READ_VENDOR_AUDIT_TOKEN_AFFILIATE_FAILURE',

  // DECLINE_AFFILIATE Action types
  DECLINE_AFFILIATE: 'DECLINE_AFFILIATE',
  DECLINE_AFFILIATE_SUCCESS: 'DECLINE_AFFILIATE_SUCCESS',
  DECLINE_AFFILIATE_FAILURE: 'DECLINE_AFFILIATE_FAILURE',

  // APPROVE AFFILIATE Action types
  APPROVE_AFFILIATE: 'APPROVE_AFFILIATE',
  APPROVE_AFFILIATE_SUCCESS: 'APPROVE_AFFILIATE_SUCCESS',
  APPROVE_AFFILIATE_FAILURE: 'APPROVE_AFFILIATE_FAILURE',

  // SEND_BA_AGREEMENT AFFILIATE Action types
  SEND_BA_AGREEMENT_AFFILIATE: 'SEND_BA_AGREEMENT_AFFILIATE',
  SEND_BA_AGREEMENT_AFFILIATE_SUCCESS: 'SEND_BA_AGREEMENT_AFFILIATE_SUCCESS',
  SEND_BA_AGREEMENT_AFFILIATE_FAILURE: 'SEND_BA_AGREEMENT_AFFILIATE_FAILURE',

  // SEND_BA_AGREEMENT AFFILIATE Action types
  SEND_CONF_AGREEMENT_AFFILIATE: 'SEND_CONF_AGREEMENT_AFFILIATE',
  SEND_CONF_AGREEMENT_AFFILIATE_SUCCESS: 'SEND_CONF_AGREEMENT_AFFILIATE_SUCCESS',
  SEND_CONF_AGREEMENT_AFFILIATE_FAILURE: 'SEND_CONF_AGREEMENT_AFFILIATE_FAILURE',

  // BULK_UPLOAD AFFILIATE Action types
  BULK_UPLOAD_AFFILIATE: 'BULK_UPLOAD_AFFILIATE',
  BULK_UPLOAD_AFFILIATE_SUCCESS: 'BULK_UPLOAD_AFFILIATE_SUCCESS',
  BULK_UPLOAD_AFFILIATE_FAILURE: 'BULK_UPLOAD_AFFILIATE_FAILURE',

  BULK_UPLOAD_SITE: 'BULK_UPLOAD_SITE',
  BULK_UPLOAD_SITE_SUCCESS: 'BULK_UPLOAD_SITE_SUCCESS',
  BULK_UPLOAD_SITE_FAILURE: 'BULK_UPLOAD_SITE_FAILURE',

  BULK_UPLOAD_DEVICE: 'BULK_UPLOAD_DEVICE',
  BULK_UPLOAD_DEVICE_SUCCESS: 'BULK_UPLOAD_DEVICE_SUCCESS',
  BULK_UPLOAD_DEVICE_FAILURE: 'BULK_UPLOAD_DEVICE_FAILURE',

  UPLOAD_DOCUMENTS: 'UPLOAD_DOCUMENTS',
  UPLOAD_DOCUMENTS_SUCCESS: 'UPLOAD_DOCUMENTS_SUCCESS',
  UPLOAD_DOCUMENTS_FAILURE: 'UPLOAD_DOCUMENTS_FAILURE',

  // DELETE AFFILIATE_DOCUMENTS Action types
  DELETE_AFFILIATE_DOCUMENTS: 'DELETE_AFFILIATE_DOCUMENTS',
  DELETE_AFFILIATE_DOCUMENTS_SUCCESS: 'DELETE_AFFILIATE_DOCUMENTS_SUCCESS',
  DELETE_AFFILIATE_DOCUMENTS_FAILURE: 'DELETE_AFFILIATE_DOCUMENTS_FAILURE',

  // DELETE AFFILIATE_DOCUMENTS Action types
  APPROVE_AFFILIATE_DOCUMENTS: 'APPROVE_AFFILIATE_DOCUMENTS',
  APPROVE_AFFILIATE_DOCUMENTS_SUCCESS: 'APPROVE_AFFILIATE_DOCUMENTS_SUCCESS',
  APPROVE_AFFILIATE_DOCUMENTS_FAILURE: 'APPROVE_AFFILIATE_DOCUMENTS_FAILURE',

  // DELETE AFFILIATE_DOCUMENTS Action types
  UNAPPROVE_AFFILIATE_DOCUMENTS: 'UNAPPROVE_AFFILIATE_DOCUMENTS',
  UNAPPROVE_AFFILIATE_DOCUMENTS_SUCCESS: 'UNAPPROVE_AFFILIATE_DOCUMENTS_SUCCESS',
  UNAPPROVE_AFFILIATE_DOCUMENTS_FAILURE: 'UNAPPROVE_AFFILIATE_DOCUMENTS_FAILURE',

  // READ_AFFILIATE_AGREEMENTS Action types
  READ_AFFILIATE_AGREEMENTS: 'READ_AFFILIATE_AGREEMENTS',
  READ_AFFILIATE_AGREEMENTS_SUCCESS: 'READ_AFFILIATE_AGREEMENTS_SUCCESS',
  READ_AFFILIATE_AGREEMENTS_FAILURE: 'READ_AFFILIATE_AGREEMENTS_FAILURE',

  // LOAD TASKS Action types
  LOAD_TASKS: 'LOAD_TASKS',
  LOAD_TASKS_SUCCESS: 'LOAD_TASKS_SUCCESS',
  LOAD_TASKS_FAILURE: 'LOAD_TASKS_FAILURE',

  LOAD_TASKS_FILTERED: 'LOAD_TASKS_FILTERED',
  LOAD_TASKS_FILTERED_SUCCESS: 'LOAD_TASKS_FILTERED_SUCCESS',
  LOAD_TASKS_FILTERED_FAILURE: 'LOAD_TASKS_FILTERED_FAILURE',

  LOAD_ONE_TASK: 'LOAD_ONE_TASK',
  LOAD_ONE_TASK_SUCCESS: 'LOAD_ONE_TASK_SUCCESS',
  LOAD_ONE_TASK_FAILURE: 'LOAD_ONE_TASK_FAILURE',

  // LOAD TASKS Action types
  GET_TASKS_FOR_USER: 'GET_TASKS_FOR_USER',
  GET_TASKS_FOR_USER_SUCCESS: 'GET_TASKS_FOR_USER_SUCCESS',
  GET_TASKS_FOR_USER_FAILURE: 'GET_TASKS_FOR_USER_FAILURE',

  // get new dashboard tasks for user
  LOAD_DASHBOARD_TASKS: 'LOAD_DASHBOARD_TASKS',
  LOAD_DASHBOARD_TASKS_SUCCESS: 'LOAD_DASHBOARD_TASKS_SUCCESS',
  LOAD_DASHBOARD_TASKS_FAILURE: 'LOAD_DASHBOARD_TASKS_FAILURE',

  // DELETE TASK Action types
  DELETE_TASK: 'DELETE_TASK',
  DELETE_TASK_SUCCESS: 'DELETE_TASK_SUCCESS',
  DELETE_TASK_FAILURE: 'DELETE_TASK_FAILURE',

  // NOTIFY TASK Action types
  NOTIFY_TASK: 'NOTIFY_TASK',
  NOTIFY_TASK_SUCCESS: 'NOTIFY_TASK_SUCCESS',
  NOTIFY_TASK_FAILURE: 'NOTIFY_TASK_FAILURE',

  // CREATE TASK Action types
  CREATE_TASK: 'CREATE_TASK',
  CREATE_TASK_SUCCESS: 'CREATE_TASK_SUCCESS',
  CREATE_TASK_FAILURE: 'CREATE_TASK_FAILURE',

  // UPDATE TASK Action types
  UPDATE_TASK: 'UPDATE_TASK',
  UPDATE_TASK_SUCCESS: 'UPDATE_TASK_SUCCESS',
  UPDATE_TASK_FAILURE: 'UPDATE_TASK_FAILURE',

  COMPLETE_TASK: 'COMPLETE_TASK',
  COMPLETE_TASK_SUCCESS: 'COMPLETE_TASK_SUCCESS',
  COMPLETE_TASK_FAILURE: 'COMPLETE_TASK_FAILURE',

  ADD_INCIDENT_NOTE: 'ADD_INCIDENT_NOTE',
  ADD_INCIDENT_NOTE_SUCCESS: 'ADD_INCIDENT_NOTE_SUCCESS',
  ADD_INCIDENT_NOTE_FAILURE: 'ADD_INCIDENT_NOTE_FAILURE',

  DELETE_INCIDENT_NOTE: 'DELETE_INCIDENT_NOTE',
  DELETE_INCIDENT_NOTE_SUCCESS: 'DELETE_INCIDENT_NOTE_SUCCESS',
  DELETE_INCIDENT_NOTE_FAILURE: 'DELETE_INCIDENT_NOTE_FAILURE',

  LOAD_INCIDENT_NOTES: 'LOAD_INCIDENT_NOTES',
  LOAD_INCIDENT_NOTES_SUCCESS: 'LOAD_INCIDENT_NOTES_SUCCESS',
  LOAD_INCIDENT_NOTES_FAILURE: 'LOAD_INCIDENT_NOTES_FAILURE',

  // LOAD INCIDENTS Action types
  LOAD_INCIDENTS: 'LOAD_INCIDENTS',
  LOAD_INCIDENTS_SUCCESS: 'LOAD_INCIDENTS_SUCCESS',
  LOAD_INCIDENTS_FAILURE: 'LOAD_INCIDENTS_FAILURE',

  // LOAD INCIDENT MANAGER Action types
  LOAD_INCIDENT: 'LOAD_INCIDENT',
  LOAD_INCIDENT_SUCCESS: 'LOAD_INCIDENT_SUCCESS',
  LOAD_INCIDENT_FAILURE: 'LOAD_INCIDENT_FAILURE',

  // LOAD INCIDENT MANAGER Action types
  LOAD_INCIDENT_TYPES: 'LOAD_INCIDENT_TYPES',
  LOAD_INCIDENT_TYPES_SUCCESS: 'LOAD_INCIDENT_TYPES_SUCCESS',
  LOAD_INCIDENT_TYPES_FAILURE: 'LOAD_INCIDENT_TYPES_FAILURE',

  // UPDATE INCIDENT MANAGER Action types
  UPDATE_INCIDENT: 'UPDATE_INCIDENT',
  UPDATE_INCIDENT_SUCCESS: 'UPDATE_INCIDENT_SUCCESS',
  UPDATE_INCIDENT_FAILURE: 'UPDATE_INCIDENT_FAILURE',

  // ADD NEW INCIDENT Action types
  ADD_NEW_INCIDENT: 'ADD_NEW_INCIDENT',
  ADD_NEW_INCIDENT_SUCCESS: 'ADD_NEW_INCIDENT_SUCCESS',
  ADD_NEW_INCIDENT_FAILURE: 'ADD_NEW_INCIDENT_FAILURE',

  // DELETE INCIDENT Action types
  DELETE_INCIDENT: 'DELETE_INCIDENT',
  DELETE_INCIDENT_SUCCESS: 'DELETE_INCIDENT_SUCCESS',
  DELETE_INCIDENT_FAILURE: 'DELETE_INCIDENT_FAILURE',

  // DASHBOARD - LOAD Important documents Action types
  LOAD_IMPORTANT_DOCUMENTS: 'LOAD_IMPORTANT_DOCUMENTS',
  LOAD_IMPORTANT_DOCUMENTS_SUCCESS: 'LOAD_IMPORTANT_DOCUMENTS_SUCCESS',
  LOAD_IMPORTANT_DOCUMENTS_FAILURE: 'LOAD_IMPORTANT_DOCUMENTS_FAILURE',

  // DASHBOARD - REPORT AN INCIDENT Action types
  REPORT_INCIDENT: 'REPORT_INCIDENT',
  REPORT_INCIDENT_SUCCESS: 'REPORT_INCIDENT_SUCCESS',
  REPORT_INCIDENT_FAILURE: 'REPORT_INCIDENT_FAILURE',

  // APPROVE INCIDENT MANAGER Action types
  APPROVE_INCIDENT: 'APPROVE_INCIDENT',
  APPROVE_INCIDENT_SUCCESS: 'APPROVE_INCIDENT_SUCCESS',
  APPROVE_INCIDENT_FAILURE: 'APPROVE_INCIDENT_FAILURE',

  // LOAD INCIDENT MANAGER Action types
  COMPLETE_INCIDENT: 'COMPLETE_INCIDENT',
  COMPLETE_INCIDENT_SUCCESS: 'COMPLETE_INCIDENT_SUCCESS',
  COMPLETE_INCIDENT_FAILURE: 'COMPLETE_INCIDENT_FAILURE',

  // LOAD INCIDENT MANAGER Action types
  INVESTIGATE_INCIDENT: 'INVESTIGATE_INCIDENT',
  INVESTIGATE_INCIDENT_SUCCESS: 'INVESTIGATE_INCIDENT_SUCCESS',
  INVESTIGATE_INCIDENT_FAILURE: 'INVESTIGATE_INCIDENT_FAILURE',

  // LOAD INCIDENT MANAGER Action types
  NOTIFY_INCIDENT: 'NOTIFY_INCIDENT',
  NOTIFY_INCIDENT_SUCCESS: 'NOTIFY_INCIDENT_SUCCESS',
  NOTIFY_INCIDENT_FAILURE: 'NOTIFY_INCIDENT_FAILURE',

  // DASHBOARD - LOAD TRAINING DOCUMENTS Action types
  LOAD_TRAINING_DOCUMENTS: 'LOAD_TRAINING_DOCUMENTS',
  LOAD_TRAINING_DOCUMENTS_SUCCESS: 'LOAD_TRAINING_DOCUMENTS_SUCCESS',
  LOAD_TRAINING_DOCUMENTS_FAILURE: 'LOAD_TRAINING_DOCUMENTS_FAILURE',

  // DASHBOARD - LOAD TRAINING MEDIA Action types
  LOAD_TRAINING_MEDIA: 'LOAD_TRAINING_MEDIA',
  LOAD_TRAINING_MEDIA_SUCCESS: 'LOAD_TRAINING_MEDIA_SUCCESS',
  LOAD_TRAINING_MEDIA_FAILURE: 'LOAD_TRAINING_MEDIA_FAILURE',

  // ACHIVE/TRAINING VIEW
  LOAD_TRAINING_COURSES: 'LOAD_TRAINING_COURSES',
  LOAD_TRAINING_COURSES_SUCCESS: 'LOAD_TRAINING_COURSES_SUCCESS',
  LOAD_TRAINING_COURSES_FAILURE: 'LOAD_TRAINING_COURSES_FAILURE',

  LOAD_TRAININGS: 'LOAD_TRAININGS',
  LOAD_TRAININGS_SUCCESS: 'LOAD_TRAININGS_SUCCESS',
  LOAD_TRAININGS_FAILURE: 'LOAD_TRAININGS_FAILURE',

  CREATE_TRAINING: 'CREATE_TRAINING',
  CREATE_TRAINING_SUCCESS: 'CREATE_TRAINING_SUCCESS',
  CREATE_TRAINING_FAILURE: 'CREATE_TRAINING_FAILURE',

  DELETE_TRAINING: 'DELETE_TRAINING',
  DELETE_TRAINING_SUCCESS: 'DELETE_TRAINING_SUCCESS',
  DELETE_TRAINING_FAILURE: 'DELETE_TRAINING_FAILURE',

  UPDATE_TRAINING: 'UPDATE_TRAINING',
  UPDATE_TRAINING_SUCCESS: 'UPDATE_TRAINING_SUCCESS',
  UPDATE_TRAINING_FAILURE: 'UPDATE_TRAINING_FAILURE',

  NOTIFY_TRAINING: 'NOTIFY_TRAINING',
  NOTIFY_TRAINING_SUCCESS: 'NOTIFY_TRAINING_SUCCESS',
  NOTIFY_TRAINING_FAILURE: 'NOTIFY_TRAINING_FAILURE',

  COMPLETE_TRAINING: 'COMPLETE_TRAINING',
  COMPLETE_TRAINING_SUCCESS: 'COMPLETE_TRAINING_SUCCESS',
  COMPLETE_TRAINING_FAILURE: 'COMPLETE_TRAINING_FAILURE',

  SUSPEND_TRAINING: 'SUSPEND_TRAINING',
  SUSPEND_TRAINING_SUCCESS: 'SUSPEND_TRAINING_SUCCESS',
  SUSPEND_TRAINING_FAILURE: 'SUSPEND_TRAINING_FAILURE',

  UNSUSPEND_TRAINING: 'UNSUSPEND_TRAINING',
  UNSUSPEND_TRAINING_SUCCESS: 'UNSUSPEND_TRAINING_SUCCESS',
  UNSUSPEND_TRAINING_FAILURE: 'UNSUSPEND_TRAINING_FAILURE',

  INCOMPLETE_TRAINING: 'INCOMPLETE_TRAINING',
  INCOMPLETE_TRAINING_SUCCESS: 'INCOMPLETE_TRAINING_SUCCESS',
  INCOMPLETE_TRAINING_FAILURE: 'INCOMPLETE_TRAINING_FAILURE',

  BULK_UPLOAD_TRAINING: 'BULK_UPLOAD_TRAINING',
  BULK_UPLOAD_TRAINING_SUCCESS: 'BULK_UPLOAD_TRAINING_SUCCESS',
  BULK_UPLOAD_TRAINING_FAILURE: 'BULK_UPLOAD_TRAINING_FAILURE',

  CHECK_QUIZ_ANSWERS: 'CHECK_QUIZ_ANSWERS',
  CHECK_QUIZ_ANSWERS_SUCCESS: 'CHECK_QUIZ_ANSWERS_SUCCESS',
  CHECK_QUIZ_ANSWERS_FAILURE: 'CHECK_QUIZ_ANSWERS_FAILURE',

  RESET_QUIZ_ANSWERS: 'RESET_QUIZ_ANSWERS',
  RESET_QUIZ_ANSWERS_SUCCESS: 'RESET_QUIZ_ANSWERS_SUCCESS',
  RESET_QUIZ_ANSWERS_FAILURE: 'RESET_QUIZ_ANSWERS_FAILURE',

  LOAD_QUIZ_QUESTIONS: 'LOAD_QUIZ_QUESTIONS',
  LOAD_QUIZ_QUESTIONS_SUCCESS: 'LOAD_QUIZ_QUESTIONS_SUCCESS',
  LOAD_QUIZ_QUESTIONS_FAILURE: 'LOAD_QUIZ_QUESTIONS_FAILURE',

  // SUPER USER VIEWS
  LOAD_ORGANIZATIONS: 'LOAD_ORGANIZATIONS',
  LOAD_ORGANIZATIONS_SUCCESS: 'LOAD_ORGANIZATIONS_SUCCESS',
  LOAD_ORGANIZATIONS_FAILURE: 'LOAD_ORGANIZATIONS_FAILURE',

  LOAD_ORGANIZATION_DETAILS: 'LOAD_ORGANIZATION_DETAILS',
  LOAD_ORGANIZATION_DETAILS_SUCCESS: 'LOAD_ORGANIZATION_DETAILS_SUCCESS',
  LOAD_ORGANIZATION_DETAILS_FAILURE: 'LOAD_ORGANIZATION_DETAILS_FAILURE',

  // ORG/SITE/DEPT - LOAD DATA
  LOAD_ORG_SITE_DEPT: 'LOAD_ORG_SITE_DEPT',
  LOAD_ORG_SITE_DEPT_SUCCESS: 'LOAD_ORG_SITE_DEPT_SUCCESS',
  LOAD_ORG_SITE_DEPT_FAILURE: 'LOAD_ORG_SITE_DEPT_FAILURE',

  LOAD_DEPT: 'LOAD_DEPT',
  LOAD_DEPT_SUCCESS: 'LOAD_DEPT_SUCCESS',
  LOAD_DEPT_FAILURE: 'LOAD_DEPT_FAILURE',

  // GET DEPARTMETNS
  UPDATE_DEPT: 'UPDATE_DEPT',
  UPDATE_DEPT_SUCCESS: 'UPDATE_DEPT_SUCCESS',
  UPDATE_DEPT_FAILURE: 'UPDATE_DEPT_FAILURE',

  // ORG - UPDATE DATA
  UPDATE_ORG: 'UPDATE_ORG',
  UPDATE_ORG_SUCCESS: 'UPDATE_ORG_SUCCESS',
  UPDATE_ORG_FAILURE: 'UPDATE_ORG_FAILURE',

  UPDATE_ORG_ACTS: 'UPDATE_ORG_ACTS',
  UPDATE_ORG_ACTS_SUCCESS: 'UPDATE_ORG_ACTS_SUCCESS',
  UPDATE_ORG_ACTS_FAILURE: 'UPDATE_ORG_ACTS_FAILURE',

  UPDATE_SITE: 'UPDATE_SITE',
  UPDATE_SITE_SUCCESS: 'UPDATE_SITE_SUCCESS',
  UPDATE_SITE_FAILURE: 'UPDATE_SITE_FAILURE',

  ACTIVATE_SITE: 'ACTIVATE_SITE',
  ACTIVATE_SITE_SUCCESS: 'ACTIVATE_SITE_SUCCESS',
  ACTIVATE_SITE_FAILURE: 'ACTIVATE_SITE_FAILURE',

  DEACTIVATE_SITE: 'DEACTIVATE_SITE',
  DEACTIVATE_SITE_SUCCESS: 'DEACTIVATE_SITE_SUCCESS',
  DEACTIVATE_SITE_FAILURE: 'DEACTIVATE_SITE_FAILURE',

  CREATE_SITE: 'CREATE_SITE',
  CREATE_SITE_SUCCESS: 'CREATE_SITE_SUCCESS',
  CREATE_SITE_FAILURE: 'CREATE_SITE_FAILURE',

  CREATE_ORG: 'CREATE_ORG',
  CREATE_ORG_SUCCESS: 'CREATE_ORG_SUCCESS',
  CREATE_ORG_FAILURE: 'CREATE_ORG_FAILURE',

  // ORG/SITE/DEPT - ADD NEW DEPT
  ADD_NEW_DEPT: 'ADD_NEW_DEPT',
  ADD_NEW_DEPT_SUCCESS: 'ADD_NEW_DEPT_SUCCESS',
  ADD_NEW_DEPT_FAILURE: 'ADD_NEW_DEPT_FAILURE',

  // ORG/SITE/DEPT - ADD NEW DEPT
  DELETE_DEPT: 'DELETE_DEPT',
  DELETE_DEPT_SUCCESS: 'DELETE_DEPT_SUCCESS',
  DELETE_DEPT_FAILURE: 'DELETE_DEPT_FAILURE',

  // ALERT
  SHOW_ALERT: 'SHOW_ALERT',
  HIDE_ALERT: 'HIDE_ALERT',

  // SESSION MODAL
  SHOW_SESSION_MODAL: 'SHOW_SESSION_MODAL',
  HIDE_SESSION_MODAL: 'HIDE_SESSION_MODAL',

  // MAINTAIN/ALERTS types
  LOAD_ALERTS: 'LOAD_ALERTS',
  LOAD_ALERTS_SUCCESS: 'LOAD_ALERTS_SUCCESS',
  LOAD_ALERTS_FAILURE: 'LOAD_ALERTS_FAILURE',

  // MAINTAIN/ALERTS types
  UPDATE_ALERTS: 'UPDATE_ALERTS',
  UPDATE_ALERTS_SUCCESS: 'UPDATE_ALERTS_SUCCESS',
  UPDATE_ALERTS_FAILURE: 'UPDATE_ALERTS_FAILURE',

  LOAD_USERS: 'LOAD_USERS',
  LOAD_USERS_SUCCESS: 'LOAD_USERS_SUCCESS',
  LOAD_USERS_FAILURE: 'LOAD_USERS_FAILURE',

  LOAD_USERS_UNASSIGNED: 'LOAD_USERS_UNASSIGNED',
  LOAD_USERS_UNASSIGNED_SUCCESS: 'LOAD_USERS_UNASSIGNED_SUCCESS',
  LOAD_USERS_UNASSIGNED_FAILURE: 'LOAD_USERS_UNASSIGNED_FAILURE',

  LOAD_USERS_BY_ORG: 'LOAD_USERS_BY_ORG',
  LOAD_USERS_BY_ORG_SUCCESS: 'LOAD_USERS_BY_ORG_SUCCESS',
  LOAD_USERS_BY_ORG_FAILURE: 'LOAD_USERS_BY_ORG_FAILURE',

  LOAD_REPORTS: 'LOAD_REPORTS',
  LOAD_REPORTS_SUCCESS: 'LOAD_REPORTS_SUCCESS',
  LOAD_REPORTS_FAILURE: 'LOAD_REPORTS_FAILURE',

  CREATE_REPORT: 'CREATE_REPORT',
  CREATE_REPORT_SUCCESS: 'CREATE_REPORT_SUCCESS',
  CREATE_REPORT_FAILURE: 'CREATE_REPORT_FAILURE',

  DELETE_REPORT: 'DELETE_REPORT',
  DELETE_REPORT_SUCCESS: 'DELETE_REPORT_SUCCESS',
  DELETE_REPORT_FAILURE: 'DELETE_REPORT_FAILURE',

  LOAD_FOLDERS: 'LOAD_FOLDERS',
  LOAD_FOLDERS_SUCCESS: 'LOAD_FOLDERS_SUCCESS',
  LOAD_FOLDERS_FAILURE: 'LOAD_FOLDERS_FAILURE',

  LOAD_FOLDER: 'LOAD_FOLDER',
  LOAD_FOLDER_SUCCESS: 'LOAD_FOLDER_SUCCESS',
  LOAD_FOLDER_FAILURE: 'LOAD_FOLDER_FAILURE',

  LOAD_FOLDER_CONTENTS: 'LOAD_FOLDER_CONTENTS',
  LOAD_FOLDER_CONTENTS_SUCCESS: 'LOAD_FOLDER_CONTENTS_SUCCESS',
  LOAD_FOLDER_CONTENTS_FAILURE: 'LOAD_FOLDER_CONTENTS_FAILURE',

  CREATE_FOLDER: 'CREATE_FOLDER',
  CREATE_FOLDER_SUCCESS: 'CREATE_FOLDER_SUCCESS',
  CREATE_FOLDER_FAILURE: 'CREATE_FOLDER_FAILURE',

  UPDATE_FOLDER: 'UPDATE_FOLDER',
  UPDATE_FOLDER_SUCCESS: 'UPDATE_FOLDER_SUCCESS',
  UPDATE_FOLDER_FAILURE: 'UPDATE_FOLDER_FAILURE',

  DELETE_FOLDER: 'DELETE_FOLDER',
  DELETE_FOLDER_SUCCESS: 'DELETE_FOLDER_SUCCESS',
  DELETE_FOLDER_FAILURE: 'DELETE_FOLDER_FAILURE',

  LOAD_DOCUMENT: 'LOAD_DOCUMENT',
  LOAD_DOCUMENT_SUCCESS: 'LOAD_DOCUMENT_SUCCESS',
  LOAD_DOCUMENT_FAILURE: 'LOAD_DOCUMENT_FAILURE',

  EDIT_DOCUMENT: 'EDIT_DOCUMENT',
  EDIT_DOCUMENT_SUCCESS: 'EDIT_DOCUMENT_SUCCESS',
  EDIT_DOCUMENT_FAILURE: 'EDIT_DOCUMENT_FAILURE',

  RESTORE_DOCUMENT: 'RESTORE_DOCUMENT',
  RESTORE_DOCUMENT_SUCCESS: 'RESTORE_DOCUMENT_SUCCESS',
  RESTORE_DOCUMENT_FAILURE: 'RESTORE_DOCUMENT_FAILURE',

  SAVE_AS_DOCUMENT: 'SAVE_AS_DOCUMENT',
  SAVE_AS_DOCUMENT_SUCCESS: 'SAVE_AS_DOCUMENT_SUCCESS',
  SAVE_AS_DOCUMENT_FAILURE: 'SAVE_AS_DOCUMENT_FAILURE',

  LOAD_GAPS: 'LOAD_GAPS',
  LOAD_GAPS_SUCCESS: 'LOAD_GAPS_SUCCESS',
  LOAD_GAPS_FAILURE: 'LOAD_GAPS_FAILURE',

  LOAD_ROLES: 'LOAD_ROLES',
  LOAD_ROLES_SUCCESS: 'LOAD_ROLES_SUCCESS',
  LOAD_ROLES_FAILURE: 'LOAD_ROLES_FAILURE',

  LOAD_ROLES_USER: 'LOAD_ROLES_USER',
  LOAD_ROLES_USER_SUCCESS: 'LOAD_ROLES_USER_SUCCESS',
  LOAD_ROLES_USER_FAILURE: 'LOAD_ROLES_USER_FAILURE',

  CREATE_USERS_BULK: 'CREATE_USERS_BULK',
  CREATE_USERS_BULK_SUCCESS: 'CREATE_USERS_BULK_SUCCESS',
  CREATE_USERS_BULK_FAILURE: 'CREATE_USERS_BULK_FAILURE',

  LOAD_STATS: 'LOAD_STATS',
  LOAD_STATS_SUCCESS: 'LOAD_STATS_SUCCESS',
  LOAD_STATS_FAILURE: 'LOAD_STATS_FAILURE',

  LOAD_USER_STATS: 'LOAD_USER_STATS',
  LOAD_USER_STATS_SUCCESS: 'LOAD_USER_STATS_SUCCESS',
  LOAD_USER_STATS_FAILURE: 'LOAD_USER_STATS_FAILURE',

  LOAD_STEPS: 'LOAD_STEPS',
  LOAD_STEPS_SUCCESS: 'LOAD_STEPS_SUCCESS',
  LOAD_STEPS_FAILURE: 'LOAD_STEPS_FAILURE',

  LOAD_ACTIVE_STEP: 'LOAD_ACTIVE_STEP',
  LOAD_ACTIVE_STEP_SUCCESS: 'LOAD_ACTIVE_STEP_SUCCESS',
  LOAD_ACTIVE_STEP_FAILURE: 'LOAD_ACTIVE_STEP_FAILURE',

  ACTIVATE_USER: 'ACTIVATE_USER',
  ACTIVATE_USER_SUCCESS: 'ACTIVATE_USER_SUCCESS',
  ACTIVATE_USER_FAILURE: 'ACTIVATE_USER_FAILURE',

  DEACTIVATE_USER: 'DEACTIVATE_USER',
  DEACTIVATE_USER_SUCCESS: 'DEACTIVATE_USER_SUCCESS',
  DEACTIVATE_USER_FAILURE: 'DEACTIVATE_USER_FAILURE',

  REVOKE_USER_ACCESS: 'REVOKE_USER_ACCESS',
  REVOKE_USER_ACCESS_SUCCESS: 'REVOKE_USER_ACCESS_SUCCESS',
  REVOKE_USER_ACCESS_FAILURE: 'REVOKE_USER_ACCESS_FAILURE',

  DELETE_USER: 'DELETE_USER',
  DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
  DELETE_USER_FAILURE: 'DELETE_USER_FAILURE',

  RESEND_REGISTRATION_EMAIL_TO_USER: 'RESEND_REGISTRATION_EMAIL_TO_USER',
  RESEND_REGISTRATION_EMAIL_TO_USER_SUCCESS: 'RESEND_REGISTRATION_EMAIL_TO_USER_SUCCESS',
  RESEND_REGISTRATION_EMAIL_TO_USER_FAILURE: 'RESEND_REGISTRATION_EMAIL_TO_USER_FAILURE',

  RESEND_REGISTRATION_EMAIL_TO_USER_ALL: 'RESEND_REGISTRATION_EMAIL_TO_USER_ALL',
  RESEND_REGISTRATION_EMAIL_TO_USER_ALL_SUCCESS: 'RESEND_REGISTRATION_EMAIL_TO_USER_ALL_SUCCESS',
  RESEND_REGISTRATION_EMAIL_TO_USER_ALL_FAILURE: 'RESEND_REGISTRATION_EMAIL_TO_USER_ALL_FAILURE',

  LOAD_USER_ORG_SITE: 'LOAD_USER_ORG_SITE',
  LOAD_USER_ORG_SITE_SUCCESS: 'LOAD_USER_ORG_SITE_SUCCESS',
  LOAD_USER_ORG_SITE_FAILURE: 'LOAD_USER_ORG_SITE_FAILURE',

  // SET_USER_ORG_SITE: "SET_USER_ORG_SITE",
  SET_USER_ORG_SITE_SUCCESS: 'SET_USER_ORG_SITE_SUCCESS',
  // SET_USER_ORG_SITE_FAILURE: "SET_USER_ORG_SITE_FAILURE",

  // CREATE DEVICE Action types
  CREATE_DEVICE: 'CREATE_DEVICE',
  CREATE_DEVICE_SUCCESS: 'CREATE_DEVICE_SUCCESS',
  CREATE_DEVICE_FAILURE: 'CREATE_DEVICE_FAILURE',

  // CREATE DEVICE Action types
  UPDATE_DEVICE: 'UPDATE_DEVICE',
  UPDATE_DEVICE_SUCCESS: 'UPDATE_DEVICE_SUCCESS',
  UPDATE_DEVICE_FAILURE: 'UPDATE_DEVICE_FAILURE',

  // LOAD DEVICES Action types
  LOAD_DEVICES: 'LOAD_DEVICES',
  LOAD_DEVICES_SUCCESS: 'LOAD_DEVICES_SUCCESS',
  LOAD_DEVICES_FAILURE: 'LOAD_DEVICES_FAILURE',

  DOWNLOAD_DEVICES: 'DOWNLOAD_DEVICES',
  DOWNLOAD_DEVICES_SUCCESS: 'DOWNLOAD_DEVICES_SUCCESS',
  DOWNLOAD_DEVICES_FAILURE: 'DOWNLOAD_DEVICES_FAILURE',

  LOAD_CLOUD_ASSETS: 'LOAD_CLOUD_ASSETS',
  LOAD_CLOUD_ASSETS_SUCCESS: 'LOAD_CLOUD_ASSETS_SUCCESS',
  LOAD_CLOUD_ASSETS_FAILURE: 'LOAD_CLOUD_ASSETS_FAILURE',

  LOAD_CLOUD_ASSETS_V2: 'LOAD_CLOUD_ASSETS_V2',
  LOAD_CLOUD_ASSETS_V2_SUCCESS: 'LOAD_CLOUD_ASSETS_V2_SUCCESS',
  LOAD_CLOUD_ASSETS_V2_FAILURE: 'LOAD_CLOUD_ASSETS_V2_FAILURE',

  CREATE_CLOUD_ASSETS_V2: 'CREATE_CLOUD_ASSETS_V2',
  CREATE_CLOUD_ASSETS_V2_SUCCESS: 'CREATE_CLOUD_ASSETS_V2_SUCCESS',
  CREATE_CLOUD_ASSETS_V2_FAILURE: 'CREATE_CLOUD_ASSETS_V2_FAILURE',

  UPDATE_CLOUD_ASSETS_V2: 'UPDATE_CLOUD_ASSETS_V2',
  UPDATE_CLOUD_ASSETS_V2_SUCCESS: 'UPDATE_CLOUD_ASSETS_V2_SUCCESS',
  UPDATE_CLOUD_ASSETS_V2_FAILURE: 'UPDATE_CLOUD_ASSETS_V2_FAILURE',

  CREATE_CLOUD_ASSETS: 'CREATE_CLOUD_ASSETS',
  CREATE_CLOUD_ASSETS_SUCCESS: 'CREATE_CLOUD_ASSETS_SUCCESS',
  CREATE_CLOUD_ASSETS_FAILURE: 'CREATE_CLOUD_ASSETS_FAILURE',

  UPDATE_CLOUD_ASSETS: 'UPDATE_CLOUD_ASSETS',
  UPDATE_CLOUD_ASSETS_SUCCESS: 'UPDATE_CLOUD_ASSETS_SUCCESS',
  UPDATE_CLOUD_ASSETS_FAILURE: 'UPDATE_CLOUD_ASSETS_FAILURE',

  ACTIVATE_CLOUD_ASSETS: 'ACTIVATE_CLOUD_ASSETS',
  ACTIVATE_CLOUD_ASSETS_SUCCESS: 'ACTIVATE_CLOUD_ASSETS_SUCCESS',
  ACTIVATE_CLOUD_ASSETS_FAILURE: 'ACTIVATE_CLOUD_ASSETS_FAILURE',

  DOWNLOAD_TEMPLATE_CLOUD_ASSETS: 'DOWNLOAD_TEMPLATE_CLOUD_ASSETS',
  DOWNLOAD_TEMPLATE_CLOUD_ASSETS_SUCCESS: 'DOWNLOAD_TEMPLATE_CLOUD_ASSETS_SUCCESS',
  DOWNLOAD_TEMPLATE_CLOUD_ASSETS_FAILURE: 'DOWNLOAD_TEMPLATE_CLOUD_ASSETS_FAILURE',

  DOWNLOAD_TEMPLATE_CLOUD_ASSETS_V2: 'DOWNLOAD_TEMPLATE_CLOUD_ASSETS_V2',
  DOWNLOAD_TEMPLATE_CLOUD_ASSETS_V2_SUCCESS: 'DOWNLOAD_TEMPLATE_CLOUD_ASSETS_V2_SUCCESS',
  DOWNLOAD_TEMPLATE_CLOUD_ASSETS_V2_FAILURE: 'DOWNLOAD_TEMPLATE_CLOUD_ASSETS_V2_FAILURE',

  BULK_UPLOAD_CLOUD_ASSETS: 'BULK_UPLOAD_CLOUD_ASSETS',
  BULK_UPLOAD_CLOUD_ASSETS_SUCCESS: 'BULK_UPLOAD_CLOUD_ASSETS_SUCCESS',
  BULK_UPLOAD_CLOUD_ASSETS_FAILURE: 'BULK_UPLOAD_CLOUD_ASSETS_FAILURE',

  BULK_UPLOAD_CLOUD_ASSETS_V2: 'BULK_UPLOAD_CLOUD_ASSETS_V2',
  BULK_UPLOAD_CLOUD_ASSETS_V2_SUCCESS: 'BULK_UPLOAD_CLOUD_ASSETS_V2_SUCCESS',
  BULK_UPLOAD_CLOUD_ASSETS_V2_FAILURE: 'BULK_UPLOAD_CLOUD_ASSETS_V2_FAILURE',

  DOWNLOAD_UPDATE_TEMPLATE_CLOUD_ASSETS: 'DOWNLOAD_UPDATE_TEMPLATE_CLOUD_ASSETS',
  DOWNLOAD_UPDATE_TEMPLATE_CLOUD_ASSETS_SUCCESS: 'DOWNLOAD_UPDATE_TEMPLATE_CLOUD_ASSETS_SUCCESS',
  DOWNLOAD_UPDATE_TEMPLATE_CLOUD_ASSETS_FAILURE: 'DOWNLOAD_UPDATE_TEMPLATE_CLOUD_ASSETS_FAILURE',

  BULK_UPDATE_CLOUD_ASSETS: 'BULK_UPDATE_CLOUD_ASSETS',
  BULK_UPDATE_CLOUD_ASSETS_SUCCESS: 'BULK_UPDATE_CLOUD_ASSETS_SUCCESS',
  BULK_UPDATE_CLOUD_ASSETS_FAILURE: 'BULK_UPDATE_CLOUD_ASSETS_FAILURE',

  DEACTIVATE_CLOUD_ASSETS: 'DEACTIVATE_CLOUD_ASSETS',
  DEACTIVATE_CLOUD_ASSETS_SUCCESS: 'DEACTIVATE_CLOUD_ASSETS_SUCCESS',
  DEACTIVATE_CLOUD_ASSETS_FAILURE: 'DEACTIVATE_CLOUD_ASSETS_FAILURE',

  DECOMMISSION_DEVICE: 'DECOMMISSION_DEVICE',
  DECOMMISSION_DEVICE_SUCCESS: 'DECOMMISSION_DEVICE_SUCCESS',
  DECOMMISSION_DEVICE_FAILURE: 'DECOMMISSION_DEVICE_FAILURE',

  // DUPLICATE DEVICE Action types
  DUPLICATE_DEVICE: 'DUPLICATE_DEVICE',
  DUPLICATE_DEVICE_SUCCESS: 'DUPLICATE_DEVICE_SUCCESS',
  DUPLICATE_DEVICE_FAILURE: 'DUPLICATE_DEVICE_FAILURE',

  // DELETE DEVICE Action types
  DELETE_DEVICE: 'DELETE_DEVICE',
  DELETE_DEVICE_SUCCESS: 'DELETE_DEVICE_SUCCESS',
  DELETE_DEVICE_FAILURE: 'DELETE_DEVICE_FAILURE',

  // LOAD FILES Action types
  LOAD_FILES: 'LOAD_FILES',
  LOAD_FILES_SUCCESS: 'LOAD_FILES_SUCCESS',
  LOAD_FILES_FAILURE: 'LOAD_FILES_FAILURE',

  UPLOAD_FILE: 'UPLOAD_FILE',

  LOAD_ATTESTATIONS: 'LOAD_ATTESTATIONS',
  LOAD_ATTESTATIONS_SUCCESS: 'LOAD_ATTESTATIONS_SUCCESS',
  LOAD_ATTESTATIONS_FAILURE: 'LOAD_ATTESTATIONS_FAILURE',

  ATTEST_DOCUMENT: 'ATTEST_DOCUMENT',
  ATTEST_DOCUMENT_SUCCESS: 'ATTEST_DOCUMENT_SUCCESS',
  ATTEST_DOCUMENT_FAILURE: 'ATTEST_DOCUMENT_FAILURE',

  ATTEST_DOCUMENT_DASHBOARD: 'ATTEST_DOCUMENT_DASHBOARD',
  ATTEST_DOCUMENT_DASHBOARD_SUCCESS: 'ATTEST_DOCUMENT_DASHBOARD_SUCCESS',
  ATTEST_DOCUMENT_DASHBOARD_FAILURE: 'ATTEST_DOCUMENT_DASHBOARD_FAILURE',

  VERIFY_AUDIT: 'VERIFY_AUDIT',
  VERIFY_AUDIT_SUCCESS: 'VERIFY_AUDIT_SUCCESS',
  VERIFY_AUDIT_FAILURE: 'VERIFY_AUDIT_FAILURE',

  LOAD_UNAPPROVED_FILES: 'LOAD_UNAPPROVED_FILES',
  LOAD_UNAPPROVED_FILES_SUCCESS: 'LOAD_UNAPPROVED_FILES_SUCCESS',
  LOAD_UNAPPROVED_FILES_FAILURE: 'LOAD_UNAPPROVED_FILES_FAILURE',

  // APPROVE Document Action types
  APPROVE_DOCUMENT: 'APPROVE_DOCUMENT',
  APPROVE_DOCUMENT_SUCCESS: 'APPROVE_DOCUMENT_SUCCESS',
  APPROVE_DOCUMENT_FAILURE: 'APPROVE_DOCUMENT_FAILURE',

  // INITIALIZE Document Action types
  INITIALIZE_DOCUMENT: 'INITIALIZE_DOCUMENT',
  INITIALIZE_DOCUMENT_SUCCESS: 'INITIALIZE_DOCUMENT_SUCCESS',
  INITIALIZE_DOCUMENT_FAILURE: 'INITIALIZE_DOCUMENT_FAILURE',

  // CLEANUP Document Action types
  CLEANUP_DOCUMENT: 'CLEANUP_DOCUMENT',
  CLEANUP_DOCUMENT_SUCCESS: 'CLEANUP_DOCUMENT_SUCCESS',
  CLEANUP_DOCUMENT_FAILURE: 'CLEANUP_DOCUMENT_FAILURE',

  LOAD_EVIDENCE_BY_INCIDENT: 'LOAD_EVIDENCE_BY_INCIDENT',
  LOAD_EVIDENCE_BY_INCIDENT_SUCCESS: 'LOAD_EVIDENCE_BY_INCIDENT_SUCCESS',
  LOAD_EVIDENCE_BY_INCIDENT_FAILURE: 'LOAD_EVIDENCE_BY_INCIDENT_FAILURE',

  LOAD_EVIDENCE_BY_TASK: 'LOAD_EVIDENCE_BY_TASK',
  LOAD_EVIDENCE_BY_TASK_SUCCESS: 'LOAD_EVIDENCE_BY_TASK_SUCCESS',
  LOAD_EVIDENCE_BY_TASK_FAILURE: 'LOAD_EVIDENCE_BY_TASK_FAILURE',

  LOAD_EVIDENCE_BY_CLOUD_ASSET: 'LOAD_EVIDENCE_BY_CLOUD_ASSET',
  LOAD_EVIDENCE_BY_CLOUD_ASSET_SUCCESS: 'LOAD_EVIDENCE_BY_CLOUD_ASSET_SUCCESS',
  LOAD_EVIDENCE_BY_CLOUD_ASSET_FAILURE: 'LOAD_EVIDENCE_BY_CLOUD_ASSET_FAILURE',

  LOAD_EVIDENCE_BY_INVENTORY: 'LOAD_EVIDENCE_BY_INVENTORY',
  LOAD_EVIDENCE_BY_INVENTORY_SUCCESS: 'LOAD_EVIDENCE_BY_INVENTORY_SUCCESS',
  LOAD_EVIDENCE_BY_INVENTORY_FAILURE: 'LOAD_EVIDENCE_BY_INVENTORY_FAILURE',

  LOAD_EVIDENCE_BY_AUDIT: 'LOAD_EVIDENCE_BY_AUDIT',
  LOAD_EVIDENCE_BY_AUDIT_SUCCESS: 'LOAD_EVIDENCE_BY_AUDIT_SUCCESS',
  LOAD_EVIDENCE_BY_AUDIT_FAILURE: 'LOAD_EVIDENCE_BY_AUDIT_FAILURE',

  CREATE_EVIDENCE: 'CREATE_EVIDENCE',
  CREATE_EVIDENCE_SUCCESS: 'CREATE_EVIDENCE_SUCCESS',
  CREATE_EVIDENCE_FAILURE: 'CREATE_EVIDENCE_FAILURE',

  DELETE_EVIDENCE: 'DELETE_EVIDENCE',
  DELETE_EVIDENCE_SUCCESS: 'DELETE_EVIDENCE_SUCCESS',
  DELETE_EVIDENCE_FAILURE: 'DELETE_EVIDENCE_FAILURE',

  DOWNLOAD_EVIDENCE: 'DOWNLOAD_EVIDENCE',
  DOWNLOAD_EVIDENCE_SUCCESS: 'DOWNLOAD_EVIDENCE_SUCCESS',
  DOWNLOAD_EVIDENCE_FAILURE: 'DOWNLOAD_EVIDENCE_FAILURE',

  DOWNLOAD_USERS_CSV: 'DOWNLOAD_USERS_CSV',
  DOWNLOAD_USERS_CSV_SUCCESS: 'DOWNLOAD_USERS_CSV_SUCCESS',
  DOWNLOAD_USERS_CSV_FAILURE: 'DOWNLOAD_USERS_CSV_FAILURE',

  DOWNLOAD_REPORT: 'DOWNLOAD_REPORT',
  DOWNLOAD_REPORT_SUCCESS: 'DOWNLOAD_REPORT_SUCCESS',
  DOWNLOAD_REPORT_FAILURE: 'DOWNLOAD_REPORT_FAILURE',

  DOWNLOAD_AFFILIATE_FILE: 'DOWNLOAD_AFFILIATE_FILE',
  DOWNLOAD_AFFILIATE_FILE_SUCCESS: 'DOWNLOAD_AFFILIATE_FILE_SUCCESS',
  DOWNLOAD_AFFILIATE_FILE_FAILURE: 'DOWNLOAD_AFFILIATE_FILE_FAILURE',

  CREATE_TASKNOTE: 'CREATE_TASKNOTE',
  CREATE_TASKNOTE_SUCCESS: 'CREATE_TASKNOTE_SUCCESS',
  CREATE_TASKNOTE_FAILURE: 'CREATE_TASKNOTE_FAILURE',

  DELETE_TASKNOTE: 'DELETE_TASKNOTE',
  DELETE_TASKNOTE_SUCCESS: 'DELETE_TASKNOTE_SUCCESS',
  DELETE_TASKNOTE_FAILURE: 'DELETE_TASKNOTE_FAILURE',

  SET_DATATABLE: 'SET_DATATABLE',
  SET_DATATABLE_SUCCESS: 'SET_DATATABLE_SUCCESS',
  SET_DATATABLE_FAILURE: 'SET_DATATABLE_FAILURE',

  SET_DASHBOARD_TAB: 'SET_DASHBOARD_TAB',

  SET_ROWS_PER_PAGE: 'SET_ROWS_PER_PAGE',
  SET_PAGINATION: 'SET_PAGINATION',

  SET_ORG_WIZARD: 'SET_ORG_WIZARD',

  SUBMIT_NEW_ORG_SAGA: 'SUBMIT_NEW_ORG_SAGA',
  SUBMIT_NEW_ORG: 'SUBMIT_NEW_ORG',

  EMAIL_AFFILIATE_FILES: 'EMAIL_AFFILIATE_FILES',
  EMAIL_AFFILIATE_FILES_SUCCESS: 'EMAIL_AFFILIATE_FILES_SUCCESS',
  EMAIL_AFFILIATE_FILES_FAILURE: 'EMAIL_AFFILIATE_FILES_FAILURE',

  LOAD_SUPERADMINS: 'LOAD_SUPERADMINS',
  LOAD_SUPERADMINS_SUCCESS: 'LOAD_SUPERADMINS_SUCCESS',
  LOAD_SUPERADMINS_FAILURE: 'LOAD_SUPERADMINS_FAILURE',

  SET_GRANT_SEAL: 'SET_GRANT_SEAL',
  SET_GRANT_SEAL_SUCCESS: 'SET_GRANT_SEAL_SUCCESS',
  SET_GRANT_SEAL_FAILURE: 'SET_GRANT_SEAL_FAILURE',

  LOAD_CERTIFICATE: 'LOAD_CERTIFICATE',
  LOAD_CERTIFICATE_SUCCESS: 'LOAD_CERTIFICATE_SUCCESS',
  LOAD_CERTIFICATE_FAILURE: 'LOAD_CERTIFICATE_FAILURE',

  LOAD_USER_CERTIFICATE: 'LOAD_USER_CERTIFICATE',
  LOAD_USER_CERTIFICATE_SUCCESS: 'LOAD_USER_CERTIFICATE_SUCCESS',
  LOAD_USER_CERTIFICATE_FAILURE: 'LOAD_USER_CERTIFICATE_FAILURE',

  LOAD_CERTIFICATES: 'LOAD_CERTIFICATES',
  LOAD_CERTIFICATES_SUCCESS: 'LOAD_CERTIFICATES_SUCCESS',
  LOAD_CERTIFICATES_FAILURE: 'LOAD_CERTIFICATES_FAILURE',

  LOAD_USER_CERTIFICATES: 'LOAD_USER_CERTIFICATES',
  LOAD_USER_CERTIFICATES_SUCCESS: 'LOAD_USER_CERTIFICATES_SUCCESS',
  LOAD_USER_CERTIFICATES_FAILURE: 'LOAD_USER_CERTIFICATES_FAILURE',

  SEARCH_USERS: 'SEARCH_USERS',
  SEARCH_USERS_SUCCESS: 'SEARCH_USERS_SUCCESS',
  SEARCH_USERS_FAILURE: 'SEARCH_USERS_FAILURE',

  LOAD_REASIGNABLE_USERS: 'LOAD_REASIGNABLE_USERS',
  LOAD_REASIGNABLE_USERS_SUCCESS: 'LOAD_REASIGNABLE_USERS_SUCCESS',
  LOAD_REASIGNABLE_USERS_FAILURE: 'LOAD_REASIGNABLE_USERS_FAILURE',

  REASSIGN_TASKS: 'REASSIGN_TASKS',
  REASSIGN_TASKS_SUCCESS: 'REASSIGN_TASKS_SUCCESS',
  REASSIGN_TASKS_FAILURE: 'REASSIGN_TASKS_FAILURE',

  // CREATE DEVICE INVENTORY Action types
  CREATE_DEVICE_INVENTORY: 'CREATE_DEVICE_INVENTORY',
  CREATE_DEVICE_INVENTORY_SUCCESS: 'CREATE_DEVICE_INVENTORY_SUCCESS',
  CREATE_DEVICE_INVENTORY_FAILURE: 'CREATE_DEVICE_INVENTORY_FAILURE',

  UPDATE_DEVICE_INVENTORY: 'UPDATE_DEVICE_INVENTORY',
  UPDATE_DEVICE_INVENTORY_SUCCESS: 'UPDATE_DEVICE_INVENTORY_SUCCESS',
  UPDATE_DEVICE_INVENTORY_FAILURE: 'UPDATE_DEVICE_INVENTORY_FAILURE',

  LOAD_DEVICE_INVENTORY: 'LOAD_DEVICE_INVENTORY',
  LOAD_DEVICE_INVENTORY_SUCCESS: 'LOAD_DEVICE_INVENTORY_SUCCESS',
  LOAD_DEVICE_INVENTORY_FAILURE: 'LOAD_DEVICE_INVENTORY_FAILURE',

  // CREATE DEVICE INVENTORY V2 Action types
  CREATE_DEVICE_INVENTORY_V2: 'CREATE_DEVICE_INVENTORY_V2',
  CREATE_DEVICE_INVENTORY_V2_SUCCESS: 'CREATE_DEVICE_INVENTORY_V2_SUCCESS',
  CREATE_DEVICE_INVENTORY_V2_FAILURE: 'CREATE_DEVICE_INVENTORY_V2_FAILURE',

  UPDATE_DEVICE_INVENTORY_V2: 'UPDATE_DEVICE_INVENTORY_V2',
  UPDATE_DEVICE_INVENTORY_V2_SUCCESS: 'UPDATE_DEVICE_INVENTORY_V2_SUCCESS',
  UPDATE_DEVICE_INVENTORY_V2_FAILURE: 'UPDATE_DEVICE_INVENTORY_V2_FAILURE',

  LOAD_DEVICE_INVENTORY_V2: 'LOAD_DEVICE_INVENTORY_V2',
  LOAD_DEVICE_INVENTORY_V2_SUCCESS: 'LOAD_DEVICE_INVENTORY_V2_SUCCESS',
  LOAD_DEVICE_INVENTORY_V2_FAILURE: 'LOAD_DEVICE_INVENTORY_V2_FAILURE',

  BULK_UPLOAD_DEVICE_INVENTORY_V2: 'BULK_UPLOAD_DEVICE_INVENTORY_V2',
  BULK_UPLOAD_DEVICE_INVENTORY_V2_SUCCESS: 'BULK_UPLOAD_DEVICE_INVENTORY_V2_SUCCESS',
  BULK_UPLOAD_DEVICE_INVENTORY_V2_FAILURE: 'BULK_UPLOAD_DEVICE_INVENTORY_V2_FAILURE',

  DOWNLOAD_TEMPLATE_DEVICE_INVENTORY_V2: 'DOWNLOAD_TEMPLATE_DEVICE_INVENTORY_V2',
  DOWNLOAD_TEMPLATE_DEVICE_INVENTORY_V2_SUCCESS: 'DOWNLOAD_TEMPLATE_DEVICE_INVENTORY_V2_SUCCESS',
  DOWNLOAD_TEMPLATE_DEVICE_INVENTORY_V2_FAILURE: 'DOWNLOAD_TEMPLATE_DEVICE_INVENTORY_V2_FAILURE',

  BULK_UPLOAD_DEVICE_INVENTORY: 'BULK_UPLOAD_DEVICE_INVENTORY',
  BULK_UPLOAD_DEVICE_INVENTORY_SUCCESS: 'BULK_UPLOAD_DEVICE_INVENTORY_SUCCESS',
  BULK_UPLOAD_DEVICE_INVENTORY_FAILURE: 'BULK_UPLOAD_DEVICE_INVENTORY_FAILURE',

  DOWNLOAD_TEMPLATE_DEVICE_INVENTORY: 'DOWNLOAD_TEMPLATE_DEVICE_INVENTORY',
  DOWNLOAD_TEMPLATE_DEVICE_INVENTORY_SUCCESS: 'DOWNLOAD_TEMPLATE_DEVICE_INVENTORY_SUCCESS',
  DOWNLOAD_TEMPLATE_DEVICE_INVENTORY_FAILURE: 'DOWNLOAD_TEMPLATE_DEVICE_INVENTORY_FAILURE',

  ACTIVATE_DEVICE_INVENTORY: 'ACTIVATE_DEVICE_INVENTORY',
  ACTIVATE_DEVICE_INVENTORY_SUCCESS: 'ACTIVATE_DEVICE_INVENTORY_SUCCESS',
  ACTIVATE_DEVICE_INVENTORY_FAILURE: 'ACTIVATE_DEVICE_INVENTORY_FAILURE',

  DEACTIVATE_DEVICE_INVENTORY: 'DEACTIVATE_DEVICE_INVENTORY',
  DEACTIVATE_DEVICE_INVENTORY_SUCCESS: 'DEACTIVATE_DEVICE_INVENTORY_SUCCESS',
  DEACTIVATE_DEVICE_INVENTORY_FAILURE: 'DEACTIVATE_DEVICE_INVENTORY_FAILURE',

  DOWNLOAD_UPDATE_TEMPLATE_DEVICE_INVENTORY: 'DOWNLOAD_UPDATE_TEMPLATE_DEVICE_INVENTORY',
  DOWNLOAD_UPDATE_TEMPLATE_DEVICE_INVENTORY_SUCCESS: 'DOWNLOAD_UPDATE_TEMPLATE_DEVICE_INVENTORY_SUCCESS',
  DOWNLOAD_UPDATE_TEMPLATE_DEVICE_INVENTORY_FAILURE: 'DOWNLOAD_UPDATE_TEMPLATE_DEVICE_INVENTORY_FAILURE',

  BULK_UPDATE_DEVICE_INVENTORY: 'BULK_UPDATE_DEVICE_INVENTORY',
  BULK_UPDATE_DEVICE_INVENTORY_SUCCESS: 'BULK_UPDATE_DEVICE_INVENTORY_SUCCESS',
  BULK_UPDATE_DEVICE_INVENTORY_FAILURE: 'BULK_UPDATE_DEVICE_INVENTORY_FAILURE',

  DESTROY_DEVICE_INVENTORY: 'DESTROY_DEVICE_INVENTORY',
  DESTROY_DEVICE_INVENTORY_SUCCESS: 'DESTROY_DEVICE_INVENTORY_SUCCESS',
  DESTROY_DEVICE_INVENTORY_FAILURE: 'DESTROY_DEVICE_INVENTORY_FAILURE',

  LOAD_MODULES: 'LOAD_MODULES',
  LOAD_MODULES_SUCCESS: 'LOAD_MODULES_SUCCESS',
  LOAD_MODULES_FAILURE: 'LOAD_MODULES_FAILURE',

  LOAD_ORG_MODULES: 'LOAD_ORG_MODULES',
  LOAD_ORG_MODULES_SUCCESS: 'LOAD_ORG_MODULES_SUCCESS',
  LOAD_ORG_MODULES_FAILURE: 'LOAD_ORG_MODULES_FAILURE',

  LOAD_TOP_LEVEL_FOLDERS: 'LOAD_TOP_LEVEL_FOLDERS',
  LOAD_TOP_LEVEL_FOLDERS_SUCCESS: 'LOAD_TOP_LEVEL_FOLDERS_SUCCESS',
  LOAD_TOP_LEVEL_FOLDERS_FAILURE: 'LOAD_TOP_LEVEL_FOLDERS_FAILURE',

  LOAD_ORG_ENTITIES: 'LOAD_ORG_ENTITIES',
  LOAD_ORG_ENTITIES_SUCCESS: 'LOAD_ORG_ENTITIES_SUCCESS',
  LOAD_ORG_ENTITIES_FAILURE: 'LOAD_ORG_ENTITIES_FAILURE',

  UPDATE_ORG_MODULES: 'UPDATE_ORG_MODULES',
  UPDATE_ORG_MODULES_SUCCESS: 'UPDATE_ORG_MODULES_SUCCESS',
  UPDATE_ORG_MODULES_FAILURE: 'UPDATE_ORG_MODULES_FAILURE',

  MFA_ACTIVATE: 'MFA_ACTIVATE',
  MFA_ACTIVATE_SUCCESS: 'MFA_ACTIVATE_SUCCESS',
  MFA_ACTIVATE_FAILURE: 'MFA_ACTIVATE_FAILURE',

  MFA_GENERATE_CODE: 'MFA_GENERATE_CODE',
  MFA_GENERATE_CODE_SUCCESS: 'MFA_GENERATE_CODE_SUCCESS',
  MFA_GENERATE_CODE_FAILURE: 'MFA_GENERATE_CODE_FAILURE',

  MFA_VERIFY_CODE: 'MFA_VERIFY_CODE',
  MFA_VERIFY_CODE_SUCCESS: 'MFA_VERIFY_CODE_SUCCESS',
  MFA_VERIFY_CODE_FAILURE: 'MFA_VERIFY_CODE_FAILURE',

  LOAD_SSO_ORGANIZATIONS: 'LOAD_SSO_ORGANIZATIONS',
  LOAD_SSO_ORGANIZATIONS_SUCCESS: 'LOAD_SSO_ORGANIZATIONS_SUCCESS',
  LOAD_SSO_ORGANIZATIONS_FAILURE: 'LOAD_SSO_ORGANIZATIONS_FAILURE',

  CONNECT_SSO_ORGANIZATION: 'CONNECT_SSO_ORGANIZATION',
  CONNECT_SSO_ORGANIZATION_SUCCESS: 'CONNECT_SSO_ORGANIZATION_SUCCESS',
  CONNECT_SSO_ORGANIZATION_FAILURE: 'CONNECT_SSO_ORGANIZATION_FAILURE',

  GET_SSO_USERS: 'GET_SSO_USERS',
  GET_SSO_USERS_SUCCESS: 'GET_SSO_USERS_SUCCESS',
  GET_SSO_USERS_FAILURE: 'GET_SSO_USERS_FAILURE',

  CONNECT_SSO_USERS: 'CONNECT_SSO_USERS',
  CONNECT_SSO_USERS_SUCCESS: 'CONNECT_SSO_USERS_SUCCESS',
  CONNECT_SSO_USERS_FAILURE: 'CONNECT_SSO_USERS_FAILURE',

  SSO_LOGIN_ATTEMPT: 'SSO_LOGIN_ATTEMPT',
  SSO_LOGIN_ATTEMPT_SUCCESS: 'SSO_LOGIN_ATTEMPT_SUCCESS',
  SSO_LOGIN_ATTEMPT_FAILURE: 'SSO_LOGIN_ATTEMPT_FAILURE',

  SSO_LOGIN: 'SSO_LOGIN',
  SSO_LOGIN_SUCCESS: 'SSO_LOGIN_SUCCESS',
  SSO_LOGIN_FAILURE: 'SSO_LOGIN_FAILURE',

  MFA_MASTER_KEY: 'MFA_MASTER_KEY',
  MFA_MASTER_KEY_SUCCESS: 'MFA_MASTER_KEY_SUCCESS',
  MFA_MASTER_KEY_FAILURE: 'MFA_MASTER_KEY_FAILURE',

  LOAD_USER_COUNTERS: 'LOAD_USER_COUNTERS',
  LOAD_USER_COUNTERS_SUCCESS: 'LOAD_USER_COUNTERS_SUCCESS',
  LOAD_USER_COUNTERS_FAILURE: 'LOAD_USER_COUNTERS_FAILURE',

  TOGGLE_NAVIGATION: 'TOGGLE_NAVIGATION',
};
