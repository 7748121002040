import '@fortawesome/fontawesome-pro/css/all.css';
import Alert from 'components/Alert/Alert';
import AlertMultiline from 'components/AlertMultiline/AlertMultiline';
import PdfUserCertificate from 'components/PdfCertificate/PdfUserCertificate';
import { ProtectedRoute } from 'components/ProtectedRoute/ProtectedRoute';
import { SessionExpirationModal } from 'components/SessionExpirationModal/SessionExpirationModal';
import ActionStatusConstants from 'helpers/ActionStatusConstants';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { useAppDispatch, useAppSelector } from 'hooks';
import React, { useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { Navigate, Route, Routes } from 'react-router-dom';
import 'scss/Root.scss';
import { authGetUserAction } from 'store/actions/Actions';
// import Home from 'v5/views/Home';
import Achieve from 'views/Achieve/Achieve';
import Certificates from 'views/Certificates/CertificatesUser';
import IncidentPage from 'views/Dashboard/components/IncidentPage/IncidentPage';
import Dashboard from 'views/Dashboard/Dashboard';
import DashboardRoutes from 'views/Dashboard/DashboardRoutes';
import DashboardSuper from 'views/Dashboard/DashboardSuper';
import Illustrate from 'views/Illustrate/Illustrate';
import Maintain from 'views/Maintain/Maintain';
import OneOrg from 'views/OneOrg/OneOrg';
import Settings from 'views/Settings/Settings';
import DownloadAffiliate from 'views/Welcome/DownloadAffiliate/DownloadAffiliate';
// import NotFound from 'views/Welcome/NotFound';
import NotFound from 'v2/views/Welcome/NotFound';
import Questionnaire from 'views/Welcome/Questionnaire/Questionnaire';
import Welcome from 'views/Welcome/Welcome';

import Assessments from 'v2/views/Assessments/Assessments';
import People from 'v2/views/People/People';
import AddPeople from 'v2/views/People/Users/Add/Add';
import AddBulkPeople from 'v2/views/People/Users/Add/AddBulk';
import EditPeople from 'v2/views/People/Users/Edit/Edit';
import Documents from 'v2/views/Documents/Documents';
import UnapprovedDocuments from 'v2/views/Documents/Documents/UnapprovedDocuments';
import Editor from 'v2/views/Documents/Documents/Editor/Editor';
import Player from 'v2/views/Documents/Documents/Player/Player';

import MyDashboard from 'v2/views/Dashboard/Dashboard';

import MyTasks from 'v2/views/User/MyTasks';
import AuditView from 'v2/views/Assessments/Audits/Audit/Audit';
import MyDocuments from 'v2/views/User/MyDocuments';
// import MyCertificates from 'v2/views/User/MyCertificates';
import ReportIncident from 'v2/views/User/ReportIncident';
import Reports from 'v2/views/Reports/Reports';
import Remediations from 'v2/views/Assessments/Remediation/Remediation';
import RemediationsAdd from 'v2/views/Assessments/Remediation/Add/Add';
import RemediationsEdit from 'v2/views/Assessments/Remediation/Edit/Edit';
import Vendors from 'v2/views/Vendors/Vendors';
import EditVendors from 'v2/views/Vendors/Edit/Edit';
import AddVendors from 'v2/views/Vendors/Add/Add';

import TasksViewAssessments from 'v2/views/Assessments/Tasks/Tasks';
import AddTaskAssessments from 'v2/views/Assessments/Tasks/Add/Add';
import EditTaskAssessments from 'v2/views/Assessments/Tasks/Edit/Edit';

import IncidentsView from 'v2/views/Incidents/IncidentsView';
import Incidents from 'v2/views/Incidents/Incidents';
import EditIncident from 'v2/views/Incidents/Edit/Edit';
import AddNewIncident from 'v2/views/Incidents/Add/Add';
import TasksViewIncidents from 'v2/views/Incidents/Tasks/Tasks';

import Trainings from 'v2/views/Trainings/Trainings';
import Training from 'v2/views/Trainings/Training/Training';
import TasksViewTrainings from 'v2/views/Trainings/Tasks/Tasks';
import EditTaskTrainings from 'v2/views/Trainings/Tasks/Edit/Edit';

import OrgSettings from 'v2/views/Settings/Settings';
import UsersUnassigned from 'v2/views/Settings/UsersUnassigned/UsersUnassigned';

// import AddTask from 'v2/views/Tasks/Tasks/Add/Add';
// import EditTask from 'v2/views/Tasks/Tasks/Edit/Edit';

import TaskStepper from 'v2/views/User/Dashboard/TaskStepper';

import Assets from 'v2/views/Assets/Assets';
import Devices from 'v2/views/Assets/Devices/Devices';
import Applications from 'v2/views/Assets/Applications/Applications';
import TasksViewAssets from 'v2/views/Assets/Tasks/Tasks';
import EditTaskAssets from 'v2/views/Assets/Tasks/Edit/Edit';

import Profile from 'v2/views/Profile/Profile';
import Account from 'v2/views/Profile/Account/Account';
import MFASettings from 'v2/views/Profile/MFA/MFASettings';

import Audits from 'v2/views/Assessments/Audits/Audits';

function getRole({ is_site_admin, is_org_admin, is_org_admin_label, is_officer, is_super, is_coach }) {
  let userRole = '';
  if (is_site_admin) {
    userRole = 'Site Admin';
  }
  if (is_org_admin || is_org_admin_label) {
    userRole = 'Org Admin';
  }
  if (is_officer) {
    userRole = 'Officer';
  }
  if (is_super) {
    userRole = 'Super Admin';
  }
  if (is_coach) {
    userRole = 'Coach';
  }
  return userRole;
}

function Root() {
  const dispatch = useAppDispatch();
  const AuthReducer = useAppSelector((state) => state.AuthReducer);
  const UserOrgSiteReducer = useAppSelector((state) => state.UserOrgSiteReducer);
  const { status, isLoggedIn } = AuthReducer;
  const { org, ui_version, is_admin, is_coach, is_officer, is_org_admin, is_site_admin, is_super } = UserOrgSiteReducer.selected;

  const isAdminUser = is_admin || is_coach || is_officer || is_org_admin || is_site_admin || is_super;

  // get user
  useEffect(() => {
    dispatch(authGetUserAction());
  }, [dispatch]);

  // session timeout
  const idleTimer = useIdleTimer({
    onIdle: () =>
      isLoggedIn &&
      dispatch({
        type: ActionTypeConstants.SHOW_SESSION_MODAL,
        payload: { message: 'Session will expire!' },
      }),
    timeout: 14 * 60 * 1000,
    startManually: true,
  });

  // init session timeout
  useEffect(() => {
    const org_name =
      (UserOrgSiteReducer.selected &&
        UserOrgSiteReducer.organizations &&
        UserOrgSiteReducer.selected.org &&
        UserOrgSiteReducer.organizations[UserOrgSiteReducer.selected.org] &&
        UserOrgSiteReducer.organizations[UserOrgSiteReducer.selected.org].name) ||
      '';
    const userRole = UserOrgSiteReducer.selected ? getRole(UserOrgSiteReducer.selected) : '';

    if (org && isLoggedIn) {
      idleTimer.start();
      process.env.REACT_APP_ENVIRONMENT === 'production' &&
        window.pendo.initialize({
          visitor: {
            id: process.env.REACT_APP_ENV_NAME !== 'prod' ? `${process.env.REACT_APP_ENV_NAME}-${AuthReducer.userData.id}` : AuthReducer.userData.id,
            email: AuthReducer.userData.email,
            full_name: AuthReducer.userData.name,
            role: userRole,
          },
          account: {
            id: UserOrgSiteReducer.selected.org,
            name: org_name,
          },
        });
    } else {
      idleTimer.pause();
    }
  }, [org, isLoggedIn]);

  // inital loading screen
  if (status === ActionStatusConstants.INITIAL || status === ActionStatusConstants.ISBUSY) {
    return (
      <span className="spinner">
        <img
          alt="loading"
          src="data:image/svg+xml;charset=UTF-8,%3csvg width='38' height='38' viewBox='0 0 38 38' xmlns='http://www.w3.org/2000/svg' stroke='%23fff'%3e%3cg fill='none' fill-rule='evenodd'%3e%3cg transform='translate(1 1)' stroke-width='2'%3e%3ccircle stroke-opacity='.5' cx='18' cy='18' r='18'/%3e%3cpath d='M36 18c0-9.94-8.06-18-18-18'%3e%3canimateTransform attributeName='transform' type='rotate' from='0 18 18' to='360 18 18' dur='1s' repeatCount='indefinite'/%3e%3c/path%3e%3c/g%3e%3c/g%3e%3c/svg%3e"
        />
      </span>
    );
  }

  return (
    <>
      <Alert />
      <AlertMultiline />
      <SessionExpirationModal />
      <Routes>
        {/* Main routes */}
        {/* {/* <Route path="/new*" element={<Home></Home>}></Route> */}
        {/* <Route path="/v2/*" element={<ProtectedRoute allowUser><Router /></ProtectedRoute>}></Route> */}

        {/* New ui routes*/}
        <Route path="my-tasks" element={<ProtectedRoute allowUser><MyTasks /></ProtectedRoute>} />
        <Route path="my-tasks/video/:taskId/view" element={<ProtectedRoute allowUser><TaskStepper url="/my-tasks" /></ProtectedRoute>} />
        <Route path="my-tasks/video/:taskId/attest" element={<ProtectedRoute allowUser><TaskStepper url="/my-tasks" /></ProtectedRoute>} />
        <Route path="my-tasks/video/:taskId/finalize" element={<ProtectedRoute allowUser><TaskStepper url="/my-tasks" /></ProtectedRoute>} />

        <Route path="my-tasks/question/:taskId/view" element={<ProtectedRoute allowUser><TaskStepper url="/my-tasks" /></ProtectedRoute>} />
        <Route path="my-tasks/question/:taskId/finalize" element={<ProtectedRoute allowUser><TaskStepper url="/my-tasks" /></ProtectedRoute>} />

        <Route path="my-tasks/document/:taskId/view" element={<ProtectedRoute allowUser><TaskStepper url="/my-tasks" /></ProtectedRoute>} />
        <Route path="my-tasks/document/:taskId/attest" element={<ProtectedRoute allowUser><TaskStepper url="/my-tasks" /></ProtectedRoute>} />
        <Route path="my-tasks/document/:taskId/finalize" element={<ProtectedRoute allowUser><TaskStepper url="/my-tasks" /></ProtectedRoute>} />

        <Route path="my-tasks/task/:taskId/view" element={<ProtectedRoute allowUser><TaskStepper url="/my-tasks" /></ProtectedRoute>} />
        <Route path="my-tasks/task/:taskId/finalize" element={<ProtectedRoute allowUser><TaskStepper url="/my-tasks" /></ProtectedRoute>} />

        <Route path="my-documents" element={<ProtectedRoute allowUser><MyDocuments /></ProtectedRoute>} />
        <Route path="my-documents/document/:taskId/view" element={<ProtectedRoute allowUser><TaskStepper url="/my-documents" /></ProtectedRoute>} />
        <Route path="my-documents/document/:taskId/attest" element={<ProtectedRoute allowUser><TaskStepper url="/my-documents" /></ProtectedRoute>} />
        <Route path="my-documents/document/:taskId/finalize" element={<ProtectedRoute allowUser><TaskStepper url="/my-documents" /></ProtectedRoute>} />

        <Route path="report-incident" element={<ProtectedRoute allowUser><ReportIncident /></ProtectedRoute>} />

        <Route path="my-dashboard" element={<ProtectedRoute allowUser><MyDashboard /></ProtectedRoute>} />

        <Route path="assessments" element={<ProtectedRoute allowUser><Assessments /></ProtectedRoute>}>
          <Route index element={<Audits />} />
          <Route path="audit" element={<AuditView />} />
          <Route path="remediations" element={<Remediations />} />
          <Route path="remediations/add" element={<RemediationsAdd />} />
          <Route path="remediations/edit" element={<RemediationsEdit />} />
          <Route path="tasks" element={<TasksViewAssessments />} />
          <Route path="tasks/add" element={<AddTaskAssessments />} />
          <Route path="tasks/edit/:taskId" element={<EditTaskAssessments />} />
        </Route>

        <Route path="people" element={<ProtectedRoute allowUser><People /></ProtectedRoute>} />
        <Route path="/people/add" element={<ProtectedRoute allowUser><AddPeople /></ProtectedRoute>} />
        <Route path="/people/add_bulk" element={<ProtectedRoute allowUser><AddBulkPeople /></ProtectedRoute>} />
        <Route path="/people/edit" element={<ProtectedRoute allowUser><EditPeople /></ProtectedRoute>} />

        <Route path="assets" element={<ProtectedRoute allowUser><Assets /></ProtectedRoute>}>
          <Route index element={<Devices />} />
          <Route path="applications" element={<Applications />} />
          <Route path="tasks" element={<TasksViewAssets />} />
          {/* <Route path="tasks/add" element={<AddTask />} /> */}
          <Route path="tasks/edit/:taskId" element={<EditTaskAssets />} />
        </Route>

        <Route path="documents" element={<ProtectedRoute allowUser><Documents /></ProtectedRoute>} />
        <Route path="documents/unapproved" element={<ProtectedRoute allowUser><UnapprovedDocuments /></ProtectedRoute>} />
        <Route path="documents/editor/:id/:mode" element={<ProtectedRoute allowUser><Editor /></ProtectedRoute>} />
        <Route path="documents/player/:id/" element={<ProtectedRoute allowUser><Player /></ProtectedRoute>} />
        <Route path="documents/:folderId1" element={<ProtectedRoute allowUser><Documents /></ProtectedRoute>}>
          <Route path=":folderId2" element={<Documents />}>
            <Route path=":folderId3" element={<Documents />}>
              <Route path=":folderId4" element={<Documents />}>
                <Route path=":folderId5" element={<Documents />}>
                  <Route path=":folderId6" element={<Documents />}>
                    <Route path=":folderId7" element={<Documents />}>
                      <Route path=":folderId8" element={<Documents />} />
                    </Route>
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>

        <Route path="trainings" element={<ProtectedRoute allowUser><Trainings /></ProtectedRoute>}>
          <Route index element={<Training />} />
          <Route path=":trainingId" element={<Training />} />
          <Route path="assignments" element={<TasksViewTrainings />} />
          {/* <Route path="tasks/add" element={<AddTask />} /> */}
          <Route path="assignments/edit/:taskId" element={<EditTaskTrainings />} />
        </Route>


        <Route path="incidents" element={<ProtectedRoute allowUser><IncidentsView /></ProtectedRoute>}>
          <Route index element={<Incidents />} />
          <Route path="edit" element={<EditIncident />} />
          <Route path="add-new-incident" element={<AddNewIncident />} />
          <Route path="tasks" element={<TasksViewIncidents />} />
          <Route path="tasks/edit/:taskId" element={<EditTaskTrainings />} />
        </Route>

        <Route path="vendors" element={<ProtectedRoute allowUser><Vendors /></ProtectedRoute>}/>
        <Route path="vendors/edit" element={<ProtectedRoute allowUser><EditVendors /></ProtectedRoute>} />
        <Route path="vendors/add" element={<ProtectedRoute allowUser><AddVendors /></ProtectedRoute>} />

        <Route path="reports" element={<ProtectedRoute allowUser><Reports /></ProtectedRoute>} />

        <Route path="admin" element={<ProtectedRoute allowUser><OrgSettings /></ProtectedRoute>} />
        <Route path="admin/unassigned" element={<ProtectedRoute allowUser><UsersUnassigned /></ProtectedRoute>} />

        <Route path="profile" element={<ProtectedRoute allowUser><Profile /></ProtectedRoute>}>
          <Route index element={<Account />} />
          <Route path="security" element={<MFASettings />} />
        </Route>

        {/* Old ui routes */}
        <Route 
          path="/achieve/*"
          element={
            <ProtectedRoute>
              <Achieve />
            </ProtectedRoute>
          }
        />
        <Route
          path="/illustrate/*"
          element={
            <ProtectedRoute>
              <Illustrate />
            </ProtectedRoute>
          }
        />
        <Route
          path="/maintain/*"
          element={
            <ProtectedRoute>
              <Maintain />
            </ProtectedRoute>
          }
        />

        {/* User dashboard routes */}
        <Route
          path="/dashboard/*"
          element={
            <ProtectedRoute allowUser>
              <DashboardRoutes />
            </ProtectedRoute>
          }
        />

        {/* Login routes */}
        <Route path="/login/*" element={<Welcome />} />

        {/* Super-user routes */}
        <Route
          path="/organization/:orgId"
          element={
            <ProtectedRoute>
              <OneOrg />
            </ProtectedRoute>
          }
        />
        <Route
          path="/active"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/organizations"
          element={
            <ProtectedRoute>
              <DashboardSuper />
            </ProtectedRoute>
          }
        />

        {/* Main dashboard */}
        <Route
          path="/"
          element={
            ui_version !== '1' ? <ProtectedRoute allowUser>
              <Dashboard />
            </ProtectedRoute> : !isAdminUser ? <Navigate to="/my-tasks" replace /> : <Navigate to="/my-dashboard" replace />
          }
        />

        {/* Email redirect */}
        <Route
          path="/:entity/:id"
          element={
            <ProtectedRoute allowUser>
              <Dashboard />
            </ProtectedRoute>
          }
        />

        {/* User routes */}
        <Route
          path="/settings"
          element={
            <ProtectedRoute allowUser>
              <Settings />
            </ProtectedRoute>
          }
        />
        <Route
          path="/certificates"
          element={
            <ProtectedRoute allowUser>
              <Certificates />
            </ProtectedRoute>
          }
        />
        <Route
          path="/certificates/:certId"
          element={
            <ProtectedRoute allowUser>
              <PdfUserCertificate />
            </ProtectedRoute>
          }
        />
        <Route
          path="/incident"
          element={
            <ProtectedRoute allowUser>
              <IncidentPage />
            </ProtectedRoute>
          }
        />

        {/* Public affiliate routes, protected via hash */}
        <Route path="/questionnaire/:hash" element={<Questionnaire />} />
        <Route path="/download/:hash" element={<DownloadAffiliate />} />

        {/* 404 page */}
        <Route path="/404" element={<NotFound />} />
        <Route path="*" element={<Navigate to={isLoggedIn ? '/404' : '/login'} replace />} />
      </Routes>
    </>
  );
}

export default Root;
