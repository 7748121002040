import ActionStatusConstants from 'helpers/ActionStatusConstants';
import ActionTypeConstants from 'helpers/ActionTypeConstants';

const initialState = {
  status: ActionStatusConstants.INITIAL,
  allUsersList: null,
};

export default (state = initialState, action) => {
  const actionType = action && action.type ? action.type : null;
  const payload = action && action.payload ? action.payload : null;

  switch (actionType) {
    case ActionTypeConstants.LOAD_ALL_USERS_LIST:
      return {
        ...state,
        status: ActionStatusConstants.ISBUSY,
      };
    case ActionTypeConstants.LOAD_ALL_USERS_LIST_SUCCESS:
      return {
        ...state,
        status: ActionStatusConstants.SUCCESS,
        allUsersList: payload.allUsersList,
      };
    case ActionTypeConstants.RESET_ALL_USERS_LIST:
      return {
        ...initialState,
      };
    default:
  }
  return state;
};
