import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { generateCodeAction, verifyCodeAction } from 'store/actions/Actions';
import { useAppDispatch, useAppSelector } from 'hooks';
import ActionStatusConstants from 'helpers/ActionStatusConstants';
import Modal from 'components/Modal/Modal';
import FormElement from 'components/FormElement/FormElement';
import { Button } from 'components/Button/Button';

import Counter from '../Counter/Counter';

import styles from './DeleteMethodModal.module.scss';

const steps = ['initial', 'generated-code', 'master-key'];

interface IProps {
  type: 'email' | 'phone';
  value: string;
  userId: string;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

function DeleteMethodModal(props: IProps) {
  const { type, value, userId, setShowModal } = props;

  const dispatch = useAppDispatch();
  const MfaReducer = useAppSelector((state) => state.MfaReducer);

  const [step, setStep] = useState(steps[0]);
  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState('');
  const [counter, setCounter] = useState(undefined);

  useEffect(() => {
    if (MfaReducer?.mfa?.codeExpiresAt) {
      const start = moment().unix();
      const end = moment().add(180, 'seconds').unix();
      setCounter(end - start);
      sessionStorage.setItem('mfa_code_expire', end.toString());
    } else {
      setCounter(undefined);
    }
    if (MfaReducer.status === ActionStatusConstants.FAILURE && MfaReducer?.message) {
      setCodeError(MfaReducer?.message);
    }
  }, [MfaReducer]);

  const onReset = () => {
    setCode('');
    setCodeError('');
    setStep(steps[0]);
  };

  const onChangeCode = (e) => {
    setCode(e.target.value);
    setCodeError('');
  };

  const onDeactivateByCode = () => {
    dispatch(
      generateCodeAction({
        type,
        user_id: userId,
      })
    );
    setCode('');
    setCodeError('');
    setStep(steps[1]);
  };

  const onDeactivateByMasterKey = () => {
    setCode('');
    setCodeError('');
    setStep(steps[2]);
  };

  const onConfirmDeactivate = () => {
    if (step === steps[1] && code.length === 6) {
      dispatch(
        verifyCodeAction({
          type,
          code,
          user_id: userId,
          for_deactivation: true,
        })
      );
    } else if (step === steps[2] && code.length === 32) {
      dispatch(
        verifyCodeAction({
          type,
          code,
          user_id: userId,
          fallback: true,
          for_deactivation: true,
        })
      );
    } else {
      setCodeError(`Code is required to have ${step === steps[1] ? '6 digits' : '32 symbols'}`);
    }
  };

  const backButton = (
    <div className={styles.lost_link} role="none" onClick={onReset}>
      Back
    </div>
  );

  return (
    <Modal className={styles.modal} setShowModal={setShowModal}>
      <div>
        <p className={styles['modal-heading']}>{`Confirm deactivation ${type}`}</p>

        <div className={styles['modal-content']}>
          <br />

          {step === steps[0] ? (
            <>
              <div>To deactivate this security method, select the button below and confirm the verification code sent to that method.</div>
              <br />
              <Button color="danger" text={`Deactivate ${value}`} className={styles.deactivate} onClick={onDeactivateByCode} />
              <div className={styles.lost_link} role="none" onClick={onDeactivateByMasterKey}>
                {`I lost access to my ${type}`}
              </div>
            </>
          ) : undefined}

          {step === steps[1] ? (
            <div>
              <FormElement className={styles.form_element} htmlFor="verificationCode" errorMessage={codeError}>
                <input autoComplete="none" type="text" name="verificationCode" placeholder="Enter verification code" value={code} onChange={onChangeCode} />
              </FormElement>
              <br />
              <div className={styles.message}>
                <div>{`Code sent to the ${value}`}</div>
                <Counter counter={counter} setCounter={setCounter} />
              </div>
              <br />
              <div className={styles.button_group}>
                <Button size="md" text="Resend" color="secondary" disabled={counter > 0} className={styles.resend} onClick={onDeactivateByCode} />
                <Button size="md" color="danger" text="Confirm" disabled={!(counter && counter > 0)} className={styles.confirm} onClick={onConfirmDeactivate} />
              </div>
              {backButton}
            </div>
          ) : undefined}

          {step === steps[2] ? (
            <div>
              <div className={styles.message}>Please enter your master key in the field below</div>
              <br />
              <FormElement className={styles.form_element} htmlFor="verificationCode" errorMessage={codeError}>
                <input autoComplete="none" type="text" name="verificationCode" placeholder="Enter master key" value={code} onChange={onChangeCode} />
              </FormElement>
              <br />
              <div className={styles.button_group}>
                <Button size="md" color="danger" text="Confirm" disabled={codeError.length > 1} className={styles.confirm} onClick={onConfirmDeactivate} />
              </div>
              {backButton}
            </div>
          ) : undefined}
        </div>

        <div className={styles['modal-footer']}>
          <Button text="CANCEL" color="secondary" onClick={() => setShowModal(false)} />
        </div>
      </div>
    </Modal>
  );
}

export default DeleteMethodModal;
