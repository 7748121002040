import { call, put, takeLatest, delay, select } from 'redux-saga/effects';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { actionApiCall } from 'helpers/api/ApiCalls';
import * as sagaConstants from 'helpers/constants/SagasConstants';

const getSelected = (state) => state.UserOrgSiteReducer.selected;

function* createTaskSaga({ payload }) {
  try {
    const selected = yield select(getSelected);
    const apiResponse = yield call(actionApiCall, {
      actionType: ActionTypeConstants.CREATE_TASK,
      data: {
        organization: selected.org,
        site: payload.site,
        name: payload.task,
        notes: payload.notes,
        user: payload.assignee,
        due: payload.due,
        files: payload.files,
        regulatory_act: payload.regulatory_act,
        tasknotes: payload.tasknotes.map((t) => t.notes),
        requires_approval: false,
      },
    });

    if (apiResponse && apiResponse.res.data.response === 200) {
      // SUCCESS
      yield put({ type: ActionTypeConstants.CREATE_TASK_SUCCESS });
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: apiResponse.res.data.message || `A task ${sagaConstants.SUCCESSFULLY_CREATED}`,
          color: 'success',
        },
      });
      yield put({ type: ActionTypeConstants.LOAD_TASKS });
      yield delay(10000);
      yield put({ type: ActionTypeConstants.HIDE_ALERT });
    } else {
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: apiResponse.res.data.message || `${sagaConstants.ISSUE_OCCURED} creating a task`,
          color: 'danger',
        },
      });
      yield delay(10000);
      yield put({ type: ActionTypeConstants.HIDE_ALERT });
    }
  } catch (err) {
    yield put({
      type: ActionTypeConstants.SHOW_ALERT,
      payload: {
        message: `${sagaConstants.SERVER_ERROR}`,
        color: 'danger',
      },
    });
    yield delay(10000);
    yield put({ type: ActionTypeConstants.HIDE_ALERT });
  }
}

export default function* () {
  yield takeLatest(ActionTypeConstants.CREATE_TASK, createTaskSaga);
}
