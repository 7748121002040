import React, { useEffect, useState } from 'react';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useNavigate } from 'react-router-dom';

import styles from './PieChartBox.module.scss';

/**
 * Generate Pie chart, calc percent based on open/closed values
 * All fields are required
 * @param {String} title - string for chart title
 * @param {String} desc - string for chart desctiption
 * @param {Number} open - intger for open items
 * @param {Number} closed - intger for closed items
 * @param {String} color - string value for color
 */
const PieChartBox = (props) => {
  const navigate = useNavigate();
  const { title, desc, total, complete, color, bg, link } = props;
  const open = total - complete || 0;
  const perc = Math.floor((complete / total) * 100) || 0;
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    const timer = window.setTimeout(() => {
      setPercentage(perc);
    }, 100);
    return () => window.clearTimeout(timer);
  }, [perc]);

  return (
    <div>
    <div className={`${styles['pie-chart-box']} ${link ? styles.isLink : ''}`} onClick={link ? () => navigate(link) : null}>
      <div className={styles.holder}>
        <div className={styles.desc}>
          <h2>{title}</h2>
          <div className={styles.text}>{desc}</div>
        </div>

        <div className={styles.circle}>
          <CircularProgressbarWithChildren
            styles={{
              path: {
                stroke: color,
                strokeLinecap: 'butt',
              },
              trail: {
                stroke: bg,
                strokeLinecap: 'butt',
                transform: 'rotate(0.25turn)',
                transformOrigin: 'center center',
              },
              text: {
                fill: '#616161',
                fontSize: '8pt',
              },
            }}
            value={percentage}
            strokeWidth={7}
          >
            <div className={styles.perc}>{`${perc}%`}</div>
            <div className={styles['perc-text']}>Completed</div>
          </CircularProgressbarWithChildren>
        </div>

        <div className={styles.legend}>
          <div>
            <h1>{total || 0}</h1>
            <span className={styles.label}>Total tasks</span>
          </div>
          <br />
          <br />
          <br />
          <div className={styles.stats}>
            <div>
              <i className="fas fa-circle" style={{ color }} /> Completed ({complete || 0})
            </div>
            <br />
            <div>
              <i className="fas fa-circle" style={{ color: bg }} /> Incompleted ({open})
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default PieChartBox;
