import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import TaskStepper from './TaskStepper';

function DashboardRoutes() {
  return (
    <Routes>
      <Route path="/document/:taskId/view" element={<TaskStepper />} />
      <Route path="/document/:taskId/attest" element={<TaskStepper />} />
      <Route path="/document/:taskId/finalize" element={<TaskStepper />} />
      <Route path="/video/:taskId/view" element={<TaskStepper />} />
      <Route path="/video/:taskId/attest" element={<TaskStepper />} />
      <Route path="/video/:taskId/finalize" element={<TaskStepper />} />
      <Route path="/question/:taskId/view" element={<TaskStepper />} />
      <Route path="/question/:taskId/finalize" element={<TaskStepper />} />
      <Route path="/task/:taskId/view" element={<TaskStepper />} />
      <Route path="/task/:taskId/finalize" element={<TaskStepper />} />
      <Route path="*" element={<Navigate to={'/404'} replace />} />
    </Routes>
  );
}

export default DashboardRoutes;
