import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import AppLayoutWrapFull from 'components/AppLayoutWrapFull';
import PageHeader from 'components/PageHeader/PageHeader';
import PageHeaderActions from 'components/PageHeader/components/PageHeaderActions/PageHeaderActions';
import Container from 'components/Container/Container';
import FullDataTable from 'components/DataTable/FullDataTable';
import Dimmer from 'components/Dimmer/Dimmer';
import { useConfirmModal } from 'hooks';

import UploadDocumentsModal from './components/UploadDocumentsModal/UploadDocumentsModal';
import CreateFolderModal from './components/CreateFolderModal/CreateFolderModal';
import DeleteFolderModal from './components/DeleteFolderModal/DeleteFolderModal';
import ViewHistoryModal from './components/ViewHistory/ViewHistoryModal';

import { loadFolderContentsAction, setPaginationAction } from 'store/actions/Actions';

import { setSelectedRows, toggleDropdowns } from 'helpers/utils';
import { DOCUMENTS_PAGE } from 'helpers/constants/DataTableConstants';
import { ONLY_OFFICE_CALLBACK_URL } from 'helpers/ApiEndpointConstants';
import useFilterRows from 'helpers/customHooks/useFilterRows';
import ActionStatusConstants from 'helpers/ActionStatusConstants';
import TableDefDocuments from 'table-defs/TableDefDocuments';

import styles from './Documents.module.scss';
import Spinner from 'components/Spinner/Spinner';

const Documents = (props) => {
  const { folderContents, loadFolderContentsAction, parentFolder, setPaginationAction, UserOrgSiteReducer, isFileUploading, FolderContentsReducer } = props;
  const navigate = useNavigate();
  const urlParams = useParams();
  const [state, setState] = useState({
    selectedRows: null,
    parentFolder: 0,
    currentFolderName: null,
  });

  const [documents, setDocuments] = useState(folderContents);

  const location = useLocation();

  const [showUploadDocumentsModal, setShowUploadDocumentsModal] = useState(false);
  const [showCreateFolderModal, setShowCreateFolderModal] = useState(false);
  const [showDocumentHistory, setShowDocumentHistory] = useState(false);
  const [showDeleteFolderModal, setDeleteFolderModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal, renderConfirmModal] = useConfirmModal();

  const [selectedFile, setSelectedFile] = useState(state.selectedFile);
  const [parentFolderPath, setParentFolderPath] = useState('');
  const [selectedDocumentIds, setSelectedDocumentIds] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [isSubfolderLoading, setIsSubfolderLoading] = useState(false);

  const noFilters = {
    text: '',
    type: '',
    'last-action': '',
    status: '',
    role: '',
    site: '',
    start: '',
    end: '',
    selected_sites: [],
    dateField: 'last_modified',
  };

  const [filters, setFilters] = useState(noFilters);

  useEffect(() => {
    setPaginationAction({ dataType: DOCUMENTS_PAGE, currentPage: 0 });
  }, [filters]);

  const [editFolder, setEditFolder] = useState(false);

  useEffect(() => {
    if (!folderContents && location.pathname === '/achieve/documents') {
      loadFolderContentsAction({ parent_folder: 0 });
    }
  }, [location.pathname, folderContents]);

  useEffect(() => {
    if (folderContents) {
      setDocuments(folderContents);
    }
  }, [folderContents]);

  useEffect(() => {
    if (location.pathname !== '/achieve/documents') {
      const pathnameArr = location.pathname.split('/');
      const parentFolderId = pathnameArr[pathnameArr.length - 1];
      const parentFolderPath = location.pathname.replace(`/${parentFolderId}`, '');
      setParentFolderPath(parentFolderPath);
      loadFolderContentsAction({ parent_folder: parentFolderId });
    }
  }, [location.pathname]);

  useEffect(() => {
    if (FolderContentsReducer.status === ActionStatusConstants.SUCCESS) {
      setLoading(false);
      setIsSubfolderLoading(false);
      return;
    }

    FolderContentsReducer && FolderContentsReducer.parentFolder && FolderContentsReducer.parentFolder.parent_folder === null && setLoading(true);
  }, [FolderContentsReducer.folderContents]);

  const setDateFilter = (filterName) => (value) => {
    setFilters({
      ...filters,
      [filterName]: value,
    });
  };

  const onChangeRowSelect = ({ selectedRows }) => {
    if (selectedRows !== state.selectedRows) {
      setState((s) => ({ ...s, selectedRows }));
    }
  };

  const filteredRows = useFilterRows(filters, documents);
  const chooseActionDropdownRef = React.createRef();

  const selectedIds = selectedDocumentIds.map((item) => item.id);
  const selectedFolders = selectedDocumentIds.find((item) => item.type === 'folder');

  const is_admin = UserOrgSiteReducer && UserOrgSiteReducer.selected && UserOrgSiteReducer.selected.is_admin ? true : false;
  const is_super = UserOrgSiteReducer && UserOrgSiteReducer.selected && UserOrgSiteReducer.selected.is_super ? true : false;
  const is_coach = UserOrgSiteReducer && UserOrgSiteReducer.selected && UserOrgSiteReducer.selected.is_coach ? true : false;

  return (
    <AppLayoutWrapFull onClick={toggleDropdowns([chooseActionDropdownRef])}>
      <Dimmer active={isFileUploading} />
      <PageHeader breadcrumbs="Achieve &nbsp;/&nbsp; Documents" showStats={true}>
        <PageHeaderActions
          primaryButtonText="UPLOAD DOCUMENTS"
          primaryButtonHandler={() => setShowUploadDocumentsModal(!showUploadDocumentsModal)}
          secondaryButtonHandler={() => {
            if (urlParams.folderId8) {
              setShowConfirmModal({
                type: 'simple',
                title: 'Subfolders limit reached',
                question: "Can't nest subfolders more deeply",
              });
            } else {
              setEditFolder(false);
              setShowCreateFolderModal(!showCreateFolderModal);
            }
          }}
          secondaryButtonText="CREATE FOLDER"
          terinaryButtonText="UNAPPROVED DOCS"
          terinaryButtonHandler={() => {
            navigate('/achieve/documents/unapproved');
          }}
          terinaryButtonIcon="fal fa-empty"
          chooseActionDropdownRef={chooseActionDropdownRef}
          searchHandler={(e) => setFilters({ ...filters, text: e.target.value || '' })}
          searchValue={filters.text}
          chooseAction={[
            {
              actionName: 'Delete Selected',
              actionHandler: (e) => {
                setDeleteFolderModal(!showDeleteFolderModal);
              },
            },
          ]}
          dateRange={{
            onChangeStart: setDateFilter('start'),
            onChangeEnd: setDateFilter('end'),
          }}
          filters={filters}
          resetFilters={() => {
            setFilters(noFilters);
          }}
        />
      </PageHeader>
      {isLoading ? (
        <Spinner />
      ) : (
        <Container wide fluid>
          {showUploadDocumentsModal && ReactDOM.createPortal(
            <UploadDocumentsModal setShowModal={setShowUploadDocumentsModal} />, document.querySelector('#modal-root'))}

          {showCreateFolderModal && ReactDOM.createPortal(
            <CreateFolderModal setShowModal={setShowCreateFolderModal} editFolder={editFolder} />, document.querySelector('#modal-root'))}

          {showDeleteFolderModal && ReactDOM.createPortal(
            <DeleteFolderModal setShowModal={setDeleteFolderModal} selectedFolder={selectedIds} parentFolder={props.parentFolder} selectedFolders={selectedFolders} />, document.querySelector('#modal-root'))}

          {showDocumentHistory && ReactDOM.createPortal(
            <ViewHistoryModal setShowModal={setShowDocumentHistory} selectedFile={selectedFile} />, document.querySelector('#modal-root'))}

          <div className={styles['path']}>
            {parentFolder && parentFolder.id !== 0 ? (
              <div className={styles[`path-wrapper`]}>
                <span
                  className={styles['up']}
                  onClick={() => {
                    navigate(parentFolderPath);
                    setPaginationAction({
                      dataType: DOCUMENTS_PAGE,
                      currentPage: 0,
                    });
                    setIsSubfolderLoading(true);
                  }}
                >
                  <i className={isSubfolderLoading ? `fal fa-circle-notch ${styles.spin}` : `fal fa-folder-open ${styles['link']}`}></i>
                </span>{' '}
                {props.parentFolder.path}
              </div>
            ) : (
              <div className={styles[`path-wrapper`]}>
                <span className={styles['up']}>
                  <i className={isSubfolderLoading ? `fal fa-circle-notch ${styles.spin}` : `fal fa-folder-open`}></i>
                </span>{' '}
                /
              </div>
            )}
          </div>

          <FullDataTable
            rowsData={filteredRows}
            definitions={TableDefDocuments}
            dataType={DOCUMENTS_PAGE}
            onChangeRowSelect={({ selectedRows }) => onChangeRowSelect({ selectedRows })}
            onRowClick={function (e) {
              if (e.currentTarget.classList.contains('col-actions') || e.currentTarget.classList.contains('col-row-selector')) {
                return;
              }
              if (this.props.rowData['type'] === 'folder') {
                navigate(`${location.pathname}/${this.props.rowData.id}`);
              } else {
                if (this.props.rowData['file_type'] === 'video') {
                  navigate(`/achieve/documents/player/${this.props.rowData.id}`);
                  return;
                }
                navigate(`/achieve/documents/editor/${this.props.rowData.id}/view`);
              }
              setIsSubfolderLoading(true);
              setPaginationAction({ dataType: DOCUMENTS_PAGE, currentPage: 0 });
            }}
            setSelectedRows={setSelectedRows(['id', 'type'], setSelectedDocumentIds)}
            customTableProps={{
              view: ({ type, id }) => {
                if (type === 'folder') {
                  navigate(`${location.pathname}/${id}`);
                } else {
                  navigate(`/achieve/documents/editor/${id}/view`);
                }
              },
              edit:
                is_admin || is_super || is_coach
                  ? (rowData) => {
                      if (rowData.type === 'folder') {
                        setShowCreateFolderModal(!showCreateFolderModal);
                        setEditFolder(rowData);
                      } else {
                        if (rowData.file_type === 'video') {
                          navigate(`/achieve/documents/player/${rowData.id}`);
                        } else {
                          navigate(`/achieve/documents/editor/${rowData.id}/edit`);
                        }
                      }
                    }
                  : null,
              editFilePermissions: (rowData) => {
                setShowCreateFolderModal(!showCreateFolderModal);
                setEditFolder(rowData);
              },
              delete: (rowData) => {
                setSelectedDocumentIds([rowData]);
                setDeleteFolderModal(!showDeleteFolderModal);
              },
              download: (rowData) => {
                const link = document.createElement('a');
                link.href = `${ONLY_OFFICE_CALLBACK_URL}Storage/Documents/get.php?${rowData.id}`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              },
              upload: (rowData) => {
                setShowUploadDocumentsModal(true);
              },
              viewHistory: (rowData) => {
                setSelectedFile(rowData);
                setShowDocumentHistory(true);
              },
            }}
          />
          {showConfirmModal && renderConfirmModal(showConfirmModal, setShowConfirmModal)}
        </Container>
      )}
    </AppLayoutWrapFull>
  );
};

const mapStateToProps = (state) => ({
  FolderContentsReducer: state.FolderContentsReducer,
  folderContents: state.FolderContentsReducer.folderContents,
  parentFolder: state.FolderContentsReducer.parentFolder,
  UserOrgSiteReducer: state.UserOrgSiteReducer,
  isFileUploading: state.FilesReducer.isFileUploading,
});

const mapDispatchToProps = {
  loadFolderContentsAction,
  setPaginationAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Documents);
