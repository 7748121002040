import classNames from 'classnames';
import React, { useEffect } from 'react';

import styles from './Modal.module.scss';

interface IModal {
  setShowModal?: React.Dispatch<React.SetStateAction<boolean>>;
  overlayClassName?: string;
  className?: string;
  disableCloseButton?: boolean;
  children?: string | React.ReactNode;
  disableModalClose?: boolean;
}

function ModalHeading(props) {
  return <p className={styles['modal-heading']}>{props.children}</p>;
}

function ModalContent(props) {
  return <div className={styles['modal-content']}>{props.children}</div>;
}

function ModalFooter(props) {
  return <div className={styles['modal-footer']}>{props.children}</div>;
}

export const useKeyPress = (targetKey, fn) => {
  function downHandler({ key }) {
    if (key === targetKey) {
      fn();
    }
  }
  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  });
};

function Modal({ setShowModal = undefined, overlayClassName = undefined, className = undefined, disableCloseButton = false, children = '', disableModalClose = false }: IModal) {
  const handleKeyPress = (e) => {
    if (e.key === 'Escape' && setShowModal && !disableModalClose) {
      setShowModal(false);
    }
  };

  const handleClose = (e) => {
    if (disableModalClose) return;
    if (setShowModal) {
      setShowModal(false);
      e.preventDefault();
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
    }
  };

  const styleOverlay = classNames(styles.overlay, overlayClassName);
  const styleIcon = classNames('fal fa-times', styles['close-modal']);
  const styleContent = classNames(styles.modal, className);

  return (
    <>
      <div className={styleOverlay} onClick={(e) => handleClose(e)} aria-hidden />
      <div className={styles.holder}>
        <div className={styleContent}>
          {!disableCloseButton && (
            <i
              className={styleIcon}
              onClick={() => !disableModalClose && setShowModal && setShowModal(false)}
              onKeyUp={handleKeyPress}
              role="button"
              tabIndex={0}
              aria-label="Close"
            />
          )}
          {children}
        </div>
      </div>
    </>
  );
}

Modal.Heading = ModalHeading;
Modal.Content = ModalContent;
Modal.Footer = ModalFooter;

export default Modal;
