import React, { useEffect } from 'react';

import FormElement from 'components/FormElement/FormElement';
import Select from 'components/Select/Select';

import { useAppDispatch, useAppSelector } from 'hooks';
import { loadSitesAction } from 'store/actions/Actions';
import { toggleDropdowns } from 'helpers/utils';

import styles from './BulkUploadSiteSelector.module.scss';

const BulkUploadSiteSelector = ({ site_id, onChangeSite }) => {
  const dispatch = useAppDispatch();
  const SitesReducer = useAppSelector((state) => state.SitesReducer);
  const UserOrgSiteReducer = useAppSelector((state) => state.UserOrgSiteReducer);

  const associatedSiteRef = React.createRef();

  useEffect(() => {
    if (!SitesReducer.sites) {
      dispatch(loadSitesAction());
    }
  }, [SitesReducer.sites]);

  const sitesList = {
    '-1': 'All Sites',
  };
  SitesReducer.sites &&
  SitesReducer.sites.forEach((site) => {
    sitesList[site.id] = site.name;
  });

  return (
    <div
      className={styles.site}
      onClick={toggleDropdowns([associatedSiteRef])}
    >
      <FormElement
        labelText="ASSOCIATED SITE"
        htmlFor="site"
      >
        <Select
          labels={sitesList}
          value={site_id}
          placeholder="Select Site"
          onClick={(e) => onChangeSite(e?.target?.attributes?.value?.value)}
          dropdownToggleRef={associatedSiteRef}
        >
          {UserOrgSiteReducer.selected && !UserOrgSiteReducer.selected.site && (
            <li key={`siteid-all`} value={'-1'}>
              All Sites
            </li>
          )}
          {SitesReducer.sites &&
            SitesReducer.sites.map((site) => (
              <li key={`siteid-${site.id}`} data-name={site.name} value={site.id}>
                {site.name}
              </li>
            ))}
        </Select>
      </FormElement>

      <br />
    </div>
  );
};

export default BulkUploadSiteSelector;
