export const ISSUE_OCCURED = 'An issue occurred while';
export const SERVER_ERROR = 'Server error. Please try again later';
export const SUCCESSFULLY_CREATED = 'successfully created';
export const SUCCESSFULLY_APPROVED = 'successfully approved';
export const SUCCESSFULLY_IMPORTED = 'successfully imported';
export const SUCCESSFULLY_DECLINED = 'successfully declined';
export const SUCCESSFULLY_DELETED = 'successfully deleted';
export const SUCCESSFULLY_UPDATED = 'successfully updated';
export const SUCCESSFULLY_SENT = 'successfully sent';
export const SUCCESSFULLY_NOTIFIED = 'successfully notified';
export const SUCCESSFULLY_UNAPPROVED = 'successfully unapproved';
export const SUCCESSFULLY_COMPLETED = 'successfully completed';
export const SUCCESSFULLY_REOPENED = 'successfully reopened';
export const SUCCESSFULLY_UPLOADED = 'successfully uploaded';
export const SUCCESSFULLY_ADDED = 'successfully added';
export const SUCCESSFULLY_REMOVED = 'successfully removed';
export const SUCCESSFULLY_SAVED = 'successfully saved';
export const SUCCESSFULLY_REASSIGNED = 'successfully reassigned';
