import React from 'react';
import classNames from 'classnames';

import styles from './Row.module.scss';

interface IProps {
  /** additional className to add */
  className?: string;
  children?: React.ReactNode;
}

const defaultProps = {
  className: undefined,
  children: undefined,
};

function Row({ className, children }: IProps) {
  const style = classNames(styles.row, className);

  return <div className={style}>{children}</div>;
}

Row.defaultProps = defaultProps;

export default Row;
