import ActionStatusConstants from 'helpers/ActionStatusConstants';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { LOCATION_CHANGE } from 'redux-first-history';

const initialState = {
  status: ActionStatusConstants.INITIAL,
  message: null,
};

export default (state = initialState, action) => {
  const actionType = action && action.type ? action.type : null;
  const payload = action && action.payload ? action.payload : null;

  switch (actionType) {
    case ActionTypeConstants.CONNECT_SSO_USERS:
      return {
        ...state,
        status: ActionStatusConstants.ISBUSY,
      };
    case ActionTypeConstants.CONNECT_SSO_USERS_SUCCESS:
      return {
        ...state,
        status: ActionStatusConstants.SUCCESS,
        message: payload?.message || null,
      };
    case ActionTypeConstants.CONNECT_SSO_USERS_FAILURE:
      return {
        ...state,
        status: ActionStatusConstants.FAILURE,
        message: payload?.message || null,
      };
    case LOCATION_CHANGE:
      return {
        ...initialState,
      };
    default:
  }
  return state;
};
