import { combineReducers } from 'redux';
import AddOfficerFormReducer from './Users/AddOfficerFormReducer';
import AddUserFormReducer from './Users/AddUserFormReducer';
import AuditsReducer from './Audits/AuditsReducer';
import ActsReducer from './Audits/ActsReducer';
import SitesReducer from './Audits/SitesReducer';
import CreateAuditReducer from './Audits/CreateAuditReducer';
import AuditReducer from './Audits/AuditReducer';
import RemediationReducer from './Remediation/RemediationReducer';
import AddRemediationPlanFormReducer from './Remediation/AddRemediationPlanFormReducer';
import EditRemediationPlanFormReducer from './Remediation/EditRemediationPlanFormReducer';
import AllSitesListReducer from './Organizations/AllSitesListReducer';
import AllUsersListReducer from './Users/AllUsersListReducer';
import AuthReducer from './Auth/AuthReducer';
import EditUserFormReducer from './Users/EditUserFormReducer';
import UserLoginFormReducer from './Auth/UserLoginFormReducer';
import UserRegisterFormReducer from './Auth/UserRegisterFormReducer';
import AffiliatesReducer from './Affiliates/AffiliatesReducer';
import EditAffiliateFormReducer from './Affiliates/EditAffiliateFormReducer';
import AddAffiliateFormReducer from './Affiliates/AddAffiliateFormReducer';
import AffiliateAgreementsReducer from './Affiliates/AffiliateAgreementsReducer';
import TasksReducer from './Tasks/TasksReducer';
import TaskReducer from './Tasks/TaskReducer';
import TasksForUserReducer from './Tasks/TasksForUserReducer';
import CreateTaskReducer from './Tasks/CreateTaskReducer';
import IncidentsReducer from './Incidents/IncidentsReducer';
import IncidentNotesReducer from './Incidents/IncidentNotesReducer';
import IncidentTypesReducer from './Incidents/IncidentTypesReducer';
import IncidentManagerReducer from './Incidents/IncidentManagerReducer';
import AddIncidentFormReducer from './Incidents/AddIncidentFormReducer';
import ImportantDocumentsReducer from './Dashboard/ImportantDocumentsReducer';
import ReportIncidentReducer from './Dashboard/ReportIncidentReducer';
import TrainingReducer from './Dashboard/TrainingReducer';
import AlertReducer from './Alerts/AlertReducer';
import AlertsReducer from './Alerts/AlertsReducer';
import OrgSiteDeptReducer from './Organizations/OrgSiteDeptReducer';
import UsersReducer from './Users/UsersReducer';
import UsersUnassignedReducer from './Users/UsersUnassignedReducer';
import UsersByOrgReducer from './Users/UsersByOrgReducer';
import RolesReducer from './Users/RolesReducer';
import RolesUserReducer from './Users/RolesUserReducer';
import ReportsReducer from './Reports/ReportsReducer';
import CreateReportReducer from './Reports/CreateReportReducer';
import FoldersReducer from './Documents/FoldersReducer';
import DepartmentsReducer from './Organizations/DepartmentsReducer';
import FolderContentsReducer from './Documents/FolderContentsReducer';
import DocumentReducer from './Documents/DocumentReducer';
import GapsReducer from './Gaps/GapsReducer';
import StatsReducer from './Dashboard/StatsReducer';
import UserStatsReducer from './Dashboard/UserStatsReducer';
import StepsReducer from './Dashboard/StepsReducer';
import UserOrgSiteReducer from './Auth/UserOrgSiteReducer';
import DevicesReducer from './Devices/DevicesReducer';
import FilesReducer from './Documents/FilesReducer';
import AttestationsReducer from './Tasks/AttestationsReducer';
import UnapprovedFilesReducer from './Documents/UnapprovedFilesReducer';
import AffiliatesAuditReducer from './Affiliates/AffiliatesAuditReducer';
import SettingsReducer from './Users/SettingsReducer';
import CreateOrgReducer from './Organizations/CreateOrgReducer';
import SessionModalReducer from './SessionModal/SessionModalReducer';

import CreateUsersBulkReducer from './Users/CreateUsersBulkReducer';

import CreateEvidenceReducer from './Evidence/CreateEvidenceReducer';
import EvidenceReducer from './Evidence/EvidenceReducer';
import DatatableReducer from './Datatable/DatatableReducer';

import DashboardTasksReducer from './Dashboard/DashboardTasksReducer';
import SuperUserReducer from './SuperUser/SuperUserReducer';
import SuperUserOneOrgReducer from './SuperUser/SuperUserOneOrgReducer';
import SuperadminsReducer from './Organizations/SuperadminsReducer';
import CertificateReducer from './Certificates/CertificateReducer';
import CertificatesReducer from './Certificates/CertificatesReducer';
import CertificatesUserReducer from './Certificates/CertificatesUserReducer';
import CertificateUserReducer from './Certificates/CertificateUserReducer';
import UsersSearchReducer from './Users/UsersSearchReducer';
import QuizReducer from './Quiz/QuizReducer';
import QuizPassedReducer from './Quiz/QuizPassedReducer';
import ReasignableUsersReducer from './Users/ReasignableUsersReducer';
import CloudAssetsReducer from './Devices/CloudAssetsReducer';
import CloudAssetsV2Reducer from './Devices/CloudAssetsV2Reducer';
import DeviceInventoryReducer from './DeviceInventory/DeviceInventoryReducer';
import DeviceInventoryV2Reducer from './DeviceInventory/DeviceInventoryV2Reducer';
import ModulesReducer from './Modules/ModulesReducer';
import OrgModulesReducer from './Modules/OrgModulesReducer';
import TopLevelFoldersReducer from './Documents/TopLevelFoldersReducer';
import OrgEntitiesReducer from './Modules/OrgEntitiesReducer';
import MfaReducer from './Mfa/MfaReducer';
import OrganizationsAzureADReducer from './MicrosoftAzureActiveDirectory/OrganizationsAzureADReducer';
import ConnectOrgAzureADReducer from './MicrosoftAzureActiveDirectory/ConnectOrgAzureADReducer';
import UsersOrgAzureADReducer from './MicrosoftAzureActiveDirectory/UsersOrgAzureADReducer';
import ConnectUsersAzureADReducer from './MicrosoftAzureActiveDirectory/ConnectUsersAzureADReducer';
import LoginAttemptReducer from './Auth/LoginAttemptReducer';
import NavigationReducer from './Navigation/Navigation';
import UserCountersReducer from './Users/UserCountersReducer';

export default (routerReducer) => {
  const appReducer = combineReducers({
    router: routerReducer,
    UserLoginFormReducer,
    UserRegisterFormReducer,
    AddUserFormReducer,
    AuditsReducer,
    ActsReducer,
    SitesReducer,
    CreateAuditReducer,
    AuditReducer,
    RemediationReducer,
    AddOfficerFormReducer,
    EditUserFormReducer,
    AuthReducer,
    AllUsersListReducer,
    AllSitesListReducer,
    AddRemediationPlanFormReducer,
    EditRemediationPlanFormReducer,
    AffiliatesReducer,
    EditAffiliateFormReducer,
    AddAffiliateFormReducer,
    AffiliateAgreementsReducer,
    TasksReducer,
    TaskReducer,
    CreateTaskReducer,
    IncidentNotesReducer,
    IncidentsReducer,
    IncidentTypesReducer,
    IncidentManagerReducer,
    AddIncidentFormReducer,
    ImportantDocumentsReducer,
    ReportIncidentReducer,
    TrainingReducer,
    AlertReducer,
    OrgSiteDeptReducer,
    AlertsReducer,
    UsersReducer,
    UsersUnassignedReducer,
    UsersByOrgReducer,
    RolesReducer,
    ReportsReducer,
    CreateReportReducer,
    FoldersReducer,
    DepartmentsReducer,
    FolderContentsReducer,
    DocumentReducer,
    GapsReducer,
    CreateUsersBulkReducer,
    RolesUserReducer,
    StatsReducer,
    StepsReducer,
    UserOrgSiteReducer,
    DevicesReducer,
    FilesReducer,
    AttestationsReducer,
    TasksForUserReducer,
    UserStatsReducer,
    UnapprovedFilesReducer,
    AffiliatesAuditReducer,
    SettingsReducer,
    CreateOrgReducer,
    SessionModalReducer,
    CreateEvidenceReducer,
    EvidenceReducer,
    DatatableReducer,
    DashboardTasksReducer,
    SuperUserReducer,
    SuperadminsReducer,
    SuperUserOneOrgReducer,
    CertificateReducer,
    CertificatesReducer,
    CertificatesUserReducer,
    CertificateUserReducer,
    UsersSearchReducer,
    QuizReducer,
    QuizPassedReducer,
    ReasignableUsersReducer,
    CloudAssetsReducer,
    CloudAssetsV2Reducer,
    DeviceInventoryReducer,
    DeviceInventoryV2Reducer,
    ModulesReducer,
    OrgModulesReducer,
    TopLevelFoldersReducer,
    OrgEntitiesReducer,
    MfaReducer,
    OrganizationsAzureADReducer,
    ConnectOrgAzureADReducer,
    UsersOrgAzureADReducer,
    ConnectUsersAzureADReducer,
    LoginAttemptReducer,
    NavigationReducer,
    UserCountersReducer,
  });

  // reset all reducers to inital state on logout
  const rootReducer = (state, action) => {
    if (action.type === 'AUTH_LOGOUT') {
      return appReducer(undefined, action);
    }
    return appReducer(state, action);
  };
  return rootReducer;
};
