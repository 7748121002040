import { call, put, takeLatest, delay } from 'redux-saga/effects';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { actionApiCall } from 'helpers/api/ApiCalls';
import * as sagaConstants from 'helpers/constants/SagasConstants';

function* loadQuizQuestionsSaga({ payload }) {
  try {
    const apiResponse = yield call(actionApiCall, {
      actionType: ActionTypeConstants.LOAD_QUIZ_QUESTIONS,
      data: {
        training_id: payload.training,
      },
    });
    if (apiResponse.res.data.response === 200) {
      yield put({
        type: ActionTypeConstants.LOAD_QUIZ_QUESTIONS_SUCCESS,
        payload: {
          section: apiResponse.res.data.data.map((item) => ({
            ...item,
            end_time: parseInt(item.end_time, 10),
            start_time: parseInt(item.start_time, 10),
            passed: item.passed === 't' ? true : false,
            watched: item.watched === 't' ? true : false,
          })),
        },
      });
    } else {
      yield put({
        type: ActionTypeConstants.LOAD_QUIZ_QUESTIONS_FAILURE,
        payload: {
          message: apiResponse.res.data.message || `${sagaConstants.ISSUE_OCCURED} searching users`,
        },
      });
    }
  } catch (err) {
    yield put({
      type: ActionTypeConstants.LOAD_QUIZ_QUESTIONS_FAILURE,
      payload: {
        message: `${sagaConstants.SERVER_ERROR}`,
      },
    });
  }
}

function* loadQuizQuestionsSagaFail({ payload }) {
  yield put({
    type: ActionTypeConstants.SHOW_ALERT,
    payload: {
      message: payload.message || 'An issue occurred while searching users',
      color: 'danger',
    },
  });
  yield delay(10000);
  yield put({ type: ActionTypeConstants.HIDE_ALERT });
}

export default function* () {
  yield takeLatest(ActionTypeConstants.LOAD_QUIZ_QUESTIONS, loadQuizQuestionsSaga);
  yield takeLatest(ActionTypeConstants.LOAD_QUIZ_QUESTIONS_FAILURE, loadQuizQuestionsSagaFail);
}
