// import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { ClickAwayListener } from '@mui/base';

// import Paper from "@material-ui/core/Paper";
// import Popper from "@material-ui/core/Popper";

import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';

// import PropTypes from "prop-types";
import React from 'react';

class ItemActionsMenu extends React.Component {
  state = {
    anchorEl: null,
  };

  handleMenuToggle(e) {
    this.setState({ anchorEl: this.state.anchorEl ? null : e.currentTarget });
  }

  handleMenuClose() {
    this.setState({ anchorEl: null });
  }

  renderActions() {
    let items = [];

    if (this.props.actions && this.props.actions.length) {
      items = this.props.actions.map((item, i) => {
        if (item) {
          return (
            <div className={`item item-${i}`} key={i} onClick={() => item.action()}>
              <span className="label">{item.label}</span>
            </div>
          );
        }
        return null;
      });
    }

    return items;
  }

  render() {
    const { anchorEl } = this.state;
    return (
      <ClickAwayListener onClickAway={() => this.handleMenuClose()}>
        <div className="actions-menu-container">
          <i className="fas fa-bars" onClick={(e) => this.handleMenuToggle(e)} />
          <Popper id="simple-menu" className="actions-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} placement="bottom-end" disablePortal={true}>
            <Paper style={{ maxHeight: '350px', overflow: 'auto' }}>
              <div className="paper-content">{this.renderActions()}</div>
            </Paper>
          </Popper>
        </div>
      </ClickAwayListener>
    );
  }

  // static propTypes = {
  //   actions: PropTypes.arrayOf(
  //     PropTypes.shape({
  //       label: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
  //         .isRequired,
  //       action: PropTypes.func.isRequired
  //     })
  //   ).isRequired
  // };
}

export default ItemActionsMenu;
