import { call, put, takeLatest, select } from 'redux-saga/effects';

import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { actionApiCall } from 'helpers/api/ApiCalls';
import * as sagaConstants from 'helpers/constants/SagasConstants';

const getItems = (state) => state.SitesReducer.sites;

function transformUsers(users, sites) {
  const result = users.map((user) => {
    if (user.active === 'f') {
      user.status = 'Inactive';
    }
    if (user.active === 't' && user.registered === 't') {
      user.status = 'Active';
    }
    if (user.active === 't' && user.registered === 'f') {
      user.status = 'Awaiting Registration';
    }

    if (user.role && user.role.length > 0) {
      let roles = new Set(user.role.map((r) => r.name));
      user.role_str = [...roles].join(', ');
    }

    if (sites) {
      sites.forEach((item) => {
        if (item.id === user.site) {
          user.siteName = item.name;
        }
      });
    }
    return user;
  });
  return result;
}

function* loadUsersSaga({ payload }) {
  try {
    const apiResponse = yield call(actionApiCall, {
      actionType: ActionTypeConstants.LOAD_USERS,
      data: {
        inactive: payload && payload.inactive ? true : undefined,
        id: payload ? payload.id : null,
        site: payload && payload.site ? String(payload.site) : undefined,
      },
    });
    if (apiResponse.res.data.response === 200) {
      let { data } = apiResponse.res.data;
      if (!Array.isArray(data)) {
        data = [data];
      }
      const sites = yield select(getItems);
      yield put({
        type: ActionTypeConstants.LOAD_USERS_SUCCESS,
        payload: {
          users: transformUsers(data, sites),
        },
      });
    } else {
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: apiResponse.res.data.message || `${sagaConstants.ISSUE_OCCURED} loading users`,
          color: 'danger',
        },
      });
    }
  } catch (err) {
    yield put({
      type: ActionTypeConstants.SHOW_ALERT,
      payload: {
        message: `${sagaConstants.SERVER_ERROR}`,
        color: 'danger',
      },
    });
  }
}

export default function* () {
  yield takeLatest(ActionTypeConstants.LOAD_USERS, loadUsersSaga);
}
