import { put, takeLatest } from 'redux-saga/effects';
import ActionTypeConstants from 'helpers/ActionTypeConstants';

function* setDatatableSaga({ payload }) {
  try {
    yield put({
      type: ActionTypeConstants.SET_DATATABLE_SUCCESS,
      payload: { datatable: payload.datatable },
    });
  } catch (err) {
    yield put({
      type: ActionTypeConstants.SET_DATATABLE_FAILURE,
      payload: {},
    });
  }
}

function* SetDatatableSaga() {
  yield takeLatest(ActionTypeConstants.SET_DATATABLE, setDatatableSaga);
}

export default SetDatatableSaga;
