import React from 'react';
import MainLayout from 'v2/layouts/MainLayout/MainLayout';

import Tabs from 'v2/components/Tabs/Tabs';

function Assets() {
  return (
    <MainLayout>
      <Tabs
        tabs={[
          {
            title: 'Devices',
            path: '',
          },
          {
            title: 'Applications',
            path: 'applications',
          },
          {
            title: 'Open Tasks',
            path: 'tasks',
          },
        ]}
      />
    </MainLayout>
  );
}

export default Assets;
