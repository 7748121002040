import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { useConfirmModal } from 'hooks';

import HR from 'components/HR/HR';
import FormElement from 'components/FormElement/FormElement';
import Button from 'components/Button/Button';
import TableDefEvidence from 'table-defs/TableDefEvidence';
import FullDataTable from 'components/DataTable/FullDataTable';
import UploadDocument from 'components/UploadDocument/UploadDocument';

import { createEvidenceAction, loadEvidenceByIncidentAction, deleteEvidenceAction, downloadEvidenceAction } from 'store/actions/Actions';

import styles from './AddEvidence.module.scss';

const EditEvidence = (props) => {
  const {
    EvidenceReducer,
    CreateEvidenceReducer,
    loadEvidenceByIncidentAction,
    createEvidenceAction,
    deleteEvidenceAction,
    downloadEvidenceAction,
    incidentId,
    UserOrgSiteReducer,
    AuthReducer,
    isFinalized,
  } = props;

  useEffect(() => {
    if (!EvidenceReducer.evidence && incidentId) {
      loadEvidenceByIncidentAction({ id: incidentId });
    }
  }, [loadEvidenceByIncidentAction, EvidenceReducer.evidence]);

  const [state, setState] = useState({
    file: '',
    name: '',
  });

  const [showConfirmModal, setShowConfirmModal, renderConfirmModal] = useConfirmModal();
  const INCIDENT = 'incident';

  const addEvidence = () => {
    try {
      createEvidenceAction({
        entity_type: INCIDENT,
        entity_id: incidentId,
        file: {
          file: state['file'],
          name: state['name'],
        },
      });
      setState({
        file: '',
        name: '',
      });
    } catch (error) {
      console.log(error);
    }
  };

  const deleteEvidence = (payload) => {
    try {
      setShowConfirmModal({
        title: 'Delete evidence file',
        question: `You're about to permanently delete evidence file "${payload.filename}"! Are you sure?`,
        confirmAction: () =>
          deleteEvidenceAction({
            id: payload.id,
            entity_id: incidentId,
            entity_type: INCIDENT,
          }),
      });
    } catch (error) {
      console.log(error);
    }
  };

  const downloadEvidence = (payload) => {
    try {
      downloadEvidenceAction({ hash: payload.hash });
    } catch (error) {
      console.log(error);
    }
  };

  const is_officer = (UserOrgSiteReducer.selected && UserOrgSiteReducer.selected.is_officer) || false;
  const is_admin = (UserOrgSiteReducer.selected && UserOrgSiteReducer.selected.is_admin) || false;
  const is_super = (UserOrgSiteReducer.selected && UserOrgSiteReducer.selected.is_super) || false;
  const is_coach = (UserOrgSiteReducer.selected && UserOrgSiteReducer.selected.is_coach) || false;
  const currentUserId = AuthReducer.userData.id || false;
  const isIncident = true;

  return (
    <div className={`${styles['add-evidence']} ${props.className || ''}`}>
      <p className={styles['add-evidence-heading']}>Attach Evidence</p>

      <HR />

      {showConfirmModal && renderConfirmModal(showConfirmModal, setShowConfirmModal)}

      <div className={styles['add-evidence-data']}>
        <>
          <div className={styles['holder']}>
            <FormElement labelText="DOCUMENTS" htmlFor="documents">
              <UploadDocument state={state} setState={setState} formats={['jpg', 'jpeg', 'png', 'gif', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'pdf']} />
            </FormElement>

            <Button
              className={styles.button}
              text="UPLOAD EVIDENCE"
              icon={CreateEvidenceReducer.status === 'ISBUSY' ? 'fad fa-spinner-third fa-spin' : 'fad fa-upload'}
              disabled={state['file'] === '' || is_coach ? true : false}
              onClick={addEvidence}
            />
          </div>

          <br />
          <br />
        </>

        <FullDataTable
          className={styles['add-evidence-table']}
          rowsData={EvidenceReducer.evidence}
          definitions={TableDefEvidence}
          customTableProps={{
            deleteEvidence,
            downloadEvidence,
            is_super,
            is_officer,
            currentUserId,
            isIncident,
            is_admin,
            isFinalized,
          }}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  EvidenceReducer: state.EvidenceReducer,
  CreateEvidenceReducer: state.CreateEvidenceReducer,
  UserOrgSiteReducer: state.UserOrgSiteReducer,
  AuthReducer: state.AuthReducer,
});

const mapDispatchToProps = {
  createEvidenceAction,
  deleteEvidenceAction,
  downloadEvidenceAction,
  loadEvidenceByIncidentAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditEvidence);
