import React from 'react';
import classNames from 'classnames';
import { randomId } from 'helpers/utils';

import styles from './DateInput.module.scss';

interface IProps {
  className?: string;
  size?: 'full' | 'md' | 'lg';
  disabled?: boolean;
  minDate?: string;
  maxDate?: string;
  isActive?: boolean;
  value?: string;
  onChange?: (e) => void;
}

const defaultProps = {
  className: '',
  size: 'lg',
  disabled: false,
  minDate: '',
  maxDate: '',
  isActive: false,
  value: '',
  onChange: () => false,
};

export function DateInput({ className, size, disabled, minDate, maxDate, isActive, value, onChange }: IProps) {
  const randomID = randomId();
  const style = classNames(styles['date-input'], styles[`date-input-${size}`], className, { [styles.active]: isActive });
  return (
    <div className={style}>
      <input type="date" id={randomID} value={value} min={minDate} max={maxDate} disabled={disabled} onChange={onChange} />
      <label htmlFor={randomID}>{/* <i className={`fal fa-calendar-alt ${styles["date-input-icon"]}`} /> */}</label>
    </div>
  );
}

DateInput.defaultProps = defaultProps;

export default DateInput;
