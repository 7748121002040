import React, { useEffect, useState } from 'react';

import Checkbox from 'components/Checkbox/Checkbox';
import FormElement from 'components/FormElement/FormElement';
import SelectWithSearch from 'components/SelectWithSearch/SelectWithSearch';

import { useAppDispatch } from 'hooks';

import styles from './AccessPermissions.module.scss';

const AccessPermissions = (props) => {
  const dispatch = useAppDispatch();
  const {
    roles,
    error,
    initState,
    setStateErrors,
    setLocalBoxes,
    organizations,
    userOrgs = [],
    orgs,
    selected,
    loadOrgSiteDeptAction,
    loadRolesUserAction,
    userId,
    UsersReducer,
  } = props;
  const [access, setAccess] = useState({});
  const [selected_org, setSelectedOrg] = useState(organizations ? Object.keys(organizations)[0] : null);
  const [filterAccess, setFilterAccess] = useState(false);

  useEffect(() => {
    setAccess(() => ({ ...initState }));
  }, [initState]);

  const handleSelect = (e) => {
    setStateErrors((s) => ({
      ...s,
      access: '',
    }));
    const access_key = e.target.dataset.key;
    const role_id = parseInt(e.target.value, 10);
    const role_name = (roles && roles.length > 0 && roles.filter((role) => role.id === `${role_id}`)[0].name) || '';
    const updated = Object.assign({}, access);
    updated[access_key]['role_id'] = role_id;
    updated[access_key]['role_name'] = role_name;
    setAccess(() => ({ ...updated }));
    setLocalBoxes((s) => ({ ...s, ...updated }));
  };

  const handleCheckbox = (e) => {
    setStateErrors((s) => ({
      ...s,
      access: '',
    }));
    const item_key = `${e.target.name}`;
    const current = Object.assign({}, access[item_key]);
    current['role_id'] = 0; // default role
    current['role_name'] = ''; // default role
    const updated = Object.assign({}, access);
    if (e.target.checked && !e.target.disabled) {
      updated[item_key] = current;
    } else {
      updated[item_key] = false;
    }
    setLocalBoxes((s) => ({ ...s, ...updated }));
  };

  const className = `${styles['access-permissions']} ${props.className || ''}`;

  const orgsList = {};
  orgsList[0] = 'All';
  Object.keys(userOrgs).forEach((key) => {
    orgsList[key] = userOrgs[key].name;
  });

  const handleOrgChange = (data) => {
    try {
      const org_id = data?.id || null;
      setLocalBoxes({});
      setSelectedOrg(org_id);
      dispatch(loadOrgSiteDeptAction({ organization: org_id }));
      if (loadRolesUserAction) {
        dispatch(loadRolesUserAction({ id: userId, organization: org_id }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const orgValue = selected_org && userOrgs[selected_org] ? selected_org : orgs[0] ? orgs[0].id : '';
  const is_site_admin = selected.is_site_admin || false;
  const is_super = selected.is_super || false;

  const userAccess = UsersReducer && UsersReducer.users && UsersReducer.users[0] && UsersReducer.users[0].role && UsersReducer.users[0].role.map((r) => r.organization);

  return (
    <div className={className}>
      <div>
        <FormElement className={`${styles['user-email-addresses']} ${className || ''}`} labelText="ACCESS PERMISSIONS" smallText="*" htmlFor="name">
          {is_super && userAccess && (
            <span className={styles.access_filter}>
              Filter by Access
              <Checkbox onChange={() => setFilterAccess(!filterAccess)} checked={filterAccess} />
            </span>
          )}

          {is_super && userAccess && filterAccess && (
            <div className={styles.search_holder}>
              <SelectWithSearch
                value={orgValue}
                onChange={handleOrgChange}
                options={Object.keys(userOrgs)
                  .filter((orgId) => userAccess && userAccess.includes(orgId))
                  .map((org_id) => ({
                    id: org_id,
                    name: userOrgs[org_id].name,
                  }))}
                placeholder="Select Organization"
              />
            </div>
          )}

          {is_super && !filterAccess && (
            <div className={styles.search_holder}>
              <SelectWithSearch
                value={orgValue}
                onChange={handleOrgChange}
                options={Object.keys(userOrgs).map((org_id) => ({
                  id: org_id,
                  name: userOrgs[org_id].name,
                }))}
                placeholder="Select Organization"
              />
            </div>
          )}

          <div className={`${styles.holder} ${error ? styles['access-error'] : ''}`}>
            {orgs.map((org) => {
              return (
                <div key={`${org.id}-key-org`} className={styles.item}>
                  <div className={`${styles.select_item_org} ${styles.select_item}`}>
                    <span className={styles.checkbox}>
                      <Checkbox disabled={is_site_admin ? true : false} id={`id-${org.key}`} name={org.key} onChange={handleCheckbox} checked={access[org.key] ? true : false} />
                    </span>
                    <span className={styles.name}>
                      <label className={styles.label} htmlFor={`id-${org.key}`}>
                        {org.name}
                      </label>
                    </span>
                    <span className={styles.drop}>
                      {access[org.key] && (
                        <select key={`${org.id}-ok`} defaultValue={access[org.key].role_id} onChange={handleSelect} name="role" data-key={org.key} data-org={org.id}>
                          <option value="0" disabled>
                            Choose role
                          </option>
                          {roles &&
                            roles
                              .filter((role) => role.org_level)
                              .map((role) => (
                                <option key={`so-${role.id}`} value={role.id}>
                                  {role.name}
                                </option>
                              ))}
                        </select>
                      )}
                    </span>
                  </div>
                  <div>
                    {org.sites.map((site) => {
                      return (
                        <div key={`site-${site.id}`}>
                          <div className={`${styles.select_item_site} ${styles.select_item}`}>
                            <span className={styles.checkbox}>
                              <Checkbox
                                id={`id-${site.key}`}
                                name={site.key}
                                onChange={handleCheckbox}
                                disabled={access[org.key] ? true : false}
                                checked={access[org.key] || access[site.key] ? true : false}
                              />
                            </span>
                            <span className={styles.name}>
                              <label className={styles.label} htmlFor={`id-${site.key}`}>
                                {site.name}
                              </label>
                            </span>
                            <span className={styles.drop}>
                              {access[site.key] && !access[org.key] ? (
                                <select
                                  key={`${org.id}-${site.id}-sk`}
                                  defaultValue={access[site.key].role_id}
                                  onChange={handleSelect}
                                  name="role"
                                  data-key={site.key}
                                  data-org={org.id}
                                  data-site={site.id}
                                  data-partial={access[site.key] && access[site.key].role_name === 'User' ? true : false}
                                >
                                  <option value="0" disabled>
                                    Choose role
                                  </option>
                                  {roles &&
                                    roles
                                      .filter((role) => role.site_level)
                                      .map((role) => {
                                        if (is_site_admin && role.name === 'Org Administrator') {
                                          return null;
                                        }
                                        return (
                                          <option key={`sr-${role.id}`} value={role.id}>
                                            {role.name}
                                          </option>
                                        );
                                      })}
                                </select>
                              ) : (
                                ''
                              )}
                            </span>
                          </div>
                          {site.deps.map((dep) => {
                            return (
                              <div key={`dep-${site.id}-${dep.id}`} className={`${styles.select_item_dep} ${styles.select_item}`}>
                                <span className={styles.checkbox}>
                                  <Checkbox
                                    id={`id-${dep.key}`}
                                    name={dep.key}
                                    onChange={handleCheckbox}
                                    disabled={
                                      access[org.key] ||
                                      (access[site.key] && !access[site.key].role_name) ||
                                      (access[site.key] && access[site.key].role_name === 'Site Admin') ||
                                      !access[site.key]
                                        ? true
                                        : false
                                    }
                                    checked={
                                      access[org.key] ||
                                      (access[site.key] && access[site.key].role_name === 'Site Admin') ||
                                      (access[site.key] && access[site.key].role_name && access[dep.key])
                                        ? true
                                        : false
                                    }
                                  />
                                </span>
                                <span className={styles.name}>
                                  <label className={styles.label} htmlFor={`id-${dep.key}`}>
                                    {dep.name}
                                  </label>
                                </span>
                                <span className={styles.drop}>
                                  {access[dep.key] && access[site.key] && access[site.key].role_name === 'User' && !access[org.key] ? (
                                    <select
                                      style={{ visibility: 'hidden' }}
                                      disabled
                                      key={`${org.id}-${site.id}-${dep.id}-dk`}
                                      defaultValue={access[dep.key].role_id}
                                      onChange={handleSelect}
                                      name="role"
                                      data-key={dep.key}
                                      data-org={org.id}
                                      data-site={site.id}
                                      data-dep={dep.id}
                                    >
                                      {/* <option value="0" disabled>Choose role</option> */}
                                      {roles &&
                                        roles
                                          .filter((role) => role.name.includes('User'))
                                          .map((role) => (
                                            <option key={`sd-${role.id}`} value={role.id}>
                                              {role.name}
                                            </option>
                                          ))}
                                    </select>
                                  ) : (
                                    ''
                                  )}
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </FormElement>
      </div>
      {error && <p className={styles['error-msg']}>{error}</p>}
    </div>
  );
};

export default AccessPermissions;
