import React from 'react';
import logo from 'assets/img/cg-logo-large.png';
import logoIconOnly from 'assets/img/logo-icon-only.png';
import hipaaSealLogo from 'assets/img/cg-seal-logo.png';

import { NavLink, Link } from 'react-router-dom';
import Label from 'components/Label/Label';
import { mainMenu } from './MainMenuData';
import classNames from 'classnames';
import { useAppSelector } from 'hooks';

import EqualizerIcon from '@mui/icons-material/Equalizer';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import PeopleIcon from '@mui/icons-material/People';
import WorkIcon from '@mui/icons-material/Work';
import SchoolIcon from '@mui/icons-material/School';
import StorefrontIcon from '@mui/icons-material/Storefront';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import SettingsIcon from '@mui/icons-material/Settings';

import styles from './MainMenu.module.scss';

interface IMainMenu {
    count: {
        myTasks: number | string;
        myDocuments: number | string;
    };
    isExpanded: boolean;
}

function MainMenu ({
    count = undefined,
    isExpanded = true,
}: IMainMenu) {

    const AuthReducer = useAppSelector((state) => state.AuthReducer);
    
    const ACTIVE_MENU_ITEM_BACKGROUND: string = '#d4eef9';
    const LOGO_LINK = "/my-dashboard";

    function getIcon(icon: string) {
        const iconMap = {
            EqualizerIcon,
            CheckCircleIcon,
            ReceiptLongIcon,
            PrivacyTipIcon,
            AssignmentTurnedInIcon,
            PeopleIcon,
            WorkIcon,
            SchoolIcon,
            StorefrontIcon,
            MonitorHeartIcon,
            SettingsIcon,
        }

        return React.createElement(iconMap[icon]);
    }

    const labelStyle = classNames(styles['menu-item-label'], !isExpanded && styles['hide']);
    const hipaaLogoStyle = classNames(styles['hipaa-seal-logo'], !isExpanded && styles['collapsed-menu']);

    return (
        <nav className={styles['main-menu']}>
            <div className={styles['logo-wrapper']}>
                {isExpanded ? 
                    <Link to={LOGO_LINK}><img className={styles.logo} src={logo} width="220px" alt="Compliancy Group Logo" /></Link> :
                    <Link to={LOGO_LINK}><img className={styles['logo-small']} src={logoIconOnly} width="50px" alt="Compliancy Group Logo" /></Link>}
            </div>

            {Object.keys(mainMenu).map((key, index) => {
                if (key === 'bottomNav' && AuthReducer?.userData?.role[0]?.name === 'User') return;
                return (
                    <div className={styles['menu-items-wrapper']} key={key}>
                        {mainMenu[key].map((navItem) => {
                            return (
                                <NavLink 
                                    to={navItem.link} 
                                    className={`${styles['menu-item-link']} ${styles['menu-item-wrapper']}`} 
                                    key={navItem.id} 
                                    style={(navData) => navData.isActive ? 
                                        {
                                            backgroundColor: ACTIVE_MENU_ITEM_BACKGROUND, 
                                            fontWeight: 500,
                                            color: '#29aae2',
                                            borderRight: '6px solid #29aae2',
                                            paddingRight: '14px',
                                        } : {}}
                                >
                                    <span className={styles['menu-icon']}>{getIcon(navItem.icon)}</span>
                                    <span className={labelStyle}>{navItem.label}</span>
                                    {(key === 'topNav' && 
                                        navItem.showStats && 
                                        isExpanded) && 
                                        <Label backgroundColor="#666666" color="#ffffff" margin="0 0 0 10px">
                                        {count[navItem.id]}
                                    </Label>}
                                </NavLink>
                            );
                        })}
                        {((Object.keys(mainMenu).length - 1 !== index) && 
                            !(AuthReducer?.userData?.role[0]?.name === 'User')) && 
                            <div className={styles['spacer']}></div>}
                    </div>
                );
            })}

            <div className={hipaaLogoStyle}>
                <img src={hipaaSealLogo} alt="HIPAA Seal of Compliance Logo" />
            </div>
        </nav>
    )
}

export default MainMenu;