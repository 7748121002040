import React from 'react';

import style from './PageLayout.module.scss';

function PageLayout({ children }) {
  return (
    <div className={style.pageLayout}>
      {children}
    </div>
  );
}

export default PageLayout;
