import { put, call, takeLatest, select, delay } from 'redux-saga/effects';
import ActionTypeConstants from 'helpers/ActionTypeConstants';
import { actionApiCall } from 'helpers/api/ApiCalls';
import * as sagaConstants from 'helpers/constants/SagasConstants';

const getItems = (state) => state.SitesReducer.sites;
const getSelected = (state) => state.UserOrgSiteReducer.selected;
const getRoles = (state) => state.RolesReducer.roles;

function transformTraining(trainings, sites, roles) {
  const result = trainings.map((training) => {
    // add status
    training.status = training.configuration.status_label;
    training.deactivated = training.configuration && training.configuration.status === '6' ? 't' : 'f';
    // add siteName
    if (sites) {
      sites.forEach((item) => {
        if (item.id === training.site) {
          training.siteName = item.name;
        }
      });
    }
    training.frequency_label = `${training.configuration.frequency_label} ${training.configuration.reset ? '(Reset)' : ''} ${training.suspended === 't' ? '(Suspended)' : ''}`;
    // add role_str
    let roleList = {};
    if (roles) {
      roles.map((role) => {
        if (role.name && !role.name.includes('Super') && !role.name.includes('Coach')) {
          roleList[role.id] = { id: role.id, name: role.name };
        }
        return false;
      });
    }
    if (roles && training.role_id !== '') {
      let newRoleNames = '';
      const rolesArray = training.role_id.split(',').map(Number);
      // generate role string name
      Object.values(rolesArray).forEach((key) => {
        if (roleList[key]) {
          newRoleNames = `${newRoleNames}, ${roleList[key].name}`;
        }
      });
      // trim leading space and commma
      newRoleNames = newRoleNames.substring(2);
      training['role_str'] = newRoleNames;
    }
    return training;
  });
  return result;
}

function* loadTrainingSaga({ payload }) {
  try {
    const roles = yield select(getRoles);
    const selected = yield select(getSelected);
    const apiResponse = yield call(actionApiCall, {
      actionType: ActionTypeConstants.LOAD_TRAININGS,
      data: {
        organization: selected.org,
        site: selected.site,
      },
    });
    if (apiResponse.res.data.response === 200) {
      const sites = yield select(getItems);
      const training = apiResponse.res.data;
      yield put({
        type: ActionTypeConstants.LOAD_TRAININGS_SUCCESS,
        payload: { training: transformTraining(training.data, sites, roles) },
      });
    } else {
      yield put({
        type: ActionTypeConstants.SHOW_ALERT,
        payload: {
          message: apiResponse.res.data.message || `${sagaConstants.ISSUE_OCCURED} loading trainings`,
          color: 'danger',
        },
      });
      yield delay(10000);
      yield put({ type: ActionTypeConstants.HIDE_ALERT });
    }
  } catch (err) {
    yield put({
      type: ActionTypeConstants.SHOW_ALERT,
      payload: {
        message: `${sagaConstants.SERVER_ERROR}`,
        color: 'danger',
      },
    });
    yield delay(10000);
    yield put({ type: ActionTypeConstants.HIDE_ALERT });
  }
}

export default function* () {
  yield takeLatest(ActionTypeConstants.LOAD_TRAININGS, loadTrainingSaga);
}
