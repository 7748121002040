import React from 'react';
import classNames from 'classnames';
import { showAlertAction } from 'store/actions/Actions';
import { fileTypeIcon, formatBytes, validateFile } from 'helpers/utils';

import styles from './UploadZone.module.scss';

interface IProps {
  parentFolderProp?: any;
  files: Array<any>;
  setFiles: React.Dispatch<React.SetStateAction<any>>;
  formats: Array<string>;
  maxFileSize: number;
  maxFileNameLength: number;
  multipleUploads?: boolean;
  setTemplate?: React.Dispatch<React.SetStateAction<any>>;
}

export function UploadZone({
  parentFolderProp = undefined,
  files = [],
  setFiles = () => false,
  formats = [],
  maxFileSize = 0,
  maxFileNameLength = 0,
  multipleUploads = false,
}: IProps) {
  const isAllUploaded = !(files.every((d) => d.status === 'new') || files.some((d) => d.status === 'inprogress'));
  const areSomeUploading = files.some((d) => d.status === 'inprogress');

  const styleUploadZone = classNames(styles['drag-n-drop'], (isAllUploaded || areSomeUploading) && styles['disabled']);

  const dotFormats = formats.map((format) => `.${format}`).join(', ');

  const handleDrop = (e) => {
    e.preventDefault();
    if (e.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (let i = 0; i < e.dataTransfer.items.length; i += 1) {
        // If dropped items aren't files, reject them
        if (e.dataTransfer.items[i].kind === 'file') {
          const file = e.dataTransfer.items[i].getAsFile();
          if (files.every((d) => d.name !== file.name)) {
            const reader = new FileReader();
            validateFile(file, reader, setFiles, files, showAlertAction, parentFolderProp, formats, maxFileSize, maxFileNameLength);
          }
        }
      }
    }
  };

  const handleBrowse = async (e) => {
    e.preventDefault();
    if (e?.target?.files) {
      for (const file of e.target.files) {
        if (files.every((d) => d.name !== file.name)) {
          const reader = new FileReader();
          validateFile(file, reader, setFiles, files, showAlertAction, parentFolderProp, formats, maxFileSize, maxFileNameLength);
        }
      }
    }
  };

  return (
    <>
      <div className={styleUploadZone} onDrop={(e) => handleDrop(e)} onDragOver={(e) => e.preventDefault()}>
        <div className={styles.browse}>
          <i className={`fal fa-arrow-to-bottom ${styles['drag-icon']}`} />
          <p className={styles['dnd-paragraph']}>Click to upload or drag &amp; drop files here</p>
          {multipleUploads ? (
            <input type="file" multiple disabled={isAllUploaded || areSomeUploading} accept={dotFormats} onChange={handleBrowse} />
          ) : (
            <input type="file" onChange={handleBrowse} accept={dotFormats} />
          )}
          <p className={styles.supported}>
            Supported file formats: {dotFormats}
            <br />
            Maximum allowed file size is {Math.floor(maxFileSize / (1024 * 1024))} MB.
          </p>
        </div>
      </div>
      {files && files.length > 0 && (
        <>
          <div className={styles.selected_title}>
            <span>Files ({files.length})</span>
          </div>
          <div className={styles.filesHolder}>
            {files &&
              files.map((d) => (
                <div className={styles.fileItem} key={d.name}>
                  <i className={fileTypeIcon(d.name)} />
                  <div className={styles.name}>{d.name}</div>
                  <div className={styles.size}>{formatBytes(d.file.size)}</div>
                  <div className={`${styles.perc} ${styles[d.status]}`}>{d.msg}</div>
                  <div className={styles.remove} aria-hidden="true" onClick={() => setFiles((docs) => docs.filter((doc) => doc.name !== d.name))}>
                    <i title="Remove document" className="fa fa-circle-xmark" />
                  </div>
                </div>
              ))}
          </div>
        </>
      )}
    </>
  );
}

export default UploadZone;
